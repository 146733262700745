import styled from 'styled-components'
import { breakpoints } from '~/helper/breakpoints'
import Figure from '../../Components/Figure'

export const Container = styled.div`
  margin-top: 8rem;
  width: 100vw;
  padding: 0rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: #18181a;
    text-align: center;
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.16;
  }

  h2 {
    color: #7e7f86;
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media ${breakpoints.mobile} {
    margin-top: 4rem;
    padding: 0rem 1.25rem;

    h1 {
      font-size: 1.5rem;
      line-height: 116%;
      margin-bottom: 0.6rem !important;
    }

    h2 {
      font-size: 0.938rem;
      line-height: 20px;
    }

    margin-bottom: 5rem;
  }
`

export const CardsContainer = styled.div`
  max-width: 96rem;
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  justify-content: center;
  align-items: center;

  @media ${breakpoints.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  padding-top: 2rem;
  padding-bottom: 2rem;

  height: 100%;
  border-radius: 20px;
  border: 1px rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(25px);

  h3 {
    text-align: center;
    color: #2d2d32;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }

  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }

  @media ${breakpoints.mobile} {
    h3 {
      width: 90%;
      font-size: 0.938rem;
      font-weight: 700;
    }
  }
`

export const CardImage = styled(Figure)`
  width: 100%;

  padding-left: 3.125rem;
  padding-right: 3.125rem;

  @media ${breakpoints.mobile} {
    padding-left: 1.563rem;
    padding-right: 1.563rem;
  }
`
