import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  setCommunityCurrentTab as setCommunityCurrentTabAction,
  setSponsorCurrentTab as setSponsorCurrentTabAction,
} from '~/store/modules/tab/actions'

const useNavigationFunctions = () => {
  const dispatch = useDispatch()
  const actions = useMemo(
    () => ({
      setCommunityCurrentTabAction(tab) {
        dispatch(setCommunityCurrentTabAction(tab))
      },
      setSponsorCurrentTabAction(tab) {
        dispatch(setSponsorCurrentTabAction(tab))
      },
    }),
    [dispatch],
  )
  return actions
}

export default useNavigationFunctions
