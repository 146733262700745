import styled from 'styled-components'
import { darken } from 'polished'
import DatePicker from 'react-datepicker'

export const FormContainer = styled.div`
  .input-date {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    height: 40px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(159, 159, 159, 0.7);
    border-radius: 5px;
    padding-left: 5px;
  }
`

export const Optional = styled.span`
  color: #87919a;
  font-family: Lato;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
`

export const DatePickerInput = styled(DatePicker)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 5px;
`

export const CouponSection = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 15px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(159, 159, 159, 0.3);
  padding: 20px 0px;

  .subtitle {
    color: #404257;
    opacity: 0.6;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
  }
`

export const InputTitle = styled.span`
  margin-top: 3px;
  color: #404257;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
`

export const ProductTitle = styled.span`
  margin-top: 3px;
  color: #fff;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
`

export const Phone = styled.div`
  width: 310px;
  height: 640px;
  border: 2px solid #ccc;
  border-radius: 30px;
  position: relative;
  left: 50%;
  top: 50px;
  margin: -20px -150px;
  background: #fff;
`

export const Screen = styled.div`
  background-color: rgba(0, 0, 0, 0.95);
  width: 290px;
  height: 520px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  left: 50%;
  top: 50%;
  margin: -260px -145px;
  box-sizing: border-box;
  overflow: hidden;
`

export const Home = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0 -10px;
  border-radius: 50%;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .image-container {
    width: 100%;
    height: 320px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .about-section {
    color: #fff;
    margin-left: 12px !important;
    font-family: Roboto;
    font-size: 12px;
    width: 260px;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
`

export const BoostIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff751a;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  text-decoration: none;
  margin-right: 20px;
  position: absolute;
  right: 0px;
  top: 53%;
`

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #fff;
    font-family: Roboto;
    font-size: 11px;
    font-weight: bold;
  }

  .points {
    padding-left: 5px;
  }

  .hashtag-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 20px;
    height: 20px;
    background-color: #ff751a;
    border-radius: 100%;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: ${darken(0.1, '#ff751a')};
      text-decoration: none;
    }
  }
`
