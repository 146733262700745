import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import useNavigationFunctions from '~/helper/navigationFunctions'
import useTutorialFunctions from '~/helper/tutorialFunctions'
import * as S from './styled'

export default function PackagesTabTooltip({ closeProps, tooltipProps }) {
  const { t } = useTranslation()
  const { setCommunityCurrentTabAction } = useNavigationFunctions()
  const { setTutorialAsInativeAction } = useTutorialFunctions()
  const communityHasStripeAccount = useSelector(state => state.community.communityHasStripeAccount)

  const goToCampaignsPage = () => {
    setCommunityCurrentTabAction('campaigns')
  }

  const goToSponsorsTab = () => {
    setCommunityCurrentTabAction('sponsors')
  }

  const goBackToFormatsTab = () => {
    setCommunityCurrentTabAction('products')
  }

  return (
    <S.ComponentContainer {...tooltipProps}>
      <S.TooltipArrow />
      <S.TooltipUserAvatar />
      <S.TooltipContainer>
        <S.CloseButtonContainer {...closeProps}>
          <S.CloseIcon onClick={() => setTutorialAsInativeAction()} />
        </S.CloseButtonContainer>
        <S.Title>{t('communityStep2TutorialTitle')}</S.Title>
        <S.Subtitle>{t('packagesTabTooltipSubtitle')}</S.Subtitle>
        <S.ButtonsContainer>
          <S.BackButton onClick={() => goBackToFormatsTab()}>
            <S.BackButtonText>{t('back')}</S.BackButtonText>
          </S.BackButton>
          {communityHasStripeAccount === false ? (
            <S.NextButton onClick={() => goToCampaignsPage()}>
              <S.NextButtonText>{t('next')}</S.NextButtonText>
            </S.NextButton>
          ) : (
            <S.NextButton onClick={() => goToSponsorsTab()}>
              <S.NextButtonText>{t('next')}</S.NextButtonText>
            </S.NextButton>
          )}
        </S.ButtonsContainer>
      </S.TooltipContainer>
    </S.ComponentContainer>
  )
}
