import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import TextField from '@material-ui/core/TextField'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import { lighten } from 'polished'
import Spinner from 'react-bootstrap/Spinner'
import DefaultButton from '~/components/Shared/DefaultButton'
import loginRibbonImage from '~/../public/login-ribbon.svg'
import loginWaveImage from '~/../public/landing-page-wave.svg'
import loginCircleImage from '~/../public/middle-circle.svg'
import { customMedia } from '~/styles/global'

export const PageContainer = styled.div.attrs({
  className: 'animated fadeIn',
})`
  position: relative;
  min-height: 100vh !important;
  height: 100%;

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px !important;
  }

  .MuiOutlinedInput-root {
    width: 20rem;
    height: 3.75rem;
    position: relative;
    border-radius: 4px;

    ${customMedia.lessThan('560px')`
        width: 15rem;
        height: 3.75rem;
    `}
  }
`

export const MiddleCircleContainer = styled.div`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  overflow: visible !important;

  ${customMedia.lessThan('1535px')`
    display: none;
  `}
`

export const MiddleCircle = styled.img.attrs({
  src: loginCircleImage,
  className: 'animated fadeIn',
})`
  position: relative;
  max-width: 100%;
  max-height: 100%;
  margin-top: -50%;
  margin-left: -50%;
  z-index: -1;

  ${customMedia.lessThan('1535px')`
    display: none;
  `}
`

export const Wave = styled.img.attrs({
  src: loginWaveImage,
})`
  position: absolute;
  width: 100%;
  top: 20rem;

  ${customMedia.greaterThan('2600px')`
    display: none;
  `}

  ${customMedia.lessThan('470px')`
    display: none;
  `}
`

export const LoginText = styled.strong`
  font-family: Lato;
  font-size: 4rem;
  padding-bottom: 1.2rem;
  color: #7345e9;
  letter-spacing: 2px;
  text-shadow: 0px 2px 4px #00000029;
`

export const LoginFormRibbon = styled.img.attrs({
  src: loginRibbonImage,
})`
  position: absolute;
  top: -15px;
`

export const LoginSectionContainer = styled(Container).attrs({
  className: 'animated swing',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 280px;

  .MuiOutlinedInput-input {
    padding: 20px !important;
  }
`

export const LoginTitleContainer = styled.div`
  background-image: linear-gradient(to right, #9671f5 40%, #7345e9 100%);
  border-radius: 12px;
  width: 90%;
  height: 3.1rem;
  overflow: hidden;
  box-shadow: 0px 13px 20px -8px rgba(0, 0, 0, 0.4);

  position: absolute;
  top: -25px;
  left: 5%;
`

export const Title = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 50px;
`

export const Subtitle = styled.span`
  color: #888888;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  width: 18rem;
  text-align: center;
  white-space: break-spaces;
  opacity: 0.65;
`

export const InputContainer = styled.div`
  padding-top: 3rem;
`

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 5rem 3.5rem 2rem 3.5rem;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  position: relative;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  ${customMedia.lessThan('560px')`
      padding: 5rem 0.8rem 2rem 0.8rem;
  `}
`

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FormInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => (props.spacing ? '2.5rem' : '0')};

  svg {
    margin-right: 12px;
  }
`

export const LoginButton = styled.button.attrs({
  type: 'submit',
  className: 'login-button',
})`
  background-color: var(--community-primary);
  margin-top: 2.5rem;
  border-radius: 50px;
  width: 16.2rem;
  height: 3.75rem;
  transition: 0.5s;

  border: none;

  &:hover {
    background-color: ${lighten(0.1, '#9671F5')};
    transform: scale(1.05);
  }

  &:active {
    vertical-align: top;
    padding: 10px 15px 8px;
  }
`

export const ButtonText = styled.span`
  color: #fff;
  font-family: Lato;
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
`

export const RememberMeText = styled.span`
  color: var(--grey);
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  margin-left: 8px;
`

export const ForgotPasswordText = styled.p`
  cursor: pointer;
  margin-top: 2.5rem;
  margin-bottom: 0;
  color: #b9b9b9;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
  text-align: center;

  &:hover {
    opacity: 0.9;
  }
`

export const RememberMePasswordContainer = styled.div``

export const FooterContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  ${customMedia.lessThan('1180px')`
    display: none;
  `}
`

export const CreateNewAccountContainer = styled.a.attrs({
  role: 'presentation',
})`
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  z-index: 10;

  ${customMedia.lessThan('1180px')`
     margin-bottom: 4rem;
  `}
`

export const CreateNewAccountText = styled.p`
  outline: none;
  color: #b9b9b9;
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    opacity: 0.9;
  }
`

export const YesButton = styled(DefaultButton).attrs({
  uppercase: true,
  color: '#9671F5',
  style: { padding: '0px 40px', marginRight: '10px' },
})``

export const NoButton = styled(DefaultButton).attrs({
  uppercase: true,
  color: '#9671F5',
  style: { padding: '0px 40px' },
})``

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``

export const AttentionText = styled.span``

export const ShowPasswordIcon = styled(IoMdEye).attrs({
  color: '#8e98a4',
  size: 27,
})`
  cursor: pointer;
`

export const HidePasswordIcon = styled(IoMdEyeOff).attrs({
  color: '#8e98a4',
  size: 27,
})`
  cursor: pointer;
`

export const PasswordTextField = styled(TextField).attrs({
  fullWidth: true,
  variant: 'outlined',
})``

export const EmailTextField = styled(TextField).attrs({
  fullWidth: true,
  variant: 'outlined',
  type: 'email',
})``
