/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Container, Spinner } from 'react-bootstrap'
import { InputAdornment } from '@material-ui/core'
import { FiUser } from 'react-icons/fi'
import { IoMdEye, IoMdEyeOff, IoIosLink } from 'react-icons/io'
import { AiOutlineMail } from 'react-icons/ai'
import history from '~/services/history'
import PreviewCommunitySignupSponsor from '~/components/Sponsor/PreviewCommunitySignupSponsor'
import useCommunityFunctions from '~/helper/communityFunctions'
import companyStoreValidator from '~/schemaValidation/companyStoreValidator'
import { signUpSponsorRequest as signUpSponsorRequestAction } from '~/store/modules/auth/actions'
import { getCommunityInformationsByInvitationLinkRequest as getCommunityInformationsByInvitationLinkRequestAction } from '~/store/modules/community/actions'
import { toggleLink } from '~/store/modules/link/actions'
import { prepareToSendSponsorObject } from '~/helper/formFunctions'
import InputPlacesAutocomplete from '~/components/InputPlacesAutocomplete'
import * as CONST from '~/helper/const'
import * as S from './styled'

export default function SignupModalSponsor({ handleCloseModal, showModal, invitationDirectLink }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { resetCommunityInformations } = useCommunityFunctions()
  const isLoading = useSelector(state => state.community.loading)
  const communityInformationsFromState = useSelector(state => state.community.communityInformations)
  const hideInvitationLink = useSelector(state => state.community.hideInvitationLink)
  const [communityInvitationLink, setCommunityInvitationLink] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [communityInvitationLinkIsValid, setCommunityInvitationLinkIsValid] = useState(false)
  const [name, setName] = useState('')
  const [manager, setManager] = useState({})
  const [adrFormatted, setAdrFormatted] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [receiveUpdates, setReceiveUpdates] = useState(true)
  const [hiddenPassword, setHiddenPassword] = useState(true)
  const [confirmPassword, setConfirmPassword] = useState('')
  const { register, handleSubmit, setValue, errors, control } = useForm({ validationSchema: companyStoreValidator })

  function resetForm() {
    resetCommunityInformations()
    setCommunityInvitationLink('')
    setName('')
    setManager([])
    setAdrFormatted('')
    setEmail('')
    setFirstName('')
    setLastName('')
    setPassword('')
    setReceiveUpdates(true)
    setHiddenPassword(true)
  }

  const handleReceiveUpdate = () => {
    setReceiveUpdates(!receiveUpdates)
    setValue('receiveUpdates', !receiveUpdates)
  }

  function onSubmit(data) {
    if (receiveUpdates === false) {
      return toast.warning(t('agreementTermsOfService'))
    }

    setSubmitting(true)
    dispatch(signUpSponsorRequestAction(data, setSubmitting))
    return setCommunityInvitationLinkIsValid(false)
  }

  const closeModal = () => {
    resetForm()
    handleCloseModal()
    setCommunityInvitationLinkIsValid(false)
  }

  const toggleShowPassword = () => {
    setHiddenPassword(!hiddenPassword)
  }

  useEffect(() => {
    dispatch(toggleLink('sponsor'))
  }, [])

  useEffect(() => {
    let typingTimer = null
    const val = communityInvitationLink
    clearTimeout(typingTimer)
    typingTimer = setTimeout(() => {
      if (val) {
        const invitationCode = communityInvitationLink.slice(communityInvitationLink.lastIndexOf('/') + 1)
        dispatch(getCommunityInformationsByInvitationLinkRequestAction(invitationCode))
        setValue('invitationCode', invitationCode)
        setCommunityInvitationLinkIsValid(true)
      }
    }, 2000)
    return () => clearTimeout(typingTimer)
  }, [communityInvitationLink])

  useEffect(() => {
    if (invitationDirectLink !== undefined) {
      dispatch(getCommunityInformationsByInvitationLinkRequestAction(invitationDirectLink))
      setValue('invitationCode', invitationDirectLink)
      setCommunityInvitationLink(invitationDirectLink)
      setCommunityInvitationLinkIsValid(true)
    }
  }, [invitationDirectLink])

  useEffect(() => {
    register({ name: 'adrLat' })
    register({ name: 'adrLng' })
    register({ name: 'adrStreetNumber' })
    register({ name: 'adrStreet' })
    register({ name: 'adrState' })
    register({ name: 'adrCity' })
    register({ name: 'adrZipcode' })
    register({ name: 'adrCountry' })
    register({ name: 'invitationCode' })
  }, [register, setValue])

  useEffect(() => {
    const error = Object.values(errors)
    const errorArray = Object.keys(error).map(i => error[i])
    if (errorArray.length <= 3) {
      errorArray.map(error => toast.error(error.message))
    }
  }, [errors])

  return (
    <>
      <S.ModalCustom show={showModal} onHide={closeModal}>
        <S.ModalHeader />
        <S.ModalBody>
          <Container fluid>
            <S.MainFormRow>
              <S.MainFormColumn md={12}>
                <S.Form onSubmit={handleSubmit(prepareToSendSponsorObject(onSubmit))}>
                  {communityInformationsFromState !== null ? (
                    <S.TitleContainer>
                      <S.Title>{t('didYouReceiveAnInvitation')}</S.Title>
                      <S.Subtitle className="animated fadeIn mt-1 mt-1">{t('thisCommunityJustInvitedYou')}</S.Subtitle>
                    </S.TitleContainer>
                  ) : (
                    <S.TitleContainer>
                      <S.Title>{t('didYouReceiveAnInvitation')}</S.Title>
                      <S.Subtitle className="animated fadeIn mt-1 mt-1">{t('pleasePastInvitationLink')}</S.Subtitle>
                    </S.TitleContainer>
                  )}
                  {!!communityInformationsFromState && (
                    <S.PreviewCommunitySignupSponsorContainer>
                      <PreviewCommunitySignupSponsor community={communityInformationsFromState} />
                    </S.PreviewCommunitySignupSponsorContainer>
                  )}
                  {!hideInvitationLink && (
                    <>
                      <S.InputTextField
                        marginTop
                        name="communityInvitationLink"
                        label={t('communityInvitationLink')}
                        value={communityInvitationLink}
                        inputRef={register({ required: true })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {isLoading ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                <IoIosLink color="#969FAA" size={27} onClick={toggleShowPassword} />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        onChange={e => setCommunityInvitationLink(e.target.value)}
                      />

                      <S.ErrorMessageContainer error={errors.invitationCode}>
                        <S.ErrorMessage error={errors.invitationCode}>
                          {errors.invitationCode && t('defaultValidation', { inputName: t('communityInvitationLink') })}
                        </S.ErrorMessage>
                      </S.ErrorMessageContainer>
                    </>
                  )}
                  <S.Title className="mt-4">{t('registerYourCompany')}</S.Title>
                  <S.InputContainer>
                    <S.InputTextField
                      name="name"
                      label={t('companyName')}
                      value={name}
                      inputRef={register}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <FiUser color="#969FAA" size={22} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={e => setName(e.target.value)}
                    />
                    <S.ErrorMessageContainer error={errors.name}>
                      <S.ErrorMessage error={errors.name}>
                        {errors.name && t('defaultValidation', { inputName: t('companyName') })}
                      </S.ErrorMessage>
                    </S.ErrorMessageContainer>
                  </S.InputContainer>
                  <S.AddressContainer>
                    <InputPlacesAutocomplete
                      adrFormatted={adrFormatted}
                      setAdrFormatted={setAdrFormatted}
                      register={register}
                      setValue={setValue}
                      errors={errors}
                    />
                  </S.AddressContainer>

                  <S.Title className="mt-4 mb-0">{t('communitySponsoredManager')}</S.Title>
                  <S.Subtitle className="mt-2">{t('theseCrededentialAccess')}</S.Subtitle>
                  <S.InputContainer>
                    <S.InputTextField
                      name="manager.email"
                      label={t('emailAddress')}
                      type="email"
                      value={manager.email}
                      inputRef={register}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <AiOutlineMail color="#969FAA" size={22} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </S.InputContainer>
                  <S.ErrorMessageContainer error={errors?.manager?.email}>
                    <S.ErrorMessage error={errors?.manager?.email}>
                      {errors?.manager?.email && t('defaultValidation', { inputName: t('emailAddress') })}
                    </S.ErrorMessage>
                  </S.ErrorMessageContainer>

                  <S.StyledRow>
                    <S.PhoneColumn>
                      <S.InputTitle>{t('phoneNumber')}</S.InputTitle>
                      <Controller
                        as={<PhoneInput country="it" placeholder={t('phoneNumber')} className="phone-number" />}
                        name="manager.phoneNumber"
                        control={control}
                      />
                      <S.ErrorMessageContainer error={errors?.manager?.phoneNumber}>
                        <S.ErrorMessage error={errors?.manager?.phoneNumber}>
                          {errors?.manager?.phoneNumber && t('defaultValidation', { inputName: t('phoneNumber') })}
                        </S.ErrorMessage>
                      </S.ErrorMessageContainer>
                    </S.PhoneColumn>
                  </S.StyledRow>

                  <S.InputContainer>
                    <S.InputTextField
                      name="manager.firstName"
                      label={t('firstName')}
                      value={manager.firstName}
                      inputRef={register}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <FiUser color="#969FAA" size={22} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </S.InputContainer>
                  <S.ErrorMessageContainer error={errors?.manager?.firstName}>
                    <S.ErrorMessage error={errors?.manager?.firstName}>
                      {errors?.manager?.firstName && t('defaultValidation', { inputName: t('firstName') })}
                    </S.ErrorMessage>
                  </S.ErrorMessageContainer>
                  <S.InputContainer>
                    <S.InputTextField
                      name="manager.lastName"
                      label={t('lastName')}
                      value={manager.lastName}
                      inputRef={register}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <FiUser color="#969FAA" size={22} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </S.InputContainer>
                  <S.ErrorMessageContainer error={errors?.manager?.lastName}>
                    <S.ErrorMessage error={errors?.manager?.lastName}>
                      {errors.manager &&
                        errors.manager.lastName &&
                        t('defaultValidation', { inputName: t('lastName') })}
                    </S.ErrorMessage>
                  </S.ErrorMessageContainer>
                  <S.InputContainer>
                    <S.InputTextField
                      name="manager.password"
                      label={t('createPassword')}
                      type={hiddenPassword ? 'password' : 'text'}
                      value={manager.password}
                      inputRef={register}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {hiddenPassword ? (
                              <IoMdEyeOff
                                style={{ cursor: 'pointer' }}
                                color="#969FAA"
                                size={27}
                                onClick={toggleShowPassword}
                              />
                            ) : (
                              <IoMdEye
                                style={{ cursor: 'pointer' }}
                                color="#969FAA"
                                size={27}
                                onClick={toggleShowPassword}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </S.InputContainer>
                  <S.ErrorMessageContainer error={errors?.manager?.password}>
                    <S.ErrorMessage error={errors.manager && errors.manager.password}>
                      {errors.manager && errors.manager.password && t('passwordValidation')}
                    </S.ErrorMessage>
                  </S.ErrorMessageContainer>
                  <S.InputContainer>
                    <S.InputTextField
                      name="manager.confirmPassword"
                      label={t('confirmPassword')}
                      value={manager.confirmPassword}
                      type={hiddenPassword ? 'password' : 'text'}
                      inputRef={register}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {hiddenPassword ? (
                              <IoMdEyeOff
                                style={{ cursor: 'pointer' }}
                                color="#969FAA"
                                size={27}
                                onClick={toggleShowPassword}
                              />
                            ) : (
                              <IoMdEye
                                style={{ cursor: 'pointer' }}
                                color="#969FAA"
                                size={27}
                                onClick={toggleShowPassword}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onChange={e => setConfirmPassword(e.target.value)}
                    />
                  </S.InputContainer>
                  <S.ErrorMessageContainer error={errors?.manager?.confirmPassword}>
                    <S.ErrorMessage error={errors.manager && errors.manager.confirmPassword}>
                      {errors.manager && errors.manager.confirmPassword && t('passwordsDontMatch')}
                    </S.ErrorMessage>
                  </S.ErrorMessageContainer>
                  <S.CheckboxContainer>
                    <S.CustomCheckbox checked={receiveUpdates} onClick={handleReceiveUpdate} />
                    <S.TermsOfServiceContainer>
                      <S.TermsOfService>
                        <Trans
                          i18nKey="agreeTermsOfService"
                          t={t}
                          components={[
                            <S.TermsOfServiceHighlight key="1" onClick={() => window.open(CONST.termsOfServiceLink)} />,
                            <S.TermsOfServiceHighlight key="2" onClick={() => window.open(CONST.privacyPolicyLink)} />,
                          ]}
                        />
                      </S.TermsOfService>
                    </S.TermsOfServiceContainer>
                  </S.CheckboxContainer>
                  <S.SignupButton type="submit" fullWidth disabled={submitting}>
                    {submitting ? <Spinner animation="border" size="sm" /> : t('signUp')}
                  </S.SignupButton>
                  <S.BorderContainer>
                    <S.Border />
                  </S.BorderContainer>
                  <S.LoginSection>
                    <S.AccountIndication>{t('alreadyHaveAccount')}</S.AccountIndication>
                    <S.Login onClick={() => history.push('/login')}>{t('secondarylogin')}</S.Login>
                  </S.LoginSection>
                </S.Form>
              </S.MainFormColumn>
            </S.MainFormRow>
          </Container>
        </S.ModalBody>
        <S.ModalFooter />
      </S.ModalCustom>
    </>
  )
}
