/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect, useCallback } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import { InputAdornment } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import Select from 'react-select'
import ErrorMessage from '~/components/Shared/ErrorMessage'
import { DefaultUserProfileImage } from '~/util/defaultImages'
import ModalCropUseProfileImage from '~/components/Sponsor/Modals/ModalCropUseProfileImage'
import { getLoggedUserProfileInformations, updateUserProfile } from '~/services/requests'
import { mountSelectObjectWithOnlyId } from '~/helper/formFunctions'
import userProfileValidator from '~/schemaValidation/userProfileValidator'
import * as CONST from '~/helper/const'
import * as S from './styled'

export default function ModalEditUserProfile({ handleCloseModal, showModal }) {
  const { t } = useTranslation()
  const { handleSubmit, errors, control } = useForm({ validationSchema: userProfileValidator })
  const userType = useSelector(state => state.user.type)
  const [loading, setLoading] = useState(false)
  const [userProfileInformations, setUserProfileInformations] = useState(false)
  const { firstName, lastName, email, gender, phoneNumber, userImageId, userImageUrl } = userProfileInformations || {}
  const [hiddenPassword, setHiddenPassword] = useState(true)
  const [showModalCropProfileImage, setShowModalCropProfileImage] = useState(false)
  const [userProfileImageUrl, setUserProfileImageUrl] = useState(userImageUrl)
  const [userProfileImageId, setUserProfileImageId] = useState(userImageId)

  const genderOptions = [
    { value: 0, label: t('unknown') },
    { value: 1, label: t('male') },
    { value: 2, label: t('female') },
    { value: 3, label: t('other') },
  ]

  function onSubmit(data) {
    data.userImageId = userProfileImageId
    data.gender = data.gender?.value
    updateUserProfile(data, handleCloseModal)
  }

  const closeModal = () => {
    setUserProfileImageId(null)
    setUserProfileImageUrl(null)
    handleCloseModal()
  }

  const toggleShowPassword = () => {
    setHiddenPassword(!hiddenPassword)
  }

  const getLoggedUserInformations = useCallback(async () => {
    const response = await getLoggedUserProfileInformations(setLoading)
    setUserProfileInformations(response.data)
    setLoading(false)
  }, [setLoading])

  useEffect(() => {
    if (showModal) {
      getLoggedUserInformations()
    }
  }, [getLoggedUserInformations, showModal])

  useEffect(() => {
    setUserProfileImageUrl(userProfileInformations?.userImageUrl)
    setUserProfileImageId(userProfileInformations?.userImageId)
  }, [userProfileInformations])

  return loading ? (
    <S.SpinnerContainer>
      <S.CustomSpinner />
    </S.SpinnerContainer>
  ) : (
    <S.ModalCustom show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.Title>{t('editUserProfile')}</S.Title>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={CONST.modalBodyStyleDefault}>
        <S.UserProfilePictureContainer>
          <S.UserProfilePicture src={userProfileImageUrl || DefaultUserProfileImage} />
          <S.EditSponsorProfileButton onClick={() => setShowModalCropProfileImage(true)} />
        </S.UserProfilePictureContainer>
        <S.FormContainer>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <S.SectionRow spacing>
              <S.Column>
                <S.InputContainer>
                  <S.InputLabel>{t('firstName')}</S.InputLabel>
                  <S.IsRequired userType={userType}>*</S.IsRequired>
                </S.InputContainer>
                <Controller
                  id="user-first-name"
                  as={
                    <S.InputTextField
                      placeholder={t('typeYour', { inputName: t('firstName').toLowerCase() })}
                      inputProps={{ maxLength: 80 }}
                    />
                  }
                  name="firstName"
                  control={control}
                  defaultValue={firstName}
                />
                <ErrorMessage errors={errors} name="firstName" />
              </S.Column>

              <S.Column>
                <S.InputContainer>
                  <S.InputLabel>{t('lastName')}</S.InputLabel>
                  <S.IsRequired userType={userType}>*</S.IsRequired>
                </S.InputContainer>
                <Controller
                  id="user-last-name"
                  as={
                    <S.InputTextField
                      placeholder={t('typeYour', { inputName: t('lastName').toLowerCase() })}
                      inputProps={{ maxLength: 80 }}
                    />
                  }
                  name="lastName"
                  control={control}
                  defaultValue={lastName}
                />
                <ErrorMessage errors={errors} name="lastName" />
              </S.Column>
            </S.SectionRow>

            <S.SectionRow>
              <S.EmailColumn>
                <S.InputContainer>
                  <S.InputLabel>{t('emailAddress')}</S.InputLabel>
                  <S.IsRequired userType={userType}>*</S.IsRequired>
                </S.InputContainer>
                <Controller
                  id="user-email"
                  as={<S.InputTextField placeholder={t('typeYour', { inputName: t('emailAddress').toLowerCase() })} />}
                  name="email"
                  type="email"
                  control={control}
                  defaultValue={email}
                />
                <ErrorMessage errors={errors} name="email" />
              </S.EmailColumn>
            </S.SectionRow>

            <S.SectionRow>
              <S.Column>
                <S.InputContainer>
                  <S.InputLabel>{t('gender')}</S.InputLabel>
                  <S.IsRequired userType={userType}>*</S.IsRequired>
                </S.InputContainer>
                <Controller
                  as={
                    <Select
                      styles={CONST.reactSelectStyleDefault}
                      placeholder={t('placeholderSelect')}
                      options={genderOptions}
                      className="select-gender"
                    />
                  }
                  name="gender"
                  control={control}
                  onChange={([selected]) => {
                    return selected
                  }}
                  defaultValue={mountSelectObjectWithOnlyId(gender, genderOptions)}
                />
                <ErrorMessage errors={errors} name="gender" />
              </S.Column>

              <S.Column>
                <S.InputContainer>
                  <S.InputLabel>{t('phoneNumber')}</S.InputLabel>
                  <S.IsRequired userType={userType}>*</S.IsRequired>
                </S.InputContainer>
                <Controller
                  as={<PhoneInput country="it" />}
                  name="phoneNumber"
                  control={control}
                  defaultValue={phoneNumber}
                />
                <ErrorMessage errors={errors} name="phoneNumber" />
              </S.Column>
            </S.SectionRow>

            <S.SectionRow>
              <S.Column>
                <S.InputContainer>
                  <S.InputLabel>{t('password')}</S.InputLabel>
                  <S.IsRequired userType={userType}>*</S.IsRequired>
                </S.InputContainer>
                <Controller
                  id="user-password"
                  as={
                    <S.InputTextField
                      placeholder={t('typeYour', { inputName: t('password').toLowerCase() })}
                      inputProps={{ autoComplete: 'new-password', maxLength: 34 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {hiddenPassword ? (
                              <IoMdEyeOff
                                style={{ cursor: 'pointer' }}
                                color="#969FAA"
                                size={27}
                                onClick={toggleShowPassword}
                              />
                            ) : (
                              <IoMdEye
                                style={{ cursor: 'pointer' }}
                                color="#969FAA"
                                size={27}
                                onClick={toggleShowPassword}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                  name="password"
                  type={hiddenPassword ? 'password' : 'text'}
                  control={control}
                />
                <ErrorMessage errors={errors} name="password" />
              </S.Column>

              <S.Column>
                <S.InputContainer>
                  <S.InputLabel>{t('confirmPassword')}</S.InputLabel>
                  <S.IsRequired userType={userType}>*</S.IsRequired>
                </S.InputContainer>
                <Controller
                  id="user-password-confirmation"
                  as={
                    <S.InputTextField
                      placeholder={t('typeYour', { inputName: t('password').toLowerCase() })}
                      inputProps={{ autoComplete: 'new-password', maxLength: 34 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {hiddenPassword ? (
                              <IoMdEyeOff
                                style={{ cursor: 'pointer' }}
                                color="#969FAA"
                                size={27}
                                onClick={toggleShowPassword}
                              />
                            ) : (
                              <IoMdEye
                                style={{ cursor: 'pointer' }}
                                color="#969FAA"
                                size={27}
                                onClick={toggleShowPassword}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                  name="passwordConfirmation"
                  type={hiddenPassword ? 'password' : 'text'}
                  control={control}
                />
                <ErrorMessage errors={errors} name="passwordConfirmation" />
              </S.Column>
            </S.SectionRow>
          </S.Form>
        </S.FormContainer>
      </Modal.Body>

      <S.ModalFooter>
        <S.CancelButton text={t('cancel')} onClick={handleCloseModal} />
        <S.SaveButton data-cy="save-user-profile" text={t('save')} large icon={<S.SaveIcon />} />
      </S.ModalFooter>

      <ModalCropUseProfileImage
        userProfileInformations={userProfileInformations}
        handleCloseModal={() => setShowModalCropProfileImage(false)}
        showModal={showModalCropProfileImage}
        setUserProfileImageUrl={setUserProfileImageUrl}
        setUserProfileImageId={setUserProfileImageId}
      />
    </S.ModalCustom>
  )
}
