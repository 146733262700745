import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import { IoMdCloseCircle } from 'react-icons/io'

export const IconContainer = styled.div`
  .close-button {
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: 10px;

    &:hover {
      opacity: 0.7;
    }
  }
`

export const CloseCommunityPreviewButton = styled(IoMdCloseCircle).attrs({
  className: 'close-button',
  color: '#969FAA',
  size: 26,
})``

export const BannerAndLogoColumn = styled(Col)`
  padding: 0px !important;
  padding-left: 16px !important;
`

export const CommunityInformationsColumn = styled(Col)`
  padding: 0px !important;
  padding-right: 16px !important;
`

export const CommunityDescription = styled.span`
  color: #858588;
  font-family: Lato;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  text-align: center;
  margin-top: 13px;
  padding-left: 10px;
  padding-right: 5px;

  width: 170px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
`

export const ContainerBannerImage = styled.div`
  position: relative;
  background-color: #f2f2f2;
  height: 110px;
  border-radius: 5px;
`

export const CommunityUserAvatarSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  margin-left: 10px;
`

export const ContainerPreview = styled.div`
  padding: 10px;
  position: relative;
  background-color: #ffffff;
  height: 110px;
  border-radius: 5px;
  border: 1px solid rgba(214, 214, 214, 0.45);
`

export const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  position: relative;
`

export const CommunityLogo = styled.img`
  border-radius: 100%;
  width: 62px;
  height: 62px;
  position: absolute;
  left: 30px;
  bottom: 0px;
  top: 40px;
  border: 4px solid #fff;
  border-radius: 50%;
  background-color: #fff;
`

export const UserProfileImage = styled.img.attrs({
  alt: 'User Profile Image',
})`
  border-radius: 100px;
  width: 42px;
  height: 42px;
  object-fit: cover;
`

export const Username = styled.span`
  text-align: center;
  color: #5a6978;
  font-family: Lato;
  font-size: 11px;
  font-weight: 400;
`

export const UserInformationsContainer = styled.div`
  display: flex;
`

export const CommunityName = styled.p`
  padding-left: 10px;
  margin: 0px;
  color: #47525e;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: nowrap;
`
