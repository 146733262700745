import styled from 'styled-components'
import Row from 'react-bootstrap/Row'

export const CardBody = styled.div`
  margin-top: 20px;
  width: 100%;
  padding-bottom: 25px;

  .icon-section {
    display: flex;
    align-items: center;
    padding: 0px 30px 8px 30px;

    p {
      margin: 0px;
      margin-left: 30px;
      color: var(--input-title);
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
  }
`

export const WelcomeAdBody = styled.div`
  margin-top: 20px;
  padding: 20px 20px 35px 20px;
  background: #ffffff;
  border-radius: 10px;
  margin-right: 15px !important;
  margin-left: 15px !important;
  width: 100%;
  height: auto;
  box-shadow: 1px 3px 12px 3px #e2e5e9;

  .ad-name {
    margin: 0px;
    color: var(--input-title);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  .product-buttons {
    padding-left: 0px !important;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: baseline;
    flex-wrap: wrap;
  }

  .chart-title {
    color: #5a6978;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 16px;
  }
`

export const ContainerWelcomeAdImage = styled.div`
  border-radius: 5px;
  margin-right: -15px;
`

export const WelcomeAdImage = styled.img`
  width: 100%;
  object-fit: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  aspect-ratio: ${({ type }) => (type === 'bottomBanner' ? '376/52' : '113/54')};
`

export const AnalyticsCardRow = styled(Row)`
  margin: -10px 16px 0px 16px !important;
`

export const AnalyticsAndImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const AnalyticsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 50%;
  gap: 10px;
`
