import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { MdArrowBack } from 'react-icons/md'
import { customMedia } from '~/styles/global'

export const CustomContainer = styled(Container).attrs({
  className: 'animated fadeIn',
})``

export const CampaignRevisionContainer = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0 30px 20px 30px;
`

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`

export const FormatsSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``

export const FormatColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  justify-content: center;
`

export const FormatTitleColumn = styled(Col).attrs({
  md: 3,
})``

export const FormatTimingColumn = styled(Col).attrs({
  md: 3,
})`
  display: flex;
  justify-content: center;
`

export const StatusColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ActionsColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Subtitle = styled.p`
  margin: 0px;
  width: 66%;
  color: #635b5b;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  padding: 0px 15px 5px 15px;
`

export const PageContainer = styled.div`
  width: auto;
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 15px;
`

export const CommunityContainer = styled.div`
  display: flex;
  margin-left: 10px;
`

export const CommunityInformationsContainer = styled.div`
  padding: 35px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;

  ${customMedia.lessThan('1430px')`
     flex-direction: column;
     justify-content: center;
     padding: 35px 0px 40px 0px;
  `}
`

export const CommunityInformations = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CommunityLogo = styled.img`
  border-radius: 100px;
  width: 74px;
  height: 74px;
`

export const CampaignFormatsTitleContainer = styled.div`
  margin-top: 20px;
`

export const CommunityNameAndLocationSection = styled.div`
  margin-top: 6px;
  span {
    font-size: 18px;
    width: 100%;
  }
`
export const CommunityLocation = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  margin-top: -6px;
`

export const CampaignManagementContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to right, #00a2ff 40%, #0090de 100%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 220px;
  padding: 0 15px;
`

export const HeaderBackContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const HeaderRouteText = styled.p`
  margin: 0px;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 10px;
  letter-spacing: 0.5px;
`

export const HeaderBackText = styled.p`
  margin: 0px;
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 6px;
`

export const HeaderVerticalLine = styled.p`
  margin: 0px;
  border-left: 2px solid var(--sponsor-dark);
  height: 20px;
  margin-left: 18px;
`

export const ButtonsSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 48px;
  margin-right: 16px;
  margin-bottom: 24px;
  padding: 0px 8px;
`

export const CampaignInformations = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${customMedia.lessThan('1430px')`
     margin-top: 24px;
  `}
`

export const CampaignContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CampaignBudgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CampaignInformationTitle = styled.p`
  margin: 0px;
  color: #635b5b;
  font-family: Lato;
  font-size: 15px;
  padding: 0px 15px 0px 15px;
`

export const CampaignInformationSubtitle = styled.p`
  margin: 0px;
  color: #484848;
  font-family: Lato;
  font-size: min(1.4vw, 23px);
  font-weight: 700;
`

export const CampaignInformationYear = styled(CampaignInformationSubtitle)`
  font-size: min(1.4vw, 16px);
  position: absolute;
  bottom: 10px;

  ${customMedia.lessThan('1430px')`
     bottom: -20px;
  `}
`

export const SearchInputContainer = styled.div`
  margin: 20px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 35px;
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const BodyContainer = styled.div`
  padding: 40px 0px 10px 0px;
`

export const ProductsTabContainer = styled.div`
  margin-top: 30px;
`

export const GridTitleContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
`

export const GridTitle = styled.p`
  margin: 0px;
  color: var(--sponsor-dark);
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: ${props => (props.margin ? '20px' : '0px')};
`

export const ProgressBarsContainer = styled.div.attrs({
  className: 'animated fadeIn',
})`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 24px;
`

export const PopoverContent = styled.div`
  width: 200px;
  padding: 8px 14px 14px 14px;
`

export const BorderContainer = styled.div`
  margin-top: 10px;
`

export const ProductsContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #f2f2f2;
  }
`

export const ProductTitle = styled.span`
  color: #737475;
  font-family: Roboto;
  font-size: 14px;
`

export const BackIcon = styled(MdArrowBack).attrs({
  size: 25,
  color: '#FFF',
})``

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 22px;
`

export const StepContainerTitle = styled.p`
  color: #ffffff;
  font-family: Montserrat;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: bold;
`

export const CampaignName = styled.div`
  margin-top: 2px;
  max-width: 200px;
  word-break: normal;
  color: var(--sponsor-primary);
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
`

export const CampaignNameLabel = styled.div`
  max-width: 200px;
  word-break: normal;
  color: #47525e;
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  line-height: 18px;
`

export const CommunityName = styled.p`
  margin: 0px;
  color: var(--community-primary);
  font-family: Lato;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.5px;
  width: 12vw;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`

export const CommunityNameLabel = styled.p`
  margin: 0px;
  margin-top: 6px;
  max-width: 200px;
  word-break: normal;
  color: #47525e;
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  line-height: 18px;
`

export const MonthTabContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  padding-bottom: 24px;
`

export const MonthTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  max-width: 120px;
  min-width: 90px;
  height: 44px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-image: ${props => (props.isActive ? '#FFF' : 'linear-gradient(to right, #00A2FF 50%, #0090de 100%)')};
  box-shadow: 0px -2px 4px 0px #c5cad3;
  box-shadow: 0px -2px 4px 0px rgb(0, 0, 0, 0.15);
  color: ${props => (props.isActive ? '#272727' : '#FFF')};
  transition: all 400ms ease;

  &:active {
    position: relative;
    top: 2px;
  }
`
