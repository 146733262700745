import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import { AiOutlinePlaySquare } from 'react-icons/ai'
import Spinner from 'react-bootstrap/Spinner'

export const UploadSection = styled.div`
  display: flex;
  margin-top: 24px;
`

export const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgba(159, 159, 159, 0.7);
  border-radius: 5px;
  padding: 5px;
  width: 50%;
  height: 143px;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    cursor: unset;
    opacity: 0.4;
  }
`

export const VideoUploadIcon = styled(AiOutlinePlaySquare).attrs({
  size: 28,
})``

export const UploadText = styled.p`
  margin-top: 5px;
  color: var(--text-primary);
  font-family: Lato;
  font-size: 14px;
  line-height: 15px;
  white-space: break-spaces;
`

export const PreviewMediaContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 26px;
`

export const PreviewMedia = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 7px 4px rgba(0, 0, 0, 0.35);
  aspect-ratio: 113/54;
`

export const CloseIcon = styled(MdClose).attrs({
  size: 18,
  color: '#47525E',
})`
  position: absolute;
  cursor: pointer;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border: 2px solid #47525e;
  border-radius: 50%;
  top: -8px;
  right: -5px;

  &:hover {
    opacity: 0.9;
  }
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
`

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``

export const InputAddImage = styled.input.attrs({
  type: 'file',
  value: '',
  accept: 'image/x-png,image/gif,image/jpeg',
  style: { display: 'none' },
})``
