import styled from 'styled-components'
import Button from '~/components/Shared/Button'
import { customMedia } from '~/styles/global'

export const CardContainer = styled.div`
  margin-top: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  width: 270px;
  height: 320px;
  border-radius: 5px;
`

export const CardContent = styled.div`
  position: relative;
`

export const CardBody = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.7rem 1.25rem 1.25rem 1.25rem;
`

export const CommunityInformations = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90px;
  border-bottom-width: 3px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
`

export const CommunityName = styled.span`
  text-align: center;
  color: #47525e;
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  line-height: 18px;
  width: 210px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  ${customMedia.lessThan('huge')`
    font-size: 15px;
    width: 150px;
  `}
`

export const CommunityLocation = styled.span`
  color: #87919a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-align: center;

  ${customMedia.lessThan('huge')`
    font-size: 13px;
    width: 180px;
  `}
`

export const CommunityDescription = styled.span`
  color: #858588;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  width: 195px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  ${customMedia.lessThan('1370px')`
    font-size: 13px;
    width: 170px;
  `}
`

export const CommunityDescriptionContainer = styled.div`
  height: 30px;
  text-align: center;
`

export const CardFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;
`

export const ContainerBannerImage = styled.img.attrs({
  alt: 'Community Banner',
})`
  width: 100%;
  height: 90px;
  object-fit: cover;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  z-index: 1;
`

export const CommunityLogo = styled.img.attrs({
  alt: 'Community Logo',
})`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`

export const ContainerCommunityLogo = styled.div`
  position: absolute;
  border-radius: 90%;
  width: 70px;
  height: 70px;
  z-index: 2;
  top: calc(-79px / 2);
  left: calc(50% - (62px / 2));
  border: 3px solid #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
`

export const CommunityUserImage = styled.img.attrs({
  alt: 'User Avatar',
})`
  position: absolute;
  border: 2px solid #fff;
  border-radius: 90%;
  background-color: #fff;
  width: 28px;
  height: 28px;
  top: 50px;
  left: 0px;
  z-index: 3;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  object-fit: cover;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
`

export const SelectButton = styled(Button).attrs({
  style: { width: '90%' },
  fontSize: '15px',
  type: 'submit',
  height: '35px',
  color: '#00A2FF',
  className: 'select-community',
})``
