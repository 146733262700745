/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import ModalCreatePost from '~/components/Sponsor/Modals/Post/ModalCreatePost'
import ModalCreateWeb from '~/components/Sponsor/Modals/Webpage/ModalCreateWeb'
// import ModalCreateDocument from '~/components/Sponsor/Modals/Document/ModalCreateDocument'
import ModalCreateVideo from '~/components/Sponsor/Modals/Video/ModalCreateVideo'
import { reactSelectStyleDefault, contentAvailable } from '~/helper/const'
import Border from '~/components/Shared/Border'
import * as S from './styled'

export default function ModalContentDropdown({ handleCloseModal, showModal, campaignProductId }) {
  const { t } = useTranslation()
  const { handleSubmit, setValue } = useForm()
  const [content, setContent] = useState(0)

  function onSubmit() {
    setContent(0)
  }

  function handleCloseModalAndResetContent() {
    setContent(0)
    handleCloseModal()
  }

  function handleContentChange(event) {
    setValue('content', event.value)
    setContent(event.value)
  }

  return (
    <>
      {content === 0 && (
        <ProductsModal
          modalTitle={t('newContent')}
          handleCloseModal={handleCloseModalAndResetContent}
          showModal={showModal}
          leftColumn={
            <form onSubmit={handleSubmit(onSubmit)}>
              <S.FormContainer>
                <S.InputTitle>{t('selectTypeContent')}</S.InputTitle>
                <Select
                  styles={reactSelectStyleDefault}
                  options={contentAvailable}
                  value={content.value}
                  onChange={handleContentChange}
                />
                <S.BorderContainer>
                  <Border />
                </S.BorderContainer>
              </S.FormContainer>
            </form>
          }
          rightColumn={
            <S.Container>
              <S.DeviceScreen />
              <S.DeviceButton />
            </S.Container>
          }
        />
      )}
      {content === 1 && (
        <ModalCreateWeb
          campaignProductId={campaignProductId}
          handleCloseModal={handleCloseModal}
          showModal={showModal}
          setContent={setContent}
        />
      )}
      {content === 2 && (
        <ModalCreatePost
          campaignProductId={campaignProductId}
          handleCloseModal={handleCloseModal}
          showModal={showModal}
          setContent={setContent}
        />
      )}
      {content === 3 && (
        <ModalCreateVideo
          campaignProductId={campaignProductId}
          handleCloseModal={handleCloseModal}
          showModal={showModal}
          setContent={setContent}
        />
      )}
      {/* {content === 4 && (
        <ModalCreateDocument
          campaignProductId={campaignProductId}
          handleCloseModal={handleCloseModal}
          showModal={showModal}
          setContent={setContent}
        />
      )} */}
    </>
  )
}
