import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { currencyFormat } from '~/helper/helperFunctions'
import RadioCheckbox from '~/components/Shared/RadioCheckbox'
import * as S from './styled'

const WithSpecialOffer = ({ packageInformations, communityCurrencySymbol }) => {
  const { monthlyPrice: packagePrice, specialOfferPrice } = packageInformations

  const { t } = useTranslation()

  return (
    <div className="d-flex flex-column">
      <div>
        <S.PackagePriceWithoutDiscount>
          {`${communityCurrencySymbol} ${currencyFormat(packagePrice)}`}
        </S.PackagePriceWithoutDiscount>

        <S.PackageMonthly>{` / ${t('monthly')}`}</S.PackageMonthly>
      </div>

      <S.PackagePriceDiscountContainer>
        <S.PackagePrice>{`${communityCurrencySymbol} ${currencyFormat(specialOfferPrice)}`}</S.PackagePrice>

        <S.PackageMonthly>{` / ${t('monthly')}`}</S.PackageMonthly>
      </S.PackagePriceDiscountContainer>
    </div>
  )
}

const WithoutSpecialOffer = ({ packageInformations, communityCurrencySymbol }) => {
  const { monthlyPrice: packagePrice } = packageInformations

  const { t } = useTranslation()

  return (
    <>
      <S.PackagePrice>{`${communityCurrencySymbol} ${currencyFormat(packagePrice)}`}</S.PackagePrice>
      <S.PackageMonthly>{` / ${t('monthly')}`}</S.PackageMonthly>
    </>
  )
}

const OffersOptionsFactory = {
  'with-special-offer': WithSpecialOffer,
  'without-special-offer': WithoutSpecialOffer,
}

const OffersKeyFactory = {
  true: 'with-special-offer',
  false: 'without-special-offer',
}

export default function SelectPackageForm({
  checkedPackage,
  packageInformations,
  onCheckPackage,
  communityCurrencySymbol,
  ...props
}) {
  const { onChange } = props
  const { name: packageName, hasSpecialOffer } = packageInformations

  const CurrentOfferKey = useMemo(() => OffersKeyFactory[hasSpecialOffer === 1], [hasSpecialOffer])

  const OfferComponent = OffersOptionsFactory[CurrentOfferKey]

  return (
    <S.FormSectionContainer>
      <S.PackageContainer>
        <S.PackageSelectionContainer>
          <S.PackageNameAndPrice>
            <S.PackageName>{packageName}</S.PackageName>

            <S.PackagePriceContainer>
              <OfferComponent
                packageInformations={packageInformations}
                communityCurrencySymbol={communityCurrencySymbol}
              />
            </S.PackagePriceContainer>
          </S.PackageNameAndPrice>

          <RadioCheckbox
            className="radio-select-package"
            checked={checkedPackage}
            onChange={e => {
              if (onCheckPackage) {
                onCheckPackage()
              }

              return onChange?.(e)
            }}
            value={checkedPackage}
          />

          {hasSpecialOffer === 1 ? <S.SpecialOfferImage /> : null}
        </S.PackageSelectionContainer>
      </S.PackageContainer>
    </S.FormSectionContainer>
  )
}
