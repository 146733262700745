import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { WhiteRadioButton } from '~/helper/const'
import * as S from './styled'

export default function Survey({ question, optionsChecked }) {
  return (
    <S.Container>
      <S.SurveyHeader>
        <S.SurveyIcon />
        <S.SurveyLabel>Sponsored survey</S.SurveyLabel>
      </S.SurveyHeader>
      <S.EntityInformations>
        <S.EntityLogo />
        <S.EntityNameAndLabelContainer>
          <S.EntityName>Apple italy</S.EntityName>
          <S.TrustedSponsorLabel>trusted sponsor</S.TrustedSponsorLabel>
        </S.EntityNameAndLabelContainer>
      </S.EntityInformations>
      <S.SurveyQuestionContainer>
        <S.SurveyQuestion>{question || 'Survey question'}</S.SurveyQuestion>

        {optionsChecked.map(
          optionChecked =>
            optionChecked?.isChecked &&
            optionChecked.option && (
              <S.RadioButtonContainer key={optionChecked.option}>
                <FormControlLabel
                  style={{ margin: '0px', color: '#D6D6D6' }}
                  label={optionChecked.option}
                  control={<WhiteRadioButton disabled />}
                />
              </S.RadioButtonContainer>
            ),
        )}
      </S.SurveyQuestionContainer>
      <S.SurveyTiming>2 hours ago</S.SurveyTiming>
    </S.Container>
  )
}
