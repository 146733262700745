import styled from 'styled-components'

export const ErrorMessage = styled.div`
  p {
    color: #ea343e;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 600;
    opacity: 0.9;
    margin-top: 3px;
    margin-bottom: 0px;
    text-align-last: start;
  }

  p::before {
    display: ${props => (props.error === undefined ? 'none' : 'inline')};
    content: '⚠ ';
  }
`

export const Container = styled.div``

export const GoogleLogo = styled.img.attrs({
  alt: 'Google Logo',
  src: '/google-logo.png',
})`
  width: 26px;
  height: 26px;
`
