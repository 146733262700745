import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { currencyFormat } from '~/helper/helperFunctions'
import RadioCheckbox from '~/components/Shared/RadioCheckbox'
import FormatDetailsCard from '../../FormatDetailsCard'
import * as S from './styled'

const WithSpecialOffer = ({ packageInformations, communityCurrencySymbol }) => {
  const { monthlyPrice: packagePrice, specialOfferDuration, specialOfferPrice } = packageInformations

  const { t } = useTranslation()

  return (
    <S.PackageDiscountContainer>
      <S.PackageSpecialOffer>
        {t('specialOfferTitle', { monthDuration: specialOfferDuration?.title })}
      </S.PackageSpecialOffer>

      <S.PackageWithoutDiscountContainer>
        <S.PackagePriceWithoutDiscount>
          {`${communityCurrencySymbol} ${currencyFormat(packagePrice)}`}
        </S.PackagePriceWithoutDiscount>

        <S.PackageMonthly>{` / ${t('monthly')}`}</S.PackageMonthly>
      </S.PackageWithoutDiscountContainer>

      <S.PackageWithDiscountContainer>
        <S.PackagePriceWithDiscount>
          {`${communityCurrencySymbol} ${currencyFormat(specialOfferPrice)}`}
        </S.PackagePriceWithDiscount>

        <S.PackageMonthly>{` / ${t('monthly')}`}</S.PackageMonthly>
      </S.PackageWithDiscountContainer>
    </S.PackageDiscountContainer>
  )
}

const WithoutSpecialOffer = ({ packageInformations, communityCurrencySymbol }) => {
  const { monthlyPrice: packageMonthlyPrice } = packageInformations

  const { t } = useTranslation()

  return (
    <S.PackageMonthlyPriceContainer>
      <S.PackageWithDiscountContainer>
        <S.PackageMonthlyPrice>
          {`${communityCurrencySymbol} ${currencyFormat(packageMonthlyPrice)}`}
        </S.PackageMonthlyPrice>
        <S.PackageMonthly>{`/ ${t('monthly')}`}</S.PackageMonthly>
      </S.PackageWithDiscountContainer>
    </S.PackageMonthlyPriceContainer>
  )
}

const OffersOptionsFactory = {
  'with-special-offer': WithSpecialOffer,
  'without-special-offer': WithoutSpecialOffer,
}

const OffersKeyFactory = {
  true: 'with-special-offer',
  false: 'without-special-offer',
}

export default function SelectedPackageSection({
  checkedPackage,
  packageInformations,
  onChangePackage,
  communityCurrencySymbol,
  ...props
}) {
  const { onChange } = props

  const { t } = useTranslation()

  const { name: packageName, products: packageProducts, hasSpecialOffer } = packageInformations

  const CurrentOfferKey = useMemo(() => OffersKeyFactory[hasSpecialOffer === 1], [hasSpecialOffer])

  const OfferComponent = OffersOptionsFactory[CurrentOfferKey]

  const handleOnChange = e => {
    if (onChangePackage) {
      onChangePackage()
    }

    return onChange?.(e)
  }

  return (
    <S.FormSectionContainer>
      <S.PackageContainer>
        <S.PackageSelectionContainer>
          <S.PackageNameAndPrice>
            <S.PackageName>{packageName}</S.PackageName>

            <S.PackagePriceContainer>
              <OfferComponent
                packageInformations={packageInformations}
                communityCurrencySymbol={communityCurrencySymbol}
              />
            </S.PackagePriceContainer>
          </S.PackageNameAndPrice>

          <S.PackageControllersWrapper>
            {hasSpecialOffer === 1 ? <S.SpecialOfferImage /> : null}

            <RadioCheckbox className="radio-select-package" defaultChecked onClick={handleOnChange} />

            <S.Separator />

            <S.ChangePackageButton type="button" onClick={handleOnChange}>
              {t('changePackage')}
            </S.ChangePackageButton>
          </S.PackageControllersWrapper>
        </S.PackageSelectionContainer>

        {checkedPackage ? (
          <S.PackageInformationContainer>
            <S.PackageComposition>{t('selectPackageIncludes')}</S.PackageComposition>

            <S.FormatDetailsCardContainer>
              {packageProducts.map(format => (
                <FormatDetailsCard
                  key={format.id}
                  name={format.name}
                  description={format.description}
                  productType={format.productType}
                  qtdPerMonth={format.quantity?.qtdPerMonth}
                />
              ))}
            </S.FormatDetailsCardContainer>
          </S.PackageInformationContainer>
        ) : null}
      </S.PackageContainer>
    </S.FormSectionContainer>
  )
}
