import produce from 'immer'
import { TOGGLE_LINK } from './actionTypes'

const INITIAL_STATE = {
  selectedLink: 'community',
}

export default function link(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case TOGGLE_LINK: {
        draft.selectedLink = action.payload.selectedLink
        break
      }
      default:
    }
  })
}
