/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { every } from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ZeroRegisterIndicator from '~/components/ZeroRegisterIndicator'
import { DefaultLogoImage } from '~/util/defaultImages'
import { getFormattedDatesWithYear } from '~/helper/getFormattedDates'
import CampaignReviewTabCard from '~/pages/Sponsor/containers/CampaignReviewTabCard'
import ShouldRender from '~/components/ShouldRender'
import TabTitleAndSubtitle from '~/components/Community/TabTitleAndSubtitle'
import FormatsProgressBar from '~/components/FormatsProgressBar'
import useCampaignFunctions from '~/helper/campaignFunctions'
import ModalInformationAfterFormatCreation from '~/components/Sponsor/Modals/ModalInformationAfterFormatCreation'
import StepCampaignReviewPage from '~/pages/Sponsor/containers/StepCampaignReviewPage'
import { getCurrentCampaignAndReloadThePage, getCampaignsFormatsPerMonth } from '~/services/requests'
import { getCampaignCurrency, currencyFormat } from '~/helper/helperFunctions'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import * as CHAT from '~/helper/chatFunctions'
import * as FN from '~/helper/formFunctions'
import * as S from './styled'

export default function CampaignReviewPage({ backToCommunityPage, campaign }) {
  const { t } = useTranslation()
  const { setCampaignCurrentSelectedMonthAction, setCampaignCurrentSelectedYearAction } = useSponsorFunctions()

  const {
    community: { fullName: communityName, networkLogo: communityLogo } = {},
    budget: campaignBudget,
    name: campaignName,
    dtStart: campaignStartDate,
    dtEnd: campaignEndDate,
    id: campaignId,
    networkId: communityId,
    companyId,
    campaignProducts,
    currency,
  } = campaign || {}

  const CF = useCampaignFunctions()
  const [isLoadingCampaign, setIsLoadingCampaign] = useState(false)
  const [budgetAlreadyAlocated, setBudgetAlreadyAlocated] = useState(0)

  const dates = useMemo(() => {
    if (!!campaignStartDate && !!campaignEndDate) {
      return getFormattedDatesWithYear(campaignStartDate, campaignEndDate)
    }

    return null
  }, [campaignStartDate, campaignEndDate])

  const campaignStartingDate = dates?.start?.dateFormatted
  const startYear = dates?.start?.year
  const campaignEndingDate = dates?.end?.dateFormatted
  const endYear = dates?.end?.year

  const showModalInformationAfterFormatCreation = useSelector(
    state => state.createCampaign.showModalAfterFormatCreation,
  )
  const callbackUpdateCampaign = useSelector(state => state.createCampaign.callbackUpdateCampaign)
  const [currentMonth, setCurrentMonth] = useState(!campaign?.months ? null : campaign?.months[0]?.month)
  const [currentYear, setCurrentYear] = useState(!campaign?.months ? null : campaign?.months[0]?.year)

  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const getCampaignsFormats = useCallback(async () => {
    setIsLoading(true)
    const response = await getCampaignsFormatsPerMonth(currentMonth, campaignId, currentYear)
    setData(response)
    setIsLoading(false)
  }, [currentMonth, callbackUpdateCampaign])

  useEffect(() => {
    getCampaignsFormats()
  }, [getCampaignsFormats])

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const getMonthShortName = month => {
    const monthShortName = months[month - 1]
    return monthShortName
  }

  const handleClickMonthTab = (month, year) => {
    setCurrentMonth(month)
    setCurrentYear(year)
  }

  const checkForEmptyProductsArray = testArray => every(testArray, product => product.unities.length === 0)

  useEffect(() => {
    if (data) {
      CF.setCampaignDatesForCurrentMonthAction(data[0]?.startDate, data[0]?.endDate)
    }
  }, [data])

  useEffect(() => {
    if (currentMonth) {
      setCampaignCurrentSelectedMonthAction(currentMonth)
      setCampaignCurrentSelectedYearAction(currentYear)
    }
  }, [currentMonth])

  useEffect(() => {
    FN.calculateBudgetAlreadyAlocated(campaignProducts, setBudgetAlreadyAlocated)
  }, [campaignProducts])

  useEffect(() => {
    getCurrentCampaignAndReloadThePage(campaignId, CF.setCurrentCampaignForReviewPageAction, setIsLoadingCampaign)
    CF.closeInformationModalAfterFormatCreationAction()
  }, [])

  useEffect(() => {
    CHAT.getSpecificChannelId(communityId, CF.setCommunitySponsorDirectChannelIdAction, companyId)
  }, [communityId, companyId])

  useEffect(() => {
    if (!campaign) {
      CF.setShowCampaignReviewPageAction(false)
      CF.setCurrentCampaignForReviewPageAction(null)
    }
  }, [campaign])

  return isLoadingCampaign ? (
    <S.SpinnerContainer>
      <S.CustomSpinner />
    </S.SpinnerContainer>
  ) : (
    <S.CustomContainer fluid>
      <S.HeaderContainer>
        <S.CampaignManagementContainer>
          <S.HeaderBackContainer onClick={backToCommunityPage}>
            <S.BackIcon />
            <S.HeaderBackText>{t('back')}</S.HeaderBackText>
          </S.HeaderBackContainer>
          <S.HeaderVerticalLine />
          <S.HeaderRouteText>{t('campaignManagementContentValidation')}</S.HeaderRouteText>
        </S.CampaignManagementContainer>
        <S.StepContainer>
          <S.StepContainerTitle>{t('contentValidation')}</S.StepContainerTitle>
          <StepCampaignReviewPage />
        </S.StepContainer>
      </S.HeaderContainer>
      <S.CampaignRevisionContainer>
        <S.PageContainer>
          <S.CustomContainer fluid>
            <S.CommunityInformationsContainer>
              <S.CommunityInformations>
                {communityLogo == null && <S.CommunityLogo src={DefaultLogoImage} />}
                {communityLogo !== null && <S.CommunityLogo src={communityLogo} />}
                <S.CommunityContainer>
                  <S.CommunityNameAndLocationSection>
                    <S.CampaignNameLabel>{`${t('campaignName')}:`}</S.CampaignNameLabel>
                    <S.CampaignName>{campaignName}</S.CampaignName>
                    <S.CommunityNameLabel>{`${t('communityName')}:`}</S.CommunityNameLabel>
                    <S.CommunityName>{communityName}</S.CommunityName>
                  </S.CommunityNameAndLocationSection>
                </S.CommunityContainer>
              </S.CommunityInformations>

              <S.CampaignInformations>
                <S.CampaignContainer>
                  <S.CampaignInformationTitle>{t('startDate')}</S.CampaignInformationTitle>
                  <S.CampaignInformationSubtitle>{campaignStartingDate}</S.CampaignInformationSubtitle>
                  <ShouldRender if={!!startYear}>
                    <S.CampaignInformationYear>{startYear}</S.CampaignInformationYear>
                  </ShouldRender>
                </S.CampaignContainer>

                <S.CampaignContainer>
                  <S.CampaignInformationTitle>{t('endDate')}</S.CampaignInformationTitle>
                  <S.CampaignInformationSubtitle>{campaignEndingDate}</S.CampaignInformationSubtitle>
                  <ShouldRender if={!!endYear}>
                    <S.CampaignInformationYear>{endYear}</S.CampaignInformationYear>
                  </ShouldRender>
                </S.CampaignContainer>

                <S.CampaignBudgetContainer>
                  <S.CampaignInformationTitle>{t('budgetUsed')}</S.CampaignInformationTitle>
                  <S.CampaignInformationSubtitle>
                    {`${getCampaignCurrency(currency)} ${currencyFormat(campaignBudget)}`}
                  </S.CampaignInformationSubtitle>
                </S.CampaignBudgetContainer>

                <S.CampaignBudgetContainer>
                  <S.CampaignInformationTitle>{t('budgetAlreadyAlocated')}</S.CampaignInformationTitle>
                  <S.CampaignInformationSubtitle>
                    {`${getCampaignCurrency(currency)} ${currencyFormat(budgetAlreadyAlocated)}`}
                  </S.CampaignInformationSubtitle>
                </S.CampaignBudgetContainer>
              </S.CampaignInformations>
            </S.CommunityInformationsContainer>
          </S.CustomContainer>
          <S.BodyContainer>
            <>
              <S.MonthTabContainer>
                {campaign?.months?.map(month => (
                  <S.MonthTab
                    isActive={month.month === currentMonth}
                    onClick={() => handleClickMonthTab(month.month, month.year)}
                    key={month}
                  >
                    {getMonthShortName(month.month)}
                  </S.MonthTab>
                ))}
              </S.MonthTabContainer>
              <TabTitleAndSubtitle sponsor title={t('monthlyFormatsAvailable')} subtitle={t('manageTheFormats')} />

              {isLoading ? (
                <S.FormatsSpinnerContainer>
                  <S.CustomSpinner />
                </S.FormatsSpinnerContainer>
              ) : (
                <>
                  <S.ProgressBarsContainer>
                    {data?.map(campaignFormat => (
                      <FormatsProgressBar
                        campaignFormat={campaignFormat}
                        currentSelectedMonth={currentMonth}
                        campaign={campaign}
                        key={campaignFormat.id}
                      />
                    ))}
                  </S.ProgressBarsContainer>
                  <S.CampaignFormatsTitleContainer>
                    <TabTitleAndSubtitle sponsor title={t('alreadyLoadedContentsForThisCampaign')} />
                  </S.CampaignFormatsTitleContainer>
                  {checkForEmptyProductsArray(data) && <ZeroRegisterIndicator text={t('noFormatsCreated')} />}
                  {data?.map(campaignProduct =>
                    campaignProduct.unities.map(formatUnity => (
                      <CampaignReviewTabCard
                        key={formatUnity.id}
                        formatUnity={formatUnity}
                        campaignFormat={campaignProduct}
                        campaign={campaign}
                      />
                    )),
                  )}
                </>
              )}
            </>
          </S.BodyContainer>
        </S.PageContainer>
      </S.CampaignRevisionContainer>

      <ModalInformationAfterFormatCreation
        handleCloseModal={() => CF.closeInformationModalAfterFormatCreationAction()}
        showModal={showModalInformationAfterFormatCreation}
      />
    </S.CustomContainer>
  )
}
