export const GET_COMMUNITY_LINK_INVITATION_REQUEST = '@community/GetCommunityLinkInvitationRequest'
export const GET_COMMUNITY_LINK_INVITATION_SUCCESS = '@community/GetCommunityLinkInvitationSuccess'
export const GET_COMMUNITY_LINK_INVITATION_FAILURE = '@community/GetCommunityLinkInvitationFailure'
export const SET_USER_CURRENT_COMMUNITY = '@community/SetUserCurrentCommunity'
export const SET_USER_CURRENT_COMMUNITY_AFTER_SIGN_UP = '@community/SetUserCurrentCommunityAfterSignUp'
export const SET_COMMUNITY_HAS_STRIPE_ACCOUNT = '@community/SetCommunityHasStripeAccount'
export const UPDATE_USER_CURRENT_COMMUNITY_INFORMATIONS = '@community/UpdateUserCurrentCommunityInformations'
export const RESET_COMMUNITY_INFORMATIONS = '@community/ResetCommunityInformations'
export const UPLOAD_S3_REQUEST = '@community/UploadS3Request'
export const UPLOAD_S3_SUCCESS = '@community/UploadS3Success'
export const SET_FIRST_LOGIN = '@community/SetFirstLogin'
export const REMOVE_S3_FROM_STATE = '@community/RemoveS3FromState'
export const UPDATE_COMMUNITY_PRODUCT_REQUEST = '@community/UpdateCommunityProductRequest'
export const UPDATE_COMMUNITY_PRODUCT_SUCCESS = '@community/UpdateCommunityProductSuccess'
export const UPDATE_COMMUNITY_PRODUCT_FAILURE = '@community/UpdateCommunityProductFailure'

export const UPDATE_COMMUNITY_PROFILE_REQUEST = '@community/UpdateCommunityProfileRequest'
export const UPDATE_COMMUNITY_PROFILE_SUCCESS = '@community/UpdateCommunityProfileSuccess'
export const UPDATE_COMMUNITY_PROFILE_LOADING = '@community/UpdateCommunityProfileLoading'
export const UPDATE_COMMUNITY_PROFILE_FAILURE = '@community/UpdateCommunityProfileFailure'

export const CREATE_CUSTOM_ADVERTISEMENT_PRODUCT = '@community/CreateCustomAdvertisementProduct'
export const UPDATE_CUSTOM_ADVERTISEMENT_PRODUCT = '@community/UpdateCustomAdvertisementProduct'
export const DELETE_CUSTOM_ADVERTISEMENT_PRODUCT = '@community/DeleteCustomAdvertisementProduct'
export const CREATE_PACKAGE = '@community/CreatePackage'
export const UPDATE_PACKAGE = '@community/UpdatePackage'
export const UPDATE_PACKAGE_ACTIVE_STATUS = '@community/UpdatePackageActiveStatus'
export const DELETE_PACKAGE = '@community/DeletePackage'
export const UPDATE_COMMUNITY_SPONSOR_COOPERATION_STATUS = '@community/UpdateCommunitySponsorCooperationStatus'
export const UPDATE_SPONSOR_COMMUNITY_COOPERATION_STATUS = '@community/UpdateSponsorCommunityCooperationStatus'
export const GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST =
  '@community/GetCommunityInformationsByInvitationLinkRequest'
export const GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_SUCCESS =
  '@community/GetCommunityInformationsByInvitationLinkSuccess'
export const GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_FAILURE =
  '@community/GetCommunityInformationsByInvitationLinkFailure'
export const UPDATE_CUSTOM_ADVERTISEMENT_PRODUCT_ACTIVE_STATUS =
  '@community/UpdateCustomAdvertisementProductActiveStatus'
export const ADD_COMMUNITY_GALLERY_MEDIA_REQUEST = '@community/AddCommunityGalleryMediaRequest'
export const ADD_COMMUNITY_GALLERY_MEDIA_FAILURE = '@community/AddCommunityGalleryMediaFailure'
export const ADD_COMMUNITY_GALLERY_MEDIA_SUCCESS = '@community/AddCommunityGalleryMediaSuccess'
export const DELETE_COMMUNITY_GALLERY_MEDIA_REQUEST = '@community/DeleteCommunityGalleryMediaRequest'
export const DELETE_COMMUNITY_GALLERY_MEDIA_FAILURE = '@community/DeleteCommunityGalleryMediaFailure'
export const DELETE_COMMUNITY_GALLERY_MEDIA_SUCCESS = '@community/DeleteCommunityGalleryMediaSuccess'
