import { combineReducers } from 'redux'
import auth from './auth/reducer'
import step from './step/reducer'
import user from './user/reducer'
import sponsor from './sponsor/reducer'
import community from './community/reducer'
import createCampaign from './createCampaign/reducer'
import landingPage from './landingPage/reducer'
import tab from './tab/reducer'
import link from './link/reducer'
import tutorial from './tutorial/reducer'
import chat from './chat/reducer'
import firebaseUsers from './firebaseUsers/reducer'
import language from './language/reducer'
import contentCropModal from './contentCropModal/reducer'

export default combineReducers({
  auth,
  step,
  createCampaign,
  user,
  sponsor,
  community,
  landingPage,
  link,
  tutorial,
  tab,
  chat,
  firebaseUsers,
  language,
  contentCropModal,
})
