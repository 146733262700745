import styled from 'styled-components'

export const ImagePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: ${props => (props.sentByMe ? '#f74657' : '#303030')};
  border-top-left-radius: ${props => (props.sentByMe && props.isReply ? '0px' : '5px')};
  border-top-right-radius: ${props => (props.sentByMe && props.isReply ? '0px' : '5px')};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: ${props => (props.media?.length > 3 ? '8px 0px' : '8px')};

  &:empty {
    display: none;
  }
`

export const ThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const Thumbnail = styled.img.attrs({
  alt: 'Thumbnail',
})`
  cursor: pointer;
  margin: 0px;
  min-width: 60px;
  min-height: 60px;
  max-height: 285px;
  max-width: 295px;
  object-fit: cover;
  border-radius: 5px;
`

export const ReplyThumbnail = styled(Thumbnail)`
  max-width: 60px !important;
  max-height: 60px !important;
`
