import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'
import { customMedia } from '~/styles/global'

export const CardContainer = styled.div.attrs({
  className: 'animated fadeIn',
})`
  background-color: #fff;
  border-radius: 5px;
  height: auto;
  width: auto;
  padding-bottom: 15px;
  border-radius: 10px;

  ${customMedia.lessThan('small')`
    margin-top: 15px;
  `}
`

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``

export const Title = styled.p`
  margin: 0px;
  margin-bottom: 5px;
  padding-top: 20px;
  padding-left: 20px;
  color: var(--input-title);
  font-family: Roboto;
  font-size: 16px;
`

export const SponsoredChatsDescription = styled.p`
  margin: 0px;
  margin-bottom: 5px;
  padding: 0px 20px;
  color: var(--grey);
  font-family: Roboto;
  font-size: 14px;
`

export const HeaderTitle = styled.p`
  margin: 0px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  margin-left: 8px;
  line-height: 18px;
  letter-spacing: 0.3px;

  ${customMedia.lessThan('1580px')`
    font-size: 15px;
  `}

  ${customMedia.lessThan('1100px')`
    font-size: 16px;
  `}
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  height: 54px;
  width: 100%;
  background-image: ${props =>
    props.userType === 'Community'
      ? 'linear-gradient(to right, #9671F5 40%, #7345e9 100%)'
      : 'linear-gradient(to right, #00A2FF 40%, #0090de 100%)'};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

export const BorderContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`

export const Border = styled.p`
  border: solid rgba(214, 214, 214, 0.3);
  border-width: 1px;
  margin-bottom: 0;
  width: 90% !important;
  opacity: 0.6;
`

export const ChannelsIcon = styled.img.attrs({
  src: '/icons/new-chat-icon.png',
})`
  width: 27px;
  height: 27px;
`
