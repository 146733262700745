import * as actions from './actionTypes'

export function setCommunityCurrentTab(tab) {
  return {
    type: actions.SET_COMMUNITY_CURRENT_TAB,
    payload: { tab },
  }
}

export function setSponsorCurrentTab(tab) {
  return {
    type: actions.SET_SPONSOR_CURRENT_TAB,
    payload: { tab },
  }
}
