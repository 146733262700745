import React, { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CampaignPopover from '~/components/Shared/CampaignPopover'
import useCommunityFunctions from '~/helper/communityFunctions'
import ModalComposePackage from '~/components/Modals/ModalComposePackage'
import CheckCompositionModal from '~/components/Community/PackagesTab/CheckCompositionModal'
import { CustomSwitch } from '~/components/Shared/StyledComponents'
import useGetCommunityCurrencySymbol from '~/hooks/useGetCommunityCurrencySymbol'
import { currencyFormat } from '~/helper/helperFunctions'
import Dialog from '~/components/Shared/Dialog'
import * as S from './styled'

export default function PackageTabCard({
  package: packageInfo,
  selectedPackages,
  selectPackage,
  deletePackage,
  setCallbackToGetPackages,
  setSelectedPackagesCallback,
}) {
  const { id: packageId, name: packageName = {}, monthlyPrice, specialOfferPrice, specialOfferDuration, statusActive } =
    packageInfo || {}

  const { t } = useTranslation()
  const { updatePackageActiveStatusAction } = useCommunityFunctions()
  const communityId = useSelector(state => state.community.currentCommunity.id)
  const communityCurrency = useSelector(state => state.community.currentCommunity.currency)
  const communityCurrencySymbol = useGetCommunityCurrencySymbol(communityCurrency)
  const [showCheckCompositionModal, setShowCheckCompositionModal] = useState(false)
  const [showModalComposePackage, setShowModalComposePackage] = useState(false)
  const [checked, setChecked] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [popoverAnchor, setPopoverAnchor] = useState(null)
  const [switchChecked, setSwitchChecked] = useState(statusActive === 'actived')

  const handleClickMoreButton = event => {
    setPopoverAnchor(event.currentTarget)
  }

  const openPackageUpdateModal = () => {
    setShowModalComposePackage(true)
    setPopoverAnchor(null)
  }

  const updatePackageActiveStatus = e => {
    if (statusActive === 'actived') {
      setSwitchChecked(e)
      updatePackageActiveStatusAction('inactivated', packageId, communityId)
    }
    if (statusActive === 'inactivated') {
      setSwitchChecked(e)
      updatePackageActiveStatusAction('actived', packageId, communityId)
    }
  }

  const handleClickCheckbox = () => {
    setChecked(!checked)
    selectPackage([...selectedPackages, packageId])
    selectedPackages.forEach(selectedPackage => {
      if (selectedPackage === packageId) {
        const duplicatePackageIndex = selectedPackages.indexOf(packageId)
        if (duplicatePackageIndex > -1) {
          selectedPackages.splice(duplicatePackageIndex, 1)
          selectPackage(selectedPackages)
        }
      }
    })
    setSelectedPackagesCallback(Math.random())
  }

  const handleDeletePackagesButton = () => {
    deletePackage(packageId, communityId)
    setTimeout(() => {
      setCallbackToGetPackages()
    }, 2000)
    setPopoverAnchor(null)
  }

  return (
    <S.Container>
      <S.CheckboxColumn md={1}>
        <Checkbox checked={checked} onChange={handleClickCheckbox} />
      </S.CheckboxColumn>
      <S.PackagesColumn md={2}>
        <S.PackageName>{packageName}</S.PackageName>
      </S.PackagesColumn>
      <S.CompositionColumn md={3}>
        <S.CheckFormatsButton onClick={() => setShowCheckCompositionModal(true)}>
          <S.CheckFormatsText>{t('checkFormatsIncluded')}</S.CheckFormatsText>
        </S.CheckFormatsButton>
      </S.CompositionColumn>

      {specialOfferPrice && (
        <>
          <S.ProductPriceColumn md={2}>
            <S.PackagePrice>{`${communityCurrencySymbol} ${currencyFormat(monthlyPrice)}`}</S.PackagePrice>
          </S.ProductPriceColumn>
          <S.SpecialOfferColumn md={2}>
            <S.PackageDuration>{`${specialOfferDuration?.qtdMonth} ${t('months')}`}</S.PackageDuration>
            <S.ProductPriceWithoutDiscount>
              {`${communityCurrencySymbol} ${currencyFormat(monthlyPrice)}`}
            </S.ProductPriceWithoutDiscount>
            <S.ProductPriceWithDiscount>
              {`${communityCurrencySymbol} ${currencyFormat(specialOfferPrice)}`}
            </S.ProductPriceWithDiscount>
          </S.SpecialOfferColumn>
        </>
      )}
      {!specialOfferPrice && (
        <>
          <S.ProductPriceColumn md={2}>
            <S.PackagePrice>{`${communityCurrencySymbol} ${currencyFormat(monthlyPrice)}`}</S.PackagePrice>
          </S.ProductPriceColumn>
          <S.ProductPriceColumn md={2}>
            <S.SpecialOfferText>{t('noSpecialOffer')}</S.SpecialOfferText>
          </S.ProductPriceColumn>
        </>
      )}
      <S.StatusColumn md={1}>
        <CustomSwitch className="switch-package-status" checked={switchChecked} onChange={updatePackageActiveStatus} />
        {switchChecked ? <S.SwitchTitle>{t('on')}</S.SwitchTitle> : <S.SwitchTitle>{t('off')}</S.SwitchTitle>}
      </S.StatusColumn>
      <S.MoreColumn md={1}>
        <S.EditOptions onClick={handleClickMoreButton} />
      </S.MoreColumn>
      <CampaignPopover
        popoverAnchor={popoverAnchor}
        setPopoverAnchor={setPopoverAnchor}
        handleClickPopover={openPackageUpdateModal}
        handleDeleteButton={() => setOpenDialog(true)}
        hideDeleteButton
      />
      <CheckCompositionModal
        showModal={showCheckCompositionModal}
        handleCloseModal={() => setShowCheckCompositionModal(false)}
        packageInfo={packageInfo}
      />
      <ModalComposePackage
        showModal={showModalComposePackage}
        handleCloseModal={() => setShowModalComposePackage(false)}
        packageInfo={packageInfo}
        setCallbackToGetPackages={setCallbackToGetPackages}
      />
      <Dialog
        openDialog={openDialog}
        closeDialog={() => setOpenDialog(false)}
        dialogTitle={t('attention')}
        dialogText={t('doYouWantToContinue')}
        cancelAction={() => setOpenDialog(false)}
        confirmAction={handleDeletePackagesButton}
      />
    </S.Container>
  )
}
