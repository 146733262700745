import styled from 'styled-components'
import { IoIosImages } from 'react-icons/io'

export const MiddlePreviewContainer = styled.div`
  background-color: #000000;
  padding-bottom: 100px;
  margin-top: -8px;
`

export const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  background-color: #ccc;
  position: absolute;
  top: 0px;
  z-index: 1;

  video {
    object-fit: cover;
  }
`

export const NoMediaIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  position: absolute;
  top: 0px;
  z-index: 1;
`

export const ImageIcon = styled(IoIosImages).attrs({
  size: 28,
  color: 'red',
})``

export const ImagePreview = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: blur(8px);
  opacity: 0.7;
`

export const ScratchToDiscoverContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 110px;
  z-index: 2;
`

export const ScratchIndicator = styled.img.attrs({
  src: '/scratch-figure.png',
  alt: 'Scratch and discover',
})``

export const HelperText = styled.span`
  color: #fff;
  font-family: Open Sans;
  font-size: 20px;
  margin-top: 24px;
  text-shadow: 1px 1px 2px rgba(9, 10, 11, 0.75);
`

export const ShadowEffect = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 202px;
  background: linear-gradient(180deg, rgba(9, 10, 11, 0) 0%, rgba(9, 10, 11, 0.45) 35.42%, #1c1e21 100%);
`
