import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import { IoIosAdd } from 'react-icons/io'
import WhiteButton from '~/components/WhiteButton'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  padding: 30px;
`

export const SearchInputContainer = styled.div`
  margin: 30px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 35px;
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const ProductsContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(385px, 1fr));

  @media (max-width: 1387px) {
    .product_tab__i-container {
      justify-content: flex-start;
    }
  }
`

export const GridTitleContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
`

export const GridTitle = styled.p`
  margin: 0px;
  color: var(--community-dark);
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
`

export const GridPriceTitle = styled.p`
  margin: 0px;
  color: var(--community-dark);
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 35px;
`

export const DeleteButtonContainer = styled.div`
  margin-right: 20px;
`

export const TestJoyride = styled.div`
  width: 200px;
  height: 200px;
  background-color: gray;
`

export const NewCustomFormatButton = styled(WhiteButton).attrs({})``

export const NewCustomFormatIcon = styled(IoIosAdd).attrs({
  size: 26,
  color: 'var(--community-primary)',
})``

export const DeleteButtonIcon = styled.img.attrs({
  src: '/icons/delete-icon.png',
})`
  width: 22px;
  height: 22px;
`

export const EditColumn = styled(Col).attrs({
  md: 1,
})`
  padding-left: 0px !important;
  display: flex;
  justify-content: center;
`

export const FormatNameColumn = styled(Col).attrs({
  md: 2,
})``

export const DescriptionColumn = styled(Col).attrs({
  md: 5,
})`
  padding-left: 0px !important;
`

export const PriceColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  justify-content: center;
`

export const StatusColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  justify-content: center;
`
