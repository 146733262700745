import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from '~/helper/getFormattedDates'
import { DefaultLogoImage } from '~/util/defaultImages'
import { currencyFormat } from '~/helper/helperFunctions'
import useGetCommunityCurrencySymbol from '~/hooks/useGetCommunityCurrencySymbol'
import * as S from './styled'

export default function PaymentTabCard({ payment }) {
  const { t } = useTranslation()
  const {
    campaignName,
    communityName,
    communityLogo,
    isPackage,
    totalBudget,
    dtStart,
    dtEnd,
    paymentStatus,
    remainFormats,
    currency,
  } = payment || {}
  const communityCurrencySymbol = useGetCommunityCurrencySymbol(currency)

  return (
    <S.Container>
      <S.CommunityColumn md={3}>
        <S.CommunityInformationsContainer>
          <S.CommunityLogoContainer>
            {communityLogo == null && <S.CommunityLogoImage src={DefaultLogoImage} />}
            {communityLogo !== null && <S.CommunityLogoImage src={communityLogo} />}
          </S.CommunityLogoContainer>
          <S.CommunityAndCampaignNameContainer>
            {campaignName ? <S.CampaignName>{campaignName}</S.CampaignName> : <S.CampaignName>-</S.CampaignName>}
            <S.CommunityName>{communityName}</S.CommunityName>
          </S.CommunityAndCampaignNameContainer>
        </S.CommunityInformationsContainer>
      </S.CommunityColumn>
      <S.CampaignModelColumn md={2}>
        <S.ProductSubtitle>{isPackage === null ? t('payAsYouGo') : t('byPackage')}</S.ProductSubtitle>
      </S.CampaignModelColumn>
      <S.FormatsColumn md={1}>
        <S.ProductSubtitle>{t('availableThisMonth', { unity: remainFormats })}</S.ProductSubtitle>
      </S.FormatsColumn>
      <S.TotalBudgetColumn md={2}>
        <S.TotalBudget>{`${communityCurrencySymbol} ${currencyFormat(totalBudget)}`}</S.TotalBudget>
      </S.TotalBudgetColumn>
      <S.PurchaseColumn md={2}>
        <S.DateContainer>
          <S.DateLabel>{t('start')}</S.DateLabel>
          <S.Date>{formatDate(dtStart)}</S.Date>
        </S.DateContainer>
        <S.DateContainer>
          <S.DateLabel>{t('end')}</S.DateLabel>
          <S.Date>{formatDate(dtEnd)}</S.Date>
        </S.DateContainer>
      </S.PurchaseColumn>
      <S.PaymentStatusColumn md={2}>
        {paymentStatus === 'requires_payment_method' ? (
          <S.InvoiceStatus paymentStatus={paymentStatus}>{t('requiresPayment')}</S.InvoiceStatus>
        ) : (
          <S.InvoiceStatus paymentStatus={paymentStatus}>{t('invoicePaid')}</S.InvoiceStatus>
        )}
      </S.PaymentStatusColumn>
    </S.Container>
  )
}
