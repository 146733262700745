import styled from 'styled-components'

export const FormContainer = styled.div`
  .input-date {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    height: 40px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(159, 159, 159, 0.7);
    border-radius: 5px;
    padding-left: 5px;
  }
  .video-icons-vimeo {
    border-radius: 3px;
  }
`

export const InputTitle = styled.span`
  margin-top: 3px;
  color: #404257;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
`

export const VideoTitle = styled.span`
  margin: 0px 10px 0px 10px;
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  width: 260px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

export const VideoSubtitle = styled.span`
  margin: 3px 10px 0px 10px;
  color: #ccc;
  font-family: Roboto;
  font-size: 12px;
  width: 260px;
  height: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`

export const Phone = styled.div`
  width: 310px;
  height: 640px;
  border: 2px solid #ccc;
  border-radius: 30px;
  position: relative;
  left: 50%;
  top: 50px;
  margin: -20px -150px;
  background: #fff;
`

export const Screen = styled.div`
  background-color: #000000;
  width: 290px;
  height: 520px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  left: 50%;
  top: 50%;
  margin: -260px -145px;
  box-sizing: border-box;
  overflow: hidden;
`

export const Home = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0 -10px;
  border-radius: 50%;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .image-container {
    width: 100%;
    height: 320px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`
