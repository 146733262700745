import styled from 'styled-components'

export const ArrowContainer = styled.div`
  height: 32px;
  width: 32px;
  background-color: rgba(255, 255, 255, 0.85);
  top: -16px;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #635b5b;
`
export const ArrowIcon = styled.img.attrs({
  src: '/back-button.png',
})`
  width: auto;
  height: 22px;
  transform: ${props => (props.rotate ? `rotate(${props.rotate}deg)` : '')};
`
