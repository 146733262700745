import styled from 'styled-components'

export const DayAlert = styled.div`
  position: absolute;

  top: 0;
  left: -50%;
  transform: translateY(-100%);

  display: none;
  align-items: center;
  justify-content: center;

  z-index: 25;

  border-radius: 10px;

  visibility: hidden;
  opacity: 0;

  background-color: #fff;

  border: 1px solid #dddddd;
  box-shadow: 0px 1px 24px 0px rgb(0 0 0 / 15%);

  transition: opacity 200ms;

  &:before {
    content: '';

    position: absolute;
    bottom: 0;
    left: 0;

    width: 0;
    height: 0;

    background-color: transparent;

    border: 1rem solid transparent;
    border-top-color: #fff;
    border-bottom: 0;

    transform: translate(100%, 100%);
  }
`

export const DayPickerWrapper = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  color: var(--input-title);
  font-family: Inter;
  font-size: 15px;

  background: #fafafa;

  border: 1.5px solid #d0cfcf;

  padding: 30px;

  border-radius: 5px;

  width: 100%;
  height: 100%;

  margin-top: 16px;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .rdp {
    position: relative;

    height: 450px;
    margin: 0;

    --rdp-cell-size: 56px;

    --rdp-accent-color: rgba(119, 189, 75, 0.6);

    --rdp-background-color: #ffffff;

    --rdp-outline: 0.1rem solid #eaeaea;
    --rdp-outline-selected: 0.1rem solid #373e48;
  }

  .rdp-multiple_months .rdp-caption {
    position: static;

    text-align: start;

    .rdp-caption_label {
      padding-left: 18px;
    }

    .rdp-nav_button {
      width: 40px;
      height: 40px;

      border: 1px solid transparent;

      transition: all 0.2s;

      &[disabled] {
        cursor: not-allowed;

        opacity: 0.5;
      }

      &:hover {
        border-color: #d0cfcf;
      }
    }

    .rdp-nav_button_next {
      transform: translateX(100%);
    }

    .rdp-nav_button_previous {
      transform: translateX(-100%);
    }
  }

  .rdp-cell {
    padding: 2px 0;

    &:first-of-type .rdp-day {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-of-type .rdp-day {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .rdp-day {
      border-radius: 0;

      &.first-day-of-month,
      &.first-selected-day,
      &.first-disabled-day {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &.last-day-of-month,
      &.last-selected-day,
      &.last-disabled-day {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  .rdp-day_selected:not(.disabled-day) {
    font-weight: bold;

    color: var(--input-title);

    background-color: var(--rdp-accent-color);
  }

  .rdp-caption_label {
    z-index: 0;
    text-transform: capitalize;
  }

  .rdp-day {
    overflow: visible;

    &:hover:not([disabled]) {
      font-weight: bold;
    }
  }

  .my-today {
    font-weight: bold;
    font-size: 120%;
  }

  .rdp-button[disabled]:not(.rdp-day_selected) {
    opacity: 1;
  }

  .disabled-out-of-range-dates:not(.rdp-day_selected) {
    font-size: 70%;

    color: var(--grey);

    background-color: transparent;

    cursor: not-allowed;
  }

  .disabled-blocked-dates {
    position: relative;

    color: var(--grey);

    background-color: transparent;

    cursor: not-allowed;

    &:hover ${DayAlert} {
      display: flex;

      visibility: visible;
      opacity: 1;
    }

    &::before {
      content: '/';

      position: absolute;

      top: 50%;
      left: 50%;

      font-size: 150%;

      font-weight: 500;

      transform: translate(-50%, -50%) rotate(10deg);
    }
  }

  .disabled-range-availability:not(.rdp-day_selected) {
    position: relative;

    background-color: var(--red);

    border-radius: 0%;

    color: #fff;

    font-weight: 600;

    cursor: not-allowed;

    &:hover ${DayAlert} {
      display: flex;

      visibility: visible;
      opacity: 1;
    }
  }
`

export const Container = styled.div`
  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 500px;
  min-height: 400px;
  height: max-content;

  font-size: 14px;
  font-weight: normal;

  cursor: auto;

  padding: 24px;

  color: var(--input-title);

  font-family: 'Inter', sans-serif;
`

export const PopupText = styled.p`
  text-align: start;

  font-family: 'Inter', sans-serif;
`

export const List = styled.ul`
  padding-left: 2ch;
`

export const ListItem = styled.li`
  text-align: start;

  font-family: 'Inter', sans-serif;
`

export const ContactButton = styled.button`
  width: 100%;

  color: #fff;

  background-color: #87a9ff;

  padding: 16px;

  font-weight: 600;

  border: none;
  border-radius: 5px;
`
