import styled from 'styled-components'
import { darken } from 'polished'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'
import { MdDone } from 'react-icons/md'
import WhiteButton from '~/components/WhiteButton'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    width: 50%;
    height: 50%;
    margin: 0;
    padding: 0;

    @media (min-width: 576px) {
      max-width: 850px;
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    p {
      margin: 0;
    }
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
`

export const GeneratingLinkContainer = styled(Row)`
  padding-top: 8px;
  display: flex;
  align-items: baseline;
`

export const ModalBannerTitle = styled.p`
  text-transform: uppercase;
  display: inline-block;
  color: #ffffff;
  width: 550px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  font-family: Montserrat;
  font-size: 40px;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Container = styled.div`
  position: relative;
  text-align: center;
`

export const InvitationTextContainer = styled.div`
  text-transform: uppercase;
  position: absolute;
  top: 60px;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 46px;
  font-weight: 600;
  line-height: 59px;
  width: 100%;
`

export const InvitationText = styled.span``

export const InputContainer = styled.div`
  display: flex;
  margin-top: 10px;

  .MuiInputBase-root,
  .MuiOutlinedInput-root,
  .MuiInputBase-fullWidth,
  .MuiInputBase-formControl {
    height: 50px !important;
  }

  .Mui-focused fieldset {
    border-color: #50a5d2 !important;
    border-width: 1px !important;
  }

  .MuiInputBase-input .MuiOutlinedInput-input {
    color: black !important;
  }
`

export const Title = styled.p`
  color: var(--text-bold);
  margin: 0px;
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  opacity: 0.9;
`

export const Subtitle = styled.p`
  color: var(--text-primary);
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  padding-bottom: -5px;
  white-space: break-spaces;
`

export const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

export const CopyButton = styled.button.attrs({
  className: 'animated fadeIn',
})`
  background-color: #77d353;
  border: none;
  border-radius: 5px;
  width: 120px;
  height: 45px;
  font-size: 15px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background-color: ${darken(0.1, '#77D353')};
    text-decoration: none;
  }

  &:active {
    vertical-align: top;
    padding: 15px 0px 10px 0px;
  }
`

export const ButtonText = styled.span`
  color: #fff;
  font-weight: bold;
`

export const ModalFooter = styled.div`
  display: flex;
  align-items: baseline;

  p {
    margin-right: 15px !important;
    color: #969faa;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.9;
  }
`

export const BannerImage = styled.img.attrs({
  src: '/sponsor-invitation.png',
})`
  width: 100%;
  height: 170px;
  object-fit: cover;
  position: relative;
  filter: brightness(90%);
`

export const CopyLinkSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  p {
    margin: 0;
    color: #47525e;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    opacity: 0.9;
  }
`

export const DoneButton = styled(WhiteButton).attrs({
  type: 'submit',
  style: { marginTop: '6px', marginRight: '10px', padding: '0px 30px 0px 20px' },
})``

export const DoneIcon = styled(MdDone).attrs({
  color: 'var(--success)',
  size: 23,
})``
