import React, { useState, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { FaRegImage } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { UploadS3 } from '~/helper/formFunctions'
import { InputTitle, FormatUploadButton } from '~/components/Shared/StyledComponents'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import ContentVisualization from '~/components/CreateFormatMobileVisualization/Content'
import { toast } from 'react-toastify'
import DisplayCriteriaSelect from '~/components/DisplayCriteriaSelect'

import * as S from './styled'

export default function ModalCreatePost({ handleCloseModal, showModal, setContent, campaignProductId }) {
  const { t } = useTranslation()
  const { createCampaignFormatAction } = useSponsorFunctions()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const [imageUrl, setImageUrl] = useState(null)
  const [imageId, setImageId] = useState(null)
  const [updatingImage, setUpdatingImage] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [date, setDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const { handleSubmit, register, setValue, errors, watch, control } = useForm()

  const titleWatch = watch('title')
  const aboutWatch = watch('about')

  function closeModalAndResetContent() {
    setContent(0)
    handleCloseModal()
  }

  function onSubmit(format) {
    if (!format.imageUrl || !format.image) {
      return toast.error(t('defaultValidation', { inputName: t('image') }))
    }

    createCampaignFormatAction(
      currentCampaignForReviewPage.id,
      campaignProductId,
      format,
      'post',
      closeModalAndResetContent,
    )
  }

  useEffect(() => {
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
  }, [register, setValue, imageUrl, imageId])

  return (
    <ProductsModal
      action={onSubmit}
      modalTitle={t('newFormat', { format: t('post') })}
      handleCloseModal={closeModalAndResetContent}
      showModal={showModal}
      review={false}
      loadingUpload={loadingUpload}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <InputTitle>{t('title')}</InputTitle>
            <Controller
              as={<TextField fullWidth variant="outlined" placeholder={t('typeTitle')} />}
              rules={{ required: t('defaultValidation', { inputName: t('title') }) }}
              name="title"
              control={control}
            />
            <div className="error-message">
              <ErrorMessage errors={errors} name="title" as="p" />
            </div>

            <StartDateSection
              product={t('post')}
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              control={control}
              errors={errors}
            />
            <EndDateSection
              product={t('post')}
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              control={control}
              errors={errors}
            />

            <DisplayCriteriaSelect control={control} />

            <div className="mt-3">
              <InputTitle>{t('about')}</InputTitle>
              <Controller
                as={
                  <TextField
                    fullWidth
                    multiline
                    rows="4"
                    variant="outlined"
                    placeholder={t('moreAboutContent', { format: t('post').toLocaleLowerCase() })}
                  />
                }
                name="about"
                control={control}
              />
            </div>

            {updatingImage ? (
              <div className="mt-3">
                <div className="d-flex justify-content-center align-items-center">
                  <FaRegImage className="mr-2" size={14} />
                  <InputTitle>{t('image')}</InputTitle>
                </div>
                <UploadS3
                  setUploadId={setImageId}
                  setUploadUrl={setImageUrl}
                  setLoadingUpload={setLoadingUpload}
                  fileType="image"
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <FormatUploadButton text={t('addImage')} action={() => setUpdatingImage(!updatingImage)} />
              </div>
            )}
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          FormatVisualizationComponent={
            <ContentVisualization image={imageUrl} title={titleWatch} about={aboutWatch} contentType={t('post')} />
          }
        />
      }
    />
  )
}
