/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import { useSelector } from 'react-redux'
import Row from 'react-bootstrap/Row'
import { InputAdornment } from '@material-ui/core'
import Col from 'react-bootstrap/Col'
import InputPlacesAutocomplete from '~/components/InputPlacesAutocomplete'
import ErrorMessage from '~/components/Shared/ErrorMessage'
import { modalBodyStyleDefault } from '~/helper/const'
import useCampaignSelectors from '~/helper/campaignSelectors'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import * as S from './styled'

export default function ModalEditSponsor({ handleCloseModal, showModal, setModalStepToOne, sponsorInformations }) {
  const { t } = useTranslation()
  const { id: sponsorId, finPersonName, finPersonEmail, fiscalCode } = sponsorInformations
  const { updateSponsorProfileRequestAction, removeS3FromStateAction } = useSponsorFunctions()
  const { sponsorS3Logo, sponsorS3LogoId } = useCampaignSelectors()
  const { handleSubmit, register, setValue, errors, control } = useForm()
  const [adrStreet] = useState(sponsorInformations.adrStreet)
  const [adrState] = useState(sponsorInformations.adrState)
  const [adrFormatted, setAdrFormatted] = useState(sponsorInformations.adrFormatted)
  const [adrCity] = useState(sponsorInformations.adrCity)
  const [adrZipcode] = useState(sponsorInformations.adrZipcode)
  const [adrCountry] = useState(sponsorInformations.adrCountry)
  const [adrStreetNumber] = useState(sponsorInformations.adrStreetNumber)
  const [adrLng] = useState(sponsorInformations.adrLng)
  const [adrLat] = useState(sponsorInformations.adrLat)
  const loading = useSelector(state => state.sponsor.loading)

  const handleCloseModalAndRemoveLogoAndBannerFromState = () => {
    removeS3FromStateAction()
    handleCloseModal()
  }

  function onSubmit(data) {
    updateSponsorProfileRequestAction(sponsorId, data)
  }

  const prepareToSendObject = onSubmitFunc => data => {
    const transformData = data
    if (transformData.youtube === '') {
      transformData.youtube = null
    }
    if (transformData.facebook === '') {
      transformData.facebook = null
    }
    if (transformData.website === '') {
      transformData.website = null
    }
    if (transformData.twitter === '') {
      transformData.twitter = null
    }
    onSubmitFunc(transformData)
  }

  const { name, description, logoId, logoAdvPlatServerPath, facebook, youtube, twitter, website } = sponsorInformations

  useEffect(() => {
    register({ name: 'adrLat' })
    register({ name: 'adrLng' })
    register({ name: 'adrStreetNumber' })
    register({ name: 'adrStreet' })
    register({ name: 'adrState' })
    register({ name: 'adrCity' })
    register({ name: 'adrZipcode' })
    register({ name: 'adrCountry' })
    register({ name: 'adrFormatted' }, { required: true })
    register({ name: 'logoId' })
    register({ name: 'bannerId' })
    register({ name: 'sponsorLogo' })
    setValue('sponsorLogo', sponsorS3Logo())
    setValue('adrFormatted', adrFormatted)
    setValue('adrStreet', adrStreet)
    setValue('adrStreetNumber', adrStreetNumber)
    setValue('adrState', adrState)
    setValue('adrCity', adrCity)
    setValue('adrZipcode', adrZipcode)
    setValue('adrCountry', adrCountry)
    setValue('adrLat', adrLat)
    setValue('adrLng', adrLng)
    setValue('bannerId', null)

    if (sponsorS3LogoId() !== null) {
      setValue('logoId', sponsorS3LogoId())
    } else {
      setValue('logoId', logoId)
    }
  }, [sponsorS3LogoId])

  return (
    <div className="animated fadeIn">
      <S.ModalCustom show={showModal} onHide={handleCloseModalAndRemoveLogoAndBannerFromState}>
        <Modal.Header closeButton>
          <Modal.Title>
            <S.Title>{t('editProfile')}</S.Title>
          </Modal.Title>
        </Modal.Header>
        <S.ContainerBannerImage>
          <S.BannerImage src="/community-default-banner-picture.jpg" alt="Sponsor Logo" />

          <S.ContainerSponsorProfilePicture>
            {(logoAdvPlatServerPath === null && sponsorS3LogoId() === null) ||
            (logoAdvPlatServerPath === undefined && sponsorS3LogoId() === null) ? (
              <S.CommunityProfilePicture src="/community-profile-default-picture.png" alt="Community Logo" />
            ) : sponsorS3LogoId() === null ? (
              <S.CommunityProfilePicture src={logoAdvPlatServerPath} alt="Community Logo" />
            ) : (
              <S.CommunityProfilePicture src={sponsorS3Logo()} alt="Community Logo" />
            )}
            <S.EditLogoButton onClick={setModalStepToOne} />
          </S.ContainerSponsorProfilePicture>
        </S.ContainerBannerImage>

        <Modal.Body style={modalBodyStyleDefault}>
          <S.FormContainer>
            <form id="my-form" onSubmit={handleSubmit(prepareToSendObject(onSubmit))}>
              <Row>
                <Col md={12}>
                  <S.InputTitleContainer>
                    <S.InputLabel>{t('name')}</S.InputLabel>
                    <S.IsRequired>*</S.IsRequired>
                  </S.InputTitleContainer>
                  <Controller
                    id="sponsor-name"
                    as={<TextField fullWidth variant="outlined" placeholder={t('name')} />}
                    rules={{ required: t('defaultValidation', { inputName: t('name') }) }}
                    name="name"
                    control={control}
                    defaultValue={name === null ? '' : name}
                  />
                  <ErrorMessage errors={errors} name="name" />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={12}>
                  <S.InputLabel>{t('description')}</S.InputLabel>
                  <Controller
                    id="sponsor-description"
                    as={<TextField fullWidth variant="outlined" multiline rows="2" placeholder="Description" />}
                    name="description"
                    control={control}
                    defaultValue={description === null ? '' : description}
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={12}>
                  <S.InputTitleContainer>
                    <S.InputLabel>{t('address')}</S.InputLabel>
                    <S.IsRequired>*</S.IsRequired>
                  </S.InputTitleContainer>
                  <div className="headquarter-address-section">
                    <InputPlacesAutocomplete
                      adrFormatted={adrFormatted}
                      setAdrFormatted={setAdrFormatted}
                      register={register}
                      setValue={setValue}
                      errors={errors}
                      entityForm
                    />
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={12}>
                  <S.InputTitleContainer>
                    <S.InputLabel>{t('financialResponsibleName')}</S.InputLabel>
                    <S.IsRequired>*</S.IsRequired>
                  </S.InputTitleContainer>
                  <Controller
                    id="sponsor-financial-responsible-name"
                    as={<TextField fullWidth variant="outlined" placeholder={t('financialResponsibleName')} />}
                    rules={{ required: t('defaultValidation', { inputName: t('financialResponsibleName') }) }}
                    name="finPersonName"
                    control={control}
                    defaultValue={finPersonName}
                  />
                  <ErrorMessage errors={errors} name="finPersonName" />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6}>
                  <S.InputTitleContainer>
                    <S.InputLabel>{t('financialResponsibleEmail')}</S.InputLabel>
                    <S.HelperText>{t('forInvoicePurposes')}</S.HelperText>
                    <S.IsRequired>*</S.IsRequired>
                  </S.InputTitleContainer>
                  <Controller
                    id="sponsor-financial-responsible-email"
                    as={<TextField fullWidth variant="outlined" placeholder={t('financialResponsibleEmail')} />}
                    rules={{ required: t('defaultValidation', { inputName: t('financialResponsibleEmail') }) }}
                    name="finPersonEmail"
                    type="email"
                    control={control}
                    defaultValue={finPersonEmail}
                  />
                  <ErrorMessage errors={errors} name="finPersonEmail" />
                </Col>
                <Col md={6}>
                  <S.InputTitleContainer>
                    <S.InputLabel>{t('companyFiscalCode')}</S.InputLabel>
                    <S.HelperText>{t('forInvoicePurposes')}</S.HelperText>
                    <S.IsRequired>*</S.IsRequired>
                  </S.InputTitleContainer>
                  <Controller
                    id="sponsor-fiscal-code"
                    as={
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={t('companyFiscalCode')}
                        inputProps={{
                          maxLength: 20,
                        }}
                      />
                    }
                    rules={{ required: t('defaultValidation', { inputName: t('companyFiscalCode') }) }}
                    name="fiscalCode"
                    control={control}
                    defaultValue={fiscalCode}
                  />
                  <ErrorMessage errors={errors} name="fiscalCode" />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6}>
                  <S.InputLabel>{t('facebookPage')}</S.InputLabel>
                  <Controller
                    id="sponsor-facebook"
                    as={
                      <TextField
                        placeholder={t('facebookPage')}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <S.FacebookIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                    name="facebook"
                    control={control}
                    defaultValue={facebook === null ? '' : facebook}
                  />
                </Col>
                <Col md={6}>
                  <S.InputLabel>{t('youtubeChannel')}</S.InputLabel>
                  <Controller
                    id="sponsor-youtube"
                    as={
                      <TextField
                        variant="outlined"
                        placeholder={t('youtubeChannel')}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <S.YoutubeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                    name="youtube"
                    control={control}
                    defaultValue={youtube === null ? '' : youtube}
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6}>
                  <S.InputLabel>{t('twitterProfile')}</S.InputLabel>
                  <Controller
                    id="sponsor-twitter"
                    as={
                      <TextField
                        placeholder={t('twitterProfile')}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <S.TwitterIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                    name="twitter"
                    control={control}
                    defaultValue={twitter === null ? '' : twitter}
                  />
                </Col>
                <Col md={6}>
                  <S.InputLabel>{t('website')}</S.InputLabel>
                  <Controller
                    id="sponsor-website"
                    as={
                      <TextField
                        variant="outlined"
                        placeholder={t('website')}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <S.WebsiteIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                    name="website"
                    control={control}
                    defaultValue={website === null ? '' : website}
                  />
                </Col>
              </Row>
            </form>
          </S.FormContainer>
        </Modal.Body>

        <S.ModalFooter>
          <S.CancelButton text={t('cancel')} onClick={handleCloseModal} />
          <S.SaveButton
            data-cy="save-sponsor-profile"
            text={t('save')}
            large
            form="my-form"
            icon={<S.SaveIcon />}
            loading={loading}
          />
        </S.ModalFooter>
      </S.ModalCustom>
    </div>
  )
}
