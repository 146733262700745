import React from 'react'
import { useTranslation } from 'react-i18next'
import Popover from '@material-ui/core/Popover'
import { DeleteIcon } from '~/components/Shared/StyledComponents'
import * as S from './styled'

export default function CampaignPopover({
  popoverAnchor,
  setPopoverAnchor,
  handleClickPopover,
  handleDeleteButton,
  hideDeleteButton,
  popoverAnalytics,
}) {
  const { t } = useTranslation()
  return (
    <>
      <Popover
        className="mt-1"
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <S.PopoverContainer popoverAnalytics={popoverAnalytics}>
          <S.EditButtonContainer onClick={handleClickPopover}>
            {popoverAnalytics ? <S.AnalyticsIcon /> : <S.EditIcon />}
            {popoverAnalytics ? (
              <S.PopoverIconText>{t('analytics')}</S.PopoverIconText>
            ) : (
              <S.PopoverIconText>{t('update')}</S.PopoverIconText>
            )}
          </S.EditButtonContainer>
          <S.BorderContainer>
            <S.Border />
          </S.BorderContainer>
          {hideDeleteButton && (
            <S.DeleteButtonContainer onClick={handleDeleteButton}>
              <DeleteIcon />
              <S.PopoverIconText>{t('delete')}</S.PopoverIconText>
            </S.DeleteButtonContainer>
          )}
        </S.PopoverContainer>
      </Popover>
    </>
  )
}
