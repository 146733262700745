import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from '~/helper/getFormattedDates'
import { DefaultLogoImage } from '~/util/defaultImages'
import useCampaignFunctions from '~/helper/campaignFunctions'
import { getCampaignCurrency, currencyFormat } from '~/helper/helperFunctions'
import ModalRatingCampaign from '~/components/Modals/ModalRatingCampaign'
import ModalAnalytics from '~/components/Modals/ModalAnalytics'
import * as S from './styled'

export default function CommunityCampaignTabCard({ campaign }) {
  const { t } = useTranslation()
  const {
    company: { name: companyName, companyLogo },
    name: campaignName,
    budget: campaignBudget,
    dtStart: campaignStartDate,
    dtEnd: campaignEndDate,
    isPackage,
    currency,
  } = campaign

  const { setCurrentCampaignForReviewPageAction, setShowCampaignReviewPageAction } = useCampaignFunctions()
  const [showModalRatingCampaign, setShowModalRatingCampaign] = useState(false)
  const [showModalAnalytics, setShowModalAnalytics] = useState(false)

  function goToCommunityHomePage() {
    setShowCampaignReviewPageAction(true)
  }

  const setCampaignForReviewPageAndNavigateToIt = () => {
    setCurrentCampaignForReviewPageAction(campaign)
    goToCommunityHomePage()
  }

  const handleClickOpenModalCampaign = () => {
    setShowModalAnalytics(true)
  }

  return (
    <S.Container>
      <S.NameColumn>
        <S.SponsorInformationsContainer>
          <S.SponsorLogoContainer>
            {companyLogo == null && <S.SponsorLogoImage src={DefaultLogoImage} />}
            {companyLogo !== null && <S.SponsorLogoImage src={companyLogo} />}
          </S.SponsorLogoContainer>
          <S.CommunityAndCampaignNameContainer>
            <S.CampaignName>{campaignName}</S.CampaignName>
            <S.SponsorName>{companyName}</S.SponsorName>
          </S.CommunityAndCampaignNameContainer>
        </S.SponsorInformationsContainer>
      </S.NameColumn>
      <S.CampaignModelColumn>
        <S.ProductSubtitle>{isPackage === null ? t('payAsYouGo') : t('byPackage')}</S.ProductSubtitle>
      </S.CampaignModelColumn>
      <S.TotalBudgetColumn>
        <S.TotalBudget>{`${getCampaignCurrency(currency)} ${currencyFormat(campaignBudget)}`}</S.TotalBudget>
      </S.TotalBudgetColumn>
      <S.CampaignTimingColumn>
        <S.DateContainer>
          <S.DateLabel>{t('start')}</S.DateLabel>
          <S.Date>{formatDate(campaignStartDate)}</S.Date>
        </S.DateContainer>
        <S.DateContainer>
          <S.DateLabel>{t('end')}</S.DateLabel>
          <S.Date>{formatDate(campaignEndDate)}</S.Date>
        </S.DateContainer>
      </S.CampaignTimingColumn>
      <S.ActionColumn>
        <S.ManageButton text={t('manage')} onClick={setCampaignForReviewPageAndNavigateToIt} icon={<S.EditIcon />} />
        <S.AnalyticsButton text={t('analytics')} onClick={handleClickOpenModalCampaign} icon={<S.AnalyticsIcon />} />
      </S.ActionColumn>
      <ModalRatingCampaign
        handleCloseModal={() => setShowModalRatingCampaign(false)}
        showModal={showModalRatingCampaign}
        campaign={campaign}
        entityInformations={campaign.company}
        entityLogo={campaign.company.companyLogo}
        entity="community"
      />
      <ModalAnalytics
        handleCloseModal={() => setShowModalAnalytics(false)}
        showModal={showModalAnalytics}
        campaign={campaign}
        communityEntity
      />
    </S.Container>
  )
}
