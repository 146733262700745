import styled from 'styled-components'
import Switch from 'react-switch'
import { MdInfoOutline } from 'react-icons/md'

export const Container = styled.div`
  position: relative;
  padding: 20px 25px;
  border: 2px solid rgb(230, 230, 230, 0.5);
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.03);
  scroll-margin-top: 40px;
`

export const SwitchContainer = styled.div``

export const SwitchLabel = styled.div`
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #494e51;
  margin-bottom: 3px;
`

export const AvailabilitySwitch = styled(Switch).attrs({
  color: 'default',
  onColor: '#77D353',
  offColor: '#c6cbd2',
  handleDiameter: 33,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 33,
  width: 61,
})`
  padding: 0;
`

export const ProductImageAndInfoContainer = styled.div.attrs({
  className: 'product_tab__i-container',
})`
  display: flex;
  gap: 17px;
  justify-content: flex-start;
  max-height: 161px;
  width: 100%;
`

export const ProductImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 2px solid rgb(230, 230, 230, 0.5);
  border-radius: 8px;
  min-width: 195px;
  min-height: 161px;
  max-height: 161px;
  box-shadow: 0px 2px 4px 0px #00000014;
`

export const ProductImage = styled.img`
  width: 100px;
  height: auto;
`

export const CustomLabelContainer = styled.div`
  position: absolute;
  top: -1px;
  left: 0px;
  background-color: #ff843f;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-width: 120px;
  padding: 0px 8px;
  height: 22px;
  display: flex;
  justify-content: center;
`

export const CustomLabel = styled.p`
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
`

export const ErrorMessage = styled.p`
  color: red;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
`

export const ProductInformationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
`

export const ProductName = styled.strong`
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 17px;
  font-weight: 700;
`

export const ProductDescription = styled.span`
  margin-top: 7px;
  color: #5a5a5a;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`

export const InputContainer = styled.div`
  position: relative;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  input {
    font-family: 'Inter';
    font-size: 15px;
    font-weight: 500;
  }

  .MuiOutlinedInput-root {
    height: 50px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #e6e6e6;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export const ActionsContainer = styled.form`
  margin-top: 17px;

  .unitPrice__container {
    margin-top: 17px;
  }

  .unitPrice__container .MuiInputBase-input {
    text-align: end;
    color: #333333;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
  }

  ${ButtonsContainer} {
    margin-top: 17px;
  }
`

export const InputLabelContainer = styled.div`
  position: relative;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
`

export const InputLabel = styled.p`
  max-width: calc(100% - 20px);
  margin: 0;
  color: #494e51;
  font-family: 'Inter';
  font-size: 15px;
  font-weight: 500;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

export const MinimumPriceContainer = styled.div``

export const MinimumPriceText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #494e51;
  margin: 0;
  margin-right: 4px;
`

export const MinimumPrice = styled(MinimumPriceText)`
  margin-top: -3px;
`

export const SaveAndDeleteButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`

export const ActionButton = styled.button`
  outline: 0;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  background: #fff;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.6;
  }
`

export const InfoIcon = styled(MdInfoOutline).attrs({
  color: '#494E51',
  size: 20,
})`
  position: absolute;
  right: -10px;
  transform: translate(-50%, 0);
  cursor: pointer;
`
