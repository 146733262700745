/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Chart from 'chart.js'
import { useTranslation } from 'react-i18next'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'chartjs-plugin-labels'
import { Pie } from 'react-chartjs-2'
import i18n from '~/i18n'
import { pizzaChartDefaultOptions } from '~/helper/const'

Chart.plugins.unregister(ChartDataLabels)

Chart.plugins.register({
  afterDraw(chart) {
    if (chart.data.datasets[0].data.every(item => item === 0)) {
      const { ctx } = chart.chart
      const { width } = chart.chart
      const { height } = chart.chart
      chart.clear()
      ctx.save()
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(i18n.t('noDataToDisplay'), width / 2, height / 2)
      ctx.restore()
    }
  },
})

export default function AnalyticsPieChart({ chartData, mostViewedFormatsSelect }) {
  const { t } = useTranslation()
  const [chartLabels, setChartLabels] = useState([])
  const [chartPercentage, setChartPercentage] = useState([])
  const currentLanguage = i18n.language

  const labels = chartLabels
  const data = {
    labels,
    datasets: [
      {
        data: chartPercentage,
        backgroundColor: [
          '#266AC3',
          '#FC9538',
          '#199635',
          '#3ff2c5',
          '#9D0593',
          '#ed4656',
          '#000986',
          '#009999',
          '#d33c69',
          '#d1ce0a',
          '#65cef7',
        ],
        minBarLength: 7,
      },
    ],
  }

  useEffect(() => {
    chartData.map(data => {
      if (!chartLabels.includes(t(data.product_type)) === false) {
        return null
      }
      setChartLabels(chartLabels => [...chartLabels, t(data.product_type)])
      setChartPercentage(chartPercentage => [...chartPercentage, parseInt(data.sub_total, 10)])
      return null
    })
  }, [chartData, mostViewedFormatsSelect])

  useEffect(() => {
    setChartLabels([])
    setChartPercentage([])
  }, [currentLanguage, mostViewedFormatsSelect])

  return <Pie data={data} width={610} height={348} options={pizzaChartDefaultOptions} />
}
