export const SET_TUTORIAL_AS_ACTIVE = '@tutorial/SetTutorialAsActive'
export const SET_TUTORIAL_AS_INACTIVE = '@tutorial/SetTutorialAsInative'

export const SET_SPONSOR_TUTORIAL_FIRST_LOGIN = '@tutorial/SetSponsorTutorialFirstLogin'
export const SET_COMMUNITY_TUTORIAL_FIRST_LOGIN = '@tutorial/SetCommunityTutorialFirstLogin'

export const INCREMENT_BY_ONE_THE_SPONSOR_LOGIN = '@tutorial/IncrementByOneTheSponsorLogin'
export const INCREMENT_BY_ONE_THE_COMMUNITY_LOGIN = '@tutorial/IncrementByOneTheCommunityLogin'

export const NEVER_SHOW_THE_TUTORIAL_AGAIN_FOR_THE_SPONSOR = '@tutorial/NeverShowTheTutorialAgainForTheSponsor'
export const NEVER_SHOW_THE_TUTORIAL_AGAIN_FOR_THE_COMMUNITY = '@tutorial/NeverShowTheTutorialAgainForTheCommunity'
