import React, { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { MdTimeline } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Checkbox from '@material-ui/core/Checkbox'
import { campaignFormatStatus } from '~/helper/const'
import ModalEditWelcomeAd from '~/components/Sponsor/Modals/WelcomeAD/ModalEditWelcomeAd'
import ModalEditChatChannel from '~/components/Sponsor/Modals/ChatChannel/ModalEditChatChannel'
import ModalEditEvent from '~/components/Sponsor/Modals/Event/ModalEditEvent'
import ModalEditPost from '~/components/Sponsor/Modals/Post/ModalEditPost'
import ModalEditReward from '~/components/Sponsor/Modals/Reward/ModalEditReward'
import ModalEditDocument from '~/components/Sponsor/Modals/Document/ModalEditDocument'
import ModalPreviewVideo from '~/components/Sponsor/Modals/Video/ModalPreviewVideo'
import ModalPreviewWeb from '~/components/Sponsor/Modals/Webpage/ModalPreviewWeb'
import ModalSingleFormatAnalytics from '~/components/Modals/ModalSingleFormatAnalytics'
import { ModalEditSurveyFirstStep } from '~/components/Sponsor/Modals/Survey/ModalEditSurveyFirstStep'
import ModalEditSocialMediaPostFormat from '~/components/SocialMedia/ModalEditSocialMediaPostFormat'
import ModalEditCommunityStory from '~/components/Sponsor/Modals/CommunityStories/ModalEditCommunityStory'
import ModalEditStoriesBottomBanner from '~/components/Sponsor/Modals/StoriesBottomBanner/ModalEditStoriesBottomBanner'
import ModalEditSlidingTopBanner from '~/components/Sponsor/Modals/SlidingTopBanner/ModalEditSlidingTopBanner'
import ModalEditScratchCard from '~/components/Sponsor/Modals/ScratchCard/ModalEditScratchCard'
import GenericPopover from '~/components/Shared/GenericPopover'
import FormatShareButton from '~/components/FormatShareButton'
import { formatProductDate } from '~/helper/getFormattedDates'
import { getFormatAnaytics } from '~/services/requests'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import {
  checkIfTheFormatIsASocialMediaPost,
  checkIfSocialMediaIsConnectedBeforeApprovingTheSocialFormat,
  analyticsNotAvailable,
} from '~/helper/helperFunctions'
import * as FN from '~/helper/formFunctions'
import * as CHAT from '~/helper/chatFunctions'
import * as S from './styled'

export default function CampaignReviewTabCard({
  campaign: { id: campaignId, companyId, networkId },
  campaign,
  formatUnity,
  campaignFormat,
  selectedProducts,
  selectProduct,
  setSelectedProductsCallback,
  callbackDeleteProducts,
  setApproveFormatLoading,
}) {
  const { t } = useTranslation()
  const community = useSelector(state => state.community.currentCommunity)
  const communityId = useSelector(state => state.community.currentCommunity.id)
  const { setCampaignProductsStatusAction } = useSponsorFunctions()
  const [checked, setChecked] = useState(false)
  const { slug: formatSlug, productType } = campaignFormat.product
  const { categoryName: formatCategory } = campaignFormat
  const { id: formatId, status: formatUnityStatus, contentId, contentType } = formatUnity || {}
  const { waiting, running, revision, finalized } = campaignFormatStatus
  const formatStatus = FN.getFormatStatus(formatUnityStatus)
  const [channelId, setChannelId] = useState(null)
  const [messageForReviseFormat, setMessageForReviseFormat] = useState(false)
  const [analyticsData, setAnalyticsData] = useState(null)
  const [loadingAnalytics, setLoadingAnalytics] = useState(false)
  const [showModalEditWelcomeAd, setShowModalEditWelcomeAd] = useState(false)
  const [showModalEditPost, setShowModalEditPost] = useState(false)
  const [showModalEditEvent, setShowModalEditEvent] = useState(false)
  const [showModalEditReward, setShowModalEditReward] = useState(false)
  const [showModalEditDocument, setShowModalEditDocument] = useState(false)
  const [showModalEditVideo, setShowModalEditVideo] = useState(false)
  const [showModalEditWeb, setShowModalEditWeb] = useState(false)
  const [showModalEditChatChannel, setShowModalEditChatChannel] = useState(false)
  const [showModalEdiSurvey, setShowModalEditSurvey] = useState(false)
  const [showModalFormatAnalytics, setShowModalFormatAnalytics] = useState(false)
  const [showModalEditSocialMediaFormat, setShowModalEditSocialMediaFormat] = useState(false)
  const [showModalEditCommunityStory, setShowModalEditCommunityStory] = useState(false)
  const [showModalEditStoriesBottomBanner, setShowModalEditStoriesBottomBanner] = useState(false)
  const [showModalEditSlidingTopBanner, setShowModalEditSlidingTopBanner] = useState(false)
  const [showModalEditScratchCard, setShowModalEditScratchCard] = useState(false)
  const [popoverAnchor, setPopoverAnchor] = useState(null)

  const socialMediasConnected = {
    facebookIsConnected: community?.facebookConnected,
    twitterIsConnected: community?.twitterConnected,
    instagramIsConnected: community?.instagramConnected,
    linkedinIsConnected: community?.linkedinConnected,
  }

  const handleClickActionsPopover = event => {
    setPopoverAnchor(event.currentTarget)
  }

  const approveCampaignProducts = () => {
    const products = [formatId]
    const status = 'running'
    let validationError = false
    if (checkIfTheFormatIsASocialMediaPost(productType)) {
      validationError = checkIfSocialMediaIsConnectedBeforeApprovingTheSocialFormat(productType, socialMediasConnected)
    }
    if (!validationError) {
      setCampaignProductsStatusAction(products, networkId, campaignId, status, setApproveFormatLoading)
      setChecked(false)
      setPopoverAnchor(null)
    }
  }

  const refuseCampaignProduct = () => {
    const products = [formatId]
    const status = 'revision-needed'
    setCampaignProductsStatusAction(products, networkId, campaignId, status, setApproveFormatLoading)
  }

  const formatIsWaitingApproval = () => {
    if (formatUnityStatus === 'waiting-approval') return true
    return false
  }

  const handleClickCheckbox = () => {
    setChecked(!checked)
    selectProduct([...selectedProducts, { formatId, status: formatUnityStatus }])
    const productIndex = selectedProducts.findIndex(productSelected => productSelected.formatId === formatId)
    if (productIndex >= 0) {
      selectedProducts.splice(productIndex, 1)
      selectProduct(selectedProducts)
      callbackDeleteProducts(Math.random())
    }
    setSelectedProductsCallback(Math.random())
  }

  // TODO refactor and export this to an external function
  const handleClickReviseButton = () => {
    setPopoverAnchor(null)

    const formatName = FN.getFormatUnityTitle(formatUnity, campaignFormat)
    if (formatSlug === 'welcomeAd') {
      setMessageForReviseFormat(`${t('suggestChangeFormat', { formatName: t('welcomeAd') })} ${formatName}`)
      setShowModalEditWelcomeAd(true)
    }
    if (formatSlug === 'scratchCard') {
      setMessageForReviseFormat(`${t('suggestChangeFormat', { formatName: t('scratchCard') })} ${formatName}`)
      setShowModalEditScratchCard(true)
    }
    if (formatSlug === 'chatChannel') {
      setMessageForReviseFormat(`${t('suggestChangeFormat', { formatName: t('chatChannel') })} ${formatName}`)
      setShowModalEditChatChannel(true)
    }
    if (formatSlug === 'event') {
      setMessageForReviseFormat(`${t('suggestChangeFormat', { formatName: t('event') })} ${formatName}`)
      setShowModalEditEvent(true)
    }
    if (formatSlug === 'reward') {
      setMessageForReviseFormat(`${t('suggestChangeFormat', { formatName: t('reward') })} ${formatName}`)
      setShowModalEditReward(true)
    }
    if (formatSlug === 'survey') {
      setMessageForReviseFormat(`${t('suggestChangeFormat', { formatName: t('survey') })} ${formatName}`)
      setShowModalEditSurvey(true)
    }
    if (formatSlug === 'communityStory') {
      setShowModalEditCommunityStory(true)
    }
    if (formatSlug === 'bottomStoriesBanner') {
      setShowModalEditStoriesBottomBanner(true)
    }
    if (formatSlug === 'slidingTopBanner') {
      setShowModalEditSlidingTopBanner(true)
    }
    if (checkIfTheFormatIsASocialMediaPost(productType)) {
      setMessageForReviseFormat(`${t('suggestChangeFormat', { formatName: t(productType) })} ${formatName}`)
      setShowModalEditSocialMediaFormat(true)
    }
    if (contentType === 'post') {
      setMessageForReviseFormat(`${t('suggestChangeFormat', { formatName: t('post') })} ${formatName}`)
      setShowModalEditPost(true)
    }
    if (contentType === 'video') {
      setMessageForReviseFormat(`${t('suggestChangeFormat', { formatName: t('video') })} ${formatName}`)
      setShowModalEditVideo(true)
    }
    if (contentType === 'web') {
      setMessageForReviseFormat(`${t('suggestChangeFormat', { formatName: t('web') })} ${formatName}`)
      setShowModalEditWeb(true)
    }
    if (contentType === 'document') {
      setMessageForReviseFormat(`${t('suggestChangeFormat', { formatName: t('document') })} ${formatName}`)
      setShowModalEditDocument(true)
    }
  }

  const createMessageForTheReviseButton = () => {
    return CHAT.createMessageBetweenCommunityAndCompany(community, messageForReviseFormat, channelId, communityId)
  }

  useEffect(() => {
    CHAT.getSpecificChannelIdWithCommunityId(networkId, setChannelId, companyId)
  }, [networkId, companyId])

  useEffect(() => {
    if (selectedProducts.length === 0) {
      setChecked(false)
    }
  }, [selectedProducts])

  const handleClickAnalytics = useCallback(async () => {
    setPopoverAnchor(null)
    const response = await getFormatAnaytics(campaignId, formatId, setLoadingAnalytics)
    setAnalyticsData(response.data)

    if (analyticsNotAvailable(response.data)) {
      setLoadingAnalytics(false)
      toast.error(t('noDataAvailable'))
    } else {
      setLoadingAnalytics(false)
      setShowModalFormatAnalytics(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, formatId])

  return (
    <S.Container>
      <S.CheckboxColumn>
        {formatIsWaitingApproval() && !checkIfTheFormatIsASocialMediaPost(productType) && (
          <Checkbox checked={checked} onChange={handleClickCheckbox} color="default" />
        )}
      </S.CheckboxColumn>
      <S.ProductTitleColumn>
        <S.FormatTitle>{FN.getFormatUnityTitle(formatUnity, campaignFormat)}</S.FormatTitle>
      </S.ProductTitleColumn>
      <S.TypeColumn>
        <S.ProductTitle>{FN.getFormatType(formatSlug)}</S.ProductTitle>
      </S.TypeColumn>
      <S.ProductTimingColumn>
        {checkIfTheFormatIsASocialMediaPost(productType) ? (
          '-'
        ) : (
          <>
            <S.DateContainer>
              <S.DateLabel>{t('start')}</S.DateLabel>
              <S.Date>{formatProductDate(formatUnity.unity?.date)}</S.Date>
            </S.DateContainer>
            <S.DateContainer>
              <S.DateLabel>{t('end')}</S.DateLabel>
              <S.Date>{formatProductDate(formatUnity.unity?.endDate)}</S.Date>
            </S.DateContainer>
          </>
        )}
      </S.ProductTimingColumn>
      <S.ProductRevisionColumn>
        {formatStatus === waiting && <S.ProductDotIndicator color="#FFBA5C" />}
        {formatStatus === running && <S.ProductDotIndicator color="#1FB32E" />}
        {formatStatus === revision && <S.ProductDotIndicator color="#F74657" />}
        {formatStatus === finalized && <S.ProductDotIndicator color="#8A8E93" />}
        <S.ProductStatus>{formatStatus}</S.ProductStatus>
      </S.ProductRevisionColumn>

      <GenericPopover
        popoverAnchor={popoverAnchor}
        setPopoverAnchor={setPopoverAnchor}
        content={
          <S.ActionsColumn>
            {formatUnityStatus === 'running' && productType !== 'custom' && formatCategory !== 'Social' && (
              <S.CheckAnalyticsButton
                loading={loadingAnalytics}
                onClick={handleClickAnalytics}
                icon={<MdTimeline color="#9671F5" size={20} />}
                text={t('analytics')}
              />
            )}
            {formatUnityStatus === 'finalized' && productType !== 'custom' && formatCategory !== 'Social' && (
              <S.CheckAnalyticsButton
                loading={loadingAnalytics}
                onClick={handleClickAnalytics}
                icon={<MdTimeline color="#9671F5" size={20} />}
                text={t('analytics')}
              />
            )}

            <FormatShareButton
              contentId={contentId}
              closePopover={() => setPopoverAnchor(null)}
              productType={productType}
              productStatus={formatUnityStatus}
              communityId={communityId}
            />

            {formatUnityStatus === 'waiting-approval' && (
              <>
                <S.ReviseButtonContainer>
                  <S.ReviseButton
                    onClick={() => handleClickReviseButton()}
                    icon={<S.ReviseIcon />}
                    text={t('revise')}
                  />
                </S.ReviseButtonContainer>
                <S.ApproveButton
                  onClick={() => approveCampaignProducts()}
                  icon={<S.ApproveIcon />}
                  text={t('approve')}
                />
              </>
            )}
            {formatUnityStatus === 'revision-needed' && <S.ActionWaitingForReview>-</S.ActionWaitingForReview>}
            {formatUnityStatus === 'running' && productType === 'custom' && (
              <S.ActionWaitingForReview>-</S.ActionWaitingForReview>
            )}
            {formatUnityStatus === 'finalized' && productType === 'custom' && (
              <S.ActionWaitingForReview>-</S.ActionWaitingForReview>
            )}
            {formatCategory === 'Social' &&
              formatUnityStatus !== 'waiting-approval' &&
              formatUnityStatus !== 'revision-needed' && <S.ActionWaitingForReview>-</S.ActionWaitingForReview>}
          </S.ActionsColumn>
        }
      />

      <S.ActionsContainer>
        <S.ActionOptions onClick={handleClickActionsPopover} />
      </S.ActionsContainer>

      <ModalEditWelcomeAd
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditWelcomeAd(false)}
        showModal={showModalEditWelcomeAd}
        welcomeAdToUpdate={formatUnity.unity}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
      />
      <ModalEditScratchCard
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditScratchCard(false)}
        showModal={showModalEditScratchCard}
        scratchCardToUpdate={formatUnity.unity}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
      />
      <ModalEditChatChannel
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditChatChannel(false)}
        showModal={showModalEditChatChannel}
        chatChannelToUpdate={formatUnity.unity}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
      />
      <ModalEditEvent
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditEvent(false)}
        showModal={showModalEditEvent}
        eventToUpdate={formatUnity.unity}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
      />
      <ModalEditReward
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditReward(false)}
        showModal={showModalEditReward}
        rewardToUpdate={formatUnity.unity}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
      />
      <ModalEditSurveyFirstStep
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditSurvey(false)}
        showModal={showModalEdiSurvey}
        surveys={formatUnity.unity}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
      />
      <ModalEditPost
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditPost(false)}
        showModal={showModalEditPost}
        postToUpdate={formatUnity.unity}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
      />
      <ModalEditDocument
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditDocument(false)}
        showModal={showModalEditDocument}
        documentToUpdate={formatUnity.unity}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
      />
      <ModalEditSocialMediaPostFormat
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditSocialMediaFormat(false)}
        showModal={showModalEditSocialMediaFormat}
        community={campaign?.community}
        formatToUpdate={formatUnity.unity}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
        socialMediaFormat={campaignFormat?.product}
        formatId={formatUnity?.id}
      />
      <ModalEditCommunityStory
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditCommunityStory(false)}
        showModal={showModalEditCommunityStory}
        community={campaign?.community}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
        formatToUpdate={formatUnity?.unity}
        formatId={formatUnity?.id}
      />
      <ModalEditStoriesBottomBanner
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditStoriesBottomBanner(false)}
        showModal={showModalEditStoriesBottomBanner}
        community={campaign?.community}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
        formatToUpdate={formatUnity?.unity}
        formatId={formatUnity?.id}
      />
      <ModalEditSlidingTopBanner
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditSlidingTopBanner(false)}
        showModal={showModalEditSlidingTopBanner}
        community={campaign?.community}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
        formatToUpdate={formatUnity?.unity}
        formatId={formatUnity?.id}
      />
      <ModalPreviewVideo
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditVideo(false)}
        showModal={showModalEditVideo}
        videoToUpdate={formatUnity.unity}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
      />
      <ModalPreviewWeb
        review
        sponsor={false}
        handleCloseModal={() => setShowModalEditWeb(false)}
        showModal={showModalEditWeb}
        webToUpdate={formatUnity.unity}
        createMessage={() => createMessageForTheReviseButton()}
        refuseCampaignProduct={() => refuseCampaignProduct()}
        approveCampaignProduct={() => approveCampaignProducts()}
      />
      <ModalSingleFormatAnalytics
        handleCloseModal={() => setShowModalFormatAnalytics(false)}
        showModal={showModalFormatAnalytics}
        formatId={formatId}
        campaign={campaign}
        analyticsData={analyticsData}
      />
    </S.Container>
  )
}
