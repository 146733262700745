import React, { useState, useEffect } from 'react'
import { currencyFormat } from '~/helper/helperFunctions'
import useGetCommunityCurrencySymbol from '~/hooks/useGetCommunityCurrencySymbol'
import * as S from './styled'

export default function AnalyticsKpiProgressBar({ percentage, total, title, budget, sponsor, communityCurrency }) {
  const [style, setStyle] = useState({})
  const communityCurrencySymbol = useGetCommunityCurrencySymbol(communityCurrency)

  useEffect(() => {
    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${percentage}%`,
      }
      setStyle(newStyle)
    }, 200)
  }, [percentage])

  return (
    <S.ProgressBarContainer>
      <S.ProgressBarTitleAndPercentageContainer>
        <S.ProgressBarTitle>{title}</S.ProgressBarTitle>
        {budget ? (
          <S.ProgressBarPercentage sponsor={sponsor}>
            {`${communityCurrencySymbol} ${currencyFormat(total)} / ${percentage?.toFixed(1)}%`}
          </S.ProgressBarPercentage>
        ) : (
          <S.ProgressBarPercentage sponsor={sponsor}>
            {`${total?.toFixed(0)} / ${percentage?.toFixed(1)}%`}
          </S.ProgressBarPercentage>
        )}
      </S.ProgressBarTitleAndPercentageContainer>
      <S.ProgressContainer>
        <S.ProgressDone sponsor={sponsor} style={style} />
      </S.ProgressContainer>
    </S.ProgressBarContainer>
  )
}
