/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { FaRegImage } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { UploadS3 } from '~/helper/formFunctions'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import ContentVisualization from '~/components/CreateFormatMobileVisualization/Content'
import { InputTitle, FormatUploadButton } from '~/components/Shared/StyledComponents'
import { transformDateToUtcFormat } from '~/helper/getFormattedDates'
import { toast } from 'react-toastify'
import DisplayCriteriaSelect from '~/components/DisplayCriteriaSelect'
import * as S from './styled'

export default function ModalEditPost({
  handleCloseModal,
  showModal,
  postToUpdate,
  review,
  createMessage,
  refuseCampaignProduct,
  approveCampaignProduct,
  formatId,
  sponsor,
}) {
  const { t } = useTranslation()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const { updateCampaignFormatAction } = useSponsorFunctions()
  const { image, title, about } = postToUpdate
  const [updatingImage, setUpdatingImage] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [imageId, setImageId] = useState(image)
  const [imageUrl, setImageUrl] = useState(postToUpdate.imageUrl)
  const [date, setDate] = useState(transformDateToUtcFormat(postToUpdate.date))
  const [endDate, setEndDate] = useState(transformDateToUtcFormat(postToUpdate.endDate))
  const { handleSubmit, register, setValue, watch, errors, control } = useForm()
  const titleWatch = watch('title', postToUpdate.title)
  const aboutWatch = watch('about', postToUpdate.about)

  function onSubmit(product) {
    if (!product.imageUrl || !product.image) {
      return toast.error(t('defaultValidation', { inputName: t('image') }))
    }

    const copyObject = JSON.stringify(postToUpdate)
    const productCopy = JSON.stringify(product)

    if (copyObject === productCopy) {
      return
    }
    updateCampaignFormatAction(product, formatId, handleCloseModal)
  }

  function modalReviewCreateMessageAndCloseModal() {
    createMessage()
    refuseCampaignProduct()
    handleCloseModal()
  }

  function handleUpdatingImage() {
    setUpdatingImage(!updatingImage)
  }

  useEffect(() => {
    register({ name: 'id' })
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
    setValue('id', postToUpdate.id)
  }, [register, postToUpdate.id, setValue, imageId, imageUrl])

  return (
    <ProductsModal
      createMessage={modalReviewCreateMessageAndCloseModal}
      approveCampaignProduct={approveCampaignProduct}
      action={onSubmit}
      loadingUpload={loadingUpload}
      buttonText={review ? t('done') : t('update')}
      modalTitle={review ? t('reviseFormat', { format: t('post') }) : t('editFormat', { format: t('post') })}
      handleCloseModal={handleCloseModal}
      showModal={showModal}
      review={review}
      sponsor={sponsor}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <InputTitle>{t('title')}</InputTitle>
            <Controller
              as={<TextField fullWidth variant="outlined" placeholder={t('typeTitle')} disabled={review || false} />}
              rules={{ required: t('defaultValidation', { inputName: t('title') }) }}
              name="title"
              control={control}
              defaultValue={title === null ? '' : title}
            />
            <div className="error-message">
              <ErrorMessage errors={errors} name="title" as="p" />
            </div>

            <StartDateSection
              product={t('post')}
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              disabled={review}
              control={control}
              errors={errors}
            />
            <EndDateSection
              product={t('post')}
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              disabled={review}
              control={control}
              errors={errors}
            />

            <DisplayCriteriaSelect control={control} value={postToUpdate?.privacy} disabled={review || false} />

            <div className="mt-3">
              <InputTitle>{t('about')}</InputTitle>
              <Controller
                as={
                  <TextField
                    fullWidth
                    multiline
                    rows="4"
                    variant="outlined"
                    placeholder={t('moreAboutContent', { format: t('post').toLocaleLowerCase() })}
                    disabled={review || false}
                  />
                }
                defaultValue={about === null ? '' : about}
                name="about"
                control={control}
              />
            </div>

            {review === false ? (
              updatingImage ? (
                <div className="mt-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <FaRegImage className="mr-2" size={14} />
                    <InputTitle>{t('image')}</InputTitle>
                  </div>
                  <UploadS3
                    setUploadId={setImageId}
                    setUploadUrl={setImageUrl}
                    setLoadingUpload={setLoadingUpload}
                    fileType="image"
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <FormatUploadButton text={t('addImage')} action={handleUpdatingImage} />
                </div>
              )
            ) : null}
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          FormatVisualizationComponent={
            <ContentVisualization image={imageUrl} title={titleWatch} about={aboutWatch} contentType={t('post')} />
          }
        />
      }
    />
  )
}
