import styled from 'styled-components'
import Button from '~/components/Shared/Button'

export const CardContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`

export const CardContent = styled.div`
  position: relative;
`

export const CardBody = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
`

export const CardInformationSection = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding-top: 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  padding-bottom: 8px;
`

export const CommunityBanner = styled.img.attrs({
  alt: 'Community Banner',
})`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  z-index: 1;
`

export const CommunityLogo = styled.img.attrs({
  alt: 'Community Logo',
})`
  position: absolute;
  border: 4px solid #fff;
  border-radius: 90%;
  background-color: #fff;
  width: 79px;
  height: 79px;
  z-index: 2;
  border: 3px solid rgba(150, 159, 170, 0.28);
  border-top-color: transparent;
  border-top: 0;
  top: calc(-79px / 2);
  left: calc(50% - (79px / 2));
  object-fit: cover;
`

export const TitleAndSubtitleContainer = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CardTitle = styled.span`
  color: #47525e;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 0.75rem;
  width: 200px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

export const CardSubtitle = styled.span`
  color: #87919a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  width: 100px;
  height: 25px;
  text-align: center;
  margin-top: -0.375rem;
  margin-bottom: 0;
`

export const MembersContainer = styled.div``

export const CampaignsContainer = styled.div`
  text-transform: uppercase;
`

export const TotalMembersValue = styled.span`
  color: #47525e;
  font-family: Droid Sans;
  font-size: 16px;
  font-weight: bold;
`
export const TotalCampaignValue = styled(TotalMembersValue)``

export const MembersTitle = styled.p`
  color: #87919a;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
`

export const TotalCampaignTitle = styled(MembersTitle)`
  text-transform: capitalize;
`

export const SelectButton = styled(Button).attrs({
  style: { margin: '0px', marginTop: '16px', width: '100%' },
  uppercase: true,
  bold: true,
  type: 'submit',
  height: '40px',
  color: '#9671F5',
})``

export const SelectButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
