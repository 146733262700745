/* eslint-disable consistent-return */
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { MdHelpOutline, MdShowChart } from 'react-icons/md'
import { FaHashtag } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import Col from 'react-bootstrap/Col'
import CommunityKpiCard from '~/components/CommunityKpiCard'
import { DefaultLogoImage } from '~/util/defaultImages'
import CircularProgressBar from '~/components/Sponsor/CircularProgressBar'
import * as CONST from '~/helper/const'
import * as FN from '~/helper/formFunctions'

import SelectPackageFormMocked from './SelectPackageFormMocked'
import * as S from './styled'

export default function ModalPreviewProfileVisualization({
  handleCloseModal,
  showModal,
  community,
  previewFromSponsor,
}) {
  const { t } = useTranslation()
  const {
    name,
    communityName,
    adrCity,
    adrCountry,
    networkLogoPath,
    communityLogo,
    packages,
    membersKpi,
    womenKpi,
    menKpi,
    averageAgeKpi,
    currency,
  } = community

  const arrayKpis = FN.GetCommunityKpisArray(membersKpi, averageAgeKpi, menKpi, womenKpi)

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {!previewFromSponsor ? (
            <S.ModalTitle>{t('previewCommunityModal')}</S.ModalTitle>
          ) : (
            <S.ModalTitle>{t('communityPreview')}</S.ModalTitle>
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <S.Container>
          <S.CommunityHeaderContainer>
            <S.CommunityHeaderInformations>
              <S.CommunityInformationsContainer>
                <S.CommunityLogo
                  src={networkLogoPath || communityLogo ? networkLogoPath || communityLogo : DefaultLogoImage}
                />
                <S.CommunityContainer>
                  <S.CompanyName>{name || communityName}</S.CompanyName>
                  {adrCity && adrCountry && <S.CommunityLocation>{`${adrCity}, ${adrCountry}`}</S.CommunityLocation>}
                </S.CommunityContainer>
              </S.CommunityInformationsContainer>
            </S.CommunityHeaderInformations>
          </S.CommunityHeaderContainer>
          <S.CommunityInformationsRow>
            <Col md={7}>
              {CONST.communityInterestsMocked.length > 0 && (
                <>
                  <S.SectionContainer>
                    <FaHashtag size={14} />
                    <S.SectionTitle>{t('interests')}</S.SectionTitle>
                  </S.SectionContainer>
                  <S.InterestsContainer>
                    <Slider {...CONST.settingsSliderCommunities}>
                      {CONST.communityInterestsMocked.map(interest => (
                        <S.CircularProgressBarContainer key={interest.name}>
                          <CircularProgressBar interest={interest} />
                        </S.CircularProgressBarContainer>
                      ))}
                    </Slider>
                  </S.InterestsContainer>
                </>
              )}

              <S.SectionContainer>
                <MdShowChart size={14} />
                <S.SectionTitle>{t('communityKpis')}</S.SectionTitle>
              </S.SectionContainer>
              <S.CommunityKpiContainer>
                {arrayKpis.map(communityKpi => (
                  <CommunityKpiCard
                    key={communityKpi.label}
                    title={communityKpi.title}
                    value={communityKpi.value}
                    icon={communityKpi.icon}
                  />
                ))}
              </S.CommunityKpiContainer>

              <>
                <S.SectionContainer>
                  <S.SectionIcon />
                  <S.SectionTitle>{t('communitySponsorshipAvailableFormats')}</S.SectionTitle>
                </S.SectionContainer>
                <S.CommunityAvailableProductsContainer>
                  {CONST.productsMock.map(product => (
                    <S.ProductContainer key={product.id}>
                      <S.Ball />
                      <MdHelpOutline color="#E84E54" size={14} />
                      <S.ProductName>{product.name}</S.ProductName>
                    </S.ProductContainer>
                  ))}
                </S.CommunityAvailableProductsContainer>
              </>
            </Col>
            <Col md={5}>
              <S.FormContainer>
                <S.Form>
                  <S.FormSectionPackageContainer>
                    <S.InputTitle previewFromSponsor={previewFromSponsor}>{t('availablePackages')}</S.InputTitle>
                  </S.FormSectionPackageContainer>
                  <SelectPackageFormMocked
                    packages={packages}
                    previewFromSponsor={previewFromSponsor}
                    currency={currency}
                  />
                </S.Form>
              </S.FormContainer>
            </Col>
          </S.CommunityInformationsRow>
        </S.Container>
      </Modal.Body>

      <S.ModalFooter>
        <S.CloseButton text={t('close')} action={handleCloseModal} />
      </S.ModalFooter>
    </S.ModalCustom>
  )
}
