import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { InputTitle } from '~/components/Shared/StyledComponents'
import Select from 'react-select'
import { reactSelectStyleDefault, privacyOptions } from '~/helper/const'

import * as S from './styled'

const DisplayCriteriaSelect = ({ control, value, disabled }) => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <InputTitle>{t('displayCriteria')}</InputTitle>
      <Controller
        as={
          <Select
            styles={{
              ...reactSelectStyleDefault,
            }}
            placeholder={t('placeholderSelect')}
            options={privacyOptions}
            isDisabled={disabled}
          />
        }
        defaultValue={value ? privacyOptions.find(option => option.value === value) : privacyOptions[0]}
        name="privacy"
        control={control}
        disabled={disabled}
        onChange={([selected]) => {
          return selected
        }}
      />
    </S.Container>
  )
}

export default DisplayCriteriaSelect
