import React from 'react'
import { useTranslation } from 'react-i18next'
import { getCampaignCurrency, currencyFormat } from '~/helper/helperFunctions'
import RadioCheckbox from '~/components/Shared/RadioCheckbox'
import * as S from './styled'

export default function SelectPackageForm({ checkedPackage, packageInformations, currency }) {
  const { t } = useTranslation()
  const {
    name: packageName,
    monthlyPrice: packagePrice,
    description: packageDescription,
    products: packageProducts,
    monthlyPrice: packageMonthlyPrice,
    specialOfferDuration,
    hasSpecialOffer,
    specialOfferPrice,
  } = packageInformations

  return (
    <S.FormSectionContainer>
      <S.PackageContainer>
        <S.PackageSelectionContainer>
          <S.PackageNameAndPrice>
            <S.PackageName>{packageName}</S.PackageName>
            <S.PackagePriceContainer>
              {!checkedPackage && (
                <>
                  <S.PackagePrice>{`${getCampaignCurrency(currency)} ${currencyFormat(packagePrice)}`}</S.PackagePrice>
                  <S.PackageMonthly>{` / ${t('monthly')}`}</S.PackageMonthly>
                </>
              )}
              {checkedPackage && hasSpecialOffer === 1 && (
                <S.PackageDiscountContainer>
                  <S.PackageSpecialOffer>
                    {t('specialOfferTitle', { monthDuration: specialOfferDuration.title })}
                  </S.PackageSpecialOffer>
                  <S.PackageWithoutDiscountContainer>
                    <S.PackagePriceWithoutDiscount>
                      {`${getCampaignCurrency(currency)} ${currencyFormat(packagePrice)}`}
                    </S.PackagePriceWithoutDiscount>
                    <S.PackageMonthly>{` / ${t('monthly')}`}</S.PackageMonthly>
                  </S.PackageWithoutDiscountContainer>
                  <S.PackageWithDiscountContainer>
                    <S.PackagePriceWithDiscount>{specialOfferPrice}</S.PackagePriceWithDiscount>
                    <S.PackageMonthly>{` / ${t('monthly')}`}</S.PackageMonthly>
                  </S.PackageWithDiscountContainer>
                </S.PackageDiscountContainer>
              )}
              {checkedPackage && hasSpecialOffer === 0 && (
                <S.PackageMonthlyPriceContainer>
                  <S.PackageWithDiscountContainer>
                    <S.PackageMonthlyPrice>{packageMonthlyPrice}</S.PackageMonthlyPrice>
                    <S.PackageMonthly>{`/ ${t('monthly')}`}</S.PackageMonthly>
                  </S.PackageWithDiscountContainer>
                </S.PackageMonthlyPriceContainer>
              )}
            </S.PackagePriceContainer>
          </S.PackageNameAndPrice>
          <RadioCheckbox disabled />
          {hasSpecialOffer === 1 && <S.SpecialOfferImage />}
        </S.PackageSelectionContainer>
        {checkedPackage && (
          <S.PackageInformationContainer>
            <S.PackageDescription>{packageDescription}</S.PackageDescription>
            <S.PackageComposition>{t('packageComposition')}</S.PackageComposition>
            <S.PackageCompositionInformationsContainer>
              {packageProducts.map(product => (
                <S.PackageCompositionInformations key={product.id}>
                  <S.ProductName>{product.name}</S.ProductName>
                  <S.Dots />
                  {product.quantity.qtdPerMonth === 1 && (
                    <S.UnitiesPerMonth>{`${product.quantity.qtdPerMonth} ${t('unityPerMonth')}`}</S.UnitiesPerMonth>
                  )}
                  {product.quantity.qtdPerMonth > 1 && (
                    <S.UnitiesPerMonth>{`${product.quantity.qtdPerMonth} ${t('unitiesPerMonth')}`}</S.UnitiesPerMonth>
                  )}
                </S.PackageCompositionInformations>
              ))}
            </S.PackageCompositionInformationsContainer>
          </S.PackageInformationContainer>
        )}
      </S.PackageContainer>
    </S.FormSectionContainer>
  )
}
