import React from 'react'
import Footer from '~/components/Footer'
import Header from './Components/Header'
import * as S from './styled'
import { MainHero } from './Partials/MainHero'
import { Principles } from './Partials/Principles'
import { Sponsor } from './Partials/Sponsor'
import { Images } from './Partials/Images'
import { Opportunities } from './Partials/Opportunities'
import ContactUs from './Partials/ContactUs'
import { PageBloobs } from './Partials/Pagebloobs/PageBloobs'

export default function LandingPage() {
  return (
    <S.PageContainer>
      <PageBloobs />

      <Header />
      <S.Container>
        <MainHero />
        <Principles />
        <Images />

        <Sponsor />
        <Opportunities />
        <ContactUs />
      </S.Container>
      <Footer />
    </S.PageContainer>
  )
}
