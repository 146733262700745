import styled from 'styled-components'
import { MdEdit } from 'react-icons/md'
import { darken } from 'polished'
import Col from 'react-bootstrap/Col'
import { customMedia } from '~/styles/global'
import WhiteButton from '~/components/WhiteButton'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  display: flex;
  align-items: center;
  border: 1px solid rgba(214, 214, 214, 0.4);
  border-radius: 5px;
  height: 75px;
  padding: 10px 0;

  svg {
    cursor: pointer;
  }
`

export const ProductTitle = styled.p`
  margin: 0px;
  color: #969faa;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.9;
  letter-spacing: 0.5px;
`

export const CommunityAndCampaignNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CampaignName = styled.p`
  margin: 0px;
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.9;
  margin-left: 15px;
  line-height: 18px;
  letter-spacing: 0.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
`

export const SponsorName = styled.p`
  margin: 0px;
  color: var(--community-primary);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-left: 15px;
  width: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`

export const ProductSubtitle = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  white-space: break-spaces;
`

export const TotalBudget = styled.p`
  margin: 0px;
  color: var(--success);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
`

export const EditButtonContainer = styled.div`
  display: flex;
  padding: 10px 0;
  width: 100%;
  flex-grow: 1;

  &:hover {
    background-color: ${darken(0.03, '#FFF')};
  }
`

export const DeleteButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;

  &:hover {
    background-color: ${darken(0.03, '#FFF')};
  }
`

export const SponsorLogoContainer = styled.div`
  width: 40px;
  height: 40px;
`

export const SponsorLogoImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
`

export const SponsorInformationsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const NameColumn = styled(Col).attrs({
  md: 4,
})``

export const CampaignModelColumn = styled(Col).attrs({
  md: 2,
})`
  padding-left: 0px !important;
`

export const TotalBudgetColumn = styled(Col).attrs({
  md: 2,
})``

export const CampaignScheduleColumn = styled(Col).attrs({
  md: 2,
})`
  ${customMedia.lessThan('1280px')`
    display: none;
  `}
`

export const MoreOptionsColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  justify-content: center;
`

export const ManageButton = styled(WhiteButton).attrs({})``

export const EditIcon = styled(MdEdit).attrs({
  color: '#0041C2',
  size: 20,
})``

export const Date = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const DateLabel = styled.p`
  margin: 0px;
  color: var(--input-title);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
`
