import ChatChannel from '~/../public/chat-product.png'
import Content from '~/../public/content-product.png'
import BottomBanner from '~/../public/bottom-banner-product.png'
import TopBanner from '~/../public/top-banner-product.png'
import Reward from '~/../public/reward-product.png'
import Stories from '~/../public/stories-product.png'
import Survey from '~/../public/survey-product.png'
import WelcomeAd from '~/../public/welcomead-product.png'
import Facebook from '~/../public/facebook-product.png'
import Twitter from '~/../public/twitter-product.png'
import Linkedin from '~/../public/linkedin-product.png'
import Instagram from '~/../public/instagram-product.png'
import Custom from '~/../public/custom-product.png'

const getProductImage = productType => {
  switch (productType) {
    case 'chatChannel':
      return ChatChannel
    case 'content':
      return Content
    case 'welcomeAd':
      return WelcomeAd
    case 'reward':
      return Reward
    case 'communityStory':
      return Stories
    case 'survey':
      return Survey
    case 'bottomStoriesBanner':
      return BottomBanner
    case 'slidingTopBanner':
      return TopBanner
    case 'linkedinPost':
      return Linkedin
    case 'facebookPost':
      return Facebook
    case 'instagramPost':
      return Instagram
    case 'twitterPost':
      return Twitter
    case 'custom':
      return Custom
    default:
      return Custom
  }
}

export default getProductImage
