import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { MdEdit } from 'react-icons/md'
import { customMedia } from '~/styles/global'
import WhiteButton from '~/components/WhiteButton'

export const ModalCreateCampaignStep1 = styled(Modal)`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: ${props => (props.step === 0 ? '950px' : '1150px')};
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 50px;
      margin-right: ${props => (props.tutorialIsActive === false ? null : '25vw')};
      position: relative;

      ${customMedia.lessThan('2060px')`
        margin-right: ${props => (props.tutorialIsActive === false ? null : '15vw')};
      `}

      ${customMedia.lessThan('1790px')`
        margin-right: ${props => (props.tutorialIsActive === false ? null : '2vw')};
      `}

      ${customMedia.lessThan('1410px')`
        margin-right: ${props => (props.tutorialIsActive === false ? null : '0vw')};
      `}

      ${customMedia.lessThan('1376px')`
        height: auto;
        margin: auto;
        padding: 0;
        margin-top: 50px;
      `}
    }
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
  .modal-body {
    padding: 10px 30px 30px 30px !important;
  }
`

export const ModalCreateCampaignStep2 = styled(Modal).attrs({
  backdrop: 'static',
  keyboard: false,
})`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: ${props => (props.step === 0 ? '950px' : '1150px')};
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 50px;
      margin-right: ${props => (props.tutorialIsActive === false ? null : '20vw')};
      position: relative;

      ${customMedia.lessThan('2100px')`
        margin-right: ${props => (props.tutorialIsActive === false ? null : '5vw')};
      `}

      ${customMedia.lessThan('1790px')`
        margin-right: ${props => (props.tutorialIsActive === false ? null : '2vw')};
      `}

      ${customMedia.lessThan('1410px')`
        margin-right: ${props => (props.tutorialIsActive === false ? null : '0vw')};
      `}

      ${customMedia.lessThan('1612px')`
        height: auto;
        margin: auto;
        padding: 0;
        margin-top: 50px;
      `}
    }
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
  .modal-body {
    padding: 10px 30px 30px 30px !important;
  }
`

export const Container = styled.div`
  position: relative;
  text-align: center;
`

export const Title = styled.p`
  color: var(--text-bold);
  margin: 0px;
  font-family: Lato;
  font-size: 17px;
  font-weight: 600;
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-start !important;
`

export const ModalFooterStepTwo = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between !important;
`

export const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 170px;
  background-image: linear-gradient(to right, #00a2ff 50%, #0090de 100%);
  position: relative;
  z-index: 10 !important;
  padding-top: 10px;
`

export const StepContainerTitle = styled.p`
  color: #ffffff;
  font-family: Montserrat;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
`

export const CancelButton = styled(WhiteButton).attrs({
  style: { padding: '0px 30px' },
})``

export const ManageButton = styled(WhiteButton).attrs({
  style: { marginRight: '20px' },
})``

export const ManageIcon = styled(MdEdit).attrs({
  color: 'var(--sponsor-primary)',
  size: 20,
})``

export const DoItLaterButton = styled(WhiteButton).attrs({
  text: 'Do it later',
  style: { padding: '0px 30px' },
})``

export const SetCampaignBackground = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 75px;
  left: 495px;
  border-radius: 100px;
  background-color: #fff;
  z-index: 1 !important;

  @media (max-width: 1174px) {
    display: none;
  }
`

export const PaymentBackground = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 75px;
  left: 632px;
  border-radius: 100px;
  background-color: #fff;
  z-index: 1 !important;

  @media (max-width: 1174px) {
    display: none;
  }
`
