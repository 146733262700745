import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import JoyRide from 'react-joyride'
import { useTranslation } from 'react-i18next'
import SearchInput from '~/components/SearchInput'
import ZeroRegisterIndicator from '~/components/ZeroRegisterIndicator'
import PackageTabCard from '~/components/Community/PackagesTab/PackageTabCard'
import ModalComposePackage from '~/components/Modals/ModalComposePackage'
import TabTitleAndSubtitle from '~/components/Community/TabTitleAndSubtitle'
import { returnDataFiltered } from '~/helper/formFunctions'
import useCommunityFunctions from '~/helper/communityFunctions'
import PackagesTabTooltip from '~/components/ApplicationTutorial/Community/PackagesTabTooltip'
import { CommunityGridTitle } from '~/components/Shared/StyledComponents'
import Dialog from '~/components/Shared/Dialog'
import * as CONST from '~/helper/const'
import * as S from './styled'

export default function PackageTab({ packages, setCallbackToGetPackages }) {
  const { t } = useTranslation()
  const { deletePackageAction } = useCommunityFunctions()
  const communityId = useSelector(state => state.community.currentCommunity.id)
  const tutorialIsActive = useSelector(state => state.tutorial.tutorialIsActive)
  const [showModalComposePackage, setShowModalComposePackage] = useState(false)
  const [selectedPackages, setSelectedPackages] = useState([])
  const [selectedPackagesCallback, setSelectedPackagesCallback] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [filter, setFilter] = useState('')
  const filteredPackages = returnDataFiltered(filter, packages)

  const handleDeletePackagesButton = () => {
    if (selectedPackages.length > 0) {
      deletePackageAction(selectedPackages, communityId)
      setTimeout(() => {
        setCallbackToGetPackages()
      }, 2000)
    }
  }

  useEffect(() => {
    setSelectedPackages(selectedPackages)
  }, [selectedPackages, selectedPackagesCallback])

  return (
    <S.Container>
      <JoyRide
        steps={CONST.communityPackagesTooltipOptions}
        run={tutorialIsActive}
        tooltipComponent={PackagesTabTooltip}
        styles={{
          options: {
            arrowColor: 'transparent',
          },
        }}
      />
      <TabTitleAndSubtitle title={t('sponsoredPackages')} subtitle={t('createCompositionSponsoredFormats')} />
      <S.SearchInputContainer>
        <SearchInput filter={filter} setFilter={setFilter} />
        <S.ButtonContainer>
          {selectedPackages.length > 0 && (
            <S.DeletePackageButton
              text={t('delete')}
              large
              icon={<S.DeleteIcon />}
              onClick={() => setOpenDialog(true)}
            />
          )}
          <S.NewPackageButton
            text={t('newPackage')}
            icon={<S.NewPackageIcon />}
            onClick={() => setShowModalComposePackage(true)}
          />
        </S.ButtonContainer>
      </S.SearchInputContainer>
      <S.ProductsTabContainer>
        <S.GridTitleContainer>
          <S.CheckboxColumn />
          <S.PackageColumn>
            <CommunityGridTitle row>{t('package')}</CommunityGridTitle>
          </S.PackageColumn>
          <S.CompositionColumn>
            <CommunityGridTitle row>{t('composition')}</CommunityGridTitle>
          </S.CompositionColumn>
          <S.MonthlyPriceColumn>
            <CommunityGridTitle row>{t('monthlyPrice')}</CommunityGridTitle>
          </S.MonthlyPriceColumn>
          <S.SpecialOfferColumn>
            <CommunityGridTitle row>{t('specialOffer')}</CommunityGridTitle>
          </S.SpecialOfferColumn>
          <S.StatusColumn>
            <CommunityGridTitle row>{t('status')}</CommunityGridTitle>
          </S.StatusColumn>
          <S.EditColumn>
            <CommunityGridTitle row>{t('edit')}</CommunityGridTitle>
          </S.EditColumn>
        </S.GridTitleContainer>
        {filteredPackages?.length === 0 && <ZeroRegisterIndicator text={t('noPackagesCreated')} />}
        {filteredPackages.map(packageInfo => (
          <PackageTabCard
            key={packageInfo.id}
            package={packageInfo}
            selectedPackages={selectedPackages}
            selectPackage={setSelectedPackages}
            deletePackage={deletePackageAction}
            setCallbackToGetPackages={setCallbackToGetPackages}
            setSelectedPackagesCallback={setSelectedPackagesCallback}
          />
        ))}
      </S.ProductsTabContainer>
      <ModalComposePackage
        showModal={showModalComposePackage}
        handleCloseModal={() => setShowModalComposePackage(false)}
        setCallbackToGetPackages={setCallbackToGetPackages}
      />
      <Dialog
        openDialog={openDialog}
        closeDialog={() => setOpenDialog(false)}
        dialogTitle={t('attention')}
        dialogText={t('doYouWantToContinue')}
        cancelAction={() => setOpenDialog(false)}
        confirmAction={handleDeletePackagesButton}
      />
    </S.Container>
  )
}
