import i18n from '~/i18n'

const useGetCommunityCurrencySymbol = currency => {
  switch (currency) {
    case null:
      return '€'
    case 'eur':
      return '€'
    case 'chf':
      return 'CHF'
    case 'usd':
      return '$'
    case 'brl':
      return 'R$'
    default:
      return `${i18n.t('currencySymbol')}`
  }
}

export default useGetCommunityCurrencySymbol
