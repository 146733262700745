import styled from 'styled-components'
import { darken } from 'polished'

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 100%;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#27b8fe')};
  width: ${props => (props.width ? props.width : '34px')};
  height: ${props => (props.height ? props.height : '34px')};

  &:hover {
    background-color: ${props =>
      props.backgroundColor ? darken(0.05, props.backgroundColor) : darken(0.05, '#27B8FE')};
  }

  img {
    width: ${props => (props.imgWidth ? props.imgWidth : '19px')};
    height: ${props => (props.imgHeight ? props.imgHeight : '19px')};
  }
`

export const EditProfileImage = styled.img.attrs({
  src: '/icons/edit.png',
})`
  width: 22px !important;
  height: 22px !important;
`

export const ReviewProfileImage = styled.img.attrs({
  src: '/icons/eye.png',
})``
