/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { RiSave2Line } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { MdCompareArrows } from 'react-icons/md'
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal'
import { format } from 'date-fns'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TextField from '@material-ui/core/TextField'
import Container from 'react-bootstrap/Container'
import * as CONST from '~/helper/const'
import Border from '~/components/Shared/Border'
import ProfileButton from '~/components/ProfileButton'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import ModalEditSurvey from '~/components/Sponsor/Modals/Survey/ModalEditSurvey'
import { InputTitle } from '~/components/Shared/StyledComponents'
import { compareCampaignStartDateAndEndDate, transformDateToUtcFormat } from '~/helper/getFormattedDates'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import ModalEditMultipleSurvey from '~/components/Sponsor/Modals/Survey/ModalEditMultipleSurvey'

import * as S from './styled'

export function ModalEditSurveyFirstStep({
  handleCloseModal,
  showModal,
  surveys,
  review,
  createMessage,
  refuseCampaignProduct,
  formatId,
  sponsor,
}) {
  const { t } = useTranslation()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const { handleSubmit, register, setValue, errors, control } = useForm()
  const [date, setDate] = useState(transformDateToUtcFormat(surveys.dtStart == null ? surveys.date : surveys.dtStart))
  const [endDate, setEndDate] = useState(
    transformDateToUtcFormat(surveys.dtEnd == null ? surveys.endDate : surveys.dtEnd),
  )
  const [display, setDisplayModalEdit] = useState('true')
  const [surveyObjectToUpdate, setSurveyObjectToUpdate] = useState(surveys)
  const { updateCampaignFormatAction } = useSponsorFunctions()

  function modalReviewCreateMessageAndCloseModal() {
    createMessage()
    refuseCampaignProduct()
    handleCloseModal()
  }

  function getNumberOfQuestions(numberSurveyQuestions) {
    let questionsObject = null
    CONST.numberOfSurveyQuestionsOptions.forEach(number => {
      if (number.value === numberSurveyQuestions) return (questionsObject = number)
    })
    return questionsObject
  }
  const [numberSurveyQuestions] = useState(getNumberOfQuestions(surveys.numberSurveyQuestions))

  function onSubmitUpdate(product) {
    const validateStartAndEndDate = compareCampaignStartDateAndEndDate(date, endDate)
    if (validateStartAndEndDate) {
      return toast.error(t('startDateHigherThenEndDate'))
    }

    product.numberSurveyQuestions = numberSurveyQuestions.value
    product.questions = surveyObjectToUpdate.questions
    updateCampaignFormatAction(product, formatId)
    handleCloseModal()
  }

  useEffect(() => {
    register({ name: 'id' })
    setValue('id', surveys.id)
  }, [register, surveys.id, setValue])

  return (
    <>
      <S.ModalCustom display={display} show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {review ? t('reviseFormat', { format: t('survey') }) : t('editFormat', { format: t('survey') })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#eee', paddingBottom: '28px' }}>
          <Container fluid>
            <form id="my-form-edit" onSubmit={handleSubmit(onSubmitUpdate)}>
              <Row>
                <Col md={12}>
                  <InputTitle>{t('howManyQuestions')}</InputTitle>
                  <Controller
                    as={
                      <Select
                        styles={CONST.reactSelectStyleDefault}
                        options={CONST.numberOfSurveyQuestionsOptions}
                        placeholder={t('placeholderSelect')}
                        isDisabled
                      />
                    }
                    name="numberSurveyQuestions"
                    control={control}
                    onChange={([selected]) => {
                      return selected
                    }}
                    defaultValue={numberSurveyQuestions}
                  />
                </Col>
                <>
                  <div className="w-100 d-flex">
                    <Col md={12} className="mt-3">
                      <InputTitle>{t('surveyTitle')}</InputTitle>
                      <Controller
                        as={
                          <TextField
                            fullWidth
                            variant="outlined"
                            placeholder={t('typeTitle')}
                            disabled={review || false}
                          />
                        }
                        rules={{ required: t('defaultValidation', { inputName: t('surveyTitle') }) }}
                        name="name"
                        control={control}
                        defaultValue={surveys.name}
                      />
                      <div className="error-message">
                        <ErrorMessage errors={errors} name="name" as="p" />
                      </div>
                    </Col>
                  </div>

                  <Col md={6} className="mt-1">
                    <StartDateSection
                      product={t('survey')}
                      selectedCampaignStartDate={date}
                      setCampaignStartDate={setDate}
                      campaignStartDate={currentCampaignForReviewPage.dtStart}
                      campaignEndDate={currentCampaignForReviewPage.dtEnd}
                      disabled={review}
                      control={control}
                      errors={errors}
                    />
                  </Col>

                  <Col md={6} className="mt-1">
                    <EndDateSection
                      product={t('survey')}
                      selectedCampaignStartDate={date}
                      selectedCampaignEndDate={endDate}
                      campaignEndDate={currentCampaignForReviewPage.dtEnd}
                      setCampaignEndDate={setEndDate}
                      disabled={review}
                      control={control}
                      errors={errors}
                    />
                  </Col>

                  <Col md={12} className="mt-2">
                    <div className="w-50 mt-3">
                      <InputTitle>{t('questions')}</InputTitle>
                      {surveyObjectToUpdate?.questions?.map(survey => (
                        <div key={survey.id} className="animated fadeIn mt-2">
                          {survey && (
                            <EditSurvey
                              setDisplayModalEdit={setDisplayModalEdit}
                              survey={survey}
                              surveys={surveyObjectToUpdate}
                              createMessage={createMessage}
                              review={review}
                              sponsor={sponsor}
                              setSurveyObjectToUpdate={setSurveyObjectToUpdate}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </Col>
                  <div className="mt-3">
                    <Border />
                  </div>
                </>
              </Row>
            </form>
          </Container>
        </Modal.Body>

        <S.ModalFooter>
          {review === undefined ||
            (review === false && (
              <S.UpdateButton form="my-form-edit" icon={<RiSave2Line color="var(--success)" size={20} />} />
            ))}

          {review === true && sponsor === false && (
            <S.ButtonContainer>
              <S.RequestButtonContainer>
                <S.RequestChangeButton
                  icon={<MdCompareArrows color="#f64859" size={26} />}
                  onClick={() => modalReviewCreateMessageAndCloseModal()}
                />
              </S.RequestButtonContainer>
              <S.DoneButton text={t('done')} icon={<S.DoneIcon />} onClick={() => handleCloseModal()} />
            </S.ButtonContainer>
          )}

          {review === true && sponsor && (
            <S.ButtonsContainer>
              <S.DoneButton text={t('done')} onClick={() => handleCloseModal()} icon={<S.DoneIcon />} />
            </S.ButtonsContainer>
          )}
        </S.ModalFooter>
      </S.ModalCustom>
    </>
  )
}

export function EditSurvey({
  survey,
  surveys,
  review,
  sponsor,
  setDisplayModalEdit,
  createMessage,
  setSurveyObjectToUpdate,
}) {
  const [showModalEditSurvey, setShowModalEditSurvey] = useState(false)
  const [showModalEditMultipleSurvey, setShowModalEditMultipleSurvey] = useState(false)
  const dateFormatted = format(transformDateToUtcFormat(surveys?.date || surveys.dtStart), 'dd/MM/yyyy')

  let surveyIndex
  if (surveys !== undefined) {
    surveyIndex = surveys.questions.findIndex(p => p.id === survey.id)
  }

  function openModalToEditMultipleSurveyAndHideFirstStepModal() {
    setDisplayModalEdit('false')
    setShowModalEditMultipleSurvey(true)
  }

  function openModalToEditSurveyAndHideFirstStepModal() {
    setDisplayModalEdit('false')
    setShowModalEditSurvey(true)
  }

  return (
    <>
      {/* survey have multiple questions */}
      {surveys !== undefined && surveys.questions && surveys.questions.length > 1 ? (
        <S.CardBody>
          <div className="reward-informations">
            <S.Date>{`Question ${surveyIndex + 1} `}</S.Date>
            <S.About>{survey.question}</S.About>
          </div>
          <div className="buttons-section">
            <ProfileButton
              type="button"
              onClick={openModalToEditMultipleSurveyAndHideFirstStepModal}
              style={{ marginTop: '3px' }}
              backgroundColor="#27b8fe"
            />
          </div>
          <ModalEditMultipleSurvey
            handleCloseModal={() => setShowModalEditMultipleSurvey(false)}
            showModal={showModalEditMultipleSurvey}
            surveyToUpdate={survey}
            surveyIndex={surveyIndex}
            numberSurveyQuestions={surveys.numberSurveyQuestions}
            surveyInformations={surveys}
            setShowModalEditMultipleSurvey={setDisplayModalEdit}
            review={review}
            sponsor={sponsor}
            createMessage={createMessage}
            setSurveyObjectToUpdate={setSurveyObjectToUpdate}
          />
        </S.CardBody>
      ) : (
        // survey does not have multiple questions
        <S.CardBody>
          <div className="reward-informations">
            <S.Date>{dateFormatted}</S.Date>
            <S.About>{survey.question}</S.About>
          </div>
          <div className="buttons-section">
            <ProfileButton
              type="button"
              onClick={openModalToEditSurveyAndHideFirstStepModal}
              style={{ marginTop: '3px' }}
              backgroundColor="#27b8fe"
            />
          </div>
          <ModalEditSurvey
            handleCloseModalEditSurvey={() => setShowModalEditSurvey(false)}
            showModal={showModalEditSurvey}
            surveyToUpdate={survey}
            surveyInformations={surveys}
            setShowModalEditMultipleSurvey={setDisplayModalEdit}
            review={review}
            sponsor={sponsor}
            createMessage={createMessage}
            setSurveyObjectToUpdate={setSurveyObjectToUpdate}
          />
        </S.CardBody>
      )}
    </>
  )
}
