import React from 'react'
import { DefaultLogoImage } from '~/util/defaultImages'
import * as S from './styled'

const InstagramContentPreview = ({ community, inputWatch, media, videoThumbnail }) => {
  return (
    <>
      <S.TopPreviewContainer>
        <S.PostHeader>
          <S.CommunityInformations>
            <S.CommunityLogoBorder>
              <S.CommunityLogo src={community?.networkLogo || DefaultLogoImage} />
            </S.CommunityLogoBorder>
            <S.CommunityName>{community?.fullName}</S.CommunityName>
            <S.InstagramCerfifiedLogo />
          </S.CommunityInformations>
          <S.ThreeDotsIcon />
        </S.PostHeader>
      </S.TopPreviewContainer>

      <S.MiddlePreviewContainer>
        {media ? (
          <S.MediaContainer>
            <S.MediaImage src={videoThumbnail || media} />
            {videoThumbnail && (
              <S.PlayIconContainer>
                <S.PlayIcon />
              </S.PlayIconContainer>
            )}
          </S.MediaContainer>
        ) : (
          <S.MediaContainer>
            <S.NoMediaIndicator>
              <S.ImageIcon />
            </S.NoMediaIndicator>
          </S.MediaContainer>
        )}
        <S.IconsContainer>
          <S.Actions>
            <S.LikeIcon />
            <S.ChatIcon />
            <S.SendIcon />
          </S.Actions>
          <S.BookmarkIcon />
        </S.IconsContainer>

        <S.Views>5.224 views</S.Views>

        <S.CommunityNameContainer>
          <S.CommunityPostName>{community?.fullName}</S.CommunityPostName>

          {inputWatch?.description ? (
            <S.PostCommentAndDescription>{`${inputWatch?.title} - ${inputWatch.description}`}</S.PostCommentAndDescription>
          ) : (
            <S.PostComment>{inputWatch?.title}</S.PostComment>
          )}
        </S.CommunityNameContainer>
      </S.MiddlePreviewContainer>
    </>
  )
}

export default InstagramContentPreview
