import styled from 'styled-components'
import { customMedia } from '~/styles/global'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  z-index: 2;
  margin-bottom: 24px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`

export const ImageContainer = styled.div`
  position: relative;
  height: 200px;
`

export const BannerImage = styled.img.attrs({
  alt: 'Sponsor Banner',
})`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const LogoImage = styled.img.attrs({
  alt: 'Sponsor Logo',
})`
  position: absolute;
  width: 79px;
  height: 79px;
  left: 10px;
  bottom: calc(-79px / 2);
  border: 4px solid #fff;
  border-radius: 50%;
  background-color: #fff;
  object-fit: cover;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export const SponsorInformationSection = styled.div`
  background-color: #fff;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

export const CampaignSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const TotalCampaigns = styled.span`
  color: #343f4b;
  font-family: Roboto;
  font-size: 17px;
  font-weight: bold;
  padding-right: 15px;
  padding-left: 15px;
`

export const TotalViews = styled(TotalCampaigns)``

export const CampaignLabel = styled.div`
  color: var(--grey);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.5px;
`

export const EditButtonContainer = styled.div`
  transition: all 50ms ease;

  img {
    ${customMedia.lessThan('huge')`
      width: 15px;
      height: 15px;
    `}
  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    position: relative;
    top: 2px;
  }
`

export const ButtonSection = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 15px;
  bottom: calc(-32px / 2);

  button {
    margin: 5px;

    ${customMedia.lessThan('huge')`
      width: 28px;
      height: 28px;
    `}
  }
`

export const SponsorNameAndRating = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 45px 15px 0px 15px;
`

export const SponsorName = styled.span`
  color: #343f4b;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  width: 170px;
  opacity: 0.9;
  letter-spacing: 0.5px;

  ${customMedia.lessThan('huge')`
    font-size: 16px;
  `}
`

export const SponsorLocation = styled.span`
  color: var(--grey);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
`

export const SponsorLocationAndRating = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 8px 15px 10px 15px;
  margin-top: -5px;

  ${customMedia.lessThan('1370px')`
    margin-top: 0px;
  `}
`

export const CampaignViewsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const SocialMediaContainer = styled.div``

export const BorderContainer = styled.div`
  margin: ${props => (props.top ? '4px 0px 16px 0px' : '16px 0px')};
`
