import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

export default () => {
  const [interests, setInterests] = useState([])
  const entityType = useSelector(state => state.user.type)

  const communityInterests = useSelector(state => state.community?.currentCommunity?.interests)
  const communityIdFromSponsorChat = useSelector(state => state.chat?.activeChannel?.communityId)
  const allCommunitiesFromTheSponsor = useSelector(state => state.sponsor?.allCommunitiesFromSponsor)

  useEffect(() => {
    if (entityType === 'sponsor') {
      allCommunitiesFromTheSponsor.forEach(community => {
        if (community.id === communityIdFromSponsorChat) {
          setInterests(community?.interests)
        }
      })
    } else {
      setInterests(communityInterests)
    }
  }, [allCommunitiesFromTheSponsor, communityIdFromSponsorChat, communityInterests, entityType])
  return { interests }
}
