import * as actions from './actionTypes'

export function uploadS3Request(file) {
  return {
    type: actions.UPLOAD_S3_REQUEST,
    payload: { file },
  }
}

export function uploadS3Success(id, url) {
  return {
    type: actions.UPLOAD_S3_SUCCESS,
    payload: { id, url },
  }
}

export function removeS3FromState() {
  return {
    type: actions.REMOVE_S3_FROM_STATE,
  }
}

export function setFirstLogin() {
  return {
    type: actions.SET_FIRST_LOGIN,
  }
}

export function setCommunityHasStripeAccount(status) {
  return {
    type: actions.SET_COMMUNITY_HAS_STRIPE_ACCOUNT,
    payload: status,
  }
}

export function setUserCurrentCommunity(community) {
  return {
    type: actions.SET_USER_CURRENT_COMMUNITY,
    payload: community,
  }
}

export function setUserCurrentCommunityAfterSignUp(community) {
  return {
    type: actions.SET_USER_CURRENT_COMMUNITY_AFTER_SIGN_UP,
    payload: community,
  }
}

export function updateUserCurrentCommunityInformations(community) {
  return {
    type: actions.UPDATE_USER_CURRENT_COMMUNITY_INFORMATIONS,
    payload: community,
  }
}

export function getCommunityLinkInvitationRequest(networkId) {
  return {
    type: actions.GET_COMMUNITY_LINK_INVITATION_REQUEST,
    payload: { networkId },
  }
}

export function getCommunityLinkInvitationFailure() {
  return {
    type: actions.GET_COMMUNITY_LINK_INVITATION_FAILURE,
  }
}

export function getCommunityLinkInvitationSuccess(code) {
  return {
    type: actions.GET_COMMUNITY_LINK_INVITATION_SUCCESS,
    payload: { code },
  }
}

export function updateCommunityProductRequest(networkId, productId, status, price) {
  return {
    type: actions.UPDATE_COMMUNITY_PRODUCT_REQUEST,
    payload: { networkId, productId, status, price },
  }
}

export function updateCommunityProductSuccess(products) {
  return {
    type: actions.UPDATE_COMMUNITY_PRODUCT_SUCCESS,
    payload: { products },
  }
}

export function updateCommunityProfileRequest(id, data) {
  return {
    type: actions.UPDATE_COMMUNITY_PROFILE_REQUEST,
    payload: { id, data },
  }
}

export function updateCommunityProfileLoading() {
  return {
    type: actions.UPDATE_COMMUNITY_PROFILE_LOADING,
  }
}

export function updateCommunityProfileSuccess() {
  return {
    type: actions.UPDATE_COMMUNITY_PROFILE_SUCCESS,
  }
}

export function updateCommunityProfileFailure() {
  return {
    type: actions.UPDATE_COMMUNITY_PROFILE_FAILURE,
  }
}

export function resetCommunityInformations() {
  return {
    type: actions.RESET_COMMUNITY_INFORMATIONS,
  }
}

export function getCommunityInformationsByInvitationLinkRequest(code) {
  return {
    type: actions.GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST,
    payload: { code },
  }
}

export function getCommunityInformationsByInvitationLinkSuccess(communityInformations) {
  return {
    type: actions.GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_SUCCESS,
    payload: { communityInformations },
  }
}

export function getCommunityInformationsByInvitationLinkFailure() {
  return {
    type: actions.GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_FAILURE,
  }
}

export function createCustomAdvertisementProduct(product, communityId, setCallbackToGetProducts) {
  return {
    type: actions.CREATE_CUSTOM_ADVERTISEMENT_PRODUCT,
    payload: { product, communityId, setCallbackToGetProducts },
  }
}

export function updateCustomAdvertisementProduct(productId, product, communityId, setCallbackToGetProducts) {
  return {
    type: actions.UPDATE_CUSTOM_ADVERTISEMENT_PRODUCT,
    payload: { productId, product, communityId, setCallbackToGetProducts },
  }
}

export function deleteCustomAdvertisementProduct(productIds, communityId) {
  return {
    type: actions.DELETE_CUSTOM_ADVERTISEMENT_PRODUCT,
    payload: { productIds, communityId },
  }
}

export function updateCustomAdvertisementProductActiveStatus(status, productId, communityId) {
  return {
    type: actions.UPDATE_CUSTOM_ADVERTISEMENT_PRODUCT_ACTIVE_STATUS,
    payload: { status, productId, communityId },
  }
}

export function createPackage(packageInfo, communityId, setCallbackToGetPackages) {
  return {
    type: actions.CREATE_PACKAGE,
    payload: { packageInfo, communityId, setCallbackToGetPackages },
  }
}

export function updatePackage(packageId, packageInfo, communityId, setCallbackToGetPackages) {
  return {
    type: actions.UPDATE_PACKAGE,
    payload: { packageId, packageInfo, communityId, setCallbackToGetPackages },
  }
}

export function updatePackageActiveStatus(status, packageId, communityId) {
  return {
    type: actions.UPDATE_PACKAGE_ACTIVE_STATUS,
    payload: { status, packageId, communityId },
  }
}

export function deletePackage(packageIds, communityId) {
  return {
    type: actions.DELETE_PACKAGE,
    payload: { packageIds, communityId },
  }
}

export function updateCommunitySponsorCooperationStatus(status, communityId, sponsorId) {
  return {
    type: actions.UPDATE_COMMUNITY_SPONSOR_COOPERATION_STATUS,
    payload: { status, communityId, sponsorId },
  }
}

export function updateSponsorCommunityCooperationStatus(status, communityId, sponsorId) {
  return {
    type: actions.UPDATE_SPONSOR_COMMUNITY_COOPERATION_STATUS,
    payload: { status, communityId, sponsorId },
  }
}

export function addCommunityGalleryMediaRequest(communityId, uploadTypeName, image) {
  return {
    type: actions.ADD_COMMUNITY_GALLERY_MEDIA_REQUEST,
    payload: { communityId, uploadTypeName, image },
  }
}

export function addCommunityGalleryMediaFailure(type) {
  return {
    type: actions.ADD_COMMUNITY_GALLERY_MEDIA_FAILURE,
    payload: { type },
  }
}

export function addCommunityGalleryMediaSuccess(type, newMedia) {
  return {
    type: actions.ADD_COMMUNITY_GALLERY_MEDIA_SUCCESS,
    payload: { type, newMedia },
  }
}

export function deleteCommunityGalleryMediaRequest(index, type, communityId, communityMediaId) {
  return {
    type: actions.DELETE_COMMUNITY_GALLERY_MEDIA_REQUEST,
    payload: { index, type, communityId, communityMediaId },
  }
}

export function deleteCommunityGalleryMediaFailure(index, type) {
  return {
    type: actions.DELETE_COMMUNITY_GALLERY_MEDIA_FAILURE,
    payload: { index, type },
  }
}

export function deleteCommunityGalleryMediaSuccess(type, communityMediaId) {
  return {
    type: actions.DELETE_COMMUNITY_GALLERY_MEDIA_SUCCESS,
    payload: { type, communityMediaId },
  }
}
