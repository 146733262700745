import axios from 'axios'
import { configure } from 'axios-hooks'
import { store } from '~/store'
import { signOut as signOutAction } from '~/store/modules/auth/actions'
import tokenRequestInterceptor from './tokenRequestInterceptor'

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
})

api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config
    const { dispatch } = store
    if (error?.response?.status === 401 && !originalRequest._retry && originalRequest.method === 'get') {
      dispatch(signOutAction())
    }
    return Promise.reject(error)
  },
)

api.interceptors.request.use(tokenRequestInterceptor)

/* 
  This is configuring the `useAxios` hook to use the adv-api axios instance,
  it is used on `/pages/Community/index.js` to call for the user-alerts. 

*/
configure({ axios: api })

export default api
