import React from 'react'
import { truncate } from 'lodash'
import { timeAgo } from '~/helper/helperFunctions'
import ShouldRender from '~/components/ShouldRender'
import ImagePreview from '~/components/Chat/ImagePreview'
import AudioPlayer from '~/components/Chat/AudioPlayer'
import FilePreview from '~/components/Chat/FilePreview'
import MessageText from '~/components/Chat/MessageText'
import { messageType } from '~/helper/const'
import video from '~/../public/video.png'
import cam from '~/../public/cam.png'
import gif from '~/../public/gif.png'
import * as S from './styled'

const PreviewReply = ({ reply, message, entireMessage, sentByMe }) => {
  const isQuestion = /qf-/g.test(reply.replyType)
  const isText = [messageType.Message, messageType.Link].includes(reply.replyType)
  const isMedia = [messageType.Video, messageType.Image, messageType.Gif].includes(reply.replyType)
  const isHubContent = reply.replyType === 'HubContent'
  const isFile = reply.replyType === messageType.File

  const getMediaIcon = () => {
    const icons = {
      [messageType.Image]: cam,
      [messageType.Video]: video,
      [messageType.Gif]: gif,
      [messageType.Content]: <S.ContentIcon />,
    }
    return icons[reply.replyType] || ''
  }

  const getMediaLabel = () => {
    const labels = {
      [messageType.Gif]: 'GIF',
      [messageType.Image]: 'Photo',
      [messageType.Video]: 'Video',
      [messageType.Content]: 'Content',
    }
    return labels[reply.replyType] || ''
  }

  return (
    <S.ReplyContainer>
      <S.QuotesIcon />
      <ShouldRender if={isMedia || isHubContent}>
        <S.MediaPreviewContainer>
          <S.ImagePreviewContainer>
            <ImagePreview
              width={60}
              media={reply.replyType === messageType.Video ? [reply.replyVideo, reply.replyImage] : [reply.replyImage]}
              type={reply.replyType === messageType.Video ? 'video' : 'image'}
              message={entireMessage}
              mediaFromUserInput={false}
            />
          </S.ImagePreviewContainer>
          <S.IconTextContainer>
            {isMedia && <S.ReplyIcon src={getMediaIcon()} alt={getMediaLabel()} />}
            {isHubContent && <S.ContentIcon />}
            <S.ReplyTypeOfMedia>{getMediaLabel()}</S.ReplyTypeOfMedia>
          </S.IconTextContainer>
        </S.MediaPreviewContainer>
      </ShouldRender>
      <ShouldRender if={isText || isQuestion}>
        <S.IconContainer>
          <ShouldRender if={isQuestion}>
            <S.FileReplyIcon />
          </ShouldRender>
          <MessageText
            entireMessage={entireMessage}
            message={entireMessage.reply?.replyMessage}
            isVideoOrImage={false}
            isReply
          />
        </S.IconContainer>
      </ShouldRender>
      <ShouldRender if={reply.replyType === messageType.Voice}>
        <S.AudioContainer>
          <S.AudioReplyIcon />
          <AudioPlayer {...message} audio={reply.replyAudio} width={210} isReply singleMessage />
        </S.AudioContainer>
      </ShouldRender>
      <ShouldRender if={isFile}>
        <FilePreview
          sentByMe={sentByMe}
          fileSize={reply.replyFileSize}
          fileName={reply.replyFileName}
          fileUrl={reply.replyFileUrl}
          fileExtension={reply.replyFileExtension}
        />
      </ShouldRender>
      <S.Author>
        {`${truncate(reply?.replyName, { length: 20 })}, ${String(timeAgo(reply?.replyData).toLowerCase())}`}
      </S.Author>
    </S.ReplyContainer>
  )
}

export default PreviewReply
