import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { RewardReviewCard } from '~/components/Community/ReviewCampaign/RewardReviewCard'
import { SurveyReviewSingleContentCard } from '~/components/Community/ReviewCampaign/SurveyReviewSingleContentCard'
import { EventReviewCard } from '~/components/Community/ReviewCampaign/EventReviewCard'
import { WelcomeAdReviewCard } from '~/components/Community/ReviewCampaign/WelcomeAdReviewCard'
import { ChatChannelReviewCard } from '~/components/Community/ReviewCampaign/ChatChannelReviewCard'
import { SingleContentReviewCard } from './SingleContentReviewCard'
import { BannerReviewCard } from './BannerReviewCard'
import * as S from './styled'

export default function ModalSingleFormatAnalytics({ handleCloseModal, showModal, campaign, analyticsData }) {
  const { t } = useTranslation()
  const { dtEnd: campaignEndDate, networkId: communityId } = campaign

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.Title>{t('formatAnalytics')}</S.Title>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* CHAT CHANNEL ANALYTICS CARD */}
        {analyticsData !== null && analyticsData?.chatChannel?.length > 0 && (
          <ChatChannelReviewCard
            handleCloseModalAnalytics={handleCloseModal}
            chatChannels={analyticsData?.chatChannel}
            campaignEndDate={campaignEndDate}
            communityId={communityId}
            singleContent
          />
        )}

        {/* REWARD ANALYTICS CARD */}
        {analyticsData !== null && analyticsData?.reward && (
          <RewardReviewCard rewards={[analyticsData?.reward]} singleContent />
        )}

        {/* EVENT ANALYTICS CARD */}
        {analyticsData !== null && analyticsData?.event && <EventReviewCard events={[analyticsData?.event]} />}

        {/* WELCOME AD ANALYTICS CARD */}
        {analyticsData !== null && analyticsData?.welcomeAd && (
          <WelcomeAdReviewCard welcomeAds={[analyticsData?.welcomeAd]} singleContent />
        )}

        {/* CONTENT ANALYTICS CARD */}
        {analyticsData !== null && analyticsData?.content && (
          <SingleContentReviewCard contents={analyticsData.content} />
        )}

        {/* TOP AND BOTTOM BANNER ANALYTICS CARD */}
        {analyticsData !== null && (analyticsData?.slidingTopBanner || analyticsData?.bottomStoriesBanner) && (
          <BannerReviewCard
            banner={analyticsData?.slidingTopBanner || analyticsData.bottomStoriesBanner}
            type={analyticsData?.slidingTopBanner ? 'topBanner' : 'bottomBanner'}
          />
        )}

        {/* SURVEY ANALYTICS CARD */}
        {analyticsData !== null && analyticsData?.questions && (
          <SurveyReviewSingleContentCard surveys={[analyticsData]} campaign={campaign} />
        )}
      </Modal.Body>
      <S.ModalFooter>
        <S.CloseButton text={t('close')} action={handleCloseModal} />
      </S.ModalFooter>
    </S.ModalCustom>
  )
}
