import styled from 'styled-components'
import { customMedia } from '~/styles/global'

export const CardContainer = styled.div`
  background-color: #fff;
  border-radius: 5px;

  ${customMedia.lessThan('small')`
    margin-top: 15px;
  `}

  span {
    text-transform: uppercase;
    color: #858588;
    font-family: Lato;
    font-size: 12px;
    font-weight: 700;
    padding-left: 10px;
    padding-top: 15px;
  }
`
