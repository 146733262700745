/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useCallback } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation } from 'react-i18next'
import Slider from '@material-ui/core/Slider'
import { toast } from 'react-toastify'
import Cropper from 'react-easy-crop'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { base64ImageToBlob } from '~/helper/formFunctions'
import { modalBodyStyleDefault, base64DefaultBanner } from '~/helper/const'
import useCommunityFunctions from '~/helper/communityFunctions'
import useCommunitySelectors from '~/helper/communitySelectors'
import getCroppedImg from './cropImage'
import * as S from './styled'

export default function ModalCropBannerImage({ handleCloseModal, showModal, setModalStepToZero, currentCommunity }) {
  const { t } = useTranslation()
  const { communityS3Banner } = useCommunitySelectors()
  const { uploadS3RequestAction, removeS3FromStateAction } = useCommunityFunctions()
  const { networkBannerPath } = currentCommunity
  const [bannerImage, setBannerImage] = useState(networkBannerPath)
  const [openDialog, setOpenDialog] = useState(false)
  const [openDialogDeletePhoto, setOpenDialogDeletePhoto] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const inputFile = useRef(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const getCroppedImage = useCallback(async () => {
    if (bannerImage === null) {
      const imageToSend = base64ImageToBlob(base64DefaultBanner)
      uploadS3RequestAction(imageToSend)
      setModalStepToZero()
      handleCloseModal()
    } else {
      try {
        const croppedImage = await getCroppedImg(bannerImage, croppedAreaPixels, rotation)
        setCroppedImage(croppedImage)
        const image = base64ImageToBlob(croppedImage)
        uploadS3RequestAction(image)
        setModalStepToZero()
        handleCloseModal()
      } catch (e) {
        toast.error(t('errorUpdatingImage'))
      }
    }
  }, [croppedAreaPixels, rotation, bannerImage])

  function clickToCloseModal() {
    removeS3FromStateAction()
    setModalStepToZero()
    handleCloseModal()
    setBannerImage(networkBannerPath)
  }

  function onBackButton() {
    removeS3FromStateAction()
    setModalStepToZero()
    setOpenDialog(false)
    setBannerImage(networkBannerPath)
  }

  function deletePhoto() {
    setBannerImage(null)
    setCroppedImage(null)
    removeS3FromStateAction()
    setOpenDialogDeletePhoto(false)
  }

  function changeFile(event) {
    event.stopPropagation()
    event.preventDefault()
    const reader = new FileReader()
    reader.onload = e => {
      setBannerImage(e.target.result)
    }
    reader.readAsDataURL(event.target.files[0])
  }

  function onButtonClick() {
    inputFile.current.click()
  }

  useEffect(() => {
    if (networkBannerPath === undefined || networkBannerPath === null) {
      setBannerImage(communityS3Banner())
    }
  }, [networkBannerPath, communityS3Banner])

  return (
    <S.ModalCustom show={showModal} onHide={clickToCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.BackIcon onClick={() => setOpenDialog(true)} />
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>
              <span>{t('attention')}</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{t('discardUnsavedChanges')}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} color="primary">
                {t('cancel')}
              </Button>
              <Button onClick={onBackButton} color="primary">
                {t('confirm')}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={openDialogDeletePhoto} onClose={() => setOpenDialogDeletePhoto(false)}>
            <DialogTitle>
              <span>{t('attention')}</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{t('cofirmPhotoDeletion')}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialogDeletePhoto(false)} color="primary">
                {t('cancel')}
              </Button>
              <Button onClick={deletePhoto} color="primary">
                {t('confirm')}
              </Button>
            </DialogActions>
          </Dialog>
          <S.Title>{t('communityBannerPhoto')}</S.Title>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={modalBodyStyleDefault}>
        <Row>
          <Col md={12}>
            {bannerImage === null && croppedImage === null && communityS3Banner() === null ? (
              <S.NoImage>
                <S.NoImageIndicatorIcon />
              </S.NoImage>
            ) : (
              <>
                <S.CropContainerParent>
                  <S.CropContainer>
                    <Cropper
                      image={bannerImage === null ? networkBannerPath : bannerImage}
                      crop={crop}
                      zoom={zoom}
                      rotation={rotation}
                      aspect={5 / 1}
                      showGrid={false}
                      onCropChange={setCrop}
                      onRotationChange={setRotation}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </S.CropContainer>
                </S.CropContainerParent>
                <div className="controls mt-3 d-flex">
                  <Col md={6}>
                    <span>{t('zoom')}</span>
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e, zoom) => setZoom(zoom)}
                    />
                  </Col>
                  <Col md={6}>
                    <span>{t('rotate')}</span>
                    <Slider
                      value={rotation}
                      min={0}
                      max={360}
                      step={1}
                      aria-labelledby="Rotation"
                      onChange={(e, rotation) => setRotation(rotation)}
                    />
                  </Col>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <S.ModalFooter>
        <div>
          <S.DeletePhoto onClick={() => setOpenDialogDeletePhoto(true)}>{t('deletePhoto')}</S.DeletePhoto>
        </div>
        <div>
          <S.ChangePhotoButton text={t('changePhoto')} action={onButtonClick} />
          <S.InputChangePhoto ref={inputFile} onChange={changeFile} />
          <S.SavePhotoButton action={getCroppedImage} text={t('savePhoto')} />
        </div>
      </S.ModalFooter>
    </S.ModalCustom>
  )
}
