/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import { MdStar } from 'react-icons/md'
import { useForm, Controller } from 'react-hook-form'
import Rating from '@material-ui/lab/Rating'
import { format } from 'date-fns'
import TextField from '@material-ui/core/TextField'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { modalRatingStyle } from '~/helper/const'
import Border from '~/components/Shared/Border'
import WhiteButton from '~/components/WhiteButton'
import * as S from './styled'

export default function ModalRatingCampaignMocked({ showModal, handleCloseModal }) {
  const { t } = useTranslation()
  const { handleSubmit, control } = useForm()
  const [rating, setRating] = useState(null)

  function onSubmit(data) {
    console.log('data', data)
  }

  const startDateMonth = format(Date.now(), 'MMM')
  const startDateDay = format(Date.now(), 'dd')
  const endDateMonth = format(Date.now(), 'MMM')
  const endDateDay = format(Date.now(), 'dd')
  const startDate = `${startDateDay} ${startDateMonth}`
  const endDate = `${endDateDay} ${endDateMonth}`

  return (
    <div className="animated fadeIn">
      <S.ModalCustom show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <S.ModalTitle>{t('campaignRating')}</S.ModalTitle>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalRatingStyle}>
          <S.CommunityInformations>
            <div className="d-flex">
              <img className="community-logo" src="community-profile-default-picture.png" alt="Cover" />
              <S.CommunityContainer>
                <S.CommunityNameAndLocationSection>
                  <S.CommunityName>Runners world</S.CommunityName>
                  <S.CommunityLocation>Rome, Italy</S.CommunityLocation>
                  <div>
                    <button type="button" target="_blank" onClick={() => window.open(`www.youtube.com`)}>
                      <img className="social-icons-youtube" src="/youtube.png" alt="Youtube" />
                    </button>
                    <button type="button" target="_blank" onClick={() => window.open(`www.facebook.com`)}>
                      <img className="social-icons" src="/facebook.png" alt="Facebook" />
                    </button>
                  </div>
                </S.CommunityNameAndLocationSection>
              </S.CommunityContainer>
            </div>
            <div className="start-date">
              <S.Subtitle>{t('startDate')}</S.Subtitle>
              <S.ChartTitle>{startDate}</S.ChartTitle>
            </div>
            <div className="end-date">
              <S.Subtitle>{t('endDate')}</S.Subtitle>
              <S.ChartTitle>{endDate}</S.ChartTitle>
            </div>
            <div className="campaign-budget">
              <S.Subtitle>{t('budgetUsed')}</S.Subtitle>
              <S.ChartTitle>€ 1200</S.ChartTitle>
            </div>
          </S.CommunityInformations>
          <div>
            <Border />
          </div>
          <S.RatingTextSection>
            <span className="rate-campaign">
              {t('pleaseRateYourCampaignWith')}
              Runners world
            </span>
            <span className="rate-message">{t('considerOneStarRating')}</span>
          </S.RatingTextSection>
          <S.RatingComponentContainer>
            <Rating
              name="customized-empty"
              defaultValue={rating}
              size="large"
              onChange={e => setRating(e.target.value)}
            />
          </S.RatingComponentContainer>
          <S.FormContainer>
            <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
              <Row className="d-flex justify-content-center">
                <Col md={6} className="d-flex justify-content-center align-items-center flex-column">
                  <span className="rate-notes pb-2">{t('wouldYouLikeToShareWithUs')}</span>
                  <Controller
                    as={<TextField fullWidth variant="outlined" multiline rows="6" />}
                    name="note"
                    control={control}
                  />
                </Col>
              </Row>
            </form>
          </S.FormContainer>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end pt-3 pt-0">
          <WhiteButton
            isDisabled={rating === null || false}
            form="my-form"
            style={{ marginRight: '20px' }}
            text={t('rateBrand')}
            icon={<MdStar color="#ea9738" size={20} />}
          />
        </Modal.Footer>
      </S.ModalCustom>
    </div>
  )
}
