import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import GenericPopover from '~/components/Shared/GenericPopover'
import { useDeleteMessage } from '~/hooks/'
import AudioPlayer from '~/components/Chat/AudioPlayer'
import VideoPlayer from '~/components/Shared/VideoPlayer'
import UrlPreview from '~/components/Chat/UrlPreview'
import FilePreview from '~/components/Chat/FilePreview'
import ImagePreview from '~/components/Chat/ImagePreview'
import ReplyPreview from '~/components/Chat/ReplyPreview'
import ContentPreview from '~/components/Chat/ContentPreview'
import GifPreview from '~/components/Chat/GifPreview'
import Dialog from '~/components/Shared/Dialog'
import Tags from '~/components/Chat/Tags'
import MessageText from '~/components/Chat/MessageText'
import ShouldRender from '~/components/ShouldRender'
import useChatFunctions from '~/helper/chatActions'
import * as S from './styled'

export default function Message({ message, userId, loggedUserId, loadingSendingMessage, community }) {
  const { t } = useTranslation()
  const [deleteMessage, { isDeleted: hasBeenDeleted }] = useDeleteMessage()
  const activeReplyMessage = useSelector(state => state.chat.activeReplyMessage)

  const {
    type,
    loading,
    content,
    gif,
    files,
    fileSizes,
    fileLocalName,
    fileExtensions,
    deletedAt,
    tags,
    reply,
    hubContentInformation,
  } = message

  const [hover, setHover] = useState()
  const { setActiveReplyMessageAction } = useChatFunctions()
  const [popoverAnchor, setPopoverAnchor] = useState(null)
  const [showModalVideo, setShowModalVideo] = useState(false)
  const [openModalDeleteMessage, setOpenModalDeleteMessage] = useState(false)

  let isSentByLoggedUser = false
  const isAudioMessage = type === 'Voice'
  const isImageMessage = type === 'Image'
  const isVideoMessage = type === 'Video'
  const isTextMessage = type === 'Message'
  const isJoinedMessage = type === 'Joined'
  const isLinkMessage = type === 'Link'
  const isGifMessage = type === 'Gif'
  const isFileMessage = type === 'File'
  const isMessageDeleted = !!deletedAt || hasBeenDeleted
  const isHubContentMessage = hubContentInformation
  // const isStoryMessage = hubContentInformation && hubContentInformation.content_type === 'story'

  const handleOpenPopover = event => {
    setPopoverAnchor(event.currentTarget)
  }

  const closePopoverAndDialog = () => {
    setPopoverAnchor(null)
    setOpenModalDeleteMessage(false)
  }

  const mountCommunityMagazineUrl = () => {
    if (community) return `${community?.redirectUri}${community?.nameUrl}`
    return null
  }

  const handleClickMagazineContent = () => {
    const magazineWebUrl = mountCommunityMagazineUrl()
    if (magazineWebUrl) {
      window.open(magazineWebUrl, '_blank')
    }
  }

  const handleDeleteMessage = () => {
    deleteMessage({
      messageId: message.messageId,
      callback: () => closePopoverAndDialog(),
    })
  }

  const showChatAdditionalMessage = () => {
    if (
      !isMessageDeleted &&
      (isVideoMessage || isImageMessage || isFileMessage || isLinkMessage || isHubContentMessage)
    )
      return true
    return false
  }

  const hoverMouseOn = () => {
    setHover(true)
  }

  const hoverMouseOff = () => {
    setHover(false)
  }

  const setActiveReplyMessage = () => {
    setActiveReplyMessageAction(message)
    setPopoverAnchor(null)
  }

  if (userId === loggedUserId) {
    isSentByLoggedUser = true
  }

  return (
    <S.MessageContainer onMouseEnter={hoverMouseOn} onMouseLeave={hoverMouseOff}>
      {/* Reply and delete options */}
      <ShouldRender if={!isMessageDeleted && hover && !activeReplyMessage}>
        <S.MoreButtonContainer>
          <S.ChatOptionsIcon onClick={handleOpenPopover} />
          <Dialog
            openDialog={openModalDeleteMessage}
            closeDialog={() => setOpenModalDeleteMessage(false)}
            dialogTitle={t('areYouSure')}
            dialogText={t('actionCannotBeReversed')}
            cancelAction={() => setOpenModalDeleteMessage(false)}
            confirmAction={() => handleDeleteMessage()}
          />
          <GenericPopover
            popoverAnchor={popoverAnchor}
            setPopoverAnchor={setPopoverAnchor}
            content={
              <S.PopupContainer>
                <ShouldRender if={isSentByLoggedUser}>
                  <S.Popup>
                    <S.PopupMessage onClick={() => setOpenModalDeleteMessage(true)}>{t('remove')}</S.PopupMessage>
                  </S.Popup>
                </ShouldRender>
                <S.Popup>
                  <S.PopupMessage onClick={() => setActiveReplyMessage()}>{t('reply')}</S.PopupMessage>
                </S.Popup>
              </S.PopupContainer>
            }
          />
        </S.MoreButtonContainer>
      </ShouldRender>
      <S.MessageContentContainer
        isSentByLoggedUser={isSentByLoggedUser}
        hasTags={tags?.length}
        isReply={!!reply}
        hasAdditionalMessage={showChatAdditionalMessage()}
        isMessageDeleted={isMessageDeleted}
        isTextMessage={isTextMessage}
        hover={!isMessageDeleted && hover && !activeReplyMessage}
        hasTextMessage={message?.message?.length === 0}
      >
        {/* Reply messages */}
        <ShouldRender if={!!reply && !isMessageDeleted}>
          <ReplyPreview message={message.message} entireMessage={message} reply={reply} sentByMe={isSentByLoggedUser} />
        </ShouldRender>

        {/* Messages with attached links and additional text */}
        <ShouldRender if={isLinkMessage && showChatAdditionalMessage()}>
          <MessageText
            entireMessage={message}
            message={message.message}
            isVideoOrImage={isVideoMessage || isImageMessage}
            isHubContentMessage={isHubContentMessage}
            isLinkMessage={isLinkMessage}
          />
        </ShouldRender>

        {/* Deleted messages */}
        <ShouldRender if={isMessageDeleted}>
          <S.MessageBoxContainer>
            <S.DeletedMessageContainer>
              <S.DeletedMessageIcon />
              <S.TextMessage>{t('messageDeleted')}</S.TextMessage>
            </S.DeletedMessageContainer>
          </S.MessageBoxContainer>
        </ShouldRender>

        {/* Text messages */}
        <ShouldRender if={isTextMessage && !isMessageDeleted}>
          <S.MessageBoxContainer>
            <MessageText
              entireMessage={message}
              message={message.message}
              sentByMe={isSentByLoggedUser}
              isReply={!!reply}
            />
          </S.MessageBoxContainer>
        </ShouldRender>

        {/* Content messages */}
        <ShouldRender if={isHubContentMessage && !isMessageDeleted}>
          <S.ContentPreviewContainer onClick={handleClickMagazineContent}>
            <ContentPreview content={hubContentInformation} />
          </S.ContentPreviewContainer>
        </ShouldRender>

        {/* User joined the chat message */}
        <ShouldRender if={isJoinedMessage && !isMessageDeleted}>
          <S.MessageBoxContainer>
            <S.MessageUserJoinedChannel>{t('hasJoinedTHeChat')}</S.MessageUserJoinedChannel>
          </S.MessageBoxContainer>
        </ShouldRender>

        {/* Image messages */}
        <ShouldRender if={isImageMessage && !loading && !isMessageDeleted}>
          <S.ImagePreviewContainer>
            <ShouldRender if={loadingSendingMessage}>
              <S.CustomSpinner />
            </ShouldRender>
            <ShouldRender if={!loadingSendingMessage}>
              <ImagePreview
                isReply={!!reply}
                sentByMe={isSentByLoggedUser}
                media={message?.images}
                type="image"
                params={message}
              />
            </ShouldRender>
          </S.ImagePreviewContainer>
        </ShouldRender>

        {/* Audio messages */}
        <ShouldRender if={isAudioMessage && !isMessageDeleted}>
          <S.AudioContainer>
            <AudioPlayer audio={message.audio} width={255} />
          </S.AudioContainer>
        </ShouldRender>

        {/* Content who has a link */}
        <ShouldRender if={isLinkMessage && !!content && content.url && content.title && !isMessageDeleted}>
          <UrlPreview content={message.content} />
        </ShouldRender>

        {/* Link without content messages */}
        <ShouldRender if={isLinkMessage && !content && !isMessageDeleted}>
          <S.MessageBoxContainer>
            <UrlPreview content={message.content} linkWithoutContent message={message} />
          </S.MessageBoxContainer>
        </ShouldRender>

        {/* Gif messages */}
        <ShouldRender if={isGifMessage && !message.message && !isMessageDeleted}>
          <S.GifContainer>
            <GifPreview gif={gif} width={255} />
          </S.GifContainer>
        </ShouldRender>

        {/* Document attached messages */}
        <ShouldRender if={isFileMessage && files && files?.length && !isMessageDeleted}>
          {files?.map((file, index) => (
            <S.FileContainer key={file}>
              <FilePreview
                fileSize={fileSizes && fileSizes[index]}
                fileName={fileLocalName && fileLocalName[index]}
                fileUrl={file}
                fileExtension={fileExtensions && fileExtensions[index]}
                hasAdditionalTextMessage={message?.message}
              />
            </S.FileContainer>
          ))}
        </ShouldRender>

        {/* Video attached messages */}
        <ShouldRender if={isVideoMessage && !loading && !isMessageDeleted}>
          <>
            <S.VideoPreviewContainer onClick={() => setShowModalVideo(true)}>
              <S.VideoPreview src={message?.videos?.[1]} />
              <S.PlayIconContainer>
                <S.PlayIcon onClick={() => setShowModalVideo(true)} />
              </S.PlayIconContainer>
            </S.VideoPreviewContainer>
            <ShouldRender if={showModalVideo}>
              <VideoPlayer
                source={message?.videos?.[0]}
                poster={message?.videos?.[1]}
                showModalVideo={showModalVideo}
                closeModalVideo={() => setShowModalVideo(false)}
                message={message}
              />
            </ShouldRender>
          </>
        </ShouldRender>

        {/* When a media message has some additional text, for example an image and a text */}
        <ShouldRender if={showChatAdditionalMessage() && message.message && !isLinkMessage && !isMessageDeleted}>
          <MessageText
            entireMessage={message}
            message={message?.message}
            isVideoOrImage={isVideoMessage || isImageMessage}
            sentByMe={isSentByLoggedUser}
            isFile={isFileMessage}
            isHubContentMessage={isHubContentMessage}
          />
        </ShouldRender>

        {/* When a message has tags */}
        <ShouldRender if={!!tags && tags?.length && !isMessageDeleted}>
          <Tags tags={tags} />
        </ShouldRender>

        {/* When a content has tags */}
        <ShouldRender if={!!hubContentInformation && hubContentInformation?.interests?.length > 0 && !isMessageDeleted}>
          <Tags tags={hubContentInformation?.interests} isContent />
        </ShouldRender>
      </S.MessageContentContainer>
    </S.MessageContainer>
  )
}
