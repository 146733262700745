import { v5 as uuidv5 } from 'uuid'

const NAMESPACE = '41931aa9-b746-46ef-924e-41fe90ef28b3'

const parseRoute = route => {
  return `/advPlatform/advertisementPlatform/${route}`
}

export const messageAudio = channelId =>
  parseRoute(`channels/${channelId}/message-audios/${uuidv5((Date.now() * Math.random()).toString(), NAMESPACE)}`)

export const messagePicture = channelId =>
  parseRoute(`channels/${channelId}/message-pictures/${uuidv5((Date.now() * Math.random()).toString(), NAMESPACE)}`)

export const messageFile = channelId =>
  parseRoute(`channels/${channelId}/message-files/${uuidv5((Date.now() * Math.random()).toString(), NAMESPACE)}`)

export const messageCollection = () => parseRoute('messages')
