/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import Border from '~/components/Shared/Border'
import { InputTitle } from '~/components/Shared/StyledComponents'
import { prepareToSendSurvey } from '~/helper/formFunctions'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import SurveyVisualization from '~/components/CreateFormatMobileVisualization/Survey'
import i18n from '~/i18n'
import { store } from '~/store'
import * as S from './styled'

export default function ModalCreateSurvey({ handleCloseModal, showModal, surveyFirstInformations, campaignProductId }) {
  const { t } = useTranslation()
  const currentLanguage = i18n.language
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const [radioOptionOne, setRadioOptionOne] = useState(false)
  const [radioOptionTwo, setRadioOptionTwo] = useState(false)
  const [radioOptionThree, setRadioOptionThree] = useState(false)
  const [radioOptionFour, setRadioOptionFour] = useState(false)
  const [radioOptionFive, setRadioOptionFive] = useState(false)
  const { handleSubmit, control, errors, watch, reset } = useForm()
  const questionWatch = watch('question', '')
  const questionLength = 100 - questionWatch.length

  const optionOneInputWatch = watch('optionOne')
  const optionOneInputWatchForCharactersLeft = watch('optionOne', '')
  const optionOneLength = 70 - optionOneInputWatchForCharactersLeft.length

  const optionTwoInputWatch = watch('optionTwo')
  const optionTwoInputWatchForCharactersLeft = watch('optionTwo', '')
  const optionTwoLength = 70 - optionTwoInputWatchForCharactersLeft.length

  const optionThreeInputWatch = watch('optionThree')
  const optionThreeInputWatchForCharactersLeft = watch('optionThree', '')
  const optionThreeLength = 70 - optionThreeInputWatchForCharactersLeft.length

  const optionFourInputWatch = watch('optionFour')
  const optionFourInputWatchForCharactersLeft = watch('optionFour', '')
  const optionFourLength = 70 - optionFourInputWatchForCharactersLeft.length

  const optionFiveInputWatch = watch('optionFive')
  const optionFiveInputWatchForCharactersLeft = watch('optionFive', '')
  const optionFiveLength = 70 - optionFiveInputWatchForCharactersLeft.length

  const optionsChecked = [
    { isChecked: radioOptionOne, option: optionOneInputWatch },
    { isChecked: radioOptionTwo, option: optionTwoInputWatch },
    { isChecked: radioOptionThree, option: optionThreeInputWatch },
    { isChecked: radioOptionFour, option: optionFourInputWatch },
    { isChecked: radioOptionFive, option: optionFiveInputWatch },
  ]

  const {
    addProductSurveyMultipleQuestionsAction,
    createCampaignFormatAction,
    deleteAllSurveysAction,
    addProductAction,
  } = useSponsorFunctions()

  function resetStateAfterFormSubmit() {
    setRadioOptionOne(false)
    setRadioOptionTwo(false)
    setRadioOptionThree(false)
    setRadioOptionFour(false)
    setRadioOptionFive(false)
  }

  function handleCloseModalAndResetForm() {
    resetStateAfterFormSubmit()
    handleCloseModal()
  }

  function onSubmit(format) {
    format.date = surveyFirstInformations.dtStart
    format.endDate = surveyFirstInformations.dtEnd
    format.id = uuidv4()
    addProductAction('survey_initial_informations', surveyFirstInformations)
    addProductSurveyMultipleQuestionsAction('multiple_survey', format, surveyFirstInformations.id)
    const surveysToCreate = store.getState().createCampaign.campaignProducts.survey.surveys
    createCampaignFormatAction(currentCampaignForReviewPage.id, campaignProductId, surveysToCreate[0])
    handleCloseModalAndResetForm()
  }

  useEffect(() => {
    deleteAllSurveysAction()
  }, [])

  useEffect(() => {
    if (optionOneInputWatch !== '') {
      setRadioOptionOne(true)
    } else {
      setRadioOptionOne(false)
    }
    if (optionTwoInputWatch !== '') {
      setRadioOptionTwo(true)
    } else {
      setRadioOptionTwo(false)
    }
    if (optionThreeInputWatch !== '') {
      setRadioOptionThree(true)
    } else {
      setRadioOptionThree(false)
    }
    if (optionFourInputWatch !== '') {
      setRadioOptionFour(true)
    } else {
      setRadioOptionFour(false)
    }
    if (optionFiveInputWatch !== '') {
      setRadioOptionFive(true)
    } else {
      setRadioOptionFive(false)
    }
  }, [optionOneInputWatch, optionTwoInputWatch, optionThreeInputWatch, optionFourInputWatch, optionFiveInputWatch])

  useEffect(() => {
    if (optionOneInputWatch !== '' && radioOptionOne === false) {
      reset({ optionOne: '' })
    }
    if (optionTwoInputWatch !== '' && radioOptionTwo === false) {
      reset({ optionTwo: '' })
    }
    if (optionThreeInputWatch !== '' && radioOptionThree === false) {
      reset({ optionThree: '' })
    }
    if (optionFourInputWatch !== '' && radioOptionFour === false) {
      reset({ optionFour: '' })
    }
    if (optionFiveInputWatch !== '' && radioOptionFive === false) {
      reset({ optionFive: '' })
    }
  }, [radioOptionOne, radioOptionTwo, radioOptionThree, radioOptionFour, radioOptionFive])

  return (
    <ProductsModal
      action={onSubmit}
      modalTitle={t('newFormat', { format: t('survey') })}
      handleCloseModal={handleCloseModalAndResetForm}
      showModal={showModal}
      review={false}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(prepareToSendSurvey(onSubmit))}>
          <S.FormContainer currentLanguage={currentLanguage}>
            <div>
              <InputTitle>{t('question')}</InputTitle>
              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={t('typeQuestion')}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                }
                rules={{ required: t('defaultValidation', { inputName: t('question') }) }}
                name="question"
                control={control}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="question" as="p" />
              </div>
            </div>

            {questionWatch !== '' && (
              <S.QuestionCharactersLeft>{`${questionLength} ${t('charactersLeft')}`}</S.QuestionCharactersLeft>
            )}
            {optionOneInputWatchForCharactersLeft !== '' && (
              <S.OptionOneCharactersLeft>{`${optionOneLength} ${t('charactersLeft')}`}</S.OptionOneCharactersLeft>
            )}
            {optionTwoInputWatchForCharactersLeft !== '' && (
              <S.OptionTwoCharactersLeft>{`${optionTwoLength} ${t('charactersLeft')}`}</S.OptionTwoCharactersLeft>
            )}
            {optionThreeInputWatchForCharactersLeft !== '' && (
              <S.OptionThreeCharactersLeft>{`${optionThreeLength} ${t('charactersLeft')}`}</S.OptionThreeCharactersLeft>
            )}
            {optionFourInputWatchForCharactersLeft !== '' && (
              <S.OptionFourCharactersLeft>{`${optionFourLength} ${t('charactersLeft')}`}</S.OptionFourCharactersLeft>
            )}
            {optionFiveInputWatchForCharactersLeft !== '' && (
              <S.OptionFiveCharactersLeft>{`${optionFiveLength} ${t('charactersLeft')}`}</S.OptionFiveCharactersLeft>
            )}

            <S.RadioGroupSection>
              <FormControlLabel
                style={{ margin: '0px' }}
                value="public"
                label="Public"
                control={
                  <Checkbox
                    checked={radioOptionOne}
                    onChange={() => setRadioOptionOne(!radioOptionOne)}
                    color="default"
                  />
                }
                label={
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={`${t('option')} 1`}
                        inputProps={{
                          maxLength: 70,
                        }}
                      />
                    }
                    name="optionOne"
                    control={control}
                  />
                }
              />

              <FormControlLabel
                style={{ margin: '0px', marginTop: '5px' }}
                value="public"
                label="Public"
                control={
                  <Checkbox
                    checked={radioOptionTwo}
                    onChange={() => setRadioOptionTwo(!radioOptionTwo)}
                    color="default"
                  />
                }
                label={
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={`${t('option')} 2`}
                        inputProps={{
                          maxLength: 70,
                        }}
                      />
                    }
                    name="optionTwo"
                    control={control}
                  />
                }
              />
              <FormControlLabel
                style={{ margin: '0px', marginTop: '5px' }}
                value="public"
                label="Public"
                control={
                  <Checkbox
                    checked={radioOptionThree}
                    onChange={() => setRadioOptionThree(!radioOptionThree)}
                    color="default"
                  />
                }
                label={
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={`${t('option')} 3`}
                        inputProps={{
                          maxLength: 70,
                        }}
                      />
                    }
                    name="optionThree"
                    control={control}
                  />
                }
              />
              <FormControlLabel
                style={{ margin: '0px', marginTop: '5px' }}
                value="public"
                label="Public"
                control={
                  <Checkbox
                    checked={radioOptionFour}
                    onChange={() => setRadioOptionFour(!radioOptionFour)}
                    color="default"
                  />
                }
                label={
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={`${t('option')} 4`}
                        inputProps={{
                          maxLength: 70,
                        }}
                      />
                    }
                    name="optionFour"
                    control={control}
                  />
                }
              />
              <FormControlLabel
                style={{ margin: '0px', marginTop: '5px' }}
                value="public"
                label="Public"
                control={
                  <Checkbox
                    checked={radioOptionFive}
                    onChange={() => setRadioOptionFive(!radioOptionFive)}
                    color="default"
                  />
                }
                label={
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={`${t('option')} 5`}
                        inputProps={{
                          maxLength: 70,
                        }}
                      />
                    }
                    name="optionFive"
                    control={control}
                  />
                }
              />
            </S.RadioGroupSection>
            <div className="mt-4 mb-2">
              <Border />
            </div>
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          isSurvey
          FormatVisualizationComponent={
            <SurveyVisualization question={questionWatch} optionsChecked={optionsChecked} />
          }
        />
      }
    />
  )
}
