import styled from 'styled-components'
import { darken } from 'polished'

export const Container = styled.div`
  margin-left: ${props => (props.spacing ? '10px' : '0px')};
  display: flex;
  flex-direction: column;
  background-image: ${props =>
    props.isTabActive
      ? 'linear-gradient(to right, #fff 0%, #fff 100%)'
      : 'linear-gradient(to right, #00A2FF 40%, #0090de 100%)'};
  height: 80px;
  width: 190px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 13px 15px 5px 20px;
  cursor: pointer;
  transition: all 200ms;
  box-shadow: 0px -4px 5px 0px #c5cad3;
  position: relative;

  &:hover {
    background-color: ${props => (props.isTabActive ? darken(0.01, '#FFF') : darken(0.05, props.tabsColor))};
    transform: scale(1.025);
  }
`
export const Title = styled.p`
  margin: 0px;
  color: ${props => (props.isTabActive ? 'var(--sponsor-dark)' : '#FFF')};
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.9;
  margin-top: 5px !important;
  letter-spacing: 0.5px;
`

export const TabIcon = styled.img`
  width: 27px;
  height: 27px;
`

export const TabDotIndicator = styled.div`
  background-color: #e81200;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  border-radius: 100%;
  position: absolute;
  background-color: red;
  top: 15px;
  right: 10px;
  color: #fff;
`

export const TabDotText = styled.span`
  font-size: 13px;
`
