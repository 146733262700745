/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import getProductImage from '~/helper/getProductImage'
import JoyRide, { STATUS } from 'react-joyride'
import useCommunityFunctions from '~/helper/communityFunctions'
import useGetCommunityCurrencySymbol from '~/hooks/useGetCommunityCurrencySymbol'
import { currencyFormat } from '~/helper/helperFunctions'
import ShouldRender from '~/components/ShouldRender'

import ModalCustomSponsoredFormat from '~/components/Community/Modals/ModalCustomSponsoredFormat'
import { useForm, Controller } from 'react-hook-form'
import { TextField } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { MdDeleteOutline, MdOutlineEdit } from 'react-icons/md'
import InputAdornment from '@material-ui/core/InputAdornment'
import { getValidationSchema, getTooltipOptions } from './utils'
import NumberOfFormatsTutorial from './NumberOfFormatsTutorial'
import * as S from './styled'

export default function ProductCard({
  product,
  deleteCustomAdvertisementProduct,
  setCallbackToGetProducts,
  refetchAlert,
}) {
  const { t } = useTranslation()
  const { id: productId, name, description, productType, activeStatus, unitPrice, maxInParallel, suggestedUnitPrice } =
    product || {}
  const communityId = useSelector(state => state.community.currentCommunity.id)
  const communityCurrency = useSelector(state => state.community.currentCommunity.currency)
  const communityCurrencySymbol = useGetCommunityCurrencySymbol(communityCurrency)
  const [switchChecked, setSwitchChecked] = useState(activeStatus === 'actived')
  const [showModalCustomSponsoredFormat, setShowModalCustomSponsoredFormat] = useState(false)
  const [showTutorial, setShowTutorial] = useState(false)
  const isFirstRun = useRef(true)

  const toggleTutorial = useCallback(value => () => setShowTutorial(value), [])

  const handleJoyrideCallback = data => {
    const { status } = data

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setShowTutorial(false)
    }
  }

  const validator = getValidationSchema(suggestedUnitPrice)
  const { control, errors, setValue, watch, setError, clearError } = useForm({ validationSchema: validator })

  const {
    updateCustomAdvertisementProductActiveStatusAction,
    updateCustomAdvertisementProductAction,
  } = useCommunityFunctions()

  const openCustomSponsoredFormatUpdateModal = () => {
    setShowModalCustomSponsoredFormat(true)
  }

  const inputWatch = {
    maxInParallel: watch('maxInParallel', maxInParallel),
    unitPrice: watch('unitPrice', Number(unitPrice)),
  }

  const updateProductStatus = e => {
    if (activeStatus === 'actived') {
      setSwitchChecked(e)
      updateCustomAdvertisementProductActiveStatusAction('inactivated', productId, communityId)
    }
    if (activeStatus === 'inactivated') {
      setSwitchChecked(e)
      updateCustomAdvertisementProductActiveStatusAction('actived', productId, communityId)
    }
  }

  const handleDeleteProductsButton = () => {
    deleteCustomAdvertisementProduct(productId, communityId)
    setTimeout(() => {
      setCallbackToGetProducts()
      refetchAlert()
    }, 2000)
  }

  const [customFormatInfo, setCustomFormatInfo] = useState(null)

  useEffect(() => {
    if (customFormatInfo) setValue('unitPrice', customFormatInfo?.unitPrice)
  }, [customFormatInfo, setValue])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    if (inputWatch?.unitPrice < suggestedUnitPrice) {
      setError('unitPrice', { type: 'min', message: t('minimumOf', { min: suggestedUnitPrice }) })
    } else {
      clearError('unitPrice')
    }

    if (!errors?.unitPrice) {
      const updateFormat = setTimeout(() => {
        updateCustomAdvertisementProductAction(product.id, inputWatch, communityId, setCallbackToGetProducts)
        refetchAlert()
      }, 1500)

      return () => clearTimeout(updateFormat)
    }
  }, [inputWatch?.maxInParallel, inputWatch?.unitPrice])

  useEffect(() => {
    if (!showModalCustomSponsoredFormat) refetchAlert()
  }, [showModalCustomSponsoredFormat])

  return (
    <S.Container id={product?.id}>
      <S.ProductImageAndInfoContainer>
        <S.ProductImageContainer>
          <S.ProductImage src={getProductImage(productType)} />
          {productType === 'custom' && (
            <S.CustomLabelContainer>
              <S.CustomLabel>{t('custom')}</S.CustomLabel>
            </S.CustomLabelContainer>
          )}
        </S.ProductImageContainer>
        <S.ProductInformationsContainer>
          <div className="d-flex flex-column" style={{ overflow: 'hidden' }}>
            <S.ProductName>{customFormatInfo?.name ?? name}</S.ProductName>
            <S.ProductDescription>{customFormatInfo?.description ?? description}</S.ProductDescription>
          </div>
        </S.ProductInformationsContainer>
      </S.ProductImageAndInfoContainer>

      <S.ActionsContainer>
        <JoyRide
          steps={getTooltipOptions(productId)}
          run={showTutorial}
          disableOverlayClose
          disableScrolling
          tooltipComponent={NumberOfFormatsTutorial}
          callback={handleJoyrideCallback}
          spotlightPadding={0}
          styles={{
            options: {
              arrowColor: 'transparent',
            },
          }}
        />
        <S.InputContainer
          style={showTutorial ? { zIndex: 99999999 } : {}}
          className={`maxInParallel__container-${productId}`}
        >
          <S.InputLabelContainer>
            <S.InputLabel>{t('maxNumberOfFormats', { format: product.name })}</S.InputLabel>
            <S.InfoIcon onClick={toggleTutorial(true)} />
          </S.InputLabelContainer>

          <Controller
            as={
              <TextField
                type="number"
                fullWidth
                variant="outlined"
                placeholder={t('setTheMax')}
                id={`product-input-${product?.id}`}
                onInput={e => {
                  e.target.value = e.target.value.toString().slice(0, 3)
                }}
              />
            }
            name="maxInParallel"
            control={control}
            defaultValue={maxInParallel ? parseInt(maxInParallel, 10) : null}
            onChange={e => parseInt(e[0].target.value, 10) || null}
          />
        </S.InputContainer>

        <S.InputContainer className="unitPrice__container">
          <S.InputLabelContainer>
            <S.InputLabel style={{ textTransform: 'capitalize' }}>{`${t('monthlyPrice')}:`}</S.InputLabel>
          </S.InputLabelContainer>
          <ShouldRender if={errors?.unitPrice}>
            <S.ErrorMessage color="red">{errors?.unitPrice?.types?.message}</S.ErrorMessage>
          </ShouldRender>

          <Controller
            as={
              <NumberFormat
                customInput={TextField}
                prefix={`${communityCurrencySymbol} `}
                allowNegative={false}
                min={0}
                fullWidth
                variant="outlined"
                allowEmptyFormatting
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <S.MinimumPriceContainer>
                        <S.MinimumPriceText>{t('minimum')}</S.MinimumPriceText>
                        <S.MinimumPrice>
                          {suggestedUnitPrice
                            ? `${communityCurrencySymbol} ${currencyFormat(Number(suggestedUnitPrice))}`
                            : 'N/A'}
                        </S.MinimumPrice>
                      </S.MinimumPriceContainer>
                    </InputAdornment>
                  ),
                }}
              />
            }
            name="unitPrice"
            control={control}
            defaultValue={unitPrice ? Number(unitPrice) : null}
            onChange={e => Number(e[0].target.value.replace(',', '.').split(' ')[1])}
          />
        </S.InputContainer>

        <S.ButtonsContainer>
          <S.SwitchContainer>
            <S.SwitchLabel>{t('availability')}</S.SwitchLabel>
            <S.AvailabilitySwitch checked={switchChecked} onChange={updateProductStatus} />
          </S.SwitchContainer>

          <S.SaveAndDeleteButtonsContainer>
            {productType === 'custom' && (
              <>
                <S.ActionButton type="button" onClick={handleDeleteProductsButton}>
                  <MdDeleteOutline size={25} color="#FE3446" />
                </S.ActionButton>

                <S.ActionButton type="button" onClick={openCustomSponsoredFormatUpdateModal}>
                  <MdOutlineEdit size={26} color="#477CE6" />
                </S.ActionButton>
              </>
            )}
          </S.SaveAndDeleteButtonsContainer>
        </S.ButtonsContainer>
      </S.ActionsContainer>

      <ModalCustomSponsoredFormat
        showModal={showModalCustomSponsoredFormat}
        handleCloseModal={() => setShowModalCustomSponsoredFormat(false)}
        product={product}
        setCallbackToGetProducts={setCallbackToGetProducts}
        setCustomFormatInfo={setCustomFormatInfo}
      />
    </S.Container>
  )
}
