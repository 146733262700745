import { useState } from 'react'
import firebase from 'firebase'

export default () => {
  const [loading, setLoading] = useState(false)
  const [transcodedVideoUrl, setTranscodedVideoUrl] = useState(undefined)
  const [transcodedVideoError, setTranscodedVideoError] = useState(false)

  const communityFirebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    transcodedVideoBucket: process.env.REACT_APP_MOBILE_TRANSCODED_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  }

  const communityFirebase = firebase.apps.find(item => item.name === 'community')
    ? firebase.app('community')
    : firebase.initializeApp(communityFirebaseConfig, 'community')

  const getTranscodedVideoFireStorage = () => {
    return communityFirebase.storage(communityFirebaseConfig.transcodedVideoBucket)
  }

  const getDownloadUrl = path => {
    try {
      setLoading(true)
      const firestorage = getTranscodedVideoFireStorage()
      const ref = firestorage.ref(path)
      ref
        .getDownloadURL()
        .then(url => {
          setTranscodedVideoUrl(url)
          setLoading(false)
        })
        .catch(e => {
          console.log('Cannot get url: ', e)
          setTranscodedVideoError(true)
        })
    } catch (e) {
      console.log('Cannot get url: ', e)
      setTranscodedVideoError(true)
    }
  }

  return [getDownloadUrl, { loading, transcodedVideoUrl, transcodedVideoError }]
}
