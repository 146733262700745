import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import Col from 'react-bootstrap/Col'
import 'chart.piecelabel.js'
import CampaignReviewProductsButtons from '~/components/Community/CampaignReviewProductsButtons'
import * as S from './styled'

export function WelcomeAdReviewCard({ welcomeAds, singleContent }) {
  const { t } = useTranslation()

  return (
    <Container fluid>
      <S.CardBody>
        <div className="icon-section">
          <img style={{ opacity: '.9' }} src="/icons/rewards.png" alt="Welcome Ads" width="19" height="19" />
          <p className="ml-2">{t('welcomeAd')}</p>
        </div>
        <S.AnalyticsCardRow>
          {welcomeAds.map(welcomeAd => (
            <WelcomeAdCard
              key={welcomeAd.id}
              name={welcomeAd.unity.name}
              tags={welcomeAd.unity.tags}
              image={welcomeAd.unity.imageUrl}
              analytics={welcomeAd.analytics}
              singleContent={singleContent}
            />
          ))}
        </S.AnalyticsCardRow>
      </S.CardBody>
    </Container>
  )
}

export function WelcomeAdCard({ name, image, analytics, singleContent }) {
  const { t } = useTranslation()

  return (
    <Col md={singleContent ? 9 : 6} className="mt-4">
      <S.WelcomeAdBody>
        <Row>
          <Col className="mb-4">
            <p className="ad-name">{name}</p>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <S.ContainerWelcomeAdImage>
              <S.WelcomeAdImage
                src={image === null || image === undefined ? '/community-default-banner-picture.jpg' : image}
                alt="Welcome Ad"
              />
            </S.ContainerWelcomeAdImage>
          </Col>

          <Col md={6} className="p-0 product-buttons">
            <CampaignReviewProductsButtons
              text={t('clickThrough')}
              number={analytics?.countClicksThrough}
              color="#35CC9E"
              icon={<img src="/icons/campaignReview/linkview.png" alt="Click through" width="24" height="24" />}
            />
            <CampaignReviewProductsButtons
              style={{ marginTop: '10px' }}
              text={t('views')}
              number={analytics?.countViews}
              color="#25B9CD"
              icon={<img src="/icons/campaignReview/view.png" alt="Views" width="24" height="24" />}
            />
          </Col>
        </Row>
      </S.WelcomeAdBody>
    </Col>
  )
}
