/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import ShouldRender from '~/components/ShouldRender'
import VideoPlayer from '~/components/Shared/VideoPlayer'
import Play from '~/components/Chat/PlayIcon'
import * as S from './styled'

const GUTTER = 3

const ImagePreview = ({ width = '100%', media, type, message, mediaFromUserInput, isReply, sentByMe }) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const getImageSize = () => {
    const quantity = (media && media?.length) || 1
    const sizeOptions = [
      { quantity: 1, size: 274 },
      { quantity: 2, size: 274 / 2 - GUTTER / 2 },
      { quantity: 3, size: 274 / 3 - GUTTER },
      { quantity: 4, size: 274 / 2 - GUTTER / 2 },
    ]
    return sizeOptions[quantity - 1].size
  }

  const size = media && media?.length > 1 ? getImageSize() : width

  const imageStyles = () => {
    const styles = {
      width: size,
      height: size,
    }
    return styles
  }

  return (
    <S.ImagePreviewContainer
      mediaFromUserInput={mediaFromUserInput}
      isReply={isReply}
      sentByMe={sentByMe}
      media={media}
    >
      <ShouldRender if={type === 'image' && isOpen}>
        <Lightbox
          mainSrc={media[photoIndex]}
          nextSrc={media[(photoIndex + 1) % media?.length]}
          prevSrc={media[(photoIndex + media?.length - 1) % media?.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + media?.length - 1) % media?.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % media?.length)}
        />
      </ShouldRender>

      <ShouldRender if={type === 'image' && !mediaFromUserInput}>
        {media &&
          media.map((item, index) => (
            <S.ThumbnailContainer
              style={{
                width: size,
                minWidth: size,
                height: size,
                minHeight: size,
                marginBottom: `${media && media?.length === 4 && index < 2 ? GUTTER + 5 : 0}px`,
                marginRight: `${
                  [2, 3].includes(media && media?.length) && index < media && media?.length - 1 ? GUTTER : GUTTER
                }px`,
                marginLeft: '3px',
              }}
              key={item}
              onClick={() => {
                setPhotoIndex(index)
                setIsOpen(true)
              }}
            >
              <S.Thumbnail src={item || item?.url || item} style={imageStyles(index)} sentByMe={sentByMe} />
            </S.ThumbnailContainer>
          ))}
      </ShouldRender>

      <ShouldRender if={type === 'image' && !mediaFromUserInput && isReply}>
        {media &&
          media.map((item, index) => (
            <S.ThumbnailContainer
              style={{
                marginBottom: `${media && media?.length === 4 && index < 2 ? GUTTER + 5 : 0}px`,
                marginRight: `${
                  [2, 3].includes(media && media?.length) && index < media && media?.length - 1 ? GUTTER : GUTTER
                }px`,
                marginLeft: '3px',
              }}
              key={item}
              onClick={() => {
                setPhotoIndex(index)
                setIsOpen(true)
              }}
            >
              <S.ReplyThumbnail src={item || item?.url || item} style={imageStyles(index)} sentByMe={sentByMe} />
            </S.ThumbnailContainer>
          ))}
      </ShouldRender>

      <ShouldRender if={type === 'image' && mediaFromUserInput}>
        {media &&
          media.map((item, index) => (
            <S.ThumbnailContainer
              style={{
                width: 30,
                height: 30,
              }}
              key={item}
              onClick={() => {
                setPhotoIndex(index)
                setIsOpen(true)
              }}
            >
              <S.Thumbnail src={item || item?.url || item} style={imageStyles(index)} />
            </S.ThumbnailContainer>
          ))}
      </ShouldRender>

      <ShouldRender if={type === 'video' && isOpen}>
        <VideoPlayer
          source={media[0]}
          poster={media[1]}
          showModalVideo={isOpen}
          closeModalVideo={() => setIsOpen(false)}
          message={message}
        />
      </ShouldRender>

      <ShouldRender if={type === 'video' && !!media[1]}>
        <S.ThumbnailContainer
          onClick={() => {
            setIsOpen(true)
          }}
        >
          <S.Thumbnail width={50} src={media[1]} />
          <Play size="small" />
        </S.ThumbnailContainer>
      </ShouldRender>
    </S.ImagePreviewContainer>
  )
}

export default ImagePreview
