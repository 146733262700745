import React from 'react'
import { LinkToSection } from '../../pages/LandingPage/Components/Link/link'
import * as S from './styled'

const Footer = () => {
  return (
    <S.Footer>
      <S.Content>
        <S.AbilityaContainer>
          <S.LogoInfos>
            <img height={34} alt="SOS Logo" src="/images/sos-logo-white.svg" />
            <img height={39} alt="Powered Logo" src="/images/powered-logo-white.svg" />
          </S.LogoInfos>
          <h4 className="desktop-footer">{`Copyright ${new Date().getFullYear()} - All rights reserved`}</h4>
        </S.AbilityaContainer>

        <S.LinksContainer>
          <LinkToSection sectionId="monetization">Monetization</LinkToSection>

          <a href="https://www.abilitya.tech/" target="_blank" rel="noopener noreferrer">
            <h4>Abilitya</h4>
          </a>

          <LinkToSection sectionId="support">Support</LinkToSection>

          <S.LinksList>
            <h3 style={{ cursor: 'default' }}>About us</h3>

            <a href="https://www.abilitya.tech/cases" target="_blank" rel="noopener noreferrer">
              <p>Cases</p>
            </a>

            <LinkToSection sectionId="about-us">Contact us</LinkToSection>
          </S.LinksList>
        </S.LinksContainer>

        <S.AbilityaInfo>
          <div>
            <p>
              <strong>ABILITYA INTERNATIONAL Sagl</strong>
            </p>
            <p>Via Canova 9 - 6900 Lugano - Switzerland info@abilytia.tech</p>
          </div>
          <div>
            <p>
              <strong>ABILITYA S.R.L</strong>
            </p>
            <p>Via Antonio Baldissera 2, 20129 Milan - Italy info@abilitya.tech</p>
          </div>
          <p>{`Copyright ${new Date().getFullYear()} - All rights reserved`}</p>
        </S.AbilityaInfo>
      </S.Content>
    </S.Footer>
  )
}

export default Footer
