import React from 'react'
import * as S from './styled'

const Play = ({ size }) => {
  return (
    <S.PlayContainer className={size}>
      <S.PlayIcon alt="Play" className={size} />
    </S.PlayContainer>
  )
}

export default Play
