import styled from 'styled-components'

export const CardBody = styled.div`
  margin-top: 20px;
  width: 100%;
  padding-bottom: 25px;

  .icon-section {
    display: flex;
    align-items: center;
    padding: 6px 30px 0px 30px;
    margin-bottom: -16px !important;

    p {
      margin: 0px;
      margin-left: 30px;
      color: var(--input-title);
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
  }
`

export const CardSurveyBody = styled.div`
  padding: 20px 20px 0px 20px;
  background: #ffffff;
  border-radius: 10px;
  margin-right: 15px !important;
  margin-left: 15px !important;
  margin-top: 16px !important;
  width: 100%;
  height: auto;
  box-shadow: 1px 3px 12px 3px #e2e5e9;
  position: relative;

  .survey-name {
    margin: 0px;
    color: #5a6978;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  }

  .survey-question {
    word-break: break-all;
    color: #5a6978;
    margin: 0px;
    padding-top: 25px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .survey-responses {
    color: #5a6978;
    padding-top: 12px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    opacity: 0.7;
  }
`

export const CardMultipleSurvey = styled.div`
  padding: 0px 20px 0px 20px;
  border-radius: 10px;
  margin-right: 15px !important;
  margin-left: 15px !important;
  margin-top: 16px !important;
  width: 100%;
  height: auto;
  box-shadow: 1px 3px 12px 3px #e2e5e9;
  position: relative;

  .survey-name {
    margin: 0px;
    color: #5a6978;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  }

  .survey-question {
    word-break: break-all;
    color: #5a6978;
    margin: 0px;
    padding-top: 25px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .survey-responses {
    color: #5a6978;
    padding-top: 12px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    opacity: 0.7;
  }
`

export const WrapperMultipleSurvey = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  height: auto;
  margin-top: 22px;
  position: relative;

  .survey-name {
    margin: 0px;
    color: var(--input-title);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  }

  .multiple-survey-name {
    color: var(--input-title);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    padding: 10px 20px 20px 20px;
  }

  .survey-question {
    word-break: break-all;
    color: var(--input-title);
    margin: 0px;
    padding-top: 25px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .survey-responses {
    color: var(--input-title);
    padding-top: 12px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    opacity: 0.7;
  }
`

export const SurveyOption = styled.div`
  word-break: break-all;
  color: red;
  margin: 0px;
  padding-top: 25px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
`

export const ChartContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NoSurveyDataIndicationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0px;
`

export const NoSurveyDataIndication = styled.p`
  margin-bottom: 0px;
  font-size: 24px;
  color: rgba(214, 214, 214, 0.6);
  font-family: Montserrat;
`

export const SurveyInformationsContainer = styled.div`
  display: flex;
  top: 2rem;
  right: 2rem;
  position: absolute;
`

export const SurveyInformationSingleFormatModal = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const NumberOfCommunityMembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 50px;
  border-radius: 100px;
  background-color: #f7f5f7;
  margin-left: ${props => (props.spacing ? '10px' : '0')};
  border: 1px solid #ebeaeb;
`

export const NumberOfCommunityMembers = styled.span`
  font-size: 14px;
  color: #000;
  margin: 0px;
  font-family: Roboto;
  font-weight: bold;
`

export const NumberOfCommunityMembersLabel = styled.span`
  font-size: 13px;
  color: #000;
  margin: 0px;
  font-family: Roboto;
`
