/* eslint-disable camelcase */
import { takeLatest, all, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '~/services/api'
import networkApi from '~/services/networkApi'
import i18n from '~/i18n'
import history from '~/services/history'
import { store } from '~/store'
import {
  GET_COMMUNITY_LINK_INVITATION_REQUEST,
  UPDATE_COMMUNITY_PRODUCT_REQUEST,
  UPDATE_COMMUNITY_PROFILE_REQUEST,
  UPLOAD_S3_REQUEST,
  GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST,
  CREATE_CUSTOM_ADVERTISEMENT_PRODUCT,
  UPDATE_CUSTOM_ADVERTISEMENT_PRODUCT,
  DELETE_CUSTOM_ADVERTISEMENT_PRODUCT,
  UPDATE_CUSTOM_ADVERTISEMENT_PRODUCT_ACTIVE_STATUS,
  UPDATE_PACKAGE_ACTIVE_STATUS,
  DELETE_PACKAGE,
  CREATE_PACKAGE,
  UPDATE_PACKAGE,
  UPDATE_COMMUNITY_SPONSOR_COOPERATION_STATUS,
  UPDATE_SPONSOR_COMMUNITY_COOPERATION_STATUS,
  ADD_COMMUNITY_GALLERY_MEDIA_REQUEST,
  DELETE_COMMUNITY_GALLERY_MEDIA_REQUEST,
} from './actionTypes'
import {
  getCommunityLinkInvitationSuccess,
  getCommunityLinkInvitationFailure,
  uploadS3Success,
  getCommunityInformationsByInvitationLinkFailure,
  getCommunityInformationsByInvitationLinkSuccess,
  addCommunityGalleryMediaSuccess,
  addCommunityGalleryMediaFailure,
  deleteCommunityGalleryMediaSuccess,
  deleteCommunityGalleryMediaFailure,
  updateCommunityProfileLoading,
  updateCommunityProfileFailure,
  updateCommunityProfileSuccess,
} from './actions'

export function* getCommunityLinkInvitationRequest({ payload }) {
  const { networkId } = payload
  try {
    const response = yield call(api.post, `community/${networkId}/invitation`, {
      networkId,
    })
    const { code } = response.data
    yield put(getCommunityLinkInvitationSuccess(code))
    toast.success(i18n.t('successGenerateCommunityLink'))
  } catch (err) {
    yield put(getCommunityLinkInvitationFailure())
    if (err.response.data.error === 'communityNotRevisedYet') {
      toast.error(i18n.t('cantInviteSponsorCommunityNotRevisedYet'))
    } else {
      toast.error(i18n.t('failedGenerateCommunityLink'))
    }
  }
}

export function* updateCommunityProductRequest({ payload }) {
  const { networkId, productId, status, price } = payload
  try {
    yield call(api.put, `/community/${networkId}/product/${productId}`, {
      status,
      price,
    })
    toast.success('The product has been successfully updated.')
  } catch (err) {
    toast.error('Failed to update the community product.')
  }
}

export function* updateCommunityProfileRequest({ payload }) {
  const { id, data } = payload
  try {
    yield put(updateCommunityProfileLoading())
    yield call(api.put, `/community/${id}`, data)
    toast.success(i18n.t('successfullyUpdated', { entity: i18n.t('communityProfile') }))
    yield put(updateCommunityProfileSuccess())
    setTimeout(() => {
      history.go(0)
    }, 2000)
  } catch (err) {
    yield put(updateCommunityProfileFailure())
    if (err.response.data.errors[0] === 'categories is a required field') {
      toast.error(i18n.t('defaultValidation', { inputName: i18n.t('communityCategory') }))
    } else if (err.response.data.errors[0] === 'name is a required field') {
      toast.error(i18n.t('defaultValidation', { inputName: i18n.t('communityName') }))
    } else {
      toast.error(i18n.t('failedToUpdateCommunityProfile'))
    }
  }
}

export function* uploadS3Request({ payload }) {
  const { file } = payload

  const { token } = store.getState().auth

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  try {
    const formData = new FormData()

    formData.append('file', file)

    networkApi.defaults.headers.Authorization = `Bearer ${token}`

    const response = yield call(networkApi.post, `/uploads`, formData, config)

    const { id, url } = response?.data?.data[0]

    yield put(uploadS3Success(id, url))
  } catch (err) {
    toast.error(i18n.t('errorUpdatingImage'))
  }
}

export function* getCommunityInformations({ payload }) {
  try {
    const { code } = payload
    const response = yield call(api.get, `/community/invitation/${code}`, {
      code,
    })
    const communityInformations = response.data[0]
    yield put(getCommunityInformationsByInvitationLinkSuccess(communityInformations))
  } catch (err) {
    if (err.response.data.error === 'InvitationCodeAlreadyUsed') {
      toast.error(i18n.t('invitationLinkAlreadyUsed'))
      yield put(getCommunityInformationsByInvitationLinkFailure())
    } else {
      toast.error(i18n.t('messages.invitationLinkInvalid'))
      yield put(getCommunityInformationsByInvitationLinkFailure())
    }
  }
}

export function* createCustomAdvertisementProduct({ payload }) {
  const { product, communityId, setCallbackToGetProducts } = payload
  try {
    yield call(api.post, `community/${communityId}/adv-products`, product)
    toast.success(i18n.t('successfullyCreated', { entity: i18n.t('customFormat') }))
    setTimeout(() => {
      setCallbackToGetProducts()
    }, 1000)
  } catch (err) {
    toast.error(i18n.t('failedToCreate', { entity: i18n.t('customFormat') }))
  }
}

export function* updateCustomAdvertisementProduct({ payload }) {
  const { productId, product, communityId, setCallbackToGetProducts } = payload
  try {
    yield call(api.put, `community/${communityId}/adv-products/${productId}`, product)
    toast.success(i18n.t('successfullyUpdated', { entity: i18n.t('product') }))
    setTimeout(() => {
      setCallbackToGetProducts()
    }, 1000)
  } catch (err) {
    toast.error(i18n.t('failedToUpdate', { entity: i18n.t('product') }))
  }
}

export function* deleteCustomAdvertisementProduct({ payload }) {
  const { productIds, communityId } = payload
  const request = {
    params: {
      ids: productIds,
    },
  }
  try {
    yield call(api.delete, `community/${communityId}/adv-products/delete`, request)
    toast.success(i18n.t('successfullyDeleted', { entity: i18n.t('product') }))
  } catch (err) {
    toast.error(i18n.t('failedToDelete', { entity: i18n.t('product') }))
  }
}

export function* updateCustomAdvertisementProductActiveStatus({ payload }) {
  const { status, productId, communityId } = payload
  try {
    yield call(api.patch, `community/${communityId}/adv-products/${productId}/active-status`, {
      activeStatus: status,
    })
    toast.success(i18n.t('successfullyUpdated', { entity: i18n.t('product') }))
  } catch (err) {
    toast.error(i18n.t('failedToUpdate', { entity: i18n.t('product') }))
  }
}

export function* createPackage({ payload }) {
  const { packageInfo, communityId, setCallbackToGetPackages } = payload
  try {
    yield call(api.post, `community/${communityId}/packages`, packageInfo)
    toast.success(i18n.t('successfullyCreatedF', { entity: i18n.t('package') }))
    setTimeout(() => {
      setCallbackToGetPackages()
    }, 1000)
  } catch (err) {
    toast.error(i18n.t('failedToCreate', { entity: i18n.t('package') }))
  }
}

export function* updatePackage({ payload }) {
  const { packageId, packageInfo, communityId, setCallbackToGetPackages } = payload
  try {
    yield call(api.put, `community/${communityId}/packages/${packageId}`, packageInfo)
    toast.success(i18n.t('successfullyUpdatedF', { entity: i18n.t('package') }))
    setTimeout(() => {
      setCallbackToGetPackages()
    }, 1000)
  } catch (err) {
    toast.error(i18n.t('failedToUpdate', { entity: i18n.t('package') }))
  }
}

export function* updatePackageActiveStatus({ payload }) {
  const { status, packageId, communityId } = payload
  try {
    yield call(api.patch, `community/${communityId}/packages/${packageId}/active-status`, {
      statusActive: status,
    })
    toast.success(i18n.t('successfullyUpdatedF', { entity: i18n.t('package') }))
  } catch (err) {
    toast.error(i18n.t('failedToUpdate', { entity: i18n.t('package') }))
  }
}

export function* deletePackage({ payload }) {
  const { packageIds, communityId } = payload
  const request = {
    params: {
      ids: packageIds,
    },
  }
  try {
    yield call(api.delete, `community/${communityId}/packages/delete`, request)
    toast.success(i18n.t('successfullyDeletedF', { entity: i18n.t('package') }))
  } catch (err) {
    toast.error(i18n.t('failedToDelete', { entity: i18n.t('package') }))
  }
}

export function* updateCommunitySponsorCooperationStatus({ payload }) {
  const { status, communityId, sponsorId } = payload
  try {
    yield call(api.patch, `community/${communityId}/sponsors/${sponsorId}`, {
      status,
    })
    toast.success(i18n.t('sponsorCooperationUpdated'))
  } catch (err) {
    toast.error(i18n.t('failedToUpdateCooperation'))
  }
}

export function* updateSponsorCommunityCooperationStatus({ payload }) {
  const { status, communityId, sponsorId } = payload
  try {
    yield call(api.patch, `sponsor/${sponsorId}/community/${communityId}`, {
      status,
    })
    toast.success(i18n.t('communityCooperationUpdated'))
  } catch (err) {
    toast.error(i18n.t('failedToUpdateCooperation'))
  }
}

export function* addCommunityGalleryMediaRequest({ payload }) {
  const { uploadTypeName, image, communityId } = payload

  const { token } = store.getState().auth

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  try {
    const formData = new FormData()

    formData.append('file', image)

    networkApi.defaults.headers.Authorization = `Bearer ${token}`

    const response = yield call(networkApi.post, '/uploads', formData, config)
    const { id, url } = response?.data.data[0]

    const registerResponse = yield call(api.post, `/community/${communityId}/media/${id}`)

    const newMedia = {
      communityMediaId: registerResponse.data.id,
      upload: {
        id,
        imageUrl: url,
        linkUrl: null,
      },
    }
    yield put(addCommunityGalleryMediaSuccess(uploadTypeName, newMedia))
    toast.success(i18n.t('mediaSentSuccessfully'))
  } catch (err) {
    yield put(addCommunityGalleryMediaFailure(uploadTypeName))
    toast.error(i18n.t('errorUpdatingFile'))
  }
}

export function* deleteCommunityGalleryMediaRequest({ payload }) {
  const { index, type, communityId, communityMediaId } = payload
  try {
    yield call(api.delete, `/community/${communityId}/${communityMediaId}`)
    yield put(deleteCommunityGalleryMediaSuccess(type, communityMediaId))
    toast.success(i18n.t('mediaDeletedSuccessfully'))
  } catch (err) {
    yield put(deleteCommunityGalleryMediaFailure(index, type))
    toast.error(i18n.t('errorDeletingFile'))
  }
}

export default all([
  takeLatest(GET_COMMUNITY_LINK_INVITATION_REQUEST, getCommunityLinkInvitationRequest),
  takeLatest(UPDATE_COMMUNITY_PRODUCT_REQUEST, updateCommunityProductRequest),
  takeLatest(UPDATE_COMMUNITY_PROFILE_REQUEST, updateCommunityProfileRequest),
  takeLatest(UPLOAD_S3_REQUEST, uploadS3Request),
  takeLatest(GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST, getCommunityInformations),
  takeLatest(CREATE_CUSTOM_ADVERTISEMENT_PRODUCT, createCustomAdvertisementProduct),
  takeLatest(UPDATE_CUSTOM_ADVERTISEMENT_PRODUCT, updateCustomAdvertisementProduct),
  takeLatest(DELETE_CUSTOM_ADVERTISEMENT_PRODUCT, deleteCustomAdvertisementProduct),
  takeLatest(UPDATE_CUSTOM_ADVERTISEMENT_PRODUCT_ACTIVE_STATUS, updateCustomAdvertisementProductActiveStatus),
  takeLatest(CREATE_PACKAGE, createPackage),
  takeLatest(UPDATE_PACKAGE_ACTIVE_STATUS, updatePackageActiveStatus),
  takeLatest(DELETE_PACKAGE, deletePackage),
  takeLatest(UPDATE_PACKAGE, updatePackage),
  takeLatest(UPDATE_COMMUNITY_SPONSOR_COOPERATION_STATUS, updateCommunitySponsorCooperationStatus),
  takeLatest(UPDATE_SPONSOR_COMMUNITY_COOPERATION_STATUS, updateSponsorCommunityCooperationStatus),
  takeLatest(ADD_COMMUNITY_GALLERY_MEDIA_REQUEST, addCommunityGalleryMediaRequest),
  takeLatest(DELETE_COMMUNITY_GALLERY_MEDIA_REQUEST, deleteCommunityGalleryMediaRequest),
])
