import styled from 'styled-components'
import { customMedia } from '~/styles/global'
import { DefaultProjectLogo } from '~/util/defaultImages'

export const Container = styled.header.attrs({
  as: 'header',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 9rem;

  ${customMedia.lessThan('510px')`
      display: flex;
    `}

  ${customMedia.lessThan('985px')`
    flex-direction: column;
  `}

${customMedia.lessThan('small')`
  padding-bottom: 0;
`}
`

export const BeLogo = styled.img.attrs({
  alt: 'Project Logo',
  role: 'presentation',
  width: '240',
  src: DefaultProjectLogo,
})`
  cursor: pointer;
  padding-bottom: 20px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  ${customMedia.lessThan('small')`
    flex-direction: column;
  `}
`
export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`

export const DiscoverBeButton = styled.button`
  background-color: #fff;
  width: 165px;
  height: 35px;
  transition: 0.5s;
  border: 1px solid #e2e2e2;
  border-radius: 100px;

  &:hover {
    background-position: right center;
    -webkit-transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
    -moz-transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
    -o-transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
    -ms-transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
    transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
  }
`

export const DiscoverBeText = styled.span`
  color: #7345e9;
  font-size: 18px;
  font-weight: bold;
  font-family: Lato;
  letter-spacing: 0px;

  &:hover {
    transform: scale(1.05);
  }
`

export const LoginButton = styled.button`
  background-color: #9671f5;
  width: 110px;
  height: 35px;
  transition: 0.5s;
  border: 1px solid var(--light-white);
  border-radius: 100px;

  &:hover {
    background-position: right center;
    -webkit-transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
    -moz-transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
    -o-transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
    -ms-transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
    transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
  }
`

export const LoginText = styled.span`
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  font-family: Lato;
  letter-spacing: 0px;
  &:hover {
    transform: scale(1.05);
  }
`
