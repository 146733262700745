import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`

export const Text = styled.p`
  margin-bottom: 0px;
  font-size: 32px;
  color: rgba(214, 214, 214, 0.5);
  font-family: Montserrat;
`
