import { SIGN_OUT } from '~/store/modules/auth/actionTypes'
import { RESET_CAMPAIGN_PROCESS } from '~/store/modules/createCampaign/actionTypes'
import { UPDATE_STEP, INCREASE_STEP, DECREASE_STEP, RESET_STEP } from './actionTypes'

const INITIAL_STATE = {
  step: 0,
}

export default function stepReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_STEP:
      return { ...state, step: action.payload }

    case INCREASE_STEP:
      return { ...state, step: state.step + 1 }

    case DECREASE_STEP:
      return { ...state, step: state.step - 1 }

    case RESET_STEP: {
      return { ...state, step: INITIAL_STATE.step }
    }

    case SIGN_OUT: {
      return { ...state, step: INITIAL_STATE.step }
    }

    case RESET_CAMPAIGN_PROCESS: {
      return { ...state, step: INITIAL_STATE.step }
    }

    default:
      return state
  }
}
