import React, { useMemo } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js'
import useResponsiveFontSize from '~/helper/useResponsiveFontSize'
import CreateCampaignModalTooltipStripe from '~/components/ApplicationTutorial//Sponsor/CreateCampaignModalTooltipStripe'
import StepPayment from '~/components/StepPayment'
import * as S from './styled'

export default function ModalCreditCardInformationTutorialMocked({ handleCloseModal, showModal }) {
  const { t } = useTranslation()
  const useOptions = () => {
    const fontSize = useResponsiveFontSize()
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Source Code Pro, monospace',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
      }),
      [fontSize],
    )
    return options
  }

  const options = useOptions()

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.Title>{t('yourCreditCard')}</S.Title>
        </Modal.Title>
      </Modal.Header>

      <S.Container>
        <S.StepContainer>
          <S.StepContainerTitle>{t('payment')}</S.StepContainerTitle>
          <StepPayment />
        </S.StepContainer>
      </S.Container>

      <Modal.Body>
        <S.Form>
          <S.FormContainer>
            <S.Label>{t('cardNumber')}</S.Label>
            <S.StripeElementContainer>
              <CardNumberElement options={options} />
            </S.StripeElementContainer>
            <S.Label>{t('expirationDate')}</S.Label>
            <S.StripeElementContainer>
              <CardExpiryElement options={options} />
            </S.StripeElementContainer>
            <S.Label className="mb-0">CVC</S.Label>
            <S.StripeElementContainer>
              <CardCvcElement options={options} />
            </S.StripeElementContainer>
            <S.PoweredByStripeContainer>
              <S.PoweredByStripe />
            </S.PoweredByStripeContainer>
          </S.FormContainer>
        </S.Form>
      </Modal.Body>
      <S.ModalFooter>
        <S.SubmitButton uppercase text={t('done')} isDisabled />
      </S.ModalFooter>

      {showModal && (
        <CreateCampaignModalTooltipStripe closeCreditCardInformationTutorialMockedModal={handleCloseModal} />
      )}
    </S.ModalCustom>
  )
}
