/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { FaRegImage } from 'react-icons/fa'
import Select from 'react-select'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { useSelector } from 'react-redux'
import ReactTagInput from '@pathofdev/react-tag-input'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import Border from '~/components/Shared/Border'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import { reactSelectStyleDefault } from '~/helper/const'
import { DatePickerInput, InputTitle, FormatUploadButton } from '~/components/Shared/StyledComponents'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import * as CONST from '~/helper/const'
import { UploadS3, mountCouponTypeComboWithId, prepareToSendReward } from '~/helper/formFunctions'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import ContentVisualization from '~/components/CreateFormatMobileVisualization/Content'
import { transformDateToUtcFormat } from '~/helper/getFormattedDates'

import * as S from './styled'

export default function ModalEditReward({
  handleCloseModal,
  showModal,
  rewardToUpdate,
  review,
  createMessage,
  refuseCampaignProduct,
  approveCampaignProduct,
  formatId,
  sponsor,
}) {
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const { t } = useTranslation()
  const { updateCampaignFormatAction } = useSponsorFunctions()
  const { image, link, title, additionalInfo, couponQuantity, couponCode } = rewardToUpdate
  const [imageId, setImageId] = useState(image)
  const [imageUrl, setImageUrl] = useState(rewardToUpdate.imageUrl)
  const { handleSubmit, setValue, register, errors, control, watch } = useForm()
  const [updatingImage, setUpdatingImage] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [expireDate, setExpireDate] = useState(new Date(rewardToUpdate.expireDate))
  const [date, setDate] = useState(transformDateToUtcFormat(rewardToUpdate.date))
  const [endDate, setEndDate] = useState(transformDateToUtcFormat(rewardToUpdate.endDate))
  const [rewardCost, setRewardCost] = useState(rewardToUpdate.rewardCost)
  const [couponType, setCouponType] = useState(
    mountCouponTypeComboWithId(rewardToUpdate.couponType, CONST.couponTypeOptions),
  )
  const [burnableCode, setBurnableCode] = useState(
    rewardToUpdate.burnableCode ? rewardToUpdate.burnableCode.map(burnable => burnable) : [],
  )
  const titleWatch = watch('title')
  const additionalInfoWatch = watch('additionalInfo')

  function onChangeSlider(value) {
    setRewardCost(value)
  }

  function resetFormAndCloseModal() {
    setExpireDate('')
    setDate('')
    setCouponType(CONST.couponTypeOptions[0])
    setRewardCost(null)
    setImageUrl(null)
    setImageId(null)
    setUpdatingImage(false)
    handleCloseModal()
  }

  function handleCloseModalAndResetCouponFields() {
    setCouponType(mountCouponTypeComboWithId(rewardToUpdate.couponType, CONST.couponTypeOptions))
    handleCloseModal()
  }

  function onSubmit(product) {
    const copyObject = JSON.stringify(rewardToUpdate)
    const productCopy = JSON.stringify(product)
    if (copyObject === productCopy) {
      return
    }
    updateCampaignFormatAction(product, formatId, resetFormAndCloseModal)
  }

  function handleUpdatingImage() {
    setUpdatingImage(!updatingImage)
  }

  function modalReviewCreateMessageAndCloseModal() {
    createMessage()
    refuseCampaignProduct()
    handleCloseModal()
  }

  useEffect(() => {
    register({ name: 'id' })
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    register({ name: 'rewardCost' })
    if (couponType?.label === 'Burnable') {
      if (burnableCode.length > 0) {
        register({ name: 'burnableCode' })
        setValue('burnableCode', burnableCode)
      }
    }
    setValue('rewardCost', rewardCost)
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
    setValue('id', rewardToUpdate.id)
  }, [register, rewardToUpdate.id, setValue, imageId, imageUrl, couponType, burnableCode, rewardCost])

  return (
    <ProductsModal
      createMessage={modalReviewCreateMessageAndCloseModal}
      approveCampaignProduct={approveCampaignProduct}
      buttonText={review ? t('done') : t('update')}
      modalTitle={review ? t('reviseFormat', { format: t('reward') }) : t('editFormat', { format: t('reward') })}
      action={onSubmit}
      handleCloseModal={handleCloseModalAndResetCouponFields}
      showModal={showModal}
      loadingUpload={loadingUpload}
      review={review}
      sponsor={sponsor}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(prepareToSendReward(onSubmit, couponType, burnableCode))}>
          <S.FormContainer>
            <InputTitle>{t('title')}</InputTitle>
            <Controller
              as={
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={t('typeTitle')}
                  inputProps={{
                    maxLength: 140,
                  }}
                  disabled={review || false}
                />
              }
              rules={{ required: t('defaultValidation', { inputName: t('title') }) }}
              name="title"
              control={control}
              defaultValue={title === null ? '' : title}
            />
            <div className="error-message">
              <ErrorMessage errors={errors} name="title" as="p" />
            </div>

            <StartDateSection
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              disabled={review}
              control={control}
              errors={errors}
            />
            <EndDateSection
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              disabled={review}
              control={control}
              errors={errors}
            />

            <div className="mt-3">
              <InputTitle>{t('dateExpire')}</InputTitle>
              <Controller
                as={
                  <DatePickerInput
                    selected={expireDate}
                    placeholderText={t('chooseExpireDate')}
                    disabled={review || false}
                    minDate={date}
                    maxDate={new Date(currentCampaignForReviewPage.dtEnd)}
                  />
                }
                rules={{ required: t('defaultValidation', { inputName: t('dateExpire') }) }}
                name="expireDate"
                control={control}
                onChange={([selected]) => {
                  setExpireDate(selected)
                  return selected
                }}
                defaultValue={expireDate}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="expireDate" as="p" />
              </div>
            </div>

            <div className="mt-3">
              <InputTitle>{t('additionalInfo')}</InputTitle>
              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={t('tellThemMoreAbout', { format: t('reward').toLocaleLowerCase() })}
                    multiline
                    rows="4"
                    disabled={review || false}
                  />
                }
                rules={{ required: t('defaultValidation', { inputName: t('additionalInfo') }) }}
                name="additionalInfo"
                control={control}
                defaultValue={additionalInfo === null ? '' : additionalInfo}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="additionalInfo" as="p" />
              </div>
            </div>

            <div className="mt-3">
              <InputTitle>{t('price')}</InputTitle>
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                placeholder={t('rewardPrice')}
                value={rewardCost}
                disabled={review || false}
                onChange={e => setRewardCost(e.target.value)}
              />
              {review === true ? null : (
                <div className="slider-horizontal">
                  <Slider min={0} max={500000} value={rewardCost} orientation="horizontal" onChange={onChangeSlider} />
                  <div>At this level 12 members can afford this advantage now</div>
                </div>
              )}

              <div className="error-message">
                <ErrorMessage errors={errors} name="rewardCost" as="p" />
              </div>
            </div>

            <div className="mt-3">
              <InputTitle>{t('useCouponType')}</InputTitle>
              <Controller
                as={
                  <Select
                    styles={reactSelectStyleDefault}
                    placeholder={t('placeholderSelect')}
                    options={CONST.couponTypeOptions}
                    isDisabled={review || false}
                  />
                }
                rules={{ required: t('defaultValidation', { inputName: t('couponType') }) }}
                name="couponType"
                control={control}
                onChange={([selected]) => {
                  setCouponType(selected)
                  return selected
                }}
                defaultValue={mountCouponTypeComboWithId(rewardToUpdate.couponType, CONST.couponTypeOptions)}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="couponType" as="p" />
              </div>
            </div>

            {couponType == null
              ? null
              : (() => {
                  switch (couponType.label) {
                    case t('couponReusable'):
                      return (
                        <>
                          <div className="mt-1">
                            <InputTitle>{t('writeTheCouponCode')}</InputTitle>
                            <Controller
                              as={
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  placeholder={t('enterReusableCouponCode')}
                                  disabled={review || false}
                                />
                              }
                              name="couponCode"
                              control={control}
                              defaultValue={couponCode}
                            />
                          </div>
                          <div className="mt-1">
                            <InputTitle>{t('howManyCoupons')}</InputTitle>
                            <Controller
                              as={
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  placeholder={t('howManyCouponsOffering')}
                                  type="number"
                                  disabled={review || false}
                                />
                              }
                              name="couponQuantity"
                              control={control}
                              defaultValue={couponQuantity}
                            />
                          </div>
                          <div className="mt-1">
                            <InputTitle>{t('whereCanBeClaimed')}</InputTitle>
                            <Controller
                              as={
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  placeholder="e.g. https://www.greatecontent.com/article10"
                                  disabled={review || false}
                                />
                              }
                              name="link"
                              control={control}
                              defaultValue={link}
                            />
                          </div>
                          <div className="mt-4">
                            <Border />
                          </div>
                        </>
                      )

                    case t('couponBurnable'):
                      return (
                        <>
                          <div className="mt-1">
                            <InputTitle>{t('burnableCouponCodes')}</InputTitle>
                            <S.Optional>{t('typeCouponAndPressEnter')}</S.Optional>
                            <ReactTagInput
                              tags={burnableCode}
                              onChange={newBurnableCode => setBurnableCode(newBurnableCode)}
                              placeholder={t('typeCouponAndPressEnter')}
                              maxTags={10}
                              removeOnBackspace
                              readOnly={review || false}
                            />
                          </div>
                          <div className="mt-1">
                            <InputTitle>{t('whereCanBeClaimed')}</InputTitle>
                            <Controller
                              as={
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  placeholder="e.g. https://www.greatecontent.com/article10"
                                  disabled={review || false}
                                />
                              }
                              name="link"
                              control={control}
                              defaultValue={link}
                            />
                          </div>
                        </>
                      )
                    case t('no'):
                      return null
                    default:
                      return null
                  }
                })()}

            {review === false ||
              (review === undefined && (
                <div className="mt-4 mb-3">
                  <Border />
                </div>
              ))}

            {review === false || review === undefined ? (
              updatingImage ? (
                <div className="mt-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <FaRegImage className="mr-2" size={14} />
                    <InputTitle>{t('image')}</InputTitle>
                  </div>
                  <UploadS3
                    setUploadId={setImageId}
                    setUploadUrl={setImageUrl}
                    setLoadingUpload={setLoadingUpload}
                    fileType="image"
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <FormatUploadButton text={t('addImage')} action={handleUpdatingImage} />
                </div>
              )
            ) : null}
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          FormatVisualizationComponent={
            <ContentVisualization
              image={imageUrl}
              title={titleWatch || title}
              about={additionalInfoWatch || additionalInfo}
              contentType={t('reward')}
            />
          }
        />
      }
    />
  )
}
