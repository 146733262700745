import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { MdDone } from 'react-icons/md'
import WhiteButton from '~/components/WhiteButton'

export const ModalCustom = styled(Modal).attrs({
  backdrop: 'static',
  keyboard: false,
})`
  .modal-dialog {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .modal-content {
    width: 100%;
    padding-bottom: 5px;
    border-radius: 10px;
    margin: auto;
  }

  .modal-body {
    position: relative;
    padding: 20px;
  }

  .modal-header {
    padding: 10px 10px 0px 10px !important;
  }
`

export const ModalTitle = styled.p`
  color: var(--text-bold);
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  opacity: 0.9;
`

export const ModalMessage = styled.p`
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 16px;
  margin: 0px;
`

export const DoneButton = styled(WhiteButton).attrs({
  type: 'submit',
  style: { marginTop: '6px', marginRight: '10px', padding: '0px 30px 0px 20px' },
})``

export const DoneIcon = styled(MdDone).attrs({
  color: 'var(--success)',
  size: 23,
})``

export const ModalFooter = styled(Modal.Footer)`
  padding: 12px 6px 10px 6px !important;
`
