import React from 'react'
import Row from 'react-bootstrap/Row'
import { format } from 'date-fns'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import Col from 'react-bootstrap/Col'
import CampaignReviewProductsButtons from '~/components/Community/CampaignReviewProductsButtons'
import ProductBadge from '~/components/Shared/ProductBadge'
import CalendarBadge from '~/components/CalendarBadge'
import * as S from './styled'

export function EventReviewCard({ events }) {
  return (
    <Container fluid>
      <S.CardBody>
        <Row>
          <Col>
            <div className="icon-section">
              <img style={{ opacity: '.9' }} src="/icons/rewards.png" alt="Rewards" width="19" height="19" />
              <p className="ml-2 mt-2">Event</p>
            </div>
          </Col>
        </Row>
        <S.AnalyticsCardRow>
          {events.map(event => (
            <EventCard
              key={event.id}
              title={event.unity.title}
              about={event.unity.about}
              startDate={event.unity.dtStart}
              image={event.imageUrl}
              analytics={event.analytics}
            />
          ))}
        </S.AnalyticsCardRow>
      </S.CardBody>
    </Container>
  )
}

export function EventCard({ title, about, startDate, image, analytics }) {
  const { t } = useTranslation()
  const getStartDateMonth = format(new Date(startDate), 'MMM')
  const getStartDateDay = format(new Date(startDate), 'dd')

  return (
    <Col md={6} className="mt-4">
      <S.CardEventBody>
        <Row>
          <Col className="mb-4">
            <p className="event-title">{title}</p>
          </Col>
        </Row>

        <Row className="d-flex align-items-start">
          <Col md={6}>
            <S.CardContainer>
              <S.ContainerEventImage>
                <S.EventImage
                  src={image === null || image === undefined ? '/community-default-banner-picture.jpg' : image}
                  alt="Campaign Logo"
                />
              </S.ContainerEventImage>
              <ProductBadge
                style={{ marginLeft: '10px', marginBottom: '10px !important' }}
                text={t('event')}
                color="#87919A"
                width="60px"
                height="25px"
                eventText="- artificial intelligence"
                eventTextColor="var(--input-title)"
                fontSize="12px"
                eventTextFontSize="13px"
                marginTop="10px"
              />
              <div className="event-card-title-section">
                <CalendarBadge
                  style={{ marginTop: '5px', marginLeft: '10px' }}
                  text="EVENT"
                  color="#F2f2f2"
                  width="60px"
                  height="60px"
                  day={getStartDateDay}
                  month={getStartDateMonth}
                />
                <div>
                  <p className="event-card-about">{about}</p>
                </div>
              </div>
            </S.CardContainer>
          </Col>

          <Col md={6} className="p-0 product-buttons">
            <CampaignReviewProductsButtons
              style={{ marginRight: '10px' }}
              text={t('saves')}
              number={analytics?.saves}
              color="#49A54D"
              icon={<img src="/icons/campaignReview/bookmark.png" alt="Saves" width="24" height="24" />}
            />
            <CampaignReviewProductsButtons
              text={t('shares')}
              number={analytics?.shares}
              color="#E94440"
              icon={<img src="/icons/campaignReview/share.png" alt="Shares" width="24" height="24" />}
            />
            <CampaignReviewProductsButtons
              style={{ marginRight: '10px', marginTop: '10px' }}
              text={t('comments')}
              number={analytics?.comments}
              color="#FC930E"
              icon={<img src="/icons/campaignReview/comments.png" alt="Comments" width="24" height="24" />}
            />
            <CampaignReviewProductsButtons
              text={t('clickThrough')}
              number={analytics?.countClicksThrough}
              color="#35CC9E"
              icon={<img src="/icons/campaignReview/linkview.png" alt="Click through" width="24" height="24" />}
            />
            <CampaignReviewProductsButtons
              style={{ marginRight: '10px', marginTop: '10px' }}
              text={t('views')}
              number={analytics?.countViews}
              color="#25B9CD"
              icon={<img src="/icons/campaignReview/view.png" alt="view" width="24" height="24" />}
            />
          </Col>
        </Row>
      </S.CardEventBody>
    </Col>
  )
}
