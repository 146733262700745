import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import * as S from './styled'

const RadioCheckbox = withStyles({
  root: {
    color: '#d2d7df',
    '&$checked': {
      color: 'var(--sponsor-primary)',
    },
  },
  checked: {},
})(props => <S.RadioStyle {...props} />)

export default RadioCheckbox
