import styled from 'styled-components'
import { AiOutlineHeart } from 'react-icons/ai'
import { BiDotsHorizontalRounded, BiBookmark } from 'react-icons/bi'
import { FiSend } from 'react-icons/fi'
import { BsChat } from 'react-icons/bs'
import { IoIosImages } from 'react-icons/io'

export const TopPreviewContainer = styled.div`
  background-color: #000000;
  padding-bottom: 10px;
`

export const PostHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 0px 10px;
`

export const CommunityInformations = styled.div`
  display: flex;
  align-items: center;
`

export const CommunityLogoBorder = styled.div`
  background: linear-gradient(45deg, hsl(37, 97%, 70%) 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  width: 42px;
  height: 42px;
  border-radius: 100px;
  padding: 2px;
`

export const CommunityLogo = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 100px;
`

export const CommunityName = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  max-width: 170px;
  margin-bottom: 0px;
`

export const InstagramCerfifiedLogo = styled.img.attrs({
  src: 'instagram-certified.png',
})`
  width: 12px;
  height: 12px;
  margin-left: 6px;
`

export const MiddlePreviewContainer = styled.div`
  background-color: #000000;
  padding-bottom: 100px;
  margin-top: -8px;
`

export const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  height: 350px;
`

export const MediaImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const NoMediaIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  background-color: #ccc;
`

export const PlayIconContainer = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff0000;
  align-self: center;
  position: absolute;
`

export const PlayIcon = styled.img.attrs({
  src: '/play-icon.png',
})`
  width: 16px;
  height: 16px;
  margin-left: 3px;
`

export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 0px;
`

export const Actions = styled.div``

export const Views = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  margin-left: 16px;
  margin-bottom: 0px;
`

export const CommunityNameContainer = styled.div`
  margin: 0px 16px;
  display: flex;
  flex-direction: column;
`

export const CommunityPostName = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-right: 8px;
  width: 100%;
`

export const PostComment = styled.p`
  color: #e4e6eb;
  font-family: Lato;
  font-size: 14px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-width: 310px;
  max-height: 120px;
`

export const PostCommentAndDescription = styled(PostComment)`
  -webkit-line-clamp: 4;
  display: -webkit-box;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
`

export const ImageIcon = styled(IoIosImages).attrs({
  size: 28,
  color: 'red',
})``

export const ChatIcon = styled(BsChat).attrs({
  size: 20,
  color: '#FFF',
})`
  margin-left: 14px;
`

export const LikeIcon = styled(AiOutlineHeart).attrs({
  size: 22,
  color: '#FFF',
})`
  margin-left: 14px;
`

export const BookmarkIcon = styled(BiBookmark).attrs({
  size: 22,
  color: '#FFF',
})``

export const SendIcon = styled(FiSend).attrs({
  size: 22,
  color: '#FFF',
})`
  margin-left: 14px;
`

export const ThreeDotsIcon = styled(BiDotsHorizontalRounded).attrs({
  size: 21,
  color: '#FFF',
})``
