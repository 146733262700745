/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import history from '~/services/history'
import LandingPageHeader from '~/components/LandingPageHeader'
import SignupModalSponsor from '~/components/Sponsor/SignupModalSponsor'
import * as S from './styled'

function SponsorInvitation({ match }) {
  const [showSignUpModalSponsor, setShowSignUpModalSponsor] = useState(false)
  const { id: invitationDirectLink } = match.params

  const handleCloseModal = useCallback(() => {
    setShowSignUpModalSponsor(false)
    history.push('/')
  }, [])

  useEffect(() => {
    if (invitationDirectLink !== undefined) {
      setShowSignUpModalSponsor(true)
    }
  }, [invitationDirectLink])

  return (
    <S.Container>
      <LandingPageHeader homeButton />
      <SignupModalSponsor
        handleCloseModal={handleCloseModal}
        showModal={showSignUpModalSponsor}
        invitationDirectLink={invitationDirectLink}
      />
    </S.Container>
  )
}

export default withRouter(SponsorInvitation)
