import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

export default function ModalInformationAfterFormatCreation({ handleCloseModal, showModal }) {
  const { t } = useTranslation()

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>
          <S.ModalTitle>{t('attention')}</S.ModalTitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <S.ModalMessage>{t('thanksForSubmittingFormat')}</S.ModalMessage>
      </Modal.Body>
      <S.ModalFooter>
        <S.DoneButton text={t('done')} onClick={() => handleCloseModal()} icon={<S.DoneIcon />} />
      </S.ModalFooter>
    </S.ModalCustom>
  )
}
