import styled from 'styled-components'
import { IoIosAdd } from 'react-icons/io'
import { customMedia } from '~/styles/global'
import WhiteButton from '~/components/WhiteButton'

export const StartCampaignButton = styled(WhiteButton).attrs({})``

export const AddIcon = styled(IoIosAdd).attrs({
  color: '#00A2FF',
  size: 26,
})``

export const NewCampaignGradientButton = styled.div`
  background-image: linear-gradient(to right, #0090de 0%, #00a2ff 51%, #0090de 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  border-radius: 100px;
  cursor: pointer;
  position: fixed;
  bottom: 1rem;
  right: 4.5rem;
  z-index: 10;
  max-width: 300px;
  padding: 0px 20px;
  height: 48px;
  &:hover {
    transform: scale(1.05);
    background-position: right center;
  }
  ${customMedia.lessThan('small')`
      display: none;
  `}
`

export const NewCampaignText = styled.span`
  font-size: 15px;
  color: #fff;
  margin: 0px;
  font-family: Montserrat;
  font-weight: bold;
`
