import styled from 'styled-components'
import { darken } from 'polished'
import { customMedia } from '~/styles/global'

export const Container = styled.div.attrs({
  className: 'stripe-section',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--yellow);
  border-radius: 5px;
  padding: 12px 15px 12px 15px;
  margin-bottom: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

  .subtitle {
    padding-bottom: 3px;
    color: #284765;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
  }

  ${customMedia.lessThan('1555px')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${customMedia.lessThan('1555px')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`

export const Title = styled.p`
  margin: 0px;
  color: #262626;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
`

export const Subtitle = styled.p`
  margin: 0px;
  padding-bottom: 3px;
  color: #284765;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  font-family: Roboto;
  padding: 0px 20px;
  height: 40px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background-color: ${darken(0.05, '#FFF')};
  }

  &:active {
    position: relative;
    top: 2px;
  }

  ${customMedia.lessThan('1555px')`
    margin: 6px 0px;
  `}
`

export const ButtonText = styled.p`
  margin: 0px;
  font-family: Roboto;
  font-size: 16px;
  text-align: center;
`
