import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import { toast } from 'react-toastify'
import { RiSave2Line } from 'react-icons/ri'
import { modalRatingStyle } from '~/helper/const'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { DefaultLogoImage } from '~/util/defaultImages'
import Border from '~/components/Shared/Border'
import { getFormattedDate } from '~/helper/getFormattedDates'
import * as S from './styled'

export default function ModalRatingCampaign({
  handleCloseModal,
  showModal,
  campaign,
  entity,
  entityInformations,
  entityLogo,
}) {
  const { t } = useTranslation()
  const { setCampaignRatingAction } = useSponsorFunctions()
  const { handleSubmit, control, register, setValue } = useForm()
  const { budget: campaignTotalPrice, id: campaignId, dtStart, dtEnd } = campaign
  const { name: entityName, adrCity: entityCity, adrCountry: entityCountry } = entityInformations
  const [rating, setRating] = useState(null)

  function onSubmit(data) {
    if (rating === null || rating === false) {
      return toast.warning(t('pleaseSelectTheRatingFirst'))
    }
    setCampaignRatingAction(entity, campaignId, data.rating, data.note)
    return handleCloseModal()
  }

  const startDate = getFormattedDate(dtStart)
  const endDate = getFormattedDate(dtEnd)

  useEffect(() => {
    register({ name: 'rating' })
    setValue('rating', rating)
  }, [register, rating, setValue])

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.ModalTitle>{t('campaignRating')}</S.ModalTitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalRatingStyle}>
        <S.CommunityInformationsContainer>
          <S.CommunityLogoAndLocationContainer>
            <S.EntityLogo src={entityLogo || DefaultLogoImage} />
            <S.CommunityContainer>
              <S.CommunityNameAndLocationSection>
                <S.CommunityName>{entityName}</S.CommunityName>
                {entityCity && entityCountry && (
                  <S.CommunityLocation>{`${entityCity}, ${entityCountry}`}</S.CommunityLocation>
                )}
              </S.CommunityNameAndLocationSection>
            </S.CommunityContainer>
          </S.CommunityLogoAndLocationContainer>
          <S.DateContainer>
            <S.Subtitle>{t('startDate')}</S.Subtitle>
            <S.ChartTitle>{startDate}</S.ChartTitle>
          </S.DateContainer>
          <S.DateContainer>
            <S.Subtitle>{t('endDate')}</S.Subtitle>
            <S.ChartTitle>{endDate}</S.ChartTitle>
          </S.DateContainer>
          <S.CampaignBudgetContainer>
            <S.Subtitle>{t('budgetUsed')}</S.Subtitle>
            <S.ChartTitle>{`€ ${campaignTotalPrice}`}</S.ChartTitle>
          </S.CampaignBudgetContainer>
        </S.CommunityInformationsContainer>
        <S.BorderContainer>
          <Border />
        </S.BorderContainer>
        <S.RatingTextSection>
          <S.RateCampaignText>
            {t('pleaseRateYourCampaignWith')}
            {entityName}
          </S.RateCampaignText>
          <S.RateMessageText>{t('considerOneStarRating')}</S.RateMessageText>
        </S.RatingTextSection>
        <S.RatingComponentContainer>
          <S.RatingCampaign defaultValue={rating} onChange={e => setRating(e.target.value)} />
        </S.RatingComponentContainer>
        <S.FormContainer>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <S.FormRow>
              <S.FormColumn>
                <S.ShareText>{t('wouldYouLikeToShareWithUs')}</S.ShareText>
                <Controller
                  as={<TextField fullWidth variant="outlined" multiline rows="6" />}
                  name="note"
                  control={control}
                />
              </S.FormColumn>
            </S.FormRow>
          </S.Form>
        </S.FormContainer>
      </Modal.Body>
      <S.ModalFooter>
        <S.NotNow onClick={handleCloseModal}>{t('notNow')}</S.NotNow>
        <S.SaveButton text={t('save')} large icon={<RiSave2Line color="var(--success)" size={23} />} />
      </S.ModalFooter>
    </S.ModalCustom>
  )
}
