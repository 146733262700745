import styled from 'styled-components'
import { Modal, Row, Col } from 'react-bootstrap'
import { darken } from 'polished'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import UploadButton from '~/components/Shared/UploadButton'
import { customMedia } from '~/styles/global'

export const ModalCustom = styled(Modal).attrs({
  backdrop: 'static',
  keyboard: false,
})`
  .modal-dialog {
    max-width: 550px;
    height: auto;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;

    ${customMedia.lessThan('small')`
      margin-top: 0px;
      margin-bottom: 0px;
    `}

    .image-container {
      img {
        ${customMedia.lessThan('1500px')`
          display: none;
        `}
      }
    }
  }

  .modal-content {
    height: auto;
    margin: auto;
    width: 580px;
    border-radius: 0;
    padding: 0;

    ${customMedia.lessThan('small')`
      width: 23.5rem !important;
    `}
  }
`

export const ModalHeader = styled(Modal.Header).attrs({
  closeButton: true,
})`
  border: none !important;
  padding-bottom: 0px !important;
`

export const ModalBody = styled(Modal.Body)`
  background-color: #fff;
  border: none;
`

export const ModalFooter = styled(Modal.Footer)`
  border: none !important;
  padding-bottom: 0px !important;
`

export const MainFormColumn = styled(Col).attrs({
  md: 12,
})``

export const MainFormRow = styled(Row)``

export const TitleContainer = styled.div.attrs({ className: 'animated fadeIn' })``

export const PreviewCommunitySignupSponsorContainer = styled.div`
  margin-top: 24px !important;
`

export const Form = styled.form`
  .checkbox-input {
    input {
      background-color: #ffffff;
      border: 1px solid rgba(214, 214, 214, 0.58);
      border-radius: 2px;
      width: 24px;
      height: 24px;
    }

    .info-icon {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .receive-updates {
    input {
      background-color: #ffffff;
      border: 1px solid rgba(214, 214, 214, 0.58);
      border-radius: 2px;
      width: 19px;
      height: 19px;
    }
  }

  .section-border {
    p {
      border: 1px solid rgba(214, 214, 214, 0.3);
    }
  }

  .login-section {
    p {
      color: #4f5559;
      font-family: Roboto;
      font-size: 17px;
      font-weight: 400;
    }

    span {
      margin-left: 5px;
      color: #006aa2;
      font-family: Roboto;
      font-size: 17px;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        color: ${darken(0.05, '#006AA2')};
      }
    }
  }
`

export const Title = styled.p`
  margin-bottom: 5px;
  color: #4f5559;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
`

export const Subtitle = styled.p`
  color: #87919a;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`

export const CheckboxLabel = styled.span`
  margin-left: 5px;
  color: #87919a;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
`

export const LanguageContainer = styled.div``

export const InputTitle = styled.span`
  margin-left: 5px;
  color: var(--grey);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
`

export const SignupButton = styled.button`
  background-color: #006eb3;
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 48px;
  margin-top: 18px;

  color: #ffffff;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;

  &:hover {
    opacity: 0.9;
    text-decoration: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const StyledRow = styled(Row)`
  display: flex;
  margin-top: 15px;
`

export const AddressContainer = styled.div`
  margin-top: 26px;
`

export const ErrorMessagePhone = styled.div`
  p {
    color: #ea343e;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 600;
    opacity: 0.9;
    margin-top: 3px;
    padding-right: 75px;
    margin-bottom: 0px;
    text-align-last: start;
  }

  p::before {
    display: ${props => (props.error === undefined ? 'none' : 'inline')};
    content: '⚠ ';
  }
`

export const ErrorMessageContainer = styled.div`
  p {
    color: #ea343e;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 600;
    opacity: 0.9;
    margin-top: 3px;
    margin-bottom: 0px;
    text-align-last: start;
  }

  p::before {
    display: ${props => (props.error === undefined ? 'none' : 'inline')};
    content: '⚠ ';
  }
`

export const ErrorMessage = styled.p`
  color: #ea343e;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 600;
  opacity: 0.9;
  margin-top: 3px;
  margin-bottom: 0px;
  text-align-last: start;

  p::before {
    display: ${props => (props.error === undefined ? 'none' : 'inline')};
    content: '⚠ ';
  }
`

// export const ErrorMessage = styled.div`
//   p {
//     color: #ea343e;
//     font-family: Roboto;
//     font-size: 13px;
//     font-weight: 600;
//     opacity: 0.9;
//     margin-top: 3px;
//     margin-bottom: 0px;
//     text-align-last: start;
//   }

//   p::before {
//     display: ${props => (props.error === undefined ? 'none' : 'inline')};
//     content: '⚠ ';
//   }
// `

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  input {
    background-color: #ffffff;
    border: 1px solid rgba(214, 214, 214, 0.58);
    border-radius: 2px;
    width: 19px;
    height: 19px;
  }
`

export const CustomCheckbox = styled(Checkbox).attrs({
  color: 'default',
})``

export const TermsOfServiceContainer = styled.div`
  display: flex;
  width: 100%;
`

export const TermsOfService = styled.p`
  margin-left: 2px;
  margin-bottom: 0px;
  color: #87919a;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`

export const TermsOfServiceHighlight = styled.span`
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const SponsorLogoRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SponsorLogoColumn = styled(Col).attrs({
  md: 8,
})`
  padding-right: 0px !important;
`

export const InputContainer = styled.div`
  margin-top: 26px !important;
`

export const InputTextField = styled(TextField).attrs(props => ({
  fullWidth: true,
  variant: 'outlined',
  type: props.type ? props.type : 'text',
}))``

export const UploadImageColumn = styled(Col).attrs({
  md: 4,
})`
  padding-left: 0px !important;

  ${customMedia.lessThan('small')`
      display: none;
  `}
`

export const UploadLogoButton = styled(UploadButton).attrs({
  uppercase: true,
  textColor: '#FFF',
  color: '#53C1DC',
  type: 'input',
  width: '100%',
  height: '40px',
})``

export const UploadLogoInput = styled.input.attrs({
  type: 'file',
  accept: 'image/x-png,image/gif,image/jpeg',
})``

export const PhoneCodeColumn = styled(Col).attrs({
  md: 8,
})``

export const PhoneColumn = styled(Col).attrs({
  md: 12,
})``

export const LanguageColumn = styled(Col).attrs({
  md: 6,
})``

export const PhoneCodeContainer = styled.div``

export const PhoneNumberInputContainer = styled.div`
  margin-top: 10px;

  ${customMedia.lessThan('small')`
      margin-top: 24px;
  `}
`

export const BorderContainer = styled.div`
  margin-top: 16px !important;

  p {
    border: 1px solid rgba(214, 214, 214, 0.3);
  }
`

export const Border = styled.p`
  border: 1px solid rgba(214, 214, 214, 0.3);
`

export const LoginSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AccountIndication = styled.p`
  margin: 0px;
  color: #4f5559;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
`
export const Login = styled.span`
  margin-left: 5px;
  color: #2b88fe;
  font-family: Roboto;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: ${darken(0.05, '#2b88fe')};
  }
`
