import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { darken } from 'polished'
import { MdDone } from 'react-icons/md'
import WhiteButton from '~/components/WhiteButton'

export const FormContainer = styled.div`
  .input-date {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    height: 40px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(159, 159, 159, 0.7);
    border-radius: 5px;
    padding-left: 5px;
  }
`

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    max-width: 950px;
    height: auto;
    margin: auto;
    padding: 0;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .modal-content {
    display: ${props => (props.display === 'false' ? 'none' : 'block')};
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;

    .modal-title {
      color: #404257;
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      line-height: 27px;
    }
  }
`

export const InputTitle = styled.span`
  margin-top: 3px;
  color: #404257;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
`

export const BoostProductContainer = styled.div`
  display: flex;

  p {
    margin-left: 15px;
  }
`

export const ProductTitle = styled.span`
  margin-top: 3px;
  color: #fff;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
`

export const Question = styled.span`
  margin-top: 3px;
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 27px;
`

export const AboutSection = styled.div`
  margin: 10px 10px 3px 10px;
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
`

export const Phone = styled.div`
  width: 310px;
  height: 640px;
  border: 2px solid #ccc;
  border-radius: 30px;
  position: relative;
  left: 50%;
  top: 50px;
  margin: -20px -150px;
  background: #fff;
`

export const Screen = styled.div`
  background-color: rgba(0, 0, 0, 0.95);
  width: 290px;
  height: 520px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  left: 50%;
  top: 50%;
  margin: -260px -145px;
  box-sizing: border-box;
  overflow: hidden;
`

export const Home = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0 -10px;
  border-radius: 50%;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .image-container {
    width: 100%;
    height: 320px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`

export const RadioGroupSection = styled.div`
  display: flex;
  flex-direction: column;

  .MuiTypography-body1 {
    font-size: 14px !important;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }
`

export const CardBody = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 85%;
  height: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  padding: 15px;
  display: flex;
  position: relative;

  p {
    margin: 0px;
  }

  .buttons-section {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10px;
    right: -13px;
  }

  .reward-informations {
    .survey-name {
      color: #404257;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 15px;
    }

    .about {
      margin-top: 5px;
      color: #949a9b;
      font-family: Lato;
      font-size: 14px;
      font-weight: 600;
    }
  }
`

export const RewardsAvailableSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 15px 15px;

  span {
    color: #858588;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8dc63f;
    border-radius: 5px;
    width: 78px;
    height: 30px;
    border: none;

    color: #ffffff;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;

    svg {
      margin-right: 5px;
    }

    &:hover {
      background-color: ${darken(0.05, '#8DC63F')};
    }
  }
`

export const Boosted = styled.p`
  padding-top: 5px;
  text-transform: uppercase;
  color: ${props => (props.isBoosted === true ? '#d70e12' : 'rgba(132, 146, 166, 0.38)')};
  font-family: Lato;
  font-size: 12px;
  font-weight: 600;
`

export const Date = styled.p`
  color: #404257;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
`

export const About = styled.p`
  padding-top: 5px;
  color: #949a9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  width: 310px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-right: 10px;
  margin-top: 5px;
`

export const RequestChangeButton = styled(WhiteButton).attrs({
  text: 'Request change',
})``

export const ApproveButton = styled(WhiteButton).attrs({
  text: 'Approve',
})``

export const UpdateButton = styled(WhiteButton).attrs({
  text: 'Update',
  style: { padding: '0px 20px' },
})``

export const RequestButtonContainer = styled.p`
  margin-right: 20px;
`

export const DoneButton = styled(WhiteButton).attrs({})``

export const DoneIcon = styled(MdDone).attrs({
  color: 'var(--success)',
  size: 23,
})``

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  align-items: center;
  padding-bottom: 14px !important;
  margin: 0px !important;
`
