import styled from 'styled-components'

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: white;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  margin-bottom: 18px;
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 19px;
  z-index: 999;
  box-shadow: 0 0 10px 0 #00000030;
`
