import { useState } from 'react'
import { useSelector } from 'react-redux'
import firebase from 'firebase'
import { updateDoc, messageDoc } from '~/helper/chatFunctions'

export default () => {
  const [loading, setLoading] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const { activeChannel } = useSelector(state => state.chat)

  const deleteMessage = async ({ messageId, callback }) => {
    try {
      setLoading(true)

      await updateDoc(messageDoc(activeChannel?.communityId, messageId), {
        deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      setIsDeleted(true)

      callback()
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  return [deleteMessage, { loading, isDeleted }]
}
