import styled from 'styled-components'
import { MdTimeline, MdEdit } from 'react-icons/md'
import { darken } from 'polished'

export const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  align-items: start;
  justify-content: center;
  width: 160px;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
`

export const PopoverIconText = styled.div.attrs({
  className: 'popover-update',
})`
  margin: 0px;
  color: #343f4b;
  font-family: Roboto;
  font-size: 16px;
  margin-left: 8px;
  margin-top: 3px;
`

export const BorderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Border = styled.p`
  border: solid rgba(214, 214, 214, 0.3);
  border-width: 1px;
  margin-bottom: 0;
  width: 150px;
  opacity: 0.6;
`

export const DeleteButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;

  &:hover {
    background-color: ${darken(0.03, '#FFF')};
  }
`

export const EditButtonContainer = styled.div`
  display: flex;
  padding: 10px 0;
  width: 100%;
  flex-grow: 1;

  &:hover {
    background-color: ${darken(0.03, '#FFF')};
  }
`

export const AnalyticsIcon = styled(MdTimeline).attrs({
  size: 22,
  color: '#976DD0',
})``

export const EditIcon = styled(MdEdit).attrs({
  size: 22,
  color: '#477CE6',
})``
