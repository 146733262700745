import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultLogoImage } from '~/util/defaultImages'
import { formatDate } from '~/helper/getFormattedDates'

import * as S from './styled'

const TwitterContentPreview = ({ community, inputWatch, media, videoThumbnail }) => {
  const { t } = useTranslation()

  return (
    <>
      <S.TopPreviewContainer>
        <S.OptionContainer>
          <S.OptionSelected>Tweets</S.OptionSelected>
          <S.Option>Replies</S.Option>
          <S.Option>About</S.Option>
          <S.Option>Media</S.Option>
          <S.Option>Likes</S.Option>
        </S.OptionContainer>
        <S.ActionsContainer>
          <S.Action>
            <S.ChatIcon />
            <S.ActionText>24</S.ActionText>
          </S.Action>
          <S.Action>
            <S.RetweetIcon />
            <S.ActionText>16</S.ActionText>
          </S.Action>
          <S.Action>
            <S.LikeIcon />
            <S.ActionText>881</S.ActionText>
          </S.Action>
          <S.Action>
            <S.ShareIcon />
          </S.Action>
        </S.ActionsContainer>
      </S.TopPreviewContainer>

      <S.MiddlePreviewContainer>
        <S.PostHeader>
          <S.CommunityInformations>
            <S.CommunityLogo src={community?.networkLogo || DefaultLogoImage} />
            <S.CommunityNameAndDate>
              <S.CommunityName>{community?.fullName}</S.CommunityName>
              <S.Date>{formatDate(new Date())}</S.Date>
              <S.ThreeDotsIcon />
            </S.CommunityNameAndDate>
          </S.CommunityInformations>
        </S.PostHeader>

        <S.PostContainer>
          {inputWatch?.description ? (
            <S.PostCommentAndDescription>{`${inputWatch?.title} - ${inputWatch.description}`}</S.PostCommentAndDescription>
          ) : (
            <S.PostComment>{inputWatch?.title}</S.PostComment>
          )}

          {media ? (
            <S.MediaContainer>
              <S.MediaImage src={videoThumbnail || media} />
              {videoThumbnail && (
                <S.PlayIconContainer>
                  <S.PlayIcon />
                </S.PlayIconContainer>
              )}
            </S.MediaContainer>
          ) : (
            <S.MediaContainer>
              <S.NoMediaIndicator>
                <S.ImageIcon />
              </S.NoMediaIndicator>
            </S.MediaContainer>
          )}
        </S.PostContainer>

        <S.ActionsContainer spacing>
          <S.Action>
            <S.ChatIcon />
            <S.ActionText>24</S.ActionText>
          </S.Action>
          <S.Action>
            <S.RetweetIcon />
            <S.ActionText>16</S.ActionText>
          </S.Action>
          <S.Action>
            <S.LikeIcon />
            <S.ActionText>881</S.ActionText>
          </S.Action>
          <S.Action>
            <S.ShareIcon />
          </S.Action>
        </S.ActionsContainer>
      </S.MiddlePreviewContainer>

      <S.BottomPreviewContainer>
        <S.RetweetContainer>
          <S.RetweetIcon />
          <S.RetweetText>Sponsor #community #campaign Retweeted</S.RetweetText>
        </S.RetweetContainer>
        <S.PostHeader>
          <S.CommunityInformations>
            <S.CommunityLogo src={community?.networkLogo || DefaultLogoImage} />
            <S.CommunityNameAndDate>
              <S.CommunityName>{community?.fullName}</S.CommunityName>
              <S.Date>{formatDate(new Date())}</S.Date>
              <S.ThreeDotsIcon />
            </S.CommunityNameAndDate>
          </S.CommunityInformations>
        </S.PostHeader>

        <S.PostContainer>
          <S.PostCommentAndDescription>{t('sponsorsTabTooltipSubtitle')}</S.PostCommentAndDescription>
          <S.MockComment>{t('packagesTabTooltipSubtitle')}</S.MockComment>
        </S.PostContainer>
      </S.BottomPreviewContainer>
    </>
  )
}

export default TwitterContentPreview
