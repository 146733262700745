import styled from 'styled-components'
import { darken } from 'polished'
import Spinner from 'react-bootstrap/Spinner'
import { AiOutlinePlaySquare } from 'react-icons/ai'
import { MdClose } from 'react-icons/md'

export const FormContainer = styled.div`
  .input-date {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    height: 40px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(159, 159, 159, 0.7);
    border-radius: 5px;
    padding-left: 5px;
  }
`

export const CloseIcon = styled(MdClose).attrs({
  size: 18,
  color: '#47525E',
})`
  position: absolute;
  cursor: pointer;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border: 2px solid #47525e;
  border-radius: 50%;
  top: -8px;
  right: -5px;

  &:hover {
    opacity: 0.9;
  }
`

export const InputAddVideo = styled.input.attrs({
  type: 'file',
  accept: 'video/mp4,video/x-m4v,image/jpeg,image/png,image/gif',
  style: { display: 'none' },
})``

export const HelperText = styled.span`
  color: var(--text-primary);
  font-family: Lato;
  font-style: italic;
  font-size: 14px;
  line-height: 15px;
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

export const PreviewMediaContainer = styled(SpinnerContainer)`
  margin-top: 10px;
  position: relative;
`

export const PreviewMedia = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 7px 4px rgba(0, 0, 0, 0.35);
`

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
    color: #fff;
    font-family: Roboto;
    font-size: 11px;
    font-weight: bold;
  }

  .hashtag-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 20px;
    height: 20px;
    background-color: #ff751a;
    border-radius: 100%;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: ${darken(0.1, '#ff751a')};
      text-decoration: none;
    }
  }
`

export const UploadSection = styled.div`
  display: flex;
  margin-top: 4px;
`

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``

export const UploadText = styled.p`
  margin-top: 5px;
  color: var(--text-primary);
  font-family: Lato;
  font-size: 14px;
  line-height: 15px;
  white-space: break-spaces;
`

export const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgba(159, 159, 159, 0.7);
  border-radius: 5px;
  padding: 5px;
  width: 50%;
  height: 113px;
  margin-top: 5px;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    cursor: unset;
    opacity: 0.4;
  }
`

export const VideoUploadIcon = styled(AiOutlinePlaySquare).attrs({
  size: 28,
})``

export const InputTitle = styled.span`
  margin-top: 3px;
  color: #404257;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
`

export const ProductTitle = styled.span`
  margin-top: 3px;
  color: #fff;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
  width: 22.5rem;
  height: 44rem;
  border: 2px solid #ccc;
  border-radius: 30px;
  position: relative;
  background: #fff;
  margin-left: 5rem;
`

export const DeviceScreen = styled.div`
  background-color: #ccc;
  width: 21.2rem;
  height: 37rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 55px;

  .image-container {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`

export const DeviceButton = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0 -10px;
  border-radius: 50%;
`

export const PlayIconContainer = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff0000;
  align-self: center;
  position: absolute;
`

export const PlayIcon = styled.img.attrs({
  src: '/play-icon.png',
})`
  width: 16px;
  height: 16px;
  margin-left: 3px;
`
