import styled from 'styled-components'
import { darken } from 'polished'
import Col from 'react-bootstrap/Col'
import { MdMoreHoriz, MdEdit, MdTimeline } from 'react-icons/md'
import { customMedia } from '~/styles/global'
import WhiteButton from '~/components/WhiteButton'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  display: flex;
  align-items: center;
  border: 1px solid rgba(214, 214, 214, 0.4);
  border-radius: 5px;
  padding: 10px 10px;
  height: 75px;

  svg {
    cursor: pointer;
  }

  ${customMedia.lessThan('1540px')`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  `}
`

export const ProductTitle = styled.p`
  margin: 0px;
  color: #969faa;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.9;
  letter-spacing: 0.5px;
`

export const CommunityAndCampaignNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CampaignName = styled.p`
  margin: 0px;
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.9;
  margin-left: 15px;
  line-height: 18px;
  letter-spacing: 0.5px;
  width: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
`

export const CommunityName = styled.p`
  margin: 0px;
  color: var(--community-primary);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-left: 15px;
  width: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`

export const ProductSubtitle = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  white-space: break-spaces;
  text-align: center;
`

export const Date = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;

  ${customMedia.lessThan('extraLarge')`
    font-size: 13px;
  `}
`

export const CampaignLabel = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const DateLabel = styled.p`
  margin: 0px;
  color: var(--input-title);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
`

export const TotalBudget = styled.p`
  margin: 0px;
  color: var(--success);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  text-align: center;

  ${customMedia.lessThan('extraLarge')`
    font-size: 13px;
  `}
`

export const EditButtonContainer = styled.div`
  display: flex;
  padding: 10px 0;
  width: 100%;
  flex-grow: 1;

  &:hover {
    background-color: ${darken(0.03, '#FFF')};
  }
`

export const DeleteButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;

  &:hover {
    background-color: ${darken(0.03, '#FFF')};
  }
`

export const SponsorLogoContainer = styled.div`
  width: 40px;
  height: 40px;
`

export const SponsorLogoImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
  margin-left: 3px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
`

export const SponsorInformationsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const EditIcon = styled(MdMoreHoriz).attrs({
  size: 24,
  color: 'var(--text-primary)',
})``

export const ProductDotIndicator = styled.div`
  background-color: #e81200;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 15px;
  left: -1px;

  ${customMedia.lessThan('1340px')`
    display: none;
  `}
`

export const CommunityColumn = styled(Col).attrs({
  md: 3,
})``

export const CampaignTypeColumn = styled(Col).attrs({
  md: 2,
})``

export const TotalBudgetColumn = styled(Col).attrs({
  md: 2,
})``

export const CampaignScheduleColumn = styled(Col).attrs({
  md: 2,
})`
  ${customMedia.lessThan('1540px')`
    display: none;
  `}
`

export const ActionColumn = styled(Col).attrs({
  md: 3,
})`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const MoreOptionsColumn = styled(Col).attrs({
  md: 1,
})`
  text-align: center;
  padding-right: 25px !important;
`

export const ManageButton = styled(WhiteButton)`
  margin-right: 10px;

  ${customMedia.lessThan('1350px')`
    min-width: 80px;
    padding: 0px 12px 0px 10px;
    text-align: center;
  `}
`

export const AnalyticsButton = styled(WhiteButton)`
  padding: 0px 13px;

  ${customMedia.lessThan('1350px')`
    min-width: 100px;
    padding: 0px 12px 0px 10px;
    text-align: center;
  `};
`

export const ManageIcon = styled(MdEdit).attrs({
  color: '#0041C2',
  size: 20,
})``

export const AnalyticsIcon = styled(MdTimeline).attrs({
  size: 22,
  color: '#976DD0',
})``
