/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import idx from 'idx'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import { Controller, ErrorMessage } from 'react-hook-form'
import MultiSelect from '~/components/MultiSelect'
import { reactSelectStyleDefault } from '~/helper/const'
import ProductTab from '~/components/Community/ProductsTab/ProductTab'
import PackageTab from '~/components/Community/PackagesTab/PackageTab'
import { store } from '~/store'
import history from '~/services/history'
import CustomSpinner from '~/components/CustomSpinner'
import SponsorCampaignTabCard from '~/components/Sponsor/CampaignsTab/SponsorCampaignTabCard'
import CommunityCampaignTabCard from '~/components/Community/CampaignsTab/CommunityCampaignTabCard'
import CommunityAnalyticsTabCard from '~/components/Community/AnalyticsTab/AnalyticsTabCard'
import SponsorAnalyticsTabCard from '~/components/Sponsor/AnalyticsTab/AnalyticsTabCard'
import PaymentTabCard from '~/components/Sponsor/PaymentsTab/PaymentTabCard'
import useCampaignFunctions from '~/helper/campaignFunctions'
import ZeroRegisterIndicator from '~/components/ZeroRegisterIndicator'
import { returnDataFiltered, returnSponsorPaymentsDataFiltered } from '~/helper/formFunctions'
import { channelsCollection } from '~/helper/chatFunctions'
import { getFirestore } from '~/services/firebase'
import { setUsers } from '~/store/modules/firebaseUsers/actions'
import i18n from '~/i18n'
import api from '../api'
import networkApi from '../networkApi'

const communityId = () => {
  const { id: communityId } = store.getState().community.currentCommunity
  return communityId
}

export const getCommunityAndCommunityAlerts = async (
  communityId,
  setLoading,
  setCurrentCommunityInformations,
  updateUserCurrentCommunityInformationsAction,
  removeS3FromStateAction,
  setCommunityHasStripeAccountAction,
  setCommunityHasStripeAccount,
  setCommunityIsRevised,
  setCommunityIncompleteProfile,
  setUserInformations,
  createSponsorCommunityChannelAction,
) => {
  if (communityId !== undefined) {
    try {
      const { data: response } = await api.get(`/community/${communityId}`)
      const { name: communityName, networkLogoPath: communityLogo, sponsors: sponsorsForTheCommunity } = response
      setCurrentCommunityInformations(response)
      updateUserCurrentCommunityInformationsAction(response)
      removeS3FromStateAction()

      const userProfileInformations = await api.get(`profile`)
      setUserInformations(userProfileInformations.data)

      const { data: responseAlerts } = await api.get(`/community/${communityId}/user-alerts`)

      setCommunityHasStripeAccountAction(responseAlerts.data.hasStripeAccount.status)
      setCommunityHasStripeAccount(responseAlerts.data.hasStripeAccount.status)
      setCommunityIsRevised(responseAlerts.data.revisedStatus.status)
      setCommunityIncompleteProfile(responseAlerts.data.incompleteProfile.status)

      // TODO refactor this (hack to update the firebase channels and keeping them always updated)
      sponsorsForTheCommunity.forEach(sponsor => {
        createSponsorCommunityChannelAction(
          sponsor.companyId,
          communityId,
          communityName,
          sponsor.companyName,
          sponsor.companyLogo,
          communityLogo,
        )
      })

      setLoading(false)
    } catch (error) {
      toast.error(i18n.t('failedToFetchData'))
      setLoading(false)
      throw new Error(error)
    }
  }
}

export const getCurrentCampaignAndReloadThePage = async (
  campaignId,
  setCurrentCampaignForReviewPageAction,
  setIsLoading,
) => {
  const response = await api.get('/sponsor/campaigns-running-or-finalized?limit=999')
  const getCampaigns = response.data
  const campaignForReview = getCampaigns?.filter(campaign => campaign.id === campaignId)
  if (campaignForReview) {
    setCurrentCampaignForReviewPageAction(campaignForReview[0])
    setIsLoading(false)
  }
  setIsLoading(false)
  return response
}

export const getCurrentCampaignAndReloadThePageForTheCommunity = async (
  campaignId,
  setCurrentCampaignForReviewPageAction,
  setIsLoading,
) => {
  const response = await api.get(`/community/${communityId()}/campaigns-running-or-finalized?limit=999`)
  const getCampaigns = response.data
  const campaignForReview = getCampaigns.filter(campaign => campaign.id === campaignId)
  if (campaignForReview) {
    setCurrentCampaignForReviewPageAction(campaignForReview[0])
    setIsLoading(false)
  }
  setIsLoading(false)
  return response
}

export const getProducts = async () => {
  const currentLanguage = i18n.language

  const response = await api.get(
    `/community/${communityId()}/adv-products?limit=999&language=${currentLanguage || 'en'}`,
    { communityId },
  )
  if (response.status !== 200) throw new Error(response.error)
  return response
}

export const ProductTabWithProducts = ({ communityId, refetchAlert }) => {
  const currentLanguage = i18n.language

  const [fetchProductsCallback, setFetchProductsCallback] = React.useState(null)
  const { data, error, isPending } = useAsync({
    promiseFn: getProducts,
    watch: fetchProductsCallback || currentLanguage,
    communityId,
  })

  const setCallbackToGetProducts = () => {
    setFetchProductsCallback(Math.random())
  }

  if (isPending && !data) return <CustomSpinner />
  if (error) return null
  if (data) {
    const products = data.data.data
    const defaultProductsArray = products.filter(product => product.productType !== 'custom')
    const customProductArray = products.filter(product => product.productType === 'custom')
    const productsArray = defaultProductsArray.concat(customProductArray)
    return (
      <ProductTab
        refetchAlert={refetchAlert}
        setCallbackToGetProducts={setCallbackToGetProducts}
        products={productsArray}
      />
    )
  }
  return null
}

export const getPackages = async () => {
  const currentLanguage = i18n.language
  const response = await api.get(`/community/${communityId()}/packages?limit=999&language=${currentLanguage || 'en'}`, {
    communityId,
  })
  if (response.status !== 200) throw new Error(response.error)
  return response
}

export const PackageTabWithPackages = ({ communityId }) => {
  const [fetchPackagesCallback, setFetchPackagesCallback] = React.useState(null)
  const { data, error, isPending } = useAsync({ promiseFn: getPackages, watch: fetchPackagesCallback, communityId })

  const setCallbackToGetPackages = () => {
    setFetchPackagesCallback(Math.random())
  }
  if (isPending) return <CustomSpinner />
  if (error) return null
  if (data) {
    const packages = data.data.data
    return <PackageTab setCallbackToGetPackages={setCallbackToGetPackages} packages={packages} />
  }
  return null
}

export const getCommunityCategories = async () => {
  const response = await api.get('/community-categories')
  if (response.status !== 200) throw new Error(response.error)
  const communityArrayOptions = Object.values(response.data.rows)
  const communityCategoryOptionsFormatted = communityArrayOptions.map(row => {
    return { value: row.id, label: row.title }
  })
  return communityCategoryOptionsFormatted
}

export const getFormatShareableLink = async (formatId, communityId) => {
  if (formatId) {
    const response = await networkApi.get(`/networks/${communityId}/contents/${formatId}/sharing-url`)

    if (response.status !== 200) throw new Error(response.error)

    return response
  }
  return null
}

export const CommunityCategoriesSelect = ({ control, errors }) => {
  const { t } = useTranslation()
  const { data, error } = useAsync({
    promiseFn: getCommunityCategories,
    control,
    errors,
  })
  if (error) return null
  if (data) {
    return (
      <div className="mt-4">
        <Controller
          as={
            <MultiSelect
              styles={reactSelectStyleDefault}
              placeholder={t('whichAreasOfInterests')}
              options={data}
              isMulti
              closeMenuOnSelect={false}
              allowSelectAll
              className="select-categories"
            />
          }
          rules={{ required: t('defaultValidation', { inputName: t('communityCategory') }) }}
          name="categories"
          control={control}
          onChange={([selected]) => {
            return selected
          }}
        />
        {/* <div className="error-message">
          <ErrorMessage errors={errors} name="categories" as="p" />
        </div> */}
      </div>
    )
  }
  return null
}

export const getProductCategories = async () => {
  const { data: response } = await api.get(`/community/${communityId()}/adv-products-categories`)
  if (!response.data) throw new Error(response.error)
  const productCategoriesArray = Object.values(response.data)
  const productCategoriesArrayFormatted = productCategoriesArray.map(product => {
    return { value: product.id, label: product.name }
  })
  return productCategoriesArrayFormatted
}

export const ProductCategoriesSelect = ({ control, errors, defaultValue, disabled }) => {
  const { t } = useTranslation()

  const defaultCategoryValue = {
    value: defaultValue?.id,
    label: defaultValue?.name,
  }
  const { data, error } = useAsync({
    promiseFn: getProductCategories,
    control,
    errors,
  })
  if (error) return null
  if (data) {
    return (
      <>
        <Controller
          as={
            <Select
              styles={reactSelectStyleDefault}
              options={data}
              isDisabled={disabled}
              placeholder={t('selectTheFormatCategory')}
              className="select-format-category"
            />
          }
          rules={{ required: t('defaultValidation', { inputName: t('formatCategorySelect') }) }}
          name="category"
          control={control}
          defaultValue={defaultValue === undefined ? '' : defaultCategoryValue}
          onChange={([selected]) => {
            return selected
          }}
        />
        <div className="error-message">
          <ErrorMessage errors={errors} name="category" as="p" />
        </div>
      </>
    )
  }
  return null
}

export const getSpecialOfferDurations = async () => {
  const { data: response } = await api.get(`/community/${communityId()}/special-offer-durations`)
  if (!response.data) throw new Error(response.error)

  // Temporary solution ultil backend fix the array
  const specialOfferDiscount = [
    { id: 4, slug: 'one_month', title: `1 ${i18n.t('month')}` },
    { id: 1, slug: 'three_months', title: `3 ${i18n.t('months')}` },
    { id: 2, slug: 'six_months', title: `6 ${i18n.t('months')}` },
    { id: 3, slug: 'twelve_months', title: `12 ${i18n.t('months')}` },
  ]

  const specialOfferDurationsArrayFormatted = specialOfferDiscount.map(specialOffer => {
    return { value: specialOffer.id, label: specialOffer.title }
  })
  return specialOfferDurationsArrayFormatted
}

export const SpecialOfferDurationsSelect = ({ defaultValue, control, errors, setSpecialOfferDurationSelect }) => {
  const { t } = useTranslation()
  const defaultSpecialOffer = {
    value: defaultValue?.id,
    label: defaultValue?.title,
  }

  const { data, error } = useAsync({
    promiseFn: getSpecialOfferDurations,
    control,
    errors,
  })

  if (error) return null
  if (data) {
    return (
      <>
        <Controller
          as={
            <Select
              styles={reactSelectStyleDefault}
              placeholder={t('placeholderSelect')}
              className="select-format-special-offer"
            />
          }
          options={data}
          defaultValue={defaultValue === undefined ? '' : defaultSpecialOffer}
          rules={{ required: 'Special offer is required' }}
          name="specialOfferDurationId"
          control={control}
          onChange={([selected]) => {
            setSpecialOfferDurationSelect(selected.value)
            return selected
          }}
        />
        <div className="error-message">
          <ErrorMessage errors={errors} name="specialOfferDurationId" as="p" />
        </div>
      </>
    )
  }
  return null
}

export const getAllSponsorsFromCommunity = async () => {
  const response = await api.get(`/community/${communityId()}/sponsors?limit=999`, { communityId })
  if (response.status !== 200) throw new Error(response.error)
  return response.data
}

export const getAllCommunitiesFromSponsor = async () => {
  const response = await api.get(`/sponsor/communities`)
  if (response.status !== 200) throw new Error(response.error)
  return response.data
}

export const getCommunityKpis = async () => {
  const response = await api.get(`/community/${communityId()}/kpis`, { communityId })
  if (response.status !== 200) throw new Error(response.error)
  return response
}

export const getSponsorAndSponsorAlerts = async (
  companyId,
  setLoading,
  setSponsorInformations,
  updateSponsorInformationsAction,
  createSponsorCommunityChannelAction,
  setAllCommunitiesFromSponsorAction,
  setSponsorIncompleteProfile,
  setUserInformations,
) => {
  if (companyId !== undefined) {
    try {
      const currentLanguage = i18n.language
      const communities = await api.get(`/sponsor-community?limit=999&offset=0&language=${currentLanguage || 'en'}`)
      const communitiesArray = Object.values(communities?.data?.rows)
      setAllCommunitiesFromSponsorAction(communitiesArray)

      const result = await api.get(`/company/${companyId}`)
      setSponsorInformations(result?.data)
      updateSponsorInformationsAction(result?.data)

      const userProfileInformations = await api.get(`profile`)
      setUserInformations(userProfileInformations?.data)

      const { id } = result.data
      const sponsorLogo = result?.data?.logoAdvPlatServerPath
      const sponsorName = result?.data?.name

      // TODO refactor this (hack to update the firebase channels and keeping them always updated)
      communitiesArray.forEach(community => {
        createSponsorCommunityChannelAction(
          id,
          community?.id,
          community?.name,
          sponsorName,
          sponsorLogo,
          community.networkLogoPath,
        )
      })
      const { data: responseAlerts } = await api.get(`/sponsor/user-alerts`)
      setSponsorIncompleteProfile(responseAlerts?.data?.incompleteProfile?.status)
      setLoading(false)
    } catch (error) {
      toast.error(i18n.t('failedToFetchData'))
      setLoading(false)
      throw new Error(error)
    }
  }
}

export const getAllCommunitiesSponsoredByTheSponsor = async () => {
  const currentLanguage = i18n.language
  const response = await api.get(`/sponsor-community?limit=999&offset=0&language=${currentLanguage || 'en'}`)
  if (response.status !== 200) throw new Error(response.error)
  return response
}

export const getCommunityInformations = async ({ communityId }) => {
  const response = await api.get(`/community/${communityId}`, { communityId })
  if (response.status !== 200) throw new Error(response.error)
  return response
}

export const getCampaignsRunningOrFinalizedForTheSponsor = async () => {
  const response = await api.get(`/sponsor/campaigns-running-or-finalized?limit=999`)
  if (response.status !== 200) throw new Error(response.error)
  return response
}

export const getCampaignsRunningOrFinalizedForTheCommunity = async () => {
  const response = await api.get(`/community/${communityId()}/campaigns-running-or-finalized?limit=999`)
  if (response.status !== 200) throw new Error(response.error)
  return response
}

export const getCampaignsWaitingForReviewForTheCommunity = async () => {
  const response = await api.get(`/community/${communityId()}/campaigns-pending-approval?limit=999`)
  if (response.status !== 200) throw new Error(response.error)
  return response
}

export const CampaignsRunningOrFinalized = ({ campaignsRunningCallback, setCampaignsRunningOrFinalized, filter }) => {
  const { data, error } = useAsync({
    promiseFn: getCampaignsRunningOrFinalizedForTheSponsor,
    watch: campaignsRunningCallback,
  })
  if (error) return null
  if (data) {
    const campaignsRunningOrFinalized = data.data
    setCampaignsRunningOrFinalized(campaignsRunningOrFinalized)
    const filteredCampaigns = returnDataFiltered(filter, campaignsRunningOrFinalized)
    return (
      <>
        {filteredCampaigns.map(campaign => (
          <SponsorCampaignTabCard key={campaign.id} campaign={campaign} />
        ))}
      </>
    )
  }
  return null
}

export const CampaignsRunningForSponsorDashbardTab = ({
  campaignsRunningCallback,
  setCampaignsRunningOrFinalized,
  filter,
}) => {
  const { data, error } = useAsync({
    promiseFn: getCampaignsRunningOrFinalizedForTheSponsor,
    watch: campaignsRunningCallback,
  })
  if (error) return null
  if (data) {
    const campaignsRunningOrFinalized = data.data
    setCampaignsRunningOrFinalized(campaignsRunningOrFinalized)
    const filteresCampaigns = returnDataFiltered(filter, campaignsRunningOrFinalized)

    return (
      <>
        {filteresCampaigns.map(campaign => (
          <SponsorAnalyticsTabCard key={campaign.id} campaign={campaign} />
        ))}
      </>
    )
  }
  return null
}

export const getCampaignsWaitingForReviewForTheSponsor = async () => {
  const response = await api.get('/sponsor/campaigns-pending-approval?limit=999')
  if (response.status !== 200) throw new Error(response.error)
  return response
}

export const CampaignsWaitingForReviewTabCards = ({ campaignsWaitingCallback, setCampaignsWaitingReview, filter }) => {
  const { setCampaignsWaitingForApprovalLengthAction } = useCampaignFunctions()
  const { data, error } = useAsync({
    promiseFn: getCampaignsWaitingForReviewForTheSponsor,
    watch: campaignsWaitingCallback,
  })
  if (error) return null
  if (data) {
    const campaignsWaitingForReview = data.data
    setCampaignsWaitingReview(campaignsWaitingForReview)
    setCampaignsWaitingForApprovalLengthAction(campaignsWaitingForReview.length)
    const filteredCampaigns = returnDataFiltered(filter, campaignsWaitingForReview)
    return (
      <>
        {filteredCampaigns.map(campaign => (
          <SponsorCampaignTabCard status="waiting-approval" key={campaign.id} campaign={campaign} />
        ))}
      </>
    )
  }
  return null
}

export const CommunityCampaignsWaitingForReviewTabCards = ({
  campaignsWaitingCallback,
  setCampaignsWaitingReview,
  filter,
}) => {
  const { data, error } = useAsync({
    promiseFn: getCampaignsWaitingForReviewForTheCommunity,
    watch: campaignsWaitingCallback,
  })
  if (error) return null
  if (data) {
    const campaignsWaitingForReview = data.data
    setCampaignsWaitingReview(campaignsWaitingForReview)
    const filteredCampaigns = returnDataFiltered(filter, campaignsWaitingForReview)
    return (
      <>
        {filteredCampaigns.map(campaign => (
          <CommunityCampaignTabCard key={campaign.id} campaign={campaign} />
        ))}
      </>
    )
  }
  return null
}

export const CommunityCampaignsRunningOrFinalized = ({
  campaignsRunningCallback,
  setCampaignsRunningOrFinalized,
  filter,
}) => {
  const { data, error } = useAsync({
    promiseFn: getCampaignsRunningOrFinalizedForTheCommunity,
    watch: campaignsRunningCallback,
  })
  if (error) return null
  if (data) {
    const campaignsRunningOrFinalized = data.data
    setCampaignsRunningOrFinalized(campaignsRunningOrFinalized)
    const filteresCampaigns = returnDataFiltered(filter, campaignsRunningOrFinalized)

    return (
      <>
        {filteresCampaigns.map(campaign => (
          <CommunityCampaignTabCard key={campaign.id} campaign={campaign} />
        ))}
      </>
    )
  }
  return null
}

export const CommunityCampaignsRunningFoAnalyticsTab = ({
  campaignsRunningCallback,
  setCampaignsRunningOrFinalized,
  filter,
}) => {
  const { data, error } = useAsync({
    promiseFn: getCampaignsRunningOrFinalizedForTheCommunity,
    watch: campaignsRunningCallback,
  })
  if (error) return null
  if (data) {
    const campaignsRunningOrFinalized = data.data
    setCampaignsRunningOrFinalized(campaignsRunningOrFinalized)
    const filteresCampaigns = returnDataFiltered(filter, campaignsRunningOrFinalized)

    return (
      <>
        {filteresCampaigns.map(campaign => (
          <CommunityAnalyticsTabCard key={campaign.id} campaign={campaign} />
        ))}
      </>
    )
  }
  return null
}

export const getCampaignAnalytics = async (campaignId, setLoading) => {
  try {
    setLoading(true)
    return await api.get(`/campaign/${campaignId}/analytics`)
  } catch (error) {
    setLoading(false)
    throw new Error(error)
  }
}

export const getCommunityProducts = async setLoading => {
  try {
    setLoading(true)
    const currentLanguage = i18n.language
    return await api.get(`/community/${communityId()}/adv-products?limit=999&language=${currentLanguage || 'en'}`, {
      communityId,
    })
  } catch (error) {
    setLoading(false)
    throw new Error(error)
  }
}

export const getChatChannelAnalytics = async (chatChannelId, setChatChannel, communityId) => {
  getFirestore()
    .collection(channelsCollection(communityId))
    .where('id', '==', chatChannelId)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        setChatChannel(doc.data())
      })
    })
    .catch(error => {
      console.log(error)
    })
}

export const getFormatAnaytics = async (campaignId, formatId, setLoading) => {
  try {
    setLoading(true)
    return await api.get(`/campaign/${campaignId}/analytics/${formatId}`)
  } catch (error) {
    setLoading(false)
    throw new Error(error)
  }
}

export const getSponsorPayments = async () => {
  const response = await api.get('/sponsor/payments')
  if (response.status !== 200) throw new Error(response.error)
  return response
}

export const PaymentTabCardsRequest = ({ filter }) => {
  const { data, error } = useAsync({ promiseFn: getSponsorPayments })
  if (error) return null
  if (data) {
    const sponsorPayments = data.data
    const filteredSponsorPayments = returnSponsorPaymentsDataFiltered(filter, sponsorPayments)
    return (
      <>
        {filteredSponsorPayments?.length === 0 && <ZeroRegisterIndicator text={i18n.t('noPaymentsHasBeenMade')} />}
        {filteredSponsorPayments.map(payment => (
          <PaymentTabCard key={payment.id} payment={payment} />
        ))}
      </>
    )
  }
  return null
}

export const getLoggedUserProfileInformations = async setLoading => {
  try {
    setLoading(true)
    return await api.get(`profile`)
  } catch (error) {
    setLoading(false)
    throw new Error(error)
  }
}

export const updateUserProfile = async (data, closeModal) => {
  try {
    await api.put('/profile', data)
    toast.success(i18n.t('userProfileUpdated'))
    closeModal()
    setTimeout(() => {
      history.push('/')
    }, 2000)
  } catch (error) {
    toast.error(i18n.t('userProfileFailedToUpdate'))
  }
}

export const uploadMedia = async (media, setLoading, setMedia) => {
  const { token } = store.getState().auth

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  try {
    setLoading(true)

    const formData = new FormData()

    formData.append('file', media)

    networkApi.defaults.headers.Authorization = `Bearer ${token}`

    const response = await networkApi.post('/uploads', formData, config)
    setMedia(response?.data?.data[0])
  } catch (e) {
    toast.error(i18n.t('errorUpdatingImage'))
  }
  setLoading(false)
}

export const signUpCommunityLead = async (data, handleCloseModal) => {
  try {
    const response = await api.post('community/lead', data)
    if (response?.status === 200) {
      handleCloseModal()
      toast.success(i18n.t('signInCommunityLeadResponse'), { autoClose: 15000 })
      setTimeout(() => {
        history.push('/')
      }, 2000)
    }
  } catch (error) {
    if (error.response.data.error === 'CommunityAlreadyExists') {
      toast.error(i18n.t('communityNameAlreadyInUse'))
    } else {
      toast.error(i18n.t('unknownError'))
    }
  }
}

export const getCampaignIdFromEmailRedirectionAndGoToCampaignReviewPage = async (
  campaignId,
  setCurrentCampaignForReviewPageAction,
  setShowCampaignReviewPageAction,
  entity,
) => {
  let response
  if (entity === 'community') {
    response = await api.get(`/community/${communityId()}/campaigns-running-or-finalized?limit=999`)
    history.replace('/community')
  } else {
    response = await api.get(`/sponsor/campaigns-running-or-finalized?limit=999`)
  }
  const getCampaigns = response.data
  const campaignForReview = getCampaigns.filter(campaign => campaign.id === parseInt(campaignId, 10))
  if (campaignForReview.length > 0) {
    setCurrentCampaignForReviewPageAction(campaignForReview[0])
    setShowCampaignReviewPageAction(true)
  }
  return response
}

export const getFollowUpAnalyticsKpi = async (period, entityId, entity) =>
  await api.get(`${entity}/${entityId}/kpis/follow-up?period=${period.value}`)

export const getPopularFormatsAnalyticsKpi = async (period, entityId, entity) =>
  await api.get(`${entity}/${entityId}/kpis/popular-formats?period=${period.value}`)

export const getCommunityRankingAnalyticsKpi = async (period, entityId, entity) =>
  await api.get(`${entity}/${entityId}/kpis/sponsor-investment?period=${period.value}`)

export const getSponsorRankingAnalyticsKpi = async (period, entityId, entity) =>
  await api.get(`${entity}/${entityId}/kpis/community-investment?period=${period.value}`)

export const getMostViewedFormatsAnalyticsKpi = async (period, entityId, entity) =>
  await api.get(`${entity}/${entityId}/kpis/most-viewed?period=${period.value}`)

export const getCampaignsFormatsPerMonth = async (month, campaignId, year) => {
  const response = await api.get(`/campaign-products-month?month=${month}&campaignId=${campaignId}&year=${year}`)
  if (response.status !== 200) throw new Error(response.error)
  return response.data
}

export const getFirebaseUsers = () => {
  const { loggedUserId } = store.getState().auth
  const entityType = store.getState().user.type

  const communityIdFromSponsorChat = store.getState().chat?.activeChannel?.communityId
  const loggedCommunityId = store.getState().community?.currentCommunity?.id

  let communityId

  if (entityType === 'sponsor') {
    communityId = communityIdFromSponsorChat
  } else {
    communityId = loggedCommunityId
  }

  const getNetworkRole = (memberships = []) => {
    const index = memberships?.findIndex(x => x.network_id === communityId)
    return index > -1 ? idx(memberships, _ => _[index]?.role) : undefined
  }

  const firestore = getFirestore()

  const reference = firestore.collection(`/networks/${communityId}/users`).onSnapshot(snap => {
    const users = []

    snap.forEach(doc => {
      const data = doc.data()
      if (!!data && !!loggedUserId && data.user_id !== loggedUserId) {
        users.push({
          id: data.user_id,
          name: idx(data, _ => _.full_name) || idx(data, _ => _.first_name),
          email: idx(data, _ => _.email),
          image: idx(data, _ => _.user_image.url),
          createdAt: idx(data, _ => _.createdAt),
          updatedAt: idx(data, _ => _.updatedAt),
          lastLogin: idx(data, _ => _.last_login_at),
          networkRole: getNetworkRole(idx(data, _ => _.memberships)),
        })
      }
    })
    store.dispatch(setUsers(users))
  })

  return () => reference()
}

export const uploadMediaToS3 = async (media, setLoading, setMedia) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  try {
    setLoading(true)

    const { token } = store.getState().auth

    const formData = new FormData()

    formData.append('file', media)

    networkApi.defaults.headers.Authorization = `Bearer ${token}`

    const response = await networkApi.post('/uploads', formData, config)
    const { url } = response?.data.data[0]
    setMedia(url)
  } catch (e) {
    console.log(e)
  }
  setLoading(false)
}

export const uploadMediaToS3WithId = async (media, setLoading, setMedia, setMediaId) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  try {
    setLoading(true)

    const { token } = store.getState().auth

    const formData = new FormData()

    formData.append('file', media)

    networkApi.defaults.headers.Authorization = `Bearer ${token}`

    const response = await networkApi.post('/uploads', formData, config)
    const { url, id } = response?.data.data[0]

    setMedia(url)
    setMediaId(id)
  } catch (e) {
    console.log(e)
  }
  setLoading(false)
}

export const getBlockedDatesByCampaign = async ({ formats, duration, date }) => {
  const response = await api.post('/formats/usage', {
    formats,
    date,
    duration,
  })

  return response
}
