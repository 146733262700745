import styled from 'styled-components'
import { MdShare, MdMoreHoriz } from 'react-icons/md'
import { IoIosImages } from 'react-icons/io'

export const Container = styled.div`
  margin: 0 auto;
  margin-top: 0.5rem;
  width: 93%;
  height: 17rem;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`

export const ContentImage = styled.img.attrs({
  className: 'animated fadeIn',
})`
  width: 100%;
  height: 8rem;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
`

export const NoContentImageAvailable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8rem;
  background-color: #d9d9d9;
  border-radius: 10px 10px 0px 0px;
`

export const NoContentImageIcon = styled(IoIosImages).attrs({
  color: 'red',
  size: 24,
})``

export const ContentInformationsContainer = styled.div`
  margin-left: 1rem;
`

export const ContentTitle = styled.p`
  margin-bottom: 0;
  margin-top: 0.2rem;
  font-family: Roboto;
  font-weight: 900;
  font-size: 17px;
  letter-spacing: 0.005em;
  width: 18rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

export const ContentDescription = styled.p`
  margin-bottom: 0;
  font-family: Roboto;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.005em;
  width: 18rem;
  color: #3d3d3d;
  height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

export const ContentFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
  margin-top: 0.5rem;
`

export const UserInformationsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const UserAvatar = styled.img.attrs({
  src: 'community-user-avatar2.jpg',
})`
  width: 25px;
  height: 25px;
  border-radius: 100px;
`

export const UserName = styled.span`
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0.005em;
  color: #777777;
  margin-left: 0.5rem;
`

export const UserOptionsInformationsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`

export const ShareIcon = styled(MdShare).attrs({
  color: '#B7B7B7',
  size: 20,
})`
  margin-right: 0.5rem;
`

export const MoreOptionsIcon = styled(MdMoreHoriz).attrs({
  color: '#B7B7B7',
  size: 20,
})`
  margin-left: 0.5rem;
`
