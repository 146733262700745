import React from 'react'
import { useTranslation } from 'react-i18next'
import useTutorialFunctions from '~/helper/tutorialFunctions'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import * as S from './styled'

export default function CreateCampaignModalTooltipStripe({
  closeProps,
  tooltipProps,
  closeCreditCardInformationTutorialMockedModal,
}) {
  const { t } = useTranslation()
  const { setTutorialAsInativeAction, closeCreateCampaignForTutorialAction } = useTutorialFunctions()
  const { setShowCampaignReviewPageMockedForTheTutorialAction } = useSponsorFunctions()

  const closeCreateCampaignModal = () => {
    closeCreateCampaignForTutorialAction()
    setTutorialAsInativeAction()
  }

  const goToNextStepAndShowTheCampaignReviewPageMocked = () => {
    closeCreditCardInformationTutorialMockedModal()
    closeCreateCampaignForTutorialAction()
    setShowCampaignReviewPageMockedForTheTutorialAction(true)
  }

  return (
    <S.ComponentContainer {...tooltipProps}>
      <S.TooltipArrow />
      <S.TooltipUserAvatar />
      <S.TooltipContainer>
        <S.CloseButtonContainer>
          <S.CloseIcon onClick={() => closeCreateCampaignModal()} />
        </S.CloseButtonContainer>
        <S.Title>{t('howToPayForSponsorship')}</S.Title>
        <S.Subtitle>{t('veryEasyCreditCardPaymentSponsorship')}</S.Subtitle>
        <S.Subtitle>{t('creditCardPaymentInstructionsSponsorship')}</S.Subtitle>
        <S.ButtonsContainer>
          <S.BackButton onClick={() => closeCreditCardInformationTutorialMockedModal()}>
            <S.BackButtonText>{t('back')}</S.BackButtonText>
          </S.BackButton>
          <S.NextButton {...closeProps}>
            <S.NextButton onClick={() => goToNextStepAndShowTheCampaignReviewPageMocked()}>
              <S.NextButtonText>{t('next')}</S.NextButtonText>
            </S.NextButton>
          </S.NextButton>
        </S.ButtonsContainer>
      </S.TooltipContainer>
    </S.ComponentContainer>
  )
}
