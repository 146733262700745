import React, { useState, useCallback, useMemo, memo } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { Controller, ErrorMessage } from 'react-hook-form'
import { reactSelectStyleDefault } from '~/helper/const'
import SelectRangeByDayPicker from '../SelectRangeByDayPicker'
import SelectPackageSection from './SelectPackageSection'
import SelectedPackageSection from './SelectedPackageSection'
import { PaymentMethod } from '../PaymentMethod/PaymentMethod'
import * as S from './styled'

const ControlledSelectRangeByDayPicker = memo(function ControlledSelectRangeByDayPicker({
  campaignDuration,
  blockedDates,
  control,
  disabled,
  onBlockedDayReport,
}) {
  const { t } = useTranslation()

  return (
    <Controller
      as={({ value, onChange }) => {
        return (
          <SelectRangeByDayPicker
            mode="single"
            selected={value}
            onChange={onChange}
            rangeQtd={campaignDuration}
            blockedDates={blockedDates}
            disabled={disabled}
            onBlockedDayReport={onBlockedDayReport}
          />
        )
      }}
      rules={{ required: t('defaultValidation', { inputName: t('startDate') }) }}
      name="dtStart"
      control={control}
      shouldUnregister
      disabled={disabled}
      onChange={([date]) => date}
    />
  )
})

export default function SelectPackageForm({
  packages,
  control,
  setValue,
  errors,
  blockedDates,
  setBlockedDates,
  setSelectedPackage,
  setCampaignDuration,
  selectedPackage,
  paymentOptionState,
  campaignDuration,
  communityCurrencySymbol,
  onBlockedDayReport,
}) {
  const { t } = useTranslation()

  const [checkedPackageIndex, setCheckedPackageIndex] = useState()
  const [durationOfTheSpecialOfferPackage, setDurationOfTheSpecialOfferPackage] = useState(null)

  const campaignDurationOptions = useMemo(
    () => [
      { value: 1, label: `1 ${t('month')} / 30 ${t('days')}` },
      { value: 2, label: `2 ${t('months')} / 60 ${t('days')}` },
      { value: 3, label: `3 ${t('months')} / 90 ${t('days')}` },
      { value: 4, label: `4 ${t('months')} / 120 ${t('days')}` },
      { value: 5, label: `5 ${t('months')} / 150 ${t('days')}` },
      { value: 6, label: `6 ${t('months')} / 180 ${t('days')}` },
      { value: 7, label: `7 ${t('months')} / 210 ${t('days')}` },
      { value: 8, label: `8 ${t('months')} / 240 ${t('days')}` },
      { value: 9, label: `9 ${t('months')} / 270 ${t('days')}` },
      { value: 10, label: `10 ${t('months')} / 300 ${t('days')}` },
      { value: 11, label: `11 ${t('months')} / 330 ${t('days')}` },
      { value: 12, label: `12 ${t('months')} / 360 ${t('days')}` },
    ],
    [t],
  )

  const handleCheckPackage = useCallback(
    (packageInfo, index) => {
      setCheckedPackageIndex(index)
      setSelectedPackage(packageInfo)

      const { qtdMonth: SpecialOfferRequiredDuration } = packageInfo.specialOfferDuration || {}

      if (SpecialOfferRequiredDuration) {
        const foundSpecialOfferDuration = campaignDurationOptions.find(month => {
          return month.value === SpecialOfferRequiredDuration
        })

        setValue('campaignDuration', foundSpecialOfferDuration)

        setDurationOfTheSpecialOfferPackage(foundSpecialOfferDuration)

        return setCampaignDuration(foundSpecialOfferDuration?.value)
      }
    },
    [campaignDurationOptions, setCampaignDuration, setSelectedPackage, setValue],
  )

  const handleUncheckPackage = useCallback(() => {
    setCheckedPackageIndex()
    setSelectedPackage('')
    setCampaignDuration(null)
    setDurationOfTheSpecialOfferPackage(null)
    setBlockedDates([])

    setValue([{ campaignDuration: '' }, { dtStart: '' }, { campaignPackage: '' }])
  }, [setSelectedPackage, setValue, setBlockedDates, setCampaignDuration])

  const hasCheckedPackage = checkedPackageIndex >= 0

  return (
    <>
      <S.PackageWrapper
        style={{
          gridTemplateColumns: hasCheckedPackage ? '1fr' : 'repeat(2, 1fr)',
        }}
      >
        {hasCheckedPackage ? (
          <Controller
            as={
              <SelectedPackageSection
                checkedPackage
                packageInformations={packages[checkedPackageIndex]}
                setSelectedPackage={setSelectedPackage}
                communityCurrencySymbol={communityCurrencySymbol}
              />
            }
            rules={{ required: t('defaultValidation', { inputName: t('campaignPackage') }) }}
            name="campaignPackage"
            defaultValue={packages[checkedPackageIndex]}
            control={control}
            onChange={() => {
              handleUncheckPackage()
              return null
            }}
          />
        ) : (
          packages?.map((packageInfo, index) => (
            <Controller
              key={packageInfo.id}
              as={
                <SelectPackageSection
                  checkedPackage={checkedPackageIndex === index}
                  packageInformations={packageInfo}
                  setSelectedPackage={setSelectedPackage}
                  communityCurrencySymbol={communityCurrencySymbol}
                />
              }
              rules={{ required: t('defaultValidation', { inputName: t('campaignPackage') }) }}
              name="campaignPackage"
              control={control}
              onChange={() => {
                handleCheckPackage(packageInfo, index)
                return packageInfo
              }}
            />
          ))
        )}

        <S.ErrorMessageContainer>
          <ErrorMessage errors={errors} name="campaignPackage" as="p" />
        </S.ErrorMessageContainer>
      </S.PackageWrapper>

      <S.FormSectionContainer>
        <S.InputTitle>{t('campaignDuration')}</S.InputTitle>

        <S.SelectContainer>
          <Controller
            as={({ onChange }) => {
              return (
                <Select
                  styles={reactSelectStyleDefault}
                  placeholder={t('placeholderSelectMonths')}
                  options={campaignDurationOptions}
                  value={durationOfTheSpecialOfferPackage}
                  isDisabled={selectedPackage.hasSpecialOffer === 1}
                  onChange={onChange}
                />
              )
            }}
            name="campaignDuration"
            control={control}
            isDisabled={selectedPackage.hasSpecialOffer === 1}
            rules={{ required: t('defaultValidation', { inputName: t('campaignDuration') }) }}
            onChange={([selected]) => {
              setCampaignDuration(selected.value)
              setDurationOfTheSpecialOfferPackage(selected)
              return selected
            }}
          />

          <S.ErrorMessageContainer>
            <ErrorMessage errors={errors} name="campaignDuration" as="p" />
          </S.ErrorMessageContainer>

          {selectedPackage?.specialOfferDuration?.qtdMonth === campaignDuration && (
            <S.DiscountLabelContainer>
              <S.DiscountLabel>
                {`${Number(selectedPackage?.specialOfferDiscount).toFixed(0)}% ${t('discountOff')}`}
              </S.DiscountLabel>
            </S.DiscountLabelContainer>
          )}
        </S.SelectContainer>
      </S.FormSectionContainer>

      <S.FormSectionContainer>
        <S.InputTitle>{t('paymentMethod')}</S.InputTitle>

        <PaymentMethod paymentOptionState={paymentOptionState} />
      </S.FormSectionContainer>

      <S.FormSectionContainer>
        <S.InputTitle className={!campaignDuration || !hasCheckedPackage ? 'disabled' : undefined}>
          {t('campaignStaringDate')}
        </S.InputTitle>

        <S.SelectContainer>
          <ControlledSelectRangeByDayPicker
            campaignDuration={campaignDuration}
            blockedDates={blockedDates}
            control={control}
            disabled={!campaignDuration || !hasCheckedPackage}
            onBlockedDayReport={onBlockedDayReport}
          />

          <S.ErrorMessageContainer>
            <ErrorMessage errors={errors} name="dtStart" as="p" />
          </S.ErrorMessageContainer>
        </S.SelectContainer>
      </S.FormSectionContainer>
    </>
  )
}
