/* eslint-disable camelcase */
import { takeLatest, all, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '~/services/api'
import networkApi from '~/services/networkApi'
import { store } from '~/store'
import history from '~/services/history'
import i18n from '~/i18n'
import { createSponsorCommunityChannel as createSponsorCommunityChannelAction } from '~/store/modules/chat/actions'
import {
  GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST,
  ADD_NEW_COMMUNITY_TO_SPONSOR,
  UPDATE_SPONSOR_PROFILE_REQUEST,
  UPLOAD_S3_REQUEST,
} from './actionTypes'
import {
  getCommunityInformationsByInvitationLinkError,
  getCommunityInformationsByInvitationLinkSuccess,
  updateSponsorInformationsFailure,
  updateSponsorInformationsLoading,
  updateSponsorInformationsSuccess,
  uploadS3Success,
} from './actions'

export function* getCommunityInformationsRequest({ payload }) {
  try {
    const { code } = payload
    const response = yield call(api.get, `/community/invitation/${code}`, {
      code,
    })
    const communityInformations = response.data[0]
    yield put(getCommunityInformationsByInvitationLinkSuccess(communityInformations))
  } catch (err) {
    toast.error(i18n.t('messages.invitationLinkInvalid'))
    yield put(getCommunityInformationsByInvitationLinkError())
  }
}

export function* updateSponsorProfileRequest({ payload }) {
  const { id, data } = payload

  try {
    yield put(updateSponsorInformationsLoading())
    yield call(api.put, `/company/${id}`, data)
    setTimeout(() => {
      history.go(0)
    }, 2000)
    toast.success(i18n.t('messages.companyProfileSuccessfullyUpdated'))
    yield put(updateSponsorInformationsSuccess())
  } catch (err) {
    yield put(updateSponsorInformationsFailure())
    toast.error(i18n.t('errorUpdatingCompanyProfile'))
  }
}

export function* addNewCommunityToSponsor({ payload }) {
  try {
    const { code, refreshRoute } = payload
    const response = yield call(api.post, `/sponsor-community`, {
      code,
    })
    toast.success(i18n.t('messages.communitySuccessfullyAdded'))

    const { id: communityId, name: communityName, logoAdvPlatServerPath: communityLogo } = response.data
    const { company } = store.getState().sponsor
    yield put(
      createSponsorCommunityChannelAction(
        company.id,
        communityId,
        communityName,
        company.name,
        company.logoAdvPlatServerPath,
        communityLogo,
      ),
    )
    if (refreshRoute === true) {
      setTimeout(() => {
        history.push('/sponsor')
      }, 2000)
    } else {
      setTimeout(() => {
        history.go(0)
      }, 2000)
    }
  } catch (err) {
    if (err.response.data.error === 'CommunityAlreadyAdded') {
      toast.error(i18n.t('communityAlreadyAdded'))
    } else {
      toast.error(i18n.t('messages.invitationLinkInvalid'))
    }
  }
}

export function* uploadS3Request({ payload }) {
  const { file } = payload

  const { token } = store.getState().auth

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  try {
    const formData = new FormData()

    formData.append('file', file)

    networkApi.defaults.headers.Authorization = `Bearer ${token}`

    const response = yield call(networkApi.post, `/uploads`, formData, config)

    const { id, url } = response?.data?.data[0]

    yield put(uploadS3Success(id, url))
  } catch (err) {
    toast.error(i18n.t('errorUpdatingImage'))
  }
}

export default all([
  takeLatest(GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST, getCommunityInformationsRequest),
  takeLatest(ADD_NEW_COMMUNITY_TO_SPONSOR, addNewCommunityToSponsor),
  takeLatest(UPDATE_SPONSOR_PROFILE_REQUEST, updateSponsorProfileRequest),
  takeLatest(UPLOAD_S3_REQUEST, uploadS3Request),
])
