/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Container from 'react-bootstrap/Container'
import TextField from '@material-ui/core/TextField'
import { InputAdornment } from '@material-ui/core'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify'
import { MdContentCopy } from 'react-icons/md'
import { customNoiticeModalBodyStyle } from '~/helper/const'
import useCommunityFunctions from '~/helper/communityFunctions'
import useCommunitySelectors from '~/helper/communitySelectors'
import * as S from './styled'

export default function ModalInviteSponsor({ handleCloseModal, showModal, communityIsRevised }) {
  const { t } = useTranslation()
  const token = useSelector(state => state.auth.token)
  const invitationLink = useSelector(state => state.community.currentCommunityInvitationLink)
  const loadingInvitationLink = useSelector(state => state.community.loadingInvitationLink)
  const { register } = useForm()
  const invitationLinkUrl = `${process.env.REACT_APP_BASE_URL}/invite-sponsor/${invitationLink}`
  const { getCommunityLinkInvitationRequestAction } = useCommunityFunctions()
  const { userCurrentCommunity } = useCommunitySelectors()

  function generateNewInvitationLink() {
    getCommunityLinkInvitationRequestAction(userCurrentCommunity().id, token)
  }

  function copyLinkAndCloseModal() {
    if (invitationLink) {
      toast.success(t('linkCopied'))
      navigator.clipboard.writeText(invitationLinkUrl)
      handleCloseModal()
    } else {
      toast.error('You must generate a new invitation link first.')
    }
  }

  useEffect(() => {
    if (communityIsRevised === true && showModal) {
      getCommunityLinkInvitationRequestAction(userCurrentCommunity().id, token)
    }
  }, [communityIsRevised])

  useEffect(() => {
    if (showModal) {
      generateNewInvitationLink()
    }
  }, [showModal])

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.Title>{`${t('sponsorInvitation')}:`}</S.Title>
        </Modal.Title>
      </Modal.Header>
      <S.Container>
        <S.BannerImage />
        <S.InvitationTextContainer>
          <S.InvitationText>{t('sponsorInvitation')}</S.InvitationText>
        </S.InvitationTextContainer>
      </S.Container>
      <Modal.Body style={customNoiticeModalBodyStyle}>
        <Container fluid>
          <S.Title>{t('inviteSponsorCreateCampaign')}</S.Title>
          <S.Subtitle>{t('youJustNeedToShareYourCommunity')}</S.Subtitle>
          <S.GeneratingLinkContainer>
            <Col>
              <S.InputContainer>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="invitationLink"
                  placeholder={t('pressToGenerateNewInvitationCode')}
                  value={invitationLink === null ? '' : invitationLinkUrl}
                  inputRef={register}
                  InputProps={{
                    style: {
                      color: 'var(--text-bold)',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        {loadingInvitationLink ? <Spinner animation="border" size="sm" /> : ''}
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputContainer>
            </Col>
          </S.GeneratingLinkContainer>
          <Row>
            <Col>
              <S.ButtonSection>
                <S.CopyLinkSection>
                  <S.CopyButton onClick={copyLinkAndCloseModal}>
                    <MdContentCopy color="#FFF" size={22} />
                    <S.ButtonText>{t('copyLink')}</S.ButtonText>
                  </S.CopyButton>
                </S.CopyLinkSection>
              </S.ButtonSection>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="mb-1">
        <S.ModalFooter>
          <S.DoneButton text={t('done')} onClick={() => handleCloseModal()} icon={<S.DoneIcon />} />
        </S.ModalFooter>
      </Modal.Footer>
    </S.ModalCustom>
  )
}
