import React from 'react'
import { useTranslation } from 'react-i18next'
import useTutorialFunctions from '~/helper/tutorialFunctions'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import * as S from './styled'

export default function CampaignReviewPageTooltip({ closeProps, tooltipProps }) {
  const { t } = useTranslation()
  const { setShowCampaignReviewPageMockedForTheTutorialAction } = useSponsorFunctions()
  const {
    setTutorialAsInativeAction,
    closeCreateCampaignForTutorialAction,
    neverShowTheTutorialAgainForTheSponsorAction,
  } = useTutorialFunctions()

  const setCampaignReviewMockedPageToFalse = () => {
    setShowCampaignReviewPageMockedForTheTutorialAction(false)
  }

  const closeTooltipAndFinishTheTutorial = () => {
    neverShowTheTutorialAgainForTheSponsorAction()
    closeCreateCampaignForTutorialAction()
    setTutorialAsInativeAction()
    setShowCampaignReviewPageMockedForTheTutorialAction(false)
  }

  return (
    <S.ComponentContainer {...tooltipProps}>
      <S.TooltipArrow />
      <S.TooltipUserAvatar />
      <S.TooltipContainer>
        <S.CloseButtonContainer>
          <S.CloseIcon onClick={() => closeTooltipAndFinishTheTutorial()} />
        </S.CloseButtonContainer>
        <S.Title>{t('sponsorStep4TutorialTitle')}</S.Title>
        <S.Subtitle>{t('sponsorsCampaignReviewPageTooltipFirstParagraph')}</S.Subtitle>
        <S.Subtitle spacing>{t('sponsorsCampaignReviewPageTooltipSecondParagraph')}</S.Subtitle>
        <S.ButtonsContainer>
          <S.BackButton onClick={() => setCampaignReviewMockedPageToFalse()}>
            <S.BackButtonText>{t('back')}</S.BackButtonText>
          </S.BackButton>
          <S.NextButton {...closeProps}>
            <S.NextButton onClick={() => closeTooltipAndFinishTheTutorial()}>
              <S.NextButtonText>{t('done')}</S.NextButtonText>
            </S.NextButton>
          </S.NextButton>
        </S.ButtonsContainer>
      </S.TooltipContainer>
    </S.ComponentContainer>
  )
}
