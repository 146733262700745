import styled, { css } from 'styled-components'
import { breakpoints } from '~/helper/breakpoints'

export const Container = styled.section`
  margin-top: 8rem;
  width: 100vw;
  padding: 0rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 6.75rem;

  @media ${breakpoints.mobile} {
    margin-top: 0rem;
    margin-bottom: 4rem;
  }
`

export const FormContainer = styled.div`
  max-width: 96rem;
  padding: 3.5rem 4rem;

  padding: 3.5rem 4rem;
  width: 100%;

  border-radius: 20px;

  border: 1px rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(25px);

  background: rgb(255, 255, 255);
  background: linear-gradient(
    15deg,
    rgba(255, 255, 255, 1) 71%,
    rgba(254, 255, 255, 1) 77%,
    rgba(243, 250, 255, 1) 85%,
    rgba(229, 245, 255, 1) 94%
  );

  @media ${breakpoints.mobile} {
    background: white;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ${breakpoints.mobile} {
    padding: 1.5rem 1.25rem;
    width: 90vw;
  }
`

export const Header = styled.header`
  text-align: center;
  margin-bottom: 3.5rem;

  h2 {
    color: #2d2d32;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 116%;
  }

  p {
    color: #7e7f86;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: 1rem;

    h2 {
      color: #2d2d32;
      text-align: center;
      font-family: Catamaran;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 116%;
      margin-bottom: 1rem !important;
    }

    p {
      color: #7e7f86;
      text-align: center;
      font-family: Catamaran;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
    }
  }
`

export const FormContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 7.5rem;

  @media ${breakpoints.tablet} {
    gap: 2.5rem;
    display: flex;
    flex-direction: column;
  }
`

export const Form = styled.form`
  max-width: 44.25rem;

  @media ${breakpoints.mobile} {
    width: auto;
  }
`

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  .text-area {
    grid-column: span 2;
  }

  @media ${breakpoints.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: 1rem;
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  p {
    color: #ea343e;
    font-size: 14px;
    font-weight: 600;
    opacity: 0.9;
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(4px);
  }

  @media ${breakpoints.mobile} {
    width: 100%;
  }
`

export const SendContainer = styled.div`
  display: flex;
  max-width: 44.25rem;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;

  button {
    display: flex;
    flex-grow: 1;
    height: 3rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 20px;
    background: #01a2ff;
    min-width: 12rem;
  }

  p {
    color: #7e7f86;
    font-family: Catamaran;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  a {
    color: #01a2ff;
    font-family: Catamaran;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }

  @media ${breakpoints.mobile} {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;

    button {
      width: 100%;
    }
  }
`

export const CallbackSubmit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2rem;

  width: 0;
  height: 0;

  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 3;
  backdrop-filter: blur(1rem);
  overflow: hidden;
  opacity: 0;

  transform: translateX(-50%);

  transition: all 0.3s ease-in-out;

  ${({ show }) =>
    show &&
    css`
      width: 100%;
      height: 100%;

      opacity: 1;
    `}

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border-radius: 1.6rem;

    height: 30rem;

    p {
      margin-top: 1.6rem;
    }

    svg {
      width: 10rem;
      height: 10rem;
    }
  }
`

export const DesktopImage = styled.div`
  img {
    width: 100%;
    max-width: 381px;
  }

  @media ${breakpoints.tablet} {
    display: none;
  }
`

export const MobileImage = styled.div`
  img {
    width: 100%;
    max-width: 241px;
  }

  display: none;

  @media ${breakpoints.tablet} {
    display: flex;
    justify-content: center;
  }
`
