import * as actions from './actionTypes'

export function getCommunityInformationsByInvitationLinkRequest(code) {
  return {
    type: actions.GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST,
    payload: { code },
  }
}

export function getCommunityInformationsByInvitationLinkSuccess(communityInformations) {
  return {
    type: actions.GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_SUCCESS,
    payload: { communityInformations },
  }
}

export function getCommunityInformationsByInvitationLinkError() {
  return {
    type: actions.GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_ERROR,
  }
}

export function openCreateCampaignForTutorial() {
  return {
    type: actions.OPEN_CREATE_CAMPAIGN_FOR_TUTORIAL,
  }
}

export function closeCreateCampaignForTutorial() {
  return {
    type: actions.CLOSE_CREATE_CAMPAIGN_FOR_TUTORIAL,
  }
}

export function updateSponsorInformations(sponsor) {
  return {
    type: actions.UPDATE_SPONSOR_INFORMATIONS,
    payload: sponsor,
  }
}

export function updateSponsorInformationsSuccess() {
  return {
    type: actions.UPDATE_SPONSOR_INFORMATIONS_SUCCESS,
  }
}

export function updateSponsorInformationsLoading() {
  return {
    type: actions.UPDATE_SPONSOR_INFORMATIONS_LOADING,
  }
}

export function updateSponsorInformationsFailure() {
  return {
    type: actions.UPDATE_SPONSOR_INFORMATIONS_FAILURE,
  }
}

export function setFirstLogin() {
  return {
    type: actions.SET_FIRST_LOGIN,
  }
}

export function setShowCampaignReviewPageMockedForTheTutorial(status) {
  return {
    type: actions.SET_SHOW_CAMPAIGN_REVIEW_PAGE_MOCKED_FOR_THE_TUTORIAL,
    payload: { status },
  }
}

export function addNewCommunityToSponsor(code, refreshRoute) {
  return {
    type: actions.ADD_NEW_COMMUNITY_TO_SPONSOR,
    payload: { code, refreshRoute },
  }
}

export function updateSponsorProfileRequest(id, data) {
  return {
    type: actions.UPDATE_SPONSOR_PROFILE_REQUEST,
    payload: { id, data },
  }
}

// delete the community informations got it by the invitation link code
export function deleteCommunitiesInformations() {
  return {
    type: actions.DELETE_COMMUNITY_INFORMATIONS,
  }
}

export function uploadS3Request(file) {
  return {
    type: actions.UPLOAD_S3_REQUEST,
    payload: { file },
  }
}

export function uploadS3Success(id, url) {
  return {
    type: actions.UPLOAD_S3_SUCCESS,
    payload: { id, url },
  }
}

export function removeS3FromState() {
  return {
    type: actions.REMOVE_S3_FROM_STATE,
  }
}

export function uploadS3LinkRequest(uploadTypeName, title, imageUrl, linkUrl) {
  return {
    type: actions.UPLOAD_S3_LINK_REQUEST,
    payload: { uploadTypeName, title, imageUrl, linkUrl },
  }
}

export function uploadS3LinkSuccess(id, url) {
  return {
    type: actions.UPLOAD_S3_LINK_SUCCESS,
    payload: { id, url },
  }
}

export function setAllCommunitiesFromSponsor(communities) {
  return {
    type: actions.SET_ALL_COMMUNITIES_FROM_SPONSOR,
    payload: { communities },
  }
}
