/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-else-return */
import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import { useSelector } from 'react-redux'
import { InputAdornment } from '@material-ui/core'
import Spinner from 'react-bootstrap/Spinner'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { InputTitle } from '~/components/Shared/StyledComponents'
import { getWebscrapperInformations } from '~/helper/formFunctions'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import ContentVisualization from '~/components/CreateFormatMobileVisualization/Content'
import { transformDateToUtcFormat } from '~/helper/getFormattedDates'
import Cover from '~/components/Sponsor/Cover'
import { toast } from 'react-toastify'
import ShouldRender from '~/components/ShouldRender'
import DisplayCriteriaSelect from '~/components/DisplayCriteriaSelect'

import 'react-lazy-load-image-component/src/effects/blur.css'
import * as S from './styled'

export default function ModalEditWeb({ review, handleCloseModal, showModal, webToUpdate, formatId, sponsor }) {
  const { t } = useTranslation()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const [loadingDebounce, setLoadingDebounce] = useState(false)
  const [scrapperDescription, setScrapperDescription] = useState(webToUpdate.scrapperDescription)
  const [scrapperTitle, setScrapperTitle] = useState(webToUpdate.scrapperTitle)
  const [, setScrapperTags] = useState(null)
  const [scrapperLink, setScrapperLink] = useState(null)
  const [, setIsUpdate] = useState(false)
  const { webpage, comment, image } = webToUpdate
  const [loadingImage, setLoadingImage] = useState(false)
  const [imageId, setImageId] = useState(image)
  const [imageUrl, setImageUrl] = useState(webToUpdate.imageUrl)
  const [date, setDate] = useState(transformDateToUtcFormat(webToUpdate.date))
  const { handleSubmit, setValue, register, control, errors, watch } = useForm()
  const [endDate, setEndDate] = useState(transformDateToUtcFormat(webToUpdate.endDate))
  const { updateCampaignFormatAction } = useSponsorFunctions()
  const sponsorManagerId = useSelector(state => state.sponsor.sponsorManager.id)

  const scrapperTitleWatch = watch('scrapperTitle')
  const scrapperDescriptionWatch = watch('scrapperDescription')

  const webpageWatch = watch('webpage', webpage)
  const uploadTypeName = 'image'

  function onSubmit(product) {
    if (loadingImage) return

    if (!product.imageUrl || !product.image) {
      return toast.error(t('defaultValidation', { inputName: t('image') }))
    }

    const copyObject = JSON.stringify(webToUpdate)
    const productCopy = JSON.stringify(product)
    if (copyObject === productCopy) {
      return
    }
    product.scrapperDescription = scrapperDescriptionWatch
    product.scrapperTitle = scrapperTitleWatch
    product.scrapperLink = scrapperLink

    updateCampaignFormatAction(product, formatId, handleCloseModal)
  }

  useEffect(() => {
    register({ name: 'id' })
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
    setValue('id', webToUpdate.id)
  }, [register, webToUpdate.id, setValue, imageId, imageUrl])

  // Debounce webpage
  const isFirstRun = useRef(true)
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    let typingTimer = null
    const val = webpageWatch
    clearTimeout(typingTimer)
    typingTimer = setTimeout(() => {
      if (val) {
        setLoadingDebounce(true)
        getWebscrapperInformations(
          webpageWatch,
          sponsorManagerId,
          currentCampaignForReviewPage.networkId,
          setScrapperTitle,
          setScrapperDescription,
          setScrapperTags,
          setLoadingDebounce,
          setScrapperLink,
          setImageUrl,
          setImageId,
          setLoadingImage,
          setIsUpdate,
          uploadTypeName,
        )
      }
    }, 2000)

    return () => clearTimeout(typingTimer)
  }, [currentCampaignForReviewPage.networkId, sponsorManagerId, webpageWatch])

  // Handle scrapper data updates
  useEffect(() => {
    setValue('scrapperTitle', scrapperTitle)
    setValue('scrapperLink', scrapperLink)
    setValue('scrapperDescription', scrapperDescription)

    if (scrapperTitle) {
      errors.scrapperTitle = undefined
    }

    if (scrapperDescription) {
      errors.scrapperDescription = undefined
    }
  }, [setValue, scrapperTitle, scrapperLink, scrapperDescription])

  return (
    <ProductsModal
      action={onSubmit}
      buttonText={review ? t('done') : t('update')}
      modalTitle={review ? t('reviseFormat', { format: t('webpage') }) : t('editFormat', { format: t('webpage') })}
      handleCloseModal={handleCloseModal}
      showModal={showModal}
      review={review}
      sponsor={sponsor}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <InputTitle>{t('pastTheWebpageLink')}</InputTitle>
            <Controller
              as={
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="https://www.example.com"
                  disabled={review || false}
                  InputProps={{
                    endAdornment: review ? null : (
                      <InputAdornment position="end">
                        {loadingDebounce ? <Spinner animation="border" size="sm" /> : ''}
                      </InputAdornment>
                    ),
                  }}
                />
              }
              rules={{ required: t('defaultValidation', { inputName: t('webpage') }) }}
              name="webpage"
              control={control}
              defaultValue={webpage === null ? '' : webpage}
            />
            <div className="error-message">
              <ErrorMessage errors={errors} name="webpage" as="p" />
            </div>

            <div className="mt-3">
              <InputTitle>{t('title')}</InputTitle>
              <Controller
                as={<TextField fullWidth variant="outlined" placeholder={t('typeTitle')} disabled={review || false} />}
                rules={{ required: t('defaultValidation', { inputName: t('title') }) }}
                name="scrapperTitle"
                control={control}
                defaultValue={scrapperTitle}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="scrapperTitle" as="p" />
              </div>
            </div>

            <div className="mt-3">
              <InputTitle>{t('description')}</InputTitle>

              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={t('typeDescription')}
                    disabled={review || false}
                  />
                }
                rules={{ required: t('defaultValidation', { inputName: t('description') }) }}
                name="scrapperDescription"
                control={control}
                defaultValue={scrapperDescription}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="scrapperDescription" as="p" />
              </div>
            </div>

            <DisplayCriteriaSelect control={control} value={webToUpdate?.privacy} disabled={review || false} />

            <StartDateSection
              product={t('webpage')}
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              disabled={review}
              control={control}
              errors={errors}
            />

            <EndDateSection
              product={t('webpage')}
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              disabled={review}
              control={control}
              errors={errors}
            />

            <div className="mt-3">
              <S.InputTitle>{t('careToComment')}</S.InputTitle>
              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={t('writeSomething')}
                    disabled={review || false}
                  />
                }
                name="comment"
                control={control}
                defaultValue={comment === null ? '' : comment}
              />
            </div>

            <ShouldRender if={!review}>
              <Cover setImageUrl={setImageUrl} setImageId={setImageId} imageUrl={imageUrl} loading={loadingImage} />
            </ShouldRender>
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          FormatVisualizationComponent={
            <ContentVisualization
              loadingImage={loadingImage}
              image={imageUrl}
              title={scrapperTitleWatch}
              about={scrapperDescriptionWatch}
              contentType={t('web')}
            />
          }
        />
      }
    />
  )
}
