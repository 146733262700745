import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9998;
  left: 0;
`

export const Content = styled.div`
  border-radius: 10px;
  background-color: white;
  padding: 18px;
  position: relative;
  transition: height 0.5s ease-in-out;

  ${({ hideCloseIcon }) => !hideCloseIcon && 'padding-top: 80px;'}
`
