import React from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import 'chart.piecelabel.js'
import * as S from './styled'
import { BannerCard } from '../BannerReviewCard'

export function MultipleBannerReviewCard({ type, banners }) {
  const { t } = useTranslation()

  const bannerName = {
    topBanner: t('slidingTopBanner'),
    bottomBanner: t('bottomStoriesBanner'),
  }

  return (
    <Container fluid>
      <S.CardBody>
        <div className="icon-section">
          <img style={{ opacity: '.9' }} src="/icons/rewards.png" alt="Welcome Ads" width="19" height="19" />
          <p className="ml-2">{bannerName[type] ?? 'Banner'}</p>
        </div>
        <S.AnalyticsCardRow>
          {banners?.map(b => (
            <BannerCard
              key={b.unity.title}
              name={b.unity.title}
              image={b.unity.imageUrl}
              analytics={b.analytics}
              type={type}
            />
          ))}
        </S.AnalyticsCardRow>
      </S.CardBody>
    </Container>
  )
}
