import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Controller, ErrorMessage } from 'react-hook-form'
import ShouldRender from '~/components/ShouldRender'
import { DatePickerInput, InputTitle, InputDateTitle } from '~/components/Shared/StyledComponents'
import { formatDate, transformDateToUtcFormat } from '~/helper/getFormattedDates'
import * as S from './styled'

export function StartDateSection({
  product,
  selectedCampaignStartDate,
  setCampaignStartDate,
  disabled,
  control,
  errors,
}) {
  const { t } = useTranslation()

  const {
    campaignMonthStartDate,
    campaignMonthEndDate,
    currentCampaignSelectedMonth,
    currentCampaignMonthYear,
  } = useSelector(state => state.createCampaign)

  const currentMonth = new Date().getMonth() + 1

  const startDate = new Date(campaignMonthStartDate)
  const firstDayOfTheMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  const currentYear = new Date().getFullYear()

  const getMinDate = () => {
    // Current month
    if (currentMonth === currentCampaignSelectedMonth) return transformDateToUtcFormat(campaignMonthStartDate)

    // Future months
    if (currentMonth < currentCampaignSelectedMonth) {
      return transformDateToUtcFormat(campaignMonthStartDate)
    }

    // Future months and different years
    // Example:
    // A campaign starts in October 2024 and ends in March 2025
    // current month is October
    // user selects January of 2025
    if (currentMonth > currentCampaignSelectedMonth && currentYear < currentCampaignMonthYear) {
      return transformDateToUtcFormat(campaignMonthStartDate)
    }

    // Past months
    if (currentMonth > currentCampaignSelectedMonth && currentYear === currentCampaignMonthYear) {
      if (currentYear < currentCampaignMonthYear) {
        return firstDayOfTheMonth
      }
      return transformDateToUtcFormat(campaignMonthStartDate)
    }
  }

  const getInputLabel = () => {
    if (currentMonth === currentCampaignSelectedMonth) {
      return t('selectDateBetween', {
        campaignStartDate: formatDate(campaignMonthStartDate),
        campaignEndDate: formatDate(campaignMonthEndDate),
      })
    }

    if (currentMonth < currentCampaignSelectedMonth) {
      return t('selectDateBetween', {
        campaignStartDate: formatDate(campaignMonthStartDate),
        campaignEndDate: formatDate(campaignMonthEndDate),
      })
    }

    if (currentMonth > currentCampaignSelectedMonth) {
      if (currentYear < currentCampaignMonthYear) {
        return t('selectDateBetween', {
          campaignStartDate: formatDate(campaignMonthStartDate),
          campaignEndDate: formatDate(campaignMonthEndDate),
        })
      }
      return t('selectDateBetween', {
        campaignStartDate: formatDate(campaignMonthStartDate),
        campaignEndDate: formatDate(campaignMonthEndDate),
      })
    }
  }

  return (
    <S.Container>
      <S.InputContainer>
        <InputTitle>{t('startDate')}</InputTitle>
        <ShouldRender if={!disabled}>
          <InputDateTitle>{getInputLabel()}</InputDateTitle>
        </ShouldRender>
      </S.InputContainer>
      <Controller
        as={
          <DatePickerInput
            selected={selectedCampaignStartDate}
            placeholderText={t('dateWhenTheContentWillBeAvailable', {
              format: product?.toLocaleLowerCase(),
            })}
            disabled={disabled || false}
            minDate={getMinDate()}
            maxDate={transformDateToUtcFormat(campaignMonthEndDate)}
          />
        }
        rules={{ required: t('defaultValidation', { inputName: t('startDate') }) }}
        name="date"
        control={control}
        onChange={([selected]) => {
          const selectedValue = selected || ''
          setCampaignStartDate(selectedValue)
          return selectedValue
        }}
        defaultValue={selectedCampaignStartDate}
      />
      <S.ErrorMessageContainer>
        <ErrorMessage errors={errors} name="date" as="p" />
      </S.ErrorMessageContainer>
    </S.Container>
  )
}

export function EndDateSection({
  product,
  selectedCampaignStartDate,
  selectedCampaignEndDate,
  setCampaignEndDate,
  disabled,
  control,
  errors,
}) {
  const { t } = useTranslation()
  const { campaignMonthEndDate } = useSelector(state => state.createCampaign)

  return (
    <S.Container>
      <InputTitle>{t('endDate')}</InputTitle>
      {selectedCampaignStartDate && (
        <ShouldRender if={!disabled}>
          <InputDateTitle>
            {t('selectDateBetween', {
              campaignStartDate: formatDate(selectedCampaignStartDate),
              campaignEndDate: formatDate(campaignMonthEndDate),
            })}
          </InputDateTitle>
        </ShouldRender>
      )}
      <Controller
        as={
          <DatePickerInput
            selected={selectedCampaignEndDate}
            placeholderText={t('dateWhenTheContentWillBecomeUnavailable', {
              format: product?.toLocaleLowerCase(),
            })}
            disabled={disabled || !selectedCampaignStartDate || false}
            minDate={transformDateToUtcFormat(selectedCampaignStartDate)}
            maxDate={transformDateToUtcFormat(campaignMonthEndDate)}
          />
        }
        rules={{ required: t('defaultValidation', { inputName: t('endDate') }) }}
        name="endDate"
        control={control}
        onChange={([selected]) => {
          const selectedValue = selected || ''

          setCampaignEndDate(selectedValue)
          return selectedValue
        }}
        defaultValue={selectedCampaignEndDate}
      />
      <S.ErrorMessageContainer>
        <ErrorMessage errors={errors} name="endDate" as="p" />
      </S.ErrorMessageContainer>
    </S.Container>
  )
}
