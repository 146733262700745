import React, { useState } from 'react'
import JoyRide from 'react-joyride'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SearchInput from '~/components/SearchInput'
import ModalCustomSponsoredFormat from '~/components/Community/Modals/ModalCustomSponsoredFormat'
import FormatsTabTooltip from '~/components/ApplicationTutorial/Community/FormatsTabTooltip'
import TabTitleAndSubtitle from '~/components/Community/TabTitleAndSubtitle'
import ProductCard from '~/components/Community/ProductCard'
import { returnDataFiltered } from '~/helper/formFunctions'
import useCommunityFunctions from '~/helper/communityFunctions'
import ZeroRegisterIndicator from '~/components/ZeroRegisterIndicator'
import * as CONST from '~/helper/const'
import * as S from './styled'

export default function ProductTab({ products, setCallbackToGetProducts, refetchAlert }) {
  const { t } = useTranslation()
  const tutorialIsActive = useSelector(state => state.tutorial.tutorialIsActive)
  const { deleteCustomAdvertisementProductAction } = useCommunityFunctions()
  const [showModalCustomSponsoredFormat, setShowModalCustomSponsoredFormat] = useState(false)
  const [filter, setFilter] = useState('')

  const filteredProducts = returnDataFiltered(filter, products)

  return (
    <S.Container>
      <JoyRide
        steps={CONST.communityFormatsTooltipOptions}
        run={tutorialIsActive}
        tooltipComponent={FormatsTabTooltip}
        styles={{
          options: {
            arrowColor: 'transparent',
          },
        }}
      />
      <TabTitleAndSubtitle title={t('advertisementProducts')} subtitle={t('sponsoredFormatsDescription')} />
      <S.SearchInputContainer>
        <SearchInput filter={filter} setFilter={setFilter} />
        <S.ButtonContainer>
          <S.NewCustomFormatButton
            text={t('customFormat')}
            icon={<S.NewCustomFormatIcon />}
            onClick={() => setShowModalCustomSponsoredFormat(true)}
          />
        </S.ButtonContainer>
      </S.SearchInputContainer>
      <S.ProductsContainer>
        {filteredProducts.map(product => (
          <ProductCard
            key={product.id}
            product={product}
            deleteCustomAdvertisementProduct={deleteCustomAdvertisementProductAction}
            setCallbackToGetProducts={setCallbackToGetProducts}
            refetchAlert={refetchAlert}
          />
        ))}
        {filteredProducts?.length === 0 && <ZeroRegisterIndicator text={t('noFormatsCreated')} />}
      </S.ProductsContainer>
      <ModalCustomSponsoredFormat
        showModal={showModalCustomSponsoredFormat}
        handleCloseModal={() => setShowModalCustomSponsoredFormat(false)}
        setCallbackToGetProducts={setCallbackToGetProducts}
      />
    </S.Container>
  )
}
