import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { darken } from 'polished'
import { customMedia } from '~/styles/global'

export const ModalCustom = styled(Modal).attrs({
  backdrop: 'static',
  keyboard: false,
  className: 'application-tutorial',
})`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 420px;
      top: 10%;
    }

    ${customMedia.lessThan('1560px')`
      top: 0%;
       max-width: 400px;
    `}
  }

  .modal-content {
    background-color: #fff !important;
    border-radius: 30px !important;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff !important;
    border-radius: 30px !important;
    padding-bottom: 40px;
    padding-right: 16px;
    padding-left: 16px;
  }
`

export const WelcomeImage = styled(LazyLoadImage).attrs({
  src: '/tutorial-welcome.png',
  effect: 'blur',
})`
  width: 100%;
  height: 100%;
`

export const WelcomeText = styled.p`
  color: #595959;
  font-family: Roboto;
  margin: 0px;
  margin-top: 20px;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
`

export const WelcomeDescription = styled.p`
  color: #767676;
  font-family: Roboto;
  margin: 0px;
  margin-top: 14px;
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  white-space: break-spaces;

  ${customMedia.lessThan('1560px')`
    font-size: 16px;
  `}
`

export const StartNowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Roboto;
  border-radius: 30px;
  margin-top: 35px;
  font-weight: 400;
  text-align: center;
  width: 67%;
  height: 45px;
  background-color: ${props => (props.sponsor ? 'var(--sponsor-primary)' : 'var(--community-primary)')};

  &:hover {
    background-color: ${props => (props.sponsor ? darken(0.03, '#00A2FF') : darken(0.03, '#9671F5'))};
  }

  &:active {
    -webkit-box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    top: 2px;
  }

  ${customMedia.lessThan('1560px')`
    width: 65%;
  `}
`

export const StartNowText = styled.p.attrs({
  className: 'start-now-button-tutorial',
})`
  color: #fff;
  font-family: Roboto;
  margin: 0px;
  font-weight: 700;
  font-size: 21px;
  letter-spacing: 0.05em;
  line-height: 15px;
`
