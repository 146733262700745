import styled from 'styled-components'
import { customMedia } from '~/styles/global'

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.color ? props.color : '#0059b3')};
  margin-top: 12px;
  border-radius: 5px;
  height: 1.8rem;
  max-width: 7.4rem;
  padding: 0px 10px;
  border: none;

  text-transform: uppercase;
  color: ${props => (props.textColor ? props.textColor : '#FFF')};
  font-family: Lato;
  font-size: ${props => (props.fontSize ? props.fontSize : '17px')};
  font-weight: 600;

  &:hover {
    opacity: 0.9;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: baseline;

  ${customMedia.lessThan('1450px')`
     align-items: center;
  `}

  span {
    text-transform: uppercase;
    margin-left: 5px;
    color: #87919a;
    font-family: Lato;
    font-size: ${props => (props.eventTextFontSize ? props.eventTextFontSize : '13px')};
    font-weight: 600;

    ${customMedia.lessThan('1450px')`
      font-size: 12px;
    `}
  }
`
