import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Button from '~/components/Shared/Button'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    max-width: 950px;
    height: auto;
    margin: auto;
    padding: 0;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;

    .modal-title {
      color: #404257;
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      line-height: 27px;
    }
  }
`

export const FormContainer = styled.div`
  .input-date {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    height: 40px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(159, 159, 159, 0.7);
    border-radius: 5px;
    padding-left: 5px;
  }
`

export const InputTitle = styled.span`
  margin-top: 3px;
  color: #404257;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
`

export const ProductTitle = styled.span`
  margin-top: 3px;
  color: #fff;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
`

export const Question = styled.span`
  margin-top: 3px;
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 27px;
`

export const AboutSection = styled.div`
  margin: 10px 10px 3px 10px;
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
`

export const Phone = styled.div`
  width: 310px;
  height: 640px;
  border: 2px solid #ccc;
  border-radius: 30px;
  position: relative;
  left: 50%;
  top: 50px;
  margin: -20px -150px;
  background: #fff;
`

export const Screen = styled.div`
  background-color: rgba(0, 0, 0, 0.95);
  width: 290px;
  height: 520px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  left: 50%;
  top: 50%;
  margin: -260px -145px;
  box-sizing: border-box;
  overflow: hidden;
`

export const Home = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0 -10px;
  border-radius: 50%;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .image-container {
    width: 100%;
    height: 320px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`

export const RadioGroupSection = styled.div`
  display: flex;
  flex-direction: column;

  .MuiTypography-body1 {
    font-size: 14px !important;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }
`

export const SaveButton = styled(Button).attrs({
  form: 'my-form',
  uppercase: true,
  type: 'submit',
  width: '7.5rem',
  height: '2.5rem',
  color: '#00A2FF',
})``
