/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import ShouldRender from '~/components/ShouldRender'
import { toast } from 'react-toastify'
import TextField from '@material-ui/core/TextField'
import {
  useStripe,
  useElements,
  CardNumberElement,
  IbanElement,
  CardCvcElement,
  CardExpiryElement,
  AddressElement,
} from '@stripe/react-stripe-js'
import Spinner from 'react-bootstrap/Spinner'
import { PAYMENT_OPTIONS } from '~/pages/Sponsor/containers/StartNewCampaign/StartNewCampaignStep2/constants'
import useResponsiveFontSize from '~/helper/useResponsiveFontSize'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import StepPayment from '~/components/StepPayment'
import * as S from './styled'
import IbanForm from './IbanForm/IbanForm'

export default function ModalCreditCardInformation({
  handleCloseModal,
  showModal,
  paymentOption,
  stripeClientSecret,
  validateAndCreateCampaign,
  sendMessageToCommunityAfterTheCampaignCreation,
  handleCloseModalCreateCampaign,
  validateIfCampaignCanBeCreated,
}) {
  const { t } = useTranslation()
  const { resetStripeClientSecretAction } = useSponsorFunctions()
  const [loadingStripe, setLoadingStripe] = useState(false)

  const isCreditSelected = paymentOption === PAYMENT_OPTIONS.CREDIT_CARD
  const isSEPASelected = paymentOption === PAYMENT_OPTIONS.SEPA

  const closeModalAndResetStripeClientSecret = () => {
    resetStripeClientSecretAction()
    handleCloseModal()
  }

  const useOptions = () => {
    const fontSize = useResponsiveFontSize()
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Source Code Pro, monospace',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
      }),
      [fontSize],
    )
    return options
  }

  const stripe = useStripe()
  const elements = useElements()
  const options = useOptions()

  const handleSubmit = async event => {
    if (!stripe || !elements) return

    setLoadingStripe(true)

    event.preventDefault()

    // Verify if the campaign can be created
    const response = await validateIfCampaignCanBeCreated()

    if (!response?.data?.verifyIfCampaignCanBeCreated) {
      setLoadingStripe(false)
      return toast.error('Cannot create campaign, please change the parameters and try again')
    }

    const addressElement = elements.getElement(AddressElement)
    const { complete, value: addressElementValues } = await addressElement.getValue()

    const billingDetails = {
      email: event?.target?.email?.value,
      address: addressElementValues?.address,
      name: addressElementValues?.name,
    }

    let result

    if (isSEPASelected) {
      if (!complete) {
        setLoadingStripe(false)
        return toast.error('Incomplete address, fill all fields.')
      }

      result = await stripe.confirmSepaDebitPayment(stripeClientSecret, {
        payment_method: {
          sepa_debit: elements.getElement(IbanElement),
          billing_details: billingDetails,
        },
      })
    }

    if (isCreditSelected) {
      if (!complete) {
        setLoadingStripe(false)
        return toast.error('Incomplete address, fill all fields.')
      }

      result = await stripe.confirmCardPayment(stripeClientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: billingDetails,
        },
      })
    }

    if (result.error) {
      toast.error(result.error.message)
      setLoadingStripe(false)
    } else {
      // SEPA Direct Debit payments are asynchronous,
      // so funds are not immediately available and status will be `'processing'`
      if (result.paymentIntent.status === 'succeeded' || result.paymentIntent.status === 'processing') {
        setLoadingStripe(false)
        try {
          toast.success(t('yourPaymentHasBeenAuthorized'))
          await validateAndCreateCampaign()
          sendMessageToCommunityAfterTheCampaignCreation()
          setTimeout(() => {
            handleCloseModal()
            handleCloseModalCreateCampaign()
          }, 1500)
        } catch {
          console.log('Error')
        }
      } else {
        toast.error(t('somethingWentWrongPayment'))
      }
      setLoadingStripe(false)
    }
  }

  useEffect(() => {
    handleCloseModal()
  }, [])

  return (
    <S.ModalCustom show={showModal} onHide={closeModalAndResetStripeClientSecret}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.Title>{isSEPASelected ? 'SEPA Direct Debit' : t('yourCreditCard')}</S.Title>
        </Modal.Title>
      </Modal.Header>

      <S.Container>
        <S.StepContainer>
          <S.StepContainerTitle>{t('payment')}</S.StepContainerTitle>
          <StepPayment />
        </S.StepContainer>
      </S.Container>

      <Modal.Body>
        <ShouldRender if={isSEPASelected}>
          <IbanForm onSubmit={handleSubmit} />
        </ShouldRender>

        <ShouldRender if={isCreditSelected}>
          <S.Form onSubmit={handleSubmit}>
            <S.FormContainer>
              <S.EmailInputContainer>
                <S.Label>{t('email')}</S.Label>
                <TextField
                  style={{ borderRadius: 6, marginBottom: '0.75rem' }}
                  name="email"
                  placeholder={t('typeYourEmail')}
                  className="email-input"
                  required
                  type="email"
                  fullWidth
                  inputProps={{
                    maxLength: 80,
                  }}
                />
              </S.EmailInputContainer>

              <AddressElement
                options={{
                  mode: 'billing',
                  fields: {
                    phone: 'never',
                  },
                  autocomplete: { mode: 'google_maps_api', apiKey: 'AIzaSyDgO_N9c1jeeop39DwJ-cFYMTn46tHHJHs' },
                }}
              />

              <S.Label>{t('cardNumber')}</S.Label>
              <S.StripeElementContainer>
                <CardNumberElement options={options} />
              </S.StripeElementContainer>
              <S.Label>{t('expirationDate')}</S.Label>
              <S.StripeElementContainer>
                <CardExpiryElement options={options} />
              </S.StripeElementContainer>
              <S.Label className="mb-0">CVC</S.Label>
              <S.StripeElementContainer>
                <CardCvcElement options={options} />
              </S.StripeElementContainer>
              <S.PoweredByStripeContainer>
                <S.PoweredByStripe />
              </S.PoweredByStripeContainer>
            </S.FormContainer>
          </S.Form>
        </ShouldRender>
      </Modal.Body>
      <S.ModalFooter>
        <S.SubmitButton
          form={isCreditSelected ? 'stripe' : 'stripe-sepa'}
          uppercase
          text={loadingStripe ? <Spinner animation="border" size="sm" /> : t('done')}
          isDisabled={loadingStripe || !stripe || !elements}
        />
      </S.ModalFooter>
    </S.ModalCustom>
  )
}
