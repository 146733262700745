import React, { useState, useEffect, useCallback, useMemo } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { toast } from 'react-toastify'
import { useElements, AddressElement } from '@stripe/react-stripe-js'
import { useAsync } from 'react-async'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { datadogLogs } from '@datadog/browser-logs'
import { getCommunityInformations, getBlockedDatesByCampaign } from '~/services/requests'
import { DefaultLogoImage } from '~/util/defaultImages'
import ModalAddProductsPayAsYouGo from '~/components/Modals/ModalAddProductsPayAsYouGo'
import useCampaignSelectors from '~/helper/campaignSelectors'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import ModalCreditCardInformation from '~/components/Sponsor/Modals/ModalCreditCardInformation'
import CreateCampaignModalTooltipStep2 from '~/components/ApplicationTutorial//Sponsor/CreateCampaignModalTooltipStep2'
import useGetCommunityCurrencySymbol from '~/hooks/useGetCommunityCurrencySymbol'
import { compareCampaignStartDateAndEndDate } from '~/helper/getFormattedDates'
import { currencyFormat } from '~/helper/helperFunctions'
import * as FN from '~/helper/formFunctions'
import * as CONST from '~/helper/const'
import * as CHAT from '~/helper/chatFunctions'
import useChatFunctions from '~/helper/chatActions'
import SelectPackageForm from './components/SelectPackageForm'
import PayAsYouGoForm from './components/PayAsYouGoForm'
import * as S from './styled'
import { PAYMENT_OPTIONS } from './constants'

const calculateCampaignTotalPrice = (price, duration) => {
  const totalPrice = price * duration
  return currencyFormat(totalPrice)
}

export default function StartNewCampaignStep2({
  showModalCreditCardMocked,
  openCreditCardMockedModal,
  handleCloseModalCreateCampaign,
}) {
  const { t } = useTranslation()
  const sponsorFunction = useSponsorFunctions()
  const { openCloseChatWindowAction, setActiveChannelAction } = useChatFunctions()

  const tutorialIsActive = useSelector(state => state.tutorial.tutorialIsActive)
  const sponsor = useSelector(state => state.sponsor.company)
  const { id: companyId, name: companyName } = useSelector(state => state.sponsor.company)
  const stripeClientSecretCreatedByPaymentIntent = useSelector(state => state.createCampaign.stripeClientSecret)
  const loadingPaymentIntent = useSelector(state => state.createCampaign.loadingStripePaymentIntent)
  const stripeTemporaryCampaignIdForPaymentIntent = useSelector(
    state => state.createCampaign.stripeTemporaryCampaignIdForPaymentIntentCreation,
  )

  const { currentCommunityForCreateCampaignProccess } = useCampaignSelectors()

  const {
    id: communityId,
    name: communityName,
    products: communityProducts,
    adrCity: communityCity,
    adrCountry: communityCountry,
    networkLogoPath: communityLogo,
    packages,
    currency,
  } = currentCommunityForCreateCampaignProccess() || {}

  const communityCurrencySymbol = useGetCommunityCurrencySymbol(currency)
  const paymentOptionState = useState(PAYMENT_OPTIONS.SEPA)
  const [paymentOption] = paymentOptionState

  const { handleSubmit, errors, control, setValue } = useForm()

  const validPackages = useMemo(() => packages?.filter(packageItem => packageItem.statusActive === 'actived'), [
    packages,
  ])
  const campaignModelSelectOptions = useMemo(
    () => [
      { value: 1, label: t('sponsoredPackages') },
      { value: 2, label: t('payAsYouGoSelect') },
    ],
    [t],
  )

  const campaignModelSelectOptionsNoPackages = useMemo(() => [{ value: 2, label: t('payAsYouGoSelect') }], [t])

  const [campaignModel, setCampaignModel] = useState(
    validPackages?.length > 0 ? campaignModelSelectOptions[0] : campaignModelSelectOptions[1],
  )
  const [showModalAddProducts, setShowModalAddProducts] = useState(false)
  const [showModalCreditCardInformation, setShowModalCreditCardInformation] = useState(false)
  const [payAsYouGoFormSubmitData, setPayAsYouGoFormSubmitData] = useState(null)
  const [packageFormSubmitData, setPackageFormSubmitData] = useState(null)
  const [campaignDuration, setCampaignDuration] = useState(null)
  const [totalCampaignPrice, setTotalCampaignPrice] = useState(0)
  const [totalCampaignProducts, setTotalCampaignProducts] = useState(0)
  const [selectedPackage, setSelectedPackage] = useState('')
  const [packagePriceWithDiscount, setPackagePriceWithDiscount] = useState(0)
  const [packageNormalPrice, setPackageNormalPrice] = useState(0)
  const [productsSelectedPayAsYouGo, setProductsSelectedPayAsYouGo] = useState([])
  const [channel, setChannel] = useState(null)
  const [blockedDates, setBlockedDates] = useState([])
  const elements = useElements()

  const onCloseCreditCardModal = () => {
    setShowModalCreditCardInformation(false)

    const address = elements.getElement(AddressElement)

    // clear address field data from stripe when closing the modal.
    if (address) {
      address.clear()
    }
  }

  const { data, error, isPending } = useAsync({ promiseFn: getCommunityInformations, communityId })

  useEffect(() => {
    CHAT.getSpecificChannelWithCommunityId(communityId, setChannel, companyId)
  }, [communityId, companyId])

  const campaignsOptionsByPackages = useMemo(() => {
    const communityPackages = validPackages.length >= 1

    if (communityPackages) return campaignModelSelectOptions

    return campaignModelSelectOptionsNoPackages
  }, [campaignModelSelectOptions, campaignModelSelectOptionsNoPackages, validPackages.length])

  const isCampaignByPackage = useMemo(() => {
    if (campaignModel?.value === 1) return true

    return false

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignModel?.value, t])

  const isCampaignPayAsYouGo = useMemo(() => {
    if (campaignModel?.value === 2) return true

    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignModel?.value, t])

  const isCampaignDurationIsTheSameAsThePackage = useMemo(() => {
    if (selectedPackage?.specialOfferDuration?.qtdMonth === campaignDuration) {
      return true
    }

    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignDuration, selectedPackage?.specialOfferDuration?.qtdMonth])

  const validateIfCampaignCanBeCreated = useCallback(() => {
    if (isCampaignByPackage) {
      const response = FN.checkIfCampaignByPackageCanBeCreated(
        communityId,
        stripeTemporaryCampaignIdForPaymentIntent,
        packageFormSubmitData,
        selectedPackage?.id,
      )
      return response
    }

    if (isCampaignPayAsYouGo) {
      const response = FN.checkIfPayAsYouGoCampaignCanBeCreated(
        communityId,
        stripeTemporaryCampaignIdForPaymentIntent,
        payAsYouGoFormSubmitData,
        productsSelectedPayAsYouGo,
      )
      return response
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    communityId,
    isCampaignByPackage,
    isCampaignPayAsYouGo,
    packageFormSubmitData,
    payAsYouGoFormSubmitData,
    productsSelectedPayAsYouGo,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    selectedPackage?.id,
    sponsorFunction.createCampaignPayAsYouGoAction,
    sponsorFunction.createCampaignWithPackageAction,
    stripeTemporaryCampaignIdForPaymentIntent,
  ])

  const validateAndCreateCampaign = useCallback(() => {
    if (isCampaignPayAsYouGo) {
      FN.validateAndCreateCampaignPayAsYouGo(
        communityId,
        stripeTemporaryCampaignIdForPaymentIntent,
        payAsYouGoFormSubmitData,
        productsSelectedPayAsYouGo,
        sponsorFunction.createCampaignPayAsYouGoAction,
      )
    }
    if (isCampaignByPackage) {
      FN.validateAndCreateCampaignByPackage(
        communityId,
        stripeTemporaryCampaignIdForPaymentIntent,
        packageFormSubmitData,
        selectedPackage?.id,
        sponsorFunction.createCampaignWithPackageAction,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    communityId,
    isCampaignByPackage,
    isCampaignPayAsYouGo,
    packageFormSubmitData,
    payAsYouGoFormSubmitData,
    productsSelectedPayAsYouGo,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    selectedPackage?.id,
    sponsorFunction.createCampaignPayAsYouGoAction,
    sponsorFunction.createCampaignWithPackageAction,
    stripeTemporaryCampaignIdForPaymentIntent,
  ])

  const sendMessageToCommunity = useCallback(
    message => {
      CHAT.createMessageBetweenCompanyAndCommunity(sponsor, message, channel?.id, communityId)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channel?.id, communityId, sponsor],
  )

  const sendMessageToCommunityToReportBlockedDay = useCallback(
    ({ date, duration, formats }) => {
      const message = `${companyName} ${t('blockedDatesMessage', {
        date,
        duration,
        formats,
      })}`
      sendMessageToCommunity(message)

      CHAT.getSpecificChannelWithCommunityId(communityId, setChannel, sponsor?.id)

      setActiveChannelAction(channel)
      openCloseChatWindowAction(channel?.id)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      channel?.id,
      communityId,
      companyName,
      openCloseChatWindowAction,
      sendMessageToCommunity,
      setActiveChannelAction,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sponsor?.id,
      t,
    ],
  )

  const sendMessageToCommunityAfterTheCampaignCreation = useCallback(() => {
    const message = `${companyName} ${t('justInvestedANewBudget')}`
    sendMessageToCommunity(message)
  }, [companyName, sendMessageToCommunity, t])

  const onSubmit = useCallback(
    data => {
      datadogLogs.logger.info('Campaign creation submit data', data)

      const validateStartAndEndDate = compareCampaignStartDateAndEndDate(data?.dtStart, data?.dtEnd)

      if (validateStartAndEndDate) {
        return toast.error(t('startDateHigherThenEndDate'))
      }

      if (isCampaignPayAsYouGo) {
        setPayAsYouGoFormSubmitData(data)

        if (!tutorialIsActive) {
          FN.validateAndCreatePaymentIntentForPayAsYouGo(
            communityId,
            sponsorFunction.createPaymentIntentPayAsYouGoAction,
            stripeTemporaryCampaignIdForPaymentIntent,
            productsSelectedPayAsYouGo,
            data.campaignDuration?.value,
            paymentOption,
          )
        }

        if (tutorialIsActive) {
          setShowModalCreditCardInformation(true)
        }
      }
      if (isCampaignByPackage) {
        if (selectedPackage.hasSpecialOffer === 1) {
          data.campaignDuration = {
            value: selectedPackage.specialOfferDuration.qtdMonth,
            label: selectedPackage.specialOfferDuration.title,
          }
        }

        setPackageFormSubmitData(data)

        if (!tutorialIsActive) {
          FN.validateAndCreatePaymentIntentForPackage(
            communityId,
            selectedPackage.id,
            stripeTemporaryCampaignIdForPaymentIntent,
            data.campaignDuration?.value,
            sponsorFunction.createPaymentIntentPackageAction,
            paymentOption,
          )
        }

        if (tutorialIsActive) {
          setShowModalCreditCardInformation(true)
        }
      }
      return null
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      communityId,
      isCampaignByPackage,
      isCampaignPayAsYouGo,
      paymentOption,
      productsSelectedPayAsYouGo,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      selectedPackage?.hasSpecialOffer,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      selectedPackage?.id,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      selectedPackage?.specialOfferDuration?.qtdMonth,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      selectedPackage?.specialOfferDuration?.title,
      sponsorFunction.createPaymentIntentPackageAction,
      sponsorFunction.createPaymentIntentPayAsYouGoAction,
      stripeTemporaryCampaignIdForPaymentIntent,
      t,
      tutorialIsActive,
    ],
  )

  useEffect(() => {
    if (stripeClientSecretCreatedByPaymentIntent != null) {
      setShowModalCreditCardInformation(true)
    }
  }, [stripeClientSecretCreatedByPaymentIntent])

  const calculateCampaignTotalPriceByType = useCallback(() => {
    if (isCampaignPayAsYouGo) {
      const campaignTotalPrice = FN.getCampaignTotalPrice(productsSelectedPayAsYouGo)
      const campaignProductsSelected = FN.getTotaProductsSelected(productsSelectedPayAsYouGo)
      setTotalCampaignProducts(campaignProductsSelected)
      setTotalCampaignPrice(campaignTotalPrice * campaignDuration)

      return
    }
    setTotalCampaignProducts(0)
    setTotalCampaignPrice(0)

    if (isCampaignByPackage && selectedPackage) {
      setPackageNormalPrice(selectedPackage?.monthlyPrice)
      setPackagePriceWithDiscount(selectedPackage?.specialOfferPrice)

      return
    }

    setPackageNormalPrice(0)
    setPackagePriceWithDiscount(0)
  }, [campaignDuration, isCampaignByPackage, isCampaignPayAsYouGo, productsSelectedPayAsYouGo, selectedPackage])

  const handleGetBlockedDatesByCampaign = useCallback(async () => {
    const formats = isCampaignByPackage
      ? selectedPackage?.products?.map(product => ({ id: product.id, qtdPerMonth: product?.quantity?.qtdPerMonth }))
      : productsSelectedPayAsYouGo?.map(product => ({ id: product.id, qtdPerMonth: product?.qtdPerMonth }))

    const response = await getBlockedDatesByCampaign({
      formats,
      duration: 24,
      date: new Date(),
    })

    setBlockedDates(
      response.data.map(blockedDates => ({
        ...blockedDates,
        reason: blockedDates.name,
        formattedDates: blockedDates.dates.map(({ startDate, endDate }) => ({
          from: new Date(new Date(startDate).toISOString().slice(0, -1)),
          to: new Date(new Date(endDate).toISOString().slice(0, -1)),
        })),
      })),
    )

    setValue('dtStart', undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignDuration, isCampaignByPackage, productsSelectedPayAsYouGo, selectedPackage?.products])

  useEffect(() => {
    if (errors) datadogLogs.logger.info('Form validation errors:', errors)
  }, [errors])

  useEffect(() => {
    calculateCampaignTotalPriceByType()

    const shouldGetBlockedDates = Boolean(
      campaignModel?.value && campaignDuration && (productsSelectedPayAsYouGo.length || selectedPackage),
    )

    if (shouldGetBlockedDates) {
      handleGetBlockedDatesByCampaign()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    campaignModel?.value,
    campaignDuration,
    productsSelectedPayAsYouGo,
    selectedPackage,
    calculateCampaignTotalPrice,
    handleGetBlockedDatesByCampaign,
  ])

  if (error) throw new Error(error)

  if (isPending)
    return (
      <S.SpinnerContainer>
        <S.CustomSpinner />
      </S.SpinnerContainer>
    )

  if (data) {
    return (
      <S.Container>
        <S.CommunityHeaderInformations>
          <S.CommunityInformationsContainer>
            <S.CommunityLogo src={communityLogo === null ? DefaultLogoImage : communityLogo} />

            <S.CommunityContainer>
              <S.CompanyName>{communityName}</S.CompanyName>

              <S.CommunityLocation>{`${communityCity}, ${communityCountry}`}</S.CommunityLocation>
            </S.CommunityContainer>
          </S.CommunityInformationsContainer>
        </S.CommunityHeaderInformations>

        <Row className="justify-content-center">
          <Col md={11}>
            <S.FormContainer>
              <S.Form onSubmit={handleSubmit(onSubmit)}>
                <S.TitleWrapper>
                  <S.FormTitle>{t('setYourCampaign')}</S.FormTitle>

                  <S.ProductTotalAndFormatsContainer>
                    {isCampaignPayAsYouGo ? (
                      <>
                        <S.CampaignTotalPrice>
                          {`${communityCurrencySymbol}${currencyFormat(Number(totalCampaignPrice))}`}
                        </S.CampaignTotalPrice>

                        <S.FormatsSelected>{`/ ${totalCampaignProducts} ${t('formatsPerMonth')}`}</S.FormatsSelected>
                      </>
                    ) : null}

                    {isCampaignByPackage && isCampaignDurationIsTheSameAsThePackage ? (
                      <>
                        {selectedPackage ? (
                          <S.PackageDiscountPrice>
                            {`${communityCurrencySymbol}${Number(packageNormalPrice).toFixed(2)}`}
                          </S.PackageDiscountPrice>
                        ) : null}

                        <S.PackageTotalPrice>
                          {`${communityCurrencySymbol}${Number(packagePriceWithDiscount).toFixed(2)}`}
                        </S.PackageTotalPrice>

                        <S.FormatsSelected>{`/ ${t('monthly')}`}</S.FormatsSelected>
                      </>
                    ) : null}

                    {isCampaignByPackage && !isCampaignDurationIsTheSameAsThePackage ? (
                      <>
                        <S.PackageTotalPrice>
                          {`${communityCurrencySymbol}${Number(packageNormalPrice).toFixed(2)}`}
                        </S.PackageTotalPrice>

                        <S.FormatsSelected>{`/ ${t('monthly')}`}</S.FormatsSelected>
                      </>
                    ) : null}
                  </S.ProductTotalAndFormatsContainer>
                </S.TitleWrapper>

                <Row>
                  <Col md={12}>
                    <S.CampaignMetadataWrapper>
                      <S.FormSectionContainer>
                        <S.InputTitle>{t('campaignName')}</S.InputTitle>

                        <Controller
                          as={<S.InputTextField placeholder={t('giveYourCampaignAName')} />}
                          rules={{ required: t('defaultValidation', { inputName: t('campaignName') }) }}
                          name="name"
                          control={control}
                        />

                        <S.ErrorMessageContainer>
                          <ErrorMessage errors={errors} name="name" as="p" />
                        </S.ErrorMessageContainer>
                      </S.FormSectionContainer>

                      <S.FormSectionContainer>
                        <S.InputTitle>{t('campaignType')}</S.InputTitle>

                        <Select
                          className="select-campaign-type"
                          styles={CONST.reactSelectStyleDefault}
                          value={campaignModel}
                          options={campaignsOptionsByPackages}
                          placeholder={t('placeholderSelect')}
                          onChange={campaign => {
                            setCampaignModel(campaign)

                            setSelectedPackage('')
                            setProductsSelectedPayAsYouGo([])
                            setBlockedDates([])
                          }}
                        />
                      </S.FormSectionContainer>
                    </S.CampaignMetadataWrapper>
                  </Col>

                  <Col md={12}>
                    {campaignModel === null || isCampaignPayAsYouGo ? (
                      <>
                        <S.FormSectionPackageContainer>
                          <S.InputTitle>{t('selectPackagesWanted')}</S.InputTitle>
                        </S.FormSectionPackageContainer>

                        <PayAsYouGoForm
                          campaignDuration={campaignDuration}
                          paymentOptionState={paymentOptionState}
                          setCampaignDuration={setCampaignDuration}
                          control={control}
                          errors={errors}
                          blockedDates={blockedDates}
                          productsSelected={productsSelectedPayAsYouGo}
                          openModalAddProducts={() => setShowModalAddProducts(true)}
                          tutorialIsActive={tutorialIsActive}
                          onBlockedDayReport={sendMessageToCommunityToReportBlockedDay}
                        />
                      </>
                    ) : null}
                  </Col>

                  <Col md={12}>
                    {validPackages.length > 0 && isCampaignByPackage ? (
                      <>
                        <S.FormSectionPackageContainer>
                          <S.InputTitle>{t('selectAvailablePackages')}</S.InputTitle>
                        </S.FormSectionPackageContainer>

                        <SelectPackageForm
                          packages={validPackages}
                          setValue={setValue}
                          paymentOptionState={paymentOptionState}
                          control={control}
                          errors={errors}
                          blockedDates={blockedDates}
                          setBlockedDates={setBlockedDates}
                          setSelectedPackage={setSelectedPackage}
                          selectedPackage={selectedPackage}
                          setCampaignDuration={setCampaignDuration}
                          campaignDuration={campaignDuration}
                          tutorialIsActive={tutorialIsActive}
                          communityCurrencySymbol={communityCurrencySymbol}
                          onBlockedDayReport={sendMessageToCommunityToReportBlockedDay}
                        />
                      </>
                    ) : null}
                  </Col>

                  <Col md={12}>
                    {tutorialIsActive ? (
                      <S.ButtonContainer>
                        <S.ConfirmCampaignMockedButton>{t('startCampaign')}</S.ConfirmCampaignMockedButton>
                      </S.ButtonContainer>
                    ) : (
                      <S.ButtonContainer>
                        <S.ConfirmCampaignButton
                          isDisabled={loadingPaymentIntent}
                          text={t('startCampaign')}
                          loading={loadingPaymentIntent}
                        />
                      </S.ButtonContainer>
                    )}
                  </Col>

                  <Col md={12}>
                    {isCampaignPayAsYouGo ? (
                      <S.PayAsYouGoCampaignTotalContainer>
                        <S.CampaignTotal>{t('total')}</S.CampaignTotal>

                        <S.CampaignTotal>
                          {`${communityCurrencySymbol} ${currencyFormat(totalCampaignPrice)}`}
                        </S.CampaignTotal>
                      </S.PayAsYouGoCampaignTotalContainer>
                    ) : null}
                  </Col>

                  <Col md={12}>
                    {isCampaignByPackage && isCampaignDurationIsTheSameAsThePackage ? (
                      // Package have discount
                      <S.PackageCampaignTotalContainer>
                        <S.CampaignDiscountContainer>
                          <S.CampaignDiscount>{t('totalBeforeDiscount')}</S.CampaignDiscount>

                          <S.CampaignDiscount>
                            {` ${communityCurrencySymbol} ${currencyFormat(packageNormalPrice * campaignDuration)}`}
                          </S.CampaignDiscount>
                        </S.CampaignDiscountContainer>

                        <S.CampaignDiscountContainer>
                          <S.CampaignDiscount>{t('discount')}</S.CampaignDiscount>

                          <S.CampaignDiscount>
                            {`${communityCurrencySymbol} ${currencyFormat(
                              packageNormalPrice * campaignDuration - packagePriceWithDiscount * campaignDuration,
                            )}`}
                          </S.CampaignDiscount>
                        </S.CampaignDiscountContainer>

                        <S.CampaignDiscountTotalContainer>
                          <S.CampaignTotal>{t('total')}</S.CampaignTotal>

                          <S.CampaignTotal>
                            {`${communityCurrencySymbol}${calculateCampaignTotalPrice(
                              packagePriceWithDiscount,
                              campaignDuration,
                            )}`}
                          </S.CampaignTotal>
                        </S.CampaignDiscountTotalContainer>
                      </S.PackageCampaignTotalContainer>
                    ) : null}
                  </Col>

                  <Col md={12}>
                    {isCampaignByPackage && !isCampaignDurationIsTheSameAsThePackage ? (
                      // Package does not have discount
                      <S.PackageCampaignTotalContainer>
                        <S.CampaignDiscountTotalContainer>
                          <S.CampaignTotal>{t('total')}</S.CampaignTotal>

                          <S.CampaignTotal>
                            {`${communityCurrencySymbol}${calculateCampaignTotalPrice(
                              packageNormalPrice,
                              campaignDuration,
                            )}`}
                          </S.CampaignTotal>
                        </S.CampaignDiscountTotalContainer>
                      </S.PackageCampaignTotalContainer>
                    ) : null}
                  </Col>
                </Row>
              </S.Form>
            </S.FormContainer>
          </Col>
        </Row>

        {isCampaignPayAsYouGo ? (
          <ModalAddProductsPayAsYouGo
            showModal={showModalAddProducts}
            handleCloseModal={() => setShowModalAddProducts(false)}
            communityProducts={communityProducts}
            productsSelectedPayAsYouGo={productsSelectedPayAsYouGo}
            setProductsSelectedPayAsYouGo={setProductsSelectedPayAsYouGo}
            communityCurrencySymbol={communityCurrencySymbol}
            community={currentCommunityForCreateCampaignProccess()}
          />
        ) : null}

        <ModalCreditCardInformation
          handleCloseModal={onCloseCreditCardModal}
          showModal={showModalCreditCardInformation}
          stripeClientSecret={stripeClientSecretCreatedByPaymentIntent}
          validateAndCreateCampaign={validateAndCreateCampaign}
          paymentOption={paymentOption}
          sendMessageToCommunityAfterTheCampaignCreation={sendMessageToCommunityAfterTheCampaignCreation}
          handleCloseModalCreateCampaign={() => handleCloseModalCreateCampaign()}
          validateIfCampaignCanBeCreated={validateIfCampaignCanBeCreated}
        />

        {tutorialIsActive && showModalCreditCardMocked === false && (
          <CreateCampaignModalTooltipStep2 openCreditCardMockedModal={openCreditCardMockedModal} />
        )}
      </S.Container>
    )
  }
}
