import styled from 'styled-components'
import { IoBatteryHalfOutline } from 'react-icons/io5'
import { BiWifi } from 'react-icons/bi'
import { AiFillSignal } from 'react-icons/ai'
import { IoIosImages } from 'react-icons/io'
import { RiArrowRightSLine } from 'react-icons/ri'

export const CellphoneHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 6px 20px 0px 20px;
  height: 40px;
`

export const Hour = styled.p`
  color: var(--text-black);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
`

export const CellphoneInformationsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const BatteryIcon = styled(IoBatteryHalfOutline).attrs({
  size: 21,
  color: '#000',
})``

export const WifiIcon = styled(BiWifi).attrs({
  size: 21,
  color: '#000',
})``

export const SignalIcon = styled(AiFillSignal).attrs({
  size: 15,
  color: '#000',
})``

export const SlidingBannerContainer = styled.div``

export const NoMediaIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 113/54;
  background-color: #ccc;
  z-index: 1;
`

export const ImageIcon = styled(IoIosImages).attrs({
  size: 28,
  color: 'red',
})``

export const BannerContainer = styled.div`
  position: relative;
`

export const Banner = styled.img`
  z-index: 10;
  aspect-ratio: 113/54;
  width: 100%;
  object-fit: cover;
`

export const SlidingIconContainer = styled.div`
  display: flex;
  gap: 5px;
  position: absolute;
  margin: auto;
  bottom: 10px;
  right: 35%;
`

export const SlidingIcon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin: 10px 20px 0px 20px;
`

export const Content = styled.div`
  position: relative;
  height: 240px;
  width: 145px;
`

export const ContentTitleAndDescription = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
`

export const ContentTitle = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  text-shadow: 0px 1px 2px #000000;
`

export const ContentDescription = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  margin: 0px;
  text-shadow: 0px 1px 2px #000000;
`

export const ContentImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const ContentTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 0px 20px;
`

export const SectionTitle = styled.p`
  color: var(--text-black);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
`

export const CommunityMockupLogo = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  height: 30px;
  width: 30px;
  object-fit: cover;
  border-radius: 50px;
`

export const SeeMoreContainer = styled.div`
  display: flex;
  align-items: center;
`

export const SeeMore = styled.p`
  color: var(--text-black);
  background-color: var(--light-white);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
`

export const ArrowIcon = styled(RiArrowRightSLine).attrs({
  size: 22,
  color: '#000',
})`
  margin-left: 4px;
`
