import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import { toast } from 'react-toastify'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { useTranslation } from 'react-i18next'
import CampaignReviewProductsButtons from '~/components/Community/CampaignReviewProductsButtons'
import { getChatChannelAnalytics } from '~/services/requests'
import useChatFunctions from '~/helper/chatActions'
import { DefaultLogoImage } from '~/util/defaultImages'
import * as S from './styled'

export function ChatChannelReviewCard({ chatChannels, handleCloseModalAnalytics, singleContent, communityId }) {
  return (
    <Container fluid>
      <S.CardBody>
        <Row>
          <Col>
            <div className="icon-section">
              <img style={{ opacity: '.9' }} src="/icons/rewards.png" alt="Chat Channels" width="19" height="19" />
              <p className="ml-2 mt-2">Chat channel</p>
            </div>
          </Col>
        </Row>

        <Row className="mr-3 ml-3">
          {chatChannels?.map(chatChannel => (
            <ChatChannelCard
              key={chatChannel.id}
              chatChannelFirebaseId={chatChannel.firebaseChannelId}
              name={chatChannel.unity.name}
              chatChannelImage={chatChannel.unity.imageUrl}
              handleCloseModalAnalytics={handleCloseModalAnalytics}
              singleContent={singleContent}
              chatChannelAnalytics={chatChannel}
              communityId={communityId}
            />
          ))}
        </Row>
      </S.CardBody>
    </Container>
  )
}

export function ChatChannelCard({
  name,
  chatChannelImage,
  chatChannelFirebaseId,
  handleCloseModalAnalytics,
  singleContent,
  chatChannelAnalytics,
  communityId,
}) {
  const { t } = useTranslation()
  const { openCloseChatWindowAction, setActiveChannelAction } = useChatFunctions()
  const [chatChannel, setChatChannel] = useState(null)
  const [totalMessages, setTotalMessages] = useState(0)
  const [totalMembers, setTotalMembers] = useState(0)

  const handleOpenChatChannel = chatChannel => {
    if (chatChannel?.archived) {
      return toast.error(t('theChatChannelIsExpired', { date: chatChannelAnalytics?.dtEnd }))
    }
    handleCloseModalAnalytics()
    setActiveChannelAction(chatChannel)
    return openCloseChatWindowAction(chatChannelFirebaseId)
  }

  React.useEffect(() => {
    if (chatChannel) {
      if (chatChannel?.count) {
        setTotalMessages(chatChannel?.count?.totalMessages)
        setTotalMembers(chatChannel?.membersIds?.length)
      }
    }
  }, [chatChannel])

  React.useEffect(() => {
    getChatChannelAnalytics(chatChannelFirebaseId, setChatChannel, communityId)
  }, [chatChannelFirebaseId, communityId])

  return (
    <Col md={singleContent ? 9 : 6} className=" mt-4">
      <S.CardChatChannelBoddy>
        <Row>
          <Col className="mb-4">
            <p className="chat-title">{name}</p>
          </Col>
        </Row>

        <Row>
          <div className="chat-informations">
            <S.ContainerChannelImage>
              <S.ChannelImage src={chatChannelImage || DefaultLogoImage} alt="Chat channel" />
            </S.ContainerChannelImage>
            <div className="ml-2">
              <p className="chat-name">{name}</p>
              <div className="d-flex justify-content-center align-items-center">
                <p className="chat-sponsored">
                  <strong>{t('sponsored')}</strong>
                </p>
                <p className="chat-description ml-1">{t('reportIssues')}</p>
              </div>
            </div>
          </div>

          <S.ProductButtonsSection>
            <CampaignReviewProductsButtons
              style={{ marginLeft: '10px' }}
              text={t('messagesLabel')}
              number={totalMessages}
              color="#FC930E"
              icon={<img src="/icons/campaignReview/comments.png" alt="Messages" width="24" height="24" />}
            />
            <CampaignReviewProductsButtons
              style={{ marginLeft: '10px' }}
              text={t('members')}
              number={totalMembers}
              color="#35CC9E"
              icon={<img src="/icons/campaignReview/linkview.png" alt="Members" width="24" height="24" />}
            />
          </S.ProductButtonsSection>
          <S.OpenChatButton expired={chatChannel?.archived} onClick={() => handleOpenChatChannel(chatChannel)}>
            {t('openChat')}
          </S.OpenChatButton>
        </Row>
      </S.CardChatChannelBoddy>
    </Col>
  )
}
