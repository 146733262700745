/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import Row from 'react-bootstrap/Row'
import { useTranslation } from 'react-i18next'
import Slider from '@material-ui/core/Slider'
import Cropper from 'react-easy-crop'
import Col from 'react-bootstrap/Col'
import { DefaultUserProfileImage } from '~/util/defaultImages'
import { modalBodyStyleDefault } from '~/helper/const'
import { base64ImageToBlob, uploadUserProfileImageS3 } from '~/helper/formFunctions'
import Dialog from '~/components/Shared/Dialog'
import getCroppedImg from './cropImage'
import * as S from './styled'

export default function ModalCropUseProfileImage({
  handleCloseModal,
  showModal,
  userProfileInformations,
  setUserProfileImageUrl,
  setUserProfileImageId,
}) {
  const { t } = useTranslation()
  const { userImageUrl } = userProfileInformations
  const [userProfileImage, setUserProfileImage] = useState(userImageUrl)
  const [openDialog, setOpenDialog] = useState(false)
  const [openDialogDeletePhoto, setOpenDialogDeletePhoto] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const inputFile = useRef(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const getCroppedImage = useCallback(async () => {
    if (userProfileImage === null) {
      const imageToSend = base64ImageToBlob(DefaultUserProfileImage)
      uploadUserProfileImageS3(setUserProfileImageId, setUserProfileImageUrl, imageToSend)
      handleCloseModal()
    } else {
      try {
        const croppedImage = await getCroppedImg(userProfileImage, croppedAreaPixels, rotation)
        setCroppedImage(croppedImage)
        const image = base64ImageToBlob(croppedImage)
        uploadUserProfileImageS3(setUserProfileImageId, setUserProfileImageUrl, image)
        handleCloseModal()
      } catch (e) {
        toast.error(t('errorUpdatingImage'))
      }
    }
  }, [croppedAreaPixels, rotation, userProfileImage])

  const clickToCloseModal = () => {
    handleCloseModal()
  }

  const onBackButton = () => {
    setOpenDialog(false)
    handleCloseModal()
  }

  const deletePhoto = () => {
    setCroppedImage(null)
    setOpenDialogDeletePhoto(false)
    setUserProfileImageUrl(null)
    setUserProfileImageId(null)
    setUserProfileImage(null)
  }

  const changeFile = event => {
    event.stopPropagation()
    event.preventDefault()
    const reader = new FileReader()
    reader.onload = e => {
      setUserProfileImage(e.target.result)
    }
    reader.readAsDataURL(event.target.files[0])
  }

  const onButtonClick = () => {
    inputFile.current.click()
  }

  return (
    <S.ModalCustom show={showModal} onHide={clickToCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.BackIcon onClick={() => setOpenDialog(true)} />
          <Dialog
            openDialog={openDialog}
            closeDialog={() => setOpenDialog(false)}
            dialogTitle={t('attention')}
            dialogText={t('discardUnsavedChanges')}
            cancelAction={() => setOpenDialog(false)}
            confirmAction={() => onBackButton()}
          />
          <Dialog
            openDialog={openDialogDeletePhoto}
            closeDialog={() => setOpenDialogDeletePhoto(false)}
            dialogTitle={t('attention')}
            dialogText={t('cofirmPhotoDeletion')}
            cancelAction={() => setOpenDialogDeletePhoto(false)}
            confirmAction={() => deletePhoto()}
          />
          <S.Title>{t('userProfilePhoto')}</S.Title>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={modalBodyStyleDefault}>
        <Row>
          <Col md={12}>
            {userProfileImage == null && croppedImage == null ? (
              <S.NoImage>
                <S.NoImageIndicatorIcon />
              </S.NoImage>
            ) : (
              <>
                <S.CropContainerParent>
                  <S.CropContainer>
                    <Cropper
                      image={userProfileImage || DefaultUserProfileImage}
                      crop={crop}
                      zoom={zoom}
                      rotation={rotation}
                      aspect={1}
                      showGrid={false}
                      onCropChange={setCrop}
                      onRotationChange={setRotation}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      cropShape="round"
                    />
                  </S.CropContainer>
                </S.CropContainerParent>
                <div className="controls mt-3 d-flex">
                  <Col md={6}>
                    <span>{t('zoom')}</span>
                    <Slider value={zoom} min={1} max={3} step={0.1} onChange={(e, zoom) => setZoom(zoom)} />
                  </Col>
                  <Col md={6}>
                    <span>{t('rotate')}</span>
                    <Slider
                      value={rotation}
                      min={0}
                      max={360}
                      step={1}
                      onChange={(e, rotation) => setRotation(rotation)}
                    />
                  </Col>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>

      <S.ModalFooter>
        <div>
          <S.DeletePhoto onClick={() => setOpenDialogDeletePhoto(true)}>{t('deletePhoto')}</S.DeletePhoto>
        </div>
        <div>
          <S.ChangePhotoButton text={t('changePhoto')} action={onButtonClick} />
          <S.InputChangePhoto ref={inputFile} onChange={changeFile} />
          <S.SavePhotoButton action={getCroppedImage} text={t('savePhoto')} />
        </div>
      </S.ModalFooter>
    </S.ModalCustom>
  )
}
