import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import WhiteButton from '~/components/WhiteButton'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    width: 50%;
    height: 50%;
    margin: 0;
    padding: 0;

    @media (min-width: 576px) {
      max-width: 850px;
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    p {
      margin: 0;
    }
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
    height: 80px !important;
  }

  textarea:disabled {
    height: 78% !important;
  }
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin-bottom: 4px;
  padding-top: 0px !important;
`

export const InputContainer = styled(Row)`
  margin-top: ${props => (props.spacing ? '24px' : '0px')};
  align-items: flex-end;
`

export const InputLabel = styled.span`
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
`

export const InputSubtitle = styled.span`
  color: var(--grey);
  font-family: Roboto;
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
`

export const FormContainer = styled.div`
  margin-top: 10px;
  .error-message {
    font-family: Roboto;
    color: #ea343e;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.9;
  }

  .headquarter-address-section {
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      height: 40px;
      padding-right: 45px;
      box-sizing: border-box;
      background: url('google-logo.png') no-repeat 98%;
      background-size: 26px;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #c4c4c4 !important;

      padding-left: 10px;
      font-family: Lato;
      font-size: 16px;
      font-weight: 500;
      line-height: 14px;
    }
  }
`

export const Title = styled.p`
  color: var(--text-bold);
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
`

export const NotNow = styled.p`
  color: #969faa;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

export const BannerContainer = styled.div`
  position: relative;
  text-align: center;
`

export const ProductBackground = styled.div`
  width: 100%;
  height: 170px;
  background-color: #9671f5;
`

export const InvitationTextContainer = styled.div`
  text-transform: uppercase;
  position: absolute;
  top: 60px;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 46px;
  font-weight: 600;
  line-height: 59px;
  width: 100%;
`

export const CustomFormatText = styled.span``

export const InputIsRequired = styled.span`
  color: var(--community-primary);
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-left: 4px;
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const ErrorMessageContainer = styled.div.attrs({
  className: 'error-message',
})``

export const SaveButton = styled(WhiteButton).attrs({
  large: true,
  form: 'my-form',
  type: 'submit',
  style: { marginTop: '20px' },
})``
