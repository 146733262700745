import React from 'react'

import * as S from './styles'

export const Button = ({ label, variant = 'primary', disabled, type, onClick }) => {
  return (
    <S.ButtonContainer disabled={disabled} variant={variant} type={type} onClick={onClick}>
      <span>{label}</span>
    </S.ButtonContainer>
  )
}
