import React, { useState, useEffect, useCallback, useMemo } from 'react'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AnalyticsKpiCard from '~/components/Shared/AnalyticsKpiCard'
import TabTitleAndSubtitle from '~/components/Community/TabTitleAndSubtitle'
import AnalyticsKpiProgressBar from '~/components/Shared/AnalyticsKpiProgressBar'
import { reactSelectStyleDefault } from '~/helper/const'
import { checkNumberForPositiveAndNegative } from '~/helper/helperFunctions'
import AnalyticsPieChart from '~/components/Shared/AnalyticsPieChart'
import * as KPI from '~/services/requests'
import * as S from './styled'

export default function AnalyticsTab() {
  const { t } = useTranslation()

  const analyticsKpiDaysSelect = useMemo(
    () => [
      { value: 15, label: `15 ${t('days')}` },
      { value: 30, label: `30 ${t('days')}` },
      { value: 45, label: `45 ${t('days')}` },
    ],
    [t],
  )

  const communityId = useSelector(state => state.community.currentCommunity.id)
  const communityCurrency = useSelector(state => state.community.currentCommunity.currency)
  const [followUpSelect, setFollowUpSelect] = useState(analyticsKpiDaysSelect[0])
  const [sponsorRankingSelect, setSponsorRankingSelect] = useState(analyticsKpiDaysSelect[0])
  const [popularFormatsSelect, setPopularFormatsSelect] = useState(analyticsKpiDaysSelect[0])
  const [mostViewedFormatsSelect, setMostViewedFormatsSelect] = useState(analyticsKpiDaysSelect[0])
  const [kpiFollowUpData, setKpiFollowUpData] = useState([])
  const [kpiSponsorRankingData, setKpiSponsorRankingData] = useState([])
  const [kpiPopularFormatsData, setKpiPopularFormatsData] = useState([])
  const [kpiMostViewedFormatsData, setKpiMostViewedFormatsData] = useState([])
  // const [kpiMostViewedFormatCallback, setKpiMostViewedFormatCallback] = useState(null)

  const getFollowUpAnalytics = useCallback(async () => {
    const response = await KPI.getFollowUpAnalyticsKpi(followUpSelect, communityId, 'community')
    const currencyArray = response?.data.find(data => data[communityCurrency])
    if (communityCurrency === 'brl') {
      setKpiFollowUpData(currencyArray.brl)
    }
    if (communityCurrency === 'usd') {
      setKpiFollowUpData(currencyArray.usd)
    }
    if (communityCurrency === 'eur') {
      setKpiFollowUpData(currencyArray.eur)
    }
  }, [followUpSelect, communityId, communityCurrency])

  const getSponsorRankingAnalytics = useCallback(async () => {
    const response = await KPI.getCommunityRankingAnalyticsKpi(sponsorRankingSelect, communityId, 'community')
    setKpiSponsorRankingData(response?.data)
  }, [sponsorRankingSelect, communityId])

  const getPopularFormatsAnalytics = useCallback(async () => {
    const response = await KPI.getPopularFormatsAnalyticsKpi(popularFormatsSelect, communityId, 'community')
    setKpiPopularFormatsData(response?.data)
  }, [popularFormatsSelect, communityId])

  const getMostViewedAnalytics = useCallback(async () => {
    const response = await KPI.getMostViewedFormatsAnalyticsKpi(mostViewedFormatsSelect, communityId, 'community')
    setKpiMostViewedFormatsData(response?.data)
    // setKpiMostViewedFormatCallback(Math.random())
  }, [mostViewedFormatsSelect, communityId])

  useEffect(() => {
    getFollowUpAnalytics()
  }, [getFollowUpAnalytics, followUpSelect])

  useEffect(() => {
    getSponsorRankingAnalytics()
  }, [getSponsorRankingAnalytics, sponsorRankingSelect])

  useEffect(() => {
    getPopularFormatsAnalytics()
  }, [getPopularFormatsAnalytics, popularFormatsSelect])

  useEffect(() => {
    getMostViewedAnalytics()
  }, [getMostViewedAnalytics, mostViewedFormatsSelect])

  useEffect(() => {
    setSponsorRankingSelect(analyticsKpiDaysSelect[0])
    setPopularFormatsSelect(analyticsKpiDaysSelect[0])
    setFollowUpSelect(analyticsKpiDaysSelect[0])
    setMostViewedFormatsSelect(analyticsKpiDaysSelect[0])
  }, [analyticsKpiDaysSelect])

  return (
    <S.Container>
      <TabTitleAndSubtitle title={t('communityKpis')} subtitle={t('checkHowYourCommunity')} />
      <S.CustomRow>
        <S.KpiCol>
          <S.KpiTitleContainer>
            <S.KpiTitle>{t('followUp')}</S.KpiTitle>
            <S.SelectContainer>
              <Select
                className="kpi-follow-up-select"
                styles={reactSelectStyleDefault}
                options={analyticsKpiDaysSelect}
                value={followUpSelect}
                onChange={val => {
                  setFollowUpSelect(val)
                }}
              />
            </S.SelectContainer>
          </S.KpiTitleContainer>

          <S.AnalyticsKpiCardsContainer>
            {kpiFollowUpData?.map(kpiData => (
              <AnalyticsKpiCard
                key={kpiData.product_type}
                percentage={kpiData.percentage}
                value={kpiData.current_sub_total}
                previousValue={kpiData.previous_sub_total}
                title={kpiData.product_type}
                status={checkNumberForPositiveAndNegative(kpiData.percentage)}
                days={followUpSelect.label}
                communityCurrency={communityCurrency}
              />
            ))}
          </S.AnalyticsKpiCardsContainer>
        </S.KpiCol>

        <S.KpiCol>
          <S.KpiTitleContainer>
            <S.KpiTitle>{t('sponsorRanking')}</S.KpiTitle>
            <S.SelectContainer>
              <Select
                className="kpi-ranking-select"
                styles={reactSelectStyleDefault}
                options={analyticsKpiDaysSelect}
                value={sponsorRankingSelect}
                onChange={val => {
                  setSponsorRankingSelect(val)
                }}
              />
            </S.SelectContainer>
          </S.KpiTitleContainer>

          {kpiSponsorRankingData.length === 0 ? (
            <S.ZeroRegisterContainer>
              <S.ZeroRegisterText>{t('yourCommunityDoesntHaveSponsors')}</S.ZeroRegisterText>
            </S.ZeroRegisterContainer>
          ) : (
            <S.KpiContainer>
              {kpiSponsorRankingData?.map(sponsorRaking => (
                <AnalyticsKpiProgressBar
                  key={sponsorRaking.id}
                  percentage={sponsorRaking.percentage}
                  total={sponsorRaking.sub_total}
                  title={sponsorRaking.name}
                  communityCurrency={communityCurrency}
                  budget
                />
              ))}
            </S.KpiContainer>
          )}
        </S.KpiCol>
      </S.CustomRow>

      <S.CustomRow spacing>
        <S.KpiCol>
          <S.KpiTitleContainer>
            <S.KpiTitle>{t('mostPurchasedFormats')}</S.KpiTitle>
            <S.SelectContainer>
              <Select
                className="kpi-formats-select"
                styles={reactSelectStyleDefault}
                options={analyticsKpiDaysSelect}
                value={popularFormatsSelect}
                onChange={val => {
                  setPopularFormatsSelect(val)
                }}
              />
            </S.SelectContainer>
          </S.KpiTitleContainer>

          {kpiPopularFormatsData.length === 0 ? (
            <S.ZeroRegisterContainer>
              <S.ZeroRegisterText>{t('noFormatsAvailable')}</S.ZeroRegisterText>
            </S.ZeroRegisterContainer>
          ) : (
            <S.KpiContainer>
              {kpiPopularFormatsData?.map(popularFormat => (
                <AnalyticsKpiProgressBar
                  key={popularFormat.product_type}
                  percentage={popularFormat.percentage}
                  total={popularFormat.sub_total}
                  title={t(popularFormat.product_type)}
                />
              ))}
            </S.KpiContainer>
          )}
        </S.KpiCol>

        <S.ChartCol>
          <S.KpiTitleContainer>
            <S.KpiTitle>{t('mostPopularFormats')}</S.KpiTitle>
            <S.SelectContainer>
              <Select
                className="kpi-most-viewed-select"
                styles={reactSelectStyleDefault}
                options={analyticsKpiDaysSelect}
                value={mostViewedFormatsSelect}
                onChange={val => {
                  setMostViewedFormatsSelect(val)
                }}
              />
            </S.SelectContainer>
          </S.KpiTitleContainer>
          <S.ChartContainer>
            <AnalyticsPieChart chartData={kpiMostViewedFormatsData} mostViewedFormatsSelect={mostViewedFormatsSelect} />
          </S.ChartContainer>
        </S.ChartCol>
      </S.CustomRow>
    </S.Container>
  )
}
