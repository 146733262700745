import React, { useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { MdEdit, MdTimeline } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import ShouldRender from '~/components/ShouldRender'
import { formatProductDate } from '~/helper/getFormattedDates'
import { getFormatAnaytics } from '~/services/requests'
import ModalEditWelcomeAd from '~/components/Sponsor/Modals/WelcomeAD/ModalEditWelcomeAd'
import ModalEditChatChannel from '~/components/Sponsor/Modals/ChatChannel/ModalEditChatChannel'
import ModalEditEvent from '~/components/Sponsor/Modals/Event/ModalEditEvent'
import ModalEditReward from '~/components/Sponsor/Modals/Reward/ModalEditReward'
import ModalEditPost from '~/components/Sponsor/Modals/Post/ModalEditPost'
import ModalEditVideo from '~/components/Sponsor/Modals/Video/ModalEditVideo'
import ModalEditWeb from '~/components/Sponsor/Modals/Webpage/ModalEditWeb'
import ModalEditDocument from '~/components/Sponsor/Modals/Document/ModalEditDocument'
import ModalSingleFormatAnalytics from '~/components/Modals/ModalSingleFormatAnalytics'
import { ModalEditSurveyFirstStep } from '~/components/Sponsor/Modals/Survey/ModalEditSurveyFirstStep'
import ModalEditSocialMediaPostFormat from '~/components/SocialMedia/ModalEditSocialMediaPostFormat'
import ModalEditCommunityStory from '~/components/Sponsor/Modals/CommunityStories/ModalEditCommunityStory'
import ModalEditStoriesBottomBanner from '~/components/Sponsor/Modals/StoriesBottomBanner/ModalEditStoriesBottomBanner'
import ModalEditSlidingTopBanner from '~/components/Sponsor/Modals/SlidingTopBanner/ModalEditSlidingTopBanner'
import ModalEditScratchCard from '~/components/Sponsor/Modals/ScratchCard/ModalEditScratchCard'
import { analyticsNotAvailable, checkIfTheFormatIsASocialMediaPost } from '~/helper/helperFunctions'
import GenericPopover from '~/components/Shared/GenericPopover'
import FormatShareButton from '~/components/FormatShareButton'
import * as FN from '~/helper/formFunctions'
import * as S from './styled'

export default function CampaignReviewTabCard({ formatUnity, campaignFormat, campaign }) {
  const { t } = useTranslation()
  const { slug: formatSlug, productType, category } = campaignFormat.product
  const { status: formatUnityStatus, contentType, contentId, syncronized } = formatUnity
  const formatStatus = FN.getFormatStatus(formatUnityStatus)
  const [analyticsData, setAnalyticsData] = useState(null)
  const [loadingAnalytics, setLoadingAnalytics] = useState(false)
  const [showModalEditWelcomeAd, setShowModalEditWelcomeAd] = useState(false)
  const [showModalEditChatChannel, setShowModalEditChatChannel] = useState(false)
  const [showModalEditReward, setShowModalEditReward] = useState(false)
  const [showModalEditEvent, setShowModalEditEvent] = useState(false)
  const [showModalEditPost, setShowModalEditPost] = useState(false)
  const [showModalEditVideo, setShowModalEditVideo] = useState(false)
  const [showModalEditWeb, setShowModalEditWeb] = useState(false)
  const [showModalEditDocument, setShowModalEditDocument] = useState(false)
  const [showModalEditSurvey, setShowModalEditSurvey] = useState(false)
  const [review, setShowReview] = useState(false)
  const [showModalFormatAnalytics, setShowModalFormatAnalytics] = useState(false)
  const [showModalEditSocialMediaFormat, setShowModalEditSocialMediaFormat] = useState(false)
  const [showModalEditCommunityStory, setShowModalEditCommunityStory] = useState(false)
  const [showModalEditStoriesBottomBanner, setShowModalEditStoriesBottomBanner] = useState(false)
  const [showModalEditSlidingTopBanner, setShowModalEditSlidingTopBanner] = useState(false)
  const [showModalEditScratchCard, setShowModalEditScratchCard] = useState(false)
  const [popoverAnchor, setPopoverAnchor] = useState(null)

  const handleClickActionsPopover = event => {
    setPopoverAnchor(event.currentTarget)
  }

  const handleClickEditButton = () => {
    setPopoverAnchor(null)

    if (formatSlug === 'welcomeAd') {
      setShowModalEditWelcomeAd(true)
    }
    if (formatSlug === 'scratchCard') {
      setShowModalEditScratchCard(true)
    }
    if (formatSlug === 'chatChannel') {
      setShowModalEditChatChannel(true)
    }
    if (formatSlug === 'event') {
      setShowModalEditEvent(true)
    }
    if (formatSlug === 'reward') {
      setShowModalEditReward(true)
    }
    if (formatSlug === 'survey') {
      setShowModalEditSurvey(true)
    }
    if (formatSlug === 'communityStory') {
      setShowModalEditCommunityStory(true)
    }
    if (formatSlug === 'bottomStoriesBanner') {
      setShowModalEditStoriesBottomBanner(true)
    }
    if (formatSlug === 'slidingTopBanner') {
      setShowModalEditSlidingTopBanner(true)
    }
    if (contentType === 'post') {
      setShowModalEditPost(true)
    }
    if (contentType === 'video') {
      setShowModalEditVideo(true)
    }
    if (contentType === 'web') {
      setShowModalEditWeb(true)
    }
    if (contentType === 'document') {
      setShowModalEditDocument(true)
    }
    if (checkIfTheFormatIsASocialMediaPost(productType)) {
      setShowModalEditSocialMediaFormat(true)
    }
  }

  const handleClickEditButtonAndSetReviewToTrue = () => {
    setShowReview(true)
    handleClickEditButton()
    setPopoverAnchor(null)
  }

  const handleClickAnalytics = useCallback(async () => {
    setPopoverAnchor(null)

    const response = await getFormatAnaytics(campaign?.id, formatUnity?.id, setLoadingAnalytics)
    setAnalyticsData(response.data)

    if (analyticsNotAvailable(response.data)) {
      setLoadingAnalytics(false)
      toast.error(t('noDataAvailable'))
    } else {
      setLoadingAnalytics(false)
      setShowModalFormatAnalytics(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.id, formatUnity?.id])

  const isStatus = useCallback(status => formatUnityStatus === status, [formatUnityStatus])

  const contentNeedsSync = ['content', 'communityStory']

  const showEditButton = () => {
    if (contentNeedsSync.includes(productType)) {
      return isStatus('revision-needed') || (isStatus('running') && syncronized)
    }
    return isStatus('revision-needed') || isStatus('running')
  }

  return (
    <S.Container>
      <S.ProductTitleColumn>
        <S.FormatTitle>{FN.getFormatUnityTitle(formatUnity, campaignFormat)}</S.FormatTitle>
      </S.ProductTitleColumn>
      <S.ProductTypeColumn>
        <S.ProductTitle>{FN.getFormatType(formatSlug)}</S.ProductTitle>
      </S.ProductTypeColumn>
      <S.ProductTimingColumn>
        {checkIfTheFormatIsASocialMediaPost(productType) ? (
          '-'
        ) : (
          <>
            <S.DateContainer>
              <S.DateLabel>{t('start')}</S.DateLabel>
              <S.Date>{formatProductDate(formatUnity?.unity?.date)}</S.Date>
            </S.DateContainer>
            <S.DateContainer>
              <S.DateLabel>{t('end')}</S.DateLabel>
              <S.Date>{formatProductDate(formatUnity?.unity?.endDate)}</S.Date>
            </S.DateContainer>
          </>
        )}
      </S.ProductTimingColumn>
      <S.ProductRevisionColumn>
        {formatUnityStatus === 'waiting-approval' && <S.ProductDotIndicator color="#FFBA5C" />}
        {formatUnityStatus === 'running' && <S.ProductDotIndicator color="#1FB32E" />}
        {formatUnityStatus === 'revision-needed' && <S.ProductDotIndicator color="#F74657" />}
        {formatUnityStatus === 'finalized' && <S.ProductDotIndicator color="#8A8E93" />}
        <S.ProductStatus>{formatStatus}</S.ProductStatus>
      </S.ProductRevisionColumn>

      <GenericPopover
        popoverAnchor={popoverAnchor}
        setPopoverAnchor={setPopoverAnchor}
        content={
          <S.ProductActionsColumn>
            {formatUnityStatus === 'waiting-approval' && (
              <S.ActionContainer>
                <S.CheckInfoButton
                  text={t('checkInfo')}
                  noShadow
                  onClick={() => handleClickEditButtonAndSetReviewToTrue()}
                />
              </S.ActionContainer>
            )}

            <ShouldRender if={showEditButton()}>
              <S.ActionContainer>
                <S.EditButton
                  text={t('update')}
                  onClick={() => handleClickEditButton()}
                  icon={<MdEdit color="#0041C2" size={20} />}
                />
              </S.ActionContainer>
            </ShouldRender>

            {formatUnityStatus === 'running' && productType !== 'custom' && category?.name !== 'Social' && (
              <S.ActionContainer>
                <S.CheckAnalyticsButton
                  loading={loadingAnalytics}
                  onClick={handleClickAnalytics}
                  icon={<MdTimeline color="#976DD0" size={20} />}
                  text={t('analytics')}
                />
              </S.ActionContainer>
            )}
            {formatUnityStatus === 'finalized' && productType !== 'custom' && category?.name !== 'Social' && (
              <S.CheckAnalyticsButton
                loading={loadingAnalytics}
                onClick={handleClickAnalytics}
                icon={<MdTimeline color="#976DD0" size={20} />}
                text={t('analytics')}
              />
            )}
            {formatUnityStatus === 'running' && productType === 'custom' && (
              <S.ActionWaitingForReview>-</S.ActionWaitingForReview>
            )}
            {formatUnityStatus === 'finalized' && productType === 'custom' && (
              <S.ActionWaitingForReview>-</S.ActionWaitingForReview>
            )}
            {category?.name === 'Social' &&
              formatUnityStatus !== 'waiting-approval' &&
              formatUnityStatus !== 'revision-needed' && <S.ActionWaitingForReview>-</S.ActionWaitingForReview>}

            <FormatShareButton
              contentId={contentId}
              closePopover={() => setPopoverAnchor(null)}
              productType={productType}
              productStatus={formatUnityStatus}
              communityId={campaign?.networkId}
            />
          </S.ProductActionsColumn>
        }
      />

      <S.ActionsContainer>
        <S.ActionOptions onClick={handleClickActionsPopover} />
      </S.ActionsContainer>

      <ModalEditWelcomeAd
        handleCloseModal={() => setShowModalEditWelcomeAd(false)}
        showModal={showModalEditWelcomeAd}
        welcomeAdToUpdate={formatUnity?.unity}
        formatId={formatUnity?.id}
        review={review}
        sponsor
      />
      <ModalEditScratchCard
        handleCloseModal={() => setShowModalEditScratchCard(false)}
        showModal={showModalEditScratchCard}
        scratchCardToUpdate={formatUnity?.unity}
        formatId={formatUnity?.id}
        review={review}
        sponsor
      />
      <ModalEditChatChannel
        handleCloseModal={() => setShowModalEditChatChannel(false)}
        showModal={showModalEditChatChannel}
        chatChannelToUpdate={formatUnity?.unity}
        formatId={formatUnity?.id}
        review={review}
        sponsor
      />
      <ModalEditEvent
        handleCloseModal={() => setShowModalEditEvent(false)}
        showModal={showModalEditEvent}
        eventToUpdate={formatUnity?.unity}
        formatId={formatUnity?.id}
        review={review}
        sponsor
      />
      <ModalEditReward
        handleCloseModal={() => setShowModalEditReward(false)}
        showModal={showModalEditReward}
        rewardToUpdate={formatUnity?.unity}
        formatId={formatUnity?.id}
        review={review}
        sponsor
      />
      <ModalEditPost
        handleCloseModal={() => setShowModalEditPost(false)}
        showModal={showModalEditPost}
        postToUpdate={formatUnity?.unity}
        formatId={formatUnity?.id}
        review={review}
        sponsor
      />
      <ModalEditWeb
        handleCloseModal={() => setShowModalEditWeb(false)}
        showModal={showModalEditWeb}
        webToUpdate={formatUnity?.unity}
        formatId={formatUnity?.id}
        review={review}
        sponsor
      />
      <ModalEditVideo
        handleCloseModal={() => setShowModalEditVideo(false)}
        showModal={showModalEditVideo}
        videoToUpdate={formatUnity?.unity}
        formatId={formatUnity?.id}
        review={review}
        sponsor
      />
      <ModalEditDocument
        handleCloseModal={() => setShowModalEditDocument(false)}
        showModal={showModalEditDocument}
        documentToUpdate={formatUnity?.unity}
        formatId={formatUnity?.id}
        review={review}
        sponsor
      />
      <ModalEditSurveyFirstStep
        handleCloseModal={() => setShowModalEditSurvey(false)}
        showModal={showModalEditSurvey}
        formatId={formatUnity?.id}
        surveys={formatUnity?.unity}
        review={review}
        sponsor
      />
      <ModalEditSocialMediaPostFormat
        sponsor
        review={review}
        handleCloseModal={() => setShowModalEditSocialMediaFormat(false)}
        showModal={showModalEditSocialMediaFormat}
        community={campaign?.community}
        formatToUpdate={formatUnity?.unity}
        socialMediaFormat={campaignFormat?.product}
        formatId={formatUnity?.id}
      />
      <ModalEditCommunityStory
        sponsor
        review={review}
        handleCloseModal={() => setShowModalEditCommunityStory(false)}
        showModal={showModalEditCommunityStory}
        formatToUpdate={formatUnity?.unity}
        socialMediaFormat={campaignFormat?.product}
        formatId={formatUnity?.id}
      />
      <ModalEditStoriesBottomBanner
        sponsor
        review={review}
        handleCloseModal={() => setShowModalEditStoriesBottomBanner(false)}
        showModal={showModalEditStoriesBottomBanner}
        formatToUpdate={formatUnity?.unity}
        socialMediaFormat={campaignFormat?.product}
        formatId={formatUnity?.id}
      />
      <ModalEditSlidingTopBanner
        sponsor
        review={review}
        handleCloseModal={() => setShowModalEditSlidingTopBanner(false)}
        showModal={showModalEditSlidingTopBanner}
        formatToUpdate={formatUnity?.unity}
        socialMediaFormat={campaignFormat?.product}
        formatId={formatUnity?.id}
      />
      <ModalSingleFormatAnalytics
        handleCloseModal={() => setShowModalFormatAnalytics(false)}
        showModal={showModalFormatAnalytics}
        campaign={campaign}
        analyticsData={analyticsData}
      />
    </S.Container>
  )
}
