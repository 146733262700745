import styled, { css } from 'styled-components'

export const ButtonContainer = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;

  cursor: pointer;
  overflow: hidden;

  transition: all 0.2s ease-in-out;

  border-radius: 20px;

  span {
    z-index: 1;
  }

  &::before {
    content: '';
    display: block;

    position: absolute;
    top: 50%;
    left: 0;

    height: 0;
    width: 100%;

    opacity: 0;

    transition: all 0.1s ease-in-out;

    transform: translateY(-50%);
    z-index: 0;
  }

  &:hover {
    &::before {
      height: 100%;
      opacity: 1;
    }
  }

  ${({ variant }) => {
    if (variant === 'primary')
      return css`
        background-color: #01a2ff;
        border: 2px solid transparent;

        span {
          color: white;
        }
      `

    if (variant === 'secondary')
      return css`
        background-color: transparent;
        border: 2px solid #01a2ff;

        span {
          color: #01a2ff;
        }
      `
  }}
`
