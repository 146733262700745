import React from 'react'
import './fileIcons.scss'

const doc = (
  <svg
    className="file-icon"
    width="170"
    height="170"
    viewBox="0 0 170 170"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M105.517 16H43.1593C35.3677 16 29.0635 22.375 29.0635 30.1667L28.9927 143.5C28.9927 151.292 35.3677 154.043 43.1593 154.043H127.83C135.622 154.043 142.326 151.292 142.326 143.5V58.5L105.517 16ZM133.101 142.841L38.8764 143.5V26.2132H99.6552L133.101 58.5V142.841Z"
      fill="white"
    />
    <path d="M99.6552 58.5V26.2132L133.101 58.5H99.6552Z" fill="white" />
    <path
      d="M55.1752 103V79.5392H62.3939C64.4564 79.5392 66.2987 80.0065 67.9208 80.9411C69.5536 81.8649 70.8265 83.1862 71.7396 84.9049C72.6527 86.6129 73.1092 88.5573 73.1092 90.7379V91.8175C73.1092 93.9982 72.6581 95.9371 71.7557 97.6344C70.8641 99.3317 69.6019 100.648 67.9691 101.582C66.3363 102.517 64.494 102.989 62.4423 103H55.1752ZM60.0091 83.4547V99.1168H62.3456C64.2362 99.1168 65.681 98.4992 66.68 97.2638C67.6791 96.0285 68.1893 94.2614 68.2108 91.9625V90.7218C68.2108 88.3371 67.7167 86.5324 66.7284 85.3078C65.7401 84.0724 64.2953 83.4547 62.3939 83.4547H60.0091Z"
      fill="white"
    />
    <path
      d="M95.9095 91.8014C95.9095 94.111 95.5013 96.1359 94.6849 97.8761C93.8685 99.6163 92.6976 100.959 91.1722 101.904C89.6576 102.85 87.9174 103.322 85.9515 103.322C84.0072 103.322 82.2723 102.855 80.7469 101.921C79.2215 100.986 78.0399 99.6539 77.202 97.9245C76.3641 96.1842 75.9398 94.1862 75.9291 91.9303V90.7702C75.9291 88.4606 76.3426 86.4303 77.1698 84.6793C78.0077 82.9176 79.184 81.5695 80.6986 80.6349C82.224 79.6896 83.9642 79.2169 85.9193 79.2169C87.8744 79.2169 89.6092 79.6896 91.1239 80.6349C92.6493 81.5695 93.8256 82.9176 94.6527 84.6793C95.4906 86.4303 95.9095 88.4552 95.9095 90.754V91.8014ZM91.0111 90.7379C91.0111 88.278 90.5707 86.4088 89.6898 85.1305C88.809 83.8522 87.5521 83.213 85.9193 83.213C84.2972 83.213 83.0458 83.8468 82.1649 85.1144C81.284 86.3712 80.8382 88.2189 80.8275 90.6574V91.8014C80.8275 94.1969 81.2679 96.0553 82.1488 97.3766C83.0297 98.6979 84.2972 99.3585 85.9515 99.3585C87.5736 99.3585 88.8197 98.7247 89.6898 97.4572C90.5599 96.1788 91.0004 94.3204 91.0111 91.882V90.7379Z"
      fill="white"
    />
    <path
      d="M117.84 95.1852C117.657 97.7096 116.723 99.6969 115.036 101.147C113.36 102.597 111.147 103.322 108.397 103.322C105.39 103.322 103.021 102.313 101.291 100.293C99.5726 98.2628 98.7132 95.4806 98.7132 91.9464V90.5123C98.7132 88.2565 99.1107 86.2692 99.9056 84.5504C100.701 82.8317 101.834 81.5158 103.306 80.6027C104.788 79.6788 106.507 79.2169 108.462 79.2169C111.169 79.2169 113.349 79.942 115.004 81.3922C116.658 82.8424 117.614 84.8781 117.872 87.4992H113.038C112.92 85.9845 112.495 84.8888 111.765 84.2121C111.045 83.5246 109.944 83.1808 108.462 83.1808C106.85 83.1808 105.642 83.7609 104.836 84.921C104.041 86.0704 103.633 87.859 103.612 90.2868V92.0592C103.612 94.5944 103.993 96.4474 104.756 97.6183C105.529 98.7892 106.743 99.3746 108.397 99.3746C109.89 99.3746 111.002 99.0363 111.733 98.3595C112.474 97.672 112.898 96.6139 113.006 95.1852H117.84Z"
      fill="white"
    />
  </svg>
)
const docx = (
  <svg
    className="file-icon"
    width="170"
    height="170"
    viewBox="0 0 170 170"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M105.517 16H43.1593C35.3677 16 29.0635 22.375 29.0635 30.1667L28.9927 143.5C28.9927 151.292 35.3677 154.043 43.1593 154.043H127.83C135.622 154.043 142.326 151.292 142.326 143.5V58.5L105.517 16ZM133.101 142.841L38.8764 143.5V26.2132H99.6552L133.101 58.5V142.841Z"
      fill="white"
    />
    <path d="M99.6552 58.5V26.2132L133.101 58.5H99.6552Z" fill="white" />
    <path
      d="M44.6854 103V79.5392H51.9042C53.9667 79.5392 55.809 80.0065 57.431 80.9411C59.0638 81.8649 60.3368 83.1862 61.2499 84.9049C62.163 86.6129 62.6195 88.5573 62.6195 90.7379V91.8175C62.6195 93.9982 62.1683 95.9371 61.266 97.6344C60.3744 99.3317 59.1122 100.648 57.4794 101.582C55.8465 102.517 54.0043 102.989 51.9525 103H44.6854ZM49.5194 83.4547V99.1168H51.8558C53.7465 99.1168 55.1913 98.4992 56.1903 97.2638C57.1893 96.0285 57.6996 94.2614 57.7211 91.9625V90.7218C57.7211 88.3371 57.2269 86.5324 56.2386 85.3078C55.2504 84.0724 53.8055 83.4547 51.9042 83.4547H49.5194Z"
      fill="white"
    />
    <path
      d="M85.4198 91.8014C85.4198 94.111 85.0116 96.1359 84.1952 97.8761C83.3788 99.6163 82.2079 100.959 80.6825 101.904C79.1678 102.85 77.4276 103.322 75.4618 103.322C73.5174 103.322 71.7826 102.855 70.2572 101.921C68.7318 100.986 67.5502 99.6539 66.7123 97.9245C65.8744 96.1842 65.4501 94.1862 65.4393 91.9303V90.7702C65.4393 88.4606 65.8529 86.4303 66.68 84.6793C67.5179 82.9176 68.6942 81.5695 70.2089 80.6349C71.7342 79.6896 73.4745 79.2169 75.4296 79.2169C77.3846 79.2169 79.1195 79.6896 80.6341 80.6349C82.1595 81.5695 83.3358 82.9176 84.163 84.6793C85.0008 86.4303 85.4198 88.4552 85.4198 90.754V91.8014ZM80.5214 90.7379C80.5214 88.278 80.0809 86.4088 79.2001 85.1305C78.3192 83.8522 77.0624 83.213 75.4296 83.213C73.8075 83.213 72.556 83.8468 71.6752 85.1144C70.7943 86.3712 70.3485 88.2189 70.3378 90.6574V91.8014C70.3378 94.1969 70.7782 96.0553 71.659 97.3766C72.5399 98.6979 73.8075 99.3585 75.4618 99.3585C77.0839 99.3585 78.3299 98.7247 79.2001 97.4572C80.0702 96.1788 80.5106 94.3204 80.5214 91.882V90.7379Z"
      fill="white"
    />
    <path
      d="M107.35 95.1852C107.167 97.7096 106.233 99.6969 104.546 101.147C102.87 102.597 100.658 103.322 97.9076 103.322C94.8998 103.322 92.5311 102.313 90.8016 100.293C89.0829 98.2628 88.2235 95.4806 88.2235 91.9464V90.5123C88.2235 88.2565 88.621 86.2692 89.4159 84.5504C90.2108 82.8317 91.3441 81.5158 92.8158 80.6027C94.2982 79.6788 96.017 79.2169 97.972 79.2169C100.679 79.2169 102.86 79.942 104.514 81.3922C106.168 82.8424 107.124 84.8781 107.382 87.4992H102.548C102.43 85.9845 102.006 84.8888 101.275 84.2121C100.556 83.5246 99.4545 83.1808 97.972 83.1808C96.3607 83.1808 95.1522 83.7609 94.3465 84.921C93.5516 86.0704 93.1434 87.859 93.1219 90.2868V92.0592C93.1219 94.5944 93.5033 96.4474 94.266 97.6183C95.0394 98.7892 96.2533 99.3746 97.9076 99.3746C99.4007 99.3746 100.513 99.0363 101.243 98.3595C101.984 97.672 102.409 96.6139 102.516 95.1852H107.35Z"
      fill="white"
    />
    <path
      d="M118.919 87.6281L123.318 79.5392H128.877L122.045 91.173L129.055 103H123.431L118.919 94.7824L114.408 103H108.784L115.793 91.173L108.961 79.5392H114.52L118.919 87.6281Z"
      fill="white"
    />
  </svg>
)
const jpeg = (
  <svg
    className="file-icon"
    width="170"
    height="170"
    viewBox="0 0 170 170"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M105.517 16H43.1593C35.3677 16 29.0635 22.375 29.0635 30.1667L28.9927 143.5C28.9927 151.292 35.3677 154.043 43.1593 154.043H127.83C135.622 154.043 142.326 151.292 142.326 143.5V58.5L105.517 16ZM133.101 142.841L38.8764 143.5V26.2132H99.6552L133.101 58.5V142.841Z"
      fill="white"
    />
    <path d="M99.6552 58.5V26.2132L133.101 58.5H99.6552Z" fill="white" />
    <path
      d="M57.1571 79.5392H61.9911V95.7814C61.9911 97.2746 61.6581 98.5958 60.9921 99.7453C60.3368 100.884 59.4076 101.765 58.2045 102.388C57.0013 103.011 55.6424 103.322 54.1278 103.322C51.6464 103.322 49.7128 102.694 48.327 101.437C46.9413 100.17 46.2484 98.381 46.2484 96.0714H51.1146C51.1146 97.2208 51.3563 98.0695 51.8397 98.6173C52.3231 99.1652 53.0858 99.4391 54.1278 99.4391C55.0516 99.4391 55.7875 99.1222 56.3353 98.4884C56.8832 97.8546 57.1571 96.9523 57.1571 95.7814V79.5392Z"
      fill="white"
    />
    <path
      d="M70.9662 94.734V103H66.1322V79.5392H75.2845C77.0463 79.5392 78.5931 79.8615 79.9252 80.506C81.2679 81.1505 82.2992 82.069 83.0189 83.2614C83.7386 84.443 84.0985 85.7912 84.0985 87.3058C84.0985 89.6046 83.309 91.4201 81.7299 92.7521C80.1615 94.0734 77.9862 94.734 75.204 94.734H70.9662ZM70.9662 90.8185H75.2845C76.5629 90.8185 77.535 90.5177 78.201 89.9162C78.8778 89.3146 79.2162 88.4552 79.2162 87.338C79.2162 86.1886 78.8778 85.2594 78.201 84.5504C77.5243 83.8414 76.5897 83.4762 75.3973 83.4547H70.9662V90.8185Z"
      fill="white"
    />
    <path
      d="M101.549 92.8327H92.2679V99.1168H103.161V103H87.434V79.5392H103.128V83.4547H92.2679V89.046H101.549V92.8327Z"
      fill="white"
    />
    <path
      d="M124.559 100.035C123.689 101.077 122.459 101.888 120.869 102.468C119.279 103.038 117.517 103.322 115.584 103.322C113.554 103.322 111.77 102.882 110.234 102.001C108.709 101.11 107.527 99.8204 106.689 98.1339C105.862 96.4474 105.438 94.4655 105.416 92.1881V90.5929C105.416 88.2511 105.808 86.2262 106.593 84.5182C107.388 82.7995 108.526 81.4889 110.009 80.5866C111.502 79.6735 113.247 79.2169 115.245 79.2169C118.028 79.2169 120.203 79.8829 121.771 81.215C123.34 82.5363 124.269 84.4645 124.559 86.9996H119.854C119.639 85.6569 119.161 84.674 118.42 84.0509C117.689 83.4279 116.68 83.1163 115.39 83.1163C113.747 83.1163 112.495 83.734 111.636 84.9694C110.777 86.2047 110.342 88.0416 110.331 90.4801V91.9787C110.331 94.4386 110.798 96.297 111.733 97.5538C112.667 98.8107 114.037 99.4391 115.842 99.4391C117.657 99.4391 118.952 99.0524 119.725 98.2789V94.2345H115.326V90.6735H124.559V100.035Z"
      fill="white"
    />
  </svg>
)
const jpg = (
  <svg
    className="file-icon"
    width="170"
    height="170"
    viewBox="0 0 170 170"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M105.517 16H43.1593C35.3677 16 29.0635 22.375 29.0635 30.1667L28.9927 143.5C28.9927 151.292 35.3677 154.043 43.1593 154.043H127.83C135.622 154.043 142.326 151.292 142.326 143.5V58.5L105.517 16ZM133.101 142.841L38.8764 143.5V26.2132H99.6552L133.101 58.5V142.841Z"
      fill="white"
    />
    <path d="M99.6552 58.5V26.2132L133.101 58.5H99.6552Z" fill="white" />
    <path
      d="M66.4383 79.5392H71.2723V95.7814C71.2723 97.2746 70.9393 98.5958 70.2733 99.7453C69.618 100.884 68.6888 101.765 67.4857 102.388C66.2826 103.011 64.9237 103.322 63.409 103.322C60.9276 103.322 58.994 102.694 57.6083 101.437C56.2225 100.17 55.5297 98.381 55.5297 96.0714H60.3959C60.3959 97.2208 60.6376 98.0695 61.121 98.6173C61.6044 99.1652 62.3671 99.4391 63.409 99.4391C64.3329 99.4391 65.0687 99.1222 65.6166 98.4884C66.1644 97.8546 66.4383 96.9523 66.4383 95.7814V79.5392Z"
      fill="white"
    />
    <path
      d="M80.2474 94.734V103H75.4134V79.5392H84.5658C86.3275 79.5392 87.8744 79.8615 89.2064 80.506C90.5492 81.1505 91.5804 82.069 92.3002 83.2614C93.0199 84.443 93.3798 85.7912 93.3798 87.3058C93.3798 89.6046 92.5902 91.4201 91.0111 92.7521C89.4427 94.0734 87.2674 94.734 84.4852 94.734H80.2474ZM80.2474 90.8185H84.5658C85.8441 90.8185 86.8163 90.5177 87.4823 89.9162C88.159 89.3146 88.4974 88.4552 88.4974 87.338C88.4974 86.1886 88.159 85.2594 87.4823 84.5504C86.8055 83.8414 85.871 83.4762 84.6786 83.4547H80.2474V90.8185Z"
      fill="white"
    />
    <path
      d="M115.278 100.035C114.408 101.077 113.178 101.888 111.588 102.468C109.998 103.038 108.236 103.322 106.303 103.322C104.272 103.322 102.489 102.882 100.953 102.001C99.4276 101.11 98.246 99.8204 97.4081 98.1339C96.5809 96.4474 96.1566 94.4655 96.1351 92.1881V90.5929C96.1351 88.2511 96.5272 86.2262 97.3114 84.5182C98.1063 82.7995 99.245 81.4889 100.727 80.5866C102.221 79.6735 103.966 79.2169 105.964 79.2169C108.746 79.2169 110.922 79.8829 112.49 81.215C114.058 82.5363 114.988 84.4645 115.278 86.9996H110.573C110.358 85.6569 109.88 84.674 109.139 84.0509C108.408 83.4279 107.398 83.1163 106.109 83.1163C104.466 83.1163 103.214 83.734 102.355 84.9694C101.495 86.2047 101.06 88.0416 101.05 90.4801V91.9787C101.05 94.4386 101.517 96.297 102.452 97.5538C103.386 98.8107 104.756 99.4391 106.56 99.4391C108.376 99.4391 109.67 99.0524 110.444 98.2789V94.2345H106.045V90.6735H115.278V100.035Z"
      fill="white"
    />
  </svg>
)
const mp3 = (
  <svg
    className="file-icon"
    width="170"
    height="170"
    viewBox="0 0 170 170"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M105.517 16H43.1593C35.3677 16 29.0635 22.375 29.0635 30.1667L28.9927 143.5C28.9927 151.292 35.3677 154.043 43.1593 154.043H127.83C135.622 154.043 142.326 151.292 142.326 143.5V58.5L105.517 16ZM133.101 142.841L38.8764 143.5V26.2132H99.6552L133.101 58.5V142.841Z"
      fill="white"
    />
    <path d="M99.6552 58.5V26.2132L133.101 58.5H99.6552Z" fill="white" />
    <path
      d="M59.832 79.5391L65.8584 96.5547L71.8525 79.5391H78.2012V103H73.3511V96.5869L73.8345 85.5171L67.502 103H64.1826L57.8662 85.5332L58.3496 96.5869V103H53.5156V79.5391H59.832Z"
      fill="white"
    />
    <path
      d="M87.2568 94.7339V103H82.4229V79.5391H91.5752C93.3369 79.5391 94.8838 79.8613 96.2158 80.5059C97.5586 81.1504 98.5898 82.0688 99.3096 83.2612C100.029 84.4429 100.389 85.791 100.389 87.3057C100.389 89.6045 99.5996 91.4199 98.0205 92.752C96.4521 94.0732 94.2769 94.7339 91.4946 94.7339H87.2568ZM87.2568 90.8184H91.5752C92.8535 90.8184 93.8257 90.5176 94.4917 89.916C95.1685 89.3145 95.5068 88.4551 95.5068 87.3379C95.5068 86.1885 95.1685 85.2593 94.4917 84.5503C93.8149 83.8413 92.8804 83.4761 91.688 83.4546H87.2568V90.8184Z"
      fill="white"
    />
    <path
      d="M107.962 89.207H110.444C111.625 89.207 112.501 88.9116 113.07 88.3208C113.64 87.73 113.924 86.9458 113.924 85.9683C113.924 85.0229 113.64 84.2871 113.07 83.7607C112.512 83.2344 111.738 82.9712 110.75 82.9712C109.858 82.9712 109.112 83.2183 108.51 83.7124C107.909 84.1958 107.608 84.8296 107.608 85.6138H102.951C102.951 84.3892 103.279 83.2935 103.934 82.3267C104.6 81.3491 105.524 80.5864 106.706 80.0386C107.898 79.4907 109.208 79.2168 110.637 79.2168C113.119 79.2168 115.063 79.813 116.47 81.0054C117.877 82.187 118.581 83.8198 118.581 85.9038C118.581 86.978 118.253 87.9663 117.598 88.8687C116.943 89.771 116.083 90.4639 115.02 90.9473C116.341 91.4199 117.324 92.1289 117.969 93.0742C118.624 94.0195 118.952 95.1367 118.952 96.4258C118.952 98.5098 118.189 100.18 116.664 101.437C115.149 102.694 113.14 103.322 110.637 103.322C108.295 103.322 106.378 102.705 104.885 101.469C103.402 100.234 102.661 98.6011 102.661 96.5708H107.318C107.318 97.4517 107.646 98.1714 108.301 98.73C108.967 99.2886 109.783 99.5679 110.75 99.5679C111.856 99.5679 112.721 99.2778 113.344 98.6978C113.978 98.1069 114.295 97.3281 114.295 96.3613C114.295 94.0195 113.006 92.8486 110.428 92.8486H107.962V89.207Z"
      fill="white"
    />
  </svg>
)
const mp4 = (
  <svg
    className="file-icon"
    width="170"
    height="170"
    viewBox="0 0 170 170"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M105.517 16H43.1593C35.3677 16 29.0635 22.375 29.0635 30.1667L28.9927 143.5C28.9927 151.292 35.3677 154.043 43.1593 154.043H127.83C135.622 154.043 142.326 151.292 142.326 143.5V58.5L105.517 16ZM133.101 142.841L38.8764 143.5V26.2132H99.6552L133.101 58.5V142.841Z"
      fill="white"
    />
    <path d="M99.6552 58.5V26.2132L133.101 58.5H99.6552Z" fill="white" />
    <path
      d="M59.8319 79.5392L65.8583 96.5548L71.8524 79.5392H78.201V103H73.3509V96.5871L73.8343 85.5172L67.5018 103H64.1825L57.8661 85.5333L58.3495 96.5871V103H53.5155V79.5392H59.8319Z"
      fill="white"
    />
    <path
      d="M87.2567 94.734V103H82.4227V79.5392H91.5751C93.3368 79.5392 94.8837 79.8615 96.2157 80.506C97.5585 81.1505 98.5897 82.069 99.3094 83.2614C100.029 84.443 100.389 85.7912 100.389 87.3058C100.389 89.6046 99.5995 91.4201 98.0204 92.7521C96.452 94.0734 94.2767 94.734 91.4945 94.734H87.2567ZM87.2567 90.8185H91.5751C92.8534 90.8185 93.8256 90.5177 94.4916 89.9162C95.1683 89.3146 95.5067 88.4552 95.5067 87.338C95.5067 86.1886 95.1683 85.2594 94.4916 84.5504C93.8148 83.8414 92.8802 83.4762 91.6879 83.4547H87.2567V90.8185Z"
      fill="white"
    />
    <path
      d="M117.002 94.1701H119.661V97.9245H117.002V103H112.345V97.9245H102.725L102.516 94.9918L112.297 79.5392H117.002V94.1701ZM107.157 94.1701H112.345V85.8878L112.039 86.4196L107.157 94.1701Z"
      fill="white"
    />
  </svg>
)
const na = (
  <svg
    className="file-icon"
    width="170"
    height="170"
    viewBox="0 0 170 170"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M105.517 16H43.1593C35.3677 16 29.0635 22.375 29.0635 30.1667L28.9927 143.5C28.9927 151.292 35.3677 154.043 43.1593 154.043H127.83C135.622 154.043 142.326 151.292 142.326 143.5V58.5L105.517 16ZM133.101 142.841L38.8764 143.5V26.2132H99.6552L133.101 58.5V142.841Z"
      fill="white"
    />
    <path d="M99.6552 58.5V26.2132L133.101 58.5H99.6552Z" fill="white" />
    <path
      d="M78.2494 103H73.4154L64.0052 87.5636V103H59.1713V79.5392H64.0052L73.4315 95.0079V79.5392H78.2494V103Z"
      fill="white"
    />
    <path d="M83.6312 105.014H80.1669L88.8036 79.5392H92.2679L83.6312 105.014Z" fill="white" />
    <path
      d="M108.059 98.1662H99.5834L97.972 103H92.8319L101.565 79.5392H106.045L114.827 103H109.686L108.059 98.1662ZM100.889 94.2506H106.754L103.805 85.4689L100.889 94.2506Z"
      fill="white"
    />
  </svg>
)
const pdf = (
  <svg
    className="file-icon"
    width="170"
    height="170"
    viewBox="0 0 170 170"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M105.517 16H43.1593C35.3677 16 29.0635 22.375 29.0635 30.1667L28.9927 143.5C28.9927 151.292 35.3677 154.043 43.1593 154.043H127.83C135.622 154.043 142.326 151.292 142.326 143.5V58.5L105.517 16ZM133.101 142.841L38.8764 143.5V26.2132H99.6552L133.101 58.5V142.841Z"
      fill="white"
    />
    <path d="M99.6552 58.5V26.2132L133.101 58.5H99.6552Z" fill="white" />
    <path
      d="M62.5067 94.734V103H57.6727V79.5392H66.8251C68.5868 79.5392 70.1337 79.8615 71.4657 80.506C72.8085 81.1505 73.8397 82.069 74.5594 83.2614C75.2792 84.443 75.639 85.7912 75.639 87.3058C75.639 89.6046 74.8495 91.4201 73.2704 92.7521C71.702 94.0734 69.5267 94.734 66.7445 94.734H62.5067ZM62.5067 90.8185H66.8251C68.1034 90.8185 69.0756 90.5177 69.7416 89.9162C70.4183 89.3146 70.7567 88.4552 70.7567 87.338C70.7567 86.1886 70.4183 85.2594 69.7416 84.5504C69.0648 83.8414 68.1302 83.4762 66.9379 83.4547H62.5067V90.8185Z"
      fill="white"
    />
    <path
      d="M78.9745 103V79.5392H86.1932C88.2557 79.5392 90.098 80.0065 91.7201 80.9411C93.3529 81.8649 94.6258 83.1862 95.5389 84.9049C96.452 86.6129 96.9086 88.5573 96.9086 90.7379V91.8175C96.9086 93.9982 96.4574 95.9371 95.555 97.6344C94.6634 99.3317 93.4012 100.648 91.7684 101.582C90.1356 102.517 88.2933 102.989 86.2416 103H78.9745ZM83.8085 83.4547V99.1168H86.1449C88.0355 99.1168 89.4803 98.4992 90.4794 97.2638C91.4784 96.0285 91.9886 94.2614 92.0101 91.9625V90.7218C92.0101 88.3371 91.516 86.5324 90.5277 85.3078C89.5394 84.0724 88.0946 83.4547 86.1932 83.4547H83.8085Z"
      fill="white"
    />
    <path
      d="M114.553 93.4127H105.271V103H100.437V79.5392H115.713V83.4547H105.271V89.5133H114.553V93.4127Z"
      fill="white"
    />
  </svg>
)
const png = (
  <svg
    className="file-icon"
    width="170"
    height="170"
    viewBox="0 0 170 170"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M105.518 16H43.1598C35.3682 16 29.064 22.375 29.064 30.1667L28.9932 143.5C28.9932 151.292 35.3682 154.043 43.1598 154.043H127.83C135.622 154.043 142.326 151.292 142.326 143.5V58.5L105.518 16ZM133.102 142.841L38.8769 143.5V26.2132H99.6557L133.102 58.5V142.841Z"
      fill="white"
    />
    <path d="M99.6557 58.5V26.2132L133.102 58.5H99.6557Z" fill="white" />
    <path
      d="M59.3812 94.734V103H54.5472V79.5392H63.6996C65.4613 79.5392 67.0082 79.8615 68.3402 80.506C69.683 81.1505 70.7142 82.069 71.434 83.2614C72.1537 84.443 72.5135 85.7912 72.5135 87.3058C72.5135 89.6046 71.724 91.4201 70.1449 92.7521C68.5765 94.0734 66.4012 94.734 63.619 94.734H59.3812ZM59.3812 90.8185H63.6996C64.9779 90.8185 65.9501 90.5177 66.6161 89.9162C67.2928 89.3146 67.6312 88.4552 67.6312 87.338C67.6312 86.1886 67.2928 85.2594 66.6161 84.5504C65.9393 83.8414 65.0048 83.4762 63.8124 83.4547H59.3812V90.8185Z"
      fill="white"
    />
    <path
      d="M94.9271 103H90.0931L80.683 87.5636V103H75.849V79.5392H80.683L90.1092 95.0079V79.5392H94.9271V103Z"
      fill="white"
    />
    <path
      d="M117.711 100.035C116.841 101.077 115.611 101.888 114.021 102.468C112.432 103.038 110.67 103.322 108.736 103.322C106.706 103.322 104.923 102.882 103.387 102.001C101.861 101.11 100.68 99.8204 99.8417 98.1339C99.0145 96.4474 98.5902 94.4655 98.5687 92.1881V90.5929C98.5687 88.2511 98.9608 86.2262 99.745 84.5182C100.54 82.7995 101.679 81.4889 103.161 80.5866C104.654 79.6735 106.4 79.2169 108.398 79.2169C111.18 79.2169 113.355 79.8829 114.924 81.215C116.492 82.5363 117.421 84.4645 117.711 86.9996H113.006C112.791 85.6569 112.313 84.674 111.572 84.0509C110.842 83.4279 109.832 83.1163 108.543 83.1163C106.899 83.1163 105.648 83.734 104.788 84.9694C103.929 86.2047 103.494 88.0416 103.483 90.4801V91.9787C103.483 94.4386 103.951 96.297 104.885 97.5538C105.82 98.8107 107.189 99.4391 108.994 99.4391C110.809 99.4391 112.104 99.0524 112.877 98.2789V94.2345H108.478V90.6735H117.711V100.035Z"
      fill="white"
    />
  </svg>
)

const FileIcons = ({ fileExtension, size = 30 }) => {
  const icons = {
    doc,
    docx,
    jpeg,
    jpg,
    mp4,
    mp3,
    na,
    pdf,
    png,
  }

  return (
    <div style={{ width: size, height: size }}>
      {fileExtension && Object.prototype.hasOwnProperty.call(icons, fileExtension) ? icons[fileExtension] : icons.na}
    </div>
  )
}

export default FileIcons
