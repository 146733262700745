import React, { useState } from 'react'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DefaultLogoImage } from '~/util/defaultImages'
import { currencyFormat } from '~/helper/helperFunctions'
import useCommunityFunctions from '~/helper/communityFunctions'
import { CustomSwitch } from '~/components/Shared/StyledComponents'
import useGetCommunityCurrencySymbol from '~/hooks/useGetCommunityCurrencySymbol'
import * as S from './styled'

export default function SponsorTabCard({ sponsor, setCallbackToGetAllTheSponsors }) {
  const { t } = useTranslation()
  const communityCurrency = useSelector(state => state.community.currentCommunity.currency)
  const communityCurrencySymbol = useGetCommunityCurrencySymbol(communityCurrency)
  const communityId = useSelector(state => state.community.currentCommunity.id)
  const { companyName, companyLogo, activeCampaigns, sponsorSince, cumulatedRevenue, status, id } = sponsor
  const { updateCommunitySponsorCooperationStatusAction } = useCommunityFunctions()
  const [switchChecked, setSwitchChecked] = useState(status === 1)

  const updateSponsorCooperationStatus = e => {
    if (status === 1) {
      setSwitchChecked(e)
      updateCommunitySponsorCooperationStatusAction(0, communityId, id)
      setTimeout(() => {
        setCallbackToGetAllTheSponsors()
      }, 1000)
    }
    if (status === 0) {
      setSwitchChecked(e)
      updateCommunitySponsorCooperationStatusAction(1, communityId, id)
      setTimeout(() => {
        setCallbackToGetAllTheSponsors()
      }, 1000)
    }
  }
  return (
    <S.Container>
      <S.NameColumn>
        <S.SponsorInformationsContainer>
          <S.SponsorLogoContainer>
            <S.SponsorLogoImage src={companyLogo === null ? DefaultLogoImage : companyLogo} />
          </S.SponsorLogoContainer>
          <S.SponsorName>{companyName}</S.SponsorName>
        </S.SponsorInformationsContainer>
      </S.NameColumn>
      <S.ActiveCampaignsColumn>
        {activeCampaigns === 0 && <S.ProductSubtitle>{t('noActiveCampaign')}</S.ProductSubtitle>}
        {activeCampaigns > 0 && <S.ActiveCampaigns>{activeCampaigns}</S.ActiveCampaigns>}
      </S.ActiveCampaignsColumn>
      <S.SponsorSinceColumn>
        <S.ProductSubtitle>{!!sponsorSince && format(new Date(sponsorSince), 'dd/MM/yyyy')}</S.ProductSubtitle>
      </S.SponsorSinceColumn>
      <S.CumulatedRevenueColumn>
        {cumulatedRevenue === null && <S.NoRevenue>{t('noRevenue')}</S.NoRevenue>}
        {cumulatedRevenue !== null && (
          <S.CumulatedSponsorshipPrice>
            {`${communityCurrencySymbol} ${currencyFormat(cumulatedRevenue)}`}
          </S.CumulatedSponsorshipPrice>
        )}
      </S.CumulatedRevenueColumn>
      <S.StatusColumn>
        <CustomSwitch checked={switchChecked} onChange={updateSponsorCooperationStatus} />
        {switchChecked ? <S.SwitchTitle>{t('on')}</S.SwitchTitle> : <S.SwitchTitle>{t('off')}</S.SwitchTitle>}
      </S.StatusColumn>
    </S.Container>
  )
}
