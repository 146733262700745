import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  setCurrentCampaignForReviewPage as setCurrentCampaignForReviewPageAction,
  setShowCampaignReviewPage as setShowCampaignReviewPageAction,
  setCampaignsWaitingForApprovalLength as setCampaignsWaitingForApprovalLengthAction,
  openInformationModalAfterFormatCreation as openInformationModalAfterFormatCreationAction,
  closeInformationModalAfterFormatCreation as closeInformationModalAfterFormatCreationAction,
  setCommunitySponsorDirectChannelId as setCommunitySponsorDirectChannelIdAction,
  setCampaignDatesForCurrentMonth as setCampaignDatesForCurrentMonthAction,
} from '~/store/modules/createCampaign/actions'

const useCampaignFunctions = () => {
  const dispatch = useDispatch()
  const actions = useMemo(
    () => ({
      setCurrentCampaignForReviewPageAction(campaign) {
        dispatch(setCurrentCampaignForReviewPageAction(campaign))
      },
      setShowCampaignReviewPageAction(status) {
        dispatch(setShowCampaignReviewPageAction(status))
      },
      setCampaignsWaitingForApprovalLengthAction(length) {
        dispatch(setCampaignsWaitingForApprovalLengthAction(length))
      },
      openInformationModalAfterFormatCreationAction() {
        dispatch(openInformationModalAfterFormatCreationAction())
      },
      closeInformationModalAfterFormatCreationAction() {
        dispatch(closeInformationModalAfterFormatCreationAction())
      },
      setCommunitySponsorDirectChannelIdAction(channelId) {
        dispatch(setCommunitySponsorDirectChannelIdAction(channelId))
      },
      setCampaignDatesForCurrentMonthAction(startDate, endDate) {
        dispatch(setCampaignDatesForCurrentMonthAction(startDate, endDate))
      },
    }),
    [dispatch],
  )
  return actions
}

export default useCampaignFunctions
