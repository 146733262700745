import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from '../public/locales/en/translation.json'
import translationIT from '../public/locales/it/translation.json'
import translationPT from '../public/locales/pt/translation.json'

const resources = {
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
  pt: {
    translation: translationPT,
  },
}

const Languages = ['en', 'it', 'pt']
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    // debug: true,
    whitelist: Languages,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
