import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchInput from '~/components/SearchInput'
import { PaymentTabCardsRequest } from '~/services/requests'
import TabTitleAndSubtitle from '~/components/Community/TabTitleAndSubtitle'
import * as S from './styled'

export default function SponsorTab() {
  const { t } = useTranslation()
  const [filter, setFilter] = useState('')

  return (
    <S.Container>
      <TabTitleAndSubtitle sponsor title={t('paymentMethod')} subtitle={t('checkYourPaymentsHistory')} />
      <S.SearchInputContainer>
        <S.SearchInputButtonContainer>
          <SearchInput filter={filter} setFilter={setFilter} />
        </S.SearchInputButtonContainer>
      </S.SearchInputContainer>
      <S.CampaignsNeedingRevisionContainer>
        <S.GridTitleContainer>
          <S.CommunityColumn md={3}>
            <S.GridTitle>{t('campaignName')}</S.GridTitle>
          </S.CommunityColumn>
          <S.CampaignModelColumn md={2}>
            <S.GridTitle>{t('campaignType')}</S.GridTitle>
          </S.CampaignModelColumn>
          <S.FormatsColumn md={1}>
            <S.GridTitle>{t('formats')}</S.GridTitle>
          </S.FormatsColumn>
          <S.TotalBudgetColumn md={2}>
            <S.GridTitle>{t('totalBudget')}</S.GridTitle>
          </S.TotalBudgetColumn>
          <S.PurchaseColumn md={2}>
            <S.GridTitle>{t('purchaseDate')}</S.GridTitle>
          </S.PurchaseColumn>
          <S.PaymentStatusColumn md={2}>
            <S.GridTitle>{t('paymentStatus')}</S.GridTitle>
          </S.PaymentStatusColumn>
        </S.GridTitleContainer>
        <PaymentTabCardsRequest filter={filter} />
      </S.CampaignsNeedingRevisionContainer>
    </S.Container>
  )
}
