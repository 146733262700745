import { Route, Redirect } from 'react-router-dom'
import React from 'react'
import { store } from '~/store'
import history from '../services/history'

export default function RouteWrapper({ component: Component, isPrivate = false, ...rest }) {
  const { signed } = store.getState().auth
  const { type } = store.getState().user

  // TODO refactor this and implement role-based routes

  if (!signed && isPrivate) {
    return <Redirect to="/" />
  }

  if (signed && isPrivate && type === 'community' && history.location.pathname === '/sponsor') {
    return <Redirect to="/community" />
  }

  if (signed && isPrivate && type === 'sponsor' && history.location.pathname === '/community') {
    return <Redirect to="/sponsor" />
  }

  if (signed && !isPrivate && type === 'sponsor' && history.location.pathname !== '/sponsor') {
    return <Redirect to="/sponsor" />
  }

  if (signed && history.location.pathname === '/' && type === 'community') {
    return <Redirect to="/community" />
  }

  if (signed && history.location.pathname === '/' && type === 'sponsor') {
    return <Redirect to="/sponsor" />
  }

  return <Route {...rest} component={Component} />
}
