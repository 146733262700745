import produce from 'immer'
import { OPEN_CLOSE_CHAT_WINDOW, SET_ACTIVE_REPLY_MESSAGE, SET_ACTIVE_CHANNEL } from './actionTypes'

const INITIAL_STATE = {
  openedChat: false,
  activeReplyMessage: null,
  activeChannel: null,
}

export default function link(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case OPEN_CLOSE_CHAT_WINDOW: {
        draft.openedChat = action.payload.chatChannelId
        break
      }
      case SET_ACTIVE_REPLY_MESSAGE: {
        draft.activeReplyMessage = action.payload.message
        break
      }
      case SET_ACTIVE_CHANNEL: {
        draft.activeChannel = action.payload.channel
        break
      }
      default:
    }
  })
}
