import firebase from 'firebase'
import { clean } from '~/helper/helperFunctions'
import { messageCollection } from '~/helper/chatFunctions'
import { store } from '~/store'
import { getFirestore } from '../firebase'

export const getCollectionDocument = collection => {
  const firestore = getFirestore()
  const ref = firestore.collection(collection).doc()
  return ref
}

export const getDoc = async documentUuid => {
  const { communityId } = store.getState().chat.activeChannel || {}
  const doc = getFirestore()
    .collection(messageCollection(communityId))
    .doc(documentUuid)
  return doc
}

export const add = (path, payload) => {
  payload.createdAt = firebase.firestore.FieldValue.serverTimestamp()
  payload.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
  const firestore = getFirestore()
  return firestore.collection(path).add(clean(payload))
}

export const updateDoc = (path, payload) => {
  const firestore = getFirestore()
  const doc = firestore.doc(path)
  payload.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
  return doc.update(clean(payload))
}

export const setDoc = (path, payload, options) => {
  const firestore = getFirestore()
  payload.createdAt = firebase.firestore.FieldValue.serverTimestamp()
  payload.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
  return firestore.doc(path).set(payload, options)
}
