import styled from 'styled-components'
import { MdSearch, MdMoreHoriz } from 'react-icons/md'
import { BiCalendarAlt } from 'react-icons/bi'
import { FaRegBell, FaBars } from 'react-icons/fa'
import { AiOutlineAppstore } from 'react-icons/ai'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
  width: 22.5rem;
  height: ${props => (props.isSurvey ? '48.5rem' : '44rem')};
  border: 2px solid #ccc;
  border-radius: 30px;
  position: relative;
  background: #fff;
  margin-left: 5rem;
`

export const DeviceScreen = styled.div`
  background-color: ${props => (props.defaultVisualization ? '#18191b' : '#f2f2f2')};
  width: 21.2rem;
  height: ${props => (props.isSurvey ? '41.5rem' : '37rem')};
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 55px;
`

export const DeviceHeader = styled.div`
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 0.5rem;
  width: 100%;
  height: 4.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 20px 20px;
`

export const UserAvatar = styled.img.attrs({
  src: 'community-user-avatar1.jpg',
})`
  width: 35px;
  height: 35px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
`

export const CommunityAvatar = styled.img.attrs({
  src: 'community-header-logo.png',
})`
  width: 45px;
  height: 45px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
`

export const SearchIcon = styled(MdSearch).attrs({
  color: '#B7B7B7',
  size: 24,
})``

export const ClipIcon = styled.img.attrs({
  src: 'clip.png',
})``

export const PointsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 26px;

  background: #ff9900;
  box-shadow: 5px 5px 40px rgba(22, 22, 22, 0.2);
  border-radius: 40px;
`

export const Points = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  margin-right: 4px;
  color: #fff;
`

export const PointsIcon = styled.img.attrs({
  src: 'mobile-points.png',
})``

export const DeviceButton = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0 -10px;
  border-radius: 50%;
`

export const InterestLabel = styled.p`
  margin: 0.5em 0 0 1rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 44px;
  color: #a9a6a6;
  letter-spacing: 0.005em;
`

export const InterestsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const Interest = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const InterestTopBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  left: 0px;
  width: 28px;
  height: 18px;
  background: #ffffff;
  border: 1px solid #ff782d;
  box-sizing: border-box;
  box-shadow: 5px 5px 40px rgba(22, 22, 22, 0.2);
  border-radius: 40px;
`

export const InterestBadgeText = styled.span`
  font-family: Roboto;
  font-size: 11px;
  letter-spacing: 0.005em;
  color: #000000;
`

export const InterestImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100px;
  object-fit: cover;
`

export const SeeAll = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`

export const InterestName = styled.p`
  margin-top: 5px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 11px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #595959;
`

export const ThreeDots = styled(MdMoreHoriz).attrs({
  color: '#000',
  size: 24,
})``

export const DeviceFooter = styled.div`
  background: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.75rem;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
`

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ChatIcon = styled(MdSearch).attrs({
  color: '#3D3D3D',
  size: 24,
})``

export const CalendarIcon = styled(BiCalendarAlt).attrs({
  color: '#3D3D3D',
  size: 24,
})``

export const NotificationIcon = styled(FaRegBell).attrs({
  color: '#3D3D3D',
  size: 23,
})``

export const MoreIcon = styled(FaBars).attrs({
  color: '#3D3D3D',
  size: 23,
})``

export const NewsIcon = styled(AiOutlineAppstore).attrs({
  color: '#3D3D3D',
  size: 25,
})``

export const ChatMixIcon = styled.img.attrs({
  src: '/chat-mobile.png',
})`
  width: 24px;
  height: 24px;
`

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const IconText = styled.span`
  margin-top: 0.5px;
  font-family: Roboto;
  font-size: 10px;
  line-height: 15px;
  color: #777777;
`

export const NotificationDot = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  background: linear-gradient(166.7deg, #ff9900 9.56%, #ff782d 94.96%);
  mix-blend-mode: normal;
  border-radius: 40px;
  width: 0.5rem;
  height: 0.5rem;
`
