import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 585px;
  height: fit-content;

  canvas {
    border-radius: 10px;
    background: transparent !important;
  }

  p,
  img {
    user-select: none;
  }

  @media (max-height: 830px) {
    height: 600px;
    overflow-y: scroll;
    padding-top: 230px;
  }
`

export const CropContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 36px 0 12px 0;

  canvas {
    box-shadow: 0 0 10px 0 #00000030;
  }

  input[type='range'] {
    height: 24px;
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: black;
  }

  input[type='range']::-webkit-slider-thumb {
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #323232;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7.5px;
  }

  input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #000;
    cursor: pointer;
  }
`

export const ZoomContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 95%;
  align-self: center;
`

export const IconContainer = styled.div`
  cursor: pointer;
`

export const DisplayContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 32px;
  justify-content: space-around;
  padding: 0 30px;
`

export const TextContainer = styled.div``

export const ImageLeftContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  min-height: 103px;

  img {
    box-shadow: 0 0 10px 0 #00000030;
  }
`

export const ImageRightContainer = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  button span {
    color: #fff;
  }
`

export const Title = styled.h1`
  font-family: Lato;
  font-size: 34px;
  color: black;
  text-transform: capitalize;
  font-weight: 600;
  text-align: center;
`

export const MainText = styled.h2`
  font-family: Roboto;
  font-size: 17px;
  color: black;
  margin-top: 24px;
`

export const Subtext = styled.p`
  font-family: Roboto;
  font-size: 12px;
  color: grey;
  margin-top: 6px;
`
