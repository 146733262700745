/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InputAdornment } from '@material-ui/core'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner'
import TextField from '@material-ui/core/TextField'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { getWebscrapperInformations } from '~/helper/formFunctions'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import { InputTitle } from '~/components/Shared/StyledComponents'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import ContentVisualization from '~/components/CreateFormatMobileVisualization/Content'
import Cover from '~/components/Sponsor/Cover'
import DisplayCriteriaSelect from '~/components/DisplayCriteriaSelect'

import * as S from './styled'

export default function ModalCreateVideo({ handleCloseModal, showModal, setContent, campaignProductId }) {
  const { t } = useTranslation()
  const { createCampaignFormatAction } = useSponsorFunctions()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const sponsorManagerId = useSelector(state => state.sponsor.sponsorManager.id)
  const [loadingDebounce, setLoadingDebounce] = useState(false)

  const [, setScrapperTags] = useState(null)
  const [scrapperLink, setScrapperLink] = useState(null)
  const [, setIsUpdate] = useState(false)
  const [loadingImage, setLoadingImage] = useState(false)
  const [linkError, setLinkError] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)
  const [imageId, setImageId] = useState(null)
  const [date, setDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const { handleSubmit, setValue, register, control, watch, errors } = useForm()

  const videoLinkWatch = watch('video')
  const uploadTypeName = 'video'

  const titleWatch = watch('title')
  const scrapperDescriptionWatch = watch('scrapperDescription')

  const [scrapperDescription, setScrapperDescription] = useState(null)
  const [scrapperTitle, setScrapperTitle] = useState(null)

  function closeModalAndResetContent() {
    setContent(0)
    handleCloseModal()
  }

  function onSubmit(format) {
    if (linkError) {
      toast.error('Link invalid. Please provide a Youtube or Vimeo link.')
    } else {
      format.scrapperTitle = titleWatch

      if (!format.imageUrl || !format.image) {
        return toast.error(t('defaultValidation', { inputName: t('image') }))
      }

      createCampaignFormatAction(
        currentCampaignForReviewPage.id,
        campaignProductId,
        format,
        'video',
        closeModalAndResetContent,
      )
    }
  }

  // Register fields only once
  useEffect(() => {
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    register({ name: 'scrapperTitle' })
    register({ name: 'scrapperLink' })
    register({ name: 'scrapperDescription' })
  }, [register])

  // Handle image updates
  useEffect(() => {
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
  }, [setValue, imageUrl, imageId])

  // Handle scrapper data updates
  useEffect(() => {
    setValue('title', scrapperTitle)
    setValue('scrapperTitle', scrapperTitle)
    setValue('scrapperLink', scrapperLink)
    setValue('scrapperDescription', scrapperDescription)

    if (scrapperTitle) {
      errors.title = undefined
    }

    if (scrapperDescription) {
      errors.scrapperDescription = undefined
    }
  }, [setValue, scrapperTitle, scrapperLink, scrapperDescription])

  // Debounce video
  useEffect(() => {
    let typingTimer = null
    const val = videoLinkWatch
    clearTimeout(typingTimer)
    typingTimer = setTimeout(() => {
      const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?=.*v=((\w|-){11}))(?:\S+)?$/
      const vimeoRegex = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
      if (val !== undefined && val !== null) {
        const matchesYoutube = val.match(youtubeRegex)
        const matchesVimeo = val.match(vimeoRegex)
        if (matchesYoutube || matchesVimeo) {
          setLoadingDebounce(true)
          getWebscrapperInformations(
            videoLinkWatch,
            sponsorManagerId,
            currentCampaignForReviewPage.networkId,
            setScrapperTitle,
            setScrapperDescription,
            setScrapperTags,
            setLoadingDebounce,
            setScrapperLink,
            setImageUrl,
            setImageId,
            setLoadingImage,
            setIsUpdate,
            uploadTypeName,
            setLinkError,
          )
        } else {
          setLinkError(true)
          toast.error('Link invalid. Please provide a Youtube or Vimeo link.')
        }
      } else {
        return null
      }
      return null
    }, 2000)
    return () => clearTimeout(typingTimer)
  }, [videoLinkWatch])

  return (
    <ProductsModal
      action={onSubmit}
      modalTitle={t('newFormat', { format: t('video') })}
      handleCloseModal={closeModalAndResetContent}
      showModal={showModal}
      review={false}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <InputTitle>{t('pastTheVideoLink')}</InputTitle>
            <Controller
              as={
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="https://www.example.com"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {loadingDebounce ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <>
                            <img className="mr-1" src="/youtube.png" alt="Youtube" width="21px" height="21px" />
                            <img
                              className="video-icons-vimeo"
                              src="/vimeo-logo.png"
                              alt="Vimeo"
                              width="19px"
                              height="19px"
                            />
                          </>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              }
              rules={{ required: t('defaultValidation', { inputName: t('video') }) }}
              name="video"
              control={control}
            />
            <div className="error-message">
              <ErrorMessage errors={errors} name="video" as="p" />
            </div>

            <div className="mt-3">
              <InputTitle>{t('title')}</InputTitle>

              <Controller
                as={<TextField fullWidth variant="outlined" placeholder={t('typeTitle')} />}
                rules={{ required: t('defaultValidation', { inputName: t('title') }) }}
                name="title"
                control={control}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="title" as="p" />
              </div>
            </div>

            <div className="mt-3">
              <InputTitle>{t('description')}</InputTitle>

              <Controller
                as={<TextField fullWidth variant="outlined" placeholder={t('typeDescription')} />}
                rules={{ required: t('defaultValidation', { inputName: t('description') }) }}
                name="scrapperDescription"
                control={control}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="scrapperDescription" as="p" />
              </div>
            </div>

            <DisplayCriteriaSelect control={control} />

            <StartDateSection
              product={t('video')}
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              control={control}
              errors={errors}
            />

            <EndDateSection
              product={t('video')}
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              control={control}
              errors={errors}
            />

            <Cover setImageUrl={setImageUrl} setImageId={setImageId} imageUrl={imageUrl} loading={loadingImage} />
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          FormatVisualizationComponent={
            <ContentVisualization
              loadingImage={loadingImage}
              image={imageUrl}
              title={titleWatch}
              about={scrapperDescriptionWatch}
              contentType={t('video')}
            />
          }
        />
      }
    />
  )
}
