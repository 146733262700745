import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import Col from 'react-bootstrap/Col'
import 'chart.piecelabel.js'
import CampaignReviewProductsButtons from '~/components/Community/CampaignReviewProductsButtons'
import * as S from './styled'

export function BannerReviewCard({ banner, type }) {
  const { t } = useTranslation()

  const bannerName = {
    topBanner: t('slidingTopBanner'),
    bottomBanner: t('bottomStoriesBanner'),
  }

  return (
    <Container fluid>
      <S.CardBody>
        <div className="icon-section">
          <img style={{ opacity: '.9' }} src="/icons/rewards.png" alt="Welcome Ads" width="19" height="19" />
          <p className="ml-2">{bannerName[type] ?? 'Banner'}</p>
        </div>
        <S.AnalyticsCardRow>
          <BannerCard
            name={banner.unity.title}
            image={banner.unity.imageUrl}
            analytics={banner.analytics}
            type={type}
          />
        </S.AnalyticsCardRow>
      </S.CardBody>
    </Container>
  )
}

export function BannerCard({ name, image, analytics, type }) {
  const { t } = useTranslation()

  return (
    <S.WelcomeAdBody>
      <Row>
        <Col className="mb-4">
          <p className="ad-name">{name}</p>
        </Col>
      </Row>

      <S.AnalyticsAndImageContainer>
        <S.ContainerWelcomeAdImage>
          <S.WelcomeAdImage
            src={image === null || image === undefined ? '/community-default-banner-picture.jpg' : image}
            alt="Banner"
            type={type}
          />
        </S.ContainerWelcomeAdImage>

        <S.AnalyticsContainer>
          <CampaignReviewProductsButtons
            text={t('clickThrough')}
            number={analytics?.countClicksThrough}
            color="#35CC9E"
            icon={<img src="/icons/campaignReview/linkview.png" alt="Click through" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            text={t('views')}
            number={analytics?.countViews}
            color="#25B9CD"
            icon={<img src="/icons/campaignReview/view.png" alt="Views" width="24" height="24" />}
          />
        </S.AnalyticsContainer>
      </S.AnalyticsAndImageContainer>
    </S.WelcomeAdBody>
  )
}
