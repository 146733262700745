/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import { InputTitle } from '~/components/Shared/StyledComponents'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import ScratchCardPreview from '~/components/Sponsor/Modals/ScratchCard/ScratchCardPreview'
import scratchCardValidator from '~/schemaValidation/communityStoryValidator'
import { getThumbnails } from 'video-metadata-thumbnails'
import { uploadMedia } from '~/services/requests'
import { formatUtcDate } from '~/helper/getFormattedDates'
import DisplayCriteriaSelect from '~/components/DisplayCriteriaSelect'

import * as S from './styled'
import { isVideo, isImage } from '../utils'

export default function ModalEditScratchCard({
  handleCloseModal,
  showModal,
  scratchCardToUpdate,
  review,
  createMessage,
  refuseCampaignProduct,
  approveCampaignProduct,
  formatId,
  sponsor,
}) {
  const { t } = useTranslation()
  const videoInput = useRef(null)

  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)

  const [loadingUpload, setLoadingUpload] = useState(false)
  const [date, setDate] = useState(formatUtcDate(scratchCardToUpdate.date))
  const [endDate, setEndDate] = useState(formatUtcDate(scratchCardToUpdate.endDate))
  const { handleSubmit, register, setValue, control, errors } = useForm({
    validationSchema: scratchCardValidator,
  })
  const { updateCampaignFormatAction } = useSponsorFunctions()
  const [mediaFileType, setMediaFileType] = useState(scratchCardToUpdate?.videoId ? 'video' : 'image')
  const [loading, setLoading] = useState(false)

  const [media, setMedia] = useState(
    mediaFileType === 'image'
      ? {
        id: scratchCardToUpdate?.image,
        url: scratchCardToUpdate?.imageUrl,
      }
      : {
        id: scratchCardToUpdate?.videoId,
        url: scratchCardToUpdate?.videoUrl,
      },
  )

  const [videoThumbnail, setVideoThumbnail] = useState({
    id: scratchCardToUpdate?.thumbnailId,
    url: scratchCardToUpdate?.thumbnailUrl,
  })

  const modalReviewCreateMessageAndCloseModal = () => {
    createMessage()
    refuseCampaignProduct()
    handleCloseModal()
  }

  const onInputVideoClick = event => {
    event.target.value = ''
  }

  const handleClickUploadInput = uploadRef => {
    return uploadRef?.current?.click()
  }

  const onSubmit = format => {
    if (!format?.imageUrl && !format?.videoId) return toast.error(t('defaultValidation', { inputName: t('media') }))

    if (mediaFileType === 'image' && !format.imageUrl)
      return toast.error(t('defaultValidation', { inputName: t('media') }))

    if (mediaFileType === 'video' && !format.videoId)
      return toast.error(t('defaultValidation', { inputName: t('media') }))

    const payload = format

    if (mediaFileType === 'image') {
      delete payload?.thumbnailId
      delete payload?.thumbnailUrl
      delete payload?.videoId
      delete payload?.videoUrl
    }

    if (mediaFileType === 'video') {
      delete payload?.image
      delete payload?.imageUrl
    }

    updateCampaignFormatAction(payload, formatId, handleCloseModal, setLoading)
  }

  const addMedia = async event => {
    const mediaSize = event.target?.files?.[0]?.size
    const mediaType = event.target?.files?.[0].type

    if (!isImage(mediaType) && !isVideo(mediaType)) return toast.error(t('invalidFormat'))

    if (mediaSize > 100000000) {
      return toast.error(t('uploadLimit'))
    }

    if (isVideo(mediaType)) {
      if (mediaType !== 'video/mp4') return toast.error(t('onlyMp4Files'))

      const video = URL.createObjectURL(event.target?.files?.[0])

      const thumbnail = await getThumbnails(video, {
        quality: 0.6,
        start: 0,
        end: 0,
      })

      if (!thumbnail) return toast.error(t('failedToGetThumbnail'))

      if (event.target?.files?.[0]) {
        const reader = new FileReader()
        reader.onload = () => {
          const media = new Audio(reader.result)
          media.onloadedmetadata = async () => {
            const videoToUpload = event.target?.files?.[0]
            await uploadMedia(videoToUpload, setLoadingUpload, setMedia)
            return await uploadMedia(thumbnail[0]?.blob, setLoadingUpload, setVideoThumbnail)
          }
        }
        await reader.readAsDataURL(event.target?.files?.[0])
      }
      return setMediaFileType('video')
    }

    if (isImage(mediaType)) {
      if (event.target?.files?.[0]) {
        const imagetoUpload = event.target?.files?.[0]
        await uploadMedia(imagetoUpload, setLoadingUpload, setMedia)
        return setMediaFileType('image')
      }
    }
  }

  useEffect(() => {
    if (mediaFileType === 'image') {
      register({ name: 'image' })
      register({ name: 'imageUrl' })
      setValue('image', media?.id)
      setValue('imageUrl', media?.url)
    }

    if (mediaFileType === 'video') {
      register({ name: 'thumbnailId' })
      register({ name: 'thumbnailUrl' })
      register({ name: 'videoId' })
      register({ name: 'videoUrl' })
      setValue('thumbnailId', videoThumbnail?.id)
      setValue('thumbnailUrl', videoThumbnail?.url)
      setValue('videoId', media?.id)
      setValue('videoUrl', media?.url)
    }
  }, [register, setValue, media, mediaFileType, videoThumbnail, scratchCardToUpdate.id])

  useEffect(() => {
    if (mediaFileType === 'image')
      return setMedia({ id: scratchCardToUpdate?.image, url: scratchCardToUpdate?.imageUrl })

    if (mediaFileType === 'video') {
      setMedia({ id: scratchCardToUpdate?.videoId, url: scratchCardToUpdate?.videoUrl })
      setVideoThumbnail({ id: scratchCardToUpdate?.thumbnailId, url: scratchCardToUpdate?.thumbnailUrl })
    }
  }, [showModal])

  return (
    <ProductsModal
      createMessage={modalReviewCreateMessageAndCloseModal}
      approveCampaignProduct={approveCampaignProduct}
      action={onSubmit}
      loadingUpload={loadingUpload}
      loading={loading}
      buttonText={review ? t('done') : t('update')}
      modalTitle={
        review ? t('reviseFormat', { format: t('scratchCard') }) : t('editFormat', { format: t('scratchCard') })
      }
      handleCloseModal={handleCloseModal}
      showModal={showModal}
      review={review}
      sponsor={sponsor}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <div className="mt-3">
              <InputTitle>{t('title')}</InputTitle>
              <Controller
                as={<TextField fullWidth variant="outlined" placeholder={t('typeTitle')} disabled={review || false} />}
                name="title"
                control={control}
                defaultValue={scratchCardToUpdate?.title}
              />
            </div>
            <div className="error-message">
              <ErrorMessage errors={errors} name="name" as="p" />
            </div>

            <div className="mt-3">
              <InputTitle>{t('link')}</InputTitle>
              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="e.g. https://www.greatecontent.com"
                    disabled={review || false}
                  />
                }
                name="link"
                control={control}
                defaultValue={scratchCardToUpdate?.link}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="link" as="p" />
              </div>
            </div>

            <div className="mt-3">
              <InputTitle>{t('description')}</InputTitle>
              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={t('moreAboutContent', { format: t('scratchCard').toLocaleLowerCase() })}
                    rows={4}
                    multiline
                    disabled={review || false}
                  />
                }
                name="description"
                control={control}
                defaultValue={scratchCardToUpdate?.description}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="description" as="p" />
              </div>
            </div>

            <DisplayCriteriaSelect control={control} value={scratchCardToUpdate?.privacy} disabled={review || false} />

            <StartDateSection
              product={t('scratchCard')}
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              disabled={review}
              control={control}
              errors={errors}
            />

            <EndDateSection
              product={t('scratchCard')}
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              disabled={review}
              control={control}
              errors={errors}
            />

            {!review && (
              <S.InputContainer>
                <InputTitle>{t('showExperience')}</InputTitle>
                <S.HelperText>{t('scratchCardResolution')}</S.HelperText>
              </S.InputContainer>
            )}

            {loadingUpload && (
              <S.SpinnerContainer>
                <S.CustomSpinner />
              </S.SpinnerContainer>
            )}

            {!loadingUpload && !media && !review && (
              <S.UploadSection>
                <S.UploadContainer spacing onClick={() => handleClickUploadInput(videoInput)}>
                  <S.VideoUploadIcon />
                  <S.UploadText>{t('videoUpload')}</S.UploadText>
                </S.UploadContainer>
              </S.UploadSection>
            )}

            {/* Preview media */}
            {!loadingUpload && media && !review && (
              <S.PreviewMediaContainer>
                {mediaFileType === 'video' && (
                  <S.PlayIconContainer>
                    <S.PlayIcon />
                  </S.PlayIconContainer>
                )}

                {sponsor && mediaFileType === 'image' && <S.PreviewMedia src={media?.url} />}
                {sponsor && mediaFileType === 'video' && <S.PreviewMedia src={videoThumbnail?.url} />}

                <S.CloseIcon
                  onClick={() => {
                    setMedia(null)
                    setVideoThumbnail(null)
                  }}
                />
              </S.PreviewMediaContainer>
            )}

            <S.InputAddVideo ref={videoInput} onChange={addMedia} onClick={onInputVideoClick} />
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          defaultVisualization
          FormatVisualizationComponent={
            <ScratchCardPreview media={mediaFileType === 'image' ? media?.url : videoThumbnail?.url} />
          }
        />
      }
    />
  )
}
