/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import Header from '~/components/Header'
import useAxios from 'axios-hooks'
import ModalStripeAccountCreationError from '~/components/Stripe/ModalStripeAccountCreationError'
import ModalStripeAccountCreationSuccess from '~/components/Stripe/ModalStripeAccountCreationSuccess'
import ModalEditUserProfile from '~/components/Modals/ModalEditUserProfile'
import useCommunityFunctions from '~/helper/communityFunctions'
import ChatList from '~/components/Chat/ChatList'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import CommunityProfile from '~/components/Community/CommunityProfile'
import SponsorTab from '~/components/Community/SponsorsTab/SponsorTab'
import CampaignTab from '~/components/Community/CampaignsTab/CampaignTab'
import AnalyticsTab from '~/components/Community/AnalyticsTab'
import CampaignReviewPage from '~/pages/Community/containers/CampaignReviewPage'
import ModalTutorialWelcome from '~/components/ApplicationTutorial/ModalTutorialWelcome'
import ModalTutorialAllSteps from '~/components/ApplicationTutorial/Community/ModalTutorialAllSteps'
import GroupTabs from '~/components/Shared/GroupTabs'
import ModalInviteSponsor from '~/components/Community/Modals/ModalInviteSponsor'
import PendingAlertSection from '~/components/PendingAlertSection'
import CommunityTabs from '~/components/Community/CommunityTabs'
import useNavigationFunctions from '~/helper/navigationFunctions'
import useCampaignFunctions from '~/helper/campaignFunctions'
import useTutorialFunctions from '~/helper/tutorialFunctions'
import { handleCheckCommunityInviteSponsorButton } from '~/helper/helperFunctions'
import {
  getCommunityAndCommunityAlerts,
  ProductTabWithProducts,
  PackageTabWithPackages,
  getCampaignIdFromEmailRedirectionAndGoToCampaignReviewPage,
} from '~/services/requests'
import * as CHAT from '~/helper/chatFunctions'
import * as S from './styled'

export default function Community({ location }) {
  const urlParams = new URLSearchParams(location.search)
  const campaignId = urlParams.get('campaignId')

  const { t } = useTranslation()
  const { setShowCampaignReviewPageAction, setCurrentCampaignForReviewPageAction } = useCampaignFunctions()
  const { setCommunityCurrentTabAction } = useNavigationFunctions()
  const { setTutorialAsInativeAction } = useTutorialFunctions()
  const { stripeUserAuthorizationCodeAction, createSponsorCommunityChannelAction } = useSponsorFunctions()
  const CF = useCommunityFunctions()
  const communityId = useSelector(state => state.community?.currentCommunity?.id)
  const [userInformations, setUserInformations] = useState(null)
  const [loadingSetChannels, setLoadingSetChannels] = useState(false)
  const [channels, setChannels] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentCommunityInformations, setCurrentCommunityInformations] = useState('')
  const [showModalStripeCreateAccountError, setShowModalStripeCreateAccountError] = useState(false)
  const [showModalStripeCreateAccountSuccess, setShowModalStripeCreateAccountSuccess] = useState(false)
  const [showModalEditUserProfile, setShowModalEditUserProfile] = useState(false)
  const { id } = currentCommunityInformations
  const showCampaignReviewPage = useSelector(state => state.createCampaign.showCampaignReviewPage)
  const campaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const currentTabActive = useSelector(state => state.tab.communityCurrentTab)
  const [showModalInviteSponsor, setShowModalInviteSponsor] = useState(false)
  const [showModalEditCommunity, setShowModalEditCommunity] = useState(false)
  const [communityHasStripeAccount, setCommunityHasStripeAccount] = useState(null)
  const [communityIsRevised, setCommunityIsRevised] = useState(null)
  const [communityIncompleteProfile, setCommunityIncompleteProfile] = useState(null)
  const [hasFormatsWithoutMaxInParallel, setHasFormatsWithoutMaxInParallel] = useState({ status: null, formats: null })
  const communityFirstLoginForTheTutorial = useSelector(state => state.tutorial.communityFirstLogin)
  const [showModalTutorialWelcome, setShowModalTutorialWelcome] = useState(communityFirstLoginForTheTutorial)
  // const isTutorialActive = useSelector(state => state.tutorial?.tutorialIsActive)
  const [showModalTutorialAllSteps, setShowModalTutorialAllSteps] = useState(false)
  const [loadingCommunityProfile, setLoadingCommunityProfile] = useState(true)
  const howManyTimesTheCommunityLoggedInTheApplication = useSelector(
    state => state.tutorial.howManyTimesTheCommunityLoggedInTheApplication,
  )
  const authResult = new URLSearchParams(window.location.search)
  const stripeAuthorizationCode = authResult.get('code')
  const stripeError = authResult.get('error')

  const filterProducts = products => {
    const defaultProductsArray = products?.filter(product => product.productType !== 'custom')
    const customProductArray = products?.filter(product => product.productType === 'custom')
    const productsArray = defaultProductsArray?.concat(customProductArray)

    return productsArray
  }

  const filteredProducts = filterProducts(hasFormatsWithoutMaxInParallel?.formats)

  const onClickMaxInParallelAlert = () => {
    if (hasFormatsWithoutMaxInParallel.status === true) {
      const id = filteredProducts?.[0]?.id
      const productElement = document.getElementById(id)

      if (productElement) {
        productElement.scrollIntoView({ behavior: 'smooth' })
      }

      const input = document.getElementById(`product-input-${id}`)

      if (input) {
        input.focus({ preventScroll: true })
      }
    }
  }

  const handleChangeCurrentTabActive = tab => {
    setCommunityCurrentTabAction(tab)
  }

  const backToCommunityPage = () => {
    setShowCampaignReviewPageAction(false)
  }

  /* 
    I had to separate the maximum formats in parallel alert from the other ones
    because it needs to be refetched everytime a product's maximum is updated on
    the `ProductCard` component, but refetching the entire `getCommunityAndCommunityAlerts` 
    function would cause the entire website to refresh, instead of just the products section.
 */
  const [{ data: maxInParallelAlert }, refetch] = useAxios({
    url: `/community/${communityId}/user-alerts`,
  })

  useEffect(() => {
    setHasFormatsWithoutMaxInParallel({
      status: maxInParallelAlert?.data?.hasFormatsWithoutMaxParallel?.status,
      formats: maxInParallelAlert?.data?.hasFormatsWithoutMaxParallel?.formats,
    })
  }, [maxInParallelAlert])

  useEffect(() => {
    setLoading(true)
    getCommunityAndCommunityAlerts(
      communityId,
      setLoading,
      setCurrentCommunityInformations,
      CF.updateUserCurrentCommunityInformationsAction,
      CF.removeS3FromStateAction,
      CF.setCommunityHasStripeAccountAction,
      setCommunityHasStripeAccount,
      setCommunityIsRevised,
      setCommunityIncompleteProfile,
      setUserInformations,
      createSponsorCommunityChannelAction,
    )
  }, [stripeAuthorizationCode, communityHasStripeAccount])

  useEffect(() => {
    CHAT.getCommunityNonSponsoredChannels(communityId, setChannels, setLoadingSetChannels)
    CHAT.createOrUpdateUsersReadDocumentForTheCommunity(communityId, channels)
  }, [loadingSetChannels])

  useEffect(() => {
    if (stripeError !== null) {
      setShowModalStripeCreateAccountError(true)
    }
  }, [stripeError])

  useEffect(() => {
    if (stripeAuthorizationCode !== null) {
      if (id !== undefined) {
        setShowModalStripeCreateAccountSuccess(true)
        stripeUserAuthorizationCodeAction(stripeAuthorizationCode, id)
      }
    }
  }, [stripeAuthorizationCode, id])

  useEffect(() => {
    if (!communityFirstLoginForTheTutorial && howManyTimesTheCommunityLoggedInTheApplication < 5) {
      setShowModalTutorialAllSteps(true)
    }
    setTutorialAsInativeAction()
    const interval = setTimeout(() => {
      setLoadingCommunityProfile(false)
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  // Get the campaignId from the email redirection and go to the campaign review page
  useEffect(() => {
    if (campaignId) {
      getCampaignIdFromEmailRedirectionAndGoToCampaignReviewPage(
        campaignId,
        setCurrentCampaignForReviewPageAction,
        setShowCampaignReviewPageAction,
        'community',
      )
    }
  }, [campaignId])

  return loading ? (
    <S.SpinnerContainer>
      <S.CustomSpinner />
    </S.SpinnerContainer>
  ) : (
    <>
      <Header
        userInformations={userInformations}
        entity="Community"
        openModalUserProfile={() => setShowModalEditUserProfile(true)}
      />
      <S.PageContainer fluid as="section">
        <S.MainRow>
          <S.CommunityProfileColumn>
            {!loadingCommunityProfile && (
              <CommunityProfile
                communityIsRevised={communityIsRevised}
                currentCommunityInformations={currentCommunityInformations}
                showModalEditCommunity={showModalEditCommunity}
                setShowModalEditCommunity={setShowModalEditCommunity}
              />
            )}
          </S.CommunityProfileColumn>
          {showCampaignReviewPage ? (
            <S.CampaginReviewPageColumn>
              <CampaignReviewPage backToCommunityPage={backToCommunityPage} campaign={campaignForReviewPage} />
            </S.CampaginReviewPageColumn>
          ) : (
            <S.GroupTabsColumn>
              <S.PendingAlertsSection>
                {communityHasStripeAccount !== null && communityHasStripeAccount === false && (
                  <PendingAlertSection
                    title={t('createFreeStripeAccount')}
                    subtitle={t('thisAccountWillBeUsedToReceivePayment')}
                    buttonText={t('createMyStripeAccount')}
                    stripe
                  />
                )}
                {communityIncompleteProfile !== null && communityIncompleteProfile === true && (
                  <PendingAlertSection
                    title={t('completeCommunityProfile')}
                    subtitle={t('describeYourCommunity')}
                    buttonText={t('completeCommunityProfile')}
                    buttonAction={() => setShowModalEditCommunity(true)}
                  />
                )}
                {communityIsRevised !== null && communityIsRevised === false && (
                  <PendingAlertSection
                    title={t('urgentCommunityRevised')}
                    subtitle={t('outTeamGetInContactWithYou')}
                    buttonText={t('contactOurTeam')}
                    buttonAction={() => toast.warning(t('comingSoon'))}
                  />
                )}
                {hasFormatsWithoutMaxInParallel.status === true && (
                  <PendingAlertSection
                    title={t('defineParallelExibition')}
                    subtitle={t('oneOrMoreMaxNumber')}
                    buttonText={t('setLimits')}
                    buttonAction={onClickMaxInParallelAlert}
                  />
                )}
              </S.PendingAlertsSection>
              <GroupTabs
                tabs={CommunityTabs(currentTabActive)}
                currentTabActive={currentTabActive}
                setCurrentTabActive={handleChangeCurrentTabActive}
                tabsColor="#9671F5"
              />
              <S.MainContainer>
                {(() => {
                  switch (currentTabActive) {
                    case 'products':
                      return <ProductTabWithProducts refetchAlert={refetch} />
                    case 'packages':
                      return <PackageTabWithPackages />
                    case 'campaigns':
                      return <CampaignTab />
                    case 'sponsors':
                      return <SponsorTab />
                    case 'analytics':
                      return <AnalyticsTab />
                    default:
                      return null
                  }
                })()}
              </S.MainContainer>
            </S.GroupTabsColumn>
          )}

          <S.InviteSponsorGradientButton
            onClick={() => handleCheckCommunityInviteSponsorButton(setShowModalInviteSponsor)}
          >
            <S.InviteSponsorText>{t('inviteSponsor')}</S.InviteSponsorText>
          </S.InviteSponsorGradientButton>
          <S.ChatChannelColumn>
            <S.ChatListContainer>
              <ChatList entityId={id} userType="Community" />
            </S.ChatListContainer>
          </S.ChatChannelColumn>
        </S.MainRow>
      </S.PageContainer>

      {!loading && (
        <ModalEditUserProfile
          showModal={showModalEditUserProfile}
          handleCloseModal={() => setShowModalEditUserProfile(false)}
        />
      )}

      {!loading && communityIsRevised && (
        <ModalInviteSponsor
          handleCloseModal={() => setShowModalInviteSponsor(false)}
          showModal={showModalInviteSponsor}
          communityIsRevised={communityIsRevised}
        />
      )}

      <ModalStripeAccountCreationError
        handleCloseModal={() => setShowModalStripeCreateAccountError(false)}
        showModal={showModalStripeCreateAccountError}
      />
      <ModalStripeAccountCreationSuccess
        handleCloseModal={() => setShowModalStripeCreateAccountSuccess(false)}
        showModal={showModalStripeCreateAccountSuccess}
      />
      <ModalTutorialWelcome
        handleCloseModal={() => setShowModalTutorialWelcome(false)}
        showModal={showModalTutorialWelcome}
        openModalTutorialAllSteps={() => setShowModalTutorialAllSteps(true)}
      />
      <ModalTutorialAllSteps
        handleCloseModal={() => setShowModalTutorialAllSteps(false)}
        showModal={showModalTutorialAllSteps}
      />

      {/* TODO: Remove comment and re-implement when 2.0 apps are ready. 
      {!loading && !showModalTutorialAllSteps && !showModalTutorialWelcome && !isTutorialActive && (
        <ModalNotifySocialMediaConnection
          closeModal={() => setShowModalNotifySocialMediaConnection(false)}
          showModal={showModalNotifySocialMediaConnection}
          socialMediaNotConnected={socialMediaNotConnected}
        />
      )} */}
    </>
  )
}
