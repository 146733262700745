import React, { useEffect, useRef } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import gsap from 'gsap'

const CheckedIllustration = () => {
  const triggerRef = useRef(null)

  useEffect(() => {
    gsap.fromTo(
      triggerRef.current,
      { scale: 0, transformOrigin: 'center', ease: 'power1.inOut' },
      { duration: 0.3, scale: 1 },
    )
  }, [])

  return (
    <svg width="658" height="658" viewBox="0 0 658 658" fill="none" ref={triggerRef} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M328.533 0C147.133 0 0 147.36 0 328.387C0 509.68 147.133 657.067 328.533 657.067C509.667 657.067 657.067 509.707 657.067 328.387C657.067 147.333 509.667 0 328.533 0ZM328.533 40.74C487.613 40.74 616.307 169.376 616.307 328.393C616.307 487.713 487.609 616.327 328.533 616.327C169.147 616.327 40.76 487.733 40.76 328.393C40.76 169.353 169.145 40.74 328.533 40.74Z"
        fill="#54AB67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M461.64 191.144L275.96 376.491L193.433 294.001C181.413 282.251 173.417 284.595 160.324 296.038L141.985 314.626C129.444 328.454 130.037 338.115 141.48 348.995L261.449 469.167C269.007 476.839 282.923 476.839 290.48 469.167L515.133 244.607C528.055 231.091 529.169 224.195 515.133 209.727L496.029 190.634C483.54 178.472 473.567 179.217 461.64 191.144Z"
        fill="#54AB67"
      />
    </svg>
  )
}

export default CheckedIllustration
