import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { MdCompareArrows } from 'react-icons/md'
import { FaRegThumbsUp } from 'react-icons/fa'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as S from './styled'

export default function ProductsModal({
  handleCloseModal,
  showModal,
  leftColumn,
  rightColumn,
  modalTitle,
  buttonText,
  review,
  createMessage,
  approveCampaignProduct,
  sponsor,
  loadingUpload,
  loading,
}) {
  const { t } = useTranslation()

  const modalBodyStyle = {
    backgroundColor: '#f2f2f2',
  }

  const onCloseModal = () => {
    handleCloseModal()

    setTimeout(() => {
      // fixes react-bootstrap issue where
      // body scroll is blocked after modal is closed
      document.body.style.overflow = 'unset'
    }, 600)
  }

  const approveCampaignProductAndCloseModal = () => {
    approveCampaignProduct()
    onCloseModal()
  }

  return (
    <S.ModalCustom show={showModal} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        <Container fluid>
          <Row>
            <Col md={6}>{leftColumn}</Col>
            <Col md={6}>{rightColumn}</Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="mb-1">
        {review === false && (
          <S.SaveButton
            disabled={loadingUpload || loading}
            text={buttonText || t('save')}
            large
            icon={<S.SaveIcon />}
            loading={loading}
          />
        )}
        {review === true && sponsor === false && (
          <S.ButtonsContainer>
            <S.RequestChangeButtonContainer>
              <S.RequestChangeButton
                text={t('requestChange')}
                icon={<MdCompareArrows color="#f64859" size={26} />}
                onClick={createMessage}
              />
            </S.RequestChangeButtonContainer>
            <S.ApproveButton
              text={t('approve')}
              onClick={() => approveCampaignProductAndCloseModal()}
              icon={<FaRegThumbsUp color="#1b73e2" size={20} />}
            />
          </S.ButtonsContainer>
        )}
        {review === true && sponsor && (
          <S.ButtonsContainer>
            <S.DoneButton type="submit" text={t('done')} onClick={() => onCloseModal()} icon={<S.DoneIcon />} />
          </S.ButtonsContainer>
        )}
      </Modal.Footer>
    </S.ModalCustom>
  )
}
