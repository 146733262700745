import React, { useCallback, useMemo, useRef, useState } from 'react'
import { DayPicker, DayContent, useDayRender, Button } from 'react-day-picker'

import { useTranslation } from 'react-i18next'

import { isFirstDayOfMonth, isLastDayOfMonth, addMonths, format, isAfter } from 'date-fns'
import { it, ptBR, enUS } from 'date-fns/locale'

import 'react-day-picker/dist/style.css'

import i18n from '~/i18n'
import {
  modifiersClassNames,
  defaultInitialMonthNavigation,
  defaultFinalMonthNavigation,
  disabledByOutOfRangeDates,
} from './helpers/constants'
import { getReasonsForBlock, getBlockedDaysToCampaign, getDaysInRange } from './helpers/functions'

import * as S from './styles'

const DayComponent = ({ date, displayMonth }) => {
  const buttonRef = useRef(null)
  const dayRender = useDayRender(date, displayMonth, buttonRef)

  if (dayRender.isHidden) {
    return <></>
  }

  const { className } = dayRender.buttonProps

  return (
    <Button
      {...dayRender.buttonProps}
      className={`${className} ${isFirstDayOfMonth(date) ? 'first-day-of-month' : ''} ${
        isLastDayOfMonth(date) ? 'last-day-of-month' : ''
      }`}
      ref={buttonRef}
    />
  )
}

const DisabledPopup = ({ isNotAllowedRange, listOfReasons = [], onBlockedDayReport, rangeQtd, date }) => {
  const { t } = useTranslation()

  const handleBlockedDayReport = () => {
    onBlockedDayReport({
      date: format(new Date(date), 'dd MMMM yyyy'),
      duration: rangeQtd,
      formats: listOfReasons,
    })
  }

  return (
    <S.Container>
      <h3>{t('dateNotAvailable')}</h3>

      {isNotAllowedRange ? (
        <>
          <S.PopupText>{t('notAllowedRange')}</S.PopupText>

          <S.PopupText>{t('notAllowedRangeSuggestion')}</S.PopupText>

          <S.List>
            <S.ListItem>{t('removeFormatNotAvailable')}</S.ListItem>
            <S.ListItem>{t('modifyCampaignDuration')}</S.ListItem>
            <S.ListItem>{t('contactComunityToNegotiate')}</S.ListItem>
          </S.List>

          <S.ContactButton>{t('contactComunity')}</S.ContactButton>
        </>
      ) : (
        <>
          <S.PopupText>{t('dateInConflict')}</S.PopupText>

          <S.List>
            {listOfReasons.map(reason => (
              <S.ListItem key={reason}>{reason}</S.ListItem>
            ))}
          </S.List>

          <S.PopupText>{t('notAllowedRangeSuggestion')}</S.PopupText>

          <S.List>
            <S.ListItem>{t('modifyCampaignDuration')}</S.ListItem>
            <S.ListItem>{t('contactComunityToNegotiate')}</S.ListItem>
          </S.List>

          <S.ContactButton type="button" onClick={handleBlockedDayReport}>
            {t('contactComunity')}
          </S.ContactButton>
        </>
      )}
    </S.Container>
  )
}

const CustomDayContent = props => {
  const { date, activeModifiers, blockedDates, onBlockedDayReport, rangeQtd } = props
  const { disabledByBlockedDates, disabledByRangeAvailability } = activeModifiers

  const reasons = useMemo(() => getReasonsForBlock({ blockedDates, date }), [blockedDates, date])

  return (
    <time>
      <DayContent {...props} />

      {Boolean(disabledByBlockedDates) || Boolean(disabledByRangeAvailability) ? (
        <S.DayAlert>
          <DisabledPopup
            isNotAllowedRange={Boolean(disabledByRangeAvailability && !disabledByBlockedDates)}
            listOfReasons={reasons}
            onBlockedDayReport={onBlockedDayReport}
            rangeQtd={rangeQtd}
            date={date}
          />
        </S.DayAlert>
      ) : null}
    </time>
  )
}

const locales = {
  en: enUS,
  it,
  pt: ptBR,
}

const CustomDayPicker = ({ onChange, onBlockedDayReport, ...props }) => {
  const { selected, rangeQtd, blockedDates, disabled: isComponentDisabled } = props

  const [currMonth, setCurrMonth] = useState(new Date())

  const disabledByBlockedDates = useMemo(
    () => blockedDates?.reduce((acc, curr) => [...acc, ...curr?.formattedDates], []),
    [blockedDates],
  )

  const disabledByRangeAvailability = useMemo(
    () =>
      getBlockedDaysToCampaign({
        blockDays: disabledByBlockedDates,
        durationInDays: rangeQtd * 30,
        setCurrMonth,
      }),
    [disabledByBlockedDates, rangeQtd],
  )

  const modifiers = useMemo(() => {
    const selectedRangeDays =
      selected && rangeQtd
        ? getDaysInRange({
            date: selected,
            durationInDays: rangeQtd * 30,
          })
        : []

    const firstSelectedDay = selectedRangeDays[0]
    const lastSelectedDay = selectedRangeDays[selectedRangeDays.length - 1]

    const disabled = [...disabledByOutOfRangeDates, ...disabledByBlockedDates, ...disabledByRangeAvailability]
    const selectedRange = [{ from: firstSelectedDay, to: lastSelectedDay }]

    return {
      disabledByOutOfRangeDates,
      disabledByBlockedDates,
      disabledByRangeAvailability,
      disabled,
      selected: selectedRange,
      firstSelectedDay,
      lastSelectedDay,
    }
  }, [selected, rangeQtd, disabledByBlockedDates, disabledByRangeAvailability])

  const handleOnChange = useCallback(
    date => {
      if (!isComponentDisabled && onChange) {
        return onChange(date)
      }

      return null
    },
    [isComponentDisabled, onChange],
  )

  const selectedDateFinalRange = selected ? addMonths(selected, rangeQtd) : undefined

  const finalMonthNavigation =
    selectedDateFinalRange && isAfter(selectedDateFinalRange, defaultFinalMonthNavigation)
      ? selectedDateFinalRange
      : defaultFinalMonthNavigation

  return (
    <S.DayPickerWrapper className={isComponentDisabled ? 'disabled' : undefined}>
      <DayPicker
        modifiers={modifiers}
        disabled={!!isComponentDisabled}
        modifiersClassNames={modifiersClassNames}
        numberOfMonths={2}
        components={{
          Day: ({ date, displayMonth }) => DayComponent({ date, displayMonth }),
          DayContent: dayProps => (
            <CustomDayContent
              {...dayProps}
              blockedDates={blockedDates}
              onBlockedDayReport={onBlockedDayReport}
              rangeQtd={rangeQtd}
            />
          ),
        }}
        locale={locales[i18n.language]}
        month={currMonth}
        pagedNavigation
        fixedWeeks
        onMonthChange={setCurrMonth}
        fromMonth={defaultInitialMonthNavigation}
        toMonth={finalMonthNavigation}
        onChange={handleOnChange}
        onSelect={handleOnChange}
        {...props}
      />
    </S.DayPickerWrapper>
  )
}

export default CustomDayPicker
