import * as actions from './actionTypes'

export function signInRequest(credentials) {
  return {
    type: actions.SIGN_IN_REQUEST,
    payload: credentials,
  }
}

export function signInSponsorRequest(credentials) {
  return {
    type: actions.SIGN_IN_SPONSOR_REQUEST,
    payload: credentials,
  }
}

export function signInSuccess(token, firebaseToken) {
  return {
    type: actions.SIGN_IN_SUCCESS,
    payload: { token, firebaseToken },
  }
}

export function signInLoadingSuccess(token) {
  return {
    type: actions.SIGN_IN_LOADING_SUCCESS,
    payload: { token },
  }
}

export function signInCommunitySuccess(user, communities) {
  return {
    type: actions.SIGN_IN_COMMUNITY_SUCCESS,
    payload: { user, communities },
  }
}

export function signInSponsorSuccess(user, company, token, firebaseToken) {
  return {
    type: actions.SIGN_IN_SPONSOR_SUCCESS,
    payload: { user, company, token, firebaseToken },
  }
}

export function signFailure() {
  return {
    type: actions.SIGN_FAILURE,
  }
}

export function setWrongUserTryingToLogin(boolean) {
  return {
    type: actions.SET_WRONG_USER_TRYING_TO_LOGIN,
    payload: boolean,
  }
}

export function signOut() {
  return {
    type: actions.SIGN_OUT,
  }
}

export function signUpCommunityRequest(data) {
  return {
    type: actions.SIGN_UP_COMMUNITY_REQUEST,
    payload: data,
  }
}

export function signUpCommunityLead(data) {
  return {
    type: actions.SIGN_UP_COMMUNITY_LEAD,
    payload: data,
  }
}

export function signUpSponsorRequest(data, setLoading) {
  return {
    type: actions.SIGN_UP_SPONSOR_REQUEST,
    payload: { data, setLoading },
  }
}
