import styled from 'styled-components'

import { darken } from 'polished'

export const FormSectionContainer = styled.div`
  margin-top: 16px;
`

export const InputTitle = styled.span`
  color: #4f5559;
  font-family: Inter;
  font-size: 15px;
  font-weight: bold;

  &.disabled {
    opacity: 0.5;
  }
`

export const ErrorMessageContainer = styled.div.attrs({
  className: 'error-message',
})``

export const FormatSelectorWrapper = styled.div`
  display: flex;

  align-items: flex-start;
  justify-content: space-between;

  flex-direction: column;

  border: 1.5px solid #d0cfcf;

  border-radius: 12px;
  padding: 10px 12px 10px 12px;
  margin-top: 5px;

  width: 100%;
`

export const SelectContainer = styled.div`
  display: flex;

  align-items: flex-start;
  justify-content: space-between;

  border-radius: 12px;
  margin-top: 5px;

  width: 100%;

  .select-element {
    width: 100%;
  }
`

export const ColumnSelectContainer = styled(SelectContainer)`
  flex-direction: column;
`

export const ListProductsSelectedByTheUser = styled.div`
  border: 2px solid rgba(214, 214, 214, 0.44);
  margin-top: 8px;
  border-radius: 10px;
  padding: 0px 12px 0px 12px;
`

export const ProductInformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
`

export const ProductInformationText = styled.span`
  color: var(--grey);
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
`

export const DeleteProductIcon = styled.img.attrs({
  alt: 'Delete Product',
  src: '/icons/delete-icon.png',
})`
  cursor: pointer;
  width: 22px;
  height: 22px;

  &:hover {
    opacity: 0.8;
  }
`

export const Border = styled.p`
  border: solid rgba(214, 214, 214, 0.44);
  border-width: 1px;
  margin-bottom: 0;
  width: 106%;
  margin-left: -11px;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  p {
    margin: 0;

    font-family: 'Inter';
    color: #9d9d9d;
  }
`

export const FormatsControllerButton = styled.button`
  font-family: Inter;

  font-weight: 700;
  background-color: #f0f0f0;
  padding: 16px;

  width: 100%;

  border: none;

  border-radius: 5px;

  color: #373737;

  transition: background-color 200ms;

  :hover {
    background-color: ${darken(0.05, '#f0f0f0')};
  }
`

export const PackageInformationContainer = styled.div.attrs({
  className: 'animated fadeIn',
})`
  margin-top: 20px;
  transition: all 300ms;
`

export const PackageComposition = styled.p`
  margin-top: 24px;
  margin-bottom: 14px;
  color: #494e51;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
`

export const FormatDetailsCardContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(2, 1fr);

  gap: 16px;
`
export const PackageControllersWrapper = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;

  width: 50%;
`
export const Separator = styled.hr`
  width: 50px;
  rotate: 90deg;
  border-style: dashed;
  border-color: #e3e3e3;
  border-width: 1px;

  margin: 0;

  transform: translateY(25px);
`
