import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import Col from 'react-bootstrap/Col'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import CampaignReviewProductsButtons from '~/components/Community/CampaignReviewProductsButtons'
import ProductBadge from '~/components/Shared/ProductBadge'
import * as S from './styled'

export function SingleContentReviewCard({ contents }) {
  const { t } = useTranslation()

  const posts = []
  if (contents.contentType === 'post') {
    posts.push(contents)
  }

  const videos = []
  if (contents.contentType === 'video') {
    videos.push(contents)
  }

  const webs = []
  if (contents.contentType === 'web') {
    webs.push(contents)
  }

  const documents = []
  if (contents.contentType === 'document') {
    documents.push(contents)
  }

  return (
    <Container fluid>
      <S.CardBody>
        <div className="icon-section">
          <img style={{ opacity: '.9' }} src="/icons/rewards.png" alt="Rewards" width="19" height="19" />
          <p className="ml-2 mt-2">Content</p>
        </div>
        <S.AnalyticsCardRow>
          {posts.length > 0 &&
            posts.map(post => (
              <Col className="mt-4" key={post.id} md={12}>
                <PostCard
                  t={t}
                  title={post.unity.title}
                  about={post.unity.about}
                  link={post.unity.link}
                  image={post.unity.imageUrl}
                  analytics={post.analytics}
                />
              </Col>
            ))}

          {videos.length > 0 &&
            videos.map(video => (
              <Col className="mt-4" key={video.id} md={12}>
                <VideoCard
                  t={t}
                  key={video.id}
                  title={video.unity.scrapperTitle}
                  about={video.unity.scrapperDescription}
                  image={video.unity.imageUrl}
                  analytics={video.analytics}
                />
              </Col>
            ))}

          {webs.length > 0 &&
            webs.map(web => (
              <Col className="mt-4" key={web.id} md={12}>
                <WebCard
                  t={t}
                  title={web.unity.scrapperTitle}
                  about={web.unity.scrapperDescription}
                  link={web.unity.webpage}
                  image={web.unity.imageUrl}
                  analytics={web.analytics}
                />
              </Col>
            ))}

          {documents.length > 0 &&
            documents.map(document => (
              <Col className="mt-4" key={document.id} md={12}>
                <DocumentCard
                  t={t}
                  key={document.id}
                  title={document.unity.title}
                  image={document.unity.imageUrl}
                  about={document.unity.about}
                  analytics={document.analytics}
                />
              </Col>
            ))}
        </S.AnalyticsCardRow>
      </S.CardBody>
    </Container>
  )
}

export function PostCard({ title, about, image, analytics, t }) {
  return (
    <S.CardRewardBody>
      <Row>
        <Col className="mb-4">
          <p className="content-title">{title}</p>
        </Col>
      </Row>

      <Row className="d-flex align-items-start">
        <Col md={6}>
          <S.CardContainer>
            <S.ContainerContentImage>
              <S.ContentImage
                src={image == null ? '/community-default-banner-picture.jpg' : image}
                alt="Campaign Logo"
              />
            </S.ContainerContentImage>
            <ProductBadge
              style={{ marginLeft: '10px', marginBottom: '10px !important' }}
              text="post"
              color="#0059b3"
              width="60px"
              height="25px"
              eventText="- artificial intelligence"
              eventTextColor="var(--input-title)"
              fontSize="12px"
              eventTextFontSize="13px"
              marginTop="10px"
            />
            <div className="content-card-title">
              <p>{title}</p>
            </div>
            <div className="content-card-about ">
              <p>{about}</p>
            </div>
          </S.CardContainer>
        </Col>

        <Col md={6} className="p-0 product-buttons">
          <CampaignReviewProductsButtons
            style={{ marginRight: '10px' }}
            text={t('views')}
            number={analytics?.countViews ?? 0}
            color="#25B9CD"
            icon={<img src="/icons/campaignReview/view.png" alt="Views" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            text={t('clickThrough')}
            number={analytics?.countClicksThrough ?? 0}
            color="#35CC9E"
            icon={<img src="/icons/campaignReview/linkview.png" alt="Click through" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            style={{ marginRight: '10px', marginTop: '10px' }}
            text={t('comments')}
            number={analytics?.comments ?? 0}
            color="#FC930E"
            icon={<img src="/icons/campaignReview/comments.png" alt="Comments" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            style={{ marginTop: '10px' }}
            text={t('shares')}
            number={analytics?.shares ?? 0}
            color="#E94440"
            icon={<img src="/icons/campaignReview/share.png" alt="Shares" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            style={{ marginRight: '10px', marginTop: '10px' }}
            text={t('saves')}
            number={analytics?.saves ?? 0}
            color="#49A54D"
            icon={<img src="/icons/campaignReview/bookmark.png" alt="Saves" width="24" height="24" />}
          />
        </Col>
      </Row>
    </S.CardRewardBody>
  )
}

export function VideoCard({ title, about, image, analytics, t }) {
  return (
    <S.CardRewardBody>
      <Row>
        <Col className="mb-4">
          <p className="content-title">{title}</p>
        </Col>
      </Row>

      <Row className="d-flex align-items-start">
        <Col md={6}>
          <S.CardContainer>
            <S.ContainerContentImage>
              <LazyLoadImage width="100%" height="100%" effect="blur" src={image} />
            </S.ContainerContentImage>
            <ProductBadge
              style={{ marginLeft: '10px', marginBottom: '10px !important' }}
              text="video"
              color="#ff751a"
              width="60px"
              height="25px"
              eventText="- artificial intelligence"
              eventTextColor="var(--input-title)"
              fontSize="12px"
              eventTextFontSize="13px"
              marginTop="10px"
            />
            <div className="content-card-title">
              <p>{title}</p>
            </div>
            <div className="content-card-about">
              <p>{about}</p>
            </div>
          </S.CardContainer>
        </Col>

        <Col md={6} className="p-0 product-buttons">
          <CampaignReviewProductsButtons
            style={{ marginRight: '10px' }}
            text={t('views')}
            number={analytics?.countViews ?? 0}
            color="#25B9CD"
            icon={<img src="/icons/campaignReview/view.png" alt="Views" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            text={t('clickThrough')}
            number={analytics?.countClicksThrough ?? 0}
            color="#35CC9E"
            icon={<img src="/icons/campaignReview/linkview.png" alt="Click through" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            style={{ marginRight: '10px', marginTop: '10px' }}
            text={t('comments')}
            number={analytics?.comments ?? 0}
            color="#FC930E"
            icon={<img src="/icons/campaignReview/comments.png" alt="Comments" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            style={{ marginTop: '10px' }}
            text={t('shares')}
            number={analytics?.shares ?? 0}
            color="#E94440"
            icon={<img src="/icons/campaignReview/share.png" alt="Shares" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            style={{ marginRight: '10px', marginTop: '10px' }}
            text={t('saves')}
            number={analytics?.saves ?? 0}
            color="#49A54D"
            icon={<img src="/icons/campaignReview/bookmark.png" alt="Saves" width="24" height="24" />}
          />
        </Col>
      </Row>
    </S.CardRewardBody>
  )
}

export function WebCard({ title, image, about, analytics, t }) {
  return (
    <S.CardRewardBody>
      <Row>
        <Col className="mb-4">
          <p className="content-title">{title}</p>
        </Col>
      </Row>

      <Row className="d-flex align-items-start">
        <Col md={6}>
          <S.CardContainer>
            <S.ContainerContentImage>
              <LazyLoadImage width="100%" height="100%" effect="blur" src={image} />
            </S.ContainerContentImage>
            <ProductBadge
              style={{ marginLeft: '10px', marginBottom: '10px !important' }}
              text="web"
              color="#b366ff"
              width="60px"
              height="25px"
              eventText="- artificial intelligence"
              eventTextColor="var(--input-title)"
              fontSize="12px"
              eventTextFontSize="13px"
              marginTop="10px"
            />
            <div className="content-card-title">
              <p>{title}</p>
            </div>
            <div className="content-card-about">
              <p>{about}</p>
            </div>
          </S.CardContainer>
        </Col>

        <Col md={6} className="p-0 product-buttons">
          <CampaignReviewProductsButtons
            style={{ marginRight: '10px' }}
            text={t('views')}
            number={analytics?.countViews ?? 0}
            color="#25B9CD"
            icon={<img src="/icons/campaignReview/view.png" alt="Views" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            text={t('clickThrough')}
            number={analytics?.countClicksThrough ?? 0}
            color="#35CC9E"
            icon={<img src="/icons/campaignReview/linkview.png" alt="Click through" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            style={{ marginRight: '10px', marginTop: '10px' }}
            text={t('comments')}
            number={analytics?.comments ?? 0}
            color="#FC930E"
            icon={<img src="/icons/campaignReview/comments.png" alt="Comments" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            style={{ marginTop: '10px' }}
            text={t('shares')}
            number={analytics?.shares ?? 0}
            color="#E94440"
            icon={<img src="/icons/campaignReview/share.png" alt="Shares" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            style={{ marginRight: '10px', marginTop: '10px' }}
            text={t('saves')}
            number={analytics?.saves ?? 0}
            color="#49A54D"
            icon={<img src="/icons/campaignReview/bookmark.png" alt="Saves" width="24" height="24" />}
          />
        </Col>
      </Row>
    </S.CardRewardBody>
  )
}

export function DocumentCard({ title, about, image, analytics, t }) {
  return (
    <S.CardRewardBody>
      <Row>
        <Col className="mb-4">
          <p className="content-title">{title}</p>
        </Col>
      </Row>

      <Row className="d-flex align-items-start">
        <Col md={6}>
          <S.CardContainer>
            <S.ContainerContentImage>
              <LazyLoadImage
                width="100%"
                height="100%"
                effect="blur"
                src={image === null || image === undefined ? '/community-default-banner-picture.jpg' : image}
              />
            </S.ContainerContentImage>
            <ProductBadge
              style={{ marginLeft: '10px', marginBottom: '10px !important' }}
              text="document"
              color="#050a14"
              width="90px"
              height="25px"
              eventText="- big data"
              eventTextColor="var(--input-title)"
              fontSize="12px"
              eventTextFontSize="13px"
              marginTop="10px"
            />
            <div className="content-card-title">
              <p>{title}</p>
            </div>
            <div className="content-card-about">
              <p>{about}</p>
            </div>
          </S.CardContainer>
        </Col>

        <Col md={6} className="p-0 product-buttons">
          <CampaignReviewProductsButtons
            style={{ marginRight: '10px' }}
            text={t('views')}
            number={analytics?.countViews ?? 0}
            color="#25B9CD"
            icon={<img src="/icons/campaignReview/view.png" alt="Views" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            text={t('clickThrough')}
            number={analytics?.countClicksThrough ?? 0}
            color="#35CC9E"
            icon={<img src="/icons/campaignReview/linkview.png" alt="Click through" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            style={{ marginRight: '10px', marginTop: '10px' }}
            text={t('comments')}
            number={analytics?.comments ?? 0}
            color="#FC930E"
            icon={<img src="/icons/campaignReview/comments.png" alt="Comments" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            style={{ marginTop: '10px' }}
            text={t('shares')}
            number={analytics?.shares ?? 0}
            color="#E94440"
            icon={<img src="/icons/campaignReview/share.png" alt="Shares" width="24" height="24" />}
          />
          <CampaignReviewProductsButtons
            style={{ marginRight: '10px', marginTop: '10px' }}
            text={t('saves')}
            number={analytics?.saves ?? 0}
            color="#49A54D"
            icon={<img src="/icons/campaignReview/bookmark.png" alt="Saves" width="24" height="24" />}
          />
        </Col>
      </Row>
    </S.CardRewardBody>
  )
}
