import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import Col from 'react-bootstrap/Col'
import CampaignReviewProductsButtons from '~/components/Community/CampaignReviewProductsButtons'
import ProductBadge from '~/components/Shared/ProductBadge'
import * as S from './styled'

export function RewardReviewCard({ rewards, singleContent }) {
  const { t } = useTranslation()

  return (
    <Container fluid>
      <S.CardBody>
        <div className="icon-section">
          <img style={{ opacity: '.9' }} src="/icons/rewards.png" alt="Rewards" width="19" height="19" />
          <p className="ml-2 mt-2">{t('rewards')}</p>
        </div>
        <S.AnalyticsCardRow>
          {rewards.map(reward => (
            <RewardCard
              key={document.id}
              title={reward.unity.title}
              additionalInfo={reward.unity.additionalInfo}
              image={reward.unity.imageUrl}
              analytics={reward.analytics}
              singleContent={singleContent}
              rewardCost={reward.unity.rewardCost}
            />
          ))}
        </S.AnalyticsCardRow>
      </S.CardBody>
    </Container>
  )
}

export function RewardCard({ title, additionalInfo, image, analytics, singleContent, rewardCost }) {
  const { t } = useTranslation()

  return (
    <Col md={singleContent ? 12 : 6} className="mt-4">
      <S.CardRewardBody>
        <Row>
          <Col className="mb-4">
            <p className="reward-title">{title}</p>
          </Col>
        </Row>

        <Row className="d-flex align-items-start">
          <Col md={6}>
            <S.CardContainer>
              <S.ContainerRewardImage>
                <S.RewardImage
                  src={image === null || image === undefined ? '/community-default-banner-picture.jpg' : image}
                  alt="Campaign Logo"
                />
              </S.ContainerRewardImage>
              <ProductBadge
                style={{ marginLeft: '10px', marginBottom: '10px !important' }}
                text={t('reward')}
                color="#cccc00"
                width="60px"
                height="25px"
                eventText="- web"
                eventTextColor="var(--input-title)"
                fontSize="12px"
                eventTextFontSize="13px"
                marginTop="10px"
              />
              <div className="reward-card-title">
                <p>{title}</p>
              </div>
              <div className="reward-card-about ">
                <p>{additionalInfo}</p>
              </div>
              <div className="d-flex justify-content-end">
                <S.RewardCostContainer>
                  <S.RewardCost>{rewardCost}</S.RewardCost>
                  <S.RewardCostIcon />
                </S.RewardCostContainer>
              </div>
            </S.CardContainer>
          </Col>

          <Col md={6} className="p-0 product-buttons">
            <CampaignReviewProductsButtons
              style={{ marginRight: '10px' }}
              text={t('views')}
              number={analytics?.countViews ?? 0}
              color="#25B9CD"
              icon={<img src="/icons/campaignReview/view.png" alt="Views" width="24" height="24" />}
            />
            <CampaignReviewProductsButtons
              text={t('clickThrough')}
              number={analytics?.countClicksThrough ?? 0}
              color="#35CC9E"
              icon={<img src="/icons/campaignReview/linkview.png" alt="Click through" width="24" height="24" />}
            />
            <CampaignReviewProductsButtons
              style={{ marginRight: '10px', marginTop: '10px' }}
              text={t('comments')}
              number={analytics?.comments ?? 0}
              color="#FC930E"
              icon={<img src="/icons/campaignReview/comments.png" alt="Comments" width="24" height="24" />}
            />
            <CampaignReviewProductsButtons
              style={{ marginTop: '10px' }}
              text={t('shares')}
              number={analytics?.shares ?? 0}
              color="#E94440"
              icon={<img src="/icons/campaignReview/share.png" alt="Shares" width="24" height="24" />}
            />

            <CampaignReviewProductsButtons
              style={{ marginRight: '10px' }}
              text={t('saves')}
              number={analytics?.saves ?? 0}
              color="#49A54D"
              icon={<img src="/icons/campaignReview/bookmark.png" alt="Saves" width="24" height="24" />}
            />
            <CampaignReviewProductsButtons
              style={{ marginRight: '10px', marginTop: '10px' }}
              text={t('claims')}
              number={analytics?.claims ?? 0}
              color="#9B33B2"
              icon={<img src="/icons/campaignReview/claims.png" alt="Claims" width="24" height="24" />}
            />
          </Col>
        </Row>
      </S.CardRewardBody>
    </Col>
  )
}
