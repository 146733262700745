import styled from 'styled-components'

export const PageContainer = styled.div`
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;

  font-family: 'Catamaran';

  p,
  h1,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0 !important;
  }

  background-color: transparent;

  position: relative;
`

export const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  width: 100rem;
`
