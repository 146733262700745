import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { RiSave2Line } from 'react-icons/ri'
import WhiteButton from '~/components/WhiteButton'
import ProfileButton from '~/components/ProfileButton'
import { customMedia } from '~/styles/global'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    max-width: 850px;
    height: auto;
    margin: auto;
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;

    ${customMedia.lessThan('small')`
      margin-bottom: 0px;
    `}
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;

    ${customMedia.lessThan('small')`
      width: 22rem !important;
      margin: auto;
    `}
  }
`

export const ContainerBannerImage = styled.div`
  position: relative;
  text-align: center;
  background-color: #f2f2f2;
  height: 220px;
`

export const InputTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const IsRequired = styled.span`
  color: var(--sponsor-primary);
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-left: 4px;
`

export const HelperText = styled.span`
  margin-left: 5px;
  color: var(--text-primary);
  font-family: Lato;
  font-style: italic;
  font-size: 14px;
`

export const InputLabel = styled.span`
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
`

export const ContainerSponsorProfilePicture = styled.div`
  position: absolute;
  width: 141px;
  height: 141px;
  left: 30px;
  bottom: 0px;
  border-radius: 100%;
  background-color: #fff;
  border: 5px solid #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  z-index: 10;
`

export const CommunityProfilePicture = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`

export const FormContainer = styled.div`
  .error-message {
    font-family: Roboto;
    color: #ea343e;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.9;
  }

  .MuiOutlinedInput-multiline {
    height: 80px !important;
    padding: 10px 14px !important;
  }

  .headquarter-address-section {
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      height: 40px;
      padding-right: 45px;
      box-sizing: border-box;
      background: url('google-logo.png') no-repeat 98%;
      background-size: 26px;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #c4c4c4 !important;

      padding-left: 10px;
      font-family: Lato;
      font-size: 16px;
      font-weight: 500;
      line-height: 14px;
    }
  }
`

export const InputContainer = styled.div`
  display: flex;
  margin-top: 10px;

  .MuiInputBase-root,
  .MuiOutlinedInput-root,
  .MuiInputBase-fullWidth,
  .MuiInputBase-formControl {
    height: 50px !important;
  }

  .Mui-focused fieldset {
    border-color: #50a5d2 !important;
    border-width: 1px !important;
  }
`

export const Title = styled.p`
  color: var(--text-bold);
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
`

export const NotNow = styled.p`
  color: var(--text-primary);
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
  margin: 0px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

export const BannerImage = styled.img`
  width: 100%;
  height: 157px;
  object-fit: cover;
  position: relative;
  filter: brightness(90%);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
`

export const CopyLinkSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  p {
    margin: 0;
    color: #47525e;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    opacity: 0.9;
  }
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between !important;
  align-items: baseline;
  margin-bottom: 4px;
  padding-top: 14px;
`

export const SaveButton = styled(WhiteButton).attrs({
  type: 'submit',
  style: { marginTop: '6px' },
})``

export const SaveIcon = styled(RiSave2Line).attrs({
  color: 'var(--success)',
  size: 23,
})``

export const CancelButton = styled(WhiteButton).attrs({
  style: { marginTop: '6px', padding: '0px 30px' },
})``

export const FacebookIcon = styled.img.attrs({
  src: '/icons/facebook.svg',
})`
  width: 24px;
  height: 19px;
`

export const YoutubeIcon = styled.img.attrs({
  src: 'youtube.png',
})`
  width: 24px;
  height: 19px;
`

export const TwitterIcon = styled.img.attrs({
  src: 'twitter-transparent.png',
})`
  width: 22px;
  height: 22px;
`

export const WebsiteIcon = styled.img.attrs({
  src: '/geowebsite.png',
})`
  width: 22px;
  height: 22px;
  border-radius: 5px;
`

export const EditBannerButton = styled(ProfileButton).attrs({
  style: {
    position: 'absolute',
    top: '20px',
    right: '30px',
  },
  backgroundColor: '#00A2FF',
})``

export const EditLogoButton = styled(ProfileButton).attrs({
  style: {
    position: 'absolute',
    top: '100px',
    right: '-6px',
  },
  backgroundColor: '#00A2FF',
})``
