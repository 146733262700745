import styled from 'styled-components'

export const Container = styled.div`
  width: 80%;
  margin: 0 auto;
`

export const StripeElementContainer = styled.div`
  padding-top: 5px;

  input {
    padding: 0.75rem;
    font-size: 0.93rem;
    background-color: #fff;
    border-radius: 5px;
    transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);

    &::placeholder {
      color: #757680 !important;
    }

    &:focus {
      outline: 0px;
      border-color: hsla(210, 96%, 45%, 50%);
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%),
        0 1px 1px 0 rgba(0, 0, 0, 0.08);
    }
  }

  .MuiInput-underline::before,
  .MuiInput-underline::after {
    display: none;
  }

  .StripeElement {
    margin: 0 auto;
    width: 100% !important;
  }

  // Styling the Stripe IBAN Element
  &.iban-container {
    transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);

    &:has(.StripeElement--focus) {
      outline: 0px;
      border-color: hsla(210, 96%, 45%, 50%);
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%),
        0 1px 1px 0 rgba(0, 0, 0, 0.08);
    }
  }
`

export const Label = styled.label`
  font-weight: 300;
  letter-spacing: 0.025em;
  padding: 0;
  margin-bottom: 2px !important;
  font-size: 0.93rem;
  color: #30313d;
  text-align: center;
`
