import styled from 'styled-components'

export const FormContainer = styled.div`
  .input-date {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    height: 40px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(159, 159, 159, 0.7);
    border-radius: 5px;
    padding-left: 5px;
  }
`

export const InputTitle = styled.span`
  margin-top: 3px;
  color: #404257;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
`

export const ProductTitle = styled.span`
  margin-top: 3px;
  color: #fff;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
  width: 22.5rem;
  height: 44rem;
  border: 2px solid #ccc;
  border-radius: 30px;
  position: relative;
  background: #fff;
  margin-left: 5rem;
`

export const DeviceScreen = styled.div`
  background-color: #000;
  width: 21.2rem;
  height: 37rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 55px;
`

export const DeviceButton = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0 -10px;
  border-radius: 50%;
`

export const BorderContainer = styled.div.attrs({
  mb: 2,
})`
  margin-top: 24px;
`
