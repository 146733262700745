/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import getProductImage from '~/helper/getProductImage'
import * as S from './styled'

export default function AddProductCard({
  product,
  advProducts,
  addProductsToProductsArray,
  productsAdded,
  isProductFromPayAsYouGoForm,
}) {
  const { t } = useTranslation()
  const isFirstRun = useRef(true)
  const {
    id: productId,
    name: productName,
    description: productDescription,
    unitPrice,
    name,
    slug,
    productType,
  } = product
  const [productQuantity, setProductQuantity] = useState(0)

  const productObject = {
    id: productId,
    productName: name,
    qtdPerMonth: productQuantity,
    unitPrice: parseInt(unitPrice, 10),
    productType: product.slug,
    productDescription,
  }

  const addProductQuantity = () => {
    setProductQuantity(prevState => prevState + 1)
  }

  const removeProductQuantity = () => {
    if (productQuantity === 0) return
    setProductQuantity(prevState => prevState - 1)
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
    } else {
      addProductsToProductsArray([...advProducts, productObject])

      advProducts.forEach((product, index) => {
        if (product.id === productId) {
          const newAdvProductsArray = [...advProducts]
          newAdvProductsArray[index].qtdPerMonth = productQuantity
          addProductsToProductsArray(newAdvProductsArray)
        }
      })
    }
  }, [productQuantity])

  useEffect(() => {
    const setQuantityOfPackageProducts = () => {
      if (productsAdded !== undefined) {
        productsAdded.forEach(packageProduct => {
          if (packageProduct.id === product.id) {
            if (isProductFromPayAsYouGoForm) {
              return setProductQuantity(packageProduct.qtdPerMonth)
            }
            return setProductQuantity(packageProduct.quantity.qtdPerMonth)
          }
          return null
        })
      }
    }
    setQuantityOfPackageProducts()
  }, [productsAdded])

  return (
    <S.CardContainer>
      <S.ImageAndInfoContainer>
        <S.FormatImageContainer>
          <S.FormatImage src={getProductImage(productType)} />
        </S.FormatImageContainer>
        <S.TitleAndDescriptionSection>
          {productType === 'custom' ? (
            <S.FormatName>{productName}</S.FormatName>
          ) : (
            <S.FormatName>{t(slug)}</S.FormatName>
          )}
          <S.FormatDescription>{productDescription}</S.FormatDescription>
        </S.TitleAndDescriptionSection>
      </S.ImageAndInfoContainer>

      <S.AddAndRemoveSection>
        <S.DecreaseProductIcon onClick={removeProductQuantity} color="#a2a5a9" size={34} />
        <S.Quantity>{productQuantity}</S.Quantity>
        <S.AddProductIcon onClick={addProductQuantity} color="#a2a5a9" size={34} />
      </S.AddAndRemoveSection>
    </S.CardContainer>
  )
}
