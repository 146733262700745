import styled from 'styled-components'
import { FaHeart } from 'react-icons/fa'
import { AiOutlineLike, AiFillLike } from 'react-icons/ai'
import { RiShareForwardLine } from 'react-icons/ri'
import { IoChatboxOutline } from 'react-icons/io5'
import { BiWorld, BiDotsHorizontalRounded } from 'react-icons/bi'
import { IoIosImages } from 'react-icons/io'

export const TopPreviewContainer = styled.div`
  background-color: #242527;
  padding: 14px;
`

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
`

export const Option = styled.p`
  width: 52px;
  height: 30px;
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  margin-left: 4px;
  line-height: 27px;
  text-align: center;
`

export const OptionSelected = styled(Option)`
  background-color: #2a3850;
  border-radius: 50px;
  color: #477de6;
`

export const LikeIcon = styled(AiOutlineLike).attrs({
  size: 21,
  color: '#a6a9ab',
})``

export const ImageIcon = styled(IoIosImages).attrs({
  size: 28,
  color: 'red',
})``

export const LikeFilledIcon = styled(AiFillLike).attrs({
  size: 14,
  color: '#FFF',
})``

export const LoveIcon = styled(FaHeart).attrs({
  size: 12,
  color: '#FFF',
})``

export const ShareIcon = styled(RiShareForwardLine).attrs({
  size: 21,
  color: '#a6a9ab',
})``

export const CommentIcon = styled(IoChatboxOutline).attrs({
  size: 21,
  color: '#a6a9ab',
})``

export const WorldIcon = styled(BiWorld).attrs({
  size: 16,
  color: '#a6a9ab',
})``

export const ThreeDotsIcon = styled(BiDotsHorizontalRounded).attrs({
  size: 21,
  color: '#a6a9ab',
})`
  margin-top: 10px;
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.spacing ? '8px 22px' : '0px 6px')};
  border-top: ${props => (props.spacing ? '1px solid #313335' : '')};
  width: 100%;
`

export const CommentsAndShares = styled.div`
  display: flex;
`

export const Action = styled.div`
  display: flex;
`

export const ActionText = styled.p`
  color: #a6a9ab;
  font-family: Lato;
  font-size: 14px;
  margin: 0px;
  margin-left: 4px;
`

export const MiddlePreviewContainer = styled.div`
  margin-top: 6px;
  background-color: #242527;
`

export const PostHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 14px 0px 14px;
`

export const CommunityInformations = styled.div`
  display: flex;
`

export const CommunityLogo = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
`

export const CommunityNameAndDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3px;
`

export const DateAndWorldIcon = styled.div`
  display: flex;
  align-items: center;
  margin-top: -3px;
`

export const Dot = styled.div`
  margin: 0px 3px 0px 5px;
  color: #a6a9ab;
`

export const CommunityName = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  max-width: 220px;
`

export const Date = styled.p`
  color: #a6a9ab;
  font-family: Lato;
  font-size: 13px;
  margin-bottom: 0px;
  margin-left: 4px;
  margin-top: -3px;
`

export const PostComment = styled.p`
  color: #e4e6eb;
  font-family: Lato;
  font-size: 14px;
  margin: 6px 0px 0px 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  max-width: 300px;
  max-height: 120px;
`

export const PostCommentAndDescription = styled(PostComment)`
  display: -webkit-box;
`

export const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  height: 230px;
`

export const MediaImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const NoMediaIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  height: 230px;
  background-color: #ccc;
`

export const LikeAndComment = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LikeAndLove = styled.div`
  display: flex;
  align-items: center;
`

export const Like = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #3f82ec;
  border-radius: 100px;
  z-index: 2;
`

export const Love = styled(Like)`
  background-color: #e1555b;
  margin-left: -4px;
  z-index: 1;
`

export const SectionText = styled.div`
  color: #a6a9ab;
  font-family: Lato;
  font-size: 14px;
  margin-bottom: 0px;
  margin-left: 6px;
  margin-left: ${props => (props.spacing ? '8px' : '2px')};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  max-width: 80px;
`

export const BottomPreviewContainer = styled.div`
  margin-top: 6px;
  background-color: #242527;
  padding-bottom: 8px;
`

export const PlayIconContainer = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff0000;
  align-self: center;
  position: absolute;
`

export const PlayIcon = styled.img.attrs({
  src: '/play-icon.png',
})`
  width: 16px;
  height: 16px;
  margin-left: 3px;
`
