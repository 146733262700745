import styled from 'styled-components'

export const ListItem = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  padding-right: 10px;

  .btn {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;

    cursor: pointer;

    input {
      margin-right: 6px;
      cursor: pointer;
      width: auto !important;
    }
  }
`

export const ListContainer = styled.div.attrs({
  className: 'animated fadeIn',
})`
  position: absolute;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px -4px 4px 0px rgba(119, 119, 119, 0.2);
  padding: 8px 8px 0;
  width: 385px;
  bottom: ${props => (props.isThereAnyReply ? '120px' : '47px')};
  right: 1px;
  z-index: 100;
`

export const TagsListContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 8px;
  max-height: 140px;
`

export const EmptyText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
`
