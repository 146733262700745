import React from 'react'
import { useTranslation } from 'react-i18next'
import history from '~/services/history'
import LanguageSelector from './LanguageSelector'
import * as S from './styled'

export default function LandingPageHeader({ homeButton }) {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.BeLogo onClick={() => history.push('/')} />
      <S.ButtonsContainer>
        <LanguageSelector />

        <S.NavigationContainer>
          <S.DiscoverBeButton onClick={() => window.open(`https://hashtagbe.abilitya.tech`)}>
            <S.DiscoverBeText>{t('discoverBe')}</S.DiscoverBeText>
          </S.DiscoverBeButton>
          {homeButton ? (
            <S.LoginButton onClick={() => history.push('/')}>
              <S.LoginText>{t('home')}</S.LoginText>
            </S.LoginButton>
          ) : (
            <S.LoginButton onClick={() => history.push('/login')}>
              <S.LoginText>{t('login')}</S.LoginText>
            </S.LoginButton>
          )}
        </S.NavigationContainer>
      </S.ButtonsContainer>
    </S.Container>
  )
}
