/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Row from 'react-bootstrap/Row'
import { modalSponsoredDefault } from '~/helper/const'
import Border from '~/components/Shared/Border'
import AddProductCard from '~/components/Shared/AddProductCard'
import useCommunityFunctions from '~/helper/communityFunctions'
import { currencyFormat } from '~/helper/helperFunctions'
import { CustomSwitch } from '~/components/Shared/StyledComponents'
import { getCommunityProducts, SpecialOfferDurationsSelect } from '~/services/requests'
import { prepareToSendPackage, mountAdvProductsObject, getPackageTotalPrice } from '~/helper/formFunctions'
import useGetCommunityCurrencySymbol from '~/hooks/useGetCommunityCurrencySymbol'
import * as S from './styled'

export default function ModalComposePackage({ showModal, handleCloseModal, packageInfo, setCallbackToGetPackages }) {
  const { t } = useTranslation()
  const { createPackageAction, updatePackageAction } = useCommunityFunctions()
  const communityCurrency = useSelector(state => state.community.currentCommunity.currency)
  const communityCurrencySymbol = useGetCommunityCurrencySymbol(communityCurrency)
  const communityId = useSelector(state => state.community.currentCommunity.id)
  const { handleSubmit, control, errors, watch, register } = useForm()
  const hasSpecialOfferWatch = watch('specialOfferDurationId', packageInfo?.specialOfferDuration?.id)
  const specialOfferDiscountWatch = watch('specialOfferDiscount')
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [specialOfferPackagePrice, setSpecialOfferPackagePrice] = useState()
  const [switchChecked, setSwitchChecked] = useState(!!packageInfo?.hasSpecialOffer)
  const [specialOfferDurationSelect, setSpecialOfferDurationSelect] = useState(packageInfo?.specialOfferDuration?.id)
  const [montlyPrice, setMontlyPrice] = useState(packageInfo?.monthlyPrice ? Number(packageInfo?.monthlyPrice) : 0)
  const [, setSwitchCallback] = useState(null)
  const [advProducts, setAdvProducts] = useState(
    packageInfo !== undefined ? mountAdvProductsObject(packageInfo.products) : [],
  )

  const handleGetCommunityProducts = useCallback(async () => {
    const response = await getCommunityProducts(setLoading)
    setProducts(response.data.data)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (showModal) {
      handleGetCommunityProducts()
      setSwitchChecked(!!packageInfo?.hasSpecialOffer)
    }
  }, [handleGetCommunityProducts, showModal])

  function onSubmit(data) {
    if (advProducts.length === 0) {
      return toast.error(t('addAtLeastOneFormatToCreatePackage'))
    }
    if (packageInfo !== undefined) {
      return updatePackageAction(packageInfo.id, data, communityId, setCallbackToGetPackages)
    }
    return createPackageAction(data, communityId, setCallbackToGetPackages)
  }

  const closeModalAndResetFormInformations = () => {
    handleCloseModal()
    if (packageInfo === undefined) {
      setSwitchChecked(false)
      setAdvProducts([])
      setMontlyPrice(0)
    }
  }

  const calculateSpecialOfferPrice = () => {
    if (hasSpecialOfferWatch) {
      const discount = (montlyPrice * specialOfferDiscountWatch) / 100
      const specialOfferPrice = montlyPrice - discount

      if (specialOfferDurationSelect === 1) {
        const specialOfferPriceTotal = specialOfferPrice * 3
        return specialOfferPriceTotal.toFixed(2)
      }
      if (specialOfferDurationSelect === 2) {
        const specialOfferPriceTotal = specialOfferPrice * 6
        return specialOfferPriceTotal.toFixed(2)
      }
      if (specialOfferDurationSelect === 3) {
        const specialOfferPriceTotal = specialOfferPrice * 12
        return specialOfferPriceTotal.toFixed(2)
      }
      if (specialOfferDurationSelect === 4) {
        const specialOfferPriceTotal = specialOfferPrice * 1
        return specialOfferPriceTotal.toFixed(2)
      }
    }
    return specialOfferPackagePrice
  }

  const calculateMontlyDiscountedPrice = () => {
    const discount = (montlyPrice * specialOfferDiscountWatch) / 100
    const price = montlyPrice - discount
    return price?.toFixed(2)
  }

  useEffect(() => {
    if (packageInfo !== undefined) {
      setSpecialOfferPackagePrice(packageInfo?.specialOfferPrice)
    }
  }, [packageInfo])

  useEffect(() => {
    if (advProducts.length > 0) {
      const packageTotalPrice = getPackageTotalPrice(advProducts)
      setMontlyPrice(packageTotalPrice)
    }
  }, [advProducts])

  useEffect(() => {
    advProducts.forEach(product => {
      if (product.qtdPerMonth === 0) {
        setAdvProducts(list => list.filter(item => item.qtdPerMonth > 0))
      }
    })
  }, [advProducts])

  useEffect(() => {
    setSwitchCallback(Math.random())
  }, [switchChecked])

  return loading ? (
    <S.SpinnerContainer>
      <S.CustomSpinner />
    </S.SpinnerContainer>
  ) : (
    <S.ModalCustom show={showModal} onHide={closeModalAndResetFormInformations}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.ModalTitle>{t('pleaseSelectTheFormatsPackage')}</S.ModalTitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalSponsoredDefault}>
        <S.Container>
          <Row>
            <S.ProductsColumn md={7}>
              <S.WebAndMobileProductsSection>
                <S.MobileWebIcon />
                <S.WebMobileText>{t('mobileWebApp')}</S.WebMobileText>
              </S.WebAndMobileProductsSection>
              <S.BorderContainer>
                <Border />
              </S.BorderContainer>
              <S.PackageCompositionCardContainer>
                {products.length > 0 &&
                  products
                    .filter(product => product?.category.name === 'Web/Mobile')
                    .filter(product => product?.activeStatus === 'actived')
                    .map(product => (
                      <AddProductCard
                        key={product.id}
                        product={product}
                        advProducts={advProducts}
                        addProductsToProductsArray={setAdvProducts}
                        productsAdded={packageInfo?.products}
                      />
                    ))}
              </S.PackageCompositionCardContainer>
              <S.SocialProductsSection>
                <S.SocialIcon />
                <S.WebMobileText>{t('social')}</S.WebMobileText>
              </S.SocialProductsSection>
              <S.BorderContainer style={{ marginTop: '16px' }}>
                <Border />
              </S.BorderContainer>
              <S.PackageCompositionCardContainer>
                {products.length > 0 &&
                  products
                    .filter(product => product?.category.name === 'Social')
                    .filter(product => product?.activeStatus === 'actived')
                    .map(product => (
                      <AddProductCard
                        key={product.id}
                        product={product}
                        advProducts={advProducts}
                        addProductsToProductsArray={setAdvProducts}
                        productsAdded={packageInfo?.products}
                      />
                    ))}
              </S.PackageCompositionCardContainer>
              <S.PhysicalProductsSection>
                <S.PhysicalIcon />
                <S.WebMobileText>{t('physical')}</S.WebMobileText>
              </S.PhysicalProductsSection>
              <S.BorderContainer style={{ marginTop: '16px' }}>
                <Border />
              </S.BorderContainer>
              <S.PackageCompositionCardContainer>
                {products.length > 0 &&
                  products
                    .filter(product => product?.category.name === 'Physical')
                    .filter(product => product?.activeStatus === 'actived')
                    .map(product => (
                      <AddProductCard
                        key={product.id}
                        product={product}
                        advProducts={advProducts}
                        addProductsToProductsArray={setAdvProducts}
                        productsAdded={packageInfo?.products}
                      />
                    ))}
              </S.PackageCompositionCardContainer>
            </S.ProductsColumn>
            <S.FormColumn md={5}>
              <S.PackageDetailsAndPrice>
                <S.FormTitle>{t('packageDetails')}</S.FormTitle>
                <S.BorderContainer>
                  <Border />
                </S.BorderContainer>

                <S.Form onSubmit={handleSubmit(prepareToSendPackage(onSubmit, hasSpecialOfferWatch, advProducts))}>
                  <S.InputContainer>
                    <S.InputLabel>{t('packageNameLabel')}</S.InputLabel>
                  </S.InputContainer>
                  <Controller
                    as={<TextField fullWidth variant="outlined" placeholder={t('typeName')} />}
                    rules={{
                      required: t('defaultValidation', { inputName: t('name') }),
                    }}
                    name="name"
                    control={control}
                    defaultValue={packageInfo?.name}
                  />
                  <S.ErrorMessageContainer>
                    <ErrorMessage errors={errors} name="name" as="p" />
                  </S.ErrorMessageContainer>
                  <S.InputContainer>
                    <S.InputLabel>{t('packageDescriptionLabel')}</S.InputLabel>
                  </S.InputContainer>
                  <Controller
                    as={<TextField fullWidth variant="outlined" placeholder={t('typeDescription')} />}
                    rules={{
                      required: t('defaultValidation', { inputName: t('description') }),
                    }}
                    name="description"
                    control={control}
                    defaultValue={packageInfo?.description}
                  />
                  <S.ErrorMessageContainer>
                    <ErrorMessage errors={errors} name="description" as="p" />
                  </S.ErrorMessageContainer>
                  <S.SummarySection>
                    <S.SummaryTitle>{t('summary')}</S.SummaryTitle>
                    <S.CustomBorderContainer>
                      <S.CustomBorder />
                    </S.CustomBorderContainer>
                    {advProducts.length > 0 &&
                      advProducts.map(product => (
                        <S.PayAsYouGoInformationContainer key={product.id}>
                          <S.ProductInformationContainer>
                            {!product.productType ? (
                              <S.ProductQuantityInformation>
                                {`${communityCurrencySymbol} ${currencyFormat(product.unitPrice)} x ${
                                  product.qtdPerMonth
                                } ${product.productName}`}
                              </S.ProductQuantityInformation>
                            ) : (
                              <S.ProductQuantityInformation>
                                {`${communityCurrencySymbol} ${currencyFormat(product.unitPrice)} x ${
                                  product.qtdPerMonth
                                } ${t(product.productType)}`}
                              </S.ProductQuantityInformation>
                            )}

                            <S.ProductInformationText>
                              {`${communityCurrencySymbol} ${currencyFormat(product.unitPrice * product.qtdPerMonth)}`}
                            </S.ProductInformationText>
                          </S.ProductInformationContainer>
                          <S.BorderTitleContainer>
                            <S.CustomBorder />
                          </S.BorderTitleContainer>
                        </S.PayAsYouGoInformationContainer>
                      ))}

                    <S.BorderTitleContainer>
                      <Border />
                    </S.BorderTitleContainer>
                  </S.SummarySection>
                  <S.PackageMonthlyPriceContainer hasSpecialOffer={switchChecked}>
                    <S.InputContainer>
                      <S.InputLabel>{t('packageMonthlyPrice')}</S.InputLabel>
                    </S.InputContainer>
                    {switchChecked ? (
                      <TextField
                        fullWidth
                        disabled
                        variant="outlined"
                        name="monthlyPrice"
                        type="number"
                        value={montlyPrice.toFixed(2)}
                        inputRef={register}
                        inputProps={{ style: { textAlign: 'end' } }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">{communityCurrencySymbol}</InputAdornment>,
                        }}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        disabled
                        variant="outlined"
                        name="monthlyPrice"
                        type="number"
                        value={montlyPrice.toFixed(2)}
                        inputRef={register}
                        inputProps={{ style: { textAlign: 'end' } }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <S.EuroInputAdornment white>{communityCurrencySymbol}</S.EuroInputAdornment>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    <S.ErrorMessageContainer>
                      <ErrorMessage errors={errors} name="monthlyPrice" as="p" />
                    </S.ErrorMessageContainer>
                  </S.PackageMonthlyPriceContainer>
                  <S.GreySectionSeparator />

                  {/* Special offer section */}
                  <S.SpecialOfferSectionContainer>
                    <S.SpecialOfferTitleContainer>
                      <S.FormTitle>{t('specialOffer')}</S.FormTitle>
                      <CustomSwitch
                        className="switch-special-offer"
                        checked={switchChecked}
                        onChange={() => setSwitchChecked(!switchChecked)}
                      />
                    </S.SpecialOfferTitleContainer>

                    {switchChecked && (
                      <>
                        <S.InputContainer>
                          <S.SpecialOfferInputLabel>{t('numberOfMonths')}</S.SpecialOfferInputLabel>
                        </S.InputContainer>
                        <SpecialOfferDurationsSelect
                          defaultValue={packageInfo?.specialOfferDuration}
                          setSpecialOfferDurationSelect={setSpecialOfferDurationSelect}
                          control={control}
                          errors={errors}
                        />
                      </>
                    )}

                    {switchChecked && packageInfo === undefined && (
                      <S.SpecialOfferDiscountContainer>
                        <S.InputContainer>
                          <S.SpecialOfferInputLabel>{t('specialOfferDiscount')}</S.SpecialOfferInputLabel>
                        </S.InputContainer>
                        <Controller
                          as={
                            <TextField
                              fullWidth
                              variant="outlined"
                              inputProps={{ style: { textAlign: 'end' } }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              }}
                              type="number"
                              onInput={e => {
                                e.target.value = e.target.value.toString().slice(0, 2)
                              }}
                            />
                          }
                          rules={{
                            required: t('defaultValidation', { inputName: t('specialOfferDiscountLabel') }),
                          }}
                          className="special-offer-discount"
                          name="specialOfferDiscount"
                          control={control}
                          defaultValue={packageInfo?.specialOfferDiscount || ''}
                        />
                        <S.ErrorMessageContainer>
                          <ErrorMessage errors={errors} name="specialOfferDiscount" as="p" />
                        </S.ErrorMessageContainer>
                      </S.SpecialOfferDiscountContainer>
                    )}

                    {switchChecked && packageInfo !== undefined && (
                      <S.SpecialOfferDiscountContainer>
                        <S.InputContainer>
                          <S.SpecialOfferInputLabel>{t('specialOfferDiscount')}</S.SpecialOfferInputLabel>
                        </S.InputContainer>
                        <Controller
                          as={
                            <TextField
                              fullWidth
                              variant="outlined"
                              inputProps={{ style: { textAlign: 'end' } }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              }}
                              type="number"
                              onInput={e => {
                                e.target.value = e.target.value.toString().slice(0, 2)
                              }}
                            />
                          }
                          rules={{
                            required: t('defaultValidation', { inputName: t('specialOfferDiscount') }),
                          }}
                          className="special-offer-discount"
                          name="specialOfferDiscount"
                          control={control}
                          defaultValue={packageInfo?.specialOfferDiscount || ''}
                        />
                        <S.ErrorMessageContainer>
                          <ErrorMessage errors={errors} name="specialOfferDiscount" as="p" />
                        </S.ErrorMessageContainer>
                      </S.SpecialOfferDiscountContainer>
                    )}

                    {switchChecked && (
                      <S.MonthlyDiscountedPriceContainer>
                        <S.InputContainer>
                          <S.SpecialOfferInputLabel>{t('monthlyDiscountedPrice')}</S.SpecialOfferInputLabel>
                        </S.InputContainer>
                        <TextField
                          fullWidth
                          disabled
                          variant="outlined"
                          name="specialOfferPrice"
                          type="number"
                          value={calculateMontlyDiscountedPrice()}
                          className="montly-discounted-price"
                          inputRef={register}
                          inputProps={{ style: { textAlign: 'end' } }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <S.EuroInputAdornment>{communityCurrencySymbol}</S.EuroInputAdornment>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </S.MonthlyDiscountedPriceContainer>
                    )}

                    {switchChecked && (
                      <S.TotalDiscountedPriceContainer>
                        <S.InputContainer>
                          <S.SpecialOfferInputLabel>{t('totalDiscountedPriceForDuration')}</S.SpecialOfferInputLabel>
                        </S.InputContainer>
                        <TextField
                          fullWidth
                          disabled
                          variant="outlined"
                          name="specialOfferTotalPrice"
                          type="number"
                          value={calculateSpecialOfferPrice()}
                          inputRef={register}
                          inputProps={{ style: { textAlign: 'end' } }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <S.EuroInputAdornment white>{communityCurrencySymbol}</S.EuroInputAdornment>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <S.ErrorMessageContainer>
                          <ErrorMessage errors={errors} name="specialOfferTotalPrice" as="p" />
                        </S.ErrorMessageContainer>
                      </S.TotalDiscountedPriceContainer>
                    )}
                  </S.SpecialOfferSectionContainer>
                </S.Form>
              </S.PackageDetailsAndPrice>
            </S.FormColumn>
          </Row>
        </S.Container>
      </Modal.Body>

      <S.ModalFooter>
        <S.CancelButton text={t('cancel')} large onClick={handleCloseModal} />
        <S.SaveButton
          data-cy="save-created-package-button"
          text={t('save')}
          large
          form="my-form"
          icon={<S.SaveIcon />}
        />
      </S.ModalFooter>
    </S.ModalCustom>
  )
}
