import styled from 'styled-components'
import { FaUserAlt } from 'react-icons/fa'

export const ChatChannelContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 1rem;
  width: 93%;
  height: 5rem;
  background: ${props => (props.sponsored ? '#ffe389' : '#F6F6F6')};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 1rem;
`

export const ChatChannelImage = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`

export const ChatChannelInformationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`

export const ChatChannelTitle = styled.span`
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.005em;
  color: var(--text-bold);
  margin-left: 0.5rem;
  font-weight: bold;
  width: 10.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

export const ManagerChatChannelTitle = styled.span`
  font-family: Roboto;
  margin-top: 2px;
  font-size: 14px;
  letter-spacing: 0.005em;
  color: var(--text-bold);
  margin-left: 4px;
  font-weight: bold;
  width: 14.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

export const ChatChannelSubtitle = styled.span`
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.005em;
  color: var(--input-title);
  margin-left: 0.5rem;
  line-height: 15px;
  width: 14rem;
  color: #3d3d3d;
  height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

export const ManagerChannelContainer = styled.div`
  display: flex;
  align-items: center;
`

export const LockIcon = styled.img.attrs({
  src: '/icons/lock.png',
})`
  margin-left: 0.5rem;
  width: 10px;
  height: 12px;
`

export const ManagerChannelMembersContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 14px;
  background-color: #000;
  border-radius: 30px;
  margin-left: 0.3rem;
`

export const ManagersIcon = styled(FaUserAlt).attrs({
  size: 9,
  color: '#FFF',
})``

export const ManagersCount = styled.div`
  font-family: Roboto;
  color: #fff;
  font-size: 10px;
  margin-left: 2px;
`

export const ChatTimeIndicator = styled.div`
  top: 5px;
  right: 8px;
  position: absolute;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0.005em;
  color: #979797;
`
