import * as Yup from 'yup'
import userStoreValidator from './userStoreValidator'

const companyStoreValidator = Yup.object().shape({
  name: Yup.string().required('Company name is required'),
  invitationCode: Yup.string().required('Invitation link is required'),
  adrFormatted: Yup.string().required('Address name is required'),
  adrLat: Yup.string(),
  adrLng: Yup.string(),
  adrStreet: Yup.string(),
  adrStreetNumber: Yup.string(),
  adrZipcode: Yup.string(),
  adrCity: Yup.string(),
  adrState: Yup.string(),
  adrCountry: Yup.string(),
  logo: Yup.number().optional(),
  manager: userStoreValidator,
})

export default companyStoreValidator
