import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

import * as S from './styled'

export default function Button({
  text,
  action,
  color,
  padding,
  type,
  height,
  fontSize,
  width,
  textColor,
  border,
  style,
  uppercase,
  bold,
  form,
  margin,
  clickEffect,
  isDisabled,
  className,
  loading,
}) {
  return (
    <S.Button
      disabled={isDisabled || false}
      fontSize={fontSize}
      bold={bold}
      form={form}
      uppercase={uppercase}
      style={style}
      border={border}
      textColor={textColor}
      color={color}
      padding={padding}
      height={height}
      width={width}
      type={type}
      onClick={action}
      margin={margin}
      clickEffect={clickEffect}
      className={className}
    >
      {loading ? <Spinner animation="border" size="sm" /> : <span>{text}</span>}
    </S.Button>
  )
}
