import React from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

export default function CreateFormatMobileVisualization({
  FormatVisualizationComponent,
  isSurvey,
  defaultVisualization,
  hideDefaultWidgets,
}) {
  const { t } = useTranslation()

  return (
    <S.Container isSurvey={isSurvey}>
      <S.DeviceScreen isSurvey={isSurvey} defaultVisualization={defaultVisualization}>
        {!defaultVisualization && (
          <>
            {!hideDefaultWidgets && (
              <>
                <S.DeviceHeader>
                  <S.UserAvatar />
                  <S.SearchIcon />
                  <S.CommunityAvatar />
                  <S.ClipIcon />
                  <S.PointsContainer>
                    <S.Points>120</S.Points>
                    <S.PointsIcon />
                  </S.PointsContainer>
                </S.DeviceHeader>
                <S.InterestLabel>{t('browseInterestRanking')}</S.InterestLabel>
                <S.InterestsContainer>
                  <S.Interest>
                    <S.InterestImage src="email.png" />
                    <S.InterestName>serie c</S.InterestName>
                    <S.InterestTopBadge>
                      <S.InterestBadgeText>#1</S.InterestBadgeText>
                    </S.InterestTopBadge>
                  </S.Interest>
                  <S.Interest>
                    <S.InterestImage src="social-run-group.jpg" />
                    <S.InterestName>allenamenti</S.InterestName>
                    <S.InterestTopBadge>
                      <S.InterestBadgeText>#2</S.InterestBadgeText>
                    </S.InterestTopBadge>
                  </S.Interest>
                  <S.Interest>
                    <S.InterestImage src="athletics-running.png" />
                    <S.InterestName>prima squa..</S.InterestName>
                    <S.InterestTopBadge>
                      <S.InterestBadgeText>#3</S.InterestBadgeText>
                    </S.InterestTopBadge>
                  </S.Interest>
                  <S.Interest>
                    <S.InterestImage src="linkedn-logo.png" />
                    <S.InterestName>curiosità..</S.InterestName>
                    <S.InterestTopBadge>
                      <S.InterestBadgeText>#4</S.InterestBadgeText>
                    </S.InterestTopBadge>
                  </S.Interest>
                  <S.Interest>
                    <S.SeeAll>
                      <S.ThreeDots />
                    </S.SeeAll>
                    <S.InterestName>{t('seeAll')}</S.InterestName>
                  </S.Interest>
                </S.InterestsContainer>
              </>
            )}

            {FormatVisualizationComponent}
            <S.DeviceFooter>
              <S.ChatContainer>
                <S.ChatMixIcon />
                <S.IconText>{t('chatMix')}</S.IconText>
              </S.ChatContainer>
              <S.ChatContainer>
                <S.NewsIcon />
                <S.IconText>{t('news')}</S.IconText>
              </S.ChatContainer>
              <S.ChatContainer>
                <S.CalendarIcon />
                <S.IconText>{t('calendar')}</S.IconText>
              </S.ChatContainer>
              <S.NotificationContainer>
                <S.NotificationIcon />
                <S.IconText>{t('forYou')}</S.IconText>
                <S.NotificationDot />
              </S.NotificationContainer>
              <S.ChatContainer>
                <S.MoreIcon />
                <S.IconText>{t('more')}</S.IconText>
              </S.ChatContainer>
            </S.DeviceFooter>
          </>
        )}
        {defaultVisualization && FormatVisualizationComponent}
      </S.DeviceScreen>
      <S.DeviceButton />
    </S.Container>
  )
}
