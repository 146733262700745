import React, { useState } from 'react'
import SignupModalSponsor from '~/components/Sponsor/SignupModalSponsor'
import { ResponsiveAnimation } from '~/components/ResposiveAnimation'

import * as S from './styles'
import Figure from '../../Components/Figure'
import { Button } from '../../Components/Button/Button'
import Card from '../../Components/Card'
import { mainHeroData } from './data'

const checkIcon = <Figure src="/images/check.svg" alt="check-icon" />

export const MainHero = () => {
  const [showSignUpModalSponsor, setShowSignUpModalSponsor] = useState(false)

  return (
    <S.Container>
      <ResponsiveAnimation type="fade" applyOn="both" effects={{ direction: 'left' }}>
        <h1>{mainHeroData.title}</h1>
        <h2>{mainHeroData.subtitle}</h2>
      </ResponsiveAnimation>

      <S.CardsContainer>
        <S.DesktopContainer>
          <S.Row>
            <Card.CardContainer style={{ justifyContent: 'space-between', padding: '1.5rem' }}>
              <ResponsiveAnimation
                type="fade"
                applyOn="desktop"
                effects={{ direction: 'down', cascade: true, damping: 0.1 }}
              >
                <Card.ContentWrapper style={{ flexDirection: 'row' }}>
                  <Figure src="/images/are-you-community.svg" width={128} height={128} />

                  <Card.ContentWrapper style={{ flexDirection: 'column', paddingLeft: '1.5rem' }}>
                    <h3>{mainHeroData.community.title}</h3>
                    <p>{mainHeroData.community.description}</p>
                  </Card.ContentWrapper>
                </Card.ContentWrapper>

                <Card.ButtonWrapper>
                  <Button
                    onClick={() => window.open(process.env.REACT_APP_ONBOARDING_URL, '_blank')}
                    label={mainHeroData.community.buttonLabel}
                    variant="primary"
                  />
                </Card.ButtonWrapper>
              </ResponsiveAnimation>
            </Card.CardContainer>

            <Card.CardContainer style={{ justifyContent: 'space-between', padding: '1.5rem' }}>
              <ResponsiveAnimation
                type="fade"
                applyOn="desktop"
                effects={{ direction: 'down', cascade: true, damping: 0.1 }}
              >
                <Card.ContentWrapper style={{ flexDirection: 'row' }}>
                  <Figure src="/images/are-you-sponsor.svg" width={128} height={128} />

                  <Card.ContentWrapper style={{ flexDirection: 'column', paddingLeft: '1.5rem' }}>
                    <h3>{mainHeroData.sponsor.title}</h3>
                    <p>{mainHeroData.sponsor.description}</p>
                  </Card.ContentWrapper>
                </Card.ContentWrapper>

                <Card.ButtonWrapper>
                  <Button
                    onClick={() => setShowSignUpModalSponsor(true)}
                    label={mainHeroData.sponsor.buttonLabel}
                    variant="primary"
                  />
                </Card.ButtonWrapper>
              </ResponsiveAnimation>
            </Card.CardContainer>
          </S.Row>

          <Card.CardContainer style={{ padding: '1.5rem' }}>
            <h3>{mainHeroData.dataSolutions.title}</h3>
            <Card.ContentWrapper style={{ flexDirection: 'column', gap: '0.3rem', fontWeight: '500' }}>
              <ResponsiveAnimation
                type="fade"
                applyOn="desktop"
                effects={{ direction: 'down', cascade: true, damping: 0.1 }}
              >
                <Card.ListItem text={mainHeroData.dataSolutions.listItems[0]} icon={checkIcon} />
                <Card.ListItem text={mainHeroData.dataSolutions.listItems[1]} icon={checkIcon} />
                <Card.ListItem text={mainHeroData.dataSolutions.listItems[2]} icon={checkIcon} />
              </ResponsiveAnimation>
            </Card.ContentWrapper>
          </Card.CardContainer>
        </S.DesktopContainer>

        <S.MobileContainer>
          <S.Row>
            <Card.CardContainer style={{ width: '100%', padding: '1.5rem', textAlign: 'center' }}>
              <ResponsiveAnimation
                type="fade"
                applyOn="mobile"
                effects={{ direction: 'down', cascade: true, damping: 0.1 }}
              >
                <Figure src="/images/are-you-community.svg" width={128} height={128} />
                <h3>{mainHeroData.community.title}</h3>
                <p>{mainHeroData.community.description}</p>
              </ResponsiveAnimation>

              <Card.ButtonWrapper>
                <Button
                  onClick={() => window.open(process.env.REACT_APP_ONBOARDING_URL, '_blank')}
                  label={mainHeroData.community.buttonLabel}
                  variant="primary"
                />
              </Card.ButtonWrapper>
            </Card.CardContainer>

            <Card.CardContainer style={{ width: '100%', padding: '1.5rem', textAlign: 'center' }}>
              <ResponsiveAnimation
                type="fade"
                applyOn="mobile"
                effects={{ direction: 'down', cascade: true, damping: 0.2 }}
              >
                <Figure src="/images/are-you-sponsor.svg" width={128} height={128} />
                <h3>{mainHeroData.sponsor.title}</h3>
                <p>{mainHeroData.sponsor.description}</p>
              </ResponsiveAnimation>

              <Card.ButtonWrapper>
                <Button
                  onClick={() => setShowSignUpModalSponsor(true)}
                  label={mainHeroData.community.buttonLabel}
                  variant="primary"
                />
              </Card.ButtonWrapper>
            </Card.CardContainer>

            <Card.CardContainer style={{ padding: '1.5rem' }}>
              <h3>{mainHeroData.dataSolutions.title}</h3>
              <Card.ContentWrapper style={{ flexDirection: 'column', gap: '0.3rem', fontWeight: '500' }}>
                <ResponsiveAnimation
                  type="fade"
                  applyOn="mobile"
                  effects={{ direction: 'down', cascade: true, damping: 0.1 }}
                >
                  <Card.ListItem text={mainHeroData.dataSolutions.listItems[0]} icon={checkIcon} />
                  <Card.ListItem text={mainHeroData.dataSolutions.listItems[1]} icon={checkIcon} />
                  <Card.ListItem text={mainHeroData.dataSolutions.listItems[2]} icon={checkIcon} />
                </ResponsiveAnimation>
              </Card.ContentWrapper>
            </Card.CardContainer>
          </S.Row>
        </S.MobileContainer>
      </S.CardsContainer>

      <SignupModalSponsor
        handleCloseModal={() => setShowSignUpModalSponsor(false)}
        showModal={showSignUpModalSponsor}
      />
    </S.Container>
  )
}
