import React from 'react'
import { useTranslation } from 'react-i18next'
import Spinner from 'react-bootstrap/Spinner'
import ProductBadge from '~/components/Shared/ProductBadge'
import { getProductBadgeColor, randomizeProductInterestTag } from '~/helper/helperFunctions'
import * as S from './styled'

export default function Content({ loadingImage, image, title, about, contentType }) {
  const { t } = useTranslation()

  return (
    <S.Container>
      {image === null &&
        (loadingImage === false || loadingImage == null ? (
          <S.NoContentImageAvailable>
            <S.NoContentImageIcon />
          </S.NoContentImageAvailable>
        ) : (
          <S.NoContentImageAvailable>
            <Spinner animation="border" size="sm" />
          </S.NoContentImageAvailable>
        ))}
      {image !== null && (loadingImage === false || loadingImage === undefined ? <S.ContentImage src={image} /> : null)}

      <S.ContentInformationsContainer>
        <ProductBadge
          text={contentType}
          color={getProductBadgeColor(contentType)}
          eventText={randomizeProductInterestTag(contentType)}
        />
        <S.ContentTitle>{title || t('title')}</S.ContentTitle>
        <S.ContentDescription>{about || t('description')}</S.ContentDescription>
      </S.ContentInformationsContainer>
      <S.ContentFooterContainer>
        <S.UserInformationsContainer>
          <S.UserAvatar />
          <S.UserName>{t('user')}</S.UserName>
        </S.UserInformationsContainer>
        <S.UserOptionsInformationsContainer>
          <S.ShareIcon />
          <S.MoreOptionsIcon />
        </S.UserOptionsInformationsContainer>
      </S.ContentFooterContainer>
    </S.Container>
  )
}
