import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ModalEditCommunity from '~/components/Community/Modals/ModalEditCommunity'
import ModalCropProfileImage from '~/components/Community/Modals/ModalCropProfileImage'
import ModalCropBannerImage from '~/components/Community/Modals/ModalCropBannerImage'
import ModalPreviewProfileVisualization from '~/components/Community/Modals/ModalPreviewProfileVisualization'
import { DefaultLogoImage, DefaultBannerImage } from '~/util/defaultImages'
import CommunityKpiCard from '~/components/CommunityKpiCard'
import SocialMediaButtons from '~/components/SocialMediaButtons'
import { CommunityStatusApprovedIcon, CommunityStatusReprovedIcon } from '~/components/Shared/StyledComponents'
import * as FN from '~/helper/formFunctions'
import * as S from './styled'

export default function CommunityProfile({
  currentCommunityInformations,
  communityIsRevised,
  showModalEditCommunity,
  setShowModalEditCommunity,
}) {
  const {
    networkLogoPath,
    name,
    facebook,
    website,
    twitter,
    youtube,
    adrCity,
    adrState,
    membersKpi,
    womenKpi,
    menKpi,
    averageAgeKpi,
  } = currentCommunityInformations

  const { t } = useTranslation()
  const [stepImage, setStepImage] = useState(0)
  const [showModalCropProfileImage, setShowModalCropProfileImage] = useState(false)
  const [showModalCropBannerImage, setShowModalCropBannerImage] = useState(false)
  const [showModalPreviewVisualization, setShowModalPreviewVisualization] = useState(false)
  const [displayModalEditCommunity, setDisplayModalEditCommunity] = useState(true)
  const arrayKpis = FN.GetCommunityKpisArray(membersKpi, averageAgeKpi, menKpi, womenKpi)

  const setModalStepToZero = () => {
    setStepImage(0)
    setShowModalCropProfileImage(false)
    setShowModalCropBannerImage(false)
    setDisplayModalEditCommunity(true)
  }

  const setModalStepToOne = () => {
    setStepImage(1)
    setDisplayModalEditCommunity(false)
  }

  const setModalStepToTwo = () => {
    setStepImage(2)
    setDisplayModalEditCommunity(false)
  }

  function hasSocialMedia() {
    if (youtube || facebook || twitter || website) {
      return true
    }
    return false
  }

  useEffect(() => {
    function getStepModalForCropImage() {
      if (stepImage === 1) {
        setDisplayModalEditCommunity(false)
        setShowModalCropProfileImage(true)
      }
      if (stepImage === 2) {
        setDisplayModalEditCommunity(false)
        setShowModalCropBannerImage(true)
      }
    }
    getStepModalForCropImage()
  }, [setShowModalEditCommunity, stepImage])

  return (
    <S.Container>
      <S.ImageContainer>
        <S.BannerImage src={DefaultBannerImage} />
        <S.LogoImage src={networkLogoPath == null ? DefaultLogoImage : networkLogoPath} />
        <S.ButtonSection>
          <S.PreviewButtonContainer>
            <S.PreviewCommunityProfileButton onClick={() => setShowModalPreviewVisualization(true)} />
          </S.PreviewButtonContainer>
          <S.EditButtonContainer data-cy="edit-profile-button">
            <S.EditCommunityProfileButton onClick={() => setShowModalEditCommunity(true)} />
          </S.EditButtonContainer>
        </S.ButtonSection>
      </S.ImageContainer>
      <S.CommunityInformationSection>
        <S.CommunityNameContainer>
          <S.CommunityName>{name}</S.CommunityName>
        </S.CommunityNameContainer>
        <S.CommunityLocationContainer>
          {(adrCity || adrState) && (
            <S.CommunityLocation>
              {`${adrCity ?? ''}${adrCity && adrState ? ',' : ''} ${adrState ?? ''}`}
            </S.CommunityLocation>
          )}
        </S.CommunityLocationContainer>
        <S.CommunityKpisContainer>
          {arrayKpis.map(communityKpi => (
            <CommunityKpiCard
              key={communityKpi.id}
              title={communityKpi.title}
              value={communityKpi.value}
              icon={communityKpi.icon}
            />
          ))}
        </S.CommunityKpisContainer>
        <S.SocialMediaContainer>
          <SocialMediaButtons youtube={youtube} facebook={facebook} twitter={twitter} website={website} />
        </S.SocialMediaContainer>
        <S.CommunityStatusContainer hasSocialMedia={hasSocialMedia()}>
          {communityIsRevised === false ? <CommunityStatusReprovedIcon /> : <CommunityStatusApprovedIcon />}
          <S.CommunityStatusSection>
            <S.CommunityStatusTitle>{t('communityStatus')}</S.CommunityStatusTitle>
            <S.CommunityStatusSubtitle>
              {communityIsRevised === false ? t('waitingBeSetup') : t('approvedByBe')}
            </S.CommunityStatusSubtitle>
          </S.CommunityStatusSection>
        </S.CommunityStatusContainer>
      </S.CommunityInformationSection>

      <ModalCropProfileImage
        setModalStepToZero={setModalStepToZero}
        currentCommunity={currentCommunityInformations}
        handleCloseModal={() => setShowModalCropProfileImage(false)}
        showModal={showModalCropProfileImage}
      />
      <ModalCropBannerImage
        setModalStepToZero={setModalStepToZero}
        currentCommunity={currentCommunityInformations}
        handleCloseModal={() => setShowModalCropBannerImage(false)}
        showModal={showModalCropBannerImage}
      />
      {showModalPreviewVisualization && (
        <ModalPreviewProfileVisualization
          handleCloseModal={() => setShowModalPreviewVisualization(false)}
          showModal={showModalPreviewVisualization}
          community={currentCommunityInformations}
        />
      )}
      {currentCommunityInformations && (
        <ModalEditCommunity
          handleCloseModal={() => setShowModalEditCommunity(false)}
          showModal={showModalEditCommunity}
          currentCommunity={currentCommunityInformations}
          setModalStepToOne={setModalStepToOne}
          setModalStepToTwo={setModalStepToTwo}
          showModalPreviewVisualization={showModalPreviewVisualization}
          setShowModalPreviewVisualization={setShowModalPreviewVisualization}
          displayModalEditCommunity={displayModalEditCommunity}
        />
      )}
    </S.Container>
  )
}
