/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import { RiSave2Line } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import Col from 'react-bootstrap/Col'
import { modalBodyStyleDefault } from '~/helper/const'
import useCommunityFunctions from '~/helper/communityFunctions'
import { ProductCategoriesSelect } from '~/services/requests'
import * as FN from '~/helper/formFunctions'
import useGetCommunityCurrencySymbol from '~/hooks/useGetCommunityCurrencySymbol'
import { prepareToSendObject } from './prepareToSend'
import * as S from './styled'

export default function ModalCustomSponsoredFormat({
  handleCloseModal,
  showModal,
  product,
  setCallbackToGetProducts,
  setCustomFormatInfo,
}) {
  const { t } = useTranslation()
  const { handleSubmit, errors, control, watch } = useForm()
  const communityCurrency = useSelector(state => state.community.currentCommunity.currency)
  const communityCurrencySymbol = useGetCommunityCurrencySymbol(communityCurrency)
  const { createCustomAdvertisementProductAction, updateCustomAdvertisementProductAction } = useCommunityFunctions()
  const [formatUnitPriceAdditionalValidation, setFormatUnitPriceAdditionalValidation] = useState(null)
  const [formatUnitPriceValidation, setFormatUnitPriceValidation] = useState(false)
  const communityId = useSelector(state => state.community.currentCommunity.id)
  const unitPriceWatch = watch('unitPrice', product?.unitPrice)

  useEffect(() => {
    if (product?.suggestedUnitPrice) {
      if (unitPriceWatch < product.suggestedUnitPrice) {
        const string = `${t('formatPriceMustBeEqualOrGreaterThan')}  ${communityCurrencySymbol}${
          product.suggestedUnitPrice
        } ${t('whichIsTheMinimumPriceForThisFormat')}`
        setFormatUnitPriceAdditionalValidation(string)
        setFormatUnitPriceValidation(true)
      } else {
        setFormatUnitPriceValidation(false)
      }
    }
  }, [unitPriceWatch])

  const onSubmit = data => {
    if (product?.suggestedUnitPrice) {
      if (data.unitPrice < product.suggestedUnitPrice) {
        const string = `${t('formatPriceMustBeEqualOrGreaterThan')}  ${communityCurrencySymbol}${
          product.suggestedUnitPrice
        } ${t('whichIsTheMinimumPriceForThisFormat')}`
        toast.error(string)
        return false
      }
    }
    if (data.unitPrice === 0) {
      const string = `${t('formatPriceMustBeGreaterThan')} ${communityCurrencySymbol}0`
      toast.error(string)
      return false
    }
    if (product) {
      updateCustomAdvertisementProductAction(product.id, data, communityId, setCallbackToGetProducts)
      setCustomFormatInfo(data)
      return handleCloseModal()
    }
    createCustomAdvertisementProductAction(data, communityId, setCallbackToGetProducts)
    return handleCloseModal()
  }

  const productIsCustom = () => {
    if (product?.productType === 'custom' || product?.productType === undefined) {
      return true
    }
    return false
  }

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {product != null && <S.Title>{`${t('edit')} ${FN.getFormatTypeForCommunity(product?.name)}:`}</S.Title>}
          {product == null && <S.Title>{t('customSponsoredFormatCreation')}</S.Title>}
        </Modal.Title>
      </Modal.Header>

      <S.BannerContainer>
        <S.ProductBackground />
        <S.InvitationTextContainer>
          {!productIsCustom() ? (
            <S.CustomFormatText>{product?.name}</S.CustomFormatText>
          ) : (
            <S.CustomFormatText>{t('customFormat')}</S.CustomFormatText>
          )}
        </S.InvitationTextContainer>
      </S.BannerContainer>

      <Modal.Body style={modalBodyStyleDefault}>
        <S.FormContainer>
          <form id="my-form" onSubmit={handleSubmit(prepareToSendObject(onSubmit))}>
            <S.InputContainer>
              <Col md={12}>
                <S.LabelContainer>
                  <S.InputLabel>{`${t('formatName')}:`}</S.InputLabel>
                  <S.InputIsRequired>*</S.InputIsRequired>
                </S.LabelContainer>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        maxLength: 60,
                      }}
                      disabled={!productIsCustom()}
                      placeholder={t('typeName')}
                    />
                  }
                  name="name"
                  control={control}
                  rules={{ required: t('defaultValidation', { inputName: t('formatName') }) }}
                  defaultValue={product?.name}
                />
                <S.ErrorMessageContainer>
                  <ErrorMessage errors={errors} name="name" as="p" />
                </S.ErrorMessageContainer>
              </Col>
            </S.InputContainer>
            <S.InputContainer spacing="true">
              <Col md={12}>
                <S.LabelContainer>
                  <S.InputLabel>{`${t('description')}:`}</S.InputLabel>
                  <S.InputIsRequired>*</S.InputIsRequired>
                </S.LabelContainer>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      variant="outlined"
                      multiline
                      rows="2"
                      disabled={!productIsCustom()}
                      inputProps={{
                        maxLength: 3000,
                      }}
                    />
                  }
                  placeholder={t('typeDescription')}
                  defaultValue={product?.description}
                  rules={{ required: t('defaultValidation', { inputName: t('description') }) }}
                  name="description"
                  control={control}
                />
                <S.ErrorMessageContainer>
                  <ErrorMessage errors={errors} name="description" as="p" />
                </S.ErrorMessageContainer>
              </Col>
            </S.InputContainer>

            <S.InputContainer spacing="true">
              <Col md={6}>
                <S.LabelContainer>
                  <S.InputLabel>{t('formatCategory')}</S.InputLabel>
                  <S.InputSubtitle className="ml-1">{t('webMobilePhysical')}</S.InputSubtitle>
                  <S.InputIsRequired>*</S.InputIsRequired>
                </S.LabelContainer>
                <ProductCategoriesSelect
                  disabled={!productIsCustom()}
                  control={control}
                  errors={errors}
                  defaultValue={product?.category}
                />
              </Col>

              <Col md={6}>
                <S.LabelContainer>
                  <S.InputLabel>{t('setFormatUnitPrice')}</S.InputLabel>
                  <S.InputIsRequired>*</S.InputIsRequired>
                </S.LabelContainer>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="number"
                      placeholder={t('typeFormatUnitPrice')}
                      inputProps={{ style: { textAlign: 'end' } }}
                      onInput={e => {
                        e.target.value = e.target.value.toString().slice(0, 6)
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{communityCurrencySymbol}</InputAdornment>,
                      }}
                    />
                  }
                  rules={{ required: t('defaultValidation', { inputName: t('formatUnitPrice') }) }}
                  defaultValue={product?.unitPrice}
                  name="unitPrice"
                  control={control}
                />
                <S.ErrorMessageContainer>
                  <ErrorMessage errors={errors} name="unitPrice" as="p" />
                </S.ErrorMessageContainer>
                {formatUnitPriceValidation && (
                  <S.ErrorMessageContainer>
                    <p>{formatUnitPriceAdditionalValidation}</p>
                  </S.ErrorMessageContainer>
                )}
              </Col>
            </S.InputContainer>
          </form>
        </S.FormContainer>
      </Modal.Body>

      <S.ModalFooter>
        <S.SaveButton
          data-cy="save-created-format-button"
          icon={<RiSave2Line color="var(--success)" size={23} />}
          text={t('save')}
        />
      </S.ModalFooter>
    </S.ModalCustom>
  )
}
