/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CompactList from '~/components/Chat/CompactList'
import * as S from './styled'

function parseUsersList(users) {
  return users.map(user => ({
    title: user && user.name,
    image: user && user.image,
    ...user,
  }))
}

function filterUsers(search) {
  return value =>
    value.name &&
    value.name.toLowerCase().includes((search === '@' || !search ? '' : search.substring(1)).toLowerCase())
}

const Mention = ({ search = '', open, onCloseEvent, onOpenEvent, onPressItem, channel, isThereAnyReply }) => {
  const { users } = useSelector(state => state.firebaseUsers)
  const { members, isPrivate } = channel || {}
  const [opened, setOpened] = useState(true)
  const [lastUser, setLastUser] = useState([])

  const usersFiltered = useMemo(() => {
    const serializedUsers = users.filter(user => !isPrivate || (members && members.includes(user && user.id)))
    const filtered = serializedUsers.filter(filterUsers(search))
    return parseUsersList(filtered)
  }, [users, search])

  const membersList = usersFiltered?.length ? usersFiltered : lastUser

  useEffect(() => {
    if (usersFiltered?.length) {
      setLastUser(usersFiltered.slice(-1))
    }
  }, [usersFiltered])

  useEffect(() => {
    const firstSearchResult = membersList?.length > 1 || membersList.find(filterUsers(search))
    if (!firstSearchResult) {
      setOpened(false)
      if (onCloseEvent) onCloseEvent()
    }
    if (!opened) {
      setOpened(true)
      onOpenEvent()
    }
  }, [search, membersList])

  return (
    <S.ListContainer className="list-container" isThereAnyReply={isThereAnyReply}>
      <S.UsersContainer>
        <CompactList data={membersList} showImages avatarSize="sm" onPressItem={item => !!open && onPressItem(item)} />
      </S.UsersContainer>
    </S.ListContainer>
  )
}

export default Mention
