import React from 'react'

export default class Preloader extends React.PureComponent {
  render() {
    const strokeProps = {}

    return (
      <div>
        <svg className="preloader_circular" viewBox="25 25 50 50">
          <circle
            className="preloader_path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
            shapeRendering="optimizeQuality"
            {...strokeProps}
          />
        </svg>
      </div>
    )
  }
}
