import { takeLatest, all, put } from 'redux-saga/effects'
import { getFirestore } from '~/services/firebase'
import { formatChannelDescription, createChannelId, channelsCollection } from '~/helper/chatFunctions'
import { setFirstLogin } from '~/store/modules/sponsor/actions'
import { CREATE_SPONSOR_COMMUNITY_CHANNEL, UPDATE_SPONSOR_COMMUNITY_CHANNEL } from './actionTypes'

export function* createSponsorCommunityChannel({ payload }) {
  const { sponsorId, communityId, communityName, sponsorName, sponsorLogo, communityLogo } = payload
  const channelCreatedAt = new Date()
  const description = formatChannelDescription(sponsorName)
  const channelId = createChannelId(sponsorId, communityId)
  try {
    yield getFirestore()
      .collection(channelsCollection(communityId))
      .doc(channelId)
      .set({
        sponsorId,
        communityId,
        id: channelId,
        communityName,
        title: sponsorName,
        description,
        isPublic: true,
        isFromAdvPlat: true,
        picture: {
          url: null,
          width: null,
          height: null,
        },
        pictureSponsor: {
          url: sponsorLogo || null,
          width: null,
          height: null,
        },
        pictureCommunity: {
          url: communityLogo || null,
          width: null,
          height: null,
        },
        ownerId: null,
        type: 'AdvPlatform',
        createdAt: channelCreatedAt,
        archived: false,
        membersId: [],
        permissions: [],
        tags: [],
        isOneToMany: false,
        isSponsored: false,
      })
    yield put(setFirstLogin())
  } catch (err) {
    console.log('err:', err)
  }
}

export function* updateSponsorCommunityChannel({ payload }) {
  const { sponsorId, communityId, communityName, sponsorName, sponsorLogo, communityLogo } = payload
  const description = formatChannelDescription(sponsorName)
  const channelId = createChannelId(sponsorId, communityId)
  try {
    yield getFirestore()
      .collection(channelsCollection(communityId))
      .doc(channelId)
      .update({
        communityName,
        title: sponsorName,
        description,
        pictureSponsor: {
          url: sponsorLogo || null,
          width: null,
          height: null,
        },
        pictureCommunity: {
          url: communityLogo || null,
          width: null,
          height: null,
        },
      })
    yield put(setFirstLogin())
  } catch (err) {
    console.log('err:', err)
  }
}

export default all([
  takeLatest(CREATE_SPONSOR_COMMUNITY_CHANNEL, createSponsorCommunityChannel),
  takeLatest(UPDATE_SPONSOR_COMMUNITY_CHANNEL, updateSponsorCommunityChannel),
])
