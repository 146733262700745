import React from 'react'
import Modal from 'react-bootstrap/Modal'
import * as S from './styled'

export default function ModalFormattedMessage({ handleCloseModal, showModal, message, closeModalVideo }) {
  const closeModalVideos = () => {
    closeModalVideo()
    handleCloseModal()
  }

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <Modal.Body>{message}</Modal.Body>
      <S.CloseButtonContainer onClick={closeModalVideos}>
        <S.CloseIcon />
      </S.CloseButtonContainer>
    </S.ModalCustom>
  )
}
