import { createGlobalStyle } from 'styled-components'
import { generateMedia } from 'styled-media-query'

export const customMedia = generateMedia({
  small: '576px',
  medium: '768px',
  large: '992px',
  extraLarge: '1200px',
  veryLarge: '1420px',
  huge: '1680px',
})

export default createGlobalStyle`

@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100..900&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    user-select: none;

    scroll-behavior: smooth !important;

  }

  :root {
    --community-primary: #9671F5;
    --community-dark: #7345e9;
    --sponsor-primary: #00A2FF;
    --sponsor-dark: #0090de;
    --text-primary:  #586674;
    --text-bold:  #222432;
    --success:  #499D28;
    --error:  #ed4656;
    --black:  #000;
    --grey:  #808b99;
    --light-grey: #d8d8d8;
    --light-white: #e2e2e2;
    --yellow: #fdd138;
    --input-title: #484e51;
    --format-post: #0059b3;
    --format-document: #050a14;
    --format-webpage: #986FFB;
    --format-reward: #BF9B31;
    --format-video: #FF782D;
    --format-event: #828282;
    --blue: #000986;
    --light-blue: #00a6ff;
  }

  .MuiStepIcon-completed {
    background-color: #FFF !important;
    border-radius: 100%;
  }

  .MuiTooltip-tooltip {
    font-size: 14px !important;
    white-space:  pre-line !important;

    p {
      margin: 0px;
      text-align: center;
    }
  }

  .autocomplete-dropdown-container {
    z-index: 10 !important;
  }

  .react-tag-input__input {
    padding-left: 0px !important;
  }

  .modal {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 1040 !important;
    width: 100% !important;
    height: 100% !important;
  }

  .modal-backdrop {
    width: 100% !important;
    height: 100% !important;
  }

  .react-audio-player {
    border-radius: 100px;
    height: 26px;
    max-width: 255px;
    &:focus {
      outline: none;
    }
  } 

  ${[1, 2, 3, 4].map(
  qty => `.z-index-${qty} {
    z-index: ${qty};
    }`,
)};

  .slick-dots {
    bottom: -30px !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .MuiStepper-root {
    display: flex !important;
    padding: 0px !important;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }

  .MuiRating-sizeLarge {
    font-size: 2.475rem !important;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px !important;
    line-height: 1;
    opacity: 1 !important;
    color: #cccccc !important;
  }

  .slick-next.slick-disabled:before {
    opacity: .05 !important;
  }

  .slick-prev.slick-disabled:before {
    opacity: .05 !important;
  }

  .read-more-less--more,
  .read-more-less--less {
    color: #f84758 !important;
    font-weight: 400 !important;
  }

  .chartjsLegend li span {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border-radius: 25px;
}
  
  .error-message {
    p {
      color: #ea343e;
      font-family: Roboto;
      font-size: 13px;
      font-weight: 600;
      opacity: 0.90;
      margin-top: 3px;
      margin-bottom: 0px;
    }

    p::before {
      display: inline;
      content: "⚠ ";
    }
  }

  .modal.show {
    padding-left: 0px !important;
  }

  .error-message-login {
    font-family: Roboto;
    font-size: 13px;
    color: #ea343e;
    opacity: 0.90;
    font-weight: 600;
    margin-bottom: 0px !important;

    p::before {
      display: inline;
      content: "⚠ ";
      margin: 0px !important;
    }
  }

  .modal-open {
    overflow:auto !important;
    padding-right:0px !important;
  }

  button {
    cursor: pointer;
  }

  form {
    input {
      padding-left: 10px !important;
      ::placeholder {
        color: #858588 !important;
        font-size: 15px !important;
        font-family: Roboto !important;
        opacity: 0.5 !important;
      }
    }
  }

  span.MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-42.MuiCheckbox-root.PrivateSwitchBase-disabled-44.Mui-disabled.Mui-disabled.Mui-disabled {
    color: rgba(132, 146, 166, 0.13) !important;
  }

  .MuiIconButton-root.Mui-disabled {
    color: rgba(132, 146, 166, 0.13) !important;
  }

  textarea::-webkit-input-placeholder {
    color: #858588 !important;
    opacity: 0.45 !important;
    font-size: 15px !important;
    font-family: Roboto !important;
  }

  a {
    text-decoration: none;
  }

  ul {
    text-decoration: none;
  }

  .MuiDialogActions-root {
    padding: 15px !important;
  }

  .MuiDialog-paper {
    min-width: 400px !important;
  }

  .MuiExpansionPanel-root:before {
    background-color: transparent !important;
  }

  .Mui-focused fieldset {
    border-color: #50A5D2 !important;
    border-width: 1px !important;
  }

  .MuiOutlinedInput-input {
    padding: 9px 9px 9px 9px !important;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
    padding: 0px !important;
  }

  /* START SLIDER */
  .rangeslider-horizontal {
    height: 10px !important;
  }

  .rangeslider__fill {
    background-color: transparent !important;
  }

  .rangeslider {
    background: #d9d9d9 !important;
  }

  .rangeslider-horizontal .rangeslider__handle {
    width: 20px !important;
    height: 20px !important;
  }

  .rangeslider, .rangeslider .rangeslider__fill {
    box-shadow: none !important;
  }

  .rangeslider-horizontal .rangeslider__handle:after {
    display: none !important;
  }
  /* END SLIDER */

  input:disabled {
    background-color: #e6e6e6;
    border-radius: 5px;
  }

  textarea:disabled {
    background-color: #e6e6e6;
    color: rgba(0, 0, 0, 0.30) !important;
  }

  .MuiOutlinedInput-multiline {
    height: 92px !important;
  }

  button, button:hover, li:hover, a:hover , li , a , *:hover, * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    outline: none !important;
  }

  /* BEGIN LANDING PAGE STYLES */
  .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl, .MuiInputLabel-animated, .MuiInputLabel-outlined {
    color: #D6D6D6 !important;
    font-size: 15px !important;
  }

  

  .MuiInputLabel-formControl {
    top: -7px !important;
  }

   .MuiInputLabel-shrink {
    color: #808080 !important;
  }

  .Mui-focused fieldset {
    border-color: #50A5D2 !important;
    border-width: 1px !important;
  }

  .react-tel-input .form-control {
    height: 39px !important;
    width: 100% !important;
    border: 1px solid #c4c4c4 !important;
    padding-left: 48px !important;

     &:focus {
      box-shadow: 0 0 0 0.5px #50A5D2;
      border: none;
      &+div:before {
        color: #50A5D2;
      } 
    }
  }
/* END LANDING PAGE STYLES */

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    width: 100%;

    ::placeholder {
      color: #858588;
      font-family: Lato;
      font-size: 15px;
      font-weight: 400;
    }

    input:focus {
      transition: 0.5s;
      border: 1px solid #50A5D2 !important;
    }

    input:disabled {
      background-color: #e6e6e6;
      color: rgba(0, 0, 0, 0.30) !important;
      font-family: Roboto;
      border: 1px solid #c4c4c4 !important;
    }
  }

  .apexcharts-legend apexcharts-align-right position-right {
    display: none !important;
  }
`
