import React from 'react'
import { ErrorMessage as Error } from 'react-hook-form'
import * as S from './styled'

export default function ErrorMessage({ errors, name, login }) {
  return (
    <>
      {login ? (
        <S.ErrorLoginMessageContainer>
          <Error errors={errors} name={name} as="p" />
        </S.ErrorLoginMessageContainer>
      ) : (
        <S.ErrorMessageContainer>
          <Error errors={errors} name={name} as="p" />
        </S.ErrorMessageContainer>
      )}
    </>
  )
}
