import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'

const handleInputPaddingLanguage = language => {
  switch (language) {
    case 'en':
      return '125px !important'
    case 'it':
      return '148px !important'
    case 'pt':
      return '160px !important'
    default:
      return '125px !important'
  }
}

export const FormContainer = styled.div`
  .input-date {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    height: 40px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(159, 159, 159, 0.7);
    border-radius: 5px;
    padding-left: 5px;
  }

  .boosts-left {
    margin: 0px;
    color: #2e601b;
    font-family: Lato;
    font-size: 10px;
    font-weight: 700;
    text-align: right;
  }

  input.MuiInputBase-input.MuiOutlinedInput-input {
    padding-right: ${props => handleInputPaddingLanguage(props.currentLanguage)};
  }
`

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    width: 50%;
    height: 50%;
    margin: 0;
    padding: 0;

    @media (min-width: 576px) {
      max-width: 1200px;
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;

    .modal-title {
      color: #404257;
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      line-height: 27px;
    }
  }
`

export const InputTitle = styled.span`
  margin-top: 3px;
  color: #404257;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
`

export const ProductTitle = styled.span`
  margin-top: 3px;
  color: #fff;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
`

export const Question = styled.span`
  margin-top: 3px;
  color: #fff;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;

  width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

export const Phone = styled.div`
  width: 380px;
  height: 640px;
  border: 2px solid #ccc;
  border-radius: 30px;
  position: relative;
  left: 45%;
  top: 50px;
  margin: -20px -150px;
  background: #fff;
`

export const Screen = styled.div`
  background-color: rgba(0, 0, 0, 0.95);
  width: 350px;
  height: 540px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  left: 42%;
  top: 45%;
  margin: -260px -145px;
  box-sizing: border-box;
  overflow: hidden;
`

export const Home = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0 -10px;
  border-radius: 50%;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .image-container {
    width: 100%;
    height: 320px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`

export const RadioGroupSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  .MuiTypography-body1 {
    font-size: 14px !important;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    width: 100%;
  }
`

export const RadioRightGroupSection = styled.div`
  display: flex;
  flex-direction: column;

  .MuiTypography-body1 {
    font-size: 14px !important;
    padding-left: 8px;
    color: #fff !important;
    font-weight: bold !important;
    font-family: Roboto;
    font-weight: 400;
    width: 110px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    height: 20px;
    display: -webkit-box;
  }
`

export const OptionBox = styled.div`
  margin-top: 8px;
  background-color: #323232;
  width: 150px;
`

export const SkipSurveyBox = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border: 2px solid #171717;
  height: 42px;
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.95;

  svg {
    margin-left: 5px;
  }
`

export const QuestionCharactersLeft = styled.div`
  position: absolute;
  right: 25px;
  top: 36px;
  color: #949aa0;
  font-size: 14px;
  font-family: Roboto;
  opacity: 0.45;
`

export const OptionOneCharactersLeft = styled.div`
  position: absolute;
  right: 25px;
  top: 108px;
  color: #949aa0;
  font-size: 14px;
  z-index: 9;
  font-family: Roboto;
  opacity: 0.45;
`

export const OptionTwoCharactersLeft = styled.div`
  position: absolute;
  right: 25px;
  top: 156px;
  color: #949aa0;
  font-size: 14px;
  z-index: 9;
  font-family: Roboto;
  opacity: 0.45;
`

export const OptionThreeCharactersLeft = styled.div`
  position: absolute;
  right: 25px;
  top: 203px;
  color: #949aa0;
  font-size: 14px;
  z-index: 9;
  font-family: Roboto;
  opacity: 0.45;
`

export const OptionFourCharactersLeft = styled.div`
  position: absolute;
  right: 25px;
  top: 250px;
  color: #949aa0;
  font-size: 14px;
  z-index: 9;
  font-family: Roboto;
  opacity: 0.45;
`

export const OptionFiveCharactersLeft = styled.div`
  position: absolute;
  right: 25px;
  top: 297px;
  color: #949aa0;
  font-size: 14px;
  z-index: 9;
  font-family: Roboto;
  opacity: 0.45;
`
