/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-else-return */
import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { InputAdornment } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Spinner from 'react-bootstrap/Spinner'
import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { getWebscrapperInformations } from '~/helper/formFunctions'
import { InputTitle } from '~/components/Shared/StyledComponents'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import ContentVisualization from '~/components/CreateFormatMobileVisualization/Content'
import { transformDateToUtcFormat } from '~/helper/getFormattedDates'
import Cover from '~/components/Sponsor/Cover'
import { toast } from 'react-toastify'
import ShouldRender from '~/components/ShouldRender'
import DisplayCriteriaSelect from '~/components/DisplayCriteriaSelect'

import * as S from './styled'

export default function ModalEditVideo({ review, handleCloseModal, showModal, videoToUpdate, formatId, sponsor }) {
  const { t } = useTranslation()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const communityId = currentCampaignForReviewPage.networkId
  const { updateCampaignFormatAction } = useSponsorFunctions()
  const [loadingDebounce, setLoadingDebounce] = useState(false)
  const [scrapperDescription, setScrapperDescription] = useState(videoToUpdate.scrapperDescription)
  const [scrapperTitle, setScrapperTitle] = useState(videoToUpdate.scrapperTitle)
  const [, setScrapperTags] = useState(null)
  const [, setScrapperLink] = useState(null)
  const [, setIsUpdate] = useState(false)
  const [loadingImage, setLoadingImage] = useState(false)
  const { image, video, title } = videoToUpdate
  const [imageId, setImageId] = useState(image)
  const [imageUrl, setImageUrl] = useState(videoToUpdate.imageUrl)
  const [date, setDate] = useState(transformDateToUtcFormat(videoToUpdate.date))
  const [endDate, setEndDate] = useState(transformDateToUtcFormat(videoToUpdate.endDate))
  const { handleSubmit, setValue, register, control, errors, watch } = useForm()
  const videoLinkWatch = watch('video', video)
  const uploadTypeName = 'video'
  const sponsorManagerId = useSelector(state => state.sponsor.sponsorManager.id)

  const titleWatch = watch('title')
  const scrapperDescriptionWatch = watch('scrapperDescription')

  function onSubmit(product) {
    if (!product.imageUrl || !product.image) {
      return toast.error(t('defaultValidation', { inputName: t('image') }))
    }

    const copyObject = JSON.stringify(videoToUpdate)
    const productCopy = JSON.stringify(product)
    if (copyObject === productCopy) {
      return
    }

    product.scrapperTitle = titleWatch

    updateCampaignFormatAction(product, formatId, handleCloseModal)
  }

  useEffect(() => {
    register({ name: 'id' })
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
    setValue('id', videoToUpdate.id)
  }, [register, videoToUpdate.id, setValue, imageId, imageUrl])

  // Handle scrapper data updates
  useEffect(() => {
    setValue('title', scrapperTitle)
    setValue('scrapperTitle', scrapperTitle)
    setValue('scrapperDescription', scrapperDescription)

    if (scrapperTitle) {
      errors.title = undefined
    }

    if (scrapperDescription) {
      errors.scrapperDescription = undefined
    }
  }, [setValue, scrapperTitle, scrapperDescription])

  // Debounce webpage and ignore first render (watch input issue)
  const isFirstRun = useRef(true)
  useEffect(() => {
    const webScrapper = () => {
      if (isFirstRun.current) {
        isFirstRun.current = false
        return null
      } else {
        let typingTimer = null
        const val = videoLinkWatch
        clearTimeout(typingTimer)
        typingTimer = setTimeout(() => {
          if (val) {
            setLoadingDebounce(true)
            getWebscrapperInformations(
              videoLinkWatch,
              sponsorManagerId,
              communityId,
              setScrapperTitle,
              setScrapperDescription,
              setScrapperTags,
              setLoadingDebounce,
              setScrapperLink,
              setImageUrl,
              setImageId,
              setLoadingImage,
              setIsUpdate,
              uploadTypeName,
            )
          }
        }, 2000)
        return () => clearTimeout(typingTimer)
      }
    }
    webScrapper()
  }, [videoLinkWatch])

  useEffect(() => {
    if (scrapperTitle) {
      errors.title = undefined
    }

    if (scrapperDescription) {
      errors.scrapperDescription = undefined
    }
  }, [scrapperTitle, scrapperDescription])

  return (
    <ProductsModal
      action={onSubmit}
      buttonText={review ? t('done') : t('update')}
      modalTitle={review ? t('reviseFormat', { format: t('video') }) : t('editFormat', { format: t('video') })}
      handleCloseModal={handleCloseModal}
      showModal={showModal}
      review={review}
      sponsor={sponsor}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <InputTitle>{t('pastTheVideoLink')}</InputTitle>
            <Controller
              as={
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="https://www.example.com"
                  disabled={review || false}
                  InputProps={{
                    endAdornment: review ? null : (
                      <InputAdornment position="end">
                        {loadingDebounce ? <Spinner animation="border" size="sm" /> : ''}
                      </InputAdornment>
                    ),
                  }}
                />
              }
              rules={{ required: t('defaultValidation', { inputName: t('video') }) }}
              name="video"
              control={control}
              defaultValue={video === null ? '' : video}
            />
            <div className="error-message">
              <ErrorMessage errors={errors} name="video" as="p" />
            </div>

            <div className="mt-3">
              <InputTitle>{t('title')}</InputTitle>
              <Controller
                as={<TextField fullWidth variant="outlined" placeholder={t('typeTitle')} disabled={review || false} />}
                rules={{ required: t('defaultValidation', { inputName: t('title') }) }}
                name="title"
                control={control}
                defaultValue={title === null ? '' : title}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="title" as="p" />
              </div>
            </div>

            <div className="mt-3">
              <InputTitle>{t('description')}</InputTitle>

              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={t('typeDescription')}
                    disabled={review || false}
                  />
                }
                rules={{ required: t('defaultValidation', { inputName: t('description') }) }}
                name="scrapperDescription"
                control={control}
                defaultValue={scrapperDescription}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="scrapperDescription" as="p" />
              </div>
            </div>

            <DisplayCriteriaSelect control={control} value={videoToUpdate?.privacy} disabled={review || false} />

            <StartDateSection
              product={t('video')}
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              disabled={review}
              control={control}
              errors={errors}
            />

            <EndDateSection
              product={t('video')}
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              disabled={review}
              control={control}
              errors={errors}
            />

            <ShouldRender if={!review}>
              <Cover setImageUrl={setImageUrl} setImageId={setImageId} imageUrl={imageUrl} loading={loadingImage} />
            </ShouldRender>
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          FormatVisualizationComponent={
            <ContentVisualization
              loadingImage={loadingImage}
              image={imageUrl}
              title={titleWatch}
              about={scrapperDescriptionWatch}
              contentType={t('video')}
            />
          }
        />
      }
    />
  )
}
