import React from 'react'
import * as S from './styled'

export default function ProfileButton({
  backgroundColor,
  width,
  height,
  image,
  style,
  onClick,
  imgHeight,
  imgWidth,
  type,
  review,
  className,
}) {
  return (
    <S.Button
      onClick={onClick}
      style={style}
      backgroundColor={backgroundColor}
      width={width}
      height={height}
      image={image}
      imgHeight={imgHeight}
      imgWidth={imgWidth}
      type={type}
      className={className}
    >
      {review ? <S.ReviewProfileImage width={imgWidth} height={imgHeight} /> : <S.EditProfileImage />}
    </S.Button>
  )
}
