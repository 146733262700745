import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultUserProfileImage } from '~/util/defaultImages'
import Border from '~/components/Shared/Border'
import LanguageSelector from '~/components/Shared/LanguageSelector'
import * as S from './styled'

export default function UserHeaderProfile({
  logoutAction,
  signInAs,
  userInformations,
  closePopover,
  openModalUserProfile,
}) {
  const { t } = useTranslation()
  const { firstName, lastName, userImageUrl } = userInformations || {}

  const openUserProfileAndClosePopover = () => {
    closePopover()
    openModalUserProfile()
  }

  return (
    <S.PopoverContent>
      <S.HeaderContainer>
        <S.UserAvatarContainer>
          <S.UserAvatar src={userImageUrl || DefaultUserProfileImage} />
        </S.UserAvatarContainer>
        <S.UserInformationContainer>
          <S.UserName>{`${firstName} ${lastName}`}</S.UserName>
          <S.SignInAsContainer>
            <S.SignInLabel>{t('signedAs')}</S.SignInLabel>
            <S.SignInText>{signInAs}</S.SignInText>
          </S.SignInAsContainer>
          <S.ViewProfile onClick={openUserProfileAndClosePopover}>{t('viewProfile')}</S.ViewProfile>
        </S.UserInformationContainer>
      </S.HeaderContainer>
      <S.BorderContainer>
        <Border />
      </S.BorderContainer>
      <LanguageSelector closePopover={closePopover} />
      <S.SignOutSection>
        <S.ProfileOption onClick={logoutAction}>{t('signOut')}</S.ProfileOption>
      </S.SignOutSection>
    </S.PopoverContent>
  )
}
