import React, { useState, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { toast } from 'react-toastify'
import { IoIosImages } from 'react-icons/io'
import { FaRegImage } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import { InputTitle, FormatUploadButton } from '~/components/Shared/StyledComponents'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import { UploadS3 } from '~/helper/formFunctions'
import Border from '~/components/Shared/Border'
import * as S from './styled'

export default function ModalCreateWelcomeAd({ handleCloseModal, showModal, campaignProductId }) {
  const { t } = useTranslation()
  const { createCampaignFormatAction } = useSponsorFunctions()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const [imageUrl, setImageUrl] = useState(null)
  const [imageId, setImageId] = useState(null)
  const [updatingImage, setUpdatingImage] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [date, setDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const { handleSubmit, register, setValue, control, errors } = useForm()

  function resetFormAfterSubmit() {
    setDate('')
    setEndDate(null)
    setImageUrl(null)
    setImageUrl(null)
  }

  function closeModal() {
    resetFormAfterSubmit()
    handleCloseModal()
  }

  function onSubmit(format) {
    if (format.imageUrl == null) {
      return toast.error(t('defaultValidation', { inputName: t('image') }))
    }
    createCampaignFormatAction(currentCampaignForReviewPage.id, campaignProductId, format, null, closeModal)
  }

  useEffect(() => {
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
  }, [register, setValue, imageUrl, imageId])

  return (
    <ProductsModal
      action={onSubmit}
      modalTitle={t('newFormat', { format: t('welcomeAd') })}
      handleCloseModal={closeModal}
      showModal={showModal}
      review={false}
      loadingUpload={loadingUpload}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <div>
              <InputTitle>{t('name')}</InputTitle>
              <Controller
                as={<TextField fullWidth variant="outlined" placeholder={t('typeName')} />}
                rules={{ required: t('defaultValidation', { inputName: t('name') }) }}
                name="name"
                control={control}
              />
            </div>
            <div className="error-message">
              <ErrorMessage errors={errors} name="name" as="p" />
            </div>

            <StartDateSection
              product={t('welcomeAd')}
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              control={control}
              errors={errors}
            />
            <EndDateSection
              product={t('welcomeAd')}
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              control={control}
              errors={errors}
            />

            <div className="mt-3">
              <InputTitle>{t('link')}</InputTitle>
              <Controller
                as={<TextField fullWidth variant="outlined" placeholder="e.g. https://www.greatecontent.com" />}
                rules={{ required: t('defaultValidation', { inputName: t('link') }) }}
                name="link"
                control={control}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="link" as="p" />
              </div>
            </div>

            <div className="mt-4 mb-2">
              <Border />
            </div>
            {updatingImage ? (
              <div className="mt-3">
                <div className="d-flex justify-content-center align-items-center">
                  <FaRegImage className="mr-2" size={14} />
                  <InputTitle>{t('image')}</InputTitle>
                </div>
                <UploadS3
                  setUploadId={setImageId}
                  setUploadUrl={setImageUrl}
                  setLoadingUpload={setLoadingUpload}
                  fileType="image"
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <FormatUploadButton text={t('addImage')} action={() => setUpdatingImage(!updatingImage)} />
              </div>
            )}
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <S.Container>
          <S.DeviceButton />
          <S.DeviceScreen>
            <div className="image-container">
              {imageUrl !== null ? (
                <div className="image-container">
                  <img className="animated fadeIn" src={imageUrl} alt="" />
                  <S.SkipAdd>{t('skipAdd')}</S.SkipAdd>
                </div>
              ) : (
                <>
                  <img
                    style={{
                      backgroundColor: '#CCC',
                    }}
                    alt=""
                    className="d-flex justify-content-center align-items"
                  />
                  <IoIosImages
                    style={{
                      position: 'absolute',
                      top: '-9999px',
                      bottom: '-9999px',
                      left: '-9999px',
                      right: '-9999px',
                      margin: 'auto',
                    }}
                    color="red"
                    size={28}
                  />
                </>
              )}
            </div>
          </S.DeviceScreen>
        </S.Container>
      }
    />
  )
}
