import React from 'react'
// import { useTranslation } from 'react-i18next'
import { DefaultLogoImage } from '~/util/defaultImages'
import * as S from './styled'

const LinkedinContentPreview = ({ community, inputWatch, media, videoThumbnail }) => {
  // const { t } = useTranslation()

  return (
    <>
      <S.TopPreviewContainer>
        <S.Icons>
          <S.LikeIcon />
          <S.LoveIcon />
          <S.SupportIcon />
          <S.CelebrateIcon />
          <S.CuriousIcon />
        </S.Icons>

        <S.ActionsContainer>
          <S.Action>
            <S.LikeOutlinedIcon />
            <S.ActionText>Like</S.ActionText>
          </S.Action>
          <S.Action>
            <S.CommentIcon />
            <S.ActionText>Comment</S.ActionText>
          </S.Action>
          <S.Action>
            <S.ShareIcon />
            <S.ActionText>Share</S.ActionText>
          </S.Action>
          <S.Action>
            <S.SendIcon />
            <S.ActionText>Send</S.ActionText>
          </S.Action>
        </S.ActionsContainer>
      </S.TopPreviewContainer>
      <S.LinkedinBackground />

      <S.MiddlePreviewContainer>
        <S.PostHeader>
          <S.CommunityInformations>
            <S.CommunityLogo src={community?.networkLogo || DefaultLogoImage} />
            <S.CommunityNameAndDate>
              <S.CommunityName>{community?.fullName}</S.CommunityName>
              <S.Text>5.222 followers</S.Text>
              <S.Text>Promoted</S.Text>
            </S.CommunityNameAndDate>
          </S.CommunityInformations>

          <S.ThreeDotsIcon />
        </S.PostHeader>

        {inputWatch?.description ? (
          <S.PostCommentAndDescription>{`${inputWatch?.title} - ${inputWatch.description}`}</S.PostCommentAndDescription>
        ) : (
          <S.PostComment>{inputWatch?.title}</S.PostComment>
        )}

        {media ? (
          <S.MediaContainer>
            <S.MediaImage src={videoThumbnail || media} />
            {videoThumbnail && (
              <S.PlayIconContainer>
                <S.PlayIcon />
              </S.PlayIconContainer>
            )}
          </S.MediaContainer>
        ) : (
          <S.MediaContainer>
            <S.NoMediaIndicator>
              <S.ImageIcon />
            </S.NoMediaIndicator>
          </S.MediaContainer>
        )}
        <S.LearnMoreContainer>
          <S.SponsoredContentText>Sponsored content</S.SponsoredContentText>
          <S.LearnMore>Learn more</S.LearnMore>
        </S.LearnMoreContainer>

        <S.Icons>
          <S.LikeIcon />
          <S.SupportIcon />
          <S.CelebrateIcon />
        </S.Icons>

        <S.ActionsContainer>
          <S.Action>
            <S.LikeOutlinedIcon />
            <S.ActionText>Like</S.ActionText>
          </S.Action>
          <S.Action>
            <S.CommentIcon />
            <S.ActionText>Comment</S.ActionText>
          </S.Action>
          <S.Action>
            <S.ShareIcon />
            <S.ActionText>Share</S.ActionText>
          </S.Action>
          <S.Action>
            <S.SendIcon />
            <S.ActionText>Send</S.ActionText>
          </S.Action>
        </S.ActionsContainer>
      </S.MiddlePreviewContainer>
      <S.LinkedinBackground />

      <S.BottomPreviewContainer>
        <S.PostHeader>
          <S.CommunityInformations>
            <S.CommunityLogo src={community?.networkLogo || DefaultLogoImage} />
            <S.CommunityNameAndDate>
              <S.CommunityName>{community?.fullName}</S.CommunityName>
              <S.Text>5.222 followers</S.Text>
              <S.Text>Promoted</S.Text>
            </S.CommunityNameAndDate>
          </S.CommunityInformations>

          <S.ThreeDotsIcon />
        </S.PostHeader>
      </S.BottomPreviewContainer>
    </>
  )
}

export default LinkedinContentPreview
