import React from 'react'
import { ResponsiveAnimation } from '~/components/ResposiveAnimation'
import * as S from './styles'
import { LinkCard } from '../../Components/LinkCard/LinkCard'

export const Sponsor = () => {
  return (
    <S.Container id="monetization">
      <ResponsiveAnimation type="fade" applyOn="both" effects={{ direction: 'left' }}>
        <h1>As sponsor you get better R.O.I.</h1>
      </ResponsiveAnimation>
      <S.GridContainer columns={3}>
        <ResponsiveAnimation type="fade" applyOn="both" effects={{ direction: 'left', cascade: true, damping: 0.2 }}>
          <LinkCard
            leftIcon="/images/sponsor-icon.svg"
            text="Traditional sponsorship is over! It gives limited results in terms of interaction with users."
          />
          <LinkCard
            leftIcon="/images/currency-icon.svg"
            text="Digital new formats for sponsorship are ideal to push conversion beside brand
          awareness."
          />
          <LinkCard
            leftIcon="/images/target-icon.svg"
            text="Specific communities offer new opportunities for effective communication with
          well defined targets."
          />
        </ResponsiveAnimation>
      </S.GridContainer>
      <ResponsiveAnimation type="fade" applyOn="both" effects={{ direction: 'right', delay: 100 }}>
        <S.GridContainer columns={2}>
          <LinkCard
            leftIcon="/images/light-icon.svg"
            text="Enjoy a set of functionalities to select the packages, pay online, upload your
          sponsored contents, get validation from the community, follow the results of the
          campaign and more."
          />
          <LinkCard
            leftIcon="/images/roi-icon.svg"
            text="Sponsors invest budgets on well-defined targets with higher R.O.I vs generic social media campaigns"
          />
        </S.GridContainer>
      </ResponsiveAnimation>
    </S.Container>
  )
}
