import { MdTrendingUp, MdTrendingDown } from 'react-icons/md'
import styled from 'styled-components'

export const CardContainer = styled.div`
  background-color: #fff;
  padding: 10px 10px 20px 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 2px rgba(240, 245, 254, 1);
  width: 190px;
  height: 90px;
  margin-right: 14px;
  margin-top: 24px;
`

export const CardPercentageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  svg {
    margin-left: 3px;
  }
`

export const CardPercentageText = styled.p`
  margin: 0px;
  color: ${props => (props.status === 'down' ? '#ff0006' : '#2b9900')};
  font-family: Roboto;
  font-size: 10px;
  font-weight: bold;
`

export const CardValueAndTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CardValue = styled.p`
  margin: 0px;
  color: #47525e;
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
`

export const CardTitle = styled.p`
  margin: 0px;
  color: var(--grey);
  font-family: Roboto;
  font-size: 14px;
`

export const GreenStatusIcon = styled(MdTrendingUp).attrs({
  size: 14,
  color: '#2b9900',
})``

export const RedStatusIcon = styled(MdTrendingDown).attrs({
  size: 14,
  color: '#ff0006',
})``

export const TooltipContainer = styled.div``
