import * as Yup from 'yup'

const userStoreValidator = Yup.object()
  .shape({
    email: Yup.string()
      .email()
      .required(),
    phoneNumber: Yup.string().required('Phone number is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    password: Yup.string()
      .required()
      .min(6),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match.'),
  })
  .required()

export default userStoreValidator
