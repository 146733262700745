import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 16px;
`

export const ErrorMessageContainer = styled.div.attrs({
  className: 'error-message',
})``

export const InputContainer = styled.div`
  display: flex;
`
