import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultLogoImage } from '~/util/defaultImages'
import * as S from './styled'

const FacebookContentPreview = ({ community, inputWatch, media, videoThumbnail }) => {
  const { t } = useTranslation()

  return (
    <>
      <S.TopPreviewContainer>
        <S.OptionContainer>
          <S.OptionSelected>Posts</S.OptionSelected>
          <S.Option>About</S.Option>
          <S.Option>Photos</S.Option>
          <S.Option>Mentions</S.Option>
        </S.OptionContainer>
        <S.ActionsContainer>
          <S.Action>
            <S.LikeIcon />
            <S.ActionText>{t('like')}</S.ActionText>
          </S.Action>
          <S.Action>
            <S.CommentIcon />
            <S.ActionText>{t('comment')}</S.ActionText>
          </S.Action>
          <S.Action>
            <S.ShareIcon />
            <S.ActionText>{t('share')}</S.ActionText>
          </S.Action>
        </S.ActionsContainer>
      </S.TopPreviewContainer>

      <S.MiddlePreviewContainer>
        <S.PostHeader>
          <S.CommunityInformations>
            <S.CommunityLogo src={community?.networkLogo || DefaultLogoImage} />
            <S.CommunityNameAndDate>
              <S.CommunityName>{community?.fullName}</S.CommunityName>
              <S.DateAndWorldIcon>
                <S.Date>1 Sep 2020</S.Date>
                <S.Dot>&middot;</S.Dot>
                <S.WorldIcon />
              </S.DateAndWorldIcon>
            </S.CommunityNameAndDate>
          </S.CommunityInformations>
          <S.ThreeDotsIcon />
        </S.PostHeader>
        {inputWatch?.description ? (
          <S.PostCommentAndDescription>{`${inputWatch?.title} - ${inputWatch.description}`}</S.PostCommentAndDescription>
        ) : (
          <S.PostComment>{inputWatch?.title}</S.PostComment>
        )}

        {media ? (
          <S.MediaContainer>
            <S.MediaImage src={videoThumbnail || media} />
            {videoThumbnail && (
              <S.PlayIconContainer>
                <S.PlayIcon />
              </S.PlayIconContainer>
            )}
          </S.MediaContainer>
        ) : (
          <S.MediaContainer>
            <S.NoMediaIndicator>
              <S.ImageIcon />
            </S.NoMediaIndicator>
          </S.MediaContainer>
        )}

        <S.LikeAndComment>
          <S.LikeAndLove>
            <S.Like>
              <S.LikeFilledIcon />
            </S.Like>
            <S.Love>
              <S.LoveIcon />
            </S.Love>
            <S.SectionText spacing>9.8K</S.SectionText>
          </S.LikeAndLove>

          <S.CommentsAndShares>
            <S.SectionText>659</S.SectionText>
            <S.SectionText>{t('comments')}</S.SectionText>

            <S.SectionText spacing>171</S.SectionText>
            <S.SectionText>{t('shares')}</S.SectionText>
          </S.CommentsAndShares>
        </S.LikeAndComment>
        <S.ActionsContainer spacing>
          <S.Action>
            <S.LikeIcon />
            <S.ActionText>{t('like')}</S.ActionText>
          </S.Action>
          <S.Action>
            <S.CommentIcon />
            <S.ActionText>{t('comment')}</S.ActionText>
          </S.Action>
          <S.Action>
            <S.ShareIcon />
            <S.ActionText>{t('share')}</S.ActionText>
          </S.Action>
        </S.ActionsContainer>
      </S.MiddlePreviewContainer>

      <S.BottomPreviewContainer>
        <S.PostHeader>
          <S.CommunityInformations>
            <S.CommunityLogo src={community?.networkLogo || DefaultLogoImage} />
            <S.CommunityNameAndDate>
              <S.CommunityName>{community?.fullName}</S.CommunityName>
              <S.DateAndWorldIcon>
                <S.Date>1 Sep 2020</S.Date>
                <S.Dot>&middot;</S.Dot>
                <S.WorldIcon />
              </S.DateAndWorldIcon>
            </S.CommunityNameAndDate>
          </S.CommunityInformations>
          <S.ThreeDotsIcon />
        </S.PostHeader>
        <S.PostComment>Title text</S.PostComment>
      </S.BottomPreviewContainer>
    </>
  )
}

export default FacebookContentPreview
