/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import ShouldRender from '~/components/ShouldRender'
import { checkIfTheFormatIsASocialMediaPost } from '~/helper/helperFunctions'
import ModalCreateWelcomeAd from '~/components/Sponsor/Modals/WelcomeAD/ModalCreateWelcomeAd'
import ModalCreateChatChannel from '~/components/Sponsor/Modals/ChatChannel/ModalCreateChatChannel'
import ModalCreateReward from '~/components/Sponsor/Modals/Reward/ModalCreateReward'
import ModalCreateEvent from '~/components/Sponsor/Modals/Event/ModalCreateEvent'
import ModalCreatePost from '~/components/Sponsor/Modals/Post/ModalCreatePost'
import ModalContentDropdown from '~/components/Sponsor/Modals/ModalContentDropdown'
import ModalCreateSurveyFistStep from '~/components/Sponsor/Modals/Survey/ModalCreateSurveyFistStep'
import ModalCreateSocialMediaPostFormat from '~/components/SocialMedia/ModalCreateSocialMediaPostFormat'
import ModalCreateCommunityStory from '~/components/Sponsor/Modals/CommunityStories/ModalCreateCommunityStory'
import ModalCreateStoriesBottomBanner from '~/components/Sponsor/Modals/StoriesBottomBanner/ModalCreateStoriesBottomBanner'
import ModalCreateSlidingTopBanner from '~/components/Sponsor/Modals/SlidingTopBanner/ModalCreateSlidingTopBanner'
import ModalCreateScratchCard from '~/components/Sponsor/Modals/ScratchCard/ModalCreateScratchCard'
import * as S from './styled'

export default function FormatsProgressBar({ campaignFormat, campaign, currentSelectedMonth }) {
  const { t } = useTranslation()
  const { campaignStatus } = campaign || {}
  const [style, setStyle] = React.useState({})
  const [showModalCreateWelcomeAd, setShowModalCreateWelcomeAd] = useState(false)
  const [showModalCreateReward, setShowModalCreateReward] = useState(false)
  const [showModalCreateEvent, setShowModalCreateEvent] = useState(false)
  const [showModalCreatePost, setShowModalCreatePost] = useState(false)
  const [showModalCreateContent, setShowModalCreateContent] = useState(false)
  const [showModalCreateSurvey, setShowModalCreateSurvey] = useState(false)
  const [showModalCreateChatChannel, setShowModalCreateChatChannel] = useState(false)
  const [showModalCreateSocialMediaFormat, setShowModalCreateSocialMediaFormat] = useState(false)
  const [showModalCreateCommunityStory, setShowModalCreateCommunityStory] = useState(false)
  const [showModalCreateStoriesBottomBanner, setShowModalCreateStoriesBottomBanner] = useState(false)
  const [showModalCreateSlidingTopBanner, setShowModalCreateSlidingTopBanner] = useState(false)
  const [showModalScrachCard, setShowModalScratchCard] = useState(false)
  const [campaignProductId, setCampaignProductId] = useState(null)
  const [isFormatButtonDisabled, setIsFormatButtonDisabled] = useState(false)

  const todayDate = new Date()
  const actualMonth = new Date().getMonth() + 1

  const getPercentageOfProductsUsed = () => {
    const percentage = (campaignFormat.qtdUsed / campaignFormat.qtdTotal) * 100
    return percentage.toFixed(0)
  }

  const {
    id: formatId,
    product: { productType },
    endDate,
  } = campaignFormat || {}

  useEffect(() => {
    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${getPercentageOfProductsUsed()}%`,
      }
      setStyle(newStyle)
    }, 200)
  }, [campaignFormat])

  useEffect(() => {
    if (currentSelectedMonth < actualMonth) {
      if (todayDate > new Date(endDate)) {
        setIsFormatButtonDisabled(true)
      }
    }
  }, [currentSelectedMonth, actualMonth])

  const openCreateFormatModal = () => {
    if (campaignStatus === 'finalized') {
      return toast.error(t('campaingIsFinalized'))
    }
    if (productType === 'welcomeAd') {
      setShowModalCreateWelcomeAd(true)
    }
    if (productType === 'scratchCard') {
      setShowModalScratchCard(true)
    }
    if (productType === 'event') {
      setShowModalCreateEvent(true)
    }
    if (productType === 'reward') {
      setShowModalCreateReward(true)
    }
    if (productType === 'content') {
      setShowModalCreateContent(true)
    }
    if (productType === 'survey') {
      setShowModalCreateSurvey(true)
    }
    if (productType === 'chatChannel') {
      setShowModalCreateChatChannel(true)
    }
    if (productType === 'communityStory') {
      setShowModalCreateCommunityStory(true)
    }
    if (productType === 'bottomStoriesBanner') {
      setShowModalCreateStoriesBottomBanner(true)
    }
    if (productType === 'slidingTopBanner') {
      setShowModalCreateSlidingTopBanner(true)
    }
    if (checkIfTheFormatIsASocialMediaPost(productType)) {
      setShowModalCreateSocialMediaFormat(true)
    }
    return setCampaignProductId(formatId)
  }

  return (
    <S.ProgressBarContainer>
      <S.TitleAndButtonContainer>
        {productType === 'custom' ? (
          <S.ProductTitle>{campaignFormat?.product?.name}</S.ProductTitle>
        ) : (
          <S.ProductTitle>{t(campaignFormat?.product?.slug)}</S.ProductTitle>
        )}

        <ShouldRender if={productType !== 'custom'}>
          {campaignFormat?.qtdTotal > campaignFormat?.qtdUsed && (
            <S.LoadNewContentButton
              disabled={isFormatButtonDisabled}
              onClick={
                isFormatButtonDisabled ? () => toast.error(t('cantCreateFormat')) : () => openCreateFormatModal()
              }
            >
              <S.LoadNewContentIcon />
              <S.LoadNewContentText>{t('loadNewContentForValidation')}</S.LoadNewContentText>
            </S.LoadNewContentButton>
          )}
        </ShouldRender>
      </S.TitleAndButtonContainer>

      <ShouldRender if={productType !== 'custom'}>
        <S.ProgressContainer>
          {getPercentageOfProductsUsed() !== '0' && (
            <S.ProgressDone style={style}>
              <S.ProgressDoneText percentage={getPercentageOfProductsUsed()}>
                {`${getPercentageOfProductsUsed()}%`}
              </S.ProgressDoneText>
            </S.ProgressDone>
          )}
        </S.ProgressContainer>
      </ShouldRender>

      <ShouldRender if={productType === 'custom'}>
        <S.ProgressContainer>
          <S.ProgressDone style={{ opacity: 1, width: '100%' }}>
            <S.ProgressDoneText percentage={100}>100%</S.ProgressDoneText>
          </S.ProgressDone>
        </S.ProgressContainer>
      </ShouldRender>

      <ShouldRender if={productType !== 'custom'}>
        <S.ProductQuantityAvailable>
          {`${campaignFormat?.qtdUsed} ${t('usedOf')} ${campaignFormat?.qtdTotal} ${t('available')}`}
        </S.ProductQuantityAvailable>
      </ShouldRender>

      <ModalCreateWelcomeAd
        handleCloseModal={() => setShowModalCreateWelcomeAd(false)}
        showModal={showModalCreateWelcomeAd}
        campaignProductId={campaignProductId}
      />
      <ModalCreateScratchCard
        handleCloseModal={() => setShowModalScratchCard(false)}
        showModal={showModalScrachCard}
        campaignProductId={campaignProductId}
      />
      <ModalCreateChatChannel
        handleCloseModal={() => setShowModalCreateChatChannel(false)}
        showModal={showModalCreateChatChannel}
        campaignProductId={campaignProductId}
      />
      <ModalCreateReward
        handleCloseModal={() => setShowModalCreateReward(false)}
        showModal={showModalCreateReward}
        campaignProductId={campaignProductId}
      />
      <ModalCreateEvent
        handleCloseModal={() => setShowModalCreateEvent(false)}
        showModal={showModalCreateEvent}
        campaignProductId={campaignProductId}
      />
      <ModalCreatePost
        handleCloseModal={() => setShowModalCreatePost(false)}
        showModal={showModalCreatePost}
        campaignProductId={campaignProductId}
      />
      <ModalContentDropdown
        handleCloseModal={() => setShowModalCreateContent(false)}
        showModal={showModalCreateContent}
        campaignProductId={campaignProductId}
      />
      <ModalCreateSurveyFistStep
        handleCloseModal={() => setShowModalCreateSurvey(false)}
        showModal={showModalCreateSurvey}
        campaignProductId={campaignProductId}
      />
      <ModalCreateSocialMediaPostFormat
        handleCloseModal={() => setShowModalCreateSocialMediaFormat(false)}
        showModal={showModalCreateSocialMediaFormat}
        campaignProductId={campaignProductId}
        socialMediaFormat={campaignFormat?.product}
        community={campaign?.community}
      />
      <ModalCreateCommunityStory
        handleCloseModal={() => setShowModalCreateCommunityStory(false)}
        showModal={showModalCreateCommunityStory}
        campaignProductId={campaignProductId}
        communityStory={campaignFormat?.product}
      />
      <ModalCreateStoriesBottomBanner
        handleCloseModal={() => setShowModalCreateStoriesBottomBanner(false)}
        showModal={showModalCreateStoriesBottomBanner}
        campaignProductId={campaignProductId}
        communityStory={campaignFormat?.product}
      />
      <ModalCreateSlidingTopBanner
        handleCloseModal={() => setShowModalCreateSlidingTopBanner(false)}
        showModal={showModalCreateSlidingTopBanner}
        campaignProductId={campaignProductId}
        communityStory={campaignFormat?.product}
      />
    </S.ProgressBarContainer>
  )
}
