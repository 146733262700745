import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'cropperjs/dist/cropper.css'
import { modalBodyStyleDefault } from '~/helper/const'
import useCommunitySelectors from '~/helper/communitySelectors'
import useCommunityFunctions from '~/helper/communityFunctions'
import CommunityLoginCard from '~/components/Community/CommunityLoginCard'
import { signOut as signOutAction } from '~/store/modules/auth/actions'
import * as S from './styled'

export default function ModalChooseCommunities({ handleCloseModal, showModal }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { allCommunitiesFromCommunityManager } = useCommunitySelectors()
  const { setUserCurrentCommunityAction } = useCommunityFunctions()

  function closeModalAndResetState() {
    dispatch(signOutAction())
    localStorage.removeItem('communityCredentials')
    handleCloseModal()
  }

  return (
    <S.ModalCustom show={showModal} onHide={closeModalAndResetState}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.Title>{t('pleaseSelectTheCommunity')}</S.Title>
          <S.Subtitle>{t('managerMultipleCommunities')}</S.Subtitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBodyStyleDefault}>
        <Row>
          {allCommunitiesFromCommunityManager() !== null
            ? allCommunitiesFromCommunityManager().map(community => (
                <S.LoginCardColumn sm={6} xs={12} key={community.id}>
                  <CommunityLoginCard
                    action={() => setUserCurrentCommunityAction(community)}
                    communityBanner={community.networkBannerPath}
                    communityLogo={community.networkLogoPath}
                    communityName={community.name}
                    communityLocation={community.adrState}
                    community={community}
                  />
                </S.LoginCardColumn>
              ))
            : null}
        </Row>
      </Modal.Body>
    </S.ModalCustom>
  )
}
