import React from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

export default function SkeletonChatBox() {
  const { t } = useTranslation()

  return (
    <S.CardContainer className="pb-5" bg="#FFFFFF">
      <span>{t('waitingApprovementSponsor')}</span>

      <div className="mt-2 p-2">
        <ContentLoader height={54} width={320} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
          <circle cx="27" cy="27" r="25" />
          <rect x="56" y="10" rx="3" ry="3" width="180" height="18" />
          <rect x="56" y="32" rx="3" ry="3" width="84" height="14" />
          <circle cx="305" cy="27" r="8" />
          <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
        </ContentLoader>

        <ContentLoader
          className="mt-3"
          height={54}
          width={320}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <circle cx="27" cy="27" r="25" />
          <rect x="56" y="10" rx="3" ry="3" width="180" height="18" />
          <rect x="56" y="32" rx="3" ry="3" width="84" height="14" />
          <circle cx="305" cy="27" r="8" />
          <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
        </ContentLoader>
      </div>

      <span>Ongoing campaigns</span>
      <div className="mt-2 p-2">
        <ContentLoader height={54} width={320} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
          <circle cx="27" cy="27" r="25" />
          <rect x="56" y="10" rx="3" ry="3" width="180" height="18" />
          <rect x="56" y="32" rx="3" ry="3" width="84" height="14" />
          <circle cx="305" cy="27" r="8" />
          <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
        </ContentLoader>

        <ContentLoader
          className="mt-3"
          height={54}
          width={320}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <circle cx="27" cy="27" r="25" />
          <rect x="56" y="10" rx="3" ry="3" width="180" height="18" />
          <rect x="56" y="32" rx="3" ry="3" width="84" height="14" />
          <circle cx="305" cy="27" r="8" />
          <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
        </ContentLoader>
      </div>

      <span>from past campaigns</span>

      <div className="mt-2 p-2">
        <ContentLoader height={54} width={320} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
          <circle cx="27" cy="27" r="25" />
          <rect x="56" y="10" rx="3" ry="3" width="180" height="18" />
          <rect x="56" y="32" rx="3" ry="3" width="84" height="14" />
          <circle cx="305" cy="27" r="8" />
          <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
        </ContentLoader>

        <ContentLoader
          className="mt-3"
          height={54}
          width={320}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <circle cx="27" cy="27" r="25" />
          <rect x="56" y="10" rx="3" ry="3" width="180" height="18" />
          <rect x="56" y="32" rx="3" ry="3" width="84" height="14" />
          <circle cx="305" cy="27" r="8" />
          <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
        </ContentLoader>

        <ContentLoader
          className="mt-3"
          height={54}
          width={320}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <circle cx="27" cy="27" r="25" />
          <rect x="56" y="10" rx="3" ry="3" width="180" height="18" />
          <rect x="56" y="32" rx="3" ry="3" width="84" height="14" />
          <circle cx="305" cy="27" r="8" />
          <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
        </ContentLoader>

        <ContentLoader
          className="mt-3"
          height={54}
          width={320}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <circle cx="27" cy="27" r="25" />
          <rect x="56" y="10" rx="3" ry="3" width="180" height="18" />
          <rect x="56" y="32" rx="3" ry="3" width="84" height="14" />
          <circle cx="305" cy="27" r="8" />
          <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
        </ContentLoader>
      </div>
    </S.CardContainer>
  )
}
