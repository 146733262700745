import { useCallback } from 'react'
import { useSendMessage } from '~/hooks/'
import { sendMessageParse, parseReplyMessage } from '~/helper/chatFunctions'
import useChatFunctions from '~/helper/chatActions'
import { clean } from '~/helper/helperFunctions'
import { store } from '~/store'

const useSendGif = () => {
  const [sendMessage, { loading }] = useSendMessage()
  const { setActiveReplyMessageAction } = useChatFunctions()

  const send = useCallback(
    async (gif, setIsComponentVisibleGif) => {
      try {
        const { activeReplyMessage } = store.getState().chat
        const { activeChannel } = store.getState().chat

        const message = clean(
          sendMessageParse({
            channelId: activeChannel?.id,
            gif,
            reply: activeReplyMessage && parseReplyMessage(activeReplyMessage),
          }),
          {
            delete: true,
          },
        )
        setActiveReplyMessageAction(null)
        setIsComponentVisibleGif(false)

        await sendMessage({ message })
      } catch (error) {
        console.log(error)
      }
    },
    [sendMessage, setActiveReplyMessageAction],
  )

  return [send, { loading }]
}

export default useSendGif
