/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { InputAdornment } from '@material-ui/core'
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { getWebscrapperInformations } from '~/helper/formFunctions'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import { InputTitle } from '~/components/Shared/StyledComponents'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import ContentVisualization from '~/components/CreateFormatMobileVisualization/Content'
import Cover from '~/components/Sponsor/Cover'
import { toast } from 'react-toastify'
import DisplayCriteriaSelect from '~/components/DisplayCriteriaSelect'

import 'react-lazy-load-image-component/src/effects/blur.css'
import * as S from './styled'

export default function ModalCreateWeb({ handleCloseModal, showModal, setContent, campaignProductId }) {
  const { t } = useTranslation()
  const { createCampaignFormatAction } = useSponsorFunctions()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const sponsorManagerId = useSelector(state => state.sponsor.sponsorManager.id)
  const [loadingDebounce, setLoadingDebounce] = useState(false)
  const [scrapperDescription, setScrapperDescription] = useState(null)
  const [scrapperTitle, setScrapperTitle] = useState(null)
  const [, setScrapperTags] = useState(null)
  const [scrapperLink, setScrapperLink] = useState(null)
  const [, setIsUpdate] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)
  const [imageId, setImageId] = useState(null)
  const [loadingImage, setLoadingImage] = useState(false)
  const [date, setDate] = useState('')
  const { handleSubmit, setValue, register, control, errors, watch } = useForm()
  const [endDate, setEndDate] = useState('')

  const uploadTypeName = 'image'
  const webpageWatch = watch('webpage')

  const scrapperTitleWatch = watch('scrapperTitle')
  const scrapperDescriptionWatch = watch('scrapperDescription')

  function closeModalAndResetContent() {
    setContent(0)
    handleCloseModal()
  }

  async function onSubmit(format) {
    if (loadingImage) return

    if (!format.imageUrl || !format.image) {
      return toast.error(t('defaultValidation', { inputName: t('image') }))
    }

    createCampaignFormatAction(
      currentCampaignForReviewPage.id,
      campaignProductId,
      format,
      'web',
      closeModalAndResetContent,
    )
  }

  // Register fields only once
  useEffect(() => {
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    register({ name: 'scrapperTitle' })
    register({ name: 'scrapperLink' })
    register({ name: 'scrapperDescription' })
  }, [register])

  // Handle image updates
  useEffect(() => {
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
  }, [setValue, imageUrl, imageId])

  // Handle scrapper data updates
  useEffect(() => {
    setValue('scrapperTitle', scrapperTitle)
    setValue('scrapperLink', scrapperLink)
    setValue('scrapperDescription', scrapperDescription)

    if (scrapperTitle) {
      errors.scrapperTitle = undefined
    }

    if (scrapperDescription) {
      errors.scrapperDescription = undefined
    }
  }, [setValue, scrapperTitle, scrapperLink, scrapperDescription, errors.scrapperTitle, errors.scrapperDescription])

  // Debounce webpage
  useEffect(() => {
    let typingTimer = null
    const val = webpageWatch
    clearTimeout(typingTimer)
    typingTimer = setTimeout(() => {
      if (val) {
        setLoadingDebounce(true)
        getWebscrapperInformations(
          webpageWatch,
          sponsorManagerId,
          currentCampaignForReviewPage.networkId,
          setScrapperTitle,
          setScrapperDescription,
          setScrapperTags,
          setLoadingDebounce,
          setScrapperLink,
          setImageUrl,
          setImageId,
          setLoadingImage,
          setIsUpdate,
          uploadTypeName,
          null,
        )
      }
    }, 2000)
    return () => clearTimeout(typingTimer)
  }, [currentCampaignForReviewPage.networkId, sponsorManagerId, webpageWatch])

  return (
    <ProductsModal
      action={onSubmit}
      modalTitle={t('newFormat', { format: t('webpage') })}
      handleCloseModal={closeModalAndResetContent}
      showModal={showModal}
      review={false}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <InputTitle>{t('pastTheWebpageLink')}</InputTitle>
            <Controller
              as={
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="https://www.example.com"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {loadingDebounce ? <Spinner animation="border" size="sm" /> : ''}
                      </InputAdornment>
                    ),
                  }}
                />
              }
              rules={{ required: t('defaultValidation', { inputName: t('webpage') }) }}
              name="webpage"
              control={control}
            />
            <div className="error-message">
              <ErrorMessage errors={errors} name="webpage" as="p" />
            </div>

            <div className="mt-3">
              <InputTitle>{t('title')}</InputTitle>

              <Controller
                as={<TextField fullWidth variant="outlined" placeholder={t('typeTitle')} />}
                rules={{ required: t('defaultValidation', { inputName: t('title') }) }}
                name="scrapperTitle"
                control={control}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="scrapperTitle" as="p" />
              </div>
            </div>

            <div className="mt-3">
              <InputTitle>{t('description')}</InputTitle>

              <Controller
                as={<TextField fullWidth variant="outlined" placeholder={t('typeDescription')} />}
                rules={{ required: t('defaultValidation', { inputName: t('description') }) }}
                name="scrapperDescription"
                control={control}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="scrapperDescription" as="p" />
              </div>
            </div>

            <DisplayCriteriaSelect control={control} />

            <StartDateSection
              product={t('webpage')}
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              control={control}
              errors={errors}
            />

            <EndDateSection
              product={t('webpage')}
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              control={control}
              errors={errors}
            />

            <div className="mt-3">
              <S.InputTitle>{t('careToComment')}</S.InputTitle>
              <Controller
                as={<TextField fullWidth variant="outlined" placeholder={t('writeSomething')} />}
                name="comment"
                control={control}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="comment" as="p" />
              </div>
            </div>

            <Cover setImageUrl={setImageUrl} setImageId={setImageId} imageUrl={imageUrl} loading={loadingImage} />
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          FormatVisualizationComponent={
            <ContentVisualization
              loadingImage={loadingImage}
              image={imageUrl}
              title={scrapperTitleWatch}
              about={scrapperDescriptionWatch}
              contentType={t('web')}
            />
          }
        />
      }
    />
  )
}
