import styled from 'styled-components'
import { breakpoints } from '~/helper/breakpoints'

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(25px);

  gap: 1rem;

  h3 {
    color: #18181a;
    font-size: 24px;
    font-weight: bolder;
    line-height: 30px;
  }

  p {
    color: #7e7f86;
  }

  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }

  &.principles {
    padding: 2rem;
  }

  @media ${breakpoints.mobile} {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.principles {
      padding: 1.25rem;
    }
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    height: 64px;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
`

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`
