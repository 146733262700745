import styled from 'styled-components'
import { darken } from 'polished'
import Col from 'react-bootstrap/Col'
import { MdMoreHoriz } from 'react-icons/md'
import { customMedia } from '~/styles/global'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  display: flex;
  align-items: center;
  border: 1px solid rgba(214, 214, 214, 0.4);
  border-radius: 5px;
  height: 75px;
  padding: 10px 0;

  svg {
    cursor: pointer;
  }
`

export const ProductTitle = styled.p`
  margin: 0px;
  color: #969faa;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.9;
  letter-spacing: 0.5px;
`

export const PackageName = styled.p`
  margin: 0px;
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.9;
  letter-spacing: 0.5px;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
`

export const PackagePrice = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-left: 25px;
`

export const SpecialOfferText = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
`

export const PackageDuration = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
`

export const CheckCompositionTitle = styled.p`
  margin: 0px;
  color: #477ce6;
  font-family: Roboto;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${darken(0.07, '#477CE6')};
  }
`

export const CheckCompositionTitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ProductPriceWithoutDiscount = styled.p`
  margin: 0px;
  color: var(--error);
  font-family: Roboto;
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 500;
`

export const ProductPriceWithDiscount = styled.p`
  margin: 0px;
  color: var(--success);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
`

export const SwitchTitle = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  margin-left: 8px;
`

export const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  align-items: start;
  justify-content: center;
  width: 157px;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
`

export const PopoverIconText = styled.div`
  margin: 0px;
  color: #343f4b;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-left: 15px;
  margin-top: 3px;
`

export const EditButtonContainer = styled.div`
  display: flex;
  padding: 10px 0;
  width: 100%;
  flex-grow: 1;

  &:hover {
    background-color: ${darken(0.03, '#FFF')};
  }
`

export const DeleteButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;

  &:hover {
    background-color: ${darken(0.03, '#FFF')};
  }
`

export const BorderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Border = styled.p`
  border: solid rgba(214, 214, 214, 0.3);
  border-width: 1px;
  margin-bottom: 0;
  width: 150px;
  opacity: 0.6;
`

export const SpecialOfferColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StatusColumn = styled(Col)`
  display: flex;
  justify-content: center;
`

export const MoreColumn = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProductPriceColumn = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CompositionColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: #000;
  }
`

export const PackagesColumn = styled(Col)``

export const CheckboxColumn = styled(Col)`
  display: flex;
`

export const CheckFormatsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0px 3px;
  width: 12rem;
  height: 38px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  white-space: normal;
  border: 1px solid #9671f5;
  border-radius: 100px;

  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;

  &:hover {
    background-color: ${props => (props.color ? darken(0.03, props.color) : darken(0.015, '#FFF'))};
    transform: scale(1.06);
  }

  &:active {
    -webkit-box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    top: 2px;
  }
`

export const CheckFormatsText = styled.p`
  text-align: center;
  margin: 0px;
  font-family: Roboto;
  color: #343f4b;
  font-size: 14px;

  ${customMedia.lessThan('veryLarge')`
    font-size: 15px;
  `}
`

export const EditOptions = styled(MdMoreHoriz).attrs({
  color: 'var(--text-primary)',
  className: 'package-options',
  size: 24,
})``
