export const mainHeroData = {
  title: 'Digital is the future of sponsorship for any community… no matter the size!',
  subtitle: 'We enable communities to engage members and to monetize their value in full control of data and privacy.',

  community: {
    title: 'Are you a community?',
    description:
      'No matter the size of your community, as far as you gather members around specific interests (sport, business, work specializations, culture, etc.) your community counts. It is time to support your community activities and development plans with new sources of revenues thanks to your digital approach.',
    buttonLabel: 'Start Now!',
  },

  sponsor: {
    title: 'Are you a sponsor?',
    description:
      'A direct channel of communication with your targeted audience is finally available. Optimize your budget by investing in very well defined groups of users through innovative digital advertising formats able to guarantee visibility and conversion.',
    buttonLabel: 'Start Now!',
  },

  dataSolutions: {
    title: 'First party data solutions!',
    listItems: [
      'Our solutions are based on the principle of <strong>“data ownership”</strong> by the community.',
      'Our products provide the communities with a <strong>set of digital tools</strong> implementing the data control.',
      'We equip communities with <strong>privacy by design and privacy by default</strong> solutions.',
    ],
  },
}
