import styled from 'styled-components'
import { lighten } from 'polished'

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px 16px 16px 16px;
`

export const ProfileOption = styled.div.attrs({
  role: 'presentation',
})`
  color: #47525e;
  font-family: Lato;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  &:hover {
    background-color: ${lighten(0.01, '#e5e5e5')};
  }
`

export const UserAvatarContainer = styled.div`
  width: 48px;
  height: 48px;
  position: relative;
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 2px solid #fff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
  }
`

export const UserAvatar = styled.img.attrs({
  role: 'presentation',
  alt: 'User Profile',
})`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 2px solid #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
`

export const UserName = styled.span`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  padding-left: 15px;
  color: #47525e;
  margin-top: 20px;
  max-width: 11.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-height: 20px;
`

export const SignOutSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-left: 8px;

  span {
    color: #47525e;
    font-family: Lato;
    font-size: 15px;
    font-weight: 500;
  }
`

export const SignInLabel = styled.span`
  font-family: Lato;
  text-align: center;
  font-size: 12px;
  color: #47525e;
  padding-left: 15px;
`

export const SignInText = styled.span`
  font-family: Lato;
  text-align: center;
  font-size: 12px;
  color: #47525e;
  padding-left: 4px;
  font-weight: bold;
`

export const SignInAsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ViewProfile = styled.div`
  cursor: pointer;
  text-align: center;
  font-family: Lato;
  font-weight: 600;
  font-size: 15px;
  color: #0874af;
  transition: background-color 0.3s;
  border: 1px solid #0874af;
  border-radius: 20px;
  margin-top: 5px;
  opacity: 0.85;
  &:hover {
    background-color: ${lighten(0.01, '#e5f5fc')};
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UserInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const BorderContainer = styled.div`
  margin: 8px 0px;
`
