/* eslint-disable no-restricted-properties */
/* eslint-disable no-nested-ternary */
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { getFirestore } from '~/services/firebase'
import { uploadFile } from '~/services/firebase-file-wrapper/firebase-file-wrapper.service'
import { getDoc } from '~/services/firebase-db-wrapper/firebase-db-wrapper.service'
import { messageAudio, messageFile, messagePicture } from '~/helper/firebase-database-paths'
import { getFileType, formatBytes } from '~/helper/helperFunctions'
import { messageCollection } from '~/helper/chatFunctions'
import { store } from '~/store'

const useSendMessage = () => {
  const [loading, setLoading] = useState(false)
  const { activeChannel } = useSelector(state => state.chat)
  const { type: userType } = store.getState().user
  const { loggedUserId } = store.getState().auth
  const document = getFirestore()
    .collection(messageCollection(activeChannel?.communityId))
    .doc()

  const documentUuid = document.id
  const createdAt = new Date()

  let userInformation

  if (userType === 'community') {
    const community = store.getState().community.currentCommunity
    userInformation = {
      name: community?.name,
      pictureUrl: community?.networkLogoPath,
    }
  }

  if (userType === 'sponsor') {
    const { sponsor } = store.getState()
    userInformation = {
      name: sponsor?.company?.name,
      pictureUrl: sponsor?.company?.logoAdvPlatServerPath,
    }
  }

  const parseMessage = message => {
    const data = {
      ...message,
      channelId: message.channelId || activeChannel?.id,
      userId: loggedUserId,
      userData: {
        name: userInformation?.name || '',
        pictureUrl: userInformation?.pictureUrl || '',
      },
      messageId: documentUuid,
      createdAt,
    }
    if (data.audio?.blob) {
      delete data.audio.blob
    }
    return data
  }

  const uploadToStorage = async ({ mediaArray, isAudio, isFile, onProgress, customMetadata = {} }) => {
    const promises = mediaArray.map(media =>
      uploadFile(
        typeof media === 'string' ? media : media?.uri,
        isAudio
          ? messageAudio(activeChannel?.id, true)
          : isFile
          ? messageFile(activeChannel?.id, true)
          : messagePicture(activeChannel?.id, true),
        onProgress,
        customMetadata,
      ),
    )
    return Promise.all(promises)
  }

  const getTypeOfMessage = message => {
    if (message?.type === 'Image') {
      return message.images
    }
    if (message?.type === 'Video') {
      return message.videos
    }
    if (message?.type === 'File') {
      return message.files
    }
    return null
  }

  const send = async ({ message, callback }) => {
    const { id: messageId, ...messageProps } = message

    if (messageProps.file) delete messageProps.file

    const payload = parseMessage(messageProps)
    const doc = await getDoc(payload?.messageId)

    try {
      setLoading(true)

      const isAudio = ['Voice', 'Audio'].includes(message?.type)
      const isMedia = ['Image', 'Video', 'File'].includes(message?.type) || !!message?.images?.length

      if (isMedia) {
        const isFile = ['File'].includes(message?.type)
        const mediaProp = `${message?.type}s`.toLowerCase()

        await doc.set({
          ...payload,
          loading: true,
          error: false,
        })

        const urls = await uploadToStorage({
          mediaArray: getTypeOfMessage(message),
          customMetadata: {
            messageId: doc.id,
            channelId: activeChannel?.id,
            documentPath: `messages/${doc.id}`,
          },
          isFile,
        })

        payload[mediaProp] = urls

        if (message?.type === 'File') {
          payload.fileExtensions = [getFileType(message.file?.type)]
          payload.fileSizes = [formatBytes(message.file?.size)]
          payload.fileLocalName = [message.file?.name]

          delete payload.file
        }
      }

      /**
       * Handle audios
       */
      if (isAudio) {
        await doc.set({
          ...payload,
          loading: true,
          error: false,
          audio: {
            info: message?.audio?.info || '',
            url: message?.audio?.url || '',
            uri: message?.audio?.url,
            duration: message?.audio?.duration || '',
          },
        })

        const url = await uploadToStorage({
          mediaArray: [message?.audio?.url],
          isAudio: true,
        })
        payload.audio = { url: url[0] }
      }

      await doc.set({
        ...payload,
      })

      // eslint-disable-next-line no-unused-expressions
      callback?.()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  return [send, { loading }]
}

export default useSendMessage
