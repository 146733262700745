import React, { useState } from 'react'
import { ToggleButton } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

const Interests = ({ onChangeSelectedInterests, selectedInterests, interests, isThereAnyReply }) => {
  const { t } = useTranslation()
  const [checkInterests, setInterests] = useState(selectedInterests)

  const changeState = item => {
    let newList = []
    if (checkInterests.includes(item.id)) {
      const index = checkInterests.indexOf(item.id)
      checkInterests.splice(index, 1)
      newList = [...checkInterests]
      setInterests(newList)
    } else {
      newList = [...checkInterests, item.id]
    }
    setInterests(newList)
    onChangeSelectedInterests(newList)
  }

  const renderItem = item => {
    return (
      <S.ListItem>
        <ToggleButton type="checkbox" checked={checkInterests.includes(item.id)} onChange={() => changeState(item)}>
          {item && item.name}
        </ToggleButton>
      </S.ListItem>
    )
  }

  return (
    <S.ListContainer isThereAnyReply={isThereAnyReply}>
      <S.TagsListContainer>
        {interests && interests?.length ? (
          interests.map(item => renderItem(item))
        ) : (
          <S.EmptyText>{t('nothingToShow')}</S.EmptyText>
        )}
      </S.TagsListContainer>
    </S.ListContainer>
  )
}

export default Interests
