import styled from 'styled-components'

export const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SocialMediaLink = styled.a`
  .youtube-icon {
    width: 36px;
    height: 36px;
  }
`

export const SocialMediaImage = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 10px;

  &:hover {
    opacity: 0.8;
  }
`
