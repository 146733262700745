import React from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

const ScratchCardPreview = ({ media }) => {
  const { t } = useTranslation()

  return (
    <>
      <S.MiddlePreviewContainer>
        {media ? (
          <S.MediaContainer>
            <S.ImagePreview src={media} />

            <S.ScratchToDiscoverContainer>
              <S.ScratchIndicator />
              <S.HelperText>{t('scratchToDiscover')}</S.HelperText>
            </S.ScratchToDiscoverContainer>

            <S.ShadowEffect />
          </S.MediaContainer>
        ) : (
          <S.MediaContainer>
            <S.NoMediaIndicator>
              <S.ImageIcon />
            </S.NoMediaIndicator>
          </S.MediaContainer>
        )}
      </S.MiddlePreviewContainer>
    </>
  )
}

export default ScratchCardPreview
