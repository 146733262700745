import styled, { css } from 'styled-components'
import Col from 'react-bootstrap/Col'
import WhiteButton from '~/components/WhiteButton'
import { customMedia } from '~/styles/global'
import { MdMoreHoriz } from 'react-icons/md'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  display: flex;
  align-items: center;
  border: 1px solid rgba(214, 214, 214, 0.4);
  border-radius: 5px;
  min-height: 85px;
  padding: 10px 16px;

  svg {
    cursor: pointer;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const ActionOptions = styled(MdMoreHoriz).attrs({
  color: 'var(--text-primary)',
  className: 'package-options',
  size: 24,
})``

export const ProductTitle = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
`

export const FormatTitle = styled.p`
  margin: 0px;
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  opacity: 0.9px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

export const ProductDotIndicator = styled.div`
  background-color: ${props => props.color};
  border-radius: 100%;
  min-width: 10px;
  min-height: 10px;

  ${customMedia.lessThan('1330px')`
    display: none;
  `}
`

export const ProductSubtitle = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  margin-left: 8px;
`

export const ProductStatus = styled.p`
  margin: 0px;
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.9px;
  margin-left: 6px;
  text-align: center;
  max-width: 90px;
`

export const ProductTimingColumn = styled(Col).attrs({
  md: 3,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ProductRevisionColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ProductActionsColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px 14px;
`

export const ProductTitleColumn = styled(Col).attrs({
  md: 3,
})`
  display: flex;
  padding-left: 0px !important;
`

export const ProductTypeColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  justify-content: center;
`

export const ActionContainer = styled.div`
  margin-left: 10px;
`

export const ActionWaitingForReview = styled.div``

const ButtonProps = css`
  min-width: 120px;
  box-shadow: 0px 2px 4px 1px rgb(209, 213, 220);
`

export const EditButton = styled(WhiteButton)`
  ${ButtonProps}
`

export const CheckAnalyticsButton = styled(WhiteButton)`
  ${ButtonProps}
`

export const RevisionNeedDotIndicator = styled.div`
  background-color: #e81200;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 15px;
  left: -5px;
`

export const CheckInfoButton = styled(WhiteButton).attrs({
  style: {
    backgroundColor: '#00A6FF',
    color: '#FFF',
    height: '42px',
    padding: '4px 20px',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    fontSize: '15px',
  },
})`
  line-height: 17px;
  ${ButtonProps}
`

export const Date = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const DateLabel = styled.p`
  margin: 0px;
  color: var(--input-title);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
`
