import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'

export const Container = styled.div`
  z-index: 2;
  position: fixed;
  top: 70%;
  left: 50%;
`

export const LoadingSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'md',
})``
