import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '~/helper/breakpoints'
import Figure from '../Figure'

const IsomorphicFigureContainer = styled.div`
  /* The first figure in the HTML is the mobile version. */
  figure:first-child {
    display: none;
    visibility: hidden;

    @media ${breakpoints.mobile} {
      display: block;
      visibility: visible;
    }
  }

  /* The second figure in the HTML is the desktop version. */
  figure:nth-child(2) {
    @media ${breakpoints.mobile} {
      display: none;
      visibility: hidden;
    }
  }
`

/**
 * This component will render one image with specific dimensions
 * on desktop, and another image with different dimensions on mobile.
 *
 * This is done using CSS, so there is no layout shift or hydration error
 * in the first render.
 */
export const IsomorphicFigure = ({ desktopDimensions, mobileDimensions, className, onClick, ...rest }) => {
  return (
    <IsomorphicFigureContainer className={className} onClick={onClick}>
      {/* Mobile figure comes first: */}
      <Figure {...mobileDimensions} {...rest} />

      {/* 
        Then the desktop figure -- This order matters in the CSS above 
        targeting `:first-child` and `:nth-child(2)`. 
      */}
      <Figure {...desktopDimensions} {...rest} />
    </IsomorphicFigureContainer>
  )
}
