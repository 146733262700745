import React from 'react'
import Popover from '@material-ui/core/Popover'

export default function GenericPopover({ popoverAnchor, setPopoverAnchor, content }) {
  return (
    <>
      <Popover
        className="mt-1"
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {content}
      </Popover>
    </>
  )
}
