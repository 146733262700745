import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { MdDone } from 'react-icons/md'
import WhiteButton from '~/components/WhiteButton'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    margin: 0;
    padding: 0;

    @media (min-width: 576px) {
      max-width: 1000px;
      height: 900px;
      margin: auto;
      padding: 0;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;

    .modal-title {
      color: var(--text-bold);
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      line-height: 27px;
    }
  }
`

export const RequestChangeButton = styled(WhiteButton).attrs({
  text: 'Request change',
  type: 'submit',
})``

export const UpdateButton = styled(WhiteButton).attrs({
  text: 'Update',
  style: { marginRight: '10px', padding: '0px 20px' },
})``

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const RequestChangeButtonContainer = styled.div`
  margin-right: 20px;
`

export const DoneButton = styled(WhiteButton).attrs({})``

export const DoneIcon = styled(MdDone).attrs({
  color: 'var(--success)',
  size: 23,
})``
