import React from 'react'
import { Spinner } from 'react-bootstrap'
import ShouldRender from '../ShouldRender'
import * as S from './styled'

export default function WhiteButton(props) {
  const { icon, text, loading, style } = props

  return (
    <S.Button {...props} disabled={loading} style={style}>
      <ShouldRender if={!loading}>
        {icon}
        <span>{text}</span>
      </ShouldRender>
      <ShouldRender if={loading}>
        <Spinner animation="border" size="sm" />
      </ShouldRender>
    </S.Button>
  )
}
