import styled from 'styled-components'
import { MdMoreHoriz, MdBlock } from 'react-icons/md'
import { darken } from 'polished'
import Spinner from 'react-bootstrap/Spinner'

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }
`

export const MessageBoxContainer = styled.div`
  display: flex;
  flex-direction: row !important;
`

export const ContentPreviewContainer = styled.div``

export const TextMessage = styled.div`
  padding: 5px 8px 5px 8px;
  margin: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  float: left;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
`

export const DeletedMessageIcon = styled(MdBlock).attrs({
  size: 18,
})`
  margin-left: 8px;
`

export const MoreButtonContainer = styled.div`
  z-index: 1;
  padding-left: 5px;
  padding-bottom: 6px;
`

export const AudioContainer = styled.div`
  padding-bottom: 4px;
  margin-top: -2px;
`

export const GifContainer = styled.div`
  padding-bottom: 4px;
  display: flex;
  justify-content: center;
`

export const ImagePreviewContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

export const VideoPreviewContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

export const ImagePreview = styled.img`
  cursor: pointer;
  margin: 0px;
  height: 180px;
  object-fit: cover;
  width: 100%;
`

export const FileContainer = styled.div`
  margin-top: -8px;
  padding-bottom: 2px;
`

export const MessageContentContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: ${props => (props.isSentByLoggedUser ? 'flex-end' : 'flex-start')};
  flex-direction: ${props =>
    (props.hasTags && !props.isMessageDeleted) ||
    (props.hasAdditionalMessage && !props.isMessageDeleted && !props.hasTextMessage) ||
    (props.isReply && !props.isMessageDeleted)
      ? 'column'
      : 'row'};
  padding: ${props => (props.isSentByLoggedUser ? '5px 10px' : '0px 8px')};
  padding-bottom: ${props => (props.isSentByLoggedUser ? '8px' : '10px')};
  padding-left: ${props => (props.hover ? '-55px' : '45px')};
  margin-top: -2px;

  ${MessageBoxContainer} {
    background-color: ${props => (props.isSentByLoggedUser ? '#f74657' : '#f3f3f3')};
    margin-top: ${props => (props.isSentByLoggedUser ? '-1px' : '0')};
    border-radius: ${props => (props.isReply ? '0px' : '5px')};
    border-top-left-radius: ${props =>
      props.isMessageDeleted || (props.isTextMessage && !props.isReply) ? '5px' : '0px'};
    border-top-right-radius: ${props =>
      props.isMessageDeleted || (props.isTextMessage && !props.isReply) ? '5px' : '0px'};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  ${TextMessage} {
    color: ${props => (props.isSentByLoggedUser ? '#ffffff' : '#353535')};
  }

  ${DeletedMessageIcon} {
    color: ${props => (props.isSentByLoggedUser ? '#FFF' : '#000')};
  }

  ${AudioContainer} {
    background-color: ${props => (props.isSentByLoggedUser ? '#f74657' : 'initial')};
    padding: ${props => (props.isSentByLoggedUser ? '6px' : 'initial')};
    border-radius: 5px;
  }

  ${FileContainer} {
    background-color: ${props => (props.isSentByLoggedUser ? '#f74657' : 'initial')};
    padding: ${props => (props.isSentByLoggedUser ? '5px' : 'initial')};
    margin-top: 5px;
    border-radius: 5px;
  }

  ${VideoPreviewContainer} {
    background-color: ${props => (props.isSentByLoggedUser ? '#f74657' : 'initial')};
    padding: ${props => (props.isSentByLoggedUser ? '6px' : 'initial')};
    border-top-left-radius: ${props => (props.isSentByLoggedUser && props.isReply ? '0px' : '5px')};
    border-top-right-radius: ${props => (props.isSentByLoggedUser && props.isReply ? '0px' : '5px')};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: -1px;
  }

  ${GifContainer} {
    background-color: ${props => (props.isSentByLoggedUser ? '#f74657' : 'initial')};
    padding: ${props => (props.isSentByLoggedUser ? '6px' : 'initial')};
    margin-top: ${props => (props.isReply ? '-2px' : 'initial')};
    border-radius: 5px;
    border-top-left-radius: ${props => (props.isMessageDeleted || !props.isReply ? '5px' : '0px')};
    border-top-right-radius: ${props => (props.isMessageDeleted || !props.isReply ? '5px' : '0px')};
  }

  ${ImagePreviewContainer} {
    margin-top: -1px;
  }
`

export const MessageUserJoinedChannel = styled.p`
  padding: 5px;
  margin: 0px;
  color: #353535;
  width: 100%;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  float: left;
  word-wrap: break-word;
`

export const PopupContainer = styled.div`
  display: flex;
`

export const Popup = styled.div`
  padding: 5px 10px;
  background-color: #1f1f1f;

  &:hover {
    background-color: ${darken(0.07, '#1F1F1F')};
  }
`

export const PopupMessage = styled.span`
  color: #fff;
  cursor: pointer;
`

export const VideoPreview = styled.img`
  cursor: pointer;
  margin: 0px;
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 5px;
`

export const PlayIconContainer = styled.div`
  cursor: pointer;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.55);
  align-self: center;
  position: absolute;
`

export const PlayIcon = styled.img.attrs({
  src: '/play-icon.png',
})`
  width: 18px;
  height: 18px;
`

export const DeletedMessageContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ChatOptionsIcon = styled(MdMoreHoriz).attrs({
  color: '#A9ADB3',
  size: 26,
})``

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``
