import styled from 'styled-components'

export const OptionsContainer = styled.div`
  display: flex;
  height: 65px;
  width: 100%;
  gap: 12px;
  margin-top: 5px;
`

export const Button = styled.button`
  padding: 0.75rem;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #6d6e78;
  transition: background 0.15s ease, opacity 0.15s ease, border 0.15s ease, box-shadow 0.15s ease;
  border: 1px solid #ccc;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0);
  appearance: none;
  display: flex;
  flex-direction: column;
  width: 200px;

  img,
  svg {
    display: block;
    flex-shrink: 0;
  }

  &:hover {
    opacity: 0.7;
  }
`

export const PaymentName = styled.span`
  padding-top: 0.25rem;
`
