/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { getThumbnails } from 'video-metadata-thumbnails'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import { InputTitle } from '~/components/Shared/StyledComponents'
import ErrorMessage from '~/components/Shared/ErrorMessage'
import { uploadMedia } from '~/services/requests'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import CommunityStoryPreview from '~/components/Sponsor/Modals/CommunityStories/CommunityStoryPreview'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { formatAndSendPutCommunityStory } from '~helper/helperFunctions'
import { transformDateToUtcFormat } from '~/helper/getFormattedDates'
import DisplayCriteriaSelect from '~/components/DisplayCriteriaSelect'

import * as S from './styled'

const ModalEditCommunityStory = props => {
  const { t } = useTranslation()
  const { updateCampaignFormatAction } = useSponsorFunctions()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign?.currentCampaignForReviewPage)
  const { handleSubmit, watch, control, errors } = useForm()

  const {
    showModal,
    handleCloseModal,
    formatToUpdate,
    createMessage,
    refuseCampaignProduct,
    approveCampaignProduct,
    review,
    sponsor,
    formatId,
  } = props || {}

  const videoInput = useRef(null)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [media, setMedia] = useState(formatToUpdate?.videoUrl)
  const [videoThumbnail, setVideoThumbnail] = useState(formatToUpdate?.thumbnailUrl)
  const [date, setDate] = useState(transformDateToUtcFormat(formatToUpdate.date))
  const [endDate, setEndDate] = useState(transformDateToUtcFormat(formatToUpdate.endDate))
  const [loading, setLoading] = useState(false)

  const inputWatch = {
    title: watch('title', formatToUpdate?.title),
    description: watch('description', formatToUpdate?.description),
  }

  const resetForm = () => {
    setMedia(null)
    setVideoThumbnail(null)
    setDate('')
    setEndDate('')
  }

  const closeModal = () => {
    resetForm()
    handleCloseModal()
  }

  const refuseTheCampaignAndSendMessageToChat = () => {
    createMessage()
    refuseCampaignProduct()
    handleCloseModal()
  }

  const onInputVideoClick = event => {
    event.target.value = ''
  }

  const onSubmit = payload => {
    if (!media?.id && !media) {
      return toast.error(t('defaultValidation', { inputName: t('video') }))
    }
    return formatAndSendPutCommunityStory(
      payload,
      media,
      videoThumbnail,
      updateCampaignFormatAction,
      closeModal,
      formatId,
      formatToUpdate,
      setLoading,
    )
  }

  const handleClickUploadInput = uploadRef => {
    return uploadRef?.current?.click()
  }

  const addVideo = async event => {
    const mediaSize = event.target?.files?.[0]?.size
    const mediaType = event.target?.files?.[0].type

    if (mediaType !== 'video/mp4') {
      return toast.error(t('onlyMp4Files'))
    }

    if (mediaSize > 105000000) {
      return toast.error(t('videoIsTooBig', { limit: '100mb' }))
    }
    const video = URL.createObjectURL(event.target?.files?.[0])
    const thumbnail = await getThumbnails(video, {
      quality: 0.6,
      start: 0,
      end: 0,
    })

    if (!thumbnail) return toast.error(t('failedToGetThumbnail'))

    if (event.target?.files?.[0]) {
      const reader = new FileReader()

      reader.onload = () => {
        const media = new Audio(reader.result)
        media.onloadedmetadata = async () => {
          const videoToUpload = event.target?.files?.[0]

          await uploadMedia(videoToUpload, setLoadingUpload, setMedia)

          return await uploadMedia(thumbnail[0].blob, setLoadingUpload, setVideoThumbnail)
        }
      }
      await reader.readAsDataURL(event.target?.files?.[0])
    }
    return null
  }

  useEffect(() => {
    setMedia(formatToUpdate?.videoUrl)
    setVideoThumbnail(formatToUpdate?.thumbnailUrl)
    setDate(new Date(formatToUpdate?.date))
    setEndDate(new Date(formatToUpdate?.endDate))
  }, [showModal])

  return (
    <ProductsModal
      action={onSubmit}
      createMessage={refuseTheCampaignAndSendMessageToChat}
      approveCampaignProduct={approveCampaignProduct}
      modalTitle={
        review ? t('reviseFormat', { format: t('communityStory') }) : t('editFormat', { format: t('communityStory') })
      }
      buttonText={review ? t('done') : t('update')}
      handleCloseModal={closeModal}
      showModal={showModal}
      review={review}
      sponsor={sponsor}
      loadingUpload={loadingUpload}
      loading={loading}
      leftColumn={
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <InputTitle>{t('title')}</InputTitle>
            <Controller
              as={
                <S.CustomTextField
                  placeholder={t('typeTitle')}
                  disabled={review || false}
                  inputProps={{
                    maxLength: 80,
                  }}
                />
              }
              defaultValue={formatToUpdate?.title}
              rules={{ required: t('defaultValidation', { inputName: t('title') }) }}
              name="title"
              control={control}
            />
            <ErrorMessage errors={errors} name="title" />

            <S.InputContainer>
              <InputTitle>{t('description')}</InputTitle>
              <Controller
                as={
                  <S.CustomMultilineTextField
                    placeholder={t('moreAboutContent', { format: t('communityStory').toLocaleLowerCase() })}
                    disabled={review || false}
                    inputProps={{
                      maxLength: 180,
                    }}
                  />
                }
                defaultValue={formatToUpdate?.description}
                name="description"
                control={control}
              />
            </S.InputContainer>

            <S.InputContainer>
              <InputTitle>{t('link')}</InputTitle>
              <S.HelperText>{t('communityStoryLinkSeeMore')}</S.HelperText>

              <Controller
                as={
                  <S.CustomTextField
                    placeholder={t('typeLink')}
                    inputProps={{
                      maxLength: 500,
                    }}
                    disabled={review || false}
                  />
                }
                defaultValue={formatToUpdate?.link}
                rules={{ required: t('defaultValidation', { inputName: t('link') }) }}
                name="link"
                control={control}
              />
              <ErrorMessage errors={errors} name="link" />
            </S.InputContainer>

            <DisplayCriteriaSelect control={control} value={formatToUpdate?.privacy} disabled={review || false} />

            <StartDateSection
              product={t('communityStory')}
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              control={control}
              errors={errors}
              disabled={review || false}
            />
            <EndDateSection
              product={t('communityStory')}
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              control={control}
              errors={errors}
              disabled={review || false}
            />

            {!review && (
              <S.InputContainer>
                <InputTitle>{t('communityStoryVideo')}</InputTitle>
              </S.InputContainer>
            )}
          </S.FormContainer>

          {loadingUpload && (
            <S.SpinnerContainer>
              <S.CustomSpinner />
            </S.SpinnerContainer>
          )}

          {!loadingUpload && !media && !review && (
            <S.UploadSection>
              <S.UploadContainer spacing onClick={() => handleClickUploadInput(videoInput)}>
                <S.VideoUploadIcon />
                <S.UploadText>{t('videoUpload')}</S.UploadText>
              </S.UploadContainer>
            </S.UploadSection>
          )}

          {/* Preview media */}
          {!loadingUpload && media && !review && (
            <S.PreviewMediaContainer>
              <S.PlayIconContainer>
                <S.PlayIcon />
              </S.PlayIconContainer>

              {sponsor && media?.url && <S.PreviewMedia src={videoThumbnail?.url} />}
              {sponsor && !media?.url && <S.PreviewMedia src={formatToUpdate?.thumbnailUrl} />}

              <S.CloseIcon
                onClick={() => {
                  setMedia(null)
                  setVideoThumbnail(null)
                }}
              />
            </S.PreviewMediaContainer>
          )}
          <S.InputAddVideo ref={videoInput} onChange={addVideo} onClick={onInputVideoClick} />
        </S.Form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          defaultVisualization
          FormatVisualizationComponent={
            <CommunityStoryPreview
              media={media?.url || formatToUpdate?.videoUrl}
              videoThumbnail={videoThumbnail?.url || videoThumbnail}
              inputWatch={inputWatch}
            />
          }
        />
      }
    />
  )
}

export default ModalEditCommunityStory
