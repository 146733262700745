import * as Yup from 'yup'
import i18n from '~/i18n'

const userProfileValidator = Yup.object()
  .shape({
    email: Yup.string()
      .email()
      .required(i18n.t('defaultValidation', { inputName: i18n.t('email') })),
    phoneNumber: Yup.string()
      .required(i18n.t('defaultValidation', { inputName: i18n.t('phoneNumber') }))
      .nullable(),
    firstName: Yup.string().required(i18n.t('defaultValidation', { inputName: i18n.t('firstName') })),
    lastName: Yup.string().required(i18n.t('defaultValidation', { inputName: i18n.t('lastName') })),
    password: Yup.string()
      .required(i18n.t('defaultValidation', { inputName: i18n.t('password') }))
      .min(6),
    passwordConfirmation: Yup.string().test('passwords-match', i18n.t('passwordsMustMatch'), function(value) {
      return this.parent.password === value
    }),
  })
  .required()

export default userProfileValidator
