/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import { modalSponsoredDefault } from '~/helper/const'
import { currencyFormat, filterFormatsPerSocialMediaConnected } from '~/helper/helperFunctions'
import Border from '~/components/Shared/Border'
import AddProductCard from '~/components/Shared/AddProductCard'
import * as FN from '~/helper/formFunctions'
import * as S from './styled'

export default function ModalAddProductsPayAsYouGo({
  showModal,
  handleCloseModal: onCloseModal,
  communityProducts,
  productsSelectedPayAsYouGo,
  setProductsSelectedPayAsYouGo,
  communityCurrencySymbol,
  community,
}) {
  const { t } = useTranslation()

  const [selectedProducts, setSelectedProducts] = useState(productsSelectedPayAsYouGo)

  const campaignTotalPrice = FN.getCampaignTotalPrice(selectedProducts)

  const products = communityProducts

  const [socialMediaFormatsAvailable, setSocialMediaFormatsAvailable] = useState([])

  const communitySocialFormats = useMemo(
    () =>
      communityProducts
        .filter(product => product?.category.slug === 'social')
        .filter(product => product?.activeStatus === 'actived'),
    [communityProducts],
  )

  const socialMediaConnected = {
    facebookIsConnected: community?.facebookConnected,
    twitterIsConnected: community?.twitterConnected,
    instagramIsConnected: community?.instagramConnected,
    linkedinIsConnected: community?.linkedinConnected,
  }

  useEffect(() => {
    filterFormatsPerSocialMediaConnected(communitySocialFormats, socialMediaConnected, setSocialMediaFormatsAvailable)
  }, [showModal, communitySocialFormats, socialMediaConnected])

  const addProductsToProductsArray = useCallback(productsArray => {
    setSelectedProducts(productsArray.filter(product => product.qtdPerMonth > 0))
  }, [])

  const handleCloseModal = useCallback(() => {
    setProductsSelectedPayAsYouGo(selectedProducts)
    onCloseModal()
  }, [selectedProducts])

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.ModalTitle>{t('pleaseSelectTheFormatsCampaign')}</S.ModalTitle>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={modalSponsoredDefault}>
        <S.Container>
          <Row>
            <S.ProductsColumn md={7}>
              <S.WebAndMobileProductsSection>
                <S.WebAppFormatsIcon />
                <S.WebMobileText>{t('mobileWebApp')}</S.WebMobileText>
              </S.WebAndMobileProductsSection>
              <S.BorderContainer style={{ marginBottom: '24px' }}>
                <Border />
              </S.BorderContainer>
              <S.PackageCompositionCardContainer>
                {products.length > 0 &&
                  products
                    .filter(
                      product =>
                        product?.category.name === 'Web/Mobile' &&
                        product?.activeStatus === 'actived' &&
                        product?.slug !== 'mobileBoost',
                    )
                    .map(product => (
                      <AddProductCard
                        key={product.id}
                        product={product}
                        advProducts={selectedProducts}
                        addProductsToProductsArray={addProductsToProductsArray}
                        productsAdded={selectedProducts}
                        isProductFromPayAsYouGoForm
                      />
                    ))}
              </S.PackageCompositionCardContainer>
              <S.SocialProductsSection>
                <S.SocialFormatsIcon />
                <S.WebMobileText>{t('social')}</S.WebMobileText>
              </S.SocialProductsSection>
              <S.BorderContainer>
                <Border />
              </S.BorderContainer>
              <S.PackageCompositionCardContainer>
                {products.length > 0 &&
                  socialMediaFormatsAvailable?.map(product => (
                    <AddProductCard
                      key={product.id}
                      product={product}
                      advProducts={selectedProducts}
                      addProductsToProductsArray={addProductsToProductsArray}
                      productsAdded={selectedProducts}
                      isProductFromPayAsYouGoForm
                    />
                  ))}
              </S.PackageCompositionCardContainer>
              <S.PhysicalProductsSection>
                <S.PhysicalFormatsIcon />
                <S.WebMobileText>{t('physical')}</S.WebMobileText>
              </S.PhysicalProductsSection>
              <S.BorderContainer style={{ marginTop: '16px' }}>
                <Border />
              </S.BorderContainer>
              <S.PackageCompositionCardContainer>
                {products.length > 0 &&
                  products
                    .filter(product => product?.category.name === 'Physical')
                    .filter(product => product?.activeStatus === 'actived')
                    .map(product => (
                      <AddProductCard
                        key={product.id}
                        product={product}
                        advProducts={selectedProducts}
                        addProductsToProductsArray={addProductsToProductsArray}
                        productsAdded={selectedProducts}
                        isProductFromPayAsYouGoForm
                      />
                    ))}
              </S.PackageCompositionCardContainer>
            </S.ProductsColumn>
            <S.FormColumn md={5}>
              <S.PayAsYouGoSection>
                <S.PayAsYouGoTitle>{t('yourCampaignBudgetControl')}</S.PayAsYouGoTitle>
                <S.CustomBorderContainer>
                  <S.CustomBorder />
                </S.CustomBorderContainer>

                {selectedProducts.length > 0 &&
                  selectedProducts.map(product => (
                    <S.PayAsYouGoInformationContainer key={product.id}>
                      <S.ProductInformationContainer>
                        {!product.productType ? (
                          <S.ProductInformationText>
                            {`${communityCurrencySymbol} ${currencyFormat(product.unitPrice)} x ${
                              product.qtdPerMonth
                            } ${product.productName}`}
                          </S.ProductInformationText>
                        ) : (
                          <S.ProductInformationText>
                            {`${communityCurrencySymbol} ${currencyFormat(product.unitPrice)} x ${
                              product.qtdPerMonth
                            } ${t(product.productType)}`}
                          </S.ProductInformationText>
                        )}
                        <S.ProductInformationCurrency>
                          {`${communityCurrencySymbol} ${currencyFormat(product.unitPrice * product.qtdPerMonth)}`}
                        </S.ProductInformationCurrency>
                      </S.ProductInformationContainer>
                      <S.BorderTitleContainer>
                        <S.CustomBorder />
                      </S.BorderTitleContainer>
                    </S.PayAsYouGoInformationContainer>
                  ))}
                <S.ProductInformationContainer>
                  <S.CampaignTotal>{t('total')}</S.CampaignTotal>
                  <S.CampaignTotal>{`${communityCurrencySymbol}${currencyFormat(campaignTotalPrice)}`}</S.CampaignTotal>
                </S.ProductInformationContainer>
                <S.BorderTitleContainer>
                  <Border />
                </S.BorderTitleContainer>
              </S.PayAsYouGoSection>
            </S.FormColumn>
          </Row>
        </S.Container>
      </Modal.Body>
      <S.ModalFooter>
        <S.CancelButton text={t('cancel')} large onClick={handleCloseModal} />
        <S.SaveButton text={t('save')} large onClick={handleCloseModal} icon={<S.SaveIcon />} />
      </S.ModalFooter>
    </S.ModalCustom>
  )
}
