/* eslint-disable consistent-return */
import { takeLatest, all, call, put, delay } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { createMessageBetweenCompanyAndCommunity, communitySendMessageToSponsor } from '~/helper/chatFunctions'
import api from '~/services/api'
import { compareCampaignStartDateAndEndDate, formatDateToSendToTheBackend } from '~/helper/getFormattedDates'
import history from '~/services/history'
import { store } from '~/store'
import i18n from '~/i18n'
import { format as formatDate } from 'date-fns'

import {
  stripeCreatePaymentIntentSuccess,
  resetCampaignProcess,
  setCurrentCampaignForReviewPage,
  openInformationModalAfterFormatCreation,
  setShowCampaignReviewPage,
  setCallbackUpdateCampaign,
  setLoadingStripePaymentIntent,
} from './actions'
import {
  REFUSE_CAMPAIGN,
  APPROVE_CAMPAIGN,
  SPONSOR_DELETE_CAMPAIGN_REQUEST,
  STOP_CAMPAIGN,
  SET_CAMPAIGN_RATING,
  STRIPE_USER_AUTHORIZATION_CODE,
  STRIPE_CREATE_PAYMENT_INTENT_REQUEST,
  STRIPE_UPDATE_PAYMENT_INTENT_REQUEST,
  CREATE_CAMPAIGN_WITH_PACKAGE,
  CREATE_CAMPAIGN_PAY_AS_YOU_GO,
  CREATE_PAYMENT_INTENT_PAY_AS_YOU_GO,
  CREATE_PAYMENT_INTENT_PACKAGE,
  CREATE_CAMPAIGN_FORMAT,
  UPDATE_CAMPAIGN_FORMAT,
  SET_CAMPAIGN_PRODUCTS_STATUS,
} from './actionTypes'

export function* refuseCampaign({ payload }) {
  const { networkId, campaignId } = payload
  try {
    yield call(api.patch, `/community/${networkId}/campaign/${campaignId}/denied`)
    toast.success('Campaign successfully refused.')
    setTimeout(() => {
      history.go(0)
    }, 2000)
  } catch (err) {
    toast.error('Failed to refuse the campaign.')
  }
}

export function* stopCampaign({ payload }) {
  const { networkId, campaignId } = payload
  try {
    yield call(api.patch, `/community/${networkId}/campaign/${campaignId}/stop`)
    toast.success('Campaign successfully stopped.')
    setTimeout(() => {
      history.go(0)
    }, 2000)
  } catch (err) {
    toast.error('Failed to stop the campaign.')
  }
}

export function* approveCampaign({ payload }) {
  const { networkId, campaignId } = payload
  try {
    yield call(api.patch, `/community/${networkId}/campaign/${campaignId}/approved`)
    toast.success('Campaign successfully approved.')
    setTimeout(() => {
      history.go(0)
    }, 2000)
  } catch (err) {
    toast.error('Failed to approve the campaign.')
  }
}

export function* sponsorDeleteCampaignRequest({ payload }) {
  try {
    yield call(api.delete, `/campaign/${payload}`)
    toast.success('Campaign successfully deleted.')
    yield put(resetCampaignProcess())
    setTimeout(() => {
      history.go(0)
    }, 2000)
  } catch (err) {
    toast.error('Failed to delete the campaign.')
  }
}

export function* setCampaignRating({ payload }) {
  const { entity, campaignId, rating, note } = payload
  try {
    if (entity === 'sponsor') {
      yield call(api.post, `/campaign/sponsor/rate`, {
        campaignId,
        note,
        rating,
      })
      toast.success(i18n.t('campaignSuccessfullyRated'))
    }
    if (entity === 'community') {
      yield call(api.post, `/campaign/community/rate`, {
        campaignId,
        note,
        rating,
      })
      toast.success(i18n.t('campaignSuccessfullyRated'))
    }
  } catch (err) {
    toast.error(i18n.t('failedToRateCampaign'))
  }
}

export function* stripeUserAuthorizationCode({ payload }) {
  const { code, networkId } = payload
  try {
    yield call(api.patch, `/stripe`, {
      code,
      networkId,
    })
    toast.success(i18n.t('stripeAccountConnected'))
  } catch (err) {
    toast.error(i18n.t('failedToConnectStripeAccount'))
  }
}

export function* stripeCreatePaymentIntentRequest({ payload }) {
  const { campaignId, campaignProducts, communityId, campaignType } = payload
  try {
    const response = yield call(api.post, `/stripe-payment-intent?type=${campaignType}`, {
      communityId,
      uuid: campaignId,
      products: campaignProducts,
    })
    const clientStripeSecret = response.data
    yield put(stripeCreatePaymentIntentSuccess(clientStripeSecret))
  } catch (err) {
    toast.error('Error creating the resource, please try again later.')
  }
}

export function* stripeUpdatePaymentIntentRequest({ payload }) {
  const { campaignId, campaignProducts } = payload
  try {
    const response = yield call(api.put, `/stripe-payment-intent`, {
      uuid: campaignId,
      campaignProducts,
    })
    const clientStripeSecret = response.data
    yield put(stripeCreatePaymentIntentSuccess(clientStripeSecret))
  } catch (err) {
    toast.error('Error creating the resource, please try again later.')
  }
}

export function* createCampaignWithPackage({ payload }) {
  const { communityId, packageInfo } = payload

  if (packageInfo.dtStart) {
    packageInfo.dtStart = formatDateToSendToTheBackend(packageInfo.dtStart)
  }

  try {
    const campaignCreated = yield call(api.post, `/community/${communityId}/campaign?type=package`, packageInfo)
    toast.success(i18n.t('campaignSuccessfullyCreated'))
    yield delay(2000)

    const { data: getCampaigns } = yield call(api.get, '/sponsor/campaigns-running-or-finalized?limit=999')
    const campaignForReview = getCampaigns.filter(campaign => campaign.id === campaignCreated.data.id)

    if (campaignForReview) {
      yield put(setCurrentCampaignForReviewPage(campaignForReview[0]))
    }
    yield put(setShowCampaignReviewPage(true))
  } catch (err) {
    toast.error(i18n.t('errorCreateCampaign'))
  }
}

export function* createCampaignPayAsYouGo({ payload }) {
  const { communityId, campaignInfo } = payload
  const { campaignDuration, uuid, products, name } = campaignInfo

  if (campaignInfo.dtStart) {
    campaignInfo.dtStart = formatDateToSendToTheBackend(campaignInfo.dtStart)
  }
  try {
    const campaignCreated = yield call(api.post, `/community/${communityId}/campaign?type=pay`, {
      name,
      communityId,
      dtStart: campaignInfo.dtStart,
      campaignDuration,
      uuid,
      products,
    })
    toast.success(i18n.t('campaignSuccessfullyCreated'))
    yield delay(2000)

    const { data: getCampaigns } = yield call(api.get, '/sponsor/campaigns-running-or-finalized?limit=999')
    const campaignForReview = getCampaigns.filter(campaign => campaign.id === campaignCreated.data.id)
    if (campaignForReview) {
      yield put(setCurrentCampaignForReviewPage(campaignForReview[0]))
    }
    yield put(setShowCampaignReviewPage(true))
  } catch (err) {
    toast.error(i18n.t('errorCreateCampaign'))
  }
}

export function* createPaymentIntentPayAsYouGo({ payload }) {
  const { communityId, uuid, campaignProducts, campaignDuration, paymentOption } = payload
  try {
    const response = yield call(api.post, `/stripe-payment-intent?type=pay`, {
      communityId,
      uuid,
      products: campaignProducts,
      campaignDuration,
      paymentMethod: paymentOption,
    })
    const clientStripeSecret = response.data

    if (!clientStripeSecret) {
      toast.error(i18n.t('paymentIntentError'), { autoClose: 12000 })
      yield put(setLoadingStripePaymentIntent(false))
    } else {
      yield put(stripeCreatePaymentIntentSuccess(clientStripeSecret))
    }
  } catch (err) {
    yield put(setLoadingStripePaymentIntent(false))
    toast.error(err?.response?.data?.error || i18n.t('paymentIntentError'))
  }
}

export function* createPaymentIntentPackage({ payload }) {
  const { communityId, packageId, uuid, campaignDuration, paymentOption } = payload
  try {
    const response = yield call(api.post, `/stripe-payment-intent?type=package`, {
      communityId,
      packageId,
      uuid,
      campaignDuration,
      paymentMethod: paymentOption,
    })
    const clientStripeSecret = response.data

    if (!clientStripeSecret) {
      toast.error(i18n.t('paymentIntentError'), { autoClose: 12000 })
      yield put(setLoadingStripePaymentIntent(false))
    } else {
      yield put(stripeCreatePaymentIntentSuccess(clientStripeSecret))
    }
  } catch (err) {
    yield put(setLoadingStripePaymentIntent(false))
    toast.error(err?.response?.data?.error || i18n.t('paymentIntentError'))
  }
}

export function* createCampaignFormat({ payload }) {
  const { campaignId, campaignProductId, contentType, closeModal, setLoading } = payload

  let { format } = payload

  let dtStart = format.date
  let dtEnd = format.endDate

  format.privacy = format?.privacy?.value || 'public'

  if (dtStart === undefined) dtStart = format.dtStart
  if (dtEnd === undefined) dtEnd = format.dtEnd

  const validateStartAndEndDate = compareCampaignStartDateAndEndDate(dtStart, dtEnd)

  if (validateStartAndEndDate) {
    return toast.error(i18n.t('startDateHigherThenEndDate'))
  }

  const campaignStartDate = formatDateToSendToTheBackend(dtStart)
  const campaignEndDate = formatDateToSendToTheBackend(dtEnd)

  if (format.isCommunityStory === true && (!format?.thumbnailId || !format.thumbnailUrl))
    return toast.error(i18n.t('failedToGetThumbnail'))

  delete format.isCommunityStory

  if (!format?.numberSurveyQuestions) {
    if (format.date) format.date = formatDateToSendToTheBackend(format.date)
    if (format.endDate) format.endDate = formatDateToSendToTheBackend(format.endDate)
  }

  if (format?.numberSurveyQuestions) {
    const updatedFormat = { ...format }

    if (updatedFormat.date) updatedFormat.date = formatDate(updatedFormat.date, 'yyyy-MM-dd')
    if (updatedFormat.endDate) updatedFormat.endDate = formatDate(updatedFormat.endDate, 'yyyy-MM-dd')

    format = updatedFormat
  }

  try {
    if (setLoading) setLoading(true)

    yield call(api.post, `/sponsor/campaigns/${campaignId}/format`, {
      unity: format,
      campaignProductId,
      contentType,
      dtStart: campaignStartDate,
      dtEnd: campaignEndDate,
    })

    toast.success(i18n.t('successfullyCreated', { entity: i18n.t('product') }))

    yield put(setCallbackUpdateCampaign(Math.random()))

    if (closeModal) closeModal()
    const { data: getCampaigns } = yield call(api.get, '/sponsor/campaigns-running-or-finalized?limit=999')
    const campaignForReview = getCampaigns.filter(campaign => campaign.id === campaignId)

    const { company, name: campaignName } = campaignForReview[0] || {}
    const channelId = store.getState().createCampaign.directChannelBetweenCommunityAndCompanyId
    const sponsor = store.getState().sponsor.company
    const message = `${company?.name} ${i18n.t('justCreatedANewFormat', { campaignName })}`
    const communityId = campaignForReview[0]?.networkId

    createMessageBetweenCompanyAndCommunity(sponsor, message, channelId, communityId)

    if (campaignForReview) {
      yield put(setCurrentCampaignForReviewPage(campaignForReview[0]))
      yield delay(1000)
    }
    yield put(openInformationModalAfterFormatCreation())
  } catch (err) {
    toast.error(i18n.t('failedToCreate', { entity: i18n.t('product') }))
    if (setLoading) setLoading(false)
  }

  if (setLoading) setLoading(false)
}

export function* updateCampaignFormat({ payload }) {
  const { format, formatId, closeModal, setLoading } = payload
  const campaignId = store.getState().createCampaign.currentCampaignForReviewPage.id
  let dtStart = format.date
  let dtEnd = format.endDate
  let formatTitle = format?.title

  format.privacy = format?.privacy?.value || 'public'

  if (dtStart === undefined) dtStart = format.dtStart
  if (dtEnd === undefined) dtEnd = format.dtEnd

  if (!formatTitle) {
    formatTitle = format?.name
  }

  const validateStartAndEndDate = compareCampaignStartDateAndEndDate(dtStart, dtEnd)
  if (validateStartAndEndDate) {
    return toast.error(i18n.t('startDateHigherThenEndDate'))
  }
  const startDate = formatDateToSendToTheBackend(dtStart)
  const endDate = formatDateToSendToTheBackend(dtEnd)

  if (format.date) format.date = formatDateToSendToTheBackend(format.date)
  if (format.endDate) format.endDate = formatDateToSendToTheBackend(format.endDate)

  try {
    if (setLoading) setLoading(true)
    yield call(api.put, `/sponsor/format/${formatId}/sponsor-revise`, {
      unity: format,
      dtStart: startDate,
      dtEnd: endDate,
    })
    toast.success(i18n.t('successfullyUpdated', { entity: i18n.t('product') }))

    yield put(setCallbackUpdateCampaign(Math.random()))

    if (closeModal) closeModal()

    const { data: getCampaigns } = yield call(api.get, '/sponsor/campaigns-running-or-finalized?limit=999')
    const campaignForReview = getCampaigns.filter(campaign => campaign.id === campaignId)

    const { company, name: campaignName } = campaignForReview[0] || {}
    const channelId = store.getState().createCampaign.directChannelBetweenCommunityAndCompanyId
    const sponsor = store.getState().sponsor.company
    const message = `${company?.name} ${i18n.t('justUpdatedANewFormat', { formatTitle, campaignName })}`
    const communityId = campaignForReview[0]?.networkId

    createMessageBetweenCompanyAndCommunity(sponsor, message, channelId, communityId)

    if (campaignForReview) {
      yield put(setCurrentCampaignForReviewPage(campaignForReview[0]))
      yield delay(1000)
    }
  } catch (err) {
    toast.error(i18n.t('failedToUpdate', { entity: i18n.t('product') }))
    if (setLoading) setLoading(false)
  }

  if (setLoading) setLoading(false)
}

export function* setCampaignProductsStatus({ payload }) {
  const { products, communityId, campaignId, status, setLoading } = payload
  try {
    setLoading(true)
    yield call(api.patch, `/community/${communityId}/campaign/${campaignId}`, {
      products,
      status,
    })
    toast.success(i18n.t('successfullyUpdated', { entity: i18n.t('product') }))
    const { data: getCampaigns } = yield call(
      api.get,
      `/community/${communityId}/campaigns-running-or-finalized?limit=999`,
    )
    const campaignForReview = getCampaigns.filter(campaign => campaign.id === campaignId)
    if (campaignForReview) {
      const { community, name: campaignName, networkId } = campaignForReview[0] || {}
      const channelId = store.getState().createCampaign.directChannelBetweenCommunityAndCompanyId

      let message
      if (status === 'running') {
        message = `${community?.fullName} ${i18n.t('justApprovedANewFormat', { campaignName })}`
      } else {
        message = `${community?.fullName} ${i18n.t('justReprovedANewFormat', { campaignName })}`
      }
      communitySendMessageToSponsor(community, message, channelId, networkId)

      yield put(setCurrentCampaignForReviewPage(campaignForReview[0]))
      yield delay(1000)
    }
  } catch (err) {
    if (err.response.data.error === 'FormatAlreadyApproved') {
      toast.error(i18n.t('failedToUpdate', { entity: i18n.t('product') }))
      setLoading(false)
    }
  }
  setLoading(false)
}

export default all([
  takeLatest(REFUSE_CAMPAIGN, refuseCampaign),
  takeLatest(APPROVE_CAMPAIGN, approveCampaign),
  takeLatest(STOP_CAMPAIGN, stopCampaign),
  takeLatest(SPONSOR_DELETE_CAMPAIGN_REQUEST, sponsorDeleteCampaignRequest),
  takeLatest(SET_CAMPAIGN_RATING, setCampaignRating),
  takeLatest(STRIPE_USER_AUTHORIZATION_CODE, stripeUserAuthorizationCode),
  takeLatest(STRIPE_CREATE_PAYMENT_INTENT_REQUEST, stripeCreatePaymentIntentRequest),
  takeLatest(STRIPE_UPDATE_PAYMENT_INTENT_REQUEST, stripeUpdatePaymentIntentRequest),
  takeLatest(CREATE_CAMPAIGN_WITH_PACKAGE, createCampaignWithPackage),
  takeLatest(CREATE_CAMPAIGN_PAY_AS_YOU_GO, createCampaignPayAsYouGo),
  takeLatest(CREATE_PAYMENT_INTENT_PAY_AS_YOU_GO, createPaymentIntentPayAsYouGo),
  takeLatest(CREATE_PAYMENT_INTENT_PACKAGE, createPaymentIntentPackage),
  takeLatest(CREATE_CAMPAIGN_FORMAT, createCampaignFormat),
  takeLatest(UPDATE_CAMPAIGN_FORMAT, updateCampaignFormat),
  takeLatest(SET_CAMPAIGN_PRODUCTS_STATUS, setCampaignProductsStatus),
])
