import React from 'react'
import { Switch, Router } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useDatadog from '~/hooks/datadog/useDatadog'
import Route from './Route'
import Login from '../pages/Login'
import Sponsor from '../pages/Sponsor'
import SponsorInvitation from '../pages/SponsorInvitation'
import LandingPage from '../pages/LandingPage'
import Community from '../pages/Community'
import history from '../services/history'

const Routes = () => {
  const signed = useSelector(state => state.auth.signed)
  const type = useSelector(state => state.user.type)

  useDatadog()

  // TODO refactor this and implement role-based routes

  const handleInviteSponsor = () => {
    if (signed === true && type === 'sponsor') {
      return Sponsor
    }
    if (signed === true && type === 'community') {
      return Community
    }
    if (signed === false) {
      return SponsorInvitation
    }
    return LandingPage
  }

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/invite-sponsor/:id?" component={handleInviteSponsor()} />
        <Route exact path="/login" component={Login} />
        <Route path="/sponsor/:id?" component={Sponsor} isPrivate />
        <Route path="/community" component={Community} isPrivate />
      </Switch>
    </Router>
  )
}

export default Routes
