import React, { useState, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { FaRegImage } from 'react-icons/fa'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import ReactTagInput from '@pathofdev/react-tag-input'
import Border from '~/components/Shared/Border'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import { reactSelectStyleDefault, couponTypeOptions } from '~/helper/const'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { DatePickerInput, InputTitle, FormatUploadButton } from '~/components/Shared/StyledComponents'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import ContentVisualization from '~/components/CreateFormatMobileVisualization/Content'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import { UploadS3, prepareToSendReward } from '~/helper/formFunctions'
import '@pathofdev/react-tag-input/build/index.css'
import * as S from './styled'

export default function ModalCreateReward({ handleCloseModal, showModal, campaignProductId }) {
  const { t } = useTranslation()
  const { createCampaignFormatAction } = useSponsorFunctions()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const [couponType, setCouponType] = useState('')
  const [imageUrl, setImageUrl] = useState(null)
  const [imageId, setImageId] = useState(null)
  const [updatingImage, setUpdatingImage] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [expireDate, setExpireDate] = useState('')
  const [date, setDate] = useState('')
  const [burnableCode, setBurnableCode] = useState([])
  const [endDate, setEndDate] = useState('')
  const { handleSubmit, setValue, register, control, watch, errors } = useForm()
  const [rewardCost, setRewardCost] = useState(null)
  const titleWatch = watch('title')
  const additionalInfoWatch = watch('additionalInfo')

  useEffect(() => {
    setCouponType(couponTypeOptions[0])
  }, [handleCloseModal])

  function resetFormAndCloseModal() {
    setExpireDate('')
    setDate('')
    setCouponType(couponTypeOptions[0])
    setRewardCost(null)
    setImageUrl(null)
    setImageId(null)
    setUpdatingImage(false)
    handleCloseModal()
  }

  function onSubmit(format) {
    createCampaignFormatAction(currentCampaignForReviewPage.id, campaignProductId, format, null, resetFormAndCloseModal)
  }

  function onChangeSlider(value) {
    setRewardCost(value)
  }

  useEffect(() => {
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    register({ name: 'rewardCost' })
    if (burnableCode.length > 0) {
      register({ name: 'burnableCode' })
      setValue('burnableCode', burnableCode)
    }
    setValue('rewardCost', rewardCost)
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
  }, [register, setValue, imageUrl, imageId, burnableCode, rewardCost])

  return (
    <>
      <ProductsModal
        action={onSubmit}
        modalTitle={t('newFormat', { format: t('reward') })}
        handleCloseModal={resetFormAndCloseModal}
        showModal={showModal}
        review={false}
        loadingUpload={loadingUpload}
        leftColumn={
          <form id="my-form" onSubmit={handleSubmit(prepareToSendReward(onSubmit, couponType, burnableCode))}>
            <S.FormContainer>
              <InputTitle>{t('title')}</InputTitle>
              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={t('typeTitle')}
                    inputProps={{
                      maxLength: 140,
                    }}
                  />
                }
                rules={{ required: t('defaultValidation', { inputName: t('title') }) }}
                name="title"
                control={control}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="title" as="p" />
              </div>

              <StartDateSection
                product={t('reward')}
                selectedCampaignStartDate={date}
                setCampaignStartDate={setDate}
                campaignStartDate={currentCampaignForReviewPage.dtStart}
                campaignEndDate={currentCampaignForReviewPage.dtEnd}
                control={control}
                errors={errors}
              />
              <EndDateSection
                product={t('reward')}
                selectedCampaignStartDate={date}
                selectedCampaignEndDate={endDate}
                campaignEndDate={currentCampaignForReviewPage.dtEnd}
                setCampaignEndDate={setEndDate}
                control={control}
                errors={errors}
              />

              <div className="mt-3">
                <InputTitle>{t('dateExpire')}</InputTitle>
                <Controller
                  as={
                    <DatePickerInput
                      selected={expireDate}
                      placeholderText={t('chooseExpireDate')}
                      minDate={date}
                      maxDate={new Date(currentCampaignForReviewPage.dtEnd)}
                    />
                  }
                  rules={{ required: t('defaultValidation', { inputName: t('dateExpire') }) }}
                  name="expireDate"
                  control={control}
                  onChange={([selected]) => {
                    setExpireDate(selected)
                    return selected
                  }}
                />
                <div className="error-message">
                  <ErrorMessage errors={errors} name="expireDate" as="p" />
                </div>
              </div>

              <div className="mt-3">
                <InputTitle>{t('additionalInfo')}</InputTitle>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={t('tellThemMoreAbout', { format: t('reward').toLocaleLowerCase() })}
                      multiline
                      rows="4"
                    />
                  }
                  rules={{ required: t('defaultValidation', { inputName: t('additionalInfo') }) }}
                  name="additionalInfo"
                  control={control}
                />
                <div className="error-message">
                  <ErrorMessage errors={errors} name="additionalInfo" as="p" />
                </div>
              </div>

              <div className="mt-3">
                <InputTitle>{t('price')}</InputTitle>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  placeholder={t('rewardPrice')}
                  value={rewardCost}
                  onChange={e => setRewardCost(e.target.value)}
                />
                <div className="slider-horizontal">
                  <Slider min={0} max={500000} value={rewardCost} orientation="horizontal" onChange={onChangeSlider} />
                </div>
                <div className="error-message">
                  <ErrorMessage errors={errors} name="rewardCost" as="p" />
                </div>
              </div>

              <div className="mt-4">
                <InputTitle>{t('useCouponType')}</InputTitle>
                <Controller
                  as={
                    <Select
                      styles={reactSelectStyleDefault}
                      placeholder={t('placeholderSelect')}
                      options={couponTypeOptions}
                    />
                  }
                  rules={{ required: t('defaultValidation', { inputName: t('couponType') }) }}
                  name="couponType"
                  control={control}
                  onChange={([selected]) => {
                    setCouponType(selected)
                    return selected
                  }}
                  defaultValue={couponTypeOptions[0]}
                />
                <div className="error-message">
                  <ErrorMessage errors={errors} name="couponType" as="p" />
                </div>
              </div>

              {couponType === undefined
                ? null
                : (() => {
                    switch (couponType.label) {
                      case t('couponReusable'):
                        return (
                          <div className="animated fadeIn">
                            <div className="mt-2">
                              <InputTitle>{t('writeTheCouponCode')}</InputTitle>
                              <Controller
                                as={
                                  <TextField fullWidth variant="outlined" placeholder={t('enterReusableCouponCode')} />
                                }
                                name="couponCode"
                                control={control}
                              />
                            </div>
                            <div className="mt-2">
                              <InputTitle>{t('howManyCoupons')}</InputTitle>
                              <Controller
                                as={
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder={t('howManyCouponsOffering')}
                                    type="number"
                                  />
                                }
                                name="couponQuantity"
                                control={control}
                              />
                            </div>
                            <div className="mt-2">
                              <InputTitle>{t('whereCanBeClaimed')}</InputTitle>
                              <Controller
                                as={
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="e.g. https://www.greatecontent.com/article10"
                                  />
                                }
                                name="link"
                                control={control}
                              />
                            </div>
                            <div className="mt-4">
                              <Border />
                            </div>
                          </div>
                        )

                      case t('couponBurnable'):
                        return (
                          <div className="animated fadeIn">
                            <div className="mt-2">
                              <InputTitle>{t('burnableCouponCodes')}</InputTitle>
                              <S.Optional>{t('typeCouponAndPressEnter')}</S.Optional>
                              <ReactTagInput
                                tags={burnableCode}
                                onChange={newBurnableCode => setBurnableCode(newBurnableCode)}
                                placeholder={t('typeCouponAndPressEnter')}
                                maxTags={10}
                                removeOnBackspace
                                readOnly={false}
                              />
                            </div>
                            <div className="mt-2">
                              <InputTitle>{t('whereCanBeClaimed')}</InputTitle>
                              <Controller
                                as={
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="e.g. https://www.greatecontent.com/article10"
                                  />
                                }
                                name="link"
                                control={control}
                              />
                            </div>
                          </div>
                        )
                      case t('no'):
                        return null
                      default:
                        return null
                    }
                  })()}

              <div className="mt-3 mb-2">
                <Border />
              </div>
              {updatingImage ? (
                <div className="mt-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <FaRegImage className="mr-2" size={14} />
                    <InputTitle>{t('image')}</InputTitle>
                  </div>
                  <UploadS3
                    setUploadId={setImageId}
                    setUploadUrl={setImageUrl}
                    setLoadingUpload={setLoadingUpload}
                    fileType="image"
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <FormatUploadButton text={t('addImage')} action={() => setUpdatingImage(!updatingImage)} />
                </div>
              )}
            </S.FormContainer>
          </form>
        }
        rightColumn={
          <CreateFormatMobileVisualization
            FormatVisualizationComponent={
              <ContentVisualization
                image={imageUrl}
                title={titleWatch}
                about={additionalInfoWatch}
                contentType={t('reward')}
              />
            }
          />
        }
      />
    </>
  )
}
