import React from 'react'
import { BsCreditCardFill } from 'react-icons/bs'

import * as S from './styled'
import { PAYMENT_OPTIONS } from '../../constants'

const activeStyle = {
  borderColor: '#30313d',
  color: '#30313d',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px #30313d',
}

export const PaymentMethod = ({ paymentOptionState }) => {
  const [paymentOption, setPaymentOption] = paymentOptionState

  const isSEPA = paymentOption === PAYMENT_OPTIONS.SEPA
  const isCard = paymentOption === PAYMENT_OPTIONS.CREDIT_CARD

  return (
    <S.OptionsContainer>
      <S.Button onClick={() => setPaymentOption(PAYMENT_OPTIONS.SEPA)} type="button" style={isSEPA ? activeStyle : {}}>
        <img src="icons/sepa-logo.png" width={40} height="auto" alt="SEPA" />

        <S.PaymentName style={{ marginTop: 2 }}>SEPA Direct Debit</S.PaymentName>
      </S.Button>

      <S.Button
        onClick={() => setPaymentOption(PAYMENT_OPTIONS.CREDIT_CARD)}
        type="button"
        style={isCard ? activeStyle : {}}
      >
        <BsCreditCardFill size={16} color="#4C4C4C" />

        <S.PaymentName>Card</S.PaymentName>
      </S.Button>
    </S.OptionsContainer>
  )
}
