import axios from 'axios'
import { store } from '~/store'

const tokenRequestInterceptor = axios.interceptors.request.use(
  config => {
    const { token } = store.getState().auth
    if (token != null) {
      config.headers.Authorization = token
    }
    return config
  },
  err => {
    return Promise.reject(err)
  },
)

export default tokenRequestInterceptor
