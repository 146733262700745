import styled from 'styled-components'

export const TagsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px 4px 8px;
  background-color: #f3f3f3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-top: 8px;
  flex-wrap: wrap;
  margin-top: -2px;
`

export const Tag = styled.p`
  height: 23px;
  padding: 3px 9px;
  border-radius: 21px;
  margin-right: 7px;
  margin-bottom: 7px;
  background-color: #fff;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`
