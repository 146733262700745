import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import useNavigationFunctions from '~/helper/navigationFunctions'
import useTutorialFunctions from '~/helper/tutorialFunctions'
import * as S from './styled'

export default function SponsorsTabTooltip({ closeProps, tooltipProps }) {
  const { t } = useTranslation()
  const { setCommunityCurrentTabAction } = useNavigationFunctions()
  const { setTutorialAsInativeAction, neverShowTheTutorialAgainForTheCommunityAction } = useTutorialFunctions()
  const communityHasStripeAccount = useSelector(state => state.community.communityHasStripeAccount)

  const goToPackagesTab = () => {
    setCommunityCurrentTabAction('packages')
  }

  const goToCampaignsPage = () => {
    setCommunityCurrentTabAction('campaigns')
  }

  const closeTooltipAndFinishTheTutorial = () => {
    neverShowTheTutorialAgainForTheCommunityAction()
    setTutorialAsInativeAction()
    setCommunityCurrentTabAction('products')
  }

  return (
    <S.ComponentContainer {...tooltipProps}>
      <S.TooltipArrow />
      <S.TooltipUserAvatar />
      <S.TooltipContainer>
        <S.CloseButtonContainer {...closeProps}>
          <S.CloseIcon onClick={() => closeTooltipAndFinishTheTutorial()} />
        </S.CloseButtonContainer>
        {communityHasStripeAccount ? (
          <S.Title>{t('communityStep4TutorialTitle', { step: 3 })}</S.Title>
        ) : (
          <S.Title>{t('communityStep4TutorialTitle', { step: 4 })}</S.Title>
        )}
        <S.Subtitle>{t('sponsorsTabTooltipSubtitle')}</S.Subtitle>
        <S.ButtonsContainer>
          {communityHasStripeAccount === true ? (
            <S.BackButton onClick={() => goToPackagesTab()}>
              <S.BackButtonText>{t('back')}</S.BackButtonText>
            </S.BackButton>
          ) : (
            <S.BackButton onClick={() => goToCampaignsPage()}>
              <S.BackButtonText>{t('back')}</S.BackButtonText>
            </S.BackButton>
          )}
          <S.NextButton onClick={() => closeTooltipAndFinishTheTutorial()}>
            <S.NextButtonText>{t('done')}</S.NextButtonText>
          </S.NextButton>
        </S.ButtonsContainer>
      </S.TooltipContainer>
    </S.ComponentContainer>
  )
}
