import React from 'react'
import * as S from './styled'

export default function Border({ width, color }) {
  return (
    <S.Border width={width} color={color}>
      <p />
    </S.Border>
  )
}
