import styled, { css } from 'styled-components'

export const BloobImage = styled.img`
  position: absolute;
  overflow: hidden;
  pointer-events: none;

  ${({ blur }) =>
    blur &&
    css`
      filter: ${`blur(${blur}px)`};
    `};

  ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}

  ${({ center }) =>
    center &&
    css`
      left: 50%;
      transform: translateX(-50%);
    `};

  ${({ scaleX, scaleY }) => {
    let transform = ''

    if (scaleX) transform += `scaleX(${scaleX}) `
    if (scaleY) transform += `scaleY(${scaleY}) `

    return css`
      transform: ${transform};
    `
  }};
`
