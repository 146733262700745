import styled from 'styled-components'

export const FormSectionContainer = styled.div`
  svg {
    font-size: 30px !important;
  }
`

export const PackageContainer = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  padding: 10px 12px 10px 12px;
  position: relative;
`

export const PackageSelectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const PackageNameAndPrice = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputTitle = styled.span`
  color: #4f5559;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
`

export const PackageName = styled.span`
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.85;
  width: 205px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`

export const PackagePrice = styled.span`
  color: var(--success);
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
`

export const PackageDiscountContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PackageMonthlyPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3px;
`

export const PackageWithoutDiscountContainer = styled.div``

export const PackageWithDiscountContainer = styled.div`
  margin-top: -5px;
`

export const PackagePriceWithoutDiscount = styled.span`
  color: var(--error);
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  text-decoration: line-through;
`

export const PackagePriceWithDiscount = styled.span`
  color: var(--success);
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
`

export const PackageMonthlyPrice = styled.span`
  color: var(--success);
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
`

export const PackageMonthly = styled.span`
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 15px;
  margin-left: 5px;
`

export const ErrorMessageContainer = styled.div.attrs({
  className: 'error-message',
})``

export const SelectContainer = styled.div`
  margin-top: 5px;
`

export const PackageInformationContainer = styled.div.attrs({
  className: 'animated fadeIn',
})`
  transition: all 300ms;
`

export const PackageDescription = styled.p`
  margin: 0px;
  margin-top: 6px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 12px;
  width: 270px;
  line-height: 15px;
`

export const PackageComposition = styled.p`
  margin-top: 2px;
  margin-bottom: 0px;
  color: #999999;
  font-family: Roboto;
  font-size: 13px;
`

export const PackageCompositionInformationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: column;
  margin-top: 4px;
`

export const PackageCompositionInformations = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 2px;
`

export const PackageSpecialOffer = styled.p`
  margin-bottom: 0px;
  color: #e3b202;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
`

export const UnitiesPerMonth = styled.span`
  white-space: nowrap;
  flex: 1 0 auto;
  color: #999999;
  font-family: Roboto;
  font-size: 12px;
`

export const ProductName = styled.span`
  white-space: nowrap;
  flex: 1 0 auto;
  color: #999999;
  font-family: Roboto;
  font-size: 12px;
`

export const Dots = styled.span`
  flex: 0 1 auto;
  overflow: hidden;
  padding: 0px 4px;

  &:before {
    color: #999999;
    font-size: 12px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    content: '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . ';
  }
`

export const PackagePriceContainer = styled.div`
  display: flex;
`

export const SpecialOfferImage = styled.img.attrs({
  src: '/special-offer.png',
})`
  position: absolute;
  right: 70px;
  top: 5%;
  width: 90px;
  height: 60px;
  transform: rotate(11deg);
`
