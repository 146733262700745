/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ChatWindow from '~/components/Chat/ChatWindow'
import { DefaultLogoImage } from '~/util/defaultImages'
import useChatFunctions from '~/helper/chatActions'
import {
  getUnreadMessagesLengthForChatChannelById,
  updateUsersReadLastMessageForTheSpecificChatChannel,
} from '~/helper/chatFunctions'
import * as S from './styled'

export default function ChatChannel({
  communityPicture,
  communityName,
  communityId,
  channelId,
  sponsorId,
  sponsorName,
  sponsorPicture,
  userType,
  lastMessage,
  channelIsSponsored,
  sponsoredChannelTitle,
  openedChat,
  setOpenedChat,
  channel,
}) {
  const { t } = useTranslation()
  const [callbackGetUnreadMessages, setCallbackGetUnreadMessages] = useState(false)
  const [numberOfUnreadMessagesInTheChatChannel, setNumberOfUnreadMessagesInTheChatChannel] = useState(0)
  const { setActiveChannelAction } = useChatFunctions()
  const [chatPicture, setChatPicture] = useState(DefaultLogoImage)
  const [channelName, setChannelName] = useState('')

  const isSponsor = userType === 'Sponsor'
  const isCommunity = userType === 'Community'

  // Update channel last message when the user opens the chat window
  function updateLastMessageFromTheChatChannel() {
    if (isCommunity) {
      updateUsersReadLastMessageForTheSpecificChatChannel(communityId, channelId, 'Community', communityId)
      setCallbackGetUnreadMessages(Math.random())
    }
    if (isSponsor) {
      updateUsersReadLastMessageForTheSpecificChatChannel(sponsorId, channelId, 'Sponsor', communityId)
      setCallbackGetUnreadMessages(Math.random())
    }
  }

  function handleCloseChatWindow() {
    updateLastMessageFromTheChatChannel()
    setOpenedChat(false)
    setActiveChannelAction(null)
  }

  function handleOpenCloseChatWindow() {
    updateLastMessageFromTheChatChannel()
    setActiveChannelAction(channel)
    if (openedChat === channelId) {
      return setOpenedChat(false)
    }
    return setOpenedChat(channelId)
  }

  useEffect(() => {
    // Get the numbers of unread messages from the community chat channel
    if (isCommunity) {
      getUnreadMessagesLengthForChatChannelById(
        communityId,
        channelId,
        sponsorId,
        setNumberOfUnreadMessagesInTheChatChannel,
        'Community',
        communityId,
      )
    }
    // Get the numbers of unread messages from the sponsor chat channel
    if (isSponsor) {
      getUnreadMessagesLengthForChatChannelById(
        sponsorId,
        channelId,
        communityId,
        setNumberOfUnreadMessagesInTheChatChannel,
        'Sponsor',
        communityId,
      )
    }
  }, [numberOfUnreadMessagesInTheChatChannel, callbackGetUnreadMessages])

  useEffect(() => {
    if (channelIsSponsored) {
      setChatPicture(sponsorPicture || DefaultLogoImage)
    } else {
      setChatPicture(communityPicture || DefaultLogoImage)
    }
    if (isCommunity) setChatPicture(sponsorPicture || DefaultLogoImage)
  }, [])

  useEffect(() => {
    if (channelIsSponsored) {
      setChannelName(sponsoredChannelTitle)
    } else {
      setChannelName(isSponsor ? communityName : sponsorName)
    }
  }, [sponsoredChannelTitle, communityName, sponsorName])

  return (
    <>
      <S.Container onClick={handleOpenCloseChatWindow}>
        <S.UserChatContent>
          <S.LogoContainer>
            <S.ChatPicture src={chatPicture} />
            {numberOfUnreadMessagesInTheChatChannel > 0 && (
              <S.UnreadMessages>
                {isSponsor ? (
                  <S.UnreadMessageText>{numberOfUnreadMessagesInTheChatChannel}</S.UnreadMessageText>
                ) : (
                  <S.NumberOfUnreadMessages>{numberOfUnreadMessagesInTheChatChannel}</S.NumberOfUnreadMessages>
                )}
              </S.UnreadMessages>
            )}
          </S.LogoContainer>
          <S.ChannelNameAndLastMessageSection>
            <S.ChatChannelName>{channelName}</S.ChatChannelName>
            <S.LastMessage>{lastMessage === '' ? t('clickToChat') : lastMessage}</S.LastMessage>
          </S.ChannelNameAndLastMessageSection>
        </S.UserChatContent>
      </S.Container>

      <ChatWindow
        channelImage={chatPicture}
        chatName={isSponsor ? communityName : sponsorName}
        channelId={channelId}
        open={openedChat === channelId}
        userType={userType}
        handleClose={handleCloseChatWindow}
        handleOpen={() => setOpenedChat(channelId)}
        sponsoredChannelTitle={channelIsSponsored ? sponsoredChannelTitle : null}
        channelIsSponsored={channelIsSponsored}
        openedChat={openedChat}
      />
    </>
  )
}
