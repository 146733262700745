import React from 'react'
import * as S from './styled'

export default function ZeroRegisterIndicator({ text }) {
  return (
    <S.Container>
      <S.Text>{text}</S.Text>
    </S.Container>
  )
}
