import React from 'react'
import Preview from '~/components/Chat/Preview'

const ContentPreview = ({ content }) => {
  const isVideo = content?.content_type === 'video'

  return (
    <Preview
      title={content?.title}
      description={content?.body}
      image={content?.cover_image_url}
      isVideo={isVideo}
      interests={content?.interests}
      content={content}
    />
  )
}

export default ContentPreview
