import React from 'react'
import { useSelector } from 'react-redux'
import * as S from './styled'

export default function SponsorContainerTab({
  isTabActive,
  title,
  image,
  icon,
  spacing,
  onClick,
  tabsColor,
  className,
}) {
  const campaignsWaitingForApprovalLength = useSelector(state => state.createCampaign.campaignsWaitingForApprovalLength)

  return (
    <S.Container
      className={className}
      tabsColor={tabsColor}
      onClick={onClick}
      spacing={spacing ? 1 : 0}
      isTabActive={isTabActive}
    >
      {icon === undefined ? <S.TabIcon src={image} /> : icon}
      <S.Title tabsColor={tabsColor} isTabActive={isTabActive}>
        {title}
      </S.Title>

      {title === 'campaigns' && campaignsWaitingForApprovalLength > 0 && (
        <S.TabDotIndicator>
          <S.TabDotText>{campaignsWaitingForApprovalLength}</S.TabDotText>
        </S.TabDotIndicator>
      )}
    </S.Container>
  )
}
