import styled from 'styled-components'
import { GoQuote } from 'react-icons/go'
import { MdPhotoCamera, MdInsertLink } from 'react-icons/md'
import { BsCameraVideoFill, BsFillMicFill } from 'react-icons/bs'
import { BiMessage } from 'react-icons/bi'
import { AiOutlineFileGif, AiFillFile } from 'react-icons/ai'

export const QuotesIcon = styled.img.attrs({
  src: '/quotes.png',
  alt: 'Quotes',
})``

export const ReplyIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 6px;
  margin-left: 6px;
`

export const QuoteIcon = styled(GoQuote).attrs({
  size: '13',
})`
  color: #000;
  height: 30px;
`

export const ReplyTypeOfMedia = styled.div`
  font-style: italic;
  color: #303030;
  margin-left: 4px;
`

export const ReplyContainer = styled.div.attrs({
  className: 'animated fadeIn',
})`
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 0 15px;
  padding-bottom: 10px;
  border-bottom: #fff 1px solid;
  background-color: #fafafa;
  width: 100%;
`

export const ReplySection = styled.div`
  padding: 0 10px;
`

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

export const AudioContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`

export const Author = styled.div`
  color: #aaa;
  font-size: 11px;
  padding-top: 3px;
  margin-left: 4px;
`

export const TextMessage = styled.div`
  padding: 5px 0;
  color: #fff;
  margin: 0px;
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  float: left;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
`

export const MediaPreviewContainer = styled.div.attrs({
  className: 'row',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px 0 15px;
`

export const ImagePreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ImageReplyIcon = styled(MdPhotoCamera).attrs({
  alt: 'Image',
  size: 22,
  color: '#303030',
})``

export const ContentIcon = styled(BiMessage).attrs({
  alt: 'Content',
  size: 22,
  color: '#303030',
})`
  margin-right: 6px;
`

export const VideoReplyIcon = styled(BsCameraVideoFill).attrs({
  alt: 'Video',
  size: 22,
  color: '#303030',
})``

export const GifReplyIcon = styled(AiOutlineFileGif).attrs({
  alt: 'Gif',
  size: 22,
  color: '#303030',
})``

export const AudioReplyIcon = styled(BsFillMicFill).attrs({
  alt: 'Audio',
  size: 22,
  color: '#303030',
})`
  margin-right: 10px;
`

export const FileReplyIcon = styled(AiFillFile).attrs({
  alt: 'File',
  size: 22,
  color: '#303030',
})`
  margin-right: 10px;
`

export const LinkReplyIcon = styled(MdInsertLink).attrs({
  alt: 'Link',
  size: 24,
  color: '#303030',
})`
  margin-right: 8px;
`
