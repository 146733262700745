import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import { RiSave2Line } from 'react-icons/ri'
import { MdWeb, MdDirectionsWalk, MdLanguage } from 'react-icons/md'
import WhiteButton from '~/components/WhiteButton'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    margin: 0;
    padding: 0;

    @media (min-width: 576px) {
      max-width: 850px;
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    p {
      margin: 0;
    }
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
`

export const InputLabel = styled.span`
  color: #969faa;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
`

export const ModalTitle = styled.p`
  color: var(--text-bold);
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  opacity: 0.9;
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-bottom: 4px;
  padding-top: 0px !important;
`

export const Container = styled.div`
  .MuiOutlinedInput-input {
    padding: 9px !important;
  }
`
export const ProductsColumn = styled(Col)``
export const FormColumn = styled(Col)``

export const WebAndMobileProductsSection = styled.div`
  display: flex;
  align-items: center;
`

export const WebMobileText = styled.span`
  color: var(--sponsor-primary);
  font-family: Roboto;
  font-size: 16px;
  margin-left: 6px;
  margin-top: 2px;
  font-weight: 600;
  letter-spacing: 0.5px;
`

export const BorderContainer = styled.div`
  margin: 16px 8px 14px 8px;
`

export const PackageCompositionCardContainer = styled.div`
  margin-top: 6px;
`

export const SocialProductsSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`

export const PhysicalProductsSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`

export const ErrorMessageContainer = styled.div.attrs({
  className: 'error-message',
})``

export const InputContainer = styled.span`
  display: flex;
  margin-top: 10px;
  margin-bottom: 3px;
  align-items: flex-start;
`

export const BorderTitleContainer = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
`

export const PayAsYouGoTitle = styled.p`
  color: var(--input-title);
  font-family: Roboto;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
`

export const PayAsYouGoSection = styled.div`
  background-color: #fff;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
  margin-top: 48px;
`

export const ProductInformationContainer = styled.div.attrs({
  className: 'animated fadeIn',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`

export const ProductInformationText = styled.p`
  color: #635b5b;
  font-family: Roboto;
  font-size: 13px;
  width: 200px;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
`

export const ProductInformationCurrency = styled.p`
  color: #635b5b;
  font-family: Roboto;
  font-size: 13px;
`

export const CampaignTotal = styled.p`
  color: #635b5b;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
`

export const CustomBorderContainer = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
`

export const CustomBorder = styled.p`
  border: solid rgba(214, 214, 214, 0.3);
  border-width: 1px;
  margin-bottom: 0;
  width: 100% !important;
  opacity: 0.6;
`

export const CancelButton = styled(WhiteButton).attrs({
  style: { marginTop: '20px', padding: '0px 30px' },
})``

export const SaveButton = styled(WhiteButton).attrs({
  style: { marginTop: '20px' },
})``

export const SaveIcon = styled(RiSave2Line).attrs({
  color: 'var(--success)',
  size: 23,
})``

export const WebAppFormatsIcon = styled(MdLanguage).attrs({
  color: 'var(--sponsor-primary)',
  size: 24,
})``

export const PhysicalFormatsIcon = styled(MdDirectionsWalk).attrs({
  color: 'var(--sponsor-primary)',
  size: 24,
})``

export const SocialFormatsIcon = styled(MdWeb).attrs({
  color: 'var(--sponsor-primary)',
  size: 24,
})``

export const PayAsYouGoInformationContainer = styled.div``
