/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useForm, ErrorMessage, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaRegImage } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import Button from '~/components/Shared/Button'
import Border from '~/components/Shared/Border'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { UploadS3 } from '~/helper/formFunctions'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import ChatChannelVisualization from '~/components/CreateFormatMobileVisualization/ChatChannel'
import { InputTitle } from '~/components/Shared/StyledComponents'

export default function ModalCreateChatChannel({ handleCloseModal, showModal, campaignProductId }) {
  const { t } = useTranslation()
  const { createCampaignFormatAction } = useSponsorFunctions()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const { handleSubmit, register, setValue, errors, control, watch } = useForm()
  const nameWatch = watch('name')
  const [imageUrl, setImageUrl] = useState(null)
  const [imageId, setImageId] = useState(null)
  const [updatingImage, setUpdatingImage] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [date, setDate] = useState('')
  const [endDate, setEndDate] = useState('')

  function resetForm() {
    setImageUrl(null)
    setImageId(null)
    setUpdatingImage(false)
    setEndDate('')
    setDate('')
  }

  function closeModalAndResetForm() {
    resetForm()
    handleCloseModal()
  }

  function onSubmit(format) {
    createCampaignFormatAction(currentCampaignForReviewPage.id, campaignProductId, format, null, closeModalAndResetForm)
  }

  function handleUpdatingImage() {
    setUpdatingImage(!updatingImage)
  }

  useEffect(() => {
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    register({ name: 'channelAccess' })
    register({ name: 'channelType' })
    register({ name: 'countdownChannel' })
    setValue('channelAccess', 'public')
    setValue('channelType', 'manyToMany')
    setValue('countdownChannel', false)
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
  }, [register, setValue, imageUrl, imageId])

  return (
    <ProductsModal
      action={onSubmit}
      modalTitle={t('newFormat', { format: t('chatChannel') })}
      handleCloseModal={closeModalAndResetForm}
      showModal={showModal}
      loadingUpload={loadingUpload}
      review={false}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <InputTitle>{t('name')}</InputTitle>
          <Controller
            as={<TextField fullWidth variant="outlined" placeholder={t('pleaseTypeTheChannelName')} />}
            rules={{ required: t('defaultValidation', { inputName: t('name') }) }}
            name="name"
            control={control}
          />
          <div className="error-message">
            <ErrorMessage errors={errors} name="name" as="p" />
          </div>

          <StartDateSection
            product={t('chatChannel')}
            selectedCampaignStartDate={date}
            setCampaignStartDate={setDate}
            campaignStartDate={currentCampaignForReviewPage.dtStart}
            campaignEndDate={currentCampaignForReviewPage.dtEnd}
            control={control}
            errors={errors}
          />

          <EndDateSection
            product={t('chatChannel')}
            selectedCampaignStartDate={date}
            selectedCampaignEndDate={endDate}
            campaignEndDate={currentCampaignForReviewPage.dtEnd}
            setCampaignEndDate={setEndDate}
            control={control}
            errors={errors}
          />

          <div className="mt-3">
            <InputTitle>{t('description')}</InputTitle>
            <Controller
              as={
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={t('pleaseTypeTheChannelDescription', { format: t('chatChannel').toLocaleLowerCase() })}
                />
              }
              rules={{ required: t('defaultValidation', { inputName: t('description') }) }}
              name="description"
              control={control}
            />
            <div className="error-message">
              <ErrorMessage errors={errors} name="description" as="p" />
            </div>
          </div>

          <div className="mt-4 mb-3">
            <Border />
          </div>

          <div className="mt-3">
            {updatingImage ? (
              <div className="mt-3">
                <div className="d-flex justify-content-center align-items-center">
                  <FaRegImage className="mr-2" size={14} />
                  <InputTitle>{t('image')}</InputTitle>
                </div>
                <UploadS3
                  setUploadId={setImageId}
                  setUploadUrl={setImageUrl}
                  setLoadingUpload={setLoadingUpload}
                  fileType="image"
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Button
                  text={t('chooseChannelPicture')}
                  type="button"
                  color="#5A6978"
                  action={handleUpdatingImage}
                  padding="0 10px"
                  width="auto"
                  height="40px"
                />
              </div>
            )}
          </div>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          FormatVisualizationComponent={<ChatChannelVisualization image={imageUrl} chatChannelName={nameWatch} />}
        />
      }
    />
  )
}
