import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useCommunitySelectors = () => {
  const allCommunitiesFromCommunityManager = useSelector(state => state.community.communities)
  const communityProducts = useSelector(state => state.community.communityProducts)
  const userCurrentCommunity = useSelector(state => state.community.currentCommunity)
  const getRecoveryPassword = useSelector(state => state.user.recoveredPassword)
  const userCurrentCommunityInvitationLink = useSelector(state => state.community.currentCommunityInvitationLink)
  const communityS3Logo = useSelector(state => state.community.s3CommunityLogo.url)
  const communityS3LogoId = useSelector(state => state.community.s3CommunityLogo.id)
  const communityS3Banner = useSelector(state => state.community.s3CommunityBanner.url)
  const communityS3BannerId = useSelector(state => state.community.s3CommunityBanner.id)

  const actions = useMemo(
    () => ({
      allCommunitiesFromCommunityManager() {
        return allCommunitiesFromCommunityManager
      },

      communityProducts() {
        return communityProducts
      },

      userCurrentCommunity() {
        return userCurrentCommunity
      },

      userCurrentCommunityInvitationLink() {
        return userCurrentCommunityInvitationLink
      },

      getRecoveryPassword() {
        return getRecoveryPassword
      },

      communityS3Logo() {
        return communityS3Logo
      },

      communityS3LogoId() {
        return communityS3LogoId
      },

      communityS3Banner() {
        return communityS3Banner
      },

      communityS3BannerId() {
        return communityS3BannerId
      },
    }),
    [
      allCommunitiesFromCommunityManager,
      userCurrentCommunity,
      getRecoveryPassword,
      userCurrentCommunityInvitationLink,
      communityProducts,
      communityS3Logo,
      communityS3Banner,
      communityS3LogoId,
      communityS3BannerId,
    ],
  )

  return actions
}

export default useCommunitySelectors
