import styled from 'styled-components'
import Row from 'react-bootstrap/Row'

export const CardBody = styled.div`
  margin-top: 20px;
  width: 100%;
  padding-bottom: 25px;

  .icon-section {
    display: flex;
    align-items: center;
    padding: 0px 30px 8px 30px;

    p {
      margin: 0px;
      margin-left: 30px;
      color: var(--input-title);
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
  }
`

export const AnalyticsCardRow = styled(Row)`
  margin: -10px 16px 0px 16px !important;
`
