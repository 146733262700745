export const SET_FIRST_LOGIN = '@sponsor/SetFirstLogin'

export const UPLOAD_S3_REQUEST = '@sponsor/UploadS3Request'
export const UPLOAD_S3_SUCCESS = '@sponsor/UploadS3Success'
export const REMOVE_S3_FROM_STATE = '@sponsor/RemoveS3FromState'
export const UPLOAD_S3_LINK_REQUEST = '@sponsor/UploadS3LinkRequest'
export const UPLOAD_S3_LINK_SUCCESS = '@sponsor/UploadS3LinkSuccess'

export const UPDATE_SPONSOR_INFORMATIONS = '@sponsor/UpdateSponsorInformations'
export const UPDATE_SPONSOR_INFORMATIONS_LOADING = '@sponsor/UpdateSponsorInformationsLoading'
export const UPDATE_SPONSOR_INFORMATIONS_FAILURE = '@sponsor/UpdateSponsorInformationsFailure'
export const UPDATE_SPONSOR_INFORMATIONS_SUCCESS = '@sponsor/UpdateSponsorInformationsSuccess'

export const ADD_NEW_COMMUNITY_TO_SPONSOR = '@sponsor/AddNewCommunityToSponsor'
export const DELETE_COMMUNITY_INFORMATIONS = '@sponsor/DeleteCommunityInformations'
export const UPDATE_SPONSOR_PROFILE_REQUEST = '@sponsor/UpdateSponsorProfileRequest'
export const OPEN_CREATE_CAMPAIGN_FOR_TUTORIAL = '@sponsor/OpenCreateCampaignForTutorial'
export const CLOSE_CREATE_CAMPAIGN_FOR_TUTORIAL = '@sponsor/CloseCreateCampaignForTutorial'

export const GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_ERROR =
  '@sponsor/GetCommunityInformationsByInvitationLinkError'
export const GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST =
  '@sponsor/GetCommunityInformationsByInvitationLinkRequest'
export const GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_SUCCESS =
  '@sponsor/GetCommunityInformationsByInvitationLinkSuccess'

export const SET_ALL_COMMUNITIES_FROM_SPONSOR = '@sponsor/SetAllCommunitiesFromSponsor'
export const SET_SHOW_CAMPAIGN_REVIEW_PAGE_MOCKED_FOR_THE_TUTORIAL =
  '@sponsor/SetShowCampaignReviewPageMockedForTheTutorial'
