export const SIGN_OUT = '@auth/SignOut'
export const SIGN_FAILURE = '@auth/SignFailure'
export const SIGN_IN_SUCCESS = '@auth/SignInSuccess'
export const SIGN_UP_SPONSOR_REQUEST = '@auth/SignUpSponsorRequest'
export const SIGN_IN_LOADING_SUCCESS = '@auth/SignInLoadingSuccess'
export const SIGN_IN_SPONSOR_REQUEST = '@auth/SignInSponsorRequest'
export const SIGN_IN_SPONSOR_SUCCESS = '@auth/SignInSponsorSuccess'
export const SIGN_IN_REQUEST = '@auth/SignInRequest'
export const SIGN_IN_COMMUNITY_SUCCESS = '@auth/SignInCommunitySuccess'
export const SIGN_UP_COMMUNITY_REQUEST = '@auth/SignUpCommunityRequest'
export const SET_WRONG_USER_TRYING_TO_LOGIN = '@auth/SetWrongUserTryingToLogin'
export const SIGN_UP_COMMUNITY_LEAD = '@auth/SignUpCommunityLead'
