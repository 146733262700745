import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import { IoIosAdd } from 'react-icons/io'
import WhiteButton from '~/components/WhiteButton'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  padding: 30px;
`

export const SearchInputContainer = styled.div`
  margin: 30px 0px 30px 0px;
  display: flex;
  justify-content: space-between;
  border-width: 1px;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 35px;
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const ProductsTabContainer = styled.div`
  margin-top: 30px;
`

export const GridTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

export const GridTitle = styled.p`
  margin: 0px;
  color: var(--community-dark);
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
`

export const NameColumn = styled(Col).attrs({
  md: 3,
})``

export const ActiveCampaignsColumn = styled(Col).attrs({
  md: 3,
})`
  padding-left: 0px !important;
`

export const SponsorSinceColumn = styled(Col).attrs({
  md: 2,
})`
  padding-left: 0px !important;
`

export const CumulatedRevenueColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
`

export const StatusColumn = styled(Col).attrs({
  md: 2,
})``

export const InviteButton = styled(WhiteButton).attrs({
  className: 'invite-sponsor-button',
})``

export const InviteButtonIcon = styled(IoIosAdd).attrs({
  size: 26,
  color: 'var(--community-primary)',
})``
