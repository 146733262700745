import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import { customMedia } from '~/styles/global'

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PageContainer = styled(Container).attrs({
  className: 'animated fadeIn',
})`
  padding: 0px 75px 50px 75px;

  ${customMedia.greaterThan('2200px')`
    padding-left: 250px;
    padding-right: 250px;
  `}

  ${customMedia.lessThan('huge')`
    padding-left: 35px;
    padding-right: 35px;
  `}

  ${customMedia.lessThan('small')`
    padding-left: 10px;
    padding-right: 10px;
  `}
`

export const MainContainer = styled.div`
  background-color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
`

export const InviteSponsorText = styled.span`
  font-size: 15px;
  color: #fff;
  margin: 0px;
  font-family: Montserrat;
  font-weight: bold;
`

export const InviteSponsorGradientButton = styled.div`
  background-image: linear-gradient(to right, #7345e9 0%, #9671f5 51%, #7345e9 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  border-radius: 100px;
  cursor: pointer;
  position: fixed;
  bottom: 1rem;
  right: 4.5rem;
  z-index: 10;
  height: 48px;
  padding: 0px 20px;

  &:hover {
    transform: scale(1.05);
    background-position: right center;
  }

  ${customMedia.lessThan('small')`
      display: none;
  `}
`

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`

export const PendingAlertsSection = styled.div``

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``

export const MainRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  align-content: space-between;
  justify-content: space-between;

  @media (min-width: 576px) {
    flex-flow: row wrap;
  }

  @media (min-width: 768px) {
    flex-flow: row nowrap;
  }

  ${customMedia.lessThan('medium')`
      flex-flow: row wrap;
  `}
`

export const GroupTabsColumn = styled.div.attrs({
  className: 'animated fadeIn',
})`
  padding: 20px 10px;

  ${customMedia.greaterThan('medium')`
   order: 2;
    width: 67% ;
  `}

  ${customMedia.lessThan('small')`
      display: none;
  `}

  ${customMedia.lessThan('1100px')`
      display: none;
  `}
`

export const CampaginReviewPageColumn = styled.div.attrs({
  className: 'animated fadeIn',
})`
  padding: 20px 10px;

  ${customMedia.greaterThan('medium')`
   order: 2;
    width: 67% ;
  `}

  ${customMedia.lessThan('small')`
      display: none;
  `}

  ${customMedia.lessThan('1100px')`
      display: none;
  `}
`

export const ChatChannelColumn = styled.div`
  padding: 20px 10px;

  @media (min-width: 768px) {
    width: 17%;
    order: 3;
  }

  ${customMedia.lessThan('1100px')`
     width: 40%;
  `}

  ${customMedia.lessThan('small')`
    width: 100%;
    padding-top: 0px;
  `}
`

export const CommunityProfileColumn = styled.div`
  padding: 20px 10px;

  @media (min-width: 768px) {
    width: 16%;
    order: 1;
  }

  ${customMedia.lessThan('1100px')`
     width: 60%;
  `}

  ${customMedia.lessThan('small')`
    width: 100%;
    padding-bottom: 0px;
  `}
`

export const ChatListContainer = styled.div`
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`
