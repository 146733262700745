import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import JoyRide from 'react-joyride'
import { useTranslation } from 'react-i18next'
import SearchInput from '~/components/SearchInput'
import TabTitleAndSubtitle from '~/components/Community/TabTitleAndSubtitle'
import ZeroCampaignsIndicator from '~/components/ZeroCampaignsIndicator'
import { CommunityCampaignsRunningOrFinalized, CommunityCampaignsWaitingForReviewTabCards } from '~/services/requests'
import StripeTooltip from '~/components/ApplicationTutorial/Community/StripeTooltip'
import { CommunityGridTitle } from '~/components/Shared/StyledComponents'
import { returnDataFiltered } from '~/helper/formFunctions'
import * as CONST from '~/helper/const'
import * as S from './styled'

export default function CampaignTab() {
  const { t } = useTranslation()
  const tutorialIsActive = useSelector(state => state.tutorial.tutorialIsActive)
  const [campaignsWaitingCallback, ,] = useState(null)
  const [campaignsRunningCallback, ,] = useState(null)
  const [campaignsRunningOrFinalized, setCampaignsRunningOrFinalized] = useState(null)
  const [campaignsWaitingReview, setCampaignsWaitingReview] = useState(null)
  const [filter, setFilter] = useState('')
  const filteredCampaignsWaitingForReview = returnDataFiltered(filter, campaignsWaitingReview)
  const filteredCampaignsRunning = returnDataFiltered(filter, campaignsRunningOrFinalized)

  return (
    <S.Container>
      <JoyRide
        steps={CONST.communityStripeTooltipOptions}
        run={tutorialIsActive}
        tooltipComponent={StripeTooltip}
        styles={{
          options: {
            arrowColor: 'transparent',
          },
        }}
      />
      <TabTitleAndSubtitle title={t('communityCampaigns')} subtitle={t('acceptNewCampaignProposed')} />
      <S.SearchInputContainer>
        <SearchInput filter={filter} setFilter={setFilter} />
        <S.CampaignTitleContainer>
          <S.SectionTitle>{t('waitingReview')}</S.SectionTitle>
        </S.CampaignTitleContainer>
      </S.SearchInputContainer>
      {campaignsWaitingReview?.length !== 0 && (
        <S.CampaignsNeedingRevisionContainer>
          <S.GridTitleContainer>
            <S.NameColumn>
              <CommunityGridTitle>{t('campaignName')}</CommunityGridTitle>
            </S.NameColumn>
            <S.CampaignTypeColumn>
              <CommunityGridTitle row>{t('campaignType')}</CommunityGridTitle>
            </S.CampaignTypeColumn>
            <S.TotalBudgetColumn>
              <CommunityGridTitle row>{t('totalBudget')}</CommunityGridTitle>
            </S.TotalBudgetColumn>
            <S.CampaignTimingColumn>
              <CommunityGridTitle row>{t('campaignSchedule')}</CommunityGridTitle>
            </S.CampaignTimingColumn>
            <S.ActionColumn>
              <CommunityGridTitle>{t('action')}</CommunityGridTitle>
            </S.ActionColumn>
          </S.GridTitleContainer>
          <CommunityCampaignsWaitingForReviewTabCards
            campaignsWaitingCallback={campaignsWaitingCallback}
            setCampaignsWaitingReview={setCampaignsWaitingReview}
            filter={filter}
          />
        </S.CampaignsNeedingRevisionContainer>
      )}
      {filteredCampaignsWaitingForReview?.length === 0 && <ZeroCampaignsIndicator type="waiting" />}

      <S.CampaignTitleContainer>
        <S.SectionTitle>{t('runningOrFinalized')}</S.SectionTitle>
      </S.CampaignTitleContainer>
      {campaignsRunningOrFinalized?.length !== 0 && (
        <S.CampaignsContainer>
          <S.GridTitleContainer>
            <S.NameColumn>
              <CommunityGridTitle>{t('campaignName')}</CommunityGridTitle>
            </S.NameColumn>
            <S.CampaignTypeColumn>
              <CommunityGridTitle row>{t('campaignType')}</CommunityGridTitle>
            </S.CampaignTypeColumn>
            <S.TotalBudgetColumn>
              <CommunityGridTitle row>{t('totalBudget')}</CommunityGridTitle>
            </S.TotalBudgetColumn>
            <S.CampaignTimingColumn>
              <CommunityGridTitle row>{t('campaignSchedule')}</CommunityGridTitle>
            </S.CampaignTimingColumn>
            <S.ActionColumn>
              <CommunityGridTitle>{t('action')}</CommunityGridTitle>
            </S.ActionColumn>
          </S.GridTitleContainer>
          <CommunityCampaignsRunningOrFinalized
            campaignsRunningCallback={campaignsRunningCallback}
            setCampaignsRunningOrFinalized={setCampaignsRunningOrFinalized}
            filter={filter}
          />
        </S.CampaignsContainer>
      )}
      {filteredCampaignsRunning?.length === 0 && <ZeroCampaignsIndicator />}
    </S.Container>
  )
}
