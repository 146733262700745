import styled from 'styled-components'
import { FaRegCommentDots } from 'react-icons/fa'
import { AiOutlineLike } from 'react-icons/ai'
import { RiShareForwardLine } from 'react-icons/ri'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { IoIosImages } from 'react-icons/io'
import { FiSend } from 'react-icons/fi'

export const TopPreviewContainer = styled.div`
  background-color: #fff;
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 4px 8px 4px 8px;
`

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ActionText = styled.p`
  color: #6e6e6e;
  font-family: Lato;
  font-size: 12px;
  margin: 0px;
  margin-left: 4px;
`

export const Icons = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  padding: 10px;
`

export const MiddlePreviewContainer = styled.div`
  background-color: #fff;
`

export const LinkedinBackground = styled.div`
  background-color: #e8e4de;
  height: 8px;
`

export const PostHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 14px 6px 14px;
`

export const CommunityInformations = styled.div`
  display: flex;
  align-items: center;
`

export const CommunityNameAndDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3px;
`

export const Date = styled.p`
  color: #a6a9ab;
  font-family: Lato;
  font-size: 13px;
  margin-bottom: 0px;
  margin-left: 4px;
  margin-top: -3px;
`

export const DateAndWorldIcon = styled.div`
  display: flex;
  align-items: center;
  margin-top: -3px;
`

export const Dot = styled.div`
  margin: 0px 3px 0px 5px;
  color: #a6a9ab;
`

export const CommunityLogo = styled.img`
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 100px;
`

export const CommunityName = styled.p`
  color: #000000e6;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  max-width: 220px;
`

export const Text = styled.p`
  color: rgb(0 0 0 / 50%);
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 4px;
  margin-top: -2px;
`

export const PostComment = styled.p`
  color: rgb(0 0 0 / 85%);
  font-family: Lato;
  font-size: 14px;
  margin-left: 18px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  max-width: 300px;
  max-height: 120px;
  line-height: 18px;
`

export const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
  width: 100%;
  height: 210px;
`

export const MediaImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 230px;
`

export const NoMediaIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 230px;
  background-color: #ccc;
`

export const LearnMoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eff3f8;
  height: 44px;
  margin-top: 8px;
`

export const SponsoredContentText = styled.strong`
  color: #000;
  font-weight: bold;
  font-family: Lato;
  font-size: 14px;
  margin-left: 18px;
  margin-bottom: 0px;
`

export const LearnMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #0a66c2;
  color: #0a66c2;
  font-family: Lato;
  font-weight: bold;
  font-size: 15px;
  border-radius: 100px;
  width: 120px;
  height: 30px;
  margin-right: 14px;
`

export const PostCommentAndDescription = styled(PostComment)`
  display: -webkit-box;
`

export const LikeOutlinedIcon = styled(AiOutlineLike).attrs({
  size: 18,
  color: '#6e6e6e',
})``

export const ImageIcon = styled(IoIosImages).attrs({
  size: 28,
  color: 'red',
})``

export const ShareIcon = styled(RiShareForwardLine).attrs({
  size: 18,
  color: '#a6a9ab',
})``

export const CommentIcon = styled(FaRegCommentDots).attrs({
  size: 18,
  color: '#a6a9ab',
})``

export const SendIcon = styled(FiSend).attrs({
  size: 18,
  color: '#a6a9ab',
})``

export const ThreeDotsIcon = styled(BiDotsHorizontalRounded).attrs({
  size: 21,
  color: '#a6a9ab',
})`
  margin-top: 10px;
`

export const LikeAndComment = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LoveIcon = styled.img.attrs({
  src: 'icons/linkedin-love.svg',
})`
  width: 16px;
  height: 16px;
  margin-left: 4px;
`

export const LikeIcon = styled.img.attrs({
  src: 'icons/linkedin-like.svg',
})`
  width: 16px;
  height: 16px;
  margin-left: 4px;
`

export const SupportIcon = styled.img.attrs({
  src: 'icons/linkedin-support.svg',
})`
  width: 16px;
  height: 16px;
  margin-left: 4px;
`

export const CelebrateIcon = styled.img.attrs({
  src: 'icons/linkedin-celebrate.svg',
})`
  width: 16px;
  height: 16px;
  margin-left: 4px;
`

export const CuriousIcon = styled.img.attrs({
  src: 'linkedin-curious.png',
})`
  width: 16px;
  height: 16px;
  margin-left: 4px;
`

export const SectionText = styled.div`
  color: #a6a9ab;
  font-family: Lato;
  font-size: 14px;
  margin-bottom: 0px;
  margin-left: 6px;
  margin-left: ${props => (props.spacing ? '8px' : '2px')};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  max-width: 80px;
`

export const BottomPreviewContainer = styled.div`
  background-color: #fff;
  padding-bottom: 8px;
`

export const PlayIconContainer = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff0000;
  align-self: center;
  position: absolute;
`

export const PlayIcon = styled.img.attrs({
  src: '/play-icon.png',
})`
  width: 16px;
  height: 16px;
  margin-left: 3px;
`
