import styled from 'styled-components'
import Col from 'react-bootstrap/Col'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  display: flex;
  align-items: center;
  border: 1px solid rgba(214, 214, 214, 0.4);
  border-radius: 5px;
  height: 100%;
  padding: 10px 0;

  svg {
    cursor: pointer;
  }
`

export const ProductSubtitle = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
`

export const TotalBudget = styled(ProductSubtitle)`
  color: var(--success);
`

export const InvoiceStatus = styled.p`
  color: ${props => (props.paymentStatus === 'requires_payment_method' ? '#fcc603' : '#499D28')};
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0px;
`

export const CommunityLogoContainer = styled.div`
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
`

export const CommunityLogoImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100px;
  object-fit: cover;
  margin-left: 3px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
`

export const CommunityInformationsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const FormatsColumn = styled(Col)`
  padding-left: 0px !important;
`

export const TotalBudgetColumn = styled(Col)`
  padding-left: 30px !important;
  padding-right: 0px !important;

  p {
    margin-left: 10px !important;
  }
`

export const CampaignModelColumn = styled(Col)`
  padding-left: 0px !important;
`

export const PaymentStatusColumn = styled(Col)`
  p {
    margin-left: 10px !important;
  }
`
export const PurchaseColumn = styled(Col)``
export const CommunityColumn = styled(Col)``

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const DateLabel = styled.p`
  margin: 0px;
  color: var(--input-title);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
`

export const Date = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
`

export const CommunityAndCampaignNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CampaignName = styled.p`
  margin: 0px;
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.9;
  margin-left: 15px;
  line-height: 18px;
  letter-spacing: 0.5px;
  width: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
`

export const CommunityName = styled.p`
  margin: 0px;
  color: var(--community-primary);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-left: 15px;
  width: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`
