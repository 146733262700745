import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { MdArrowBack } from 'react-icons/md'
import WhiteButton from '~/components/WhiteButton'

export const CustomContainer = styled(Container).attrs({
  className: 'animated fadeIn',
})``

export const CampaignRevisionContainer = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0 30px 20px 30px;
`

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``

export const CategoryColumn = styled(Col).attrs({
  md: 1,
})`
  margin-left: 8px;
`

export const FormatColumn = styled(Col).attrs({
  md: 2,
})``

export const FormatTitleColumn = styled(Col).attrs({
  md: 2,
})`
  padding-left: 0px !important;
  padding-right: 0px !important;
`

export const FormatTimingColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
`

export const StatusColumn = styled(Col).attrs({
  md: 3,
})`
  display: flex;
`

export const ActionsColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  padding-left: 0px !important;
`

export const LoadNewFormatButton = styled(WhiteButton).attrs({
  text: 'Load new format',
})`
  margin-right: 20px;
`

export const Subtitle = styled.p`
  margin: 0px;
  width: 66%;
  color: #635b5b;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  padding: 0px 15px 5px 15px;
`

export const PageContainer = styled.div`
  width: auto;
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 15px;
`

export const CommunityContainer = styled.div`
  display: flex;
  margin-left: 10px;
`

export const CommunityInformationsContainer = styled.div`
  padding: 35px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
`

export const CommunityInformations = styled.div`
  display: flex;
`

export const CommunityLogo = styled.img`
  border-radius: 100px;
  width: 68px;
  height: 68px;
`

export const CampaignFormatsTitleContainer = styled.div`
  margin-top: 20px;
`
export const CompanyName = styled.div`
  max-width: 200px;
  word-break: normal;
  color: #47525e;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
`

export const CommunityNameAndLocationSection = styled.div`
  margin-top: 6px;
  span {
    font-size: 18px;
    width: 100%;
  }
`

export const CommunityLocation = styled.p`
  margin: 0px;
  color: #635b5b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background-image: linear-gradient(to right, #00a2ff 40%, #0090de 100%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 55px;
  padding: 0 15px;
  width: 100% !important;
`

export const HeaderBackContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 400ms ease;

  &:hover {
    transform: scale(1.06);
  }

  &:active {
    position: relative;
    top: 2px;
  }
`

export const HeaderRouteText = styled.p`
  margin: 0px;
  color: #fff;
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 10px;
  letter-spacing: 0.5px;
`

export const HeaderBackText = styled.p`
  margin: 0px;
  color: var(--sponsor-dark);
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 6px;
`

export const HeaderVerticalLine = styled.p`
  margin: 0px;
  border-left: 2px solid var(--sponsor-dark);
  height: 20px;
  margin-left: 10px;
`

export const ButtonsSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 48px;
  margin-right: 16px;
  margin-bottom: 24px;
  padding: 0px 8px;
`

export const CampaignContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CampaignBudgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CampaignInformationTitle = styled.p`
  margin: 0px;
  color: #635b5b;
  font-family: Lato;
  font-size: 14px;
  padding: 0px 15px 5px 15px;
`

export const CampaignInformationSubtitle = styled.p`
  margin: 0px;
  color: #484848;
  font-family: Lato;
  font-size: min(1.4vw, 23px);
  font-weight: 700;
`

export const SearchInputContainer = styled.div`
  margin: 20px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 35px;
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const BodyContainer = styled.div`
  padding: 40px 0px 10px 0px;
`

export const ProductsTabContainer = styled.div`
  margin-top: 30px;
`

export const GridTitleContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
`

export const GridTitle = styled.p`
  margin: 0px;
  color: var(--community-dark);
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: ${props => (props.margin ? '20px' : '0px')};
`

export const ProgressBarsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 24px;
`

export const PopoverContent = styled.div`
  width: 200px;
  padding: 8px 14px 14px 14px;
`

export const BorderContainer = styled.div`
  margin-top: 10px;
`

export const ProductsContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #f2f2f2;
  }
`

export const ProductTitle = styled.span`
  color: #737475;
  font-family: Roboto;
  font-size: 14px;
`

export const BackIcon = styled(MdArrowBack).attrs({
  size: 25,
  color: 'var(--sponsor-dark)',
})``
