import React from 'react'
import { useTranslation } from 'react-i18next'
import getProductImage from '~/helper/getProductImage'
import * as S from './styled'

const FormatDetailsCard = ({ name, description, productType, qtdPerMonth }) => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.FormatImageContainer>
        <S.FormatImage src={getProductImage(productType)} />
      </S.FormatImageContainer>

      <div className="d-flex flex-column ml-2 w-100">
        <S.FormatName>{name}</S.FormatName>
        {description ? <S.FormatDescription>{description}</S.FormatDescription> : null}
      </div>

      <S.MonthUnity>{`${qtdPerMonth}/${t('month')}`}</S.MonthUnity>
    </S.Container>
  )
}

export default FormatDetailsCard
