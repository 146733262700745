import React from 'react'
import { ResponsiveAnimation } from '~/components/ResposiveAnimation'
import * as S from './styles'
import { IsomorphicFigure } from '../../Components/IsomorphicFigure'

import Card from '../../Components/Card'

const listItems = [
  'Variety of digital sponsorship formats',
  'Pricing defined by the community',
  'Create your packages according to yearly, monthly or special campaigns periods',
  'System to validate sponsored contents',
  'Analytics for community and sponsor',
  'Automated digital payment for each campaign',
]

const listIcon = <p>•</p>

export const Principles = () => {
  return (
    <S.Container id="support">
      <ResponsiveAnimation type="fade" applyOn="both" effects={{ direction: 'left' }}>
        <h1>Identity, collaboration and simplified management: three principles to support your community</h1>
      </ResponsiveAnimation>

      <S.CardsContainer>
        <S.Row>
          <Card.CardContainer className="principles">
            <ResponsiveAnimation
              type="fade"
              applyOn="both"
              effects={{ direction: 'down', cascade: true, damping: 0.1 }}
            >
              <Card.ContentWrapper>
                <h3>Digital is your future in terms of members engagement as well as monetization.</h3>

                <IsomorphicFigure
                  desktopDimensions={{ width: 64, height: 64 }}
                  mobileDimensions={{ width: 40, height: 40 }}
                  src="/images/principle-engagement.svg"
                />
              </Card.ContentWrapper>

              <p>
                Your community needs innovation in order to financially survive and grow. Thanks to S.O.S (Sponsors
                Operating System) you can generate new revenues by involving your sponsors in innovative digital
                interactions with your users.
              </p>
            </ResponsiveAnimation>
          </Card.CardContainer>

          <Card.CardContainer className="principles">
            <Card.ContentWrapper>
              <ResponsiveAnimation
                type="fade"
                applyOn="both"
                effects={{ direction: 'down', cascade: true, damping: 0.1 }}
              >
                <h3>Control is the keyword.</h3>

                <IsomorphicFigure
                  desktopDimensions={{ width: 64, height: 64 }}
                  mobileDimensions={{ width: 40, height: 40 }}
                  src="/images/principle-control.svg"
                />
              </ResponsiveAnimation>
            </Card.ContentWrapper>

            <ResponsiveAnimation type="fade" applyOn="both" effects={{ direction: 'down' }}>
              <p>
                Thanks to S.O.S (Sponsors Operating System) you will be able to select and manage your sponsors in order
                to provide your users with “meaningful” sponsored content. Finally a new way to involve your partners
                through a simplified and intuitive approach.
              </p>
            </ResponsiveAnimation>
          </Card.CardContainer>

          <Card.CardContainer className="principles">
            <ResponsiveAnimation
              type="fade"
              applyOn="both"
              effects={{ direction: 'down', cascade: true, damping: 0.1 }}
            >
              <Card.ContentWrapper>
                <h3>A full set of simple and intuitive features is waiting for you:</h3>

                <IsomorphicFigure
                  desktopDimensions={{ width: 64, height: 64 }}
                  mobileDimensions={{ width: 40, height: 40 }}
                  src="/images/principle-features.svg"
                />
              </Card.ContentWrapper>

              <Card.ContentWrapper style={{ flexDirection: 'column' }}>
                {listItems.map(item => (
                  <Card.ListItem icon={listIcon} key={item} text={item} />
                ))}
              </Card.ContentWrapper>
            </ResponsiveAnimation>
          </Card.CardContainer>
        </S.Row>
      </S.CardsContainer>
    </S.Container>
  )
}
