import styled, { css } from 'styled-components'
import Col from 'react-bootstrap/Col'
import { RiFilter2Line } from 'react-icons/ri'
import { FaRegThumbsUp } from 'react-icons/fa'
import { MdMoreHoriz } from 'react-icons/md'
import WhiteButton from '~/components/WhiteButton'
import { customMedia } from '~/styles/global'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  display: flex;
  align-items: center;
  border: 1px solid rgba(214, 214, 214, 0.4);
  border-radius: 5px;
  height: 85px;
  padding: 10px;

  svg {
    cursor: pointer;
  }

  ${customMedia.lessThan('1650px')`
   height: 105px;
  `}
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const ActionOptions = styled(MdMoreHoriz).attrs({
  color: 'var(--text-primary)',
  className: 'package-options',
  size: 24,
})``

export const ProductTitle = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
`

export const FormatTitle = styled.p`
  margin: 0px;
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  opacity: 0.9;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

export const ProductSubtitle = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
`

export const ProductTimingColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ActionsColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px 14px;
`

export const MoreColumn = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProductTitleColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
`

export const TypeColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  justify-content: center;
`

export const CheckboxColumn = styled(Col).attrs({
  md: 1,
})`
  display: flex;
`

export const ProductRevisionColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ReviseButtonContainer = styled.div`
  ${customMedia.lessThan('1650px')`
    margin-right: 0px;
  `}
`

const ButtonProps = css`
  min-width: 120px;
  box-shadow: 0px 2px 4px 1px rgb(209, 213, 220);
`

export const ReviseButton = styled(WhiteButton).attrs({
  style: { padding: '0px 14px 0px 10px' },
})`
  ${ButtonProps}
`

export const ApproveButton = styled(WhiteButton).attrs({
  style: { padding: '0px 14px 0px 10px' },
})`
  ${ButtonProps}

  ${customMedia.lessThan('1650px')`
    margin-right: 0px;
    margin-top: 6px;
  `}
`

export const CheckAnalyticsButton = styled(WhiteButton).attrs({})`
  ${ButtonProps}
`

export const ActionWaitingForReview = styled.div``

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const DateLabel = styled.p`
  margin: 0px;
  color: var(--input-title);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
`

export const Date = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
`

export const ApproveIcon = styled(FaRegThumbsUp).attrs({
  color: '#1b73e2',
  size: 20,
})``

export const ReviseIcon = styled(RiFilter2Line).attrs({
  color: '#ed4656',
  size: 20,
})``

export const ProductDotIndicator = styled.div`
  background-color: ${props => props.color};
  border-radius: 100%;
  min-width: 10px;
  min-height: 10px;

  ${customMedia.lessThan('1330px')`
    display: none;
  `}
`

export const ProductStatus = styled.span`
  margin: 0px;
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.9px;
  margin-left: 6px;
  text-align: center;
  max-width: 90px;
`
