import styled from 'styled-components'

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`

export const ProgressContainer = styled.div`
  background-color: var(--light-grey);
  margin-top: 4px;
  border-radius: 20px;
  position: relative;
  height: 6px;
`

export const ProgressDone = styled.div`
  background: ${props =>
    props.sponsor
      ? 'linear-gradient(to right, #00A2FF 50%, #0090de 100%)'
      : 'linear-gradient(to right, #b9a0f8 50%, #9671F5 100%)'};
  border-radius: 10px;
  color: var(--text-bold);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
`

export const ProgressBarTitleAndPercentageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ProgressBarTitle = styled.p`
  margin-bottom: 0px;
  color: var(--grey);
  font-family: Roboto;
  font-size: 14px;
  margin-bottom: 0px;
`

export const ProgressBarPercentage = styled.p`
  margin-bottom: 0px;
  color: ${props => (props.sponsor ? '#00A2FF' : '#9671F5')};
  font-family: Roboto Condensed;
  font-size: 13px;
  margin-bottom: 0px;
  font-weight: 600;
`
