import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    width: 50%;
    height: 50%;
    margin: 0;
    padding: 0;

    @media (min-width: 576px) {
      max-width: 850px;
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    p {
      margin: 0;
    }
  }

  .modal-body {
    padding: 0px !important;
  }

  .modal-content {
    padding-top: 200px;
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
`

export const InputLabel = styled.span`
  color: #47525e;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
`

export const RatingTextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .rate-campaign {
    color: #404257;
    font-family: Lato;
    font-size: 19px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
  }

  .rate-message {
    color: #404257;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
  }
`

export const FormContainer = styled.div`
  margin-bottom: 20px;
  .rate-notes {
    color: #404257;
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
  }

  .error-message {
    font-family: Roboto;
    color: #ea343e;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.9;
  }

  .MuiOutlinedInput-multiline {
    height: 130px !important;
  }
`

export const ModalTitle = styled.p`
  color: #404257;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  opacity: 0.9;
`

export const CommunityContainer = styled.div`
  display: flex;
  margin-left: 10px;
`

export const CommunityNameAndLocationSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .social-icons {
    border-radius: 2px;
    width: 21px;
    height: 21px;
  }

  .social-icons-youtube {
    border-radius: 2px;
    width: 23px;
    height: 23px;
  }

  button {
    border: none;
    background: transparent;

    + button {
      margin-left: 5px;
    }
  }
`

export const CommunityName = styled.span`
  display: inline-block;
  width: 250px;
  height: 22px;
  color: #47525e;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
`

export const Subtitle = styled.p`
  text-align: right;
  margin: 0px;
  color: #635b5b;
  font-family: Lato;
  font-size: 14px;
`

export const CommunityLocation = styled.p`
  margin: 0px;
  color: #635b5b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
`

export const ChartTitle = styled.p`
  margin: 0px;
  color: #484848;
  font-family: Lato;
  font-size: 23px;
  font-weight: 700;
  text-align: right;
`

export const CommunityInformations = styled.div`
  background-color: #fff;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;

  .community-logo {
    border-radius: 100px;
    width: 70px;
    height: 70px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    object-fit: cover;
  }
`

export const RatingComponentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`
