import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Default from '~/../public/default.jpg'
import ShouldRender from '../ShouldRender'
import Shimmer from '../Shimmer'
import * as S from './styles'

const CustomImage = props => {
  const { loading: isLoading, objectFit = 'cover', width, height, radius, src, rounded, className, ...rest } = props

  const [loaded, setLoaded] = useState(false)
  const [hasError, setHasError] = useState(false)

  const loading = isLoading || !loaded

  const onLoad = useCallback(() => {
    setLoaded(true)
  }, [])

  const handleError = useCallback(() => {
    setHasError(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  const currentSrc = useMemo(() => {
    if (hasError || !src) {
      return Default
    }

    return src
  }, [src, hasError])

  useEffect(() => {
    const img = new Image()
    img.src = currentSrc
    img.onload = onLoad

    if (typeof currentSrc === 'string') {
      setLoaded(true)
    }

    return () => {
      setLoaded(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  return (
    <>
      <ShouldRender if={!loading}>
        <S.Image
          {...rest}
          src={currentSrc}
          objectFit={objectFit}
          width={width}
          height={height}
          radius={radius}
          rounded={rounded}
          onError={handleError}
          className={`${className} animate__animated animate__fadeIn`}
        />
      </ShouldRender>
      <ShouldRender if={loading}>
        <Shimmer
          className={className}
          style={{
            width,
            height,
            borderRadius: rounded ? '50%' : radius,
            ...(rest.style || {}),
          }}
        />
      </ShouldRender>
    </>
  )
}

export default CustomImage
