import React from 'react'
import * as S from './styled'

export default function ProductBadge({
  text,
  color,
  textColor,
  height,
  width,
  eventText,
  eventTextColor,
  fontSize,
  eventTextFontSize,
  style,
  marginTop,
}) {
  return (
    <S.Container eventTextFontSize={eventTextFontSize} style={style} eventTextColor={eventTextColor}>
      <S.BadgeContainer
        marginTop={marginTop}
        textColor={textColor}
        color={color}
        height={height}
        width={width}
        fontSize={fontSize}
      >
        {text}
      </S.BadgeContainer>
      <span>{eventText}</span>
    </S.Container>
  )
}
