import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.size-sm {
    width: 28px;
    height: 28px;
  }

  &.size-40 {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }

  &.size-md {
    width: 44px;
    height: 44px;
  }

  &.size-lg {
    width: 64px;
    height: 64px;
  }
`

export const InitialsContainer = styled.div`
  background-color: #000;
  width: 100%;
  height: 100%;
  min-height: 28px;
  min-width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 10px;
  line-height: 10px;
  color: #fff;
  font-weight: bold;

  &.size-sm {
    font-size: 13px;
    line-height: 13px;
  }

  &.size-40 {
    font-size: 17px;
    line-height: 17px;
  }

  &.size-md {
    font-size: 17px;
    line-height: 17px;
  }

  &.size-lg {
    font-size: 20px;
    line-height: 20px;
  }
`

export const UserAvatar = styled.img`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;

  &.size-sm {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }

  &.size-40 {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }

  &.size-md {
    width: 44px;
    height: 44px;
    border-radius: 22px;
  }

  &.size-lg {
    width: 64px;
    height: 64px;
    border-radius: 32px;
  }
`
