import styled from 'styled-components'
import { BiMessage } from 'react-icons/bi'

export const QuotesIcon = styled.img.attrs({
  src: '/quotes.png',
  alt: 'Quotes',
})``

export const ReplyIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 6px;
  margin-left: 6px;
`

export const FileReplyIcon = styled(ReplyIcon).attrs({
  src: '/file.png',
  alt: 'File',
})``

export const AudioReplyIcon = styled(ReplyIcon).attrs({
  src: '/mic.png',
  alt: 'Audio',
})`
  margin-left: 0px;
`

export const ContentIcon = styled(BiMessage).attrs({
  alt: 'Content',
  size: 22,
  color: '#FFF',
})`
  margin-right: 6px;
  margin-left: 6px;
`

export const ReplyTypeOfMedia = styled.div`
  font-style: italic;
  color: #fff;
`

export const ReplyContainer = styled.div`
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 0 15px;
  padding-bottom: 10px;
  border-bottom: #fff 1px solid;
  background-color: #303030;
`

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

export const AudioContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`

export const Author = styled.div`
  color: #aaa;
  font-size: 11px;
  padding-top: 3px;
`

export const TextMessage = styled.div`
  padding: 5px 0;
  color: #fff;
  margin: 0px;
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  float: left;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
`

export const MediaPreviewContainer = styled.div.attrs({
  className: 'row',
})`
  padding: 5px 15px 0 15px;
`

export const ImagePreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`
