/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Play from '~/components/Chat/PlayIcon'
import defaultImg from '~/../public/default.jpg'
import * as S from './styled'

const Preview = ({ image, isVideo, title, description, linkWithoutContent, message }) => {
  const [thumbnail, setThumbnail] = useState(defaultImg)
  const [link, setLink] = useState(null)

  const handleClickLink = () => {
    if (link) {
      const urlWithHttp = `${link}`
      window.open(urlWithHttp, '_blank')
    }
  }

  useEffect(() => {
    if (!!image && typeof image === 'string') {
      setThumbnail(image)
    }
    if (!!image && image.url) {
      setThumbnail(image.url)
    }
  }, [image])

  useEffect(() => {
    if (linkWithoutContent) {
      if (message?.links?.length > 0) {
        setLink(message?.links[0])
      }
      setLink(message?.message)
    }
  }, [])

  return linkWithoutContent ? (
    <S.PreviewLinkContainer role="button" onClick={handleClickLink}>
      <S.ContentContainer>
        <S.Link>{link}</S.Link>
      </S.ContentContainer>
    </S.PreviewLinkContainer>
  ) : (
    <S.PreviewContainer role="button">
      <S.ThumbnailContainer>
        <S.ThumbnailImage
          src={thumbnail}
          onError={e => {
            e.target.onerror = null
            e.target.src = defaultImg
          }}
        />
        {isVideo && <Play />}
      </S.ThumbnailContainer>

      {description || title ? (
        <S.ContentContainer>
          <S.ContentTitle>{title}</S.ContentTitle>
          <S.ContentDescription>{description}</S.ContentDescription>
        </S.ContentContainer>
      ) : null}
    </S.PreviewContainer>
  )
}

export default Preview
