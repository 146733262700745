import produce from 'immer'
import { SET_LANGUAGE } from './actionTypes'

const INITIAL_STATE = {
  language: String(navigator.language.substring(0, 2)),
}

export default function language(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_LANGUAGE: {
        draft.language = action.payload
        break
      }
      default:
    }
  })
}
