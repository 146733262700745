export const RESET_CAMPAIGN_PROCESS = '@createCampaign/ResetCampaignProcess'
export const GET_CAMPAIGN_INFORMATIONS = '@createCampaign/GetCampaignInformations'
export const SET_CAMPAIGN_FOR_REVIEW = '@createCampaign/SetCampaignForReview'
export const SET_CAMPAIGN_FOR_UPDATE = '@createCampaign/SetCampaignForUpdate'
export const SET_CAMPAIGN_TOTAL_PRICE = '@createCampaign/SetCampaignTotalPrice'
export const REFUSE_CAMPAIGN = '@createCampaign/RefuseCampaign'
export const APPROVE_CAMPAIGN = '@createCampaign/ApproveCampaign'
export const STOP_CAMPAIGN = '@createCampaign/StopCampaign'
export const SET_CAMPAIGN_CURRENT_SELECTED_MONTH = '@createCampaign/SetCampaignCurrentSelectedMonth'
export const SET_CAMPAIGN_CURRENT_SELECTED_YEAR = '@createCampaign/SetCampaignCurrentSelectedYear'
export const SET_PRODUCT_INFORMATIONS = '@createCampaign/SetProductInformations'
export const GET_CAMPAIGN_TARGET_FILTER_SUCCESS = '@createCampaign/GetCampaignTargetFilterSuccess'
export const ADD_CAMPAIGN_INFORMATIONS = '@createCampaign/AddCampaignInformations'
export const ADD_CAMPAIGN_FOR_COMMUNITY_SUCCESS = '@createCampaign/AddCampaignForCommunitySuccess'
export const SPONSOR_DELETE_CAMPAIGN_REQUEST = '@createCampaign/SponsorDeleteCampaignRequest'
export const SET_CURRENT_CAMPAIGN_BEING_UPDATED = '@createCampaign/SetCurrentCampaignBeingUpdated'
export const SET_CURRENT_COMMUNITY_FOR_CREATE_CAMPAIGN_PROCESS =
  '@createCampaign/SetCurrentCommunityForCreateCampaignProcess'

// SURVEY
export const ADD_SURVEY_INITIAL_INFORMATIONS = '@createCampaign/AddSurveyInitialInformations'
export const INCREASE_SURVEY_QUANTITY_PRODUCT = '@createCampaign/IncreaseSurveyQuantityProduct'
export const DECREASE_SURVEY_QUANTITY_PRODUCT = '@createCampaign/DecreaseSurveyQuantityProduct'
export const DELETE_ALL_SURVEYS = '@createCampaign/DeleteAllSurveys'

// SURVEY WITHOUT MULTIPLE QUESTIONS
export const ADD_SURVEY = '@createCampaign/AddSurvey'
export const EDIT_SURVEY = '@createCampaign/EditSurvey'
export const DELETE_SURVEY = '@createCampaign/DeleteSurvey'

// SURVEY WITH MULTIPLE QUESTIONS
export const ADD_SURVEY_MULTIPLE_QUESTIONS = '@createCampaign/AddSurveyMultipleQuestions'
export const EDIT_SURVEY_MULTIPLE_QUESTIONS = '@createCampaign/EditSurveyMultipleQuestions'
export const DELETE_SURVEY_MULTIPLE_QUESTIONS = '@createCampaign/DeleteSurveyMultipleQuestions'

export const EDIT_SURVEY_INITIAL_INFORMATIONS = '@createCampaign/EditSurveyInitialInformations'

// CHAT CHANNEL
export const ADD_CHAT_CHANNEL = '@createCampaign/AddChatChannel'
export const EDIT_CHAT_CHANNEL = '@createCampaign/EditChatChannel'
export const DELETE_CHAT_CHANNEL = '@createCampaign/DeleteChatChannel'
export const INCREASE_CHAT_CHANNEL_QUANTITY_PRODUCT = '@createCampaign/IncreaseChatChannelQuantityProduct'
export const DECREASE_CHAT_CHANNEL_QUANTITY_PRODUCT = '@createCampaign/DecreaseChatChannelQuantityProduct'
export const DELETE_ALL_CHAT_CHANNELS = '@createCampaign/DeleteAllChatChannels'

// WELCOME AD
export const ADD_WELCOME_AD = '@createCampaign/AddChatWelcomeAd'
export const EDIT_WELCOME_AD = '@createCampaign/EditChatWelcomeAd'
export const DELETE_WELCOME_AD = '@createCampaign/DeleteChatWelcomeAd'
export const INCREASE_WELCOME_AD_QUANTITY_PRODUCT = '@createCampaign/IncreaseChatWelcomeAdQuantityProduct'
export const DECREASE_WELCOME_AD_QUANTITY_PRODUCT = '@createCampaign/DecreaseChatWelcomeAdQuantityProduct'
export const DELETE_ALL_WELCOME_ADS = '@createCampaign/DeleteAllWelcomeAds'

// MOBILE BOOST
export const INCREASE_MOBILE_BOOST_QUANTITY_PRODUCT = '@createCampaign/IncreaseMobileBoostQuantityProduct'
export const DECREASE_MOBILE_BOOST_QUANTITY_PRODUCT = '@createCampaign/DecreaseMobileBoostQuantityProduct'
export const DECREASE_MOBILE_BOOST_QUANTITY = '@createCampaign/DecreaseMobileBoostQuantity'

// REWARD
export const ADD_REWARD = '@createCampaign/AddReward'
export const EDIT_REWARD = '@createCampaign/EditReward'
export const DELETE_REWARD = '@createCampaign/DeleteReward'
export const INCREASE_REWARD_QUANTITY_PRODUCT = '@createCampaign/IncreaseRewardQuantityProduct'
export const DECREASE_REWARD_QUANTITY_PRODUCT = '@createCampaign/DecreaseRewardQuantityProduct'
export const DELETE_ALL_REWARDS = '@createCampaign/DeleteAllRewards'

// EVENT
export const ADD_EVENT = '@createCampaign/AddEvent'
export const EDIT_EVENT = '@createCampaign/EditEvent'
export const DELETE_EVENT = '@createCampaign/DeleteEvent'
export const INCREASE_EVENT_QUANTITY_PRODUCT = '@createCampaign/IncreaseEventQuantityProduct'
export const DECREASE_EVENT_QUANTITY_PRODUCT = '@createCampaign/DecreaseEventQuantityProduct'
export const DELETE_ALL_EVENTS = '@createCampaign/DeleteAllEvents'

// CONTENT
export const INCREASE_CONTENT_QUANTITY_PRODUCT = '@createCampaign/IncreaseContentQuantityProduct'
export const DECREASE_CONTENT_QUANTITY_PRODUCT = '@createCampaign/DecreaseContentQuantityProduct'
export const DELETE_ALL_CONTENT = '@createCampaign/DeleteAllContent'
export const ADD_VIDEO = '@createCampaign/AddVideo'
export const EDIT_VIDEO = '@createCampaign/EditVideo'
export const DELETE_VIDEO = '@createCampaign/DeleteVideo'
export const ADD_POST = '@createCampaign/AddPost'
export const EDIT_POST = '@createCampaign/EditPost'
export const DELETE_POST = '@createCampaign/DeletePost'
export const ADD_WEB = '@createCampaign/AddWeb'
export const EDIT_WEB = '@createCampaign/EditWeb'
export const DELETE_WEB = '@createCampaign/DeleteWeb'
export const ADD_ANNOUNCEMENT = '@createCampaign/AddAnnouncement'
export const EDIT_ANNOUNCEMENT = '@createCampaign/EditAnnouncement'
export const DELETE_ANNOUNCEMENT = '@createCampaign/DeleteAnnouncement'
export const ADD_DOCUMENT = '@createCampaign/AddDocument'
export const EDIT_DOCUMENT = '@createCampaign/EditDocument'
export const DELETE_DOCUMENT = '@createCampaign/DeleteDocument'

// RATING
export const SET_CAMPAIGN_RATING = '@createCampaign/SetCampaignRating'

// STRIPE
export const STRIPE_USER_AUTHORIZATION_CODE = '@createCampaign/StripeUserAuthorizationCode'
export const STRIPE_CREATE_PAYMENT_INTENT_REQUEST = '@createCampaign/StripeCreatePaymentIntentRequest'
export const STRIPE_CREATE_PAYMENT_INTENT_SUCCESS = '@createCampaign/StripeCreatePaymentIntentSuccess'
export const STRIPE_UPDATE_PAYMENT_INTENT_REQUEST = '@createCampaign/StripeUpdatePaymentIntentRequest'
export const SET_LOADING_STRIPE_PAYMENT_INTENT = '@createCampaign/SetLoadingStripePaymentIntent'
export const SET_TEMPORARY_CAMPAIGN_ID_FOR_STRIPE_PAYMENT_INTENT_CREATION =
  '@createCampaign/SetTemporaryCampaignIdForStripePaymentIntentCreation'

// CAMPAIGN 2.0
export const CREATE_CAMPAIGN_WITH_PACKAGE = '@createCampaign/CreateCampaignWithPackage'
export const CREATE_CAMPAIGN_PAY_AS_YOU_GO = '@createCampaign/CreateCampaignPayAsYouGo'
export const RESET_STRIPE_CLIENT_SECRET = '@createCampaign/ResetStripeClientSecret'

export const CREATE_PAYMENT_INTENT_PAY_AS_YOU_GO = '@createCampaign/CreatePaymentIntentPayAsYouGo'
export const CREATE_PAYMENT_INTENT_PACKAGE = '@createCampaign/CreatePaymentIntentPackage'

export const CREATE_CAMPAIGN_FORMAT = '@createCampaign/CreateCampaignFormat'
export const UPDATE_CAMPAIGN_FORMAT = '@createCampaign/UpdateCampaignFormat'
export const SET_CURRENT_CAMPAIGN_FOR_CREATE_FORMAT = '@createCampaign/SetCurrentCampaignForCreateFormat'
export const SET_CAMPAIGN_PRODUCTS_STATUS = '@createCampaign/SetCampaignProductsStatus'

export const ADD_SURVEY_TO_ARRAY = '@createCampaign/AddSurveyToArray'
export const REMOVE_SURVEYS_FROM_ARRAY = '@createCampaign/RemoveSurveysFromArray'
export const SET_CURRENT_CAMPAIGN_FOR_REVIEW_PAGE = '@createCampaign/SetCurrentCampaignForReviewPage'
export const SET_SHOW_CAMPAIGN_REVIEW_PAGE = '@createCampaign/SetShowCampaignReviewPage'
export const SET_CAMPAIGNS_WAITING_FOR_APPROVAL_LENGTH = '@createCampaign/SetCampaignsWaitingForApprovalLength'
export const OPEN_INFORMATION_MODAL_AFTER_FORMAT_CREATION = '@createCampaign/OpenInformationModalAfterFormatCreation'
export const CLOSE_INFORMATION_MODAL_AFTER_FORMAT_CREATION = '@createCampaign/CloseInformationModalAfterFormatCreation'
export const SET_COMMUNITY_SPONSOR_DIRECT_CHANNEL_ID = '@createCampaign/SetCommunitySponsorDirectChannelId'
export const SET_CAMPAIGN_DATES_FOR_CURRENT_MONTH = '@createCampaign/SetCampaignDatesForCurrentMonth'
export const SET_CALLBACK_UPDATE_CAMPAIGN = '@createCampaign/SetCallbackUpdateCampaign'
