import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'

export const ModalCustom = styled(Modal).attrs({
  backdrop: 'static',
  keyboard: false,
})`
  .modal-dialog {
    width: 50%;
    height: 50%;
    margin: 0;
    padding: 0;

    @media (min-width: 576px) {
      max-width: 850px;
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    p {
      margin: 0;
    }
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  text-align-last: center;
  text-align: center;
`

export const Title = styled.p`
  color: #404257;
  margin: 0px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  opacity: 0.9;
`

export const TitleBody = styled.p`
  color: #404257;
  margin: 0px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  opacity: 0.9;
`

export const TitleBodyCenter = styled.p`
  color: #404257;
  margin: 0px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  opacity: 0.9;
  text-align-last: center;
`

export const ModalFooterContainer = styled.div`
  display: flex;
  align-items: baseline;

  p {
    margin-right: 15px !important;
    color: #969faa;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.9;
  }
`

export const BannerImageContainer = styled.div`
  width: 100%;
  height: 170px;
  background-color: #13ce66;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InvitationTextContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InvitationText = styled.span`
  font-size: 38px;
  font-weight: 600;
  line-height: 43px;
  color: #ffffff;
  font-family: Montserrat;
`

export const StartAgain = styled.p`
  color: #00a6ff;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 900;
  line-height: 33px;
  text-decoration: underline;
  padding-top: 20px;
  cursor: pointer;
  display: inline-block;
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalFooter = styled(Modal.Footer)`
  margin-bottom: 4px;
`
