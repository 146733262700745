import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { RiSave2Line } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as S from './styled'

export default function ProductsModalSurvey({
  handleCloseModal,
  showModal,
  leftColumn,
  rightColumn,
  modalTitle,
  updateSurvey,
  review,
}) {
  const { t } = useTranslation()

  const modalBodyStyle = {
    backgroundColor: '#eee',
  }

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        <Container fluid>
          <Row>
            <Col md={6}>{leftColumn}</Col>
            <Col md={6}>{rightColumn}</Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="mb-1">
        <S.ButtonsContainer>
          {review && <S.DoneButton text={t('done')} onClick={() => handleCloseModal()} icon={<S.DoneIcon />} />}
          {!review && (
            <S.UpdateButton onClick={() => updateSurvey()} icon={<RiSave2Line color="var(--success)" size={23} />} />
          )}
        </S.ButtonsContainer>
      </Modal.Footer>
    </S.ModalCustom>
  )
}
