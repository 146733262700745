/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { InputAdornment } from '@material-ui/core'
import PreviewCommunity from '~/components/PreviewCommunity'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import * as AC from '~/store/modules/sponsor/actions'
import * as S from './styled'

export default function ModalAddCommunity({ handleCloseModal, showModal }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { deleteCommunitiesInformationsAction } = useSponsorFunctions()
  const loading = useSelector(state => state.sponsor.loading)
  const communityInformations = useSelector(state => state.sponsor.communityInformations)
  const { handleSubmit, register, errors } = useForm()
  const [invitationLink, setInvitationLink] = useState('')

  function closeModalAndResetForm() {
    setInvitationLink('')
    deleteCommunitiesInformationsAction()
    handleCloseModal()
  }

  function onSubmit() {
    const invitationCode = invitationLink.slice(invitationLink.lastIndexOf('/') + 1)
    dispatch(AC.addNewCommunityToSponsor(invitationCode))
  }

  useEffect(() => {
    if (invitationLink === '' && communityInformations) {
      deleteCommunitiesInformationsAction()
    }
    if (invitationLink !== '') {
      if (invitationLink.length >= 7) {
        const invitationCode = invitationLink.slice(invitationLink.lastIndexOf('/') + 1)
        dispatch(AC.getCommunityInformationsByInvitationLinkRequest(invitationCode))
      }
    }
  }, [invitationLink])

  return (
    <S.ModalCustom show={showModal} onHide={closeModalAndResetForm}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.Title>{t('doYouHaveTheCommunityLink')}</S.Title>
          <S.Subtitle>{t('askCommunityForInvitationCode')}</S.Subtitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InvitationLinkInput
            name="invitationLink"
            placeholder={t('pasteHereInvitationCode')}
            value={invitationLink}
            disabled={!!communityInformations}
            inputRef={register({ required: true })}
            InputProps={{
              endAdornment: <InputAdornment position="end">{loading && <S.LoadingSpinner />}</InputAdornment>,
            }}
            onChange={e => setInvitationLink(e.target.value)}
          />
          <S.ErrorMessage>{errors.invitationLink && t('invitationLinkRequired')}</S.ErrorMessage>
          {communityInformations != null && (
            <S.PreviewCommunityContainer>
              <PreviewCommunity community={communityInformations} />
            </S.PreviewCommunityContainer>
          )}
          <S.ButtonContainer>
            <S.IncludeCommunityButton text={t('include')} action={() => onSubmit} />
          </S.ButtonContainer>
        </S.Form>
      </Modal.Body>
    </S.ModalCustom>
  )
}
