import styled from 'styled-components'

export const PreviewContainer = styled.div`
  background-color: #303030;
  border-radius: 5px;
  transition: all 300ms ease;
`

export const PreviewLinkContainer = styled.div`
  border-radius: 5px;
  transition: all 300ms ease;
  margin-top: 5px;
`

export const ThumbnailContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ThumbnailImage = styled.img`
  height: auto;
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`

export const ContentContainer = styled.div`
  padding: 11px 11px 6px 11px;
`

export const Link = styled.a`
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-style: italic;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
`

export const ContentTitle = styled.div`
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`

export const ContentDescription = styled.div`
  color: #777777;
  font-weight: 400;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 16px;

  max-height: 98px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`
