/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react'
import Lottie from 'react-lottie'
import { FaRegThumbsUp } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ShouldRender from '~/components/ShouldRender'
import { DefaultLogoImage } from '~/util/defaultImages'
import { getFormattedDatesWithYear } from '~/helper/getFormattedDates'
import CampaignReviewTabCard from '~/pages/Community/containers/CampaignReviewTabCard'
import TabTitleAndSubtitle from '~/components/Community/TabTitleAndSubtitle'
import CampaignRequestChangeModal from '~/components/Community/Modals/CampaignRequestChangeModal'
import useGetCommunityCurrencySymbol from '~/hooks/useGetCommunityCurrencySymbol'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import useCampaignFunctions from '~/helper/campaignFunctions'
import ZeroRegisterIndicator from '~/components/ZeroRegisterIndicator'
import { getCurrentCampaignAndReloadThePageForTheCommunity } from '~/services/requests'
import { CommunityGridTitle } from '~/components/Shared/StyledComponents'
import loadingAnimation from '~/../public/animations/loading.json'
import { currencyFormat } from '~/helper/helperFunctions'
import * as FN from '~/helper/formFunctions'
import * as CHAT from '~/helper/chatFunctions'
import * as S from './styled'

export default function CampaignReviewPage({ backToCommunityPage, campaign }) {
  const {
    company: { name: companyName, companyLogo } = {},
    name: campaignName,
    budget: campaignBudget,
    dtStart: campaignStartDate,
    dtEnd: campaignEndDate,
    id: campaignId,
    campaignProducts,
    companyId,
    currency,
  } = campaign || {}

  const { t } = useTranslation()
  const CF = useCampaignFunctions()
  const communityId = useSelector(state => state.community.currentCommunity.id)
  const { setCampaignProductsStatusAction } = useSponsorFunctions()
  const [budgetAlreadyAlocated, setBudgetAlreadyAlocated] = useState(0)
  const [showModalCampaignRequestChange, setShowModalCampaignRequestChange] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [approveFormatLoading, setApproveFormatLoading] = useState(false)
  const [atLeastOneFormatCreated, setAtLeastOneFormatCreated] = useState(false)
  const [selectedProductsCallback, setSelectedProductsCallback] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [callbackDeleteProducts, setCallbackDeleteProducts] = useState('')
  const communityCurrencySymbol = useGetCommunityCurrencySymbol(currency)

  const dates = useMemo(() => {
    if (!!campaignStartDate && !!campaignEndDate) {
      return getFormattedDatesWithYear(campaignStartDate, campaignEndDate)
    }

    return null
  }, [campaignStartDate, campaignEndDate])

  const campaignStartingDate = dates?.start?.dateFormatted
  const startYear = dates?.start?.year
  const campaignEndingDate = dates?.end?.dateFormatted
  const endYear = dates?.end?.year

  const handleApproveProductsButton = () => {
    if (selectedProducts.length > 0) {
      const productsIds = []
      selectedProducts
        .filter(product => product.status === 'waiting-approval')
        .forEach(selectedProduct => productsIds.push(selectedProduct?.formatId))
      const status = 'running'
      setCampaignProductsStatusAction(productsIds, communityId, campaignId, status, setApproveFormatLoading)
      return setSelectedProducts([])
    }
    return null
  }

  const checkProducts = () => {
    let status = false
    selectedProducts.forEach(product => {
      if (product.status !== 'waiting-approval') {
        status = false
      } else {
        status = true
      }
    })
    return status
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
  }

  useEffect(() => {
    FN.calculateBudgetAlreadyAlocated(campaignProducts, setBudgetAlreadyAlocated)
  }, [campaignProducts])

  useEffect(() => {
    getCurrentCampaignAndReloadThePageForTheCommunity(
      campaignId,
      CF.setCurrentCampaignForReviewPageAction,
      setIsLoading,
    )
  }, [])

  useEffect(() => {
    if (campaignProducts) {
      campaignProducts.map(campaignProduct =>
        campaignProduct.unities.length > 0 ? setAtLeastOneFormatCreated(true) : null,
      )
    }
  }, [campaignProducts])

  useEffect(() => {
    CHAT.getSpecificChannelId(communityId, CF.setCommunitySponsorDirectChannelIdAction, companyId)
  }, [communityId, companyId])

  useEffect(() => {
    setSelectedProducts(selectedProducts)
  }, [selectedProducts, selectedProductsCallback])

  useEffect(() => {
    if (!campaign) {
      CF.setShowCampaignReviewPageAction(false)
      CF.setCurrentCampaignForReviewPageAction(null)
    }
  }, [campaign])

  useEffect(() => { }, [callbackDeleteProducts])

  return (
    <div>
      {isLoading && !approveFormatLoading && (
        <S.SpinnerContainer>
          <S.CustomSpinner />
        </S.SpinnerContainer>
      )}
      {!isLoading && approveFormatLoading && (
        <S.LoadingApproveFormatContainer>
          <Lottie options={defaultOptions} width={300} height={300} />
        </S.LoadingApproveFormatContainer>
      )}
      {!isLoading && !approveFormatLoading && (
        <S.CustomContainer fluid>
          <S.HeaderContainer>
            <S.HeaderBackContainer onClick={backToCommunityPage}>
              <S.BackIcon />
              <S.HeaderBackText>{t('back')}</S.HeaderBackText>
            </S.HeaderBackContainer>
            <S.HeaderVerticalLine />
            <S.HeaderRouteText>{t('sponsoredFormatsWaitingReview')}</S.HeaderRouteText>
          </S.HeaderContainer>

          <S.CampaignRevisionContainer>
            <S.PageContainer>
              <S.CustomContainer fluid>
                <S.SponsorInformationsContainer>
                  <S.SponsorInformations>
                    {companyLogo == null && <S.SponsorLogo src={DefaultLogoImage} />}
                    {companyLogo !== null && <S.SponsorLogo src={companyLogo} />}
                    <S.CommunityContainer>
                      <S.CommunityNameAndLocationSection>
                        <S.CampaignNameLabel>{`${t('campaignName')}:`}</S.CampaignNameLabel>
                        <S.CampaignName>{campaignName}</S.CampaignName>
                        <S.CompanyNameLabel>{`${t('sponsor')}:`}</S.CompanyNameLabel>
                        <S.CompanyName>{companyName}</S.CompanyName>
                      </S.CommunityNameAndLocationSection>
                    </S.CommunityContainer>
                  </S.SponsorInformations>

                  <S.CampaignInformations>
                    <S.CampaignContainer>
                      <S.CampaignInformationTitle>{t('startDate')}</S.CampaignInformationTitle>
                      <S.CampaignInformationSubtitle>{campaignStartingDate}</S.CampaignInformationSubtitle>
                      <ShouldRender if={!!startYear}>
                        <S.CampaignInformationYear>{startYear}</S.CampaignInformationYear>
                      </ShouldRender>
                    </S.CampaignContainer>

                    <S.CampaignContainer>
                      <S.CampaignInformationTitle>{t('endDate')}</S.CampaignInformationTitle>
                      <S.CampaignInformationSubtitle>{campaignEndingDate}</S.CampaignInformationSubtitle>
                      <ShouldRender if={!!endYear}>
                        <S.CampaignInformationYear>{endYear}</S.CampaignInformationYear>
                      </ShouldRender>
                    </S.CampaignContainer>

                    <S.CampaignBudgetContainer>
                      <S.CampaignInformationTitle>{t('budgetUsed')}</S.CampaignInformationTitle>
                      <S.CampaignInformationSubtitle>
                        {`${communityCurrencySymbol} ${currencyFormat(campaignBudget)}`}
                      </S.CampaignInformationSubtitle>
                    </S.CampaignBudgetContainer>

                    <S.CampaignBudgetContainer>
                      <S.CampaignInformationTitle>{t('budgetAlreadyAlocated')}</S.CampaignInformationTitle>
                      <S.CampaignInformationSubtitle>
                        {`${communityCurrencySymbol} ${currencyFormat(budgetAlreadyAlocated)}`}
                      </S.CampaignInformationSubtitle>
                    </S.CampaignBudgetContainer>
                  </S.CampaignInformations>
                </S.SponsorInformationsContainer>
              </S.CustomContainer>
              <S.BodyContainer>
                <TabTitleAndSubtitle title={t('proposedFormats')} subtitle={t('reviseTheSponsoredFormat')} />
                <S.SearchInputContainer>
                  <S.ButtonContainer>
                    {selectedProducts.length > 0 && checkProducts() && (
                      <S.ApproveButton
                        icon={<FaRegThumbsUp color="#1b73e2" size={20} />}
                        onClick={() => handleApproveProductsButton()}
                        text={t('approve')}
                      />
                    )}
                  </S.ButtonContainer>
                </S.SearchInputContainer>
              </S.BodyContainer>
              <S.ProductsTabContainer>
                <S.GridTitleContainer>
                  <S.CheckboxColumn />
                  <S.TitleColumn>
                    <CommunityGridTitle>{t('formatTitle')}</CommunityGridTitle>
                  </S.TitleColumn>
                  <S.TypeColumn>
                    <CommunityGridTitle row>{t('type')}</CommunityGridTitle>
                  </S.TypeColumn>
                  <S.TimingColumn>
                    <CommunityGridTitle row>{t('formatScheduling')}</CommunityGridTitle>
                  </S.TimingColumn>
                  <S.StatusColumn>
                    <CommunityGridTitle row>{t('status')}</CommunityGridTitle>
                  </S.StatusColumn>
                  <S.ActionsColumn>
                    <CommunityGridTitle>{t('actions')}</CommunityGridTitle>
                  </S.ActionsColumn>
                </S.GridTitleContainer>
                {!atLeastOneFormatCreated && <ZeroRegisterIndicator text={t('noFormatsCreated')} />}
                {campaignProducts.map(campaignProduct =>
                  campaignProduct.unities.map(formatUnity => (
                    <CampaignReviewTabCard
                      key={formatUnity.id}
                      formatUnity={formatUnity}
                      campaignFormat={campaignProduct}
                      campaign={campaign}
                      selectedProducts={selectedProducts}
                      selectProduct={setSelectedProducts}
                      setSelectedProductsCallback={setSelectedProductsCallback}
                      callbackDeleteProducts={setCallbackDeleteProducts}
                      setApproveFormatLoading={setApproveFormatLoading}
                    />
                  )),
                )}
              </S.ProductsTabContainer>
            </S.PageContainer>
          </S.CampaignRevisionContainer>

          <CampaignRequestChangeModal
            handleCloseModal={() => setShowModalCampaignRequestChange(false)}
            showModal={showModalCampaignRequestChange}
            companyName={companyName}
            companyId={companyId}
          />
        </S.CustomContainer>
      )}
    </div>
  )
}
