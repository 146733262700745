import styled from 'styled-components'

export const SearchContainer = styled.div`
  display: flex;
  svg {
    margin-left: 30px;
    position: absolute;
    width: 24px;
    height: 24px;
    margin-top: 10px;
  }
`

export const SearchInput = styled.input.attrs({
  type: 'text',
})`
  padding-left: 65px;
  padding-right: 12vw;
  margin-right: 10px;
  height: 42px;
  position: relative;
  max-width: 500px;
  border-radius: 100px;
  border: none;
  box-shadow: 0px 3.5px 11px 2px rgba(240, 245, 254, 1);
  ::placeholder {
    color: #343f4b;
    font-size: 15px;
    font-family: Roboto;
  }
`
