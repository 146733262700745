import { useEffect, useState } from 'react'

export function useIsDesktop() {
  const [matches, setMatches] = useState(null)

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(max-width: 760px)')

    const handleMatchChange = e => {
      setMatches(e.matches)
    }

    mediaQueryList.addEventListener('change', handleMatchChange)
    setMatches(mediaQueryList.matches)

    return () => {
      mediaQueryList.removeEventListener('change', handleMatchChange)
    }
  }, [])

  return !matches
}
export default useIsDesktop
