import React, { Suspense } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'filepond/dist/filepond.min.css'
import 'intro.js/introjs.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-dates/lib/css/_datepicker.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'video-react/dist/video-react.css'
import 'react-tenor/dist/styles.css'
import { ToastContainer } from 'react-toastify'
import Spinner from 'react-bootstrap/Spinner'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { inputTheme } from '~/helper/const'
import ErrorBoundary from '~/components/ErrorBoundary'
import Routes from './routes/routes'
import GlobalStyles from './styles/global'
import { store, persistor } from './store'
import '../node_modules/react-image-lightbox/style.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './styles/animate.css'
import '../node_modules/react-modal-video/scss/modal-video.scss'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

export default function App() {
  return (
    <Suspense
      fallback={
        <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
          <Spinner animation="border" size="sm" />
        </div>
      }
    >
      <ErrorBoundary>
        <Elements stripe={stripePromise}>
          <Provider store={store}>
            <ToastContainer />
            <PersistGate loading={null} persistor={persistor}>
              <GlobalStyles />
              <ThemeProvider theme={inputTheme}>
                <Routes />
              </ThemeProvider>
            </PersistGate>
          </Provider>
        </Elements>
      </ErrorBoundary>
    </Suspense>
  )
}
