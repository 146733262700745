import React, { memo, useMemo } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { Controller, ErrorMessage } from 'react-hook-form'
import { reactSelectStyleDefault } from '~/helper/const'
import FormatDetailsCard from '../FormatDetailsCard'
import SelectRangeByDayPicker from '../SelectRangeByDayPicker'
import { PaymentMethod } from '../PaymentMethod/PaymentMethod'

import * as S from './styled'

const ControlledSelectRangeByDayPicker = memo(function ControlledSelectRangeByDayPicker({
  campaignDuration,
  blockedDates,
  control,
  disabled,
  onBlockedDayReport,
}) {
  const { t } = useTranslation()

  return (
    <Controller
      as={({ value, onChange }) => {
        return (
          <SelectRangeByDayPicker
            mode="single"
            selected={value}
            onChange={onChange}
            rangeQtd={campaignDuration}
            blockedDates={blockedDates}
            disabled={disabled}
            onBlockedDayReport={onBlockedDayReport}
          />
        )
      }}
      rules={{ required: t('defaultValidation', { inputName: t('startDate') }) }}
      name="dtStart"
      control={control}
      shouldUnregister
      disabled={disabled}
      onChange={([date]) => date}
    />
  )
})

export default function PayAsYouGoForm({
  setCampaignDuration,
  control,
  errors,
  openModalAddProducts,
  productsSelected,
  blockedDates,
  paymentOptionState,
  campaignDuration,
  onBlockedDayReport,
}) {
  const { t } = useTranslation()

  const campaignDurationOptions = useMemo(
    () => [
      { value: 1, label: `1 ${t('month')} / 30 ${t('days')}` },
      { value: 2, label: `2 ${t('months')} / 60 ${t('days')}` },
      { value: 3, label: `3 ${t('months')} / 90 ${t('days')}` },
      { value: 4, label: `4 ${t('months')} / 120 ${t('days')}` },
      { value: 5, label: `5 ${t('months')} / 150 ${t('days')}` },
      { value: 6, label: `6 ${t('months')} / 180 ${t('days')}` },
      { value: 7, label: `7 ${t('months')} / 210 ${t('days')}` },
      { value: 8, label: `8 ${t('months')} / 240 ${t('days')}` },
      { value: 9, label: `9 ${t('months')} / 270 ${t('days')}` },
      { value: 10, label: `10 ${t('months')} / 300 ${t('days')}` },
      { value: 11, label: `11 ${t('months')} / 330 ${t('days')}` },
      { value: 12, label: `12 ${t('months')} / 360 ${t('days')}` },
    ],
    [t],
  )

  return (
    <>
      <S.FormatSelectorWrapper>
        <S.SelectContainer>
          <S.TextWrapper>
            <strong>{t('selectedFormats')}</strong>
            <p>
              {t('formatsSelected', {
                qtd: productsSelected.length,
              })}
            </p>
          </S.TextWrapper>

          <S.PackageControllersWrapper>
            <S.Separator />

            <S.FormatsControllerButton type="button" onClick={openModalAddProducts}>
              {productsSelected.length === 0 ? t('addFormats') : t('manageFormats')}
            </S.FormatsControllerButton>
          </S.PackageControllersWrapper>
        </S.SelectContainer>
      </S.FormatSelectorWrapper>

      {productsSelected.length > 0 ? (
        <S.PackageInformationContainer>
          <S.FormatDetailsCardContainer>
            {productsSelected.map(product => (
              <FormatDetailsCard
                key={product.id}
                name={product.productName}
                description={product.productDescription}
                productType={product.productType}
                qtdPerMonth={product.qtdPerMonth}
              />
            ))}
          </S.FormatDetailsCardContainer>
        </S.PackageInformationContainer>
      ) : null}

      <S.FormSectionContainer>
        <S.InputTitle>{t('campaignDuration')}</S.InputTitle>

        <S.SelectContainer>
          <Controller
            as={
              <Select
                className="select-element"
                styles={reactSelectStyleDefault}
                placeholder={t('placeholderSelectMonths')}
                options={campaignDurationOptions}
              />
            }
            name="campaignDuration"
            control={control}
            rules={{ required: t('defaultValidation', { inputName: t('campaignDuration') }) }}
            onChange={([selected]) => {
              setCampaignDuration(selected.value)
              return selected
            }}
          />

          <S.ErrorMessageContainer>
            <ErrorMessage errors={errors} name="campaignDuration" as="p" />
          </S.ErrorMessageContainer>
        </S.SelectContainer>
      </S.FormSectionContainer>

      <S.FormSectionContainer>
        <S.InputTitle>{t('paymentMethod')}</S.InputTitle>

        <PaymentMethod paymentOptionState={paymentOptionState} />
      </S.FormSectionContainer>

      <S.FormSectionContainer>
        <S.InputTitle className={!campaignDuration || productsSelected?.length === 0 ? 'disabled' : undefined}>
          {t('campaignStaringDate')}
        </S.InputTitle>

        <S.ColumnSelectContainer>
          <ControlledSelectRangeByDayPicker
            campaignDuration={campaignDuration}
            blockedDates={blockedDates}
            control={control}
            disabled={!campaignDuration || productsSelected.length === 0}
            onBlockedDayReport={onBlockedDayReport}
          />

          <S.ErrorMessageContainer>
            <ErrorMessage errors={errors} name="dtStart" as="p" />
          </S.ErrorMessageContainer>
        </S.ColumnSelectContainer>
      </S.FormSectionContainer>
    </>
  )
}
