import React, { useState, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import Container from 'react-bootstrap/Container'
import { reactSelectStyleDefault, numberOfSurveyQuestionsOptions } from '~/helper/const'
import { InputTitle } from '~/components/Shared/StyledComponents'
import ModalCreateMultipleSurvey from '~/components/Sponsor/Modals/Survey/ModalCreateMultipleSurvey'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import ModalCreateSurvey from '~/components/Sponsor/Modals/Survey/ModalCreateSurvey'
import { compareCampaignStartDateAndEndDate } from '~/helper/getFormattedDates'
import * as S from './styled'

export default function ModalCreateSurveyFistStep({ handleCloseModal, showModal, campaignProductId }) {
  const { t } = useTranslation()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const { handleSubmit, register, setValue, errors, control } = useForm()
  const [numberSurveyQuestions, setNumberOfSurveyQuestions] = useState('')
  const [surveyFirstInformations, setSurveyFirstInformations] = useState('')
  const [showModalCreateMultipleSurvey, setShowModalCreateMultipleSurvey] = useState(false)
  const [showModalCreateSurvey, setShowModalCreateSurvey] = useState(false)
  const [date, setDate] = useState('')
  const [endDate, setEndDate] = useState('')

  function resetStateAfterFormSubmit() {
    setNumberOfSurveyQuestions('')
    setSurveyFirstInformations('')
    setDate('')
    setEndDate('')
  }

  function onSubmit(product) {
    const validateStartAndEndDate = compareCampaignStartDateAndEndDate(date, endDate)
    if (validateStartAndEndDate) {
      return toast.error(t('startDateHigherThenEndDate'))
    }
    if (numberSurveyQuestions > 1) {
      resetStateAfterFormSubmit()
      handleCloseModal()
      setSurveyFirstInformations(product)
      setShowModalCreateMultipleSurvey(true)
    }
    if (numberSurveyQuestions === 1) {
      resetStateAfterFormSubmit()
      handleCloseModal()
      setSurveyFirstInformations(product)
      setShowModalCreateSurvey(true)
    }
    return null
  }

  function handleCloseModalAndResetForm() {
    resetStateAfterFormSubmit()
    handleCloseModal()
  }

  const handleNumberOfSurveyQuestions = survey => {
    setValue('numberSurveyQuestions', survey.value)
    setNumberOfSurveyQuestions(survey.value)
  }

  useEffect(() => {
    register({ name: 'numberSurveyQuestions' })
    register({ name: 'questions' })
    function isMultiQuestion() {
      if (numberSurveyQuestions === 1) {
        setValue('numberSurveyQuestions', 1)
      }
    }
    isMultiQuestion()
    setValue('questions', [])
  }, [register, numberSurveyQuestions, setValue])

  return (
    <>
      {showModalCreateMultipleSurvey === false && (
        <S.ModalCustom show={showModal} onHide={handleCloseModalAndResetForm}>
          <Modal.Header closeButton>
            <Modal.Title>{t('surveyOptions')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: '#eee', paddingBottom: '28px' }}>
            <Container fluid>
              <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={12}>
                    <InputTitle>{t('howManyQuestions')}</InputTitle>
                    <Select
                      styles={reactSelectStyleDefault}
                      value={numberSurveyQuestions.value}
                      options={numberOfSurveyQuestionsOptions}
                      placeholder={t('placeholderSelect')}
                      onChange={handleNumberOfSurveyQuestions}
                    />
                  </Col>

                  {numberSurveyQuestions === '' ? (
                    ''
                  ) : (
                    <>
                      <Col md={12} className="mt-3">
                        <InputTitle>{t('surveyTitle')}</InputTitle>
                        <Controller
                          as={<TextField fullWidth variant="outlined" placeholder={t('typeTitle')} />}
                          rules={{ required: t('defaultValidation', { inputName: t('surveyTitle') }) }}
                          name="name"
                          control={control}
                        />
                        <div className="error-message">
                          <ErrorMessage errors={errors} name="name" as="p" />
                        </div>
                      </Col>

                      <Col md={6}>
                        <StartDateSection
                          product={t('survey')}
                          selectedCampaignStartDate={date}
                          setCampaignStartDate={setDate}
                          campaignStartDate={currentCampaignForReviewPage.dtStart}
                          campaignEndDate={currentCampaignForReviewPage.dtEnd}
                          control={control}
                          errors={errors}
                        />
                      </Col>

                      <Col md={6}>
                        <EndDateSection
                          product={t('survey')}
                          selectedCampaignStartDate={date}
                          selectedCampaignEndDate={endDate}
                          campaignEndDate={currentCampaignForReviewPage.dtEnd}
                          setCampaignEndDate={setEndDate}
                          control={control}
                          errors={errors}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </form>
            </Container>
          </Modal.Body>

          <Modal.Footer className="mb-1">
            <S.SaveButton
              text={t('save')}
              action={event => {
                event.persist()
                handleSubmit(event)
              }}
            />
          </Modal.Footer>
        </S.ModalCustom>
      )}

      {/* 'numberSurveyQuestions' is stored in redux
      after saving these informations, the create survey modal will open with access to these informations */}
      {showModalCreateMultipleSurvey === true && (
        <ModalCreateMultipleSurvey
          handleCloseModal={() => setShowModalCreateMultipleSurvey(false)}
          showModal={showModalCreateMultipleSurvey}
          surveyFirstInformations={surveyFirstInformations}
          campaignProductId={campaignProductId}
        />
      )}

      {showModalCreateSurvey === true && (
        <ModalCreateSurvey
          handleCloseModal={() => setShowModalCreateSurvey(false)}
          showModal={showModalCreateSurvey}
          surveyFirstInformations={surveyFirstInformations}
          campaignProductId={campaignProductId}
        />
      )}
    </>
  )
}
