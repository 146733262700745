/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import ProductsModalSurvey from '~/components/Sponsor/Modals/ProductsModalSurvey'
import Border from '~/components/Shared/Border'
import { prepareToSendSurvey } from '~/helper/formFunctions'
import { InputTitle } from '~/components/Shared/StyledComponents'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import SurveyVisualization from '~/components/CreateFormatMobileVisualization/Survey'
import i18n from '~/i18n'
import * as S from './styled'

export default function ModalEditMultipleSurvey({
  handleCloseModal,
  showModal,
  surveyToUpdate,
  surveyIndex,
  numberSurveyQuestions,
  surveyInformations,
  review,
  createMessage,
  setShowModalEditMultipleSurvey,
  setSurveyObjectToUpdate,
  sponsor,
}) {
  const { t } = useTranslation()
  const currentLanguage = i18n.language
  const { handleSubmit, register, setValue, watch, errors } = useForm()
  const [question, setQuestion] = useState(surveyToUpdate.question)
  const [optionOne, setOptionOne] = useState(surveyToUpdate.optionOne)
  const [optionTwo, setOptionTwo] = useState(surveyToUpdate.optionTwo)
  const [optionThree, setOptionThree] = useState(
    surveyToUpdate.optionThree === undefined ? '' : surveyToUpdate.optionThree,
  )
  const [optionFour, setOptionFour] = useState(surveyToUpdate.optionFour === undefined ? '' : surveyToUpdate.optionFour)
  const [optionFive, setOptionFive] = useState(surveyToUpdate.optionFive === undefined ? '' : surveyToUpdate.optionFive)
  const [radioOptionOne, setRadioOptionOne] = useState(optionOne !== '' || optionOne !== undefined ? true : false)
  const [radioOptionTwo, setRadioOptionTwo] = useState(optionTwo !== '' || optionTwo !== undefined ? true : false)
  const [radioOptionThree, setRadioOptionThree] = useState(
    optionThree !== '' || optionThree !== undefined ? true : false,
  )
  const [radioOptionFour, setRadioOptionFour] = useState(optionFour !== '' || optionFour !== undefined ? true : false)
  const [radioOptionFive, setRadioOptionFive] = useState(optionFive !== '' || optionFive !== undefined ? true : false)
  const questionLength = 100 - question?.length

  const optionOneInputWatch = watch('optionOne', surveyToUpdate.optionOne)
  const optionOneLength = 70 - optionOneInputWatch?.length

  const optionTwoInputWatch = watch('optionTwo', surveyToUpdate.optionTwo)
  const optionTwoLength = 70 - optionTwoInputWatch?.length

  const optionThreeInputWatch = watch('optionThree', surveyToUpdate.optionThree)
  let optionThreeLength = 70
  if (optionThreeInputWatch !== undefined) {
    optionThreeLength = 70 - optionThreeInputWatch?.length
  }

  const optionFourInputWatch = watch('optionFour', surveyToUpdate.optionFour)
  let optionFourLength = 70
  if (optionFourInputWatch !== undefined) {
    optionFourLength = 70 - optionFourInputWatch?.length
  }

  const optionFiveInputWatch = watch('optionFive', surveyToUpdate.optionFive)
  let optionFiveLength = 70
  if (optionFiveInputWatch !== undefined) {
    optionFiveLength = 70 - optionFiveInputWatch?.length
  }

  const optionsChecked = [
    { isChecked: radioOptionOne, option: optionOneInputWatch },
    { isChecked: radioOptionTwo, option: optionTwoInputWatch },
    { isChecked: radioOptionThree, option: optionThreeInputWatch },
    { isChecked: radioOptionFour, option: optionFourInputWatch },
    { isChecked: radioOptionFive, option: optionFiveInputWatch },
  ]

  function closeModalAndHideFirstStepModal() {
    handleCloseModal()
    setShowModalEditMultipleSurvey('true')
  }

  function onSubmit(data) {
    console.log('data:', data)
  }

  const updateSurvey = () => {
    const surveyObjectClone = JSON.parse(JSON.stringify(surveyInformations))
    surveyObjectClone.questions[surveyIndex].question = question
    surveyObjectClone.questions[surveyIndex].optionOne = optionOne
    surveyObjectClone.questions[surveyIndex].optionTwo = optionTwo
    surveyObjectClone.questions[surveyIndex].optionThree = optionThree
    surveyObjectClone.questions[surveyIndex].optionFour = optionFour
    surveyObjectClone.questions[surveyIndex].optionFive = optionFive
    setSurveyObjectToUpdate(surveyObjectClone)
    handleCloseModal()
    setShowModalEditMultipleSurvey('true')
  }

  const modalReviewCreateMessageAndCloseModal = () => {
    createMessage()
    handleCloseModal()
    setShowModalEditMultipleSurvey('true')
  }

  useEffect(() => {
    register({ name: 'id' })
    setValue('id', surveyToUpdate.id)
    register({ name: 'optionOne' })
    register({ name: 'optionTwo' })
    register({ name: 'optionThree' })
    register({ name: 'optionFour' })
    register({ name: 'optionFive' })
    setValue('optionOne', optionOne)
    setValue('optionTwo', optionTwo)
    setValue('optionThree', optionThree)
    setValue('optionFour', optionFour)
    setValue('optionFive', optionFive)
  }, [register, surveyToUpdate.id, setValue, optionOne, optionTwo, optionThree, optionFour, optionFive])

  useEffect(() => {
    if (optionOne !== '') {
      setRadioOptionOne(true)
    } else {
      setRadioOptionOne(false)
    }
    if (optionTwo !== '') {
      setRadioOptionTwo(true)
    } else {
      setRadioOptionTwo(false)
    }
    if (optionThree !== '') {
      setRadioOptionThree(true)
    } else {
      setRadioOptionThree(false)
    }
    if (optionFour !== '') {
      setRadioOptionFour(true)
    } else {
      setRadioOptionFour(false)
    }
    if (optionFive !== '') {
      setRadioOptionFive(true)
    } else {
      setRadioOptionFive(false)
    }
  }, [optionOne, optionTwo, optionThree, optionFour, optionFive])

  useEffect(() => {
    if (optionOne !== '' && radioOptionOne === false) {
      setOptionOne('')
    }
    if (optionTwo !== '' && radioOptionTwo === false) {
      setOptionTwo('')
    }
    if (optionThree !== '' && radioOptionThree === false) {
      setOptionThree('')
    }
    if (optionFour !== '' && radioOptionFour === false) {
      setOptionFour('')
    }
    if (optionFive !== '' && radioOptionFive === false) {
      setOptionFive('')
    }
  }, [radioOptionOne, radioOptionTwo, radioOptionThree, radioOptionFour, radioOptionFive])

  return (
    <ProductsModalSurvey
      createMessage={modalReviewCreateMessageAndCloseModal}
      updateSurvey={updateSurvey}
      buttonText={review ? t('done') : t('update')}
      modalTitle={
        review
          ? t('reviseFormat', { format: t('survey') })
          : `${t('editSurvey')} ${surveyIndex + 1}/${numberSurveyQuestions}`
      }
      handleCloseModal={review === undefined ? closeModalAndHideFirstStepModal : closeModalAndHideFirstStepModal}
      showModal={showModal}
      review={review}
      sponsor={sponsor}
      leftColumn={
        <form onSubmit={handleSubmit(prepareToSendSurvey(onSubmit))}>
          <S.FormContainer currentLanguage={currentLanguage}>
            <div>
              <InputTitle>{`${t('question')} ${surveyIndex + 1}`}</InputTitle>
              <TextField
                fullWidth
                variant="outlined"
                name="question"
                placeholder={t('typeQuestion')}
                value={question}
                inputRef={register}
                onChange={e => setQuestion(e.target.value)}
                disabled={review || false}
                inputProps={{
                  maxLength: 100,
                }}
                inputRef={register({ required: true })}
              />
              <S.ErrorMessage error={errors.question}>
                <p>{errors.question && t('defaultValidation', { inputName: t('question') })}</p>
              </S.ErrorMessage>
            </div>

            {question !== '' && (
              <S.QuestionCharactersLeft>{`${questionLength} ${t('charactersLeft')}`}</S.QuestionCharactersLeft>
            )}
            {optionOneInputWatch !== '' && (
              <S.OptionOneCharactersLeft>{`${optionOneLength} ${t('charactersLeft')}`}</S.OptionOneCharactersLeft>
            )}
            {optionTwoInputWatch !== '' && (
              <S.OptionTwoCharactersLeft>{`${optionTwoLength} ${t('charactersLeft')}`}</S.OptionTwoCharactersLeft>
            )}
            {optionThreeInputWatch !== '' && (
              <S.OptionThreeCharactersLeft>{`${optionThreeLength} ${t('charactersLeft')}`}</S.OptionThreeCharactersLeft>
            )}
            {optionFourInputWatch !== '' && (
              <S.OptionFourCharactersLeft>{`${optionFourLength} ${t('charactersLeft')}`}</S.OptionFourCharactersLeft>
            )}
            {optionFiveInputWatch !== '' && (
              <S.OptionFiveCharactersLeft>{`${optionFiveLength} ${t('charactersLeft')}`}</S.OptionFiveCharactersLeft>
            )}

            <S.RadioGroupSection>
              <FormControlLabel
                style={{ margin: '0px' }}
                value="public"
                label="Public"
                control={
                  <Checkbox
                    checked={radioOptionOne}
                    onChange={() => setRadioOptionOne(!radioOptionOne)}
                    color="default"
                    disabled={review || false}
                  />
                }
                label={
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="optionOne"
                    placeholder={`${t('option')} 1`}
                    type="text"
                    value={optionOne}
                    inputRef={register}
                    onChange={e => setOptionOne(e.target.value)}
                    disabled={review || false}
                    inputProps={{
                      maxLength: 70,
                    }}
                  />
                }
              />
              <FormControlLabel
                style={{ margin: '0px', marginTop: '5px' }}
                value="public"
                label="Public"
                control={
                  <Checkbox
                    checked={radioOptionTwo}
                    onChange={() => setRadioOptionTwo(!radioOptionTwo)}
                    color="default"
                    disabled={review || false}
                  />
                }
                label={
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="optionTwo"
                    disabled={review || false}
                    placeholder={`${t('option')} 2`}
                    value={optionTwo}
                    inputRef={register}
                    onChange={e => setOptionTwo(e.target.value)}
                    inputProps={{
                      maxLength: 70,
                    }}
                  />
                }
              />
              <FormControlLabel
                style={{ margin: '0px', marginTop: '5px' }}
                value="public"
                label="Public"
                control={
                  <Checkbox
                    checked={radioOptionThree}
                    onChange={() => setRadioOptionThree(!radioOptionThree)}
                    color="default"
                    disabled={review || false}
                  />
                }
                label={
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="optionThree"
                    disabled={review || false}
                    placeholder={`${t('option')} 3`}
                    value={optionThree}
                    inputRef={register}
                    onChange={e => setOptionThree(e.target.value)}
                    inputProps={{
                      maxLength: 70,
                    }}
                  />
                }
              />
              <FormControlLabel
                style={{ margin: '0px', marginTop: '5px' }}
                value="public"
                label="Public"
                control={
                  <Checkbox
                    checked={radioOptionFour}
                    onChange={() => setRadioOptionFour(!radioOptionFour)}
                    color="default"
                    disabled={review || false}
                  />
                }
                label={
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="optionFour"
                    disabled={review || false}
                    placeholder={`${t('option')} 4`}
                    value={optionFour}
                    inputRef={register}
                    onChange={e => setOptionFour(e.target.value)}
                    inputProps={{
                      maxLength: 70,
                    }}
                  />
                }
              />
              <FormControlLabel
                style={{ margin: '0px', marginTop: '5px' }}
                value="public"
                label="Public"
                control={
                  <Checkbox
                    checked={radioOptionFive}
                    onChange={() => setRadioOptionFive(!radioOptionFive)}
                    color="default"
                    disabled={review || false}
                  />
                }
                label={
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="optionFive"
                    disabled={review || false}
                    placeholder={`${t('option')} 5`}
                    value={optionFive}
                    inputRef={register}
                    onChange={e => setOptionFive(e.target.value)}
                    inputProps={{
                      maxLength: 70,
                    }}
                  />
                }
              />
            </S.RadioGroupSection>
            <div className="mt-4 mb-2">
              <Border />
            </div>
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          isSurvey
          FormatVisualizationComponent={<SurveyVisualization question={question} optionsChecked={optionsChecked} />}
        />
      }
    />
  )
}
