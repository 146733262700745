import React from 'react'
import * as S from './styles'
import { IsomorphicFigure } from '../IsomorphicFigure'

export const LinkCard = ({ text, leftIcon, onClick }) => {
  return (
    <S.Card onClick={onClick}>
      {leftIcon && (
        <IsomorphicFigure
          src={leftIcon}
          alt="leftIcon"
          desktopDimensions={{ width: 43, height: 43 }}
          mobileDimensions={{ width: 31, height: 31 }}
          className="leftIcon"
        />
      )}
      <p>{text}</p>
    </S.Card>
  )
}
