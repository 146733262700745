import styled from 'styled-components'
import { breakpoints } from '~/helper/breakpoints'

export const Footer = styled.footer`
  width: 100vw;
  background: rgba(22, 22, 24);
  font-family: 'Catamaran' !important;

  background-image: linear-gradient(
    125deg,
    hsl(240deg 4% 9%) 2%,
    hsl(219deg 37% 15%) 23%,
    hsl(217deg 40% 16%) 37%,
    hsl(217deg 40% 16%) 51%,
    hsl(219deg 37% 15%) 67%,
    hsl(240deg 4% 9%) 95%
  );
  padding: 3.5rem 12rem;
  position: relative;
  display: flex;
  justify-content: center;

  @media ${breakpoints.laptopL} {
    padding: 3.5rem 8rem;
  }

  @media ${breakpoints.mobile} {
    padding: 1.125rem 1.5rem;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100rem;
  gap: 2.5rem;

  @media ${breakpoints.mobile} {
    gap: 0.5rem;
    flex-direction: column;
  }
`

export const AbilityaContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 2rem;

  @media ${breakpoints.mobile} {
    gap: 1rem;
    width: 100%;

    .desktop-footer {
      display: none;
    }
  }

  h4 {
    color: #aaacb1;
    font-size: 0.75rem;
    font-weight: 400;
  }
`

export const AbilityaInfo = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.5rem;

  div {
    padding-bottom: 0.75rem;
    width: 13.3rem;
  }

  p {
    color: #aaacb1;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }

  strong {
    font-weight: 700;
  }

  @media ${breakpoints.tablet} {
    display: flex;
    align-items: flex-end;
  }

  @media ${breakpoints.mobile} {
    display: flex;
    align-items: flex-start;
  }
`

export const LogoInfos = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: start;
  flex: 1;
  gap: 4rem;

  h4,
  h3 {
    color: #eff0f4;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 0 !important;
    cursor: pointer;
  }

  a {
    text-decoration: none !important;
    color: #55555b;
    margin-bottom: 0 !important;

    &:hover {
      color: #55555b;
    }
  }

  @media ${breakpoints.tablet} {
    display: none;
  }
`

export const LinksList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;

  h4,
  a,
  p {
    margin-top: 0.3rem !important;
    color: #aaacb1;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0 !important;
    cursor: pointer;
  }
`
