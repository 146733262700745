import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import JoyRide from 'react-joyride'
import { useTranslation } from 'react-i18next'
import SearchInput from '~/components/SearchInput'
import { CampaignsRunningOrFinalized, CampaignsWaitingForReviewTabCards } from '~/services/requests'
import TabTitleAndSubtitle from '~/components/Community/TabTitleAndSubtitle'
import ZeroCampaignsIndicator from '~/components/ZeroCampaignsIndicator'
import StartNewCampaignModal from '~/components/Sponsor/Modals/StartNewCampaignModal'
import CampaignsTabTooltip from '~/components/ApplicationTutorial//Sponsor/CampaignsTabTooltip'
import { resetCampaignProcess as resetCampaignProcessAction } from '~/store/modules/createCampaign/actions'
import useTutorialFunctions from '~/helper/tutorialFunctions'
import { SponsorGridTitle } from '~/components/Shared/StyledComponents'
import { returnDataFiltered } from '~/helper/formFunctions'
import * as CONST from '~/helper/const'
import * as S from './styled'
import StartCampaignButton from '../../StartCampaignButton/StartCampaignButton'

export default function CampaignTab() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tutorialIsActive = useSelector(state => state.tutorial.tutorialIsActive)
  const openCreateCampaignForTutorial = useSelector(state => state.sponsor.openCreateCampaignForTutorial)
  const [campaignsWaitingCallback, setCampaignsWaitingCallback] = useState(null)
  const [campaignsRunningCallback, setCampaignsRunningCallback] = useState(null)
  const [campaignsRunningOrFinalized, setCampaignsRunningOrFinalized] = useState(null)
  const [campaignsWaitingReview, setCampaignsWaitingReview] = useState(null)
  const { openCreateCampaignForTutorialAction, closeCreateCampaignForTutorialAction } = useTutorialFunctions()
  const [filter, setFilter] = useState('')
  const filteredCampaignsWaitingForReview = returnDataFiltered(filter, campaignsWaitingReview)
  const filteredCampaignsRunning = returnDataFiltered(filter, campaignsRunningOrFinalized)

  const openCreateCampaignModalAndSetCampaignStepToOne = () => {
    dispatch(resetCampaignProcessAction())
    openCreateCampaignForTutorialAction()
  }

  return (
    <S.Container>
      <JoyRide
        steps={CONST.sponsorCampaignsTooltipOptions}
        run={tutorialIsActive}
        tooltipComponent={CampaignsTabTooltip}
        styles={{
          options: {
            arrowColor: 'transparent',
          },
        }}
      />
      <TabTitleAndSubtitle sponsor title={t('yourCampaigns')} subtitle={t('manageYourCampaigns')} />
      <S.SearchInputButtonContainer>
        <SearchInput filter={filter} setFilter={setFilter} />
        <S.StartButtonContainer>
          <StartCampaignButton onClick={openCreateCampaignModalAndSetCampaignStepToOne} />
        </S.StartButtonContainer>
      </S.SearchInputButtonContainer>
      <S.WaitingReviewContainer>
        <S.SectionTitle>{t('waitingReview')}</S.SectionTitle>
      </S.WaitingReviewContainer>
      {campaignsWaitingReview?.length !== 0 && (
        <S.CampaignsNeedingRevisionContainer>
          <S.GridTitleContainer>
            <S.CommunityColumn>
              <SponsorGridTitle>{t('campaignName')}</SponsorGridTitle>
            </S.CommunityColumn>
            <S.CampaignTypeColumn>
              <SponsorGridTitle row>{t('campaignType')}</SponsorGridTitle>
            </S.CampaignTypeColumn>
            <S.TotalBudgetColumn>
              <SponsorGridTitle row>{t('totalBudget')}</SponsorGridTitle>
            </S.TotalBudgetColumn>
            <S.CampaignScheduleColumn>
              <SponsorGridTitle row>{t('campaignSchedule')}</SponsorGridTitle>
            </S.CampaignScheduleColumn>
            <S.ActionColumn>
              <SponsorGridTitle>{t('action')}</SponsorGridTitle>
            </S.ActionColumn>
          </S.GridTitleContainer>
          <CampaignsWaitingForReviewTabCards
            campaignsWaitingCallback={campaignsWaitingCallback}
            setCampaignsWaitingReview={setCampaignsWaitingReview}
            filter={filter}
          />
        </S.CampaignsNeedingRevisionContainer>
      )}
      {filteredCampaignsWaitingForReview?.length === 0 && <ZeroCampaignsIndicator type="waiting" />}

      <S.WaitingReviewContainer>
        <S.SectionTitle>{t('runningOrFinalized')}</S.SectionTitle>
      </S.WaitingReviewContainer>
      {campaignsRunningOrFinalized?.length !== 0 && (
        <S.CampaignsContainer>
          <S.GridTitleContainer>
            <S.CommunityColumn>
              <SponsorGridTitle>{t('campaignName')}</SponsorGridTitle>
            </S.CommunityColumn>
            <S.CampaignTypeColumn>
              <SponsorGridTitle row>{t('campaignType')}</SponsorGridTitle>
            </S.CampaignTypeColumn>
            <S.TotalBudgetColumn>
              <SponsorGridTitle row>{t('totalBudget')}</SponsorGridTitle>
            </S.TotalBudgetColumn>
            <S.CampaignScheduleColumn>
              <SponsorGridTitle row>{t('campaignSchedule')}</SponsorGridTitle>
            </S.CampaignScheduleColumn>
            <S.ActionColumn>
              <SponsorGridTitle>{t('action')}</SponsorGridTitle>
            </S.ActionColumn>
          </S.GridTitleContainer>
          <CampaignsRunningOrFinalized
            campaignsRunningCallback={campaignsRunningCallback}
            setCampaignsRunningOrFinalized={setCampaignsRunningOrFinalized}
            filter={filter}
          />
        </S.CampaignsContainer>
      )}
      {filteredCampaignsRunning?.length === 0 && <ZeroCampaignsIndicator />}

      <StartNewCampaignModal
        handleCloseModal={() => closeCreateCampaignForTutorialAction()}
        showModal={openCreateCampaignForTutorial}
        setCampaignsWaitingCallback={setCampaignsWaitingCallback}
        setCampaignsRunningCallback={setCampaignsRunningCallback}
      />
    </S.Container>
  )
}
