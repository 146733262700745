/* eslint-disable consistent-return */
import produce from 'immer'
import { SIGN_IN_COMMUNITY_SUCCESS, SIGN_OUT } from '~/store/modules/auth/actionTypes'
import {
  SET_USER_CURRENT_COMMUNITY,
  GET_COMMUNITY_LINK_INVITATION_REQUEST,
  GET_COMMUNITY_LINK_INVITATION_FAILURE,
  GET_COMMUNITY_LINK_INVITATION_SUCCESS,
  GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST,
  GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_SUCCESS,
  GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_FAILURE,
  UPDATE_COMMUNITY_PRODUCT_SUCCESS,
  UPLOAD_S3_SUCCESS,
  REMOVE_S3_FROM_STATE,
  UPDATE_USER_CURRENT_COMMUNITY_INFORMATIONS,
  SET_FIRST_LOGIN,
  RESET_COMMUNITY_INFORMATIONS,
  SET_USER_CURRENT_COMMUNITY_AFTER_SIGN_UP,
  SET_COMMUNITY_HAS_STRIPE_ACCOUNT,
  ADD_COMMUNITY_GALLERY_MEDIA_REQUEST,
  ADD_COMMUNITY_GALLERY_MEDIA_FAILURE,
  ADD_COMMUNITY_GALLERY_MEDIA_SUCCESS,
  DELETE_COMMUNITY_GALLERY_MEDIA_REQUEST,
  DELETE_COMMUNITY_GALLERY_MEDIA_FAILURE,
  DELETE_COMMUNITY_GALLERY_MEDIA_SUCCESS,
  UPDATE_COMMUNITY_PROFILE_SUCCESS,
  UPDATE_COMMUNITY_PROFILE_FAILURE,
  UPDATE_COMMUNITY_PROFILE_LOADING,
} from '~/store/modules/community/actionTypes'
import history from '~/services/history'

const INITIAL_STATE = {
  communityManager: null,
  communities: null,
  currentCommunity: null,
  currentCommunityInvitationLink: null,
  loadingInvitationLink: false,
  firstLogin: true,
  communityInformations: null,
  communityHasStripeAccount: false,
  hideInvitationLink: false,
  loading: false,
  s3CommunityLogo: {
    id: null,
    url: null,
    loading: false,
  },
  s3CommunityBanner: {
    id: null,
    url: null,
    loading: false,
  },
}

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_FIRST_LOGIN: {
        draft.firstLogin = false
        break
      }

      case UPDATE_COMMUNITY_PROFILE_SUCCESS: {
        draft.loading = false
        break
      }

      case UPDATE_COMMUNITY_PROFILE_FAILURE: {
        draft.loading = false
        break
      }

      case UPDATE_COMMUNITY_PROFILE_LOADING: {
        draft.loading = true
        break
      }

      case REMOVE_S3_FROM_STATE: {
        draft.s3CommunityLogo.id = null
        draft.s3CommunityLogo.url = null
        draft.s3CommunityLogo.loading = false

        draft.s3CommunityBanner.id = null
        draft.s3CommunityBanner.url = null
        draft.s3CommunityBanner.loading = false
        break
      }

      case SIGN_IN_COMMUNITY_SUCCESS: {
        draft.communityManager = action.payload.user
        draft.communities = action.payload.communities
        break
      }

      case UPLOAD_S3_SUCCESS: {
        draft.s3CommunityLogo.id = action.payload.id
        draft.s3CommunityLogo.url = action.payload.url
        break
      }

      case GET_COMMUNITY_LINK_INVITATION_REQUEST: {
        draft.loadingInvitationLink = true
        break
      }

      case GET_COMMUNITY_LINK_INVITATION_SUCCESS: {
        draft.currentCommunityInvitationLink = action.payload.code
        draft.loadingInvitationLink = false
        break
      }

      case GET_COMMUNITY_LINK_INVITATION_FAILURE: {
        draft.loadingInvitationLink = false
        break
      }

      case UPDATE_COMMUNITY_PRODUCT_SUCCESS: {
        draft.communityProducts = action.payload.products
        break
      }

      case SET_USER_CURRENT_COMMUNITY: {
        draft.currentCommunity = action.payload
        history.push('/community')
        break
      }

      case SET_USER_CURRENT_COMMUNITY_AFTER_SIGN_UP: {
        draft.currentCommunity = action.payload
        break
      }

      case UPDATE_USER_CURRENT_COMMUNITY_INFORMATIONS: {
        draft.currentCommunity = action.payload
        break
      }

      case SIGN_OUT: {
        draft.currentCommunity = null
        draft.communities = null
        draft.communityManager = null
        draft.currentCommunityInvitationLink = null
        draft.hideInvitationLink = false
        draft.communityInformations = null
        draft.loading = false
        break
      }

      case RESET_COMMUNITY_INFORMATIONS: {
        draft.hideInvitationLink = false
        draft.communityInformations = null
        draft.loading = false
        break
      }

      case GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST: {
        draft.loading = true
        break
      }

      case GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_SUCCESS: {
        draft.communityInformations = action.payload.communityInformations
        draft.hideInvitationLink = true
        draft.loading = false
        break
      }

      case GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_FAILURE: {
        draft.loading = false
        break
      }

      case SET_COMMUNITY_HAS_STRIPE_ACCOUNT: {
        draft.communityHasStripeAccount = action.payload
        break
      }

      case ADD_COMMUNITY_GALLERY_MEDIA_REQUEST: {
        const { uploadTypeName, image } = action.payload
        const isImage = uploadTypeName === 'image'
        const galleryArray = isImage ? 'photos' : 'videos'
        draft.currentCommunity.communityMedias[galleryArray] = [
          ...draft.currentCommunity.communityMedias[galleryArray],
          {
            loading: true,
            upload: {
              imageUrl: isImage ? URL.createObjectURL(image) : image,
            },
          },
        ]

        break
      }

      case ADD_COMMUNITY_GALLERY_MEDIA_SUCCESS: {
        const galleryArray = action.payload.type === 'image' ? 'photos' : 'videos'
        draft.currentCommunity.communityMedias[galleryArray][
          draft.currentCommunity.communityMedias[galleryArray].length - 1
        ] = action.payload.newMedia
        break
      }

      case ADD_COMMUNITY_GALLERY_MEDIA_FAILURE: {
        const galleryArray = action.payload.type === 'image' ? 'photos' : 'videos'
        draft.currentCommunity.communityMedias[galleryArray] = [
          ...draft.currentCommunity.communityMedias[galleryArray].slice(
            0,
            draft.currentCommunity.communityMedias[galleryArray].length - 1,
          ),
        ]
        break
      }

      case DELETE_COMMUNITY_GALLERY_MEDIA_REQUEST: {
        const { index, type } = action.payload
        const galleryArray = type === 'image' ? 'photos' : 'videos'
        draft.currentCommunity.communityMedias[galleryArray][index] = {
          ...draft.currentCommunity.communityMedias[galleryArray][index],
          loading: true,
        }

        break
      }

      case DELETE_COMMUNITY_GALLERY_MEDIA_SUCCESS: {
        const { type, communityMediaId } = action.payload
        const galleryArray = type === 'image' ? 'photos' : 'videos'
        const mediaIndex = draft.currentCommunity.communityMedias[galleryArray].findIndex(
          m => m.communityMediaId === communityMediaId,
        )
        draft.currentCommunity.communityMedias[galleryArray] = [
          ...draft.currentCommunity.communityMedias[galleryArray].slice(0, mediaIndex),
          ...draft.currentCommunity.communityMedias[galleryArray].slice(mediaIndex + 1),
        ]

        break
      }

      case DELETE_COMMUNITY_GALLERY_MEDIA_FAILURE: {
        const { index, type } = action.payload
        const galleryArray = type === 'image' ? 'photos' : 'videos'
        draft.currentCommunity.communityMedias[galleryArray][index] = {
          ...draft.currentCommunity.communityMedias[galleryArray][index],
          loading: false,
        }

        break
      }

      default:
    }
  })
}
