/* eslint-disable no-nested-ternary */
import styled from 'styled-components'

export const TextMessageContainer = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.0043em;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
`

export const MentionMessage = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.0043em;
  word-break: break-word;
  max-height: 98px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
`

export const Container = styled.div`
  background-color: ${props => (props.isReplyFromInput ? '#fafafa' : props.sentByMe ? '#f74657' : '#303030')};
  border-top-right-radius: ${props => (props.isVideoOrImage || props.isFile || props.isReply ? '0px' : '5px')};
  border-top-left-radius: ${props => (props.isVideoOrImage || props.isFile || props.isReply ? '0px' : '5px')};
  border-bottom-right-radius: ${props => (props.isVideoOrImage || props.isFile || props.isTextMessage ? '5px' : '0px')};
  border-bottom-left-radius: ${props => (props.isVideoOrImage || props.isFile || props.isTextMessage ? '5px' : '0px')};
  padding: ${props => (props.isReplyFromInput ? '0px' : '6px 8px')};
  margin-top: ${props => (props.isVideoOrImage || props.isHubContentMessage ? '-5px' : '0px')};
  margin-bottom: ${props => (props.isLinkMessage ? '-2px' : '0px')};
  width: 100% !important;

  cursor: ${props => (props.isVideoOrImage ? 'normal' : 'pointer')};

  ${TextMessageContainer} {
    color: ${props => (props.isReplyFromInput ? '#303030' : '#FFFF')};
  }
`

export const UrlText = styled(TextMessageContainer)`
  text-decoration: underline;
`
