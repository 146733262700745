import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Rating from '@material-ui/lab/Rating'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import WhiteButton from '~/components/WhiteButton'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    width: 50%;
    height: 50%;
    margin: 0;
    padding: 0;

    @media (min-width: 576px) {
      max-width: 850px;
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    p {
      margin: 0;
    }
  }

  .modal-content {
    padding-top: 200px;
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
`

export const InputLabel = styled.span`
  color: #47525e;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
`

export const RatingTextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .rate-message {
    color: #404257;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
  }
`

export const FormContainer = styled.div`
  .rate-notes {
    color: #404257;
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
  }

  .error-message {
    font-family: Roboto;
    color: #ea343e;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.9;
  }

  .MuiOutlinedInput-multiline {
    height: 130px !important;
  }
`

export const ModalTitle = styled.p`
  color: #404257;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  opacity: 0.9;
`

export const NotNow = styled.p`
  color: #969faa;
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

export const CommunityContainer = styled.div`
  display: flex;
  margin-left: 10px;
`

export const CommunityNameAndLocationSection = styled.div`
  .social-icons {
    border-radius: 2px;
    width: 21px;
    height: 21px;
    margin-top: 5px;
  }

  .social-icons-youtube {
    border-radius: 2px;
    width: 23px;
    height: 23px;
    margin-top: 5px;
  }

  button {
    border: none;
    background: transparent;

    + button {
      margin-left: 5px;
    }
  }
`

export const CommunityName = styled.span`
  display: inline-block;
  width: 250px;
  color: #47525e;
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
`

export const Subtitle = styled.p`
  text-align: right;
  margin: 0px;
  color: #635b5b;
  font-family: Lato;
  font-size: 17px;
`

export const CommunityLocation = styled.p`
  margin: 0px;
  color: #635b5b;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
`

export const ChartTitle = styled.p`
  margin: 0px;
  color: #484848;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  text-align: right;
`

export const CommunityInformationsContainer = styled.div`
  margin-top: 10px;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;

  .campaign-running-data-section {
    display: flex;
  }
`

export const RatingComponentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .dv-star-rating {
    font-size: 55px;
  }
`

export const EntityLogo = styled.img`
  border-radius: 100px;
  width: 89px;
  height: 89px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  object-fit: cover;
`

export const CommunityLogoAndLocationContainer = styled.div`
  display: flex;
`

export const DateContainer = styled.div``

export const CampaignBudgetContainer = styled.div``

export const RateCampaignText = styled.span`
  color: #404257;
  font-family: Lato;
  font-size: 19px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;
`

export const RateMessageText = styled.span`
  color: #404257;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between !important;
  align-items: baseline;
  margin-bottom: 4px;
  padding: 12px 24px !important;
`

export const SaveButton = styled(WhiteButton).attrs({
  form: 'my-form',
  type: 'submit',
})``

export const RatingCampaign = styled(Rating).attrs({
  size: 'large',
  name: 'customized-empty',
})``

export const Form = styled.form.attrs({
  id: 'my-form',
})``

export const FormRow = styled(Row)`
  display: flex;
  justify-content: center;
`

export const FormColumn = styled(Col).attrs({
  md: 6,
})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ShareText = styled.span`
  padding-bottom: 8px;
`
export const BorderContainer = styled.div`
  margin-top: 1.5rem;
`
