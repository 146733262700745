import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import { customMedia } from '~/styles/global'
import WhiteButton from '~/components/WhiteButton'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  display: flex;
  align-items: center;
  border: 1px solid rgba(214, 214, 214, 0.4);
  border-radius: 5px;
  height: 75px;
  padding: 10px 0;

  svg {
    cursor: pointer;
  }
`

export const ProductTitle = styled.p`
  margin: 0px;
  color: #969faa;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.9;
  letter-spacing: 0.5px;
`

export const SponsorName = styled.p`
  margin: 0px;
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.9;
  margin-left: 15px;
  letter-spacing: 0.5px;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

export const ProductSubtitle = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
`

export const NoRevenue = styled.p`
  margin: 0px;
  color: var(--error);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`

export const BudgetInvested = styled.p`
  margin: 0px;
  color: var(--success);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`

export const BudgetMonths = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
`

export const ActiveCampaigns = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
`

export const SwitchTitle = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  margin-left: 8px;
`

export const SponsorLogoContainer = styled.div`
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
`

export const SponsorLogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
`

export const SponsorInformationsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const NameColumn = styled(Col).attrs({
  md: 3,
})``

export const ActiveCampaignsColumn = styled(Col).attrs({
  md: 2,
})`
  text-align: center;
`

export const BudgetInvestedColumn = styled(Col).attrs({
  md: 2,
})``

export const CommunityProfileColumn = styled(Col).attrs({
  md: 3,
})`
  display: flex;
  justify-content: center;
`

export const CheckInfoButton = styled(WhiteButton).attrs({
  style: {
    backgroundColor: '#00A6FF',
    color: '#FFF',
    height: '42px',
    padding: '0px 10px',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    fontSize: '15px',
  },
})`
  ${customMedia.lessThan('1571px')`
  max-width: 9rem;
`}
`

export const StatusColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  justify-content: center;
`
