import React from 'react'
import { useTranslation } from 'react-i18next'
import communityDefaultBanner from '~/util/community-default-banner-picture.jpg'
import communityDefaultProfilePicture from '~/util/community-profile-default-picture.png'
import * as S from './styled'

export default function CommunityLoginCard({
  communityLogo,
  communityName,
  communityLocation,
  action,
  community: { totalCampaign, totalMembers },
}) {
  const { t } = useTranslation()

  return (
    <>
      <S.CardContainer>
        <S.CardContent>
          <S.CommunityBanner src={communityDefaultBanner} />
          <S.CardBody>
            <S.CommunityLogo src={communityLogo === null ? communityDefaultProfilePicture : communityLogo} />
            <S.TitleAndSubtitleContainer>
              <S.CardTitle>{communityName}</S.CardTitle>
              <S.CardSubtitle>{communityLocation}</S.CardSubtitle>
            </S.TitleAndSubtitleContainer>
            <S.CardInformationSection>
              <S.MembersContainer>
                <S.TotalMembersValue>{totalMembers !== null ? totalMembers : 0}</S.TotalMembersValue>
                <S.MembersTitle>{t('members')}</S.MembersTitle>
              </S.MembersContainer>
              <S.CampaignsContainer>
                <S.TotalCampaignValue>{totalCampaign !== null ? totalCampaign : 0}</S.TotalCampaignValue>
                <S.TotalCampaignTitle>{t('campaigns')}</S.TotalCampaignTitle>
              </S.CampaignsContainer>
            </S.CardInformationSection>

            <S.SelectButtonContainer>
              <S.SelectButton text={t('select')} action={action} />
            </S.SelectButtonContainer>
          </S.CardBody>
        </S.CardContent>
      </S.CardContainer>
    </>
  )
}
