import React from 'react'
import { useTranslation } from 'react-i18next'
import useNavigationFunctions from '~/helper/navigationFunctions'
import useTutorialFunctions from '~/helper/tutorialFunctions'
import * as S from './styled'

export default function FormatsTabTooltip({ closeProps, tooltipProps }) {
  const { t } = useTranslation()
  const { setCommunityCurrentTabAction } = useNavigationFunctions()
  const { setTutorialAsInativeAction } = useTutorialFunctions()

  const goToPackagesTab = () => {
    setCommunityCurrentTabAction('packages')
  }

  return (
    <S.ComponentContainer {...tooltipProps}>
      <S.TooltipArrow />
      <S.TooltipUserAvatar />
      <S.TooltipContainer>
        <S.CloseButtonContainer {...closeProps}>
          <S.CloseIcon onClick={() => setTutorialAsInativeAction()} />
        </S.CloseButtonContainer>
        <S.Title>{t('communityStep1TutorialTitle')}</S.Title>
        <S.Subtitle>{t('formatsTabTooltipSubtitle')}</S.Subtitle>
        <S.ButtonsContainer>
          <S.NextButton onClick={() => goToPackagesTab()}>
            <S.NextButtonText>{t('next')}</S.NextButtonText>
          </S.NextButton>
        </S.ButtonsContainer>
      </S.TooltipContainer>
    </S.ComponentContainer>
  )
}
