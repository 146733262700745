/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FaRegImage } from 'react-icons/fa'
import TextField from '@material-ui/core/TextField'
import Button from '~/components/Shared/Button'
import Border from '~/components/Shared/Border'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import ChatChannelVisualization from '~/components/CreateFormatMobileVisualization/ChatChannel'
import { InputTitle } from '~/components/Shared/StyledComponents'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import { UploadS3 } from '~/helper/formFunctions'
import { transformDateToUtcFormat } from '~/helper/getFormattedDates'

export default function ModalEditChatChannel({
  handleCloseModal,
  showModal,
  chatChannelToUpdate,
  review,
  createMessage,
  refuseCampaignProduct,
  approveCampaignProduct,
  formatId,
  sponsor,
}) {
  const { t } = useTranslation()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const { name, description, image, channelAccess, channelType, countdownChannel } = chatChannelToUpdate
  const [updatingImage, setUpdatingImage] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [imageId, setImageId] = useState(image)
  const [imageUrl, setImageUrl] = useState(chatChannelToUpdate.imageUrl)
  const [date, setDate] = useState(transformDateToUtcFormat(chatChannelToUpdate.date))
  const [endDate, setEndDate] = useState(transformDateToUtcFormat(chatChannelToUpdate.endDate))
  const { handleSubmit, register, setValue, control, errors, watch } = useForm({
    defaultValues: {
      channelAccess,
      channelType,
      countdownChannel: countdownChannel === true ? 'true' : 'false',
    },
  })
  const nameWatch = watch('name', chatChannelToUpdate.name)
  const { updateCampaignFormatAction } = useSponsorFunctions()

  function resetFormAndCloseModal() {
    setImageUrl(null)
    setImageId(null)
    setUpdatingImage(false)
    setEndDate('')
    setDate('')
    handleCloseModal()
  }

  function modalReviewCreateMessageAndCloseModal() {
    createMessage()
    refuseCampaignProduct()
    handleCloseModal()
  }

  function onSubmit(product) {
    const copyObject = JSON.stringify(chatChannelToUpdate)
    const productCopy = JSON.stringify(product)

    if (copyObject === productCopy) {
      return
    }
    updateCampaignFormatAction(product, formatId, resetFormAndCloseModal)
  }

  function handleUpdatingImage() {
    setUpdatingImage(!updatingImage)
  }

  useEffect(() => {
    register({ name: 'id' })
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    register({ name: 'channelAccess' })
    register({ name: 'channelType' })
    register({ name: 'countdownChannel' })
    setValue('channelAccess', 'public')
    setValue('channelType', 'manyToMany')
    setValue('countdownChannel', false)
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
    setValue('id', chatChannelToUpdate.id)
  }, [register, chatChannelToUpdate.id, setValue, imageId, imageUrl])

  return (
    <ProductsModal
      createMessage={modalReviewCreateMessageAndCloseModal}
      approveCampaignProduct={approveCampaignProduct}
      action={onSubmit}
      loadingUpload={loadingUpload}
      buttonText={review ? t('done') : t('update')}
      modalTitle={
        review ? t('reviseFormat', { format: t('chatChannel') }) : t('editFormat', { format: t('chatChannel') })
      }
      handleCloseModal={handleCloseModal}
      showModal={showModal}
      review={review}
      sponsor={sponsor}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <InputTitle>{t('name')}</InputTitle>
          <Controller
            as={
              <TextField
                fullWidth
                variant="outlined"
                placeholder={t('pleaseTypeTheChannelName')}
                disabled={review || false}
              />
            }
            rules={{ required: t('defaultValidation', { inputName: t('name') }) }}
            name="name"
            control={control}
            defaultValue={name === null ? '' : name}
          />
          <div className="error-message">
            <ErrorMessage errors={errors} name="name" as="p" />
          </div>

          <StartDateSection
            product={t('chatChannel')}
            selectedCampaignStartDate={date}
            setCampaignStartDate={setDate}
            campaignStartDate={currentCampaignForReviewPage.dtStart}
            campaignEndDate={currentCampaignForReviewPage.dtEnd}
            disabled={review}
            control={control}
            errors={errors}
          />

          <EndDateSection
            product={t('chatChannel')}
            selectedCampaignStartDate={date}
            selectedCampaignEndDate={endDate}
            campaignEndDate={currentCampaignForReviewPage.dtEnd}
            setCampaignEndDate={setEndDate}
            disabled={review}
            control={control}
            errors={errors}
          />

          <div className="mt-3">
            <InputTitle>{t('description')}</InputTitle>
            <Controller
              as={
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={t('pleaseTypeTheChannelDescription', { format: t('chatChannel').toLocaleLowerCase() })}
                  disabled={review || false}
                />
              }
              rules={{ required: t('defaultValidation', { inputName: t('description') }) }}
              name="description"
              control={control}
              defaultValue={description === null ? '' : description}
            />
            <div className="error-message">
              <ErrorMessage errors={errors} name="description" as="p" />
            </div>
          </div>

          <div className="mt-4 mb-3">
            <Border />
          </div>

          <div className="mt-3">
            {review === false ? (
              updatingImage ? (
                <div className="mt-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <FaRegImage className="mr-2" size={14} />
                    <InputTitle>{t('image')}</InputTitle>
                  </div>
                  <UploadS3
                    setUploadId={setImageId}
                    setUploadUrl={setImageUrl}
                    setLoadingUpload={setLoadingUpload}
                    fileType="image"
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <Button
                    text={t('chooseChannelPicture')}
                    type="button"
                    color="#5A6978"
                    action={handleUpdatingImage}
                    padding="0 10px"
                    width="auto"
                    height="40px"
                  />
                </div>
              )
            ) : null}
          </div>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          FormatVisualizationComponent={<ChatChannelVisualization image={imageUrl} chatChannelName={nameWatch} />}
        />
      }
    />
  )
}
