import React from 'react'
import CommunityContainerTab from '~/components/Community/CommunityContainerTab'
import SponsorContainerTab from '~/components/Sponsor/SponsorContainerTab'
import * as S from './styled'

export default function GroupTabs({ tabs, tabsColor, currentTabActive, setCurrentTabActive, sponsor }) {
  return (
    <S.ContainerTabs sponsor={sponsor}>
      {tabs.map(tab =>
        sponsor ? (
          <SponsorContainerTab
            className={tab.className}
            key={tab.title}
            tabsColor={tabsColor}
            icon={tab.icon}
            image={tab.image}
            title={tab.title}
            spacing={tab.spacing}
            onClick={() => setCurrentTabActive(tab.slug)}
            isTabActive={currentTabActive === tab.slug || false}
          />
        ) : (
          <CommunityContainerTab
            className={tab.className}
            key={tab.title}
            tabsColor={tabsColor}
            icon={tab.icon}
            image={tab.image}
            title={tab.title}
            spacing={tab.spacing}
            onClick={() => setCurrentTabActive(tab.slug)}
            isTabActive={currentTabActive === tab.slug || false}
          />
        ),
      )}
    </S.ContainerTabs>
  )
}
