/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { toast } from 'react-toastify'
import { IoIosImages } from 'react-icons/io'
import { FaRegImage } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import Border from '~/components/Shared/Border'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import { InputTitle, FormatUploadButton } from '~/components/Shared/StyledComponents'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import { UploadS3 } from '~/helper/formFunctions'
import { transformDateToUtcFormat } from '~/helper/getFormattedDates'

import * as S from './styled'

export default function ModalEditWelcomeAd({
  handleCloseModal,
  showModal,
  welcomeAdToUpdate,
  review,
  createMessage,
  refuseCampaignProduct,
  approveCampaignProduct,
  formatId,
  sponsor,
}) {
  const { t } = useTranslation()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const { image, name, link } = welcomeAdToUpdate
  const [updatingImage, setUpdatingImage] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [imageId, setImageId] = useState(image)
  const [imageUrl, setImageUrl] = useState(welcomeAdToUpdate.imageUrl)
  const [date, setDate] = useState(transformDateToUtcFormat(welcomeAdToUpdate.date))
  const [endDate, setEndDate] = useState(transformDateToUtcFormat(welcomeAdToUpdate.endDate))
  const { handleSubmit, register, setValue, control, errors } = useForm()
  const { updateCampaignFormatAction } = useSponsorFunctions()

  function modalReviewCreateMessageAndCloseModal() {
    createMessage()
    refuseCampaignProduct()
    handleCloseModal()
  }

  function onSubmit(format) {
    if (format.imageUrl == null) {
      return toast.error(t('defaultValidation', { inputName: t('image') }))
    }
    updateCampaignFormatAction(format, formatId, handleCloseModal)
  }

  function handleCloseModalAndResetImageInputs() {
    setUpdatingImage(false)
    handleCloseModal()
  }

  function handleUpdatingImage() {
    setUpdatingImage(!updatingImage)
  }

  useEffect(() => {
    register({ name: 'id' })
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
    setValue('id', welcomeAdToUpdate.id)
  }, [register, setValue, welcomeAdToUpdate.id, imageId, imageUrl])

  return (
    <ProductsModal
      createMessage={modalReviewCreateMessageAndCloseModal}
      approveCampaignProduct={approveCampaignProduct}
      action={onSubmit}
      loadingUpload={loadingUpload}
      buttonText={review ? t('done') : t('update')}
      modalTitle={review ? t('reviseFormat', { format: t('welcomeAd') }) : t('editFormat', { format: t('welcomeAd') })}
      handleCloseModal={handleCloseModalAndResetImageInputs}
      showModal={showModal}
      review={review}
      sponsor={sponsor}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <div className="mt-3">
              <InputTitle>{t('name')}</InputTitle>
              <Controller
                as={<TextField fullWidth variant="outlined" placeholder={t('typeName')} disabled={review || false} />}
                rules={{ required: t('defaultValidation', { inputName: t('name') }) }}
                name="name"
                control={control}
                defaultValue={name === null ? '' : name}
              />
            </div>
            <div className="error-message">
              <ErrorMessage errors={errors} name="name" as="p" />
            </div>

            <StartDateSection
              product={t('welcomeAd')}
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              disabled={review}
              control={control}
              errors={errors}
            />
            <EndDateSection
              product={t('welcomeAd')}
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              disabled={review}
              control={control}
              errors={errors}
            />

            <div className="mt-3">
              <InputTitle>{t('link')}</InputTitle>
              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="e.g. https://www.greatecontent.com/article10"
                    disabled={review || false}
                  />
                }
                rules={{ required: t('defaultValidation', { inputName: t('link') }) }}
                name="link"
                control={control}
                defaultValue={link === null ? '' : link}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="link" as="p" />
              </div>
            </div>

            <div className="mt-4 mb-2">
              <Border />
            </div>

            {review === false ? (
              updatingImage ? (
                <div className="mt-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <FaRegImage className="mr-2" size={14} />
                    <InputTitle>{t('image')}</InputTitle>
                  </div>
                  <UploadS3
                    setUploadId={setImageId}
                    setUploadUrl={setImageUrl}
                    setLoadingUpload={setLoadingUpload}
                    fileType="image"
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <FormatUploadButton text={t('addImage')} action={handleUpdatingImage} />
                </div>
              )
            ) : null}
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <S.Container>
          <S.DeviceButton />
          <S.DeviceScreen>
            <div className="image-container">
              {imageUrl !== null ? (
                <div className="image-container">
                  <img className="animated fadeIn" src={imageUrl} alt="" />
                  <S.SkipAdd>{t('skipAdd')}</S.SkipAdd>
                </div>
              ) : (
                <>
                  <img
                    style={{
                      backgroundColor: '#CCC',
                    }}
                    alt=""
                    className="d-flex justify-content-center align-items"
                  />
                  <IoIosImages
                    style={{
                      position: 'absolute',
                      top: '-9999px',
                      bottom: '-9999px',
                      left: '-9999px',
                      right: '-9999px',
                      margin: 'auto',
                    }}
                    color="red"
                    size={28}
                  />
                </>
              )}
            </div>
          </S.DeviceScreen>
        </S.Container>
      }
    />
  )
}
