import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ProfileButton from '~/components/ProfileButton'
import Border from '~/components/Shared/Border'
import ModalEditSponsor from '~/components/Sponsor/Modals/ModalEditSponsor'
import ModalCropProfileImage from '~/components/Sponsor/Modals/ModalCropProfileImage'
import ModalCropBannerImage from '~/components/Sponsor/Modals/ModalCropBannerImage'
import { DefaultLogoImage, DefaultBannerImage } from '~/util/defaultImages'
import SocialMediaButtons from '~/components/SocialMediaButtons'
import * as S from './styled'

export default function SponsorProfile({ sponsorInformations, showModalEditSponsor, setShowModalEditSponsor }) {
  const { t } = useTranslation()
  const {
    logoAdvPlatServerPath,
    name,
    totalCampaign,
    facebook,
    website,
    twitter,
    youtube,
    adrCity,
    adrState,
  } = sponsorInformations

  const [showModalCropProfileImage, setShowModalCropProfileImage] = useState(false)
  const [showModalCropBannerImage, setShowModalCropBannerImage] = useState(false)
  const [stepImage, setStepImage] = useState(0)

  const setModalStepToZero = () => {
    setStepImage(0)
    setShowModalCropProfileImage(false)
    setShowModalCropBannerImage(false)
    setShowModalEditSponsor(true)
  }

  const setModalStepToOne = () => {
    setStepImage(1)
    setShowModalEditSponsor(false)
  }

  const setModalStepToTwo = () => {
    setStepImage(2)
    setShowModalEditSponsor(false)
  }

  useEffect(() => {
    function getStepModalForCropImage() {
      if (stepImage === 1) {
        setShowModalEditSponsor(false)
        setShowModalCropProfileImage(true)
      }
      if (stepImage === 2) {
        setShowModalEditSponsor(false)
        setShowModalCropBannerImage(true)
      }
    }
    getStepModalForCropImage()
  }, [setShowModalEditSponsor, stepImage])

  return (
    <S.Container>
      <S.ImageContainer>
        <S.BannerImage src={DefaultBannerImage} />
        <S.LogoImage
          src={
            logoAdvPlatServerPath === null || logoAdvPlatServerPath === undefined
              ? DefaultLogoImage
              : logoAdvPlatServerPath
          }
        />
        <S.ButtonSection>
          <S.EditButtonContainer data-cy="edit-profile-button">
            <ProfileButton backgroundColor="#00A2FF" onClick={() => setShowModalEditSponsor(true)} />
          </S.EditButtonContainer>
        </S.ButtonSection>
      </S.ImageContainer>

      <S.SponsorInformationSection>
        <S.SponsorNameAndRating>
          <S.SponsorName>{name}</S.SponsorName>
        </S.SponsorNameAndRating>
        <S.SponsorLocationAndRating>
          {(adrCity || adrState) && (
            <S.SponsorLocation>
              {`${adrCity ?? ''}${adrCity && adrState ? ',' : ''} ${adrState ?? ''}`}
            </S.SponsorLocation>
          )}
        </S.SponsorLocationAndRating>
        <S.BorderContainer top>
          <Border />
        </S.BorderContainer>
        <S.CampaignSection>
          <S.CampaignViewsContainer>
            <S.TotalCampaigns>{totalCampaign === null ? 0 : totalCampaign}</S.TotalCampaigns>
            <S.CampaignLabel>{t('campaigns')}</S.CampaignLabel>
          </S.CampaignViewsContainer>
          <S.CampaignViewsContainer>
            <S.TotalViews>0</S.TotalViews>
            <S.CampaignLabel>{t('formatViews')}</S.CampaignLabel>
          </S.CampaignViewsContainer>
        </S.CampaignSection>

        <S.SocialMediaContainer>
          {twitter != null || facebook != null || website != null || youtube != null ? (
            <S.BorderContainer>
              <Border />
            </S.BorderContainer>
          ) : null}
          <SocialMediaButtons youtube={youtube} facebook={facebook} twitter={twitter} website={website} />
        </S.SocialMediaContainer>
        <S.BorderContainer>
          <Border />
        </S.BorderContainer>
      </S.SponsorInformationSection>

      <ModalCropProfileImage
        setModalStepToZero={setModalStepToZero}
        sponsorInformations={sponsorInformations}
        handleCloseModal={() => setShowModalCropProfileImage(false)}
        showModal={showModalCropProfileImage}
      />

      <ModalCropBannerImage
        setModalStepToZero={setModalStepToZero}
        sponsorInformations={sponsorInformations}
        handleCloseModal={() => setShowModalCropBannerImage(false)}
        showModal={showModalCropBannerImage}
      />

      {sponsorInformations && (
        <ModalEditSponsor
          handleCloseModal={() => setShowModalEditSponsor(false)}
          showModal={showModalEditSponsor}
          sponsorInformations={sponsorInformations}
          setModalStepToOne={setModalStepToOne}
          setModalStepToTwo={setModalStepToTwo}
        />
      )}
    </S.Container>
  )
}
