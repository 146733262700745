import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, ErrorMessage, Controller } from 'react-hook-form'
import { InputAdornment } from '@material-ui/core'
import Spinner from 'react-bootstrap/Spinner'
import { FaRegCheckCircle } from 'react-icons/fa'
import TextField from '@material-ui/core/TextField'
import { recoverUserPasswordRequest as recoverUserPasswordRequestAction } from '~/store/modules/user/actions'
import { maskEmail } from '~/helper/formFunctions'
import * as S from './styled'

export default function ModalResetPassword({ handleCloseModal, showModal, sponsor }) {
  const { t } = useTranslation()
  const recoveredPassword = useSelector(state => state.user.recoveredPassword)
  const loading = useSelector(state => state.user.loadingPassword)
  const { handleSubmit, errors, control, watch } = useForm()
  const emailWatch = watch('email', '')
  const dispatch = useDispatch()

  function closeModal() {
    handleCloseModal()
  }

  function onSubmit(data) {
    dispatch(recoverUserPasswordRequestAction(data.email))
  }

  return (
    <S.ModalCustom show={showModal} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.Title>{t('resetYourPassword')}</S.Title>
          <S.Subtitle>{t('emailTemporaryPassword')}</S.Subtitle>
        </Modal.Title>
      </Modal.Header>

      {recoveredPassword === false ? (
        <Modal.Body>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              as={
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={t('typeYourEmail')}
                  id="recover-password-field"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {loading ? <Spinner animation="border" size="sm" /> : ''}
                      </InputAdornment>
                    ),
                  }}
                />
              }
              name="email"
              rules={{ required: t('defaultValidation', { inputName: t('email') }) }}
              control={control}
            />
            <S.ErrorMessageContainer>
              <ErrorMessage errors={errors} name="email" as="p" />
            </S.ErrorMessageContainer>

            <S.ButtonContainer>
              <S.ResetPasswordButton text={t('resetPassword')} sponsor={sponsor} onClick={() => onSubmit} />
              <S.ReturnLoginContainer sponsor={sponsor} onClick={closeModal}>
                <S.ReturnLoginText>{t('returnToLogin')}</S.ReturnLoginText>
              </S.ReturnLoginContainer>
            </S.ButtonContainer>
          </S.Form>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <S.SuccessSectionContainer>
            <FaRegCheckCircle color="black" size={22} />
            {emailWatch !== '' ? (
              <S.SuccessTextContainer>
                <S.SuccessText>{t('success')}</S.SuccessText>
                <S.SuccessMessageText>
                  {`We've sent an email to ${maskEmail(emailWatch)} with password reset instructions.`}
                </S.SuccessMessageText>
              </S.SuccessTextContainer>
            ) : null}
          </S.SuccessSectionContainer>
          <S.EmailAlert>{t('checkSpamFolder')}</S.EmailAlert>
          <S.ButtonContainer>
            <S.ReturnToLoginButton text={t('returnToLogin')} onClick={closeModal} />
          </S.ButtonContainer>
        </Modal.Body>
      )}
    </S.ModalCustom>
  )
}
