import styled from 'styled-components'
import { breakpoints } from '~/helper/breakpoints'

export const Card = styled.div`
  display: flex;
  padding: 1.2rem;
  align-items: center;
  border-radius: 20px;
  border: 1px rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(25px);

  text-decoration-line: none !important;
  text-align: start !important;

  cursor: pointer;

  p {
    color: #7e7f86;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
  }

  .leftIcon {
    width: 43;
    height: fit-content;
  }

  gap: 1rem;

  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }

  max-width: 31rem;

  @media ${breakpoints.mobile} {
    max-width: 31.3rem;

    p {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .leftIcon {
      width: 31;
    }
  }
`
