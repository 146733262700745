import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { DefaultLogoImage } from '~/util/defaultImages'
import { currencyFormat } from '~/helper/helperFunctions'
import { CustomSwitch } from '~/components/Shared/StyledComponents'
import useGetCommunityCurrencySymbol from '~/hooks/useGetCommunityCurrencySymbol'
import ModalPreviewProfileVisualization from '~/components/Community/Modals/ModalPreviewProfileVisualization'
import * as S from './styled'

export default function CommunityTabCard({ community }) {
  const { t } = useTranslation()
  const { communityName, communityLogo, activeCampaigns, cumulatedRevenue, status, currency } = community
  const communityCurrencySymbol = useGetCommunityCurrencySymbol(currency)
  const [showModalPreviewVisualization, setShowModalPreviewVisualization] = useState(false)

  const switchChange = () => {
    toast.warning(t('onlyCommunityCanChangeCooperation'))
  }

  return (
    <S.Container>
      <S.NameColumn>
        <S.SponsorInformationsContainer>
          <S.SponsorLogoContainer>
            <S.SponsorLogoImage src={communityLogo === null ? DefaultLogoImage : communityLogo} />
          </S.SponsorLogoContainer>
          <S.SponsorName>{communityName}</S.SponsorName>
        </S.SponsorInformationsContainer>
      </S.NameColumn>
      <S.ActiveCampaignsColumn>
        {activeCampaigns === 0 && <S.ProductSubtitle>{t('noActiveCampaign')}</S.ProductSubtitle>}
        {activeCampaigns > 0 && <S.ActiveCampaigns>{activeCampaigns}</S.ActiveCampaigns>}
      </S.ActiveCampaignsColumn>
      <S.BudgetInvestedColumn>
        {cumulatedRevenue === null && <S.NoRevenue>{t('noRevenue')}</S.NoRevenue>}
        {cumulatedRevenue && (
          <S.BudgetInvested>{`${communityCurrencySymbol} ${currencyFormat(cumulatedRevenue)}`}</S.BudgetInvested>
        )}
      </S.BudgetInvestedColumn>
      <S.CommunityProfileColumn>
        <S.CheckInfoButton noShadow text={t('checkInfo')} onClick={() => setShowModalPreviewVisualization(true)} />
      </S.CommunityProfileColumn>
      <S.StatusColumn>
        <CustomSwitch checked={status} onChange={switchChange} />
        {status ? <S.SwitchTitle>{t('on')}</S.SwitchTitle> : <S.SwitchTitle>{t('off')}</S.SwitchTitle>}
      </S.StatusColumn>

      {showModalPreviewVisualization && (
        <ModalPreviewProfileVisualization
          handleCloseModal={() => setShowModalPreviewVisualization(false)}
          showModal={showModalPreviewVisualization}
          community={community}
          previewFromSponsor
        />
      )}
    </S.Container>
  )
}
