import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalRatingCampaignMocked from '~/components/Modals/ModalRatingCampaignMocked'
import { formatDate } from '~/helper/getFormattedDates'
import { DefaultLogoImage } from '~/util/defaultImages'
import { getCampaignCurrency, currencyFormat } from '~/helper/helperFunctions'
import useCampaignFunctions from '~/helper/campaignFunctions'
import ModalAnalytics from '~/components/Modals/ModalAnalytics'
import * as S from './styled'

export default function SponsorCampaignTabCard({ campaign, status }) {
  const { t } = useTranslation()
  const {
    community: { fullName: communityName, networkLogo: communityLogo },
    name: campaignName,
    budget: campaignBudget,
    dtStart: campaignStartDate,
    dtEnd: campaignEndDate,
    isPackage,
    currency,
  } = campaign

  const { setCurrentCampaignForReviewPageAction, setShowCampaignReviewPageAction } = useCampaignFunctions()
  const [showModalRating, ,] = useState(false)
  const [showModalAnalytics, setShowModalAnalytics] = useState(false)

  function goToCommunityHomePage() {
    setShowCampaignReviewPageAction(true)
  }

  const setCampaignForReviewPageAndNavigateToIt = () => {
    setCurrentCampaignForReviewPageAction(campaign)
    goToCommunityHomePage()
  }

  const handleClickOpenModalCampaign = () => {
    setShowModalAnalytics(true)
  }

  return (
    <S.Container>
      <S.CommunityColumn>
        <S.SponsorInformationsContainer>
          <S.SponsorLogoContainer>
            {communityLogo == null && <S.SponsorLogoImage src={DefaultLogoImage} />}
            {communityLogo !== null && <S.SponsorLogoImage src={communityLogo} />}
          </S.SponsorLogoContainer>
          <S.CommunityAndCampaignNameContainer>
            {campaignName ? <S.CampaignName>{campaignName}</S.CampaignName> : <S.CampaignName>-</S.CampaignName>}
            <S.CommunityName>{communityName}</S.CommunityName>
          </S.CommunityAndCampaignNameContainer>
        </S.SponsorInformationsContainer>
        {status === 'waiting-approval' && <S.ProductDotIndicator />}
      </S.CommunityColumn>
      <S.CampaignTypeColumn>
        <S.ProductSubtitle>{isPackage === null ? t('payAsYouGo') : t('byPackage')}</S.ProductSubtitle>
      </S.CampaignTypeColumn>
      <S.TotalBudgetColumn>
        <S.TotalBudget>{`${getCampaignCurrency(currency)} ${currencyFormat(campaignBudget)}`}</S.TotalBudget>
      </S.TotalBudgetColumn>
      <S.CampaignScheduleColumn>
        <S.DateContainer>
          <S.DateLabel>{t('start')}</S.DateLabel>
          <S.Date>{formatDate(campaignStartDate)}</S.Date>
        </S.DateContainer>
        <S.DateContainer>
          <S.DateLabel>{t('end')}</S.DateLabel>
          <S.Date>{formatDate(campaignEndDate)}</S.Date>
        </S.DateContainer>
      </S.CampaignScheduleColumn>
      <S.ActionColumn>
        {/* We dont have these features yet */}
        {/* {revised && (
          <WhiteButton
            style={{ marginRight: '20px' }}
            text="Review"
            icon={<RiFilter2Line color="#966dcc" size={20} />}
          />
        )}
        {stop && (
          <WhiteButton
            style={{ marginRight: '20px' }}
            text="Stop it"
            icon={<MdStop color="#f64859" size={20} />}
          />
        )}
        {resume && (
          <WhiteButton
            style={{ marginRight: '20px' }}
            text="Resume"
            icon={<MdPlayArrow color="#4b9c36" size={20} />}
          />
        )}
        {rate && (
          <WhiteButton
            style={{ marginRight: '20px' }}
            text="Rate result"
            icon={<MdStar color="#ea9738" size={20} />}
          />
        )}
        {manage && (
          <WhiteButton
            style={{ marginRight: '20px' }}
            text="Manage"
            icon={<MdEdit color="#0041C2" size={20} />}
          />
        )} */}
        <S.ManageButton text={t('manage')} onClick={setCampaignForReviewPageAndNavigateToIt} icon={<S.ManageIcon />} />
        <S.AnalyticsButton text={t('analytics')} onClick={handleClickOpenModalCampaign} icon={<S.AnalyticsIcon />} />
      </S.ActionColumn>

      <ModalRatingCampaignMocked handleCloseModal={() => setShowModalAnalytics(false)} showModal={showModalRating} />
      <ModalAnalytics
        handleCloseModal={() => setShowModalAnalytics(false)}
        showModal={showModalAnalytics}
        campaign={campaign}
      />
    </S.Container>
  )
}
