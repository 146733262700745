import styled from 'styled-components'
import { breakpoints } from '~/helper/breakpoints'

export const Container = styled.div`
  margin-top: 8rem;
  width: 100vw;
  padding: 0rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    max-width: 1200px;
    color: #18181a;
    text-align: center;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.16;
  }

  @media ${breakpoints.tablet} {
    padding: 0rem 1.25rem;
    margin-top: 4rem;
    margin-bottom: 0rem;

    h1 {
      font-size: 1.5rem;
      line-height: 116%;
    }
  }
`

export const CardsContainer = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media ${breakpoints.tablet} {
    display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    padding: 1rem;

    height: 100%;

    & > * {
      flex: 0 0 auto;
    }
  }
`

export const Row = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 96rem;
  justify-content: center;

  p {
    color: #7e7f86;
    font-size: 1.125rem;
    font-weight: 400;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: 0rem;
    width: 100%;
    grid-template-columns: repeat(3, 19rem);
    justify-content: flex-start;

    h3 {
      color: #2d2d32;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }

    p {
      color: #7e7f86;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
    }

    .figure-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
