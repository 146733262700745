import React from 'react'

import { ResponsiveAnimation } from '~/components/ResposiveAnimation'
import Figure from '../../Components/Figure'

import * as S from './styles'

export const Images = () => {
  return (
    <S.Container className="abilitya">
      <S.DesktopContainer>
        <ResponsiveAnimation type="fade" applyOn="desktop" effects={{ direction: 'left', delay: 100 }}>
          <S.ImageContainer>
            <Figure className="desktop-image" src="/images/large-computer.png" />
          </S.ImageContainer>
        </ResponsiveAnimation>
      </S.DesktopContainer>

      <S.MobileContainer>
        <ResponsiveAnimation type="fade" applyOn="mobile" effects={{ direction: 'left', delay: 100 }}>
          <S.ImageContainer>
            <S.TextContainer>
              <Figure src="/images/community-icon.svg" width={32} height={32} />

              <h2>Community</h2>
            </S.TextContainer>
            <Figure className="desktop-image" src="/images/notebook1.svg" />
          </S.ImageContainer>
        </ResponsiveAnimation>

        <ResponsiveAnimation type="fade" applyOn="mobile" effects={{ direction: 'right', delay: 100 }}>
          <S.ImageContainer>
            <S.TextContainer>
              <Figure src="/images/sponsor-icon2.svg" width={32} height={32} />

              <h2>Sponsor</h2>
            </S.TextContainer>
            <Figure className="desktop-image" src="/images/notebook2.svg" />
          </S.ImageContainer>
        </ResponsiveAnimation>
      </S.MobileContainer>
    </S.Container>
  )
}
