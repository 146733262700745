import styled from 'styled-components'
import { breakpoints } from '~/helper/breakpoints'

export const Container = styled.div`
  margin-top: 10rem;
  width: 100vw;
  padding: 0rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: #18181a;
    text-align: center;
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.16;
  }

  h2 {
    color: #7e7f86;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media ${breakpoints.mobile} {
    padding: 0rem 1.25rem;
    margin-top: 8.5rem;

    h1 {
      font-size: 1.5rem;
      line-height: 116%;
    }

    h2 {
      font-size: 0.938rem;
      line-height: 19px;
    }
  }
`

export const MobileContainer = styled.div`
  display: none;
  @media ${breakpoints.mobile} {
    display: block;
  }
`

export const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media ${breakpoints.mobile} {
    display: none;
  }
`

export const CardsContainer = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5625rem;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const Row = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
  max-width: 68.5rem;

  h3 {
    margin-bottom: 1rem !important;
  }

  p {
    color: #7e7f86;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media ${breakpoints.mobile} {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      margin-bottom: 0rem !important;
    }

    p {
      color: #7e7f86;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`
