/* eslint-disable no-useless-escape */
import * as Yup from 'yup'
import i18n from '~/i18n'
import { urlRegex } from './utils'

const communityStoryValidator = Yup.object()
  .shape({
    title: Yup.string().required(i18n.t('defaultValidation', { inputName: i18n.t('title') })),
    description: Yup.string().required(i18n.t('defaultValidation', { inputName: i18n.t('description') })),
    endDate: Yup.string().required(i18n.t('defaultValidation', { inputName: i18n.t('endDate') })),
    date: Yup.string().required(i18n.t('defaultValidation', { inputName: i18n.t('startDate') })),
    link: Yup.string()
      .matches(urlRegex, i18n.t('linkIsNotValid'))
      .required(i18n.t('defaultValidation', { inputName: i18n.t('link') })),
  })
  .required()

export default communityStoryValidator
