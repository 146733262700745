import React from 'react'
import * as S from './styled'

export default function UploadButton({
  text,
  action,
  color,
  padding,
  type,
  height,
  fontSize,
  width,
  textColor,
  border,
  style,
  uppercase,
  bold,
  form,
}) {
  return (
    <S.Button
      fontSize={fontSize}
      bold={bold}
      form={form}
      uppercase={uppercase}
      style={style}
      border={border}
      textColor={textColor}
      color={color}
      padding={padding}
      height={height}
      width={width}
      type={type}
      onClick={action}
    >
      <span>{text}</span>
    </S.Button>
  )
}
