import React, { useMemo, useState } from 'react'
import idx from 'idx'
import Preloader from '~/components/Chat/Preloader'
import * as S from './styled'

const GifPreview = ({ gif }) => {
  const [loading, setLoading] = useState(true)

  const { uri } = useMemo(() => {
    const [firstMedia] = gif.media
    return {
      uri: idx(firstMedia, _ => _.tinygif.url),
      height: idx(firstMedia, _ => _.tinygif.dims[1]),
    }
  }, [gif.media])

  return (
    <S.GifContainer>
      <S.Gif
        src={uri}
        alt="Gif"
        onLoad={() => {
          setLoading(false)
        }}
      />
      {loading && <Preloader />}
    </S.GifContainer>
  )
}

export default GifPreview
