import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useGetUserDatadogInfo = () => {
  const currentCommunity = useSelector(state => state.community.currentCommunity)
  const communityManager = useSelector(state => state.community.communityManager)
  const sponsorManager = useSelector(state => state.sponsor.sponsorManager)

  const userType = useSelector(state => state.user.type)

  const isCommunity = userType === 'community'
  const isSponsor = userType === 'sponsor'

  const userInfo = useMemo(() => {
    if (isCommunity) {
      const communityUserInfo = {
        id: communityManager?.id?.toString(),
        name: communityManager?.firstName,
        email: communityManager?.email,
        networkId: currentCommunity?.id,
      }

      return communityUserInfo
    }

    if (isSponsor) {
      const sponsorUserInfo = {
        id: sponsorManager?.id?.toString(),
        name: sponsorManager?.firstName,
        email: sponsorManager?.email,
        networkId: sponsorManager?.companyId,
      }

      return sponsorUserInfo
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommunity, isSponsor, currentCommunity])

  return userInfo
}

export default useGetUserDatadogInfo
