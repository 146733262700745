import React from 'react'
import styled, { css } from 'styled-components'

const FigureContainer = styled.figure`
  display: inline-block;
  margin: 0;

  > img {
    ${({ width, height }) =>
      width &&
      height &&
      css`
        width: ${Number(width) / 16}rem;
        height: ${Number(height) / 16}rem;
      `}
  }
`

const Figure = ({ width, height, src, alt, ...rest }) => {
  return (
    <FigureContainer width={width} height={height} className="figure-container">
      <img src={src} alt={alt} {...rest} />
    </FigureContainer>
  )
}

Figure.defaultProps = {
  width: null,
  height: null,
  src: '',
  alt: '',
}

export default Figure
