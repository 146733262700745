import styled from 'styled-components'

export const FormContainer = styled.div`
  .input-date {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    height: 40px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(159, 159, 159, 0.7);
    border-radius: 5px;
    padding-left: 5px;
  }
`

export const CouponSection = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 15px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(159, 159, 159, 0.3);
  padding: 20px 0px;

  .subtitle {
    color: #404257;
    opacity: 0.6;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
`

export const InputTitle = styled.span`
  margin-top: 3px;
  color: #404257;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
`

export const ProductTitle = styled.span`
  margin-top: 3px;
  color: #fff;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
`

export const Optional = styled.span`
  color: #87919a;
  font-family: Lato;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  margin-left: 4px;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .image-container {
    width: 100%;
    height: 320px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .about-section {
    color: #fff;
    margin-left: 12px !important;
    font-family: Roboto;
    font-size: 12px;
    width: 260px;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
`
