/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import JoyRide from 'react-joyride'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ZeroRegisterIndicator from '~/components/ZeroRegisterIndicator'
import { DefaultLogoImage } from '~/util/defaultImages'
import SearchInputMocked from '~/components/SearchInputMocked'
import TabTitleAndSubtitle from '~/components/Community/TabTitleAndSubtitle'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import * as CONST from '~/helper/const'
import CampaignReviewPageTooltip from '~/components/ApplicationTutorial//Sponsor/CampaignReviewPageTooltip'
import { SponsorGridTitle } from '~/components/Shared/StyledComponents'
import * as S from './styled'

export default function CampaignReviewPageTutorialMocked() {
  const { t } = useTranslation()
  const tutorialIsActive = useSelector(state => state.tutorial.tutorialIsActive)
  const { setShowCampaignReviewPageMockedForTheTutorialAction } = useSponsorFunctions()

  return (
    <S.CustomContainer className="campaignMockedPage" fluid>
      <JoyRide
        steps={CONST.sponsorCampaignReviewPageTooltipOptions}
        run={tutorialIsActive}
        disableCloseOnEsc
        disableOverlayClose
        tooltipComponent={CampaignReviewPageTooltip}
        styles={{
          options: {
            arrowColor: 'transparent',
          },
        }}
      />
      <S.HeaderContainer>
        <S.HeaderBackContainer onClick={() => setShowCampaignReviewPageMockedForTheTutorialAction(false)}>
          <S.BackIcon />
          <S.HeaderBackText>{t('back')}</S.HeaderBackText>
        </S.HeaderBackContainer>
        <S.HeaderVerticalLine />
        <S.HeaderRouteText>{t('campaignManagementContentValidation')}</S.HeaderRouteText>
      </S.HeaderContainer>
      <S.CampaignRevisionContainer>
        <S.PageContainer>
          <S.CustomContainer fluid>
            <S.CommunityInformationsContainer>
              <S.CommunityInformations>
                <S.CommunityLogo src={DefaultLogoImage} />
                <S.CommunityContainer>
                  <S.CommunityNameAndLocationSection>
                    <S.CompanyName>Community</S.CompanyName>
                    <S.CommunityLocation>Rome, Italy</S.CommunityLocation>
                  </S.CommunityNameAndLocationSection>
                </S.CommunityContainer>
              </S.CommunityInformations>
              <S.CampaignContainer>
                <S.CampaignInformationTitle>{t('startDate')}</S.CampaignInformationTitle>
                <S.CampaignInformationSubtitle>23 Nov</S.CampaignInformationSubtitle>
              </S.CampaignContainer>
              <S.CampaignContainer>
                <S.CampaignInformationTitle>{t('endDate')}</S.CampaignInformationTitle>
                <S.CampaignInformationSubtitle>05 Dec</S.CampaignInformationSubtitle>
              </S.CampaignContainer>
              <S.CampaignBudgetContainer>
                <S.CampaignInformationTitle>{t('budgetUsed')}</S.CampaignInformationTitle>
                <S.CampaignInformationSubtitle>€ 14500.00</S.CampaignInformationSubtitle>
              </S.CampaignBudgetContainer>
              <S.CampaignBudgetContainer>
                <S.CampaignInformationTitle>{t('budgetAlreadyAlocated')}</S.CampaignInformationTitle>
                <S.CampaignInformationSubtitle>€ 0.00</S.CampaignInformationSubtitle>
              </S.CampaignBudgetContainer>
            </S.CommunityInformationsContainer>
          </S.CustomContainer>
          <S.BodyContainer>
            <TabTitleAndSubtitle sponsor title={t('campaignFormatsAvailable')} subtitle={t('manageTheFormats')} />
            <S.SearchInputContainer>
              <SearchInputMocked />
            </S.SearchInputContainer>
          </S.BodyContainer>
          <S.ProductsTabContainer>
            <S.GridTitleContainer>
              <S.FormatTitleColumn>
                <SponsorGridTitle>{t('formatTitle')}</SponsorGridTitle>
              </S.FormatTitleColumn>
              <S.FormatColumn>
                <SponsorGridTitle row>{t('format')}</SponsorGridTitle>
              </S.FormatColumn>
              <S.FormatTimingColumn>
                <SponsorGridTitle row>{t('formatScheduling')}</SponsorGridTitle>
              </S.FormatTimingColumn>
              <S.StatusColumn>
                <SponsorGridTitle>{t('status')}</SponsorGridTitle>
              </S.StatusColumn>
              <S.ActionsColumn>
                <SponsorGridTitle>{t('actions')}</SponsorGridTitle>
              </S.ActionsColumn>
            </S.GridTitleContainer>
            <ZeroRegisterIndicator text={t('noFormatsCreated')} />
          </S.ProductsTabContainer>
        </S.PageContainer>
      </S.CampaignRevisionContainer>
    </S.CustomContainer>
  )
}
