import { useEffect } from 'react'
import getEnv from '~/helper/getEnv'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import packageJson from '../../../package.json'
import useGetUserDatadogInfo from './useGetUserDatadogInfo'

const useDatadog = () => {
  const { version } = packageJson
  const env = getEnv()
  const userInfo = useGetUserDatadogInfo()

  const datadog = {
    applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    service: process.env.REACT_APP_DD_SERVICE_NAME,
  }

  useEffect(() => {
    if (userInfo) {
      return datadogRum.setUser(userInfo)
    }

    return () => datadogRum.removeUser()
  }, [userInfo])

  useEffect(() => {
    if (datadog) {
      datadogRum.init({
        site: 'datadoghq.eu',
        version,
        env,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        clientToken: datadog.clientToken,
        applicationId: datadog.applicationId,
        service: datadog.service,
      })

      // Send logs to datadog.
      datadogLogs.init({
        env,
        version,
        clientToken: datadog.clientToken,
        service: datadog.service,
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        // If datadog should forward logs from console.*:
        // forwardConsoleLogs: "all",
        sessionSampleRate: 100,
        sampleRate: 100,
        premiumSampleRate: 100,
      })

      datadogRum.startSessionReplayRecording()
    }
  }, [datadog, env, version])
}

export default useDatadog
