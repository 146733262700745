import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Button from '~/components/Shared/Button'

export const ModalCustom = styled(Modal).attrs({
  backdrop: 'static',
  keyboard: false,
})`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 610px;
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 100px;
    }
  }

  .modal-body {
    padding: 15px 25px 25px 25px !important;
    background-color: #f2f2f2;
  }

  .modal-content {
    width: 700px;
    padding-bottom: 10px;
    height: auto;
    margin: auto;
    border-radius: 0;
  }

  .error-message {
    font-family: Roboto;
    color: #ea343e;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.9;
  }
`

export const Title = styled.p`
  color: #404257;
  margin-bottom: 0px;
  margin-left: 5px !important;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  opacity: 0.9;
`

export const Subtitle = styled.p`
  margin-bottom: 0px;
  margin-top: 5px;
  line-height: 19px;
  opacity: 0.85;
  color: #404257;
  width: fit-content;
  margin-bottom: 12px;
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%;

  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
    width: 100%;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
`

export const Label = styled.div`
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  margin-bottom: 0px !important;
  text-align: center;
`

export const Form = styled.form.attrs({
  id: 'stripe',
})`
  margin-top: 8px;
`

export const ModalFooter = styled(Modal.Footer)`
  padding-top: 4px !important;
  padding-bottom: 4px !important;
`

export const SubmitButton = styled(Button).attrs({
  style: { marginTop: '12px', width: '120px' },
  type: 'submit',
  height: '40px',
  color: '#00A2FF',
})``

export const PoweredByStripe = styled.img.attrs({
  alt: 'Stripe Logo',
  src: '/powered-by-stripe.png',
})`
  margin-top: 10px;
  width: 300px;
  height: 50px;
  object-fit: cover;
`

export const PoweredByStripeContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  position: relative;
  text-align: center;
`

export const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 170px;
  background-image: linear-gradient(to right, #00a2ff 50%, #0090de 100%);
  position: relative;
  z-index: 10 !important;
  padding-top: 10px;
`

export const StepContainerTitle = styled.p`
  color: #ffffff;
  font-family: Montserrat;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
`

export const EmailInputContainer = styled.div`
  width: 78%;
  margin: 0 auto;

  .MuiInput-underline::before,
  .MuiInput-underline::after {
    display: none;
  }

  input {
    padding: 0.75rem;
    font-size: 0.93rem;
    background-color: #fff;
    border-radius: 5px;
    transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
    background: white;

    &::placeholder {
      color: #757680 !important;
    }
  }
`

export const StripeElementContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 16px;
  width: 100%;

  .MuiInput-underline::before,
  .MuiInput-underline::after {
    display: none;
  }

  .StripeElement {
    margin: 0 auto;
    width: 100% !important;
  }
`
