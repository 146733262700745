/* eslint-disable dot-notation */
import firebase from 'firebase'
import { toast } from 'react-toastify'
import { uniqBy } from 'lodash'
import { getFirestore } from '~/services/firebase'
import { messageType } from '~/helper/const'
import { isUrl, clean } from '~/helper/helperFunctions'
import { store } from '~/store'
import i18n from '~/i18n'

export const parseFirebaseRoute = (communityId, route) => {
  return `/networks/${communityId}/${route}`
}

export const channelsCollection = communityId => parseFirebaseRoute(communityId, 'channels')

export const messageCollection = communityId => parseFirebaseRoute(communityId, 'messages')

export const usersReadCollection = communityId => parseFirebaseRoute(communityId, 'users_read')

export const messageDoc = (communityId, messageId) => parseFirebaseRoute(communityId, `messages/${messageId}`)

export const formatChannelDescription = sponsorName => {
  return `Communication with ${sponsorName}`
}

export const createChannelId = (sponsorId, communityId) => {
  return `sponsor${sponsorId}_community${communityId}`
}

export const getAllMessagesFromChannel = (channelId, setMessages, communityId) => {
  try {
    if (communityId) {
      getFirestore()
        .collection(messageCollection(communityId))
        .where('channelId', '==', channelId)
        .orderBy('createdAt', 'asc')
        .onSnapshot(snapshot => {
          const messages = []
          snapshot.forEach(doc => {
            messages.push(doc.data())
          })
          getFirestore()
            .collection(messageCollection(communityId))
            .where('channelId', '==', channelId)
            .orderBy('createdAt', 'desc')
            .limit(1)
          setMessages(messages)
        })
    }
  } catch (err) {
    console.log('err:', err)
  }
}

export const getSpecificChannelIdWithCommunityId = (communityId, setChannelId, sponsorId) => {
  if (communityId !== undefined) {
    try {
      getFirestore()
        .collection(channelsCollection(communityId))
        .where('communityId', '==', communityId)
        .where('sponsorId', '==', sponsorId)
        .where('isSponsored', '==', false)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            setChannelId(doc.data().id)
          })
        })
    } catch (err) {
      toast.error(i18n.t('errorChannelInformations'))
    }
  }
}

export const getSpecificChannelWithCommunityId = (communityId, setChannelId, sponsorId) => {
  if (communityId !== undefined) {
    try {
      getFirestore()
        .collection(channelsCollection(communityId))
        .where('communityId', '==', communityId)
        .where('sponsorId', '==', sponsorId)
        .where('isSponsored', '==', false)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            setChannelId(doc.data())
          })
        })
    } catch (err) {
      toast.error(i18n.t('errorChannelInformations'))
    }
  }
}

export const createMessageBetweenCommunityAndCompany = (entity, message, channelId, communityId) => {
  try {
    const { id: userId, name: username, networkLogoPath: userPicture } = entity
    const document = getFirestore()
      .collection(messageCollection(communityId))
      .doc()
    const documentUuid = document.id
    const createdAt = new Date()
    getFirestore()
      .collection(messageCollection(communityId))
      .doc(documentUuid)
      .set({
        messageId: documentUuid,
        message,
        channelId,
        userId,
        userData: {
          name: username,
          pictureUrl: userPicture,
        },
        type: 'Message',
        tags: [],
        mentionedUsersIds: [],
        createdAt,
      })
  } catch (err) {
    console.log('err:', err)
  }
}

export const getCommunityNonSponsoredChannels = (communityId, setChannels, setLoadingSetChannels) => {
  const channels = []
  if (communityId) {
    try {
      getFirestore()
        .collection(channelsCollection(communityId))
        .where('communityId', '==', communityId)
        .where('isSponsored', '==', false)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            channels.push(doc.data())
          })
          setChannels(channels)
          setLoadingSetChannels(true)
        })
    } catch (err) {
      toast.error(i18n.t('errorChannelInformations'), err)
    }
  }
}

export const getSponsorNonSponsoredChannels = (companyId, setChannels, setLoadingSetChannels) => {
  const { allCommunitiesFromSponsor } = store.getState().sponsor
  const channels = []

  if (allCommunitiesFromSponsor) {
    allCommunitiesFromSponsor.forEach(community => {
      try {
        getFirestore()
          .collection(channelsCollection(community?.id))
          .where('sponsorId', '==', companyId)
          .where('isSponsored', '==', false)
          .onSnapshot(querySnapshot => {
            querySnapshot.forEach(doc => {
              channels.push(doc.data())
            })
            setChannels(channels)
            setLoadingSetChannels(true)
          })
      } catch (err) {
        toast.error(i18n.t('errorChannelInformations'), err)
      }
    })
  }
}

export const createMessageBetweenCompanyAndCommunity = (entity, message, channelId, communityId) => {
  try {
    const { id: userId, name: username, logoAdvPlatServerPath: userPicture } = entity
    const document = getFirestore()
      .collection(messageCollection(communityId))
      .doc()
    const documentUuid = document.id
    const createdAt = new Date()
    getFirestore()
      .collection(messageCollection(communityId))
      .doc(documentUuid)
      .set({
        messageId: documentUuid,
        message,
        channelId,
        userId,
        userData: {
          name: username,
          pictureUrl: userPicture,
        },
        type: 'Message',
        tags: [],
        mentionedUsersIds: [],
        createdAt,
      })
  } catch (err) {
    console.log('err:', err)
  }
}

// The users_read collection on the Firebase manages the unread messages on the chat channel
export const createOrUpdateUsersReadDocumentForTheCommunity = (communityId, channels) => {
  try {
    const documentId = `community_${communityId}`

    // Check if the document exists before create
    const docRef = getFirestore()
      .collection(usersReadCollection(communityId))
      .doc(documentId)

    docRef
      .get()
      .then(doc => {
        if (!doc.exists) {
          getFirestore()
            .collection(usersReadCollection(communityId))
            .doc(documentId)
            .set({})

          channels.forEach(channel => {
            getFirestore()
              .collection(usersReadCollection(communityId))
              .doc(documentId)
              .update({
                [channel.id]: channel.lastMessage === undefined ? null : channel.lastMessage.createdAt,
              })
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  } catch (err) {
    console.log(err)
  }
}

// The users_read collection on the Firebase manages the unread messages on the chat channel
export const createOrUpdateUsersReadDocumentForTheSponsor = (sponsorId, channels) => {
  try {
    const { allCommunitiesFromSponsor } = store.getState().sponsor
    const documentId = `sponsor_${sponsorId}`
    if (allCommunitiesFromSponsor) {
      allCommunitiesFromSponsor.forEach(community => {
        // Check if the document exists before create
        const docRef = getFirestore()
          .collection(usersReadCollection(community?.id))
          .doc(documentId)
        docRef
          .get()
          .then(doc => {
            if (!doc.exists) {
              getFirestore()
                .collection(usersReadCollection(community?.id))
                .doc(documentId)
                .set({})

              channels.forEach(channel => {
                getFirestore()
                  .collection(usersReadCollection(community?.id))
                  .doc(documentId)
                  .update({
                    [channel.id]: channel.lastMessage === undefined ? null : channel.lastMessage.createdAt,
                  })
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
      })
    }
  } catch (err) {
    console.log(err)
  }
}

export const formatAndReturnUsersReadDocumentId = (entityType, loggedUserId) => {
  if (entityType === 'Sponsor') {
    return `sponsor_${loggedUserId}`
  }
  return `community_${loggedUserId}`
}

export const updateUsersReadLastMessageForTheSpecificChatChannel = (
  loggedUserId,
  chatChannelId,
  entityType,
  communityId,
) => {
  let documentId
  if (entityType === 'Sponsor') {
    documentId = formatAndReturnUsersReadDocumentId('Sponsor', loggedUserId)
  }
  if (entityType === 'Community') {
    documentId = formatAndReturnUsersReadDocumentId('Community', loggedUserId)
  }
  getFirestore()
    .collection(usersReadCollection(communityId))
    .doc(documentId)
    .update({
      [chatChannelId]: new Date(),
    })
}

export const getUnreadMessagesLengthForChatChannelById = (
  loggedUserId,
  chatChannelId,
  entityIdWhoSentTheMessage,
  setNumberOfUnreadMessagesInTheChatChannel,
  entityType,
  communityId,
) => {
  try {
    let documentId
    if (entityType === 'Community') {
      documentId = formatAndReturnUsersReadDocumentId('Community', loggedUserId)
    }

    if (entityType === 'Sponsor') {
      documentId = formatAndReturnUsersReadDocumentId('Sponsor', loggedUserId)
    }

    const docRef = getFirestore()
      .collection(usersReadCollection(communityId))
      .doc(documentId)

    if (docRef && chatChannelId) {
      docRef
        .get()
        .then(doc => {
          const channelLastMessage = doc.data()[chatChannelId]

          if (channelLastMessage !== null && channelLastMessage !== undefined) {
            getFirestore()
              .collection(messageCollection(communityId))
              .where('channelId', '==', chatChannelId)
              .where('createdAt', '>', channelLastMessage)
              .onSnapshot(querySnapshot => {
                // Hack necessary because the firebase can't accept the "!=" operator
                const messages = []
                querySnapshot.forEach(doc => {
                  if (doc.data().userId !== loggedUserId) {
                    messages.push(doc.data())
                  }
                })
                setNumberOfUnreadMessagesInTheChatChannel(messages.length)
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  } catch (err) {
    console.log(err)
  }
}

export const getAllChannelsFromEntity = (
  entityType,
  entityId,
  setChannels,
  setSponsoredChannels,
  setLoadingChannels,
) => {
  if (entityId !== undefined) {
    try {
      if (entityType === 'Sponsor') {
        const { allCommunitiesFromSponsor } = store.getState().sponsor
        const channels = []
        const sponsoredChannels = []
        allCommunitiesFromSponsor.forEach(community => {
          getFirestore()
            .collection(channelsCollection(community?.id))
            .where('sponsorId', '==', entityId)
            .where('isSponsored', '==', false)
            .where('archived', '==', false)
            .onSnapshot(querySnapshot => {
              querySnapshot.forEach(doc => {
                channels.push(doc.data())
              })
              const uniqueChannels = uniqBy(channels, 'id')
              setChannels(uniqueChannels)
            })
          getFirestore()
            .collection(channelsCollection(community?.id))
            .where('sponsorId', '==', entityId)
            .where('isSponsored', '==', true)
            .where('archived', '==', false)
            .onSnapshot(querySnapshot => {
              querySnapshot.forEach(doc => {
                sponsoredChannels.push(doc.data())
              })
              const uniqueSponsoredChannels = uniqBy(sponsoredChannels, 'title')
              setSponsoredChannels(uniqueSponsoredChannels)
            })
        })
      }
      if (entityType === 'Community') {
        const channels = []
        const sponsoredChannels = []
        getFirestore()
          .collection(channelsCollection(entityId))
          .where('communityId', '==', entityId)
          .where('isSponsored', '==', false)
          .where('archived', '==', false)
          .onSnapshot(querySnapshot => {
            querySnapshot.forEach(doc => {
              channels.push(doc.data())
            })
            const uniqueChannels = uniqBy(channels, 'id')
            setChannels(uniqueChannels)
          })
        getFirestore()
          .collection(channelsCollection(entityId))
          .where('communityId', '==', entityId)
          .where('isSponsored', '==', true)
          .where('archived', '==', false)
          .onSnapshot(querySnapshot => {
            querySnapshot.forEach(doc => {
              if (doc.data().id) {
                sponsoredChannels.push(doc.data())
              }
            })
            const uniqueSponsoredChannels = uniqBy(sponsoredChannels, 'title')
            setSponsoredChannels(uniqueSponsoredChannels)
          })
      }
    } catch (err) {
      console.log('err:', err)
    }
    setTimeout(() => {
      setLoadingChannels(false)
    }, 1500)
  }
}

export const getSpecificChannelId = (communityId, setCommunitySponsorDirectChannelIdAction, sponsorId) => {
  if (communityId !== undefined) {
    try {
      getFirestore()
        .collection(channelsCollection(communityId))
        .where('communityId', '==', communityId)
        .where('sponsorId', '==', sponsorId)
        .where('isSponsored', '==', false)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            setCommunitySponsorDirectChannelIdAction(doc.data().id)
          })
        })
    } catch (err) {
      toast.error(i18n.t('errorChannelInformations'))
    }
  }
}

export const communitySendMessageToSponsor = (entity, message, channelId, communityId) => {
  try {
    const { fullName: username, networkLogo: userPicture } = entity
    const document = getFirestore()
      .collection(messageCollection(communityId))
      .doc()
    const documentUuid = document.id
    const createdAt = new Date()
    getFirestore()
      .collection(messageCollection(communityId))
      .doc(documentUuid)
      .set({
        messageId: documentUuid,
        message,
        channelId,
        userId: communityId,
        userData: {
          name: username,
          pictureUrl: userPicture,
        },
        type: 'Message',
        tags: [],
        mentionedUsersIds: [],
        createdAt,
      })
  } catch (err) {
    console.log(err)
  }
}

export const updateDoc = (path, payload) => {
  const firestore = getFirestore()
  const doc = firestore.doc(path)
  payload['updatedAt'] = firebase.firestore.FieldValue.serverTimestamp()
  return doc.update(payload)
}

export const parseReplyMessage = reply => {
  const getReplyImage = () => {
    const types = {
      [messageType.Gif]: reply.gif?.media?.[0]?.tinygif
        ? reply.gif?.media?.[0]?.tinygif?.url
        : reply?.gif?.media?.[0]?.nanogif?.url,
      [messageType.HubContent]: reply?.hubContentInformation?.cover_image_url,
      [messageType.Image]: reply?.images?.[0],
      [messageType.Video]: reply?.videos?.[1],
    }
    return types[reply?.type] || reply?.mediaPreview
  }
  return {
    replyMessage: reply?.message,
    replyImage: getReplyImage(),
    replyType: reply?.type,
    replyName: reply?.userData?.name,
    replyVideo: reply.type === messageType.Video ? reply?.videos?.[0] : null,
    replyData: reply?.createdAt?.seconds * 1000,
    replyAudio: reply?.type === messageType.Voice ? reply?.audio : null,
    replyFileName: reply?.type === messageType.File ? reply?.fileLocalName?.[0] : null,
    replyFileExtension: reply?.type === messageType.File ? reply?.fileExtensions?.[0] : null,
    replyFileUrl: reply?.type === messageType.File ? reply?.files?.[0] : null,
    replyFileSize: reply?.type === messageType.File ? reply?.fileSizes?.[0] : null,
    replyContentId: reply?.hubContentInformation?.content_id ?? null,
    replyStory: reply?.hubContentInformation?.content_type === 'story',
  }
}

export const sendMessageParse = ({
  channelId,
  message,
  audio,
  links,
  media,
  mediaFileType,
  reply,
  gif,
  interests,
  selectedInterests,
}) => {
  const hasLinks = isUrl(message)

  const mountSelectedTags = () => {
    const tags = interests?.filter(item => (selectedInterests || [])?.includes(item?.id)) || []
    const res = tags?.map(item => {
      return {
        interest_id: item.id,
        language: 'en',
        name: item.name,
      }
    })
    return res || []
  }

  const parsedMessage = {
    channelId,
    message: message ? message?.trim() : '',
    tags: mountSelectedTags() || [],
    type: hasLinks ? messageType.Link : messageType.Message,
    links: links || null,
    content: null,
    audio: audio?.url ? audio : null,
    reply: reply || null,
    gif: gif || null,
  }

  if (gif) {
    parsedMessage.type = messageType.Gif
  }

  if (audio?.url) {
    parsedMessage.type = messageType.Voice
  }

  // TODO refactor this to a more organized function
  if (media?.length > 0) {
    const mediaType = mediaFileType
    if (mediaFileType === 'Image') {
      parsedMessage['images'] = media
    }
    if (mediaFileType === 'Video') {
      parsedMessage['videos'] = media
    }
    if (mediaFileType === 'File') {
      parsedMessage['files'] = media
    }
    parsedMessage.type = mediaType
  }
  return clean(parsedMessage, { delete: true })
}
