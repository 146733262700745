/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import { useSelector } from 'react-redux'
import ShouldRender from '~/components/ShouldRender'
import AudioPlayer from '~/components/Chat/AudioPlayer'
import Interests from '~/components/Chat/Interests'
import Mention from '~/components/Chat/Mention'
import { mentionParser, getLastIndex, replaceAt } from '~/helper/helperFunctions'
import * as S from './styled'

export default function Input({
  message,
  setMessage,
  sendMessage,
  audio,
  setAudio,
  handleClickEmojiModal,
  setShowGifModal,
  addImage,
  addVideo,
  addFile,
  media,
  mediaFileType,
  isSendingMessage,
  showInterests,
  selectedInterests,
  setSelectedInterests,
  setShowInterests,
  interests,
  setShowMention,
  showMention,
  setShowChatSendingOptions,
  showChatSendingOptions,
}) {
  const { t } = useTranslation()
  const activeReplyMessage = useSelector(state => state.chat.activeReplyMessage)
  const { activeChannel } = useSelector(state => state.chat)
  const [recordState, setRecordState] = useState(null)
  const [isRecording, setIsRecording] = useState(false)
  const [selectedUsersFromMention, setSelectedUsersFromMention] = useState([])
  const [cursor, setCursor] = useState(0)
  const inputRef = useRef()

  const startRecording = () => {
    setRecordState(RecordState.START)
    setIsRecording(true)
  }

  const stopRecording = e => {
    e.preventDefault()
    setRecordState(RecordState.STOP)
    setIsRecording(false)
  }

  const onStop = audioData => {
    setAudio(audioData)
    setIsRecording(false)
  }

  const cancelRecord = () => {
    setAudio(null)
    setIsRecording(false)
    setMessage('')
  }

  const handleClickShowInterests = () => {
    if (showMention) {
      return null
    }
    return setShowInterests(!showInterests)
  }

  const openOptionAndThenClose = action => {
    action()
    setShowChatSendingOptions(false)
  }

  const clone = string => {
    return ` ${string}`.slice(1)
  }

  const renderSendButton = () => {
    if (
      (!isRecording && !isSendingMessage && !showInterests && message) ||
      (!isRecording && !isSendingMessage && !showInterests && mediaFileType) ||
      (!isRecording && !isSendingMessage && !showInterests && audio)
    ) {
      return true
    }
    return false
  }

  const onSelectMention = user => {
    setShowMention(false)
    let newMessage = clone(message)
    const indexLastAt = getLastIndex(newMessage, /\B\@/gim)
    const mention = `${user && user.title}`
    newMessage = replaceAt({
      index: indexLastAt > 0 ? indexLastAt : 0,
      message: newMessage,
      replacement: `@${mention} `,
      endsIndex: cursor < indexLastAt ? undefined : cursor,
    })
    setMessage(newMessage)
    setSelectedUsersFromMention([...selectedUsersFromMention, user])
    setShowMention(false)
  }

  const onPressMention = () => {
    inputRef.current.focus()
    if (!showMention) {
      setMessage(`${message?.length ? `${message} ` : ''}@`)
      setShowMention(true)
      setShowInterests(false)
    } else {
      setShowMention(false)
      if (message.slice(-2) === ' @') {
        return setMessage(message.slice(0, -2))
      }
      if (message.slice(-1) === '@') {
        setMessage(message.slice(0, -1))
      }
    }
    return null
  }

  const handleEnterKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      sendMessage(e)
    }
  }

  const setFormClass = () => {
    if (showInterests) {
      return 'interestsIsOpen'
    }
    return null
  }

  const setInputClass = () => {
    if ((!message && media?.length > 0) || (message && media?.length > 0)) {
      return 'inputWithMedia'
    }
    if (message && media?.length === 0) {
      return 'full'
    }
    return null
  }

  const handleChangeText = useCallback(e => {
    setCursor(inputRef.current.selectionStart)
    const { value } = e.target

    if (!value) {
      setShowMention(false)
    }
    const [penultimateLetter, lastLetter] = value.split('').slice(-2)
    const matchWithMention = (!penultimateLetter || penultimateLetter === ' ') && lastLetter === '@'
    const penultimateMatchWithMention = !lastLetter && penultimateLetter === '@'
    const isMention = matchWithMention || penultimateMatchWithMention || !!mentionParser(value, cursor)

    if (isMention && !activeChannel?.dm) {
      setShowMention(true)
      setShowInterests(false)
    }
    setMessage(value)
  }, [])

  useEffect(() => {
    if (!message) {
      setShowChatSendingOptions(false)
    }
  }, [message])

  return (
    <>
      <S.Form className={setFormClass()}>
        <ShouldRender if={message && !showChatSendingOptions && media?.length === 0}>
          <ShouldRender if={!isRecording && !audio && !showMention}>
            <S.OpenChatOptionsIcon onClick={() => setShowChatSendingOptions(true)} />
          </ShouldRender>
        </ShouldRender>

        <ShouldRender if={message && showChatSendingOptions}>
          <ShouldRender if={!isRecording && !audio}>
            <S.CloseChatOptionsIcon onClick={() => setShowChatSendingOptions(false)} />
          </ShouldRender>
        </ShouldRender>

        {message && media?.length > 0 && (
          <S.IconsContainer isVisible>
            <ShouldRender if={!isRecording && !audio}>
              <S.SelectInterestContainer disabled={showMention} onClick={handleClickShowInterests}>
                <ShouldRender if={!!selectedInterests && selectedInterests?.length}>
                  <S.SelectedCount>{selectedInterests && selectedInterests?.length}</S.SelectedCount>
                </ShouldRender>
                <S.InterestIcon />
              </S.SelectInterestContainer>
            </ShouldRender>
            <ShouldRender if={!isRecording && !audio}>
              <S.EmojiIcon onClick={handleClickEmojiModal} />
            </ShouldRender>
            <ShouldRender if={!isRecording && !audio}>
              <S.ImageIcon
                onClick={addImage}
                disabled={media?.length === 4 || mediaFileType === 'Video' || mediaFileType === 'File'}
              />
            </ShouldRender>
          </S.IconsContainer>
        )}

        <S.IconsContainer isVisible={!message}>
          {/* Interests */}
          <ShouldRender if={!showInterests}>
            <ShouldRender if={!isRecording && !audio}>
              <S.SelectInterestContainer disabled={showMention} onClick={handleClickShowInterests}>
                <ShouldRender if={!!selectedInterests && selectedInterests?.length}>
                  <S.SelectedCount>{selectedInterests && selectedInterests?.length}</S.SelectedCount>
                </ShouldRender>
                <S.InterestIcon />
              </S.SelectInterestContainer>
            </ShouldRender>

            {/* Emojis */}
            <ShouldRender if={!isRecording && !audio}>
              <S.EmojiIcon onClick={handleClickEmojiModal} />
            </ShouldRender>

            {/* File */}
            <ShouldRender if={!isRecording && !audio && !mediaFileType}>
              <S.FileIcon
                onClick={addFile}
                disabled={media?.length === 4 || mediaFileType === 'Video' || mediaFileType === 'File'}
              />
            </ShouldRender>

            {/* Images */}
            <ShouldRender if={!isRecording && !audio}>
              <S.ImageIcon
                onClick={addImage}
                disabled={media?.length === 4 || mediaFileType === 'Video' || mediaFileType === 'File'}
              />
            </ShouldRender>
          </ShouldRender>

          <ShouldRender if={!message && audio && (recordState === null || recordState === 'stop')}>
            <S.CancelRecordIcon onClick={cancelRecord} />
          </ShouldRender>

          <ShouldRender if={!message && recordState === 'start'}>
            <S.AudioStopIcon onClick={stopRecording} />
          </ShouldRender>

          <S.RecorderContainer isRecording={isRecording}>
            <AudioReactRecorder
              canvasWidth={200}
              canvasHeight={30}
              foregroundColor="#303030"
              backgroundColor="#fff"
              state={recordState}
              onStop={onStop}
            />
          </S.RecorderContainer>

          <ShouldRender if={audio}>
            <S.AudioPlayerContainer>
              <AudioPlayer audio={audio} width={240} />
            </S.AudioPlayerContainer>
          </ShouldRender>
        </S.IconsContainer>

        {/* Input */}
        <S.InputContainer>
          <ShouldRender if={!audio && !showInterests}>
            <ShouldRender if={!isRecording}>
              <input
                ref={inputRef}
                placeholder="Aa"
                type="text"
                value={message}
                onChange={handleChangeText}
                onKeyPress={handleEnterKeyPress}
                disabled={isSendingMessage || showInterests}
                className={setInputClass()}
              />
            </ShouldRender>

            {/* Gifs */}
            <ShouldRender if={!message && !isRecording && !audio && !mediaFileType && !showInterests}>
              <S.GifIcon onClick={() => setShowGifModal(prevState => !prevState)} />
            </ShouldRender>
          </ShouldRender>
        </S.InputContainer>

        {/* Send message */}
        <S.SendMessageContainer>
          <ShouldRender if={renderSendButton()}>
            <S.SendMessageIcon onClick={e => sendMessage(e)} message={message} />
          </ShouldRender>
        </S.SendMessageContainer>

        {/* Audio */}
        <S.SendMessageContainer>
          <ShouldRender
            if={
              !message && !audio && (recordState === null || recordState === 'stop') && !mediaFileType && !showInterests
            }
          >
            <S.AudioStartRecordingIcon onClick={startRecording} />
          </ShouldRender>
        </S.SendMessageContainer>

        <ShouldRender if={showInterests}>
          <S.ConfirmInterestsButton onClick={() => setShowInterests(prevState => !prevState)}>
            {t('confirm')}
          </S.ConfirmInterestsButton>
        </ShouldRender>

        <ShouldRender if={showChatSendingOptions}>
          <S.ChatSendingOptionsContainer>
            <S.IconOption onClick={() => openOptionAndThenClose(() => setShowInterests(prevState => !prevState))}>
              <S.InterestIcon className="m-0" />
            </S.IconOption>
            <S.IconOption onClick={() => openOptionAndThenClose(handleClickEmojiModal)}>
              <S.EmojiIcon className="m-0" />
            </S.IconOption>
            <S.IconOption onClick={() => onPressMention()}>
              <S.AtSigIcon className="m-0" />
            </S.IconOption>
            <S.IconOption onClick={() => openOptionAndThenClose(addImage)}>
              <S.ImageIcon className="m-0" />
            </S.IconOption>
            <S.IconOption onClick={() => openOptionAndThenClose(() => setShowGifModal(prevState => !prevState))}>
              <S.GifIconOption className="m-0" />
            </S.IconOption>
            <S.IconOption onClick={() => openOptionAndThenClose(addVideo)}>
              <S.VideoIcon className="m-0" />
            </S.IconOption>
          </S.ChatSendingOptionsContainer>
        </ShouldRender>
      </S.Form>

      <ShouldRender if={showInterests && !showMention}>
        <Interests
          interests={interests}
          selectedInterests={selectedInterests}
          isThereAnyReply={activeReplyMessage}
          onChangeSelectedInterests={ids => {
            setSelectedInterests([...ids])
          }}
        />
      </ShouldRender>

      <ShouldRender if={showMention}>
        <Mention
          onPressItem={user => onSelectMention(user)}
          onOpenEvent={() => {
            setShowInterests(false)
          }}
          search={mentionParser(message, cursor)}
          open={showMention}
          channel={activeChannel}
          isThereAnyReply={activeReplyMessage}
        />
      </ShouldRender>
    </>
  )
}
