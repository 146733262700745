import React, { useState } from 'react'
import { useAsync } from 'react-async'
import JoyRide from 'react-joyride'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SearchInput from '~/components/SearchInput'
import SponsorTabCard from '~/components/Community/SponsorsTab/SponsorTabCard'
import ModalInviteSponsor from '~/components/Community/Modals/ModalInviteSponsor'
import TabTitleAndSubtitle from '~/components/Community/TabTitleAndSubtitle'
import CustomSpinner from '~/components/CustomSpinner'
import SponsorsTabTooltip from '~/components/ApplicationTutorial/Community/SponsorsTabTooltip'
import { getAllSponsorsFromCommunity } from '~/services/requests'
import { returnCommunitySponsorsDataFiltered } from '~/helper/formFunctions'
import { CommunityGridTitle } from '~/components/Shared/StyledComponents'
import ZeroRegisterIndicator from '~/components/ZeroRegisterIndicator'
import { handleCheckCommunityInviteSponsorButton } from '~/helper/helperFunctions'
import * as CONST from '~/helper/const'
import * as S from './styled'

export default function SponsorTab() {
  const { t } = useTranslation()
  const tutorialIsActive = useSelector(state => state.tutorial.tutorialIsActive)
  const [showModalInviteSponsor, setShowModalInviteSponsor] = useState(false)
  const [filter, setFilter] = useState('')
  const [fetchAllSponsors, setFetchAllSponsors] = React.useState(null)
  const { data, error, isPending } = useAsync({ promiseFn: getAllSponsorsFromCommunity, watch: fetchAllSponsors })

  const setCallbackToGetAllTheSponsors = () => {
    setFetchAllSponsors(Math.random())
  }

  if (error) throw new Error(error)
  if (isPending) return <CustomSpinner />

  if (data) {
    const filteredSponsors = returnCommunitySponsorsDataFiltered(filter, data)
    return (
      <S.Container>
        <JoyRide
          steps={CONST.communitySponsorsTooltipOptions}
          run={tutorialIsActive}
          tooltipComponent={SponsorsTabTooltip}
          styles={{
            options: {
              arrowColor: 'transparent',
            },
          }}
        />
        <TabTitleAndSubtitle title={t('communitySponsors')} subtitle={t('manageSponsorsAndControlCampaigns')} />
        <S.SearchInputContainer>
          <SearchInput filter={filter} setFilter={setFilter} />
          <S.ButtonContainer>
            <S.InviteButton
              text={t('inviteSponsor')}
              icon={<S.InviteButtonIcon />}
              onClick={() => handleCheckCommunityInviteSponsorButton(setShowModalInviteSponsor)}
            />
          </S.ButtonContainer>
        </S.SearchInputContainer>
        <S.ProductsTabContainer>
          <S.GridTitleContainer>
            <S.NameColumn>
              <CommunityGridTitle>{t('sponsor')}</CommunityGridTitle>
            </S.NameColumn>
            <S.ActiveCampaignsColumn>
              <CommunityGridTitle row>{t('activeCampaigns')}</CommunityGridTitle>
            </S.ActiveCampaignsColumn>
            <S.SponsorSinceColumn>
              <CommunityGridTitle row>{t('sponsorSince')}</CommunityGridTitle>
            </S.SponsorSinceColumn>
            <S.CumulatedRevenueColumn>
              <CommunityGridTitle row>{t('cumulatedSponsorship')}</CommunityGridTitle>
            </S.CumulatedRevenueColumn>
            <S.StatusColumn>
              <CommunityGridTitle row>{t('status')}</CommunityGridTitle>
            </S.StatusColumn>
          </S.GridTitleContainer>
          {filteredSponsors?.length === 0 && <ZeroRegisterIndicator text={t('yourCommunityDoesntHaveSponsors')} />}
          {filteredSponsors.map(sponsor => (
            <SponsorTabCard
              key={sponsor.id}
              sponsor={sponsor}
              setCallbackToGetAllTheSponsors={setCallbackToGetAllTheSponsors}
            />
          ))}
        </S.ProductsTabContainer>
        <ModalInviteSponsor
          handleCloseModal={() => setShowModalInviteSponsor(false)}
          showModal={showModalInviteSponsor}
        />
      </S.Container>
    )
  }
}
