import styled from 'styled-components'

import { darken } from 'polished'

export const FormSectionContainer = styled.div`
  svg {
    font-size: 30px !important;
  }
`

export const PackageContainer = styled.div`
  position: relative;
`

export const PackageSelectionContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;

  border: 1.5px solid #d0cfcf;

  border-radius: 12px;
  padding: 10px 12px 10px 12px;
`

export const PackageNameAndPrice = styled.div`
  display: flex;
  flex-direction: column;
`

export const PackageControllersWrapper = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;

  width: 60%;
`

export const InputTitle = styled.span`
  color: #4f5559;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
`

export const PackageName = styled.span`
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.85;
  max-width: 210px;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`

export const PackagePrice = styled.span`
  color: var(--success);
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
`

export const PackageDiscountContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PackagePriceDiscountContainer = styled.div`
  margin-top: -5px;
`

export const PackageMonthlyPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3px;
`

export const PackageWithoutDiscountContainer = styled.div``

export const PackageWithDiscountContainer = styled.div`
  margin-top: -5px;
`

export const PackagePriceWithoutDiscount = styled.span`
  color: var(--error);
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  text-decoration: line-through;
`

export const PackagePriceWithDiscount = styled.span`
  color: var(--success);
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
`

export const PackageMonthlyPrice = styled.span`
  color: var(--success);
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
`

export const PackageMonthly = styled.span`
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 15px;
  margin-left: 5px;
`

export const ErrorMessageContainer = styled.div.attrs({
  className: 'error-message',
})``

export const SelectContainer = styled.div`
  margin-top: 5px;
`

export const PackageInformationContainer = styled.div.attrs({
  className: 'animated fadeIn',
})`
  transition: all 300ms;
`

export const PackageDescription = styled.p`
  margin: 0px;
  margin-top: 6px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 15px;
  width: 270px;
  line-height: 1.3;
`

export const PackageComposition = styled.p`
  margin-top: 24px;
  margin-bottom: 14px;
  color: #494e51;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
`

export const FormatDetailsCardContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(2, 1fr);

  gap: 16px;
`

export const PackageSpecialOffer = styled.p`
  margin-bottom: 0px;
  color: #e3b202;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
`

export const ProductName = styled.span`
  white-space: nowrap;
  flex: 1 0 auto;
  color: #999999;
  font-family: Roboto;
  font-size: 14px;
`

export const PackagePriceContainer = styled.div`
  display: flex;
`

export const SpecialOfferImage = styled.img.attrs({
  src: '/special-offer.png',
})`
  width: 90px;
  height: 60px;
  transform: rotate(11deg);
`

export const ChangePackageButton = styled.button`
  background-color: #f0f0f0;
  font-family: Inter;

  padding: 16px;

  width: 75%;

  border: none;

  border-radius: 5px;

  color: var(--input-title);

  font-weight: 700;

  transition: background-color 200ms;

  :hover {
    background-color: ${darken(0.05, '#f0f0f0')};
  }
`

export const Separator = styled.hr`
  width: 50px;
  rotate: 90deg;
  border-style: dashed;
  border-color: #e3e3e3;
  border-width: 1px;
`
