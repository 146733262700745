import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'

export default function MaterialDialog({
  openDialog,
  closeDialog,
  dialogTitle,
  dialogText,
  cancelAction,
  confirmAction,
}) {
  const { t } = useTranslation()

  return (
    <Dialog open={openDialog} onClose={closeDialog}>
      <DialogTitle>
        <span>{dialogTitle}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelAction} color="primary">
          {t('cancel')}
        </Button>
        <Button data-cy="confirm-button" onClick={confirmAction} color="primary">
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
