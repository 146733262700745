import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { darken } from 'polished'
import { customMedia } from '~/styles/global'

export const ModalCustom = styled(Modal).attrs({
  backdrop: 'static',
  keyboard: false,
  centered: true,
})`
  .modal-dialog {
    max-width: 820px;
  }

  .modal-content {
    background-color: #fff !important;
    border-radius: 30px !important;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff !important;
    border-radius: 30px !important;
    padding-bottom: 40px;
  }
`

export const FadingBackground = styled.div`
  width: 100%;
  height: 180px;
  background: linear-gradient(180deg, var(--sponsor-primary) 33.85%, rgba(255, 120, 45, 0) 100%);
  border-radius: 25px !important;
`

export const AvatarImageContainer = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`

export const AvatarImage = styled.img.attrs({
  src: '/tutorial-avatar.png',
})`
  width: 147px;
  height: 147px;
`

export const AvailableStepsContainer = styled.div`
  width: 100%;
  padding: 4rem 1rem;
`

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => (props.spacing ? '2.5rem' : '0')};
`

export const StepTitle = styled.div`
  color: #767676;
  font-family: Roboto;
  margin: 0px;
  margin-top: 8px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
`

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  background-color: #0090de;
  border-radius: 100%;
  margin-left: 1.8rem;
  margin-top: 6px;

  ${customMedia.lessThan('770px')`
    display: none
  `}
`

export const StepDescription = styled.p`
  color: #767676;
  font-family: Roboto;
  margin: 0px;
  margin-top: 8px;
  font-size: 15px;
  text-align: center;
  margin-left: 1.2rem;
`

export const TutorialDescription = styled.p`
  color: #595959;
  font-family: Roboto;
  margin: 0px;
  margin-top: 8px;
  line-height: 24px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;

  ${customMedia.lessThan('1560px')`
     font-size: 18px;
  `}
`

export const NextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Roboto;
  border-radius: 30px;
  text-align: center;
  width: 35%;
  height: 2.8rem;
  background-color: var(--sponsor-primary);

  &:hover {
    background-color: ${darken(0.03, '#00A2FF')};
  }

  &:active {
    -webkit-box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    top: 2px;
  }

  ${customMedia.lessThan('1560px')`
    width: 30%;
  `}
`

export const NextText = styled.p`
  color: #fff;
  font-family: Roboto;
  margin: 0px;
  font-weight: 700;
  font-size: 21px;
  letter-spacing: 0.05em;
  line-height: 15px;
`

export const SkipIt = styled.p`
  cursor: pointer;
  color: #767676;
  font-family: Roboto;
  text-decoration: underline;
  margin: 0px;
  margin-top: 26px;
  font-size: 16px;

  &:hover {
    color: ${darken(0.1, '#767676')};
  }

  ${customMedia.lessThan('1560px')`
     font-size: 15px;
  `}
`

export const DontShowThisMessageAgain = styled(SkipIt)`
  margin-top: 8px;
`
