/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultLogoImage } from '~/util/defaultImages'
import * as S from './styled'

export default function ChatChannel({ image, chatChannelName }) {
  const { t } = useTranslation()

  return (
    <>
      <S.ChatChannelContainer sponsored>
        <S.ChatChannelImage src={image || DefaultLogoImage} />
        <S.ChatChannelInformationsContainer>
          <S.ChatChannelTitle>{chatChannelName || t('chatName')}</S.ChatChannelTitle>
          <S.ChatChannelSubtitle>
            <b>Andrea: </b> {t('mockCreateChatChannelFirstSubtitle')}
          </S.ChatChannelSubtitle>
        </S.ChatChannelInformationsContainer>
        <S.ChatTimeIndicator>{t('mockCreateChatChannelTimeLapseDay')}</S.ChatTimeIndicator>
      </S.ChatChannelContainer>

      <S.ChatChannelContainer>
        <S.ChatChannelImage src="survey-image.jpg" />
        <S.ChatChannelInformationsContainer>
          <S.ManagerChannelContainer>
            <S.LockIcon />
            <S.ManagerChannelMembersContainer>
              <S.ManagersIcon />
              <S.ManagersCount>86</S.ManagersCount>
            </S.ManagerChannelMembersContainer>
            <S.ManagerChatChannelTitle>{t('mockCreateChatChannelTitle')}</S.ManagerChatChannelTitle>
          </S.ManagerChannelContainer>
          <S.ChatChannelSubtitle>
            <b>Lorella: </b> {t('mockCreateChatChannelSecondSubtitle')}
          </S.ChatChannelSubtitle>
        </S.ChatChannelInformationsContainer>
        <S.ChatTimeIndicator>{t('mockCreateChatChannelTimeLapseWeek')}</S.ChatTimeIndicator>
      </S.ChatChannelContainer>

      <S.ChatChannelContainer>
        <S.ChatChannelImage src="athletics-running.png" />
        <S.ChatChannelInformationsContainer>
          <S.ChatChannelTitle>Petter Hoffman</S.ChatChannelTitle>
          <S.ChatChannelSubtitle>
            <b>{t('you')} </b> {t('mockCreateChatChannelThirdSubtitle')}
          </S.ChatChannelSubtitle>
        </S.ChatChannelInformationsContainer>
        <S.ChatTimeIndicator>{t('mockCreateChatChannelTimeLapseMonth')}</S.ChatTimeIndicator>
      </S.ChatChannelContainer>
    </>
  )
}
