import { all } from 'redux-saga/effects'
import auth from './auth/sagas'
import user from './user/sagas'
import sponsor from './sponsor/sagas'
import chat from './chat/sagas'
import community from './community/sagas'
import createCampaign from './createCampaign/sagas'
import landingPage from './landingPage/sagas'

export default function* rootSaga() {
  return yield all([auth, user, sponsor, community, createCampaign, chat, landingPage])
}
