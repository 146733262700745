/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import { InputTitle } from '~/components/Shared/StyledComponents'
import WhiteButton from '~/components/WhiteButton'
import ErrorMessage from '~/components/Shared/ErrorMessage'
import { setCroppedImage as setCropped, setShowCropModal } from '~/store/modules/contentCropModal/actions'
import base64ToBlob from '~/util/base64ToBlob'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import SlidingTopBannerPreview from '~/components/Sponsor/Modals/SlidingTopBanner/SlidingTopBannerPreview'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { uploadMediaToS3 } from '~/services/requests'
import { transformDateToUtcFormat } from '~/helper/getFormattedDates'

import * as S from './styled'
import ContentCropImageModal from '../../ContentCropImageModal'

const ModalEditSlidingTopBanner = props => {
  const { t } = useTranslation()
  const { updateCampaignFormatAction } = useSponsorFunctions()
  const [loading, setLoading] = useState(false)
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign?.currentCampaignForReviewPage)
  const { handleSubmit, control, errors, register, setValue } = useForm()

  const {
    showModal,
    handleCloseModal,
    formatToUpdate,
    createMessage,
    refuseCampaignProduct,
    approveCampaignProduct,
    review,
    sponsor,
    formatId,
  } = props || {}

  const imageInput = useRef(null)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [media, setMedia] = useState(formatToUpdate?.imageUrl)
  const [date, setDate] = useState(transformDateToUtcFormat(formatToUpdate?.date))
  const [endDate, setEndDate] = useState(transformDateToUtcFormat(formatToUpdate?.endDate))

  const { showCropModal, croppedImage } = useSelector(state => state.contentCropModal)
  const dispatch = useDispatch()

  const toggleCropModal = value => () => dispatch(setShowCropModal(value))

  const setCroppedImage = image => dispatch(setCropped(image))

  const resetForm = () => {
    setMedia(null)
    setDate('')
    setEndDate('')
    setCroppedImage(null)
  }

  const closeModal = () => {
    resetForm()
    handleCloseModal()
  }

  const refuseTheCampaignAndSendMessageToChat = () => {
    createMessage()
    refuseCampaignProduct()
    handleCloseModal()
  }

  const handleImageInputClick = event => {
    event.target.value = ''
  }

  const onSubmit = payload => {
    if (!media) {
      return toast.error(t('defaultValidation', { inputName: t('image') }))
    }
    updateCampaignFormatAction(payload, formatId, closeModal, setLoading)
  }

  const handleClickUploadInput = uploadRef => {
    return uploadRef?.current?.click()
  }

  const addImage = event => {
    const image = event?.target?.files?.[0]
    const imageSize = event.target?.files?.[0]?.size
    if (imageSize > 10000000) {
      return toast.error(t('imageTooBig', { limit: '10mb' }))
    }
    if (image) uploadMediaToS3(image, setLoadingUpload, setMedia)
  }

  useEffect(() => {
    setMedia(formatToUpdate?.imageUrl)
    setDate(new Date(formatToUpdate?.date))
    setEndDate(new Date(formatToUpdate?.endDate))
  }, [showModal])

  useEffect(() => {
    register({ name: 'imageUrl' })
    setValue('imageUrl', media)
  }, [register, setValue, media])

  useEffect(() => {
    if (croppedImage) {
      const imageToUpload = base64ToBlob(croppedImage)
      uploadMediaToS3(imageToUpload, setLoadingUpload, setMedia)
    }
  }, [croppedImage])

  return (
    <>
      <ProductsModal
        action={onSubmit}
        createMessage={refuseTheCampaignAndSendMessageToChat}
        approveCampaignProduct={approveCampaignProduct}
        loading={loading}
        modalTitle={
          review
            ? t('reviseFormat', { format: t('slidingTopBanner') })
            : t('editFormat', { format: t('slidingTopBanner') })
        }
        buttonText={review ? t('done') : t('update')}
        handleCloseModal={closeModal}
        showModal={showModal}
        review={review}
        sponsor={sponsor}
        loadingUpload={loadingUpload}
        leftColumn={
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <S.FormContainer>
              <InputTitle>{t('title')}</InputTitle>
              <Controller
                as={
                  <S.CustomTextField
                    placeholder={t('typeTitle')}
                    disabled={review || false}
                    inputProps={{
                      maxLength: 80,
                    }}
                  />
                }
                defaultValue={formatToUpdate?.title}
                rules={{ required: t('defaultValidation', { inputName: t('title') }) }}
                name="title"
                control={control}
              />
              <ErrorMessage errors={errors} name="title" />

              <S.InputContainer>
                <InputTitle>{t('link')}</InputTitle>
                <S.HelperText>{t('bannerRedirectClick')}</S.HelperText>
                <Controller
                  as={
                    <S.CustomTextField
                      placeholder={t('typeLink')}
                      inputProps={{
                        maxLength: 500,
                      }}
                      disabled={review || false}
                    />
                  }
                  defaultValue={formatToUpdate?.link}
                  rules={{ required: t('defaultValidation', { inputName: t('link') }) }}
                  name="link"
                  control={control}
                />
                <ErrorMessage errors={errors} name="link" />
              </S.InputContainer>

              <StartDateSection
                product={t('slidingTopBanner')}
                selectedCampaignStartDate={date}
                setCampaignStartDate={setDate}
                campaignStartDate={currentCampaignForReviewPage.dtStart}
                campaignEndDate={currentCampaignForReviewPage.dtEnd}
                control={control}
                errors={errors}
                disabled={review || false}
              />

              <EndDateSection
                product={t('slidingTopBanner')}
                selectedCampaignStartDate={date}
                selectedCampaignEndDate={endDate}
                campaignEndDate={currentCampaignForReviewPage.dtEnd}
                setCampaignEndDate={setEndDate}
                control={control}
                errors={errors}
                disabled={review || false}
              />

              {!review && (
                <S.InputContainer>
                  <InputTitle>{t('companyBannerPhoto')}</InputTitle>
                  <S.HelperText>{t('storiesBannerRecommended')}</S.HelperText>
                </S.InputContainer>
              )}
            </S.FormContainer>

            {loadingUpload && (
              <S.SpinnerContainer>
                <S.CustomSpinner />
              </S.SpinnerContainer>
            )}

            {!loadingUpload && !media && !review && (
              <S.UploadSection>
                <S.UploadContainer spacing onClick={() => handleClickUploadInput(imageInput)}>
                  <S.VideoUploadIcon />
                  <S.UploadText>{t('imageUploadFormat')}</S.UploadText>
                </S.UploadContainer>
              </S.UploadSection>
            )}

            {/* Preview media */}
            {!loadingUpload && media && !review && (
              <S.PreviewMediaContainer>
                {sponsor && media && <S.PreviewMedia src={media} />}
                {sponsor && !media && <S.PreviewMedia src={formatToUpdate?.imageUrl} />}
                <S.CloseIcon
                  onClick={() => {
                    setMedia(null)
                  }}
                />
              </S.PreviewMediaContainer>
            )}
            <S.InputAddImage ref={imageInput} onChange={addImage} onClick={handleImageInputClick} />

            {media && !review && (
              <WhiteButton
                onClick={toggleCropModal(true)}
                text={t('cropImage')}
                style={{ marginTop: 10 }}
                icon={<S.EditIcon />}
                type="button"
              />
            )}
          </S.Form>
        }
        rightColumn={
          <CreateFormatMobileVisualization
            hideDefaultWidgets
            FormatVisualizationComponent={<SlidingTopBannerPreview media={media} />}
          />
        }
      />
      <ContentCropImageModal
        visible={showCropModal && showModal}
        onClose={toggleCropModal(false)}
        imageToCrop={media}
        imagePreview={media}
        setCroppedImage={setCroppedImage}
        uploadType="string"
      />
    </>
  )
}

export default ModalEditSlidingTopBanner
