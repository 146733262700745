import React, { useState } from 'react'
import history from '~/services/history'
import * as S from './styled'
import { Button } from '../Button/Button'
import Figure from '../Figure'
import { LinkToSection } from '../Link/link'

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleLoginClick = () => {
    history.push('/login')
  }

  return (
    <>
      <S.Nav>
        <S.Content>
          <S.LogosContainer>
            <Figure id="sos" height={34} alt="sos" src="/images/sos-logo.svg" />
            <a href="https://www.abilitya.tech/" target="_blank" rel="noopener noreferrer">
              <Figure height={39} alt="powered-by" src="/images/powered-logo.svg" id="powered-by" />
            </a>
          </S.LogosContainer>

          <S.TextContainer>
            <LinkToSection sectionId="monetization">Monetization</LinkToSection>

            <a href="https://www.abilitya.tech/" target="_blank" rel="noopener noreferrer">
              Abilitya
            </a>
            <LinkToSection sectionId="support">Support</LinkToSection>
            <LinkToSection sectionId="about-us">About us</LinkToSection>
          </S.TextContainer>
          <S.ButtonsContainer>
            <Button onClick={handleLoginClick} label="Log in" variant="secondary" />
          </S.ButtonsContainer>

          <S.MobileHeader>
            <Figure height={34} alt="sos" src="/images/abilitya-logo.svg" />

            <S.HamburgerIcon onClick={toggleMenu} isMenuOpen={isMenuOpen}>
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </S.HamburgerIcon>
          </S.MobileHeader>
          <S.DropdownMenu
            isMenuOpen={isMenuOpen}
            onClick={() => {
              setIsMenuOpen(false)
            }}
          >
            <LinkToSection sectionId="monetization">Monetization</LinkToSection>

            <a href="https://www.abilitya.tech/" target="_blank" rel="noopener noreferrer">
              Abilitya
            </a>
            <LinkToSection sectionId="support">Support</LinkToSection>
            <LinkToSection sectionId="about-us">About us</LinkToSection>
          </S.DropdownMenu>
        </S.Content>
      </S.Nav>
    </>
  )
}
