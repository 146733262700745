import React from 'react'
import * as S from './styled'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log(errorInfo)
    console.log(error)
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <S.ErrorBoundaryContainer>
          <S.BeLogoImage />
          <S.ErrorText>Something went wrong.</S.ErrorText>
          <S.TryReload href="/">Try reload?</S.TryReload>
        </S.ErrorBoundaryContainer>
      )
    }
    return children
  }
}
