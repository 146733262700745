import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DefaultLogoImage } from '~/util/defaultImages'
import * as S from './styled'

const StoriesBottomBannerPreview = ({ media }) => {
  const { t } = useTranslation()

  const company = useSelector(state => state?.sponsor?.company)

  return (
    <>
      <S.TopPreviewContainer>
        <S.PostHeader>
          <S.CommunityInformations>
            <S.CommunityLogoBorder>
              <S.CommunityLogo src={company?.logoAdvPlatServerPath || DefaultLogoImage} />
            </S.CommunityLogoBorder>
            <S.CommunityNameContainerTop>
              <S.CommunityName>{company?.name}</S.CommunityName>
              <S.Sponsored>{t('sponsoredLabel')}</S.Sponsored>
            </S.CommunityNameContainerTop>
          </S.CommunityInformations>
          <S.ThreeDotsIcon />
          <S.CloseIcon />
        </S.PostHeader>
      </S.TopPreviewContainer>

      <S.MiddlePreviewContainer>
        <S.MediaContainer>
          <S.ImageBackground />
        </S.MediaContainer>
        <S.CommunityNameContainer>
          <S.StoriesTitle>{t('storiesTitle')}</S.StoriesTitle>
          <S.StoriesDescription>{t('storiesDescription')}</S.StoriesDescription>
        </S.CommunityNameContainer>

        {media ? (
          <S.BottomStoriesBanner src={media} />
        ) : (
          <S.NoMediaIndicator>
            <S.ImageIcon />
          </S.NoMediaIndicator>
        )}
      </S.MiddlePreviewContainer>
    </>
  )
}

export default StoriesBottomBannerPreview
