import styled from 'styled-components'

export const CardBody = styled.div`
  margin-top: 20px;
  width: 90%;
  padding-bottom: 25px;

  .icon-section {
    display: flex;
    align-items: center;
    padding: 0px 30px 0px 30px;

    p {
      margin: 0px;
      margin-left: 30px;
      color: var(--input-title);
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
  }
`

export const CardChatChannelBoddy = styled.div`
  padding: 20px 20px 35px 20px;
  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 20px;
  width: 100%;
  height: auto;
  box-shadow: 1px 3px 12px 3px #e2e5e9;

  .chat-title {
    margin: 0px;
    color: var(--input-title);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .chat-informations {
    display: flex;
    padding: 15px;
    align-items: center;
    margin: 0px 10px 0px 10px;
    background-color: #fff5e9;
    padding-bottom: 15px;
    width: 100%;
    height: 70px;
    border-radius: 10px;
  }

  .chat-name {
    margin: 0px;
    color: #292725;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 14px;
  }

  .chat-sponsored {
    margin: 0px;
    padding-top: 5px;
    color: #2e2e2d;
    font-weight: 600;
    font-family: Lato;
    font-size: 13px;
  }

  .chat-description {
    padding-top: 5px;
    color: #635b5b;
    font-family: Lato;
    opacity: 0.6;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
  }
`

export const ContainerChannelImage = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 100%;
`

export const ChannelImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`

export const CardContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding-bottom: 15px;
  width: 100%;
  height: 100%;
  border-radius: 5px;

  .reward-card-title {
    margin: 0px;
    margin-left: 10px;
    margin-top: 10px;
    color: var(--input-title);
    font-family: Lato;
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
  }

  .reward-card-about {
    margin: 0px;
    width: 250px;
    min-height: 32px;
    padding-left: 10px;
    padding-right: 5px;
    margin-top: 5px;
    color: #a6a6a6;
    font-family: Roboto;
    font-size: 10px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`

export const ProductButtonsSection = styled.div`
  display: flex;
  margin-left: 10px;
  margin-top: 20px;
`

export const OpenChatButton = styled.button`
  width: 100%;
  height: 2.5rem;
  background-color: ${props => (props.expired ? '#cccccc' : 'var(--light-blue)')};
  color: #fff;
  margin: 0 10px;
  text-transform: uppercase;
  font-family: Roboto;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  font-weight: bold;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    vertical-align: top;
    padding: 13px 0px 13px;
  }
`
