import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as S from './styled'

const Tags = ({ tags, isContent }) => {
  return (
    <S.TagsContainer>
      {!isContent && tags.map(({ name }) => <S.Tag key={uuidv4()}>{`#${name && name.toUpperCase()}`}</S.Tag>)}
      {isContent && tags.map(({ label }) => <S.Tag key={uuidv4()}>{`#${label && label.toUpperCase()}`}</S.Tag>)}
    </S.TagsContainer>
  )
}

export default Tags
