import styled from 'styled-components'
import { customMedia } from '~/styles/global'

export const Title = styled.p`
  margin: 0px;
  color: ${props => (props.sponsor ? 'var(--sponsor-primary)' : 'var(--community-primary)')};
  font-family: Roboto Condensed;
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
`

export const Subtitle = styled.p`
  margin: 0px;
  color: #374049;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin-left: ${props => (props.row ? '10px !important' : '0px')};
  white-space: break-spaces;

  ${customMedia.lessThan('huge')`
    margin: 0px;
  `}
`

export const TextContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  flex-direction: ${props => (props.row ? 'row' : 'column')};
`
