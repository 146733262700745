/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'
import { Player } from 'video-react'
import { useGetTranscodedVideoUrl, useWindowDimensions, useImageDimensions } from '~/hooks/'
import CustomSpinner from '~/components/CustomSpinner'
import ModalFormattedMessage from '~/components/Shared/ModalFormattedMessage'
import './video-player.scss'

export default function VideoPlayer({ source, poster, showModalVideo, closeModalVideo, message }) {
  const { t } = useTranslation()
  const [getDownloadUrl, { loading, transcodedVideoUrl, transcodedVideoError }] = useGetTranscodedVideoUrl()
  const { height, width } = useWindowDimensions()
  const [imgWidth, imgHeight] = useImageDimensions(poster)
  const [displayDimensions, setDisplayDimensions] = useState(undefined)
  const [videoSrc, setVideoSrc] = useState(undefined)
  const [displayModalFormattedMessage, setDisplayModalFormattedMessage] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)
  const [videoIsMov, setVideoIsMov] = useState(false)

  useEffect(() => {
    if (imgWidth && imgHeight) {
      const aspectRatio = imgWidth / imgHeight
      if (imgWidth > imgHeight) {
        const displayWidth = width * 0.6 > 800 ? 800 : width * 0.6
        setDisplayDimensions({
          width: displayWidth,
          height: displayWidth / aspectRatio,
        })
      } else {
        const displayHeight = height * 0.8
        setDisplayDimensions({
          width: aspectRatio * displayHeight,
          height: displayHeight,
        })
      }
    }
  }, [imgWidth, imgHeight, width, height])

  useEffect(() => {
    const isMov = message && message?.fileNames?.[0] && message?.fileNames?.[0].toLowerCase().slice(-4) === '.mov'
    setVideoIsMov(isMov)
    if (message.transcodedVideo) {
      getDownloadUrl(message.transcodedVideo)
    } else {
      !isMov && setVideoSrc(source)
    }
  }, [])

  useEffect(() => {
    if (videoIsMov && !loading && !videoSrc && !transcodedVideoUrl) {
      setDisplayModalFormattedMessage(true)
      setModalMessage(t('thisVideoCannotBePlayed'))
    }

    if (videoIsMov && !loading && !!transcodedVideoError) {
      setDisplayModalFormattedMessage(true)
      setModalMessage(t('somethingWentWrong'))
    }
  }, [loading, videoSrc, transcodedVideoUrl, transcodedVideoError, videoIsMov])

  return (
    <>
      {!!loading && <CustomSpinner />}

      {!loading && !transcodedVideoError && (!!transcodedVideoUrl || !!videoSrc) && (
        <ReactModal isOpen={showModalVideo} overlayClassName="video-player" className="player">
          <div style={{ opacity: displayDimensions ? 1 : 0 }}>
            <Player
              fluid={false}
              poster={poster}
              width={displayDimensions ? displayDimensions.width : null}
              height={displayDimensions ? displayDimensions.height : null}
            >
              <source src={transcodedVideoUrl || videoSrc} />
            </Player>
          </div>
          <button
            onClick={closeModalVideo}
            type="button"
            aria-label="Close image"
            className="ril-close ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__closeButton close-button"
          />
        </ReactModal>
      )}

      <ModalFormattedMessage
        handleCloseModal={() => setDisplayModalFormattedMessage(false)}
        closeModalVideo={closeModalVideo}
        showModal={displayModalFormattedMessage}
        message={modalMessage}
      />
    </>
  )
}
