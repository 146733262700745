import React from 'react'
import * as S from './styled'

export default function CampaignReviewProductsButtons({ style, text, color, icon, number }) {
  return (
    <S.CardBody color={color} style={style}>
      <S.CardIcon>{icon}</S.CardIcon>
      <S.CardText>{text}</S.CardText>
      <S.CardNumber>{number}</S.CardNumber>
    </S.CardBody>
  )
}
