/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { getThumbnails } from 'video-metadata-thumbnails'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { clone } from 'ramda'
import Picker from 'emoji-picker-react'
import Tenor from 'react-tenor'
import Input from '~/components/Chat/Input'
import Messages from '~/components/Chat/Messages'
import ShouldRender from '~/components/ShouldRender'
import InputReplyPreview from '~/components/Chat/InputReplyPreview'
import { useSendMessage, useComponentVisible, useSendGif, useGetInterests } from '~/hooks/'
import { getAllMessagesFromChannel, sendMessageParse, parseReplyMessage } from '~/helper/chatFunctions'
import { videoParser, getFileType } from '~/helper/helperFunctions'
import { DefaultLogoImage } from '~/util/defaultImages'
import useChatFunctions from '~/helper/chatActions'
import InputPreviewFileIcons from '~/components/Chat/InputPreviewFileIcons'
import { getFirebaseUsers } from '~/services/requests'
import * as S from './styled'

export default function ChatWindow({
  open,
  handleClose,
  handleOpen,
  channelImage,
  chatName,
  channelId,
  sponsoredChannelTitle,
  channelIsSponsored,
  openedChat,
}) {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [audio, setAudio] = useState(null)
  const [media, setMedia] = useState([])
  const [file, setFile] = useState(null)
  const [showInterests, setShowInterests] = useState(false)
  const [showMention, setShowMention] = useState(false)
  const [selectedInterests, setSelectedInterests] = useState([])
  const [mediaFileType, setMediaFileType] = useState(null)
  const [showChatSendingOptions, setShowChatSendingOptions] = useState(false)
  const imageInput = useRef(null)
  const videoInput = useRef(null)
  const fileInput = useRef(null)
  const [send, { loading: loadingSendingMessage }] = useSendMessage()
  const { setActiveReplyMessageAction } = useChatFunctions()
  const [sendGifMessage] = useSendGif()
  const activeReplyMessage = useSelector(state => state.chat?.activeReplyMessage)
  const scrollDivRef = useRef(null)
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const { activeChannel } = useSelector(state => state.chat)

  const { interests } = useGetInterests()
  const {
    ref: refGif,
    isComponentVisible: isComponentVisibleGif,
    setIsComponentVisible: setIsComponentVisibleGif,
  } = useComponentVisible(false)

  const scrollToBottom = () => {
    if (scrollDivRef?.current) {
      scrollDivRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const messageCallback = () => {
    if (activeReplyMessage) {
      setActiveReplyMessageAction(null)
    }
    setAudio(null)
    setMessage('')
    setMedia([])
    setFile(null)
    setSelectedInterests([])
    setShowInterests(false)
    setShowMention(false)
    setShowChatSendingOptions(false)
  }

  const onSelectEmoji = (event, emojiObject) => {
    let newMessage = clone(message)
    newMessage += `${emojiObject.emoji} `
    setMessage(newMessage)
  }

  const handleClickEmojiModal = () => {
    setIsComponentVisible(prevState => !prevState)
  }

  const handleClickInputImage = () => {
    imageInput.current.click()
  }

  const handleClickInputVideo = () => {
    videoInput.current.click()
  }

  const handleClickInputFile = () => {
    fileInput.current.click()
  }

  const setInputReplyPreviewClass = () => {
    if (media?.length > 0) {
      return 'inputHasMedia'
    }
    if (showChatSendingOptions) {
      return 'optionsSectionIsOpen'
    }
    return null
  }

  const addImage = event => {
    if (media?.length === 4) return null
    // const imageFileSize = formatBytes(event.target?.files?.[0].size)
    // TODO check the image file size by bytes
    if (event.target?.files?.[0]?.size > 10000000) {
      return toast.error(t('imageTooBig', { limit: '10mb' }))
    }
    if (event.target?.files?.[0]) {
      setMedia([...media, URL.createObjectURL(event?.target?.files?.[0])])
    }
    return setMediaFileType('Image')
  }

  const addVideo = async event => {
    if (event.target?.files?.[0]?.size > 50000000) {
      return toast.error(t('videoIsTooBig', { limit: '50mb' }))
    }
    const video = URL.createObjectURL(event.target?.files?.[0])
    const thumbnail = await getThumbnails(video, {
      quality: 0.6,
      start: 0,
      end: 0,
    })
    if (event.target?.files?.[0]) {
      const formattedThumb = URL.createObjectURL(thumbnail[0].blob)
      setMedia(videoParser(video, formattedThumb))
    }
    return setMediaFileType('Video')
  }

  const addFile = async event => {
    if (event.target?.files?.[0]?.size > 50000000) {
      return toast.error(t('fileIsTooBig'))
    }
    setFile(event.target?.files?.[0])

    if (event.target?.files?.[0]) {
      setMedia([...media, URL.createObjectURL(event?.target?.files?.[0])])
    }
    return setMediaFileType('File')
  }

  const sendMessage = async event => {
    event.preventDefault()
    if (!message && !audio && !media?.length) {
      return null
    }
    // TODO Refactor this to have only one message parser
    if ((activeReplyMessage && message) || (activeReplyMessage && audio) || (activeReplyMessage && media)) {
      const reply = activeReplyMessage
      const parsedMessage = sendMessageParse({
        channelId: activeReplyMessage?.channelId,
        message,
        audio,
        media,
        mediaFileType,
        interests,
        selectedInterests,
        reply: !!reply && parseReplyMessage(reply),
      })
      if (mediaFileType === 'File') {
        parsedMessage.file = file
      }
      await send({
        message: parsedMessage,
        callback: () => messageCallback(),
      })
    }
    if (!activeReplyMessage) {
      const parsedMessage = sendMessageParse({
        channelId,
        message,
        audio,
        media,
        mediaFileType,
        interests,
        selectedInterests,
      })
      if (mediaFileType === 'File') {
        parsedMessage.file = file
      }
      await send({
        message: parsedMessage,
        callback: () => messageCallback(),
      })
    }
    return false
  }

  const closeChatChannelAndResetAnyActiveReply = () => {
    handleClose()
    setActiveReplyMessageAction(null)
    setShowChatSendingOptions(false)
    setMessage('')
  }

  const handleSelectGifAndSendMessage = gif => {
    sendGifMessage(gif, setIsComponentVisibleGif)
  }

  useEffect(() => {
    if (activeReplyMessage) {
      setActiveReplyMessageAction(null)
    }
    getAllMessagesFromChannel(openedChat, setMessages, activeChannel?.communityId)
    getFirebaseUsers()
  }, [open])

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom()
    }, 1000)
  }, [messages, handleOpen])

  useEffect(() => {
    if (media?.length === 0) {
      setMediaFileType(null)
    }
  }, [media])

  return (
    <S.Container open={open}>
      {isComponentVisible && (
        <S.EmojiPickerContainer ref={ref}>
          <Picker onEmojiClick={onSelectEmoji} disableSearchBar />
        </S.EmojiPickerContainer>
      )}
      {isComponentVisibleGif && (
        <S.GifContainer ref={refGif}>
          <Tenor
            token="DIX2JL6WNZHU"
            onSelect={handleSelectGifAndSendMessage}
            defaultResults
            searchPlaceholder={t('search')}
          />
        </S.GifContainer>
      )}
      <S.ChatWindowContainer>
        <S.ChannelLogoAndTitleSection>
          <S.ChannelLogoContainer>
            <S.ChannelImage src={channelImage === null ? DefaultLogoImage : channelImage} />
          </S.ChannelLogoContainer>
          {channelIsSponsored === true ? (
            <S.ChannelTitle>{sponsoredChannelTitle}</S.ChannelTitle>
          ) : (
            <S.ChannelTitle>{chatName}</S.ChannelTitle>
          )}
        </S.ChannelLogoAndTitleSection>
        <S.ChatChannelCloseIcon onClick={closeChatChannelAndResetAnyActiveReply} />
      </S.ChatWindowContainer>
      <S.HeaderHorizontalLine />
      <S.ContainerChat>
        <Messages
          messages={messages}
          scrollDivRef={scrollDivRef}
          loadingSendingMessage={loadingSendingMessage}
          open={open}
        />
      </S.ContainerChat>
      <S.MessageHorizontalLine />
      <S.InputAndReplyContainer>
        <S.InputContainer>
          <Input
            message={message}
            setMessage={setMessage}
            sendMessage={sendMessage}
            audio={audio}
            setAudio={setAudio}
            handleClickEmojiModal={handleClickEmojiModal}
            setShowGifModal={setIsComponentVisibleGif}
            addImage={handleClickInputImage}
            addVideo={handleClickInputVideo}
            addFile={handleClickInputFile}
            media={media}
            mediaFileType={mediaFileType}
            isSendingMessage={loadingSendingMessage}
            showInterests={showInterests}
            selectedInterests={selectedInterests}
            setSelectedInterests={setSelectedInterests}
            setShowInterests={setShowInterests}
            interests={interests}
            setShowMention={setShowMention}
            showMention={showMention}
            setShowChatSendingOptions={setShowChatSendingOptions}
            showChatSendingOptions={showChatSendingOptions}
          />
          <S.InputAddImage
            ref={imageInput}
            onChange={addImage}
            disabled={media?.length === 4 || mediaFileType === 'Video' || mediaFileType === 'File'}
          />
          <S.InputAddVideo
            ref={videoInput}
            onChange={addVideo}
            disabled={
              (media?.length === 2 && mediaFileType === 'Video') ||
              mediaFileType === 'Image' ||
              mediaFileType === 'File'
            }
          />
          <S.InputAddFile
            ref={fileInput}
            onChange={addFile}
            disabled={(media?.length === 2 && mediaFileType === 'Video') || mediaFileType === 'Image'}
          />
        </S.InputContainer>
        <ShouldRender if={activeReplyMessage}>
          <S.ReplyContainer className={setInputReplyPreviewClass()}>
            <InputReplyPreview
              message={activeReplyMessage?.message}
              entireMessage={activeReplyMessage}
              sentByMe={false}
            />
            <S.ReplyCloseIcon onClick={() => setActiveReplyMessageAction(null)} />
          </S.ReplyContainer>
        </ShouldRender>

        <ShouldRender if={media?.length > 0}>
          <S.ImagesContainer>
            <S.ImagePreviewContainer>
              {(() => {
                switch (mediaFileType) {
                  case 'Image':
                    return media?.map((image, index) => (
                      <S.ContainerImage key={image}>
                        <S.ImagePreview src={image} />
                        <S.RemoveImageIcon
                          onClick={() => {
                            media.splice(index, 1)
                            setMedia([...media])
                          }}
                        />
                      </S.ContainerImage>
                    ))
                  case 'Video':
                    return (
                      <S.ContainerImage>
                        <S.VideoPreview src={media?.[0]?.uri} />
                        <S.RemoveImageIcon onClick={() => setMedia([])} />
                      </S.ContainerImage>
                    )
                  case 'File':
                    return (
                      <S.FileContainer>
                        <InputPreviewFileIcons fileExtension={getFileType(file?.type)} />
                        <S.FileName>{file?.name}</S.FileName>
                      </S.FileContainer>
                    )
                  default:
                    return null
                }
              })()}
            </S.ImagePreviewContainer>

            <S.ReplyCloseIcon
              onClick={() => {
                setMedia([])
                setMediaFileType(null)
              }}
            />
          </S.ImagesContainer>
        </ShouldRender>
      </S.InputAndReplyContainer>
    </S.Container>
  )
}
