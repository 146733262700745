import React, { useRef, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { getThumbnails } from 'video-metadata-thumbnails'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import { InputTitle } from '~/components/Shared/StyledComponents'
import ErrorMessage from '~/components/Shared/ErrorMessage'
import { uploadMediaToS3 } from '~/services/requests'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import { setTheSocialMediaContentPreview, formatAndSendPutSocialMediaFormat } from '~helper/helperFunctions'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import * as S from './styled'

const ModalEditSocialMediaPostFormat = props => {
  const { t } = useTranslation()
  const { updateCampaignFormatAction } = useSponsorFunctions()
  const { handleSubmit, watch, control, errors } = useForm()

  const {
    showModal,
    handleCloseModal,
    socialMediaFormat: { slug: formatType } = {},
    community,
    formatToUpdate,
    createMessage,
    refuseCampaignProduct,
    approveCampaignProduct,
    review,
    sponsor,
    formatId,
  } = props || {}

  const imageInput = useRef(null)
  const videoInput = useRef(null)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [media, setMedia] = useState(formatToUpdate?.imageUrl || formatToUpdate?.videoUrl)
  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)
  const [videoThumbnail, setVideoThumbnail] = useState(formatToUpdate?.videoThumbnail || null)
  const [mediaFileType, setMediaFileType] = useState(null)
  const inputWatch = {
    title: watch('title', title),
    description: watch('description', description),
  }

  const resetForm = () => {
    setMedia(null)
    setVideoThumbnail(null)
    setMediaFileType(null)
  }

  const onInputVideoClick = event => {
    event.target.value = ''
  }

  const closeModal = () => {
    resetForm()
    handleCloseModal()
  }

  const onSubmit = payload => {
    if (formatType === 'instagramPost' && !media) {
      return toast.error(t('imageIsRequired'))
    }
    return formatAndSendPutSocialMediaFormat(
      payload,
      mediaFileType,
      media,
      formatId,
      updateCampaignFormatAction,
      closeModal,
    )
  }

  const handleClickUploadInput = uploadRef => {
    return uploadRef?.current?.click()
  }

  const addImage = event => {
    setVideoThumbnail(null)
    if (event.target?.files?.[0]?.size > 10000000) {
      return toast.error(t('imageTooBig', { limit: '10mb' }))
    }
    if (event.target?.files?.[0]) {
      const image = event?.target?.files?.[0]
      uploadMediaToS3(image, setLoadingUpload, setMedia)
    }
    return setMediaFileType('image')
  }

  const addVideo = async event => {
    const mediaSize = event.target?.files?.[0]?.size
    const mediaType = event.target?.files?.[0].type

    if (mediaType !== 'video/mp4') {
      return toast.error(t('onlyMp4Files'))
    }

    if (mediaSize > 50000000) {
      return toast.error(t('videoIsTooBig', { limit: '50mb' }))
    }

    if (mediaSize > 5000000 && formatType === 'twitterPost') {
      return toast.error(t('videoIsTooBig', { limit: '5mb' }))
    }

    if (mediaSize > 50000000 && !formatType === 'twitterPost') {
      return toast.error(t('videoIsTooBig', { limit: '50mb' }))
    }

    const video = URL.createObjectURL(event.target?.files?.[0])
    const thumbnail = await getThumbnails(video, {
      quality: 0.6,
      start: 0,
      end: 0,
    })
    if (event.target?.files?.[0]) {
      const videoToUpload = event.target?.files?.[0]
      const formattedThumb = URL.createObjectURL(thumbnail[0].blob)
      uploadMediaToS3(videoToUpload, setLoadingUpload, setMedia)
      setVideoThumbnail(formattedThumb)
    }
    return setMediaFileType('video')
  }

  const isInputDisabled = inputType => {
    if (inputType === 'image') {
      if (media && mediaFileType === 'video') {
        return true
      }
    }
    if (inputType === 'video') {
      if (media && mediaFileType === 'image') {
        return true
      }
    }
    return false
  }

  const refuseTheCampaignAndSendMessageToChat = () => {
    createMessage()
    refuseCampaignProduct()
    handleCloseModal()
  }

  useEffect(() => {
    const titleAndDescription = formatToUpdate?.message
    const description = titleAndDescription?.split('-')[1]
    const title = titleAndDescription?.split('-')[0]
    setTitle(title)
    setDescription(description)
    setMedia(formatToUpdate?.imageUrl || formatToUpdate?.videoUrl)
  }, [formatToUpdate.message, showModal, formatToUpdate.imageUrl, formatToUpdate.videoUrl])

  useEffect(() => {
    if (formatToUpdate?.imageUrl) setMediaFileType('image')
    if (formatToUpdate?.videoThumbnail) setMediaFileType('video')
  }, [formatToUpdate.imageUrl, formatToUpdate.videoThumbnail, showModal])

  return (
    <ProductsModal
      action={onSubmit}
      createMessage={refuseTheCampaignAndSendMessageToChat}
      approveCampaignProduct={approveCampaignProduct}
      modalTitle={review ? t('reviseFormat', { format: t(formatType) }) : t('editFormat', { format: t(formatType) })}
      buttonText={review ? t('done') : t('update')}
      handleCloseModal={closeModal}
      showModal={showModal}
      review={review}
      sponsor={sponsor}
      loadingUpload={loadingUpload}
      leftColumn={
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <InputTitle>{t('title')}</InputTitle>
            <Controller
              as={
                <S.CustomTextField
                  placeholder={t('typeTitle')}
                  disabled={review || false}
                  inputProps={{
                    maxLength: 80,
                  }}
                />
              }
              rules={{ required: t('defaultValidation', { inputName: t('title') }) }}
              name="title"
              defaultValue={title}
              control={control}
            />
            <ErrorMessage errors={errors} name="title" />
            <S.InputContainer>
              <InputTitle>{t('description')}</InputTitle>
              <Controller
                as={
                  <S.CustomMultilineTextField
                    placeholder={t('moreAboutContent')}
                    disabled={review || false}
                    inputProps={{
                      maxLength: 180,
                    }}
                  />
                }
                defaultValue={description}
                name="description"
                control={control}
              />
            </S.InputContainer>
            {!review && (
              <S.InputContainer>
                <InputTitle>{t('addMediasToYourPost')}</InputTitle>
                <S.HelperText>{t('onlyChooseOneOption')}</S.HelperText>
              </S.InputContainer>
            )}
          </S.FormContainer>

          {loadingUpload && (
            <S.SpinnerContainer>
              <S.CustomSpinner />
            </S.SpinnerContainer>
          )}

          {!loadingUpload && !media && !review && (
            <S.UploadSection>
              <S.UploadContainer
                onClick={() => handleClickUploadInput(imageInput)}
                className={isInputDisabled('image') ? 'disabled' : ''}
              >
                <S.ImageUploadIcon />
                <S.UploadText>{t('imageUpload')}</S.UploadText>
              </S.UploadContainer>

              <S.UploadContainer
                spacing
                onClick={!isInputDisabled('video') && (() => handleClickUploadInput(videoInput))}
                disabled={media && mediaFileType === 'image'}
                className={isInputDisabled('video') ? 'disabled' : ''}
              >
                <S.VideoUploadIcon />
                <S.UploadText>{t('videoUpload')}</S.UploadText>
              </S.UploadContainer>
            </S.UploadSection>
          )}

          {/* Preview media */}
          {!loadingUpload && media && !review && (
            <S.PreviewMediaContainer>
              {mediaFileType === 'video' && (
                <S.PlayIconContainer>
                  <S.PlayIcon />
                </S.PlayIconContainer>
              )}

              {sponsor && !media && <S.PreviewMedia src={formatToUpdate?.imageUrl || formatToUpdate?.videoThumbnail} />}
              {sponsor && media && <S.PreviewMedia src={mediaFileType === 'image' ? media : videoThumbnail} />}
              {!sponsor && <S.PreviewMedia src={mediaFileType === 'image' ? media : videoThumbnail} />}

              <S.CloseIcon
                onClick={() => {
                  setMedia(null)
                  setMediaFileType(null)
                  setVideoThumbnail(null)
                }}
              />
            </S.PreviewMediaContainer>
          )}
          <S.InputAddImage ref={imageInput} onChange={addImage} />
          <S.InputAddVideo ref={videoInput} onChange={addVideo} onClick={onInputVideoClick} />
        </S.Form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          defaultVisualization
          FormatVisualizationComponent={setTheSocialMediaContentPreview(
            formatType,
            community,
            inputWatch,
            media,
            videoThumbnail || formatToUpdate?.videoThumbnail,
          )}
        />
      }
    />
  )
}

export default ModalEditSocialMediaPostFormat
