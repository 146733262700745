/* eslint-disable no-return-assign */
import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import Col from 'react-bootstrap/Col'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'chartjs-plugin-labels'
import { Pie } from 'react-chartjs-2'
import { surveyAnalyticsChartOptions } from '~/helper/const'
import * as S from './styled'

Chart.plugins.unregister(ChartDataLabels)

export function SurveyReviewSingleContentCard({ surveys, campaign }) {
  const { t } = useTranslation()
  const surveyWithMultipleQuestions = []
  const surveyWithoutMultipleQuestions = []

  surveys.forEach(survey => {
    if (survey.numberSurveyQuestions === 1) {
      surveyWithoutMultipleQuestions.push(survey)
    }
    if (survey.numberSurveyQuestions > 1) {
      surveyWithMultipleQuestions.push(survey)
    }
  })

  return (
    <Container>
      <S.CardBody>
        <Row>
          <Col>
            <div className="icon-section">
              <img src="/icons/rewards.png" alt="Surveys" width="19" height="19" />
              <p className="ml-2">{t('survey')}</p>
            </div>
          </Col>
        </Row>

        {surveyWithMultipleQuestions.length > 0 ? (
          <Row className="mr-3 ml-3 mt-3">
            {surveyWithMultipleQuestions.map(surveys => (
              <S.WrapperMultipleSurvey key={surveys.dtEnd}>
                <p className="multiple-survey-name">{surveys.name}</p>
                {surveys.questions.map(survey => (
                  <Row className="mr-3 ml-3 pb-4" key={survey.id}>
                    <MultipleSurveyCard
                      name={surveys.name}
                      question={survey.question}
                      optionOneLabel={survey.optionOne}
                      optionTwoLabel={survey.optionTwo}
                      optionThreeLabel={survey.optionThree}
                      optionFourLabel={survey.optionFour}
                      optionFiveLabel={survey.optionFive}
                      surveys={surveys}
                      survey={survey}
                      analytics={survey.analytics}
                      communityTotalMembers={campaign?.community?.totalMembers}
                    />
                  </Row>
                ))}
              </S.WrapperMultipleSurvey>
            ))}
          </Row>
        ) : null}

        {surveyWithoutMultipleQuestions.length > 0 ? (
          <Row className="mr-3 ml-3 mt-3">
            {surveyWithoutMultipleQuestions.map(surveys =>
              surveys.questions.map(survey => (
                <SurveyCard
                  key={survey.id}
                  name={surveys.name}
                  question={survey.question}
                  optionOneLabel={survey.optionOne}
                  optionTwoLabel={survey.optionTwo}
                  optionThreeLabel={survey.optionThree}
                  optionFourLabel={survey.optionFour}
                  optionFiveLabel={survey.optionFive}
                  analytics={survey.analytics}
                  communityTotalMembers={campaign?.community?.totalMembers}
                />
              )),
            )}
          </Row>
        ) : null}
      </S.CardBody>
    </Container>
  )
}

export function SurveyCard({
  question,
  name,
  optionOneLabel,
  optionTwoLabel,
  optionThreeLabel,
  optionFourLabel,
  optionFiveLabel,
  analytics,
  communityTotalMembers,
}) {
  const { t } = useTranslation()
  const { optionOne, optionTwo, optionThree, optionFour, optionFive, total } = analytics
  const checkAndRemoveSurveyOptionsThatDontExist = () => {
    const labelsArray = [
      `${optionOneLabel} - ${optionOne.quantity} ${t('votes')}`,
      `${optionTwoLabel} - ${optionTwo.quantity} ${t('votes')}`,
      optionThreeLabel ? `${optionThreeLabel} - ${optionThree.quantity} ${t('votes')}` : '',
      optionFourLabel ? `${optionFourLabel} - ${optionFour.quantity} ${t('votes')}` : '',
      optionFiveLabel ? `${optionFiveLabel} - ${optionFive.quantity} ${t('votes')}` : '',
    ]
    const labelsArrayFormatted = labelsArray.filter(Boolean)
    return labelsArrayFormatted
  }

  const data = {
    labels: checkAndRemoveSurveyOptionsThatDontExist(),
    datasets: [
      {
        data: [
          Number(optionOne.percentage).toFixed(0),
          Number(optionTwo.percentage).toFixed(0),
          Number(optionThree.percentage).toFixed(0),
          Number(optionFour.percentage).toFixed(0),
          Number(optionFive.percentage).toFixed(0),
        ],
        backgroundColor: ['#266AC3', '#FC9538', '#199635', '#9D0593', '#ed4656'],
        hoverBackgroundColor: ['#266AC3', '#FC9538', '#199635', '#9D0593', '#ed4656'],
      },
    ],
  }

  return (
    <S.CardSurveyBody>
      <p className="survey-name">{name}</p>
      <p className="survey-question">{question}</p>
      <p className="survey-responses">{`${total} ${t('responses')}`}</p>

      <S.SurveyInformationSingleFormatModal>
        <S.NumberOfCommunityMembersContainer>
          <S.NumberOfCommunityMembers>{communityTotalMembers}</S.NumberOfCommunityMembers>
          <S.NumberOfCommunityMembersLabel>{t('communityMembers')}</S.NumberOfCommunityMembersLabel>
        </S.NumberOfCommunityMembersContainer>
        <S.NumberOfCommunityMembersContainer spacing>
          <S.NumberOfCommunityMembers>{total}</S.NumberOfCommunityMembers>
          <S.NumberOfCommunityMembersLabel>{t('participants')}</S.NumberOfCommunityMembersLabel>
        </S.NumberOfCommunityMembersContainer>
      </S.SurveyInformationSingleFormatModal>

      <div className="col-md-12 mt-4 mb-3">
        {!optionOne.percentage &&
        !optionTwo.percentage &&
        !optionThree.percentage &&
        !optionFour.percentage &&
        !optionFive.percentage ? (
          <>
            <S.NoSurveyDataIndicationContainer>
              <S.NoSurveyDataIndication>{t('surveyZeroResponses')}</S.NoSurveyDataIndication>
              <S.NoSurveyDataIndication>{t('pleaseComebackLater')}</S.NoSurveyDataIndication>
            </S.NoSurveyDataIndicationContainer>
          </>
        ) : (
          <Pie data={data} width={700} height={400} options={surveyAnalyticsChartOptions} />
        )}
      </div>
    </S.CardSurveyBody>
  )
}

export function MultipleSurveyCard({
  question,
  surveys,
  survey,
  optionOneLabel,
  optionTwoLabel,
  optionThreeLabel,
  optionFourLabel,
  optionFiveLabel,
  analytics,
  communityTotalMembers,
}) {
  const { t } = useTranslation()
  const { optionOne, optionTwo, optionThree, optionFour, optionFive, total } = analytics

  const checkAndRemoveSurveyOptionsThatDontExist = () => {
    const labelsArray = [
      `${optionOneLabel} - ${optionOne.quantity} ${t('votes')}`,
      `${optionTwoLabel} - ${optionTwo.quantity} ${t('votes')}`,
      optionThreeLabel ? `${optionThreeLabel} - ${optionThree.quantity} ${t('votes')}` : '',
      optionFourLabel ? `${optionFourLabel} - ${optionFour.quantity} ${t('votes')}` : '',
      optionFiveLabel ? `${optionFiveLabel} - ${optionFive.quantity} ${t('votes')}` : '',
    ]
    const labelsArrayFormatted = labelsArray.filter(Boolean)
    return labelsArrayFormatted
  }

  const data = {
    labels: checkAndRemoveSurveyOptionsThatDontExist(),
    datasets: [
      {
        data: [
          Number(optionOne.percentage).toFixed(0),
          Number(optionTwo.percentage).toFixed(0),
          Number(optionThree.percentage).toFixed(0),
          Number(optionFour.percentage).toFixed(0),
          Number(optionFive.percentage).toFixed(0),
        ],
        backgroundColor: ['#266AC3', '#FC9538', '#199635', '#9D0593', '#ed4656'],
        hoverBackgroundColor: ['#266AC3', '#FC9538', '#199635', '#9D0593', '#ed4656'],
      },
    ],
  }

  // get survey index to show the question number
  let surveyIndex
  if (surveys !== undefined) {
    surveyIndex = surveys.questions.findIndex(p => p.id === survey.id)
  }

  return (
    <S.CardMultipleSurvey>
      <p className="survey-question">{`Q${surveyIndex + 1} - ${question}`}</p>
      <p className="survey-responses">{`${total} ${t('responses')}`}</p>

      <S.SurveyInformationSingleFormatModal>
        <S.NumberOfCommunityMembersContainer>
          <S.NumberOfCommunityMembers>{communityTotalMembers}</S.NumberOfCommunityMembers>
          <S.NumberOfCommunityMembersLabel>{t('communityMembers')}</S.NumberOfCommunityMembersLabel>
        </S.NumberOfCommunityMembersContainer>
        <S.NumberOfCommunityMembersContainer spacing>
          <S.NumberOfCommunityMembers>{total}</S.NumberOfCommunityMembers>
          <S.NumberOfCommunityMembersLabel>{t('participants')}</S.NumberOfCommunityMembersLabel>
        </S.NumberOfCommunityMembersContainer>
      </S.SurveyInformationSingleFormatModal>

      <div className="col-md-12 mt-4 mb-3">
        {!optionOne.percentage &&
        !optionTwo.percentage &&
        !optionThree.percentage &&
        !optionFour.percentage &&
        !optionFive.percentage ? (
          <>
            <S.NoSurveyDataIndicationContainer>
              <S.NoSurveyDataIndication>{t('surveyZeroResponses')}</S.NoSurveyDataIndication>
              <S.NoSurveyDataIndication>{t('pleaseComebackLater')}</S.NoSurveyDataIndication>
            </S.NoSurveyDataIndicationContainer>
          </>
        ) : (
          <Pie data={data} width={700} height={450} options={surveyAnalyticsChartOptions} />
        )}
      </div>
    </S.CardMultipleSurvey>
  )
}
