import React from 'react'
import { useHistory } from 'react-router-dom'
import { useIsDesktop } from '../../useIsDesktop'

export const LinkToSection = ({ sectionId, children }) => {
  const isDesktop = useIsDesktop()
  const history = useHistory()

  const scrollToSection = () => {
    const handleScroll = () => {
      const element = document.getElementById(sectionId)
      if (!element) return

      const headerOffset = isDesktop ? 210 : 120
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.scrollY - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }

    if (window.location.pathname !== '/') {
      history.push('/')
      setTimeout(handleScroll, 500)
    } else {
      handleScroll()
    }
  }

  return <h4 onClick={scrollToSection}>{children}</h4>
}
