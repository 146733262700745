import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { RiSave2Line } from 'react-icons/ri'
import { Col, Row, Spinner, Modal } from 'react-bootstrap'
import { customMedia } from '~/styles/global'
import WhiteButton from '~/components/WhiteButton'
import ProfileButton from '~/components/ProfileButton'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    max-width: 850px;
    height: auto;
    margin: auto;
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;

    ${customMedia.lessThan('small')`
      width: 22rem !important;
      margin: auto;
      padding-left: 0px !important;
    `}
  }
  .modal-body {
    position: relative;
  }
`

export const DescriptionCharactersLeft = styled.div`
  position: absolute;
  right: 30px;
  top: 215px;
  color: #949aa0;
  font-size: 14px;
  font-family: Roboto;
`

export const InputLabel = styled.span`
  color: #47525e;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
`

export const UserProfilePictureContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UserProfilePicture = styled.img.attrs({
  alt: 'User Profile Photo',
})`
  width: 141px;
  height: 141px;
  border-radius: 100%;
  object-fit: cover;
  border-radius: 100%;
  border: 5px solid #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  z-index: 10;
`

export const EditCommunityProfileButton = styled(ProfileButton).attrs({
  style: {
    position: 'absolute',
    top: '110px',
    right: '322px',
    zIndex: '999',
  },
  backgroundColor: '#9671F5',
})``

export const EditSponsorProfileButton = styled(EditCommunityProfileButton).attrs({
  backgroundColor: '#00A2FF',
  className: 'edit-profile-button',
})``

export const FormContainer = styled.div`
  margin-top: 45px;

  .isRequired {
    color: #38b1f3;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
  }

  .error-message {
    font-family: Roboto;
    color: #ea343e;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.9;
  }

  .MuiOutlinedInput-multiline {
    height: 80px !important;
    padding: 10px 14px !important;
  }

  .headquarter-address-section {
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      height: 40px;
      padding-right: 45px;
      box-sizing: border-box;
      background: url('google-logo.png') no-repeat 98%;
      background-size: 26px;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #c4c4c4 !important;

      padding-left: 10px;
      font-family: Lato;
      font-size: 16px;
      font-weight: 500;
      line-height: 14px;
    }
  }
`

export const InputContainer = styled.div`
  display: flex;
  align-items: start;
`

export const Title = styled.p`
  color: var(--input-title);
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  opacity: 0.9;
`

export const NotNow = styled.p`
  color: #969faa;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between !important;
  align-items: baseline;
  margin-bottom: 4px;
  padding-top: 14px !important;
`

export const SaveIcon = styled(RiSave2Line).attrs({
  color: 'var(--success)',
  size: 23,
})``

export const SaveButton = styled(WhiteButton).attrs({
  type: 'submit',
  form: 'my-form',
  style: { marginTop: '10px' },
})``

export const CancelButton = styled(WhiteButton).attrs({
  style: { marginTop: '6px', padding: '0px 30px' },
  className: 'close-modal-button',
})``

export const IsRequired = styled.span`
  color: ${props => (props.userType === 'sponsor' ? 'var(--sponsor-primary)' : 'var(--community-primary)')};
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-left: 4px;
`

export const InputTextField = styled(TextField).attrs({
  fullWidth: true,
  variant: 'outlined',
})``

export const Column = styled(Col).attrs({
  md: 6,
})``

export const EmailColumn = styled(Col).attrs({
  md: 12,
})``

export const SectionRow = styled(Row)`
  margin-top: ${props => (props.spacing ? '0px' : '16px')};
`

export const Form = styled.form.attrs({
  id: 'my-form',
})``

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``
