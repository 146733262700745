import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  setUserCurrentCommunity as setUserCurrentCommunityAction,
  getCommunityLinkInvitationRequest as getCommunityLinkInvitationRequestAction,
  updateCommunityProductRequest as updateCommunityProductRequestAction,
  updateCommunityProfileRequest as updateCommunityProfileRequestAction,
  uploadS3Request as uploadS3RequestAction,
  removeS3FromState as removeS3FromStateAction,
  updateUserCurrentCommunityInformations as updateUserCurrentCommunityInformationsAction,
  setFirstLogin as setFirstLoginAction,
  resetCommunityInformations as resetCommunityInformationsAction,
  createCustomAdvertisementProduct as createCustomAdvertisementProductAction,
  updateCustomAdvertisementProduct as updateCustomAdvertisementProductAction,
  updatePackage as updatePackageAction,
  deleteCustomAdvertisementProduct as deleteCustomAdvertisementProductAction,
  deletePackage as deletePackageAction,
  updateCustomAdvertisementProductActiveStatus as updateCustomAdvertisementProductActiveStatusAction,
  updateCommunitySponsorCooperationStatus as updateCommunitySponsorCooperationStatusAction,
  updateSponsorCommunityCooperationStatus as updateSponsorCommunityCooperationStatusAction,
  updatePackageActiveStatus as updatePackageActiveStatusAction,
  createPackage as createPackageAction,
  setCommunityHasStripeAccount as setCommunityHasStripeAccountAction,
  addCommunityGalleryMediaRequest as addCommunityGalleryMediaRequestAction,
  deleteCommunityGalleryMediaRequest as deleteCommunityGalleryMediaRequestAction,
} from '~/store/modules/community/actions'
import {
  setCampaignForReview as setCampaignForReviewAction,
  setCampaignForUpdate as setCampaignForUpdateAction,
  refuseCampaign as refuseCampaignAction,
  approveCampaign as approveCampaignAction,
  stopCampaign as stopCampaignAction,
} from '~/store/modules/createCampaign/actions'

const useCommunityFunctions = () => {
  const dispatch = useDispatch()
  const actions = useMemo(
    () => ({
      setUserCurrentCommunityAction(community) {
        dispatch(setUserCurrentCommunityAction(community))
      },

      setCampaignForReviewAction(campaign) {
        dispatch(setCampaignForReviewAction(campaign))
      },

      setCampaignForUpdateAction(campaign) {
        dispatch(setCampaignForUpdateAction(campaign))
      },

      stopCampaignAction(networkId, campaignId) {
        dispatch(stopCampaignAction(networkId, campaignId))
      },

      resetCommunityInformations(data) {
        dispatch(resetCommunityInformationsAction(data))
      },

      createCustomAdvertisementProductAction(product, communityId, setCallbackToGetProducts) {
        dispatch(createCustomAdvertisementProductAction(product, communityId, setCallbackToGetProducts))
      },

      updateCustomAdvertisementProductAction(productId, product, communityId, setCallbackToGetProducts) {
        dispatch(updateCustomAdvertisementProductAction(productId, product, communityId, setCallbackToGetProducts))
      },

      updatePackageAction(packageId, packageInfo, communityId, setCallbackToGetPackages) {
        dispatch(updatePackageAction(packageId, packageInfo, communityId, setCallbackToGetPackages))
      },

      createPackageAction(packageInfo, communityId, setCallbackToGetPackages) {
        dispatch(createPackageAction(packageInfo, communityId, setCallbackToGetPackages))
      },

      setCommunityHasStripeAccountAction(status) {
        dispatch(setCommunityHasStripeAccountAction(status))
      },

      deleteCustomAdvertisementProductAction(productIds, communityId) {
        dispatch(deleteCustomAdvertisementProductAction(productIds, communityId))
      },

      deletePackageAction(packageIds, communityId) {
        dispatch(deletePackageAction(packageIds, communityId))
      },

      updateCustomAdvertisementProductActiveStatusAction(status, productId, communityId) {
        dispatch(updateCustomAdvertisementProductActiveStatusAction(status, productId, communityId))
      },

      updateCommunitySponsorCooperationStatusAction(status, communityId, sponsorId) {
        dispatch(updateCommunitySponsorCooperationStatusAction(status, communityId, sponsorId))
      },

      updateSponsorCommunityCooperationStatusAction(status, communityId, sponsorId) {
        dispatch(updateSponsorCommunityCooperationStatusAction(status, communityId, sponsorId))
      },

      updatePackageActiveStatusAction(status, packageId, communityId) {
        dispatch(updatePackageActiveStatusAction(status, packageId, communityId))
      },

      refuseCampaignAction(networkId, campaignId) {
        dispatch(refuseCampaignAction(networkId, campaignId))
      },

      approveCampaignAction(networkId, campaignId) {
        dispatch(approveCampaignAction(networkId, campaignId))
      },

      updateUserCurrentCommunityInformationsAction(community) {
        dispatch(updateUserCurrentCommunityInformationsAction(community))
      },

      uploadS3RequestAction(file) {
        dispatch(uploadS3RequestAction(file))
      },

      removeS3FromStateAction() {
        dispatch(removeS3FromStateAction())
      },

      setFirstLoginAction() {
        dispatch(setFirstLoginAction())
      },

      updateCommunityProductRequestAction(networkId, productId, status, price) {
        dispatch(updateCommunityProductRequestAction(networkId, productId, status, price))
      },

      updateCommunityProfileRequestAction(id, data) {
        dispatch(updateCommunityProfileRequestAction(id, data))
      },

      getCommunityLinkInvitationRequestAction(communityId) {
        dispatch(getCommunityLinkInvitationRequestAction(communityId))
      },

      addCommunityGalleryMediaRequestAction(communityId, uploadTypeName, title, image, link) {
        dispatch(addCommunityGalleryMediaRequestAction(communityId, uploadTypeName, title, image, link))
      },

      deleteCommunityGalleryMediaRequestAction(index, type, communityId, communityMediaId) {
        dispatch(deleteCommunityGalleryMediaRequestAction(index, type, communityId, communityMediaId))
      },
    }),
    [dispatch],
  )
  return actions
}

export default useCommunityFunctions
