import styled from 'styled-components'
import { darken } from 'polished'

export const CalendarBadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 5px;
  border: none;
  height: ${props => (props.height ? props.height : '40px')};
  width: ${props => (props.width ? props.width : '40px')};
  background-color: ${props => (props.color ? props.color : '#fb5561')};

  color: #fff;
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;

  &:hover {
    background-color: ${props => (props.color ? darken(0.05, props.color) : darken(0.05, '#fb5561'))};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StartDateMonth = styled.span`
  margin-top: 5px;
  color: #a8b1bd;
  font-family: Roboto;
  font-size: 12px;
`

export const StartDateDay = styled.span`
  color: #ff751a;
  font-family: Roboto;
  font-size: 26px;
`
