import React from 'react'
import * as S from './styles'

const BLOOB_SRC_BY_VARIANT = {
  1: 'blob-1.svg',
  2: 'blob-1-purple.svg',
  3: 'blob-2.svg',
  4: 'blob-3.svg',
  5: 'blob-5.svg',
}

const getBloobPath = name => `/bloobs/${name}`

export const Bloob = props => {
  const { blur, center, opacity, scaleX, scaleY, variant, className, ...styles } = props || {}

  const src = getBloobPath(BLOOB_SRC_BY_VARIANT[variant ?? 1])

  return (
    <S.BloobImage
      blur={blur}
      opacity={opacity}
      center={center}
      scaleX={scaleX}
      scaleY={scaleY}
      style={styles}
      src={src}
      id={`bloob-${variant}`}
      className={className}
    />
  )
}
