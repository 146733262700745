import styled from 'styled-components'
import { darken } from 'polished'

export const Button = styled.button`
  background-color: ${props => props.color};
  border-radius: 50px;
  max-width: 17rem;
  padding: 0px 20px;
  height: 40px;
  transition: background-color 0.3s;
  border: none;
  box-shadow: ${props => (props.noShadow ? 'none' : '0px 2px 6px 3px rgb(209, 213, 220)')};
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 350ms ease;

  &:hover {
    background-color: ${props => darken(0.05, props.color)};
    transform: scale(1.06);
  }

  &:active {
    -webkit-box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    top: 2px;
  }
`

export const ButtonText = styled.span`
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
`
