import React from 'react'
import { breakpoints } from '~/helper/breakpoints'
import styled from 'styled-components'
import { Bloob } from '../../Components/Bloob/Bloob'

const desktopBloobsConfig = [
  { variant: 1, top: 140, left: 700, blur: 64, scaleX: 1 },
  { variant: 2, top: -100, left: 550, blur: 64, scaleX: 1 },
  { variant: 3, top: 500, left: -50, blur: 64, scaleX: 1 },
  { variant: 4, top: 1800, left: 1000, blur: 64, scaleX: 1 },
  { variant: 5, top: 3000, left: -600, blur: 64, scaleX: 0.5 },
]

const mobileBloobsConfig = [
  { variant: 1, top: -350, left: -100, blur: 64, scaleX: 1 },
  { variant: 2, top: -500, left: -300, blur: 64, scaleX: 1 },
]

const DesktopBloob = styled(Bloob)`
  @media ${breakpoints.mobile} {
    display: none;
    visibility: hidden;
  }
`

const MobileBloob = styled(Bloob)`
  display: none;
  visibility: hidden;

  @media ${breakpoints.mobile} {
    display: block;
    visibility: visible;
  }
`

export const PageBloobs = ({ bloobsToShow = 5 }) => {
  return (
    <>
      {mobileBloobsConfig.slice(0, bloobsToShow).map(bloob => (
        <MobileBloob key={bloob.variant} {...bloob} />
      ))}

      {desktopBloobsConfig.slice(0, bloobsToShow).map(bloob => (
        <DesktopBloob key={bloob.variant} {...bloob} />
      ))}
    </>
  )
}
