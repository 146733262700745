import styled from 'styled-components'
import { AiOutlineHeart, AiOutlineRetweet } from 'react-icons/ai'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { FiShare } from 'react-icons/fi'
import { BsChat } from 'react-icons/bs'
import { IoIosImages } from 'react-icons/io'

export const TopPreviewContainer = styled.div`
  background-color: #182029;
  padding: 14px;
`

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Option = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  margin-left: 4px;
  line-height: 27px;
  text-align: center;
`

export const OptionSelected = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  margin-left: 4px;
  line-height: 27px;
  text-align: center;
  border-bottom: 3px solid #4796e7;
`

export const ImageIcon = styled(IoIosImages).attrs({
  size: 28,
  color: 'red',
})``

export const ChatIcon = styled(BsChat).attrs({
  size: 16,
  color: '#6e757f',
})``

export const RetweetIcon = styled(AiOutlineRetweet).attrs({
  size: 16,
  color: '#6e757f',
})``

export const LikeIcon = styled(AiOutlineHeart).attrs({
  size: 16,
  color: '#6e757f',
})``

export const ShareIcon = styled(FiShare).attrs({
  size: 16,
  color: '#6e757f',
})``

export const ThreeDotsIcon = styled(BiDotsHorizontalRounded).attrs({
  size: 21,
  color: '#6e757f',
})`
  margin-left: 10px;
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.spacing ? '14px 54px 0px 54px' : '10px 40px 0px 40px')};
  padding-bottom: ${props => (props.spacing ? '10px' : '0px')};
  width: 100%;
`

export const CommentsAndShares = styled.div`
  display: flex;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
`

export const ActionText = styled.p`
  color: #6e757f;
  font-family: Lato;
  font-size: 14px;
  margin: 0px;
  margin-left: 4px;
`

export const MiddlePreviewContainer = styled.div`
  background-color: #182029;
  border-top: 1px solid #313335;
`

export const PostHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 14px 0px 14px;
`

export const CommunityInformations = styled.div`
  display: flex;
`

export const CommunityLogo = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
`

export const CommunityNameAndDate = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 3px;
`

export const CommunityName = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  max-width: 170px;
  margin-bottom: 0px;
`

export const Date = styled.p`
  color: #6e757f;
  font-family: Lato;
  font-size: 13px;
  margin-bottom: 0px;
  margin-left: 8px;
`

export const PostContainer = styled.div`
  padding: 0px 22px 0px 62px;
  margin-top: -20px;
`

export const PostComment = styled.p`
  color: #e4e6eb;
  font-family: Lato;
  font-size: 14px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  max-width: 300px;
  max-height: 120px;
`

export const PostCommentAndDescription = styled(PostComment)`
  display: -webkit-box;
`

export const MockComment = styled(PostComment)`
  max-width: 280px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
`

export const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  height: 200px;
`

export const MediaImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
`

export const NoMediaIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  height: 200px;
  background-color: #ccc;
  border-radius: 15px;
`

export const BottomPreviewContainer = styled.div`
  background-color: #182029;
  padding-bottom: 8px;
  border-top: 1px solid #313335;
`

export const RetweetContainer = styled.div`
  margin-left: 10px;
  margin-bottom: -8px;
  padding-top: 4px;
`

export const RetweetText = styled.span`
  color: #6e757f;
  font-weight: bold;
  font-family: Lato;
  font-size: 13px;
  margin-bottom: 0px;
  margin-left: 8px;
`

export const PlayIconContainer = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff0000;
  align-self: center;
  position: absolute;
`

export const PlayIcon = styled.img.attrs({
  src: '/play-icon.png',
})`
  width: 16px;
  height: 16px;
  margin-left: 3px;
`
