/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import produce from 'immer'
import { toast } from 'react-toastify'
import { uuid } from 'uuidv4'
import { SIGN_OUT } from '~/store/modules/auth/actionTypes'
import {
  INCREASE_SURVEY_QUANTITY_PRODUCT,
  DECREASE_SURVEY_QUANTITY_PRODUCT,
  ADD_REWARD,
  DELETE_REWARD,
  EDIT_REWARD,
  INCREASE_REWARD_QUANTITY_PRODUCT,
  DECREASE_REWARD_QUANTITY_PRODUCT,
  ADD_CHAT_CHANNEL,
  EDIT_CHAT_CHANNEL,
  DELETE_CHAT_CHANNEL,
  INCREASE_CHAT_CHANNEL_QUANTITY_PRODUCT,
  DECREASE_CHAT_CHANNEL_QUANTITY_PRODUCT,
  RESET_CAMPAIGN_PROCESS,
  INCREASE_MOBILE_BOOST_QUANTITY_PRODUCT,
  DECREASE_MOBILE_BOOST_QUANTITY_PRODUCT,
  ADD_WEB,
  ADD_EVENT,
  EDIT_EVENT,
  DELETE_EVENT,
  INCREASE_EVENT_QUANTITY_PRODUCT,
  DECREASE_EVENT_QUANTITY_PRODUCT,
  EDIT_WEB,
  DELETE_WEB,
  ADD_POST,
  EDIT_POST,
  DELETE_POST,
  ADD_VIDEO,
  EDIT_VIDEO,
  DELETE_VIDEO,
  ADD_ANNOUNCEMENT,
  EDIT_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  ADD_DOCUMENT,
  EDIT_DOCUMENT,
  DELETE_DOCUMENT,
  INCREASE_CONTENT_QUANTITY_PRODUCT,
  DECREASE_CONTENT_QUANTITY_PRODUCT,
  ADD_SURVEY_INITIAL_INFORMATIONS,
  ADD_SURVEY,
  EDIT_SURVEY,
  DELETE_SURVEY,
  ADD_SURVEY_MULTIPLE_QUESTIONS,
  EDIT_SURVEY_MULTIPLE_QUESTIONS,
  ADD_WELCOME_AD,
  EDIT_WELCOME_AD,
  DELETE_WELCOME_AD,
  INCREASE_WELCOME_AD_QUANTITY_PRODUCT,
  DECREASE_WELCOME_AD_QUANTITY_PRODUCT,
  SET_CURRENT_COMMUNITY_FOR_CREATE_CAMPAIGN_PROCESS,
  ADD_CAMPAIGN_INFORMATIONS,
  GET_CAMPAIGN_TARGET_FILTER_SUCCESS,
  SET_PRODUCT_INFORMATIONS,
  DELETE_SURVEY_MULTIPLE_QUESTIONS,
  SET_CAMPAIGN_FOR_REVIEW,
  SET_CAMPAIGN_FOR_UPDATE,
  EDIT_SURVEY_INITIAL_INFORMATIONS,
  DELETE_ALL_CONTENT,
  DELETE_ALL_REWARDS,
  DELETE_ALL_SURVEYS,
  DELETE_ALL_EVENTS,
  DELETE_ALL_CHAT_CHANNELS,
  DELETE_ALL_WELCOME_ADS,
  DECREASE_MOBILE_BOOST_QUANTITY,
  SET_TEMPORARY_CAMPAIGN_ID_FOR_STRIPE_PAYMENT_INTENT_CREATION,
  STRIPE_CREATE_PAYMENT_INTENT_SUCCESS,
  STRIPE_CREATE_PAYMENT_INTENT_REQUEST,
  CREATE_PAYMENT_INTENT_PAY_AS_YOU_GO,
  CREATE_PAYMENT_INTENT_PACKAGE,
  SET_CURRENT_CAMPAIGN_BEING_UPDATED,
  SET_CAMPAIGN_TOTAL_PRICE,
  RESET_STRIPE_CLIENT_SECRET,
  SET_CURRENT_CAMPAIGN_FOR_CREATE_FORMAT,
  ADD_SURVEY_TO_ARRAY,
  REMOVE_SURVEYS_FROM_ARRAY,
  SET_CURRENT_CAMPAIGN_FOR_REVIEW_PAGE,
  SET_SHOW_CAMPAIGN_REVIEW_PAGE,
  SET_CAMPAIGNS_WAITING_FOR_APPROVAL_LENGTH,
  OPEN_INFORMATION_MODAL_AFTER_FORMAT_CREATION,
  CLOSE_INFORMATION_MODAL_AFTER_FORMAT_CREATION,
  SET_COMMUNITY_SPONSOR_DIRECT_CHANNEL_ID,
  SET_CAMPAIGN_DATES_FOR_CURRENT_MONTH,
  SET_CALLBACK_UPDATE_CAMPAIGN,
  SET_CAMPAIGN_CURRENT_SELECTED_MONTH,
  SET_CAMPAIGN_CURRENT_SELECTED_YEAR,
  SET_LOADING_STRIPE_PAYMENT_INTENT,
} from './actionTypes'

const INITIAL_STATE = {
  campaignId: null,
  uuid: null,
  stripeTemporaryCampaignIdForPaymentIntentCreation: null,
  stripeClientSecret: null,
  loadingStripePaymentIntent: false,
  stripePaymentIntentAlreadyCreated: false,
  campaignUpdate: false,
  campaignReview: null,
  campaignsWaitingForApprovalLength: null,
  campaignIsBeingReviewed: false,
  currentCampaignBeingUpdated: null,
  currentCampaignTotalPrice: null,
  currentCampaignForCreateFormat: null,
  currentCampaignForReviewPage: null,
  currentCampaignSelectedMonth: null,
  callbackUpdateCampaign: null,
  community: null,
  showCampaignReviewPage: false,
  surveysFormatCreation: [],
  showModalAfterFormatCreation: false,
  directChannelBetweenCommunityAndCompanyId: null,
  campaignMonthStartDate: null,
  campaignMonthEndDate: null,
  currentCampaignMonthYear: null,
  campaignDates: {
    dtStart: '',
    dtEnd: '',
  },
  campaignTarget: {
    targetNumber: '',
    gender: '',
    ageLevel: null,
    interestArea: null,
  },
  campaignProducts: {
    mobileBoost: {
      advIdProduct: 0,
      networkAdvProductId: 0,
      quantity: 0,
      price: 0,
      boostsUsed: 0,
    },
    survey: {
      name: 'Survey',
      advIdProduct: 0,
      networkAdvProductId: 0,
      quantity: 0,
      price: 0,
      surveys: [],
    },
    reward: {
      name: 'Reward',
      advIdProduct: 0,
      networkAdvProductId: 0,
      quantity: 0,
      price: 0,
      rewards: [],
    },
    chatChannel: {
      name: 'Chat Channel',
      advIdProduct: 0,
      networkAdvProductId: 0,
      quantity: 0,
      price: 0,
      channels: [],
    },
    event: {
      name: 'Event',
      advIdProduct: 0,
      networkAdvProductId: 0,
      quantity: 0,
      price: 0,
      events: [],
    },
    welcomeAd: {
      name: 'Welcome AD',
      advIdProduct: 0,
      networkAdvProductId: 0,
      quantity: 0,
      price: 0,
      welcomeAds: [],
    },
    content: {
      quantity: 0,
      price: 0,
      advIdProduct: 0,
      networkAdvProductId: 0,
      video: {
        name: 'Video',
        quantity: 0,
        videos: [],
      },
      post: {
        name: 'Post',
        quantity: 0,
        posts: [],
      },
      web: {
        name: 'Web',
        quantity: 0,
        webs: [],
      },
      announcement: {
        name: 'Announcement',
        quantity: 0,
        announcements: [],
      },
      document: {
        name: 'document',
        quantity: 0,
        documents: [],
      },
    },
  },
}

export default function createCampaignReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_COMMUNITY_FOR_CREATE_CAMPAIGN_PROCESS:
      return produce(state, draft => {
        draft.community = action.payload
      })

    case ADD_CAMPAIGN_INFORMATIONS:
      return produce(state, draft => {
        draft.campaignDates.dtStart = action.payload.startDate
        draft.campaignDates.dtEnd = action.payload.endDate
        draft.campaignTarget.ageLevel = action.payload.ageLevel
        draft.campaignTarget.gender = action.payload.gender
        draft.campaignTarget.interestArea = action.payload.interestAreas
      })

    case GET_CAMPAIGN_TARGET_FILTER_SUCCESS:
      return produce(state, draft => {
        draft.campaignTarget.targetNumber = action.payload
      })

    case SET_CAMPAIGN_CURRENT_SELECTED_MONTH:
      return produce(state, draft => {
        draft.currentCampaignSelectedMonth = action.payload
      })

    case SET_CAMPAIGN_CURRENT_SELECTED_YEAR:
      return produce(state, draft => {
        draft.currentCampaignMonthYear = action.payload
      })

    // SURVEY
    case ADD_SURVEY:
      return produce(state, draft => {
        draft.campaignProducts.survey.surveys.push(action.payload)
      })

    case EDIT_SURVEY:
      return produce(state, draft => {
        const surveyIndex = draft.campaignProducts.survey.surveys.findIndex(p => p.id === action.payload.id)
        if (surveyIndex >= 0) {
          draft.campaignProducts.survey.surveys[surveyIndex] = action.payload
        }
      })

    case DELETE_SURVEY:
      return produce(state, draft => {
        const surveyIndex = draft.campaignProducts.survey.surveys.findIndex(p => p.id === action.payload)
        if (surveyIndex >= 0) {
          draft.campaignProducts.survey.surveys.splice(surveyIndex, 1)
        }
      })

    case DELETE_SURVEY_MULTIPLE_QUESTIONS:
      return produce(state, draft => {
        const surveyIndex = draft.campaignProducts.survey.surveys.findIndex(p => p.id === action.payload)
        if (surveyIndex >= 0) {
          draft.campaignProducts.survey.surveys.splice(surveyIndex, 1)
        }
      })

    case ADD_SURVEY_MULTIPLE_QUESTIONS:
      return produce(state, draft => {
        const surveyIndex = draft.campaignProducts.survey.surveys.findIndex(p => p.id === action.payload.surveyId)
        if (surveyIndex >= 0) {
          draft.campaignProducts.survey.surveys[surveyIndex].questions.push(action.payload.product)
        }
      })

    case EDIT_SURVEY_MULTIPLE_QUESTIONS:
      return produce(state, draft => {
        const surveyIndex = draft.campaignProducts.survey.surveys.findIndex(p => p.id === action.payload.surveyId)
        if (surveyIndex >= 0) {
          const questionIndex = draft.campaignProducts.survey.surveys[surveyIndex].questions.findIndex(
            p => p.id === action.payload.product.id,
          )
          if (questionIndex >= 0) {
            draft.campaignProducts.survey.surveys[surveyIndex].questions[questionIndex] = action.payload.product
          }
        }
      })

    case EDIT_SURVEY_INITIAL_INFORMATIONS:
      return produce(state, draft => {
        const surveyIndex = draft.campaignProducts.survey.surveys.findIndex(p => p.id === action.payload.id)
        if (surveyIndex >= 0) {
          draft.campaignProducts.survey.surveys[surveyIndex].name = action.payload.name
          draft.campaignProducts.survey.surveys[surveyIndex].date = action.payload.date
          draft.campaignProducts.survey.surveys[surveyIndex].boost = action.payload.boost
          draft.campaignProducts.survey.surveys[surveyIndex].tags = action.payload.tags
        }
      })

    case ADD_SURVEY_INITIAL_INFORMATIONS:
      return produce(state, draft => {
        draft.campaignProducts.survey.surveys.push(action.payload)
      })

    case DECREASE_SURVEY_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.survey.quantity === 0
          ? draft.campaignProducts.survey.quantity
          : (draft.campaignProducts.survey.quantity -= 1)
      })

    case INCREASE_SURVEY_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.survey.quantity += 1
      })

    // MOBILE BOOST
    case INCREASE_MOBILE_BOOST_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.mobileBoost.quantity += 1
      })

    case DECREASE_MOBILE_BOOST_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.mobileBoost.quantity === 0
          ? draft.campaignProducts.mobileBoost.quantity
          : (draft.campaignProducts.mobileBoost.quantity -= 1)
      })

    // REWARD
    case ADD_REWARD:
      return produce(state, draft => {
        draft.campaignProducts.reward.rewards.push(action.payload)
      })

    case EDIT_REWARD:
      return produce(state, draft => {
        const rewardIndex = draft.campaignProducts.reward.rewards.findIndex(p => p.id === action.payload.id)
        if (rewardIndex >= 0) {
          draft.campaignProducts.reward.rewards[rewardIndex] = action.payload
        }
      })

    case DELETE_REWARD:
      return produce(state, draft => {
        const rewardIndex = draft.campaignProducts.reward.rewards.findIndex(p => p.id === action.payload)
        if (rewardIndex >= 0) {
          draft.campaignProducts.reward.rewards.splice(rewardIndex, 1)
        }
      })

    case INCREASE_REWARD_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.reward.quantity += 1
      })

    case DECREASE_REWARD_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.reward.quantity === 0
          ? draft.campaignProducts.reward.quantity
          : (draft.campaignProducts.reward.quantity -= 1)
      })

    // WELCOME AD
    case ADD_WELCOME_AD:
      return produce(state, draft => {
        draft.campaignProducts.welcomeAd.welcomeAds.push(action.payload)
      })

    case EDIT_WELCOME_AD:
      return produce(state, draft => {
        const welcomeAdIndex = draft.campaignProducts.welcomeAd.welcomeAds.findIndex(p => p.id === action.payload.id)
        if (welcomeAdIndex >= 0) {
          draft.campaignProducts.welcomeAd.welcomeAds[welcomeAdIndex] = action.payload
        }
      })

    case DELETE_WELCOME_AD:
      return produce(state, draft => {
        const welcomeAdIndex = draft.campaignProducts.welcomeAd.welcomeAds.findIndex(p => p.id === action.payload)
        if (welcomeAdIndex >= 0) {
          draft.campaignProducts.welcomeAd.welcomeAds.splice(welcomeAdIndex, 1)
        }
      })

    case INCREASE_WELCOME_AD_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.welcomeAd.quantity += 1
      })

    case DECREASE_WELCOME_AD_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.welcomeAd.quantity === 0
          ? draft.campaignProducts.welcomeAd.quantity
          : (draft.campaignProducts.welcomeAd.quantity -= 1)
      })

    // CHAT CHANNEL
    case ADD_CHAT_CHANNEL:
      return produce(state, draft => {
        draft.campaignProducts.chatChannel.channels.push(action.payload)
      })

    case EDIT_CHAT_CHANNEL:
      return produce(state, draft => {
        const channelIndex = draft.campaignProducts.chatChannel.channels.findIndex(p => p.id === action.payload.id)
        if (channelIndex >= 0) {
          draft.campaignProducts.chatChannel.channels[channelIndex] = action.payload
        }
      })

    case DELETE_CHAT_CHANNEL:
      return produce(state, draft => {
        const channelIndex = draft.campaignProducts.chatChannel.channels.findIndex(p => p.id === action.payload)
        if (channelIndex >= 0) {
          draft.campaignProducts.chatChannel.channels.splice(channelIndex, 1)
        }
      })

    case INCREASE_CHAT_CHANNEL_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.chatChannel.quantity += 1
      })

    case DECREASE_CHAT_CHANNEL_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.chatChannel.quantity === 0
          ? draft.campaignProducts.chatChannel.quantity
          : (draft.campaignProducts.chatChannel.quantity -= 1)
      })

    // EVENT
    case ADD_EVENT:
      return produce(state, draft => {
        draft.campaignProducts.event.events.push(action.payload)
      })

    case EDIT_EVENT:
      return produce(state, draft => {
        const eventIndex = draft.campaignProducts.event.events.findIndex(p => p.id === action.payload.id)
        if (eventIndex >= 0) {
          draft.campaignProducts.event.events[eventIndex] = action.payload
        }
      })

    case DELETE_EVENT:
      return produce(state, draft => {
        const eventIndex = draft.campaignProducts.event.events.findIndex(p => p.id === action.payload)
        if (eventIndex >= 0) {
          draft.campaignProducts.event.events.splice(eventIndex, 1)
        }
      })

    case INCREASE_EVENT_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.event.quantity += 1
      })

    case DECREASE_EVENT_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.event.quantity === 0
          ? draft.campaignProducts.event.quantity
          : (draft.campaignProducts.event.quantity -= 1)
      })

    // CONTENT
    case INCREASE_CONTENT_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.content.quantity += 1
      })

    case DECREASE_CONTENT_QUANTITY_PRODUCT:
      return produce(state, draft => {
        draft.campaignProducts.content.quantity === 0
          ? draft.campaignProducts.content.quantity
          : (draft.campaignProducts.content.quantity -= 1)
      })

    case ADD_WEB:
      return produce(state, draft => {
        draft.campaignProducts.content.web.webs.push(action.payload)
        draft.campaignProducts.content.web.quantity += 1
      })

    case EDIT_WEB:
      return produce(state, draft => {
        const webIndex = draft.campaignProducts.content.web.webs.findIndex(p => p.id === action.payload.id)
        if (webIndex >= 0) {
          draft.campaignProducts.content.web.webs[webIndex] = action.payload
        }
      })

    case DELETE_WEB:
      return produce(state, draft => {
        const webIndex = draft.campaignProducts.content.web.webs.findIndex(p => p.id === action.payload)
        if (webIndex >= 0) {
          draft.campaignProducts.content.web.webs.splice(webIndex, 1)
          draft.campaignProducts.content.web.quantity -= 1
        }
      })

    case ADD_POST:
      return produce(state, draft => {
        draft.campaignProducts.content.post.posts.push(action.payload)
        draft.campaignProducts.content.post.quantity += 1
      })

    case EDIT_POST:
      return produce(state, draft => {
        const postIndex = draft.campaignProducts.content.post.posts.findIndex(p => p.id === action.payload.id)
        if (postIndex >= 0) {
          draft.campaignProducts.content.post.posts[postIndex] = action.payload
        }
      })

    case DELETE_POST:
      return produce(state, draft => {
        const postIndex = draft.campaignProducts.content.post.posts.findIndex(p => p.id === action.payload)
        if (postIndex >= 0) {
          draft.campaignProducts.content.post.posts.splice(postIndex, 1)
          draft.campaignProducts.content.post.quantity -= 1
        }
      })

    case ADD_VIDEO:
      return produce(state, draft => {
        draft.campaignProducts.content.video.videos.push(action.payload)
        draft.campaignProducts.content.video.quantity += 1
      })

    case EDIT_VIDEO:
      return produce(state, draft => {
        const videoIndex = draft.campaignProducts.content.video.videos.findIndex(p => p.id === action.payload.id)
        if (videoIndex >= 0) {
          draft.campaignProducts.content.video.videos[videoIndex] = action.payload
        }
      })

    case DELETE_VIDEO:
      return produce(state, draft => {
        const videoIndex = draft.campaignProducts.content.video.videos.findIndex(p => p.id === action.payload)
        if (videoIndex >= 0) {
          draft.campaignProducts.content.video.videos.splice(videoIndex, 1)
          draft.campaignProducts.content.video.quantity -= 1
        }
      })

    case ADD_ANNOUNCEMENT:
      return produce(state, draft => {
        draft.campaignProducts.content.announcement.announcements.push(action.payload)
        draft.campaignProducts.content.announcement.quantity += 1
      })

    case EDIT_ANNOUNCEMENT:
      return produce(state, draft => {
        const announcementIndex = draft.campaignProducts.content.announcement.announcements.findIndex(
          p => p.id === action.payload.id,
        )
        if (announcementIndex >= 0) {
          draft.campaignProducts.content.announcement.announcements[announcementIndex] = action.payload
        }
      })

    case DELETE_ANNOUNCEMENT:
      return produce(state, draft => {
        const announcementIndex = draft.campaignProducts.content.announcement.announcements.findIndex(
          p => p.id === action.payload,
        )
        if (announcementIndex >= 0) {
          draft.campaignProducts.content.announcement.announcements.splice(announcementIndex, 1)
          draft.campaignProducts.content.announcement.quantity -= 1
        }
      })

    case ADD_DOCUMENT:
      return produce(state, draft => {
        draft.campaignProducts.content.document.documents.push(action.payload)
        draft.campaignProducts.content.document.quantity += 1
      })

    case EDIT_DOCUMENT:
      return produce(state, draft => {
        const documentIndex = draft.campaignProducts.content.document.documents.findIndex(
          p => p.id === action.payload.id,
        )
        if (documentIndex >= 0) {
          draft.campaignProducts.content.document.documents[documentIndex] = action.payload
        }
      })

    case DELETE_DOCUMENT:
      return produce(state, draft => {
        const documentIndex = draft.campaignProducts.content.document.documents.findIndex(p => p.id === action.payload)
        if (documentIndex >= 0) {
          draft.campaignProducts.content.document.documents.splice(documentIndex, 1)
          draft.campaignProducts.content.document.quantity -= 1
        }
      })

    case SET_PRODUCT_INFORMATIONS:
      return produce(state, draft => {
        switch (action.payload.productType) {
          case 'reward':
            draft.campaignProducts.reward.price = action.payload.product.price
            draft.campaignProducts.reward.advIdProduct = action.payload.product.advIdProduct
            draft.campaignProducts.reward.networkAdvProductId = action.payload.product.networkAdvProductId
            break

          case 'survey':
            draft.campaignProducts.survey.price = action.payload.product.price
            draft.campaignProducts.survey.advIdProduct = action.payload.product.advIdProduct
            draft.campaignProducts.survey.networkAdvProductId = action.payload.product.networkAdvProductId
            break

          case 'welcomeAd':
            draft.campaignProducts.welcomeAd.price = action.payload.product.price
            draft.campaignProducts.welcomeAd.advIdProduct = action.payload.product.advIdProduct
            draft.campaignProducts.welcomeAd.networkAdvProductId = action.payload.product.networkAdvProductId
            break

          case 'content':
            draft.campaignProducts.content.price = action.payload.product.price
            draft.campaignProducts.content.advIdProduct = action.payload.product.advIdProduct
            draft.campaignProducts.content.networkAdvProductId = action.payload.product.networkAdvProductId
            break

          case 'chatChannel':
            draft.campaignProducts.chatChannel.price = action.payload.product.price
            draft.campaignProducts.chatChannel.advIdProduct = action.payload.product.advIdProduct
            draft.campaignProducts.chatChannel.networkAdvProductId = action.payload.product.networkAdvProductId
            break

          case 'event':
            draft.campaignProducts.event.price = action.payload.product.price
            draft.campaignProducts.event.advIdProduct = action.payload.product.advIdProduct
            draft.campaignProducts.event.networkAdvProductId = action.payload.product.networkAdvProductId
            break

          case 'mobileBoost':
            draft.campaignProducts.mobileBoost.price = action.payload.product.price
            draft.campaignProducts.mobileBoost.advIdProduct = action.payload.product.advIdProduct
            draft.campaignProducts.mobileBoost.networkAdvProductId = action.payload.product.networkAdvProductId
            break

          default:
            return null
        }
      })

    case SET_CAMPAIGN_FOR_REVIEW:
      return produce(state, draft => {
        draft.campaignReview = action.payload
        draft.campaignIsBeingReviewed = true
      })

    case SET_CAMPAIGN_FOR_UPDATE:
      return produce(state, draft => {
        const { myCampaign } = action.payload

        draft.campaignId = action.payload.id

        draft.campaignDates.dtStart = new Date(myCampaign.campaignDates.dtStart)
        draft.campaignDates.dtEnd = new Date(myCampaign.campaignDates.dtEnd)

        draft.campaignUpdate = true

        draft.campaignTarget = myCampaign.campaignTarget

        if (myCampaign.campaignProducts.event !== null) {
          draft.campaignProducts.event = myCampaign.campaignProducts.event
        }

        if (myCampaign.campaignProducts.reward !== null) {
          draft.campaignProducts.reward = myCampaign.campaignProducts.reward
        }

        if (myCampaign.campaignProducts.survey !== null) {
          draft.campaignProducts.survey = myCampaign.campaignProducts.survey
        }

        if (myCampaign.campaignProducts.welcomeAd !== null) {
          draft.campaignProducts.welcomeAd = myCampaign.campaignProducts.welcomeAd
        }

        if (myCampaign.campaignProducts.chatChannel !== null) {
          draft.campaignProducts.chatChannel = myCampaign.campaignProducts.chatChannel
        }

        // content
        if (myCampaign.campaignProducts.content !== null) {
          draft.campaignProducts.content.quantity = myCampaign.campaignProducts.content.quantity
          draft.campaignProducts.content.price = myCampaign.campaignProducts.content.price

          myCampaign.campaignProducts.content.video !== null
            ? (draft.campaignProducts.content.video = myCampaign.campaignProducts.content.video)
            : draft.campaignProducts.content.video
          myCampaign.campaignProducts.content.document !== null
            ? (draft.campaignProducts.content.document = myCampaign.campaignProducts.content.document)
            : draft.campaignProducts.content.document
          myCampaign.campaignProducts.content.web !== null
            ? (draft.campaignProducts.content.web = myCampaign.campaignProducts.content.web)
            : draft.campaignProducts.content.web
          myCampaign.campaignProducts.content.post !== null
            ? (draft.campaignProducts.content.post = myCampaign.campaignProducts.content.post)
            : draft.campaignProducts.content.post
        }
      })

    case DELETE_ALL_CONTENT:
      return produce(state, draft => {
        draft.campaignProducts.content.quantity = 0
        draft.campaignProducts.content.video.quantity = 0
        draft.campaignProducts.content.video.videos = []

        draft.campaignProducts.content.post.quantity = 0
        draft.campaignProducts.content.post.posts = []

        draft.campaignProducts.content.web.quantity = 0
        draft.campaignProducts.content.web.webs = []

        draft.campaignProducts.content.document.quantity = 0
        draft.campaignProducts.content.document.documents = []

        toast.success('Formats successfully deleted.')
      })

    case DELETE_ALL_REWARDS:
      return produce(state, draft => {
        draft.campaignProducts.reward.rewards = []
        draft.campaignProducts.reward.quantity = 0
        toast.success('Formats successfully deleted.')
      })

    case DELETE_ALL_SURVEYS:
      return produce(state, draft => {
        draft.campaignProducts.survey.surveys = []
        draft.campaignProducts.survey.quantity = 0
      })

    case DELETE_ALL_EVENTS:
      return produce(state, draft => {
        draft.campaignProducts.event.events = []
        draft.campaignProducts.event.quantity = 0
        toast.success('Formats successfully deleted.')
      })

    case DELETE_ALL_CHAT_CHANNELS:
      return produce(state, draft => {
        draft.campaignProducts.chatChannel.channels = []
        draft.campaignProducts.chatChannel.quantity = 0
        toast.success('Formats successfully deleted.')
      })

    case DELETE_ALL_WELCOME_ADS:
      return produce(state, draft => {
        draft.campaignProducts.welcomeAd.welcomeAds = []
        draft.campaignProducts.welcomeAd.quantity = 0
        toast.success('Formats successfully deleted.')
      })

    case DECREASE_MOBILE_BOOST_QUANTITY:
      return produce(state, draft => {
        draft.campaignProducts.mobileBoost.quantity -= action.payload.quantityToDecrease
      })

    case SET_TEMPORARY_CAMPAIGN_ID_FOR_STRIPE_PAYMENT_INTENT_CREATION:
      return produce(state, draft => {
        const temporaryId = uuid()
        draft.stripeTemporaryCampaignIdForPaymentIntentCreation = temporaryId
        draft.uuid = temporaryId
      })

    case STRIPE_CREATE_PAYMENT_INTENT_REQUEST:
      return produce(state, draft => {
        draft.stripePaymentIntentAlreadyCreated = true
      })

    case STRIPE_CREATE_PAYMENT_INTENT_SUCCESS:
      return produce(state, draft => {
        draft.stripeClientSecret = action.payload.clientSecret
        draft.loadingStripePaymentIntent = false
      })

    case CREATE_PAYMENT_INTENT_PAY_AS_YOU_GO:
      return produce(state, draft => {
        draft.loadingStripePaymentIntent = true
      })

    case CREATE_PAYMENT_INTENT_PACKAGE:
      return produce(state, draft => {
        draft.loadingStripePaymentIntent = true
      })

    case SET_LOADING_STRIPE_PAYMENT_INTENT:
      return produce(state, draft => {
        draft.loadingStripePaymentIntent = action.payload
      })

    case SET_CURRENT_CAMPAIGN_BEING_UPDATED:
      return produce(state, draft => {
        draft.currentCampaignBeingUpdated = action.payload
        draft.uuid = action.payload.uuid
      })

    case SET_CAMPAIGN_TOTAL_PRICE:
      return produce(state, draft => {
        draft.currentCampaignTotalPrice = action.payload
      })

    case RESET_STRIPE_CLIENT_SECRET:
      return produce(state, draft => {
        draft.stripeClientSecret = null
      })

    case RESET_CAMPAIGN_PROCESS: {
      return INITIAL_STATE
    }

    case SIGN_OUT: {
      return INITIAL_STATE
    }

    case SET_CURRENT_CAMPAIGN_FOR_CREATE_FORMAT:
      return produce(state, draft => {
        draft.currentCampaignForCreateFormat = action.payload
      })

    case ADD_SURVEY_TO_ARRAY:
      return produce(state, draft => {
        draft.surveysFormatCreation.push(action.payload)
      })

    case REMOVE_SURVEYS_FROM_ARRAY:
      return produce(state, draft => {
        draft.surveysFormatCreation = []
      })

    case SET_CURRENT_CAMPAIGN_FOR_REVIEW_PAGE:
      return produce(state, draft => {
        draft.currentCampaignForReviewPage = action.payload.campaign
      })

    case SET_SHOW_CAMPAIGN_REVIEW_PAGE:
      return produce(state, draft => {
        draft.showCampaignReviewPage = action.payload.status
      })

    case SET_CAMPAIGNS_WAITING_FOR_APPROVAL_LENGTH:
      return produce(state, draft => {
        draft.campaignsWaitingForApprovalLength = action.payload.length
      })

    case OPEN_INFORMATION_MODAL_AFTER_FORMAT_CREATION:
      return produce(state, draft => {
        draft.showModalAfterFormatCreation = true
      })

    case CLOSE_INFORMATION_MODAL_AFTER_FORMAT_CREATION:
      return produce(state, draft => {
        draft.showModalAfterFormatCreation = false
      })

    case SET_COMMUNITY_SPONSOR_DIRECT_CHANNEL_ID:
      return produce(state, draft => {
        draft.directChannelBetweenCommunityAndCompanyId = action.payload.channelId
      })

    case SET_CAMPAIGN_DATES_FOR_CURRENT_MONTH:
      return produce(state, draft => {
        draft.campaignMonthStartDate = action.payload.startDate
        draft.campaignMonthEndDate = action.payload.endDate
      })

    case SET_CALLBACK_UPDATE_CAMPAIGN:
      return produce(state, draft => {
        draft.callbackUpdateCampaign = action.payload.value
      })

    default:
      return state
  }
}
