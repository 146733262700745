import styled from 'styled-components'

export const FileContainer = styled.div`
  display: ${props => (props.hasAdditionalTextMessage ? 'initial' : 'flex')};
  align-items: center;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-decoration: none;

  & a {
    text-decoration: none;
    color: #fff;
  }
`

export const FileIconsContainer = styled.div`
  display: flex;
  padding: 5px;
  border-radius: 5px;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.3);
`

export const FileInformations = styled.div`
  display: flex;
  padding-left: 10px;
`

export const FileDetails = styled.p`
  font-size: 10px;
  margin-bottom: 0px;
`

export const FileName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  margin-left: 6px;
`
