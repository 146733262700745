import { isSameDay, addDays, differenceInDays, eachDayOfInterval, addMonths, isWithinInterval } from 'date-fns'

function identifyBlockedDays({ availableDays, durationInDays, setCurrMonth, hasSelected }) {
  let consecutiveStart = null
  const consecutiveDays = []

  availableDays.forEach((currentDay, index) => {
    // armazenamos o dia anterior
    const previousDay = availableDays[index - 1]

    // se o dia anterior não for o dia anterior do dia atual
    // então vamos definir o dia atual como o início da sequência
    if (!isSameDay(addDays(previousDay, 1), currentDay)) {
      consecutiveStart = null
    }

    // se o dia consecutivo não foi definido, então vamos definir o dia atual como o início da sequência
    if (!consecutiveStart) {
      consecutiveStart = currentDay
    }

    // se a diferença entre o dia atual e o início da sequência for igual a duração da campanha
    // então temos uma sequência de dias consecutivos que atendem a duração da campanha
    if (differenceInDays(currentDay, consecutiveStart) === durationInDays) {
      consecutiveDays.push(consecutiveStart)

      // aqui estamos definindo o próximo dia como o início da próxima sequência
      // para que possamos encontrar a próxima sequência de dias consecutivos
      // que atendam a duração da campanha
      consecutiveStart = addDays(consecutiveStart, 1)
    }
  })

  const blockedDays = availableDays.filter(
    availableDay => !consecutiveDays.some(consecutiveDay => isSameDay(consecutiveDay, availableDay)),
  )

  if (!hasSelected) {
    setCurrMonth(consecutiveDays[0])
  }

  return blockedDays
}

export function getBlockedDaysToCampaign({ blockDays, durationInDays, setCurrMonth, hasSelected }) {
  const allDays = eachDayOfInterval({
    start: new Date(),
    end: addMonths(addDays(new Date(), durationInDays), 18),
  })

  const blockedDays = blockDays.reduce((acc, { from, to }) => {
    const days = eachDayOfInterval({
      start: from,
      end: to,
    })

    const uniqueDatesArray = [...acc, ...days]
      .map(date => {
        return date.getTime()
      })
      .filter((date, i, array) => {
        return array.indexOf(date) === i
      })
      .map(time => {
        return new Date(time)
      })

    return uniqueDatesArray
  }, [])

  const availableDays = allDays.filter(day => !blockedDays.find(block => isSameDay(block, day)))

  return identifyBlockedDays({
    availableDays,
    durationInDays,
    setCurrMonth,
    hasSelected,
  })
}

export function getDaysInRange({ date, durationInDays }) {
  if (date && durationInDays) {
    const to = addDays(date, durationInDays)

    return eachDayOfInterval({
      start: date,
      end: to,
    })
  }

  return []
}

export function getReasonsForBlock({ blockedDates, date }) {
  return blockedDates
    .filter(({ formattedDates }) => {
      const inTheInterval = formattedDates?.filter(range => {
        return !!isWithinInterval(date, {
          start: range.from,
          end: range.to,
        })
      })

      return inTheInterval
    })
    .map(({ reason }) => reason)
}
