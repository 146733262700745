import * as Yup from 'yup'
import i18n from '~/i18n'

export const getValidationSchema = minUnitPrice => {
  const yupValidator = Yup.object().shape({
    unitPrice: Yup.number()
      .required()
      .min(minUnitPrice, i18n.t('minimumOf', { min: minUnitPrice })),
    maxInParallel: Yup.number()
      .nullable()
      .max(999, i18n.t('maxOf999')),
  })

  return yupValidator
}

export const getTooltipOptions = id => [
  {
    target: `.maxInParallel__container-${id} .MuiTextField-root input`,
    disableBeacon: 'true',
    placement: 'left-start',
  },
]
