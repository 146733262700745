import axios from 'axios'
import { store } from '~/store'
import { signOut as signOutAction } from '~/store/modules/auth/actions'
import tokenRequestInterceptor from './tokenRequestInterceptor'

const networkApi = axios.create({
  baseURL: process.env.REACT_APP_NETWORK_API,
})

networkApi.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config
    const { dispatch } = store
    if (error?.response?.status === 401 && !originalRequest._retry && originalRequest.method === 'get') {
      dispatch(signOutAction())
    }
    return Promise.reject(error)
  },
)

networkApi.interceptors.request.use(tokenRequestInterceptor)

export default networkApi
