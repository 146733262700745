import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useAsync } from 'react-async'
import { getAllCommunitiesSponsoredByTheSponsor } from '~/services/requests'
import CustomSpinner from '~/components/CustomSpinner'
import StartNewCampaignStep1 from '~/pages/Sponsor/containers/StartNewCampaign/StartNewCampaignStep1'
import StartNewCampaignStep2 from '~/pages/Sponsor/containers/StartNewCampaign/StartNewCampaignStep2'
import { resetCampaignProcess as resetCampaignProcessAction } from '~/store/modules/createCampaign/actions'
import ModalCreditCardInformationTutorialMocked from '~/components/ApplicationTutorial//Sponsor/ModalCreditCardInformationTutorialMocked'
import Step from '~/components/Step'
import * as CONST from '~/helper/const'
import * as S from './styled'

export default function StartNewCampaignModal({
  handleCloseModal,
  showModal,
  setCampaignsWaitingCallback,
  setCampaignsRunningCallback,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showModalCreditCardInformationMocked, setShowModalCreditCardInformationMocked] = useState(false)
  const tutorialIsActive = useSelector(state => state.tutorial.tutorialIsActive)
  const step = useSelector(state => state.step.step)
  const { data, error, isPending } = useAsync({ promiseFn: getAllCommunitiesSponsoredByTheSponsor })
  if (error) return null
  if (isPending) return <CustomSpinner />

  const handleCloseModalAndResetCreateCampaignData = () => {
    handleCloseModal()
    if (setCampaignsWaitingCallback && setCampaignsRunningCallback) {
      setCampaignsWaitingCallback(Math.random())
      setCampaignsRunningCallback(Math.random())
    }
    dispatch(resetCampaignProcessAction())
  }

  const isStepOne = () => {
    if (step === 0) {
      return true
    }
    return false
  }

  const isStepTwo = () => {
    if (step === 1) {
      return true
    }
    return false
  }

  if (data) {
    const communitiesSponsored = data.data.rows
    const communitiesSponsoredWithCooperationOn = communitiesSponsored.filter(
      community => community.cooperationStatus === 1,
    )

    return step === 0 ? (
      <S.ModalCreateCampaignStep1
        step={step}
        backdrop="static"
        keyboard={false}
        show={showModal}
        onHide={handleCloseModalAndResetCreateCampaignData}
        tutorialIsActive={tutorialIsActive}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <S.Title>{t('newCampaign')}</S.Title>
          </Modal.Title>
        </Modal.Header>
        <S.Container>
          <S.StepContainer step={step}>
            {isStepOne() && <S.StepContainerTitle>{t('selectTheCommunity')}</S.StepContainerTitle>}
            {isStepTwo() && <S.StepContainerTitle>{t('setCampaign')}</S.StepContainerTitle>}
            <Step step={step} />
          </S.StepContainer>
        </S.Container>
        <Modal.Body style={CONST.customModalBodyStyle}>
          {step === 0 && <StartNewCampaignStep1 communities={communitiesSponsoredWithCooperationOn} />}
          {step === 1 && <StartNewCampaignStep2 />}
          {step === 2 && <StartNewCampaignStep2 />}
        </Modal.Body>

        {!isStepTwo() && (
          <S.ModalFooter>
            <S.CancelButton text={t('cancel')} onClick={handleCloseModalAndResetCreateCampaignData} />
          </S.ModalFooter>
        )}

        {isStepTwo() && (
          <S.ModalFooterStepTwo>
            <S.DoItLaterButton text={t('doItLater')} onClick={handleCloseModalAndResetCreateCampaignData} />
            <S.ManageButton
              text={t('manage')}
              onClick={handleCloseModalAndResetCreateCampaignData}
              icon={<S.ManageIcon />}
            />
          </S.ModalFooterStepTwo>
        )}
      </S.ModalCreateCampaignStep1>
    ) : (
      <S.ModalCreateCampaignStep2
        step={step}
        show={showModal}
        onHide={handleCloseModalAndResetCreateCampaignData}
        tutorialIsActive={tutorialIsActive}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <S.Title>{t('newCampaign')}</S.Title>
          </Modal.Title>
        </Modal.Header>
        <S.Container>
          <S.StepContainer step={step}>
            {isStepOne() && <S.StepContainerTitle>{t('selectTheCommunity')}</S.StepContainerTitle>}
            {isStepTwo() && <S.StepContainerTitle>{t('setCampaign')}</S.StepContainerTitle>}
            <Step step={step} />
          </S.StepContainer>
        </S.Container>
        <Modal.Body style={CONST.customModalBodyStyle}>
          {step === 0 && <StartNewCampaignStep1 communities={communitiesSponsoredWithCooperationOn} />}
          {step === 1 && (
            <StartNewCampaignStep2
              showModalCreditCardMocked={showModalCreditCardInformationMocked}
              openCreditCardMockedModal={() => setShowModalCreditCardInformationMocked(true)}
              handleCloseModalCreateCampaign={handleCloseModal}
            />
          )}
          {step === 2 && <StartNewCampaignStep2 />}
        </Modal.Body>

        <S.ModalFooter>
          <S.CancelButton text={t('cancel')} onClick={handleCloseModalAndResetCreateCampaignData} />
        </S.ModalFooter>

        <ModalCreditCardInformationTutorialMocked
          handleCloseModal={() => setShowModalCreditCardInformationMocked(false)}
          showModal={showModalCreditCardInformationMocked}
        />
      </S.ModalCreateCampaignStep2>
    )
  }
}
