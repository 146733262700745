/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import { InputAdornment } from '@material-ui/core'
import Spinner from 'react-bootstrap/Spinner'
import { useSelector } from 'react-redux'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import { InputTitle } from '~/components/Shared/StyledComponents'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import ContentVisualization from '~/components/CreateFormatMobileVisualization/Content'
import { transformDateToUtcFormat } from '~/helper/getFormattedDates'
import DisplayCriteriaSelect from '~/components/DisplayCriteriaSelect'

import 'react-lazy-load-image-component/src/effects/blur.css'
import * as S from './styled'

export default function ModalPreviewWeb({
  handleCloseModal,
  showModal,
  webToUpdate,
  review,
  createMessage,
  refuseCampaignProduct,
  approveCampaignProduct,
  sponsor,
}) {
  const { t } = useTranslation()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const [loadingDebounce, setLoadingDebounce] = useState(false)
  const [scrapperDescription, setScrapperDescription] = useState(webToUpdate.scrapperDescription)
  const [scrapperTitle, setScrapperTitle] = useState(webToUpdate.scrapperTitle)
  const [scrapperImage, setScrapperImage] = useState(null)
  const [scrapperTags, setScrapperTags] = useState(null)
  const [scrapperLink, setScrapperLink] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const { webpage, comment, image } = webToUpdate
  const [updatingImage, setUpdatingImage] = useState(false)
  const [loadingImage, setLoadingImage] = useState(false)
  const [imageId, setImageId] = useState(image)
  const [imageUrl, setImageUrl] = useState(webToUpdate.imageUrl)
  const [date, setDate] = useState(transformDateToUtcFormat(webToUpdate.date))
  const [endDate, setEndDate] = useState(transformDateToUtcFormat(webToUpdate.endDate))
  const { handleSubmit, setValue, register, control, errors } = useForm()
  const { editProductAction } = useSponsorFunctions()

  function modalReviewCreateMessageAndCloseModal() {
    createMessage()
    refuseCampaignProduct()
    handleCloseModal()
  }

  function onSubmit(product) {
    const copyObject = JSON.stringify(webToUpdate)
    const productCopy = JSON.stringify(product)
    if (copyObject === productCopy) {
      return
    }
    editProductAction('content/web', product)
    handleCloseModal()
    setUpdatingImage(false)
  }

  function handleUpdatingImage() {
    setUpdatingImage(!updatingImage)
  }

  useEffect(() => {
    register({ name: 'id' })
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
    setValue('id', webToUpdate.id)
  }, [register, webToUpdate.id, setValue, imageId, imageUrl])

  useEffect(() => {
    register({ name: 'scrapperTitle' })
    register({ name: 'scrapperLink' })
    register({ name: 'scrapperDescription' })
    setValue('scrapperTitle', webToUpdate.scrapperTitle)
    setValue('scrapperLink', webToUpdate.scrapperLink)
    setValue('scrapperDescription', webToUpdate.scrapperDescription)
  }, [scrapperTitle, scrapperLink, scrapperDescription])

  return (
    <ProductsModal
      review={review}
      createMessage={modalReviewCreateMessageAndCloseModal}
      approveCampaignProduct={approveCampaignProduct}
      action={onSubmit}
      buttonText={review ? t('done') : t('update')}
      modalTitle={review ? t('reviseFormat', { format: t('webpage') }) : t('editFormat', { format: t('webpage') })}
      handleCloseModal={handleCloseModal}
      showModal={showModal}
      sponsor={sponsor}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <InputTitle>{t('pastTheWebpageLink')}</InputTitle>
            <Controller
              as={
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="https://www.example.com"
                  disabled={review || false}
                  InputProps={{
                    endAdornment: review ? null : (
                      <InputAdornment position="end">
                        {loadingDebounce ? <Spinner animation="border" size="sm" /> : ''}
                      </InputAdornment>
                    ),
                  }}
                />
              }
              rules={{ required: t('defaultValidation', { inputName: t('webpage') }) }}
              name="webpage"
              control={control}
              defaultValue={webpage === null ? '' : webpage}
            />
            <div className="error-message">
              <ErrorMessage errors={errors} name="webpage" as="p" />
            </div>

            <div className="mt-3">
              <InputTitle>{t('title')}</InputTitle>
              <Controller
                as={<TextField fullWidth variant="outlined" placeholder={t('typeTitle')} disabled={review || false} />}
                rules={{ required: t('defaultValidation', { inputName: t('title') }) }}
                name="scrapperTitle"
                control={control}
                defaultValue={scrapperTitle}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="title" as="p" />
              </div>
            </div>

            <div className="mt-3">
              <InputTitle>{t('description')}</InputTitle>

              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={t('typeDescription')}
                    disabled={review || false}
                  />
                }
                rules={{ required: t('defaultValidation', { inputName: t('description') }) }}
                name="scrapperDescription"
                control={control}
                defaultValue={scrapperDescription}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="scrapperDescription" as="p" />
              </div>
            </div>

            <DisplayCriteriaSelect control={control} value={webToUpdate?.privacy} disabled={review || false} />

            <StartDateSection
              product={t('webpage')}
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              disabled={review}
              control={control}
              errors={errors}
            />

            <EndDateSection
              product={t('webpage')}
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              disabled={review}
              control={control}
              errors={errors}
            />

            <div className="mt-3">
              <S.InputTitle>{t('careToComment')}</S.InputTitle>
              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={t('writeSomething')}
                    disabled={review || false}
                  />
                }
                name="comment"
                control={control}
                defaultValue={comment === null ? '' : comment}
              />
            </div>
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          FormatVisualizationComponent={
            <ContentVisualization
              loadingImage={loadingImage}
              image={imageUrl}
              title={scrapperTitle}
              about={scrapperDescription}
              contentType={t('web')}
            />
          }
        />
      }
    />
  )
}
