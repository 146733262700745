import React from 'react'
import { ResponsiveAnimation } from '~/components/ResposiveAnimation'
import * as S from './styles'

const data = [
  { title: 'Training', image: '/images/training.svg' },
  { title: 'Effective Campaigns', image: '/images/campaigns.svg' },
  { title: 'Higher satisfaction of your sponsors', image: '/images/satisfaction.svg' },
  { title: 'Higher Revenues', image: '/images/revenue.svg' },
]

export const Opportunities = () => {
  return (
    <S.Container>
      <ResponsiveAnimation type="fade" applyOn="both" effects={{ direction: 'left' }}>
        <h1>We create opportunities by training your teams!</h1>
        <h2>Our model represents a great way to involve young generations and digital people in your community.</h2>
      </ResponsiveAnimation>

      <ResponsiveAnimation type="fade" applyOn="both" effects={{ direction: 'right', duration: 1500 }}>
        <S.CardsContainer>
          {data.map(item => (
            <S.Card key={item.title}>
              <S.CardImage src={item.image} alt={item.title} />
              <h3>{item.title}</h3>
            </S.Card>
          ))}
        </S.CardsContainer>
      </ResponsiveAnimation>
    </S.Container>
  )
}
