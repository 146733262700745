import styled from 'styled-components'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { MdClose, MdKeyboardArrowUp } from 'react-icons/md'
import { IoIosImages } from 'react-icons/io'
import orangeBackgroundImage from '~/../public/bottom-stories-background.jpg'

export const TopPreviewContainer = styled.div``

export const PostHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0px;
  padding: 10px 10px 0px 10px;
  z-index: 10;
  width: 100%;
`

export const CommunityInformations = styled.div`
  display: flex;
  align-items: center;
  z-index: 10;
`

export const CommunityLogoBorder = styled.div`
  background: linear-gradient(45deg, hsl(37, 97%, 70%) 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  width: 42px;
  height: 42px;
  border-radius: 100px;
  padding: 2px;
`

export const CommunityNameContainerTop = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;
  margin-left: 8px;
`

export const CommunityNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;
  position: absolute;
  bottom: 105px;
`

export const BottomStoriesBanner = styled.img`
  z-index: 10;
  aspect-ratio: 530/73;
  width: 100%;
  bottom: 0;
  object-fit: cover;
  position: absolute;
  bottom: 0;
`

export const CommunityLogo = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 100px;
`

export const CommunityName = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  max-width: 170px;
  margin-bottom: 0px;
  text-shadow: 0px 1px 4px #000000;
`

export const Sponsored = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 13px;
  margin-bottom: 0px;
  margin-top: -4px;
  margin-right: 24px;
  text-shadow: 0px 1px 4px #000000;
`

export const MiddlePreviewContainer = styled.div`
  background-color: #000000;
  width: 100%;
  height: 100%;
`

export const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 544px;
  background-color: #ccc;
  z-index: 1;
`

export const NoMediaIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 530/73;
  background-color: #ccc;
  z-index: 1;
`

export const ImageIcon = styled(IoIosImages).attrs({
  size: 28,
  color: 'red',
})``

export const MediaImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const ImageBackground = styled.img.attrs({
  src: orangeBackgroundImage,
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 0px;
`

export const Actions = styled.div``

export const Views = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  margin-left: 16px;
  margin-bottom: 0px;
`

export const CommunityPostName = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-right: 8px;
  width: 100%;
`

export const StoriesTitle = styled.p`
  color: #e4e6eb;
  font-family: Lato;
  font-size: 18px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-width: 310px;
  max-height: 120px;
  font-weight: bold;
  margin-left: 10px;
  text-shadow: 0px 1px 4px #000000;
`

export const StoriesDescription = styled(StoriesTitle)`
  -webkit-line-clamp: 4;
  display: -webkit-box;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-weight: 400;
  font-size: 15px;
`

export const ThreeDotsIcon = styled(BiDotsHorizontalRounded).attrs({
  size: 24,
  color: '#FFF',
})`
  position: absolute;
  right: 48px;
  z-index: 10;
`

export const CloseIcon = styled(MdClose).attrs({
  size: 28,
  color: '#FFF',
})`
  z-index: 10;
`

export const ArrowUp = styled(MdKeyboardArrowUp).attrs({
  size: 28,
  color: '#FFF',
})`
  z-index: 10;
`

export const CheckNowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 115px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  margin-left: 14px;
`

export const CheckNow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1863a;
  color: #fff;
  max-width: 140px;
  padding: 0px 10px;
  height: 26px;
  border-radius: 100px;
  font-size: 12px;
  font-family: Lato;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  align-self: center;
`
