import firebase from 'firebase'
import 'firebase/auth'

const communityFirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  transcodedVideoBucket: process.env.REACT_APP_MOBILE_TRANSCODED_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const communityFirebase = firebase.apps.find(item => item.name === 'community')
  ? firebase.app('community')
  : firebase.initializeApp(communityFirebaseConfig, 'community')

export const getFireauth = () => {
  return communityFirebase.auth()
}

export const getFirestore = () => {
  return communityFirebase.firestore()
}

export const getFireStorage = () => {
  return communityFirebase.storage(communityFirebaseConfig.storageBucket)
}
