import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useCampaignSelectors = () => {
  const surveyQuantity = useSelector(state => state.createCampaign.campaignProducts.survey.quantity)
  const welcomeAdQuantity = useSelector(state => state.createCampaign.campaignProducts.welcomeAd.quantity)
  const welcomeAdPrice = useSelector(state => state.createCampaign.campaignProducts.welcomeAd.price)
  const surveyPrice = useSelector(state => state.createCampaign.campaignProducts.survey.price)
  const campaignId = useSelector(state => state.createCampaign.campaignId)
  const allProductsAddedByUser = useSelector(state => state.createCampaign.campaignProducts)
  const rewardQuantity = useSelector(state => state.createCampaign.campaignProducts.reward.quantity)
  const eventQuantity = useSelector(state => state.createCampaign.campaignProducts.event.quantity)
  const allEventsAddedByUser = useSelector(state => state.createCampaign.campaignProducts.event.events)
  const allWelcomeAdsAddedByUser = useSelector(state => state.createCampaign.campaignProducts.welcomeAd.welcomeAds)
  const eventPrice = useSelector(state => state.createCampaign.campaignProducts.event.price)
  const numberOfEventsAddedByUser = useSelector(state => state.createCampaign.campaignProducts.event.events.length)
  const allContentAddedByUser = useSelector(state => state.createCampaign.campaignProducts.content)
  const mobileBoostQuantity = useSelector(state => state.createCampaign.campaignProducts.mobileBoost.quantity)
  const rewardPrice = useSelector(state => state.createCampaign.campaignProducts.reward.price)
  const chatChannelQuantity = useSelector(state => state.createCampaign.campaignProducts.chatChannel.quantity)
  const contentQuantity = useSelector(state => state.createCampaign.campaignProducts.content.quantity)
  const chatChannelPrice = useSelector(state => state.createCampaign.campaignProducts.chatChannel.price)
  const contentPrice = useSelector(state => state.createCampaign.campaignProducts.content.price)
  const mobileBoostPrice = useSelector(state => state.createCampaign.campaignProducts.mobileBoost.price)
  const allRewardsAddedByUser = useSelector(state => state.createCampaign.campaignProducts.reward.rewards)
  const allChatChannelsAddedByUser = useSelector(state => state.createCampaign.campaignProducts.chatChannel.channels)
  const numberOfRewardsAddedByUser = useSelector(state => state.createCampaign.campaignProducts.reward.rewards.length)
  const numberOfSurveysAddedByUser = useSelector(state => state.createCampaign.campaignProducts.survey.surveys.length)
  const allSurveysAddedByUser = useSelector(state => state.createCampaign.campaignProducts.survey.surveys)
  const allWebsAddedByUser = useSelector(state => state.createCampaign.campaignProducts.content.web.webs)
  const allPostsAddedByUser = useSelector(state => state.createCampaign.campaignProducts.content.post.posts)
  const allVideosAddedByUser = useSelector(state => state.createCampaign.campaignProducts.content.video.videos)
  const allDocumentsAddedByUser = useSelector(state => state.createCampaign.campaignProducts.content.document.documents)
  const startCampaignDate = useSelector(state => state.createCampaign.campaignDates.dtStart)
  const endCampaignDate = useSelector(state => state.createCampaign.campaignDates.dtEnd)
  const campaignGender = useSelector(state => state.createCampaign.campaignTarget.gender)
  const campaignAgeLevel = useSelector(state => state.createCampaign.campaignTarget.ageLevel)
  const campaignInterestAreas = useSelector(state => state.createCampaign.campaignTarget.interestArea)
  const interestAreas = useSelector(state => state.createCampaign.campaignTarget.interestArea)
  const numberOfWebsAddedByUser = useSelector(state => state.createCampaign.campaignProducts.content.web.webs.length)
  const numberOfPostsAddedByUser = useSelector(state => state.createCampaign.campaignProducts.content.post.posts.length)
  const currentCommunityForCreateCampaignProccess = useSelector(state => state.createCampaign.community)
  const sponsorS3Logo = useSelector(state => state.sponsor.s3SponsorLogo.url)
  const sponsorS3LogoId = useSelector(state => state.sponsor.s3SponsorLogo.id)
  const sponsorS3Banner = useSelector(state => state.sponsor.s3SponsorBanner.url)
  const sponsorS3BannerId = useSelector(state => state.sponsor.s3SponsorBanner.id)
  const isCampaignBeingUpdated = useSelector(state => state.createCampaign.campaignUpdate)
  const stripePaymentIntentAlreadyCreated = useSelector(state => state.createCampaign.stripePaymentIntentAlreadyCreated)
  const campaignTargetNumber = useSelector(state => state.createCampaign.campaignTarget.targetNumber)
  const stripeTemporaryCampaignIdForPaymentIntentCreation = useSelector(
    state => state.createCampaign.stripeTemporaryCampaignIdForPaymentIntentCreation,
  )
  const numberOfWelcomeAdsAddedByUser = useSelector(
    state => state.createCampaign.campaignProducts.welcomeAd.welcomeAds.length,
  )
  const numberOfChatChannelsAddedByUser = useSelector(
    state => state.createCampaign.campaignProducts.chatChannel.channels.length,
  )
  const allAnnouncementsAddedByUser = useSelector(
    state => state.createCampaign.campaignProducts.content.announcement.announcements,
  )
  const numberOfVideosAddedByUser = useSelector(
    state => state.createCampaign.campaignProducts.content.video.videos.length,
  )
  const numberOfAnnouncementsAddedByUser = useSelector(
    state => state.createCampaign.campaignProducts.content.announcement.announcements.length,
  )
  const numberOfDocumentsAddedByUser = useSelector(
    state => state.createCampaign.campaignProducts.content.document.documents.length,
  )

  const actions = useMemo(
    () => ({
      surveyQuantity() {
        return surveyQuantity
      },

      campaignTargetNumber() {
        return campaignTargetNumber
      },

      welcomeAdQuantity() {
        return welcomeAdQuantity
      },

      welcomeAdPrice() {
        return welcomeAdPrice
      },

      allWelcomeAdsAddedByUser() {
        return allWelcomeAdsAddedByUser
      },

      campaignId() {
        return campaignId
      },

      allContentAddedByUser() {
        return allContentAddedByUser
      },

      numberOfWelcomeAdsAddedByUser() {
        return numberOfWelcomeAdsAddedByUser
      },

      surveyPrice() {
        return surveyPrice
      },

      stripeTemporaryCampaignIdForPaymentIntentCreation() {
        return stripeTemporaryCampaignIdForPaymentIntentCreation
      },

      eventQuantity() {
        return eventQuantity
      },

      eventPrice() {
        return eventPrice
      },

      campaignAgeLevel() {
        return campaignAgeLevel
      },

      campaignGender() {
        return campaignGender
      },

      isCampaignBeingUpdated() {
        return isCampaignBeingUpdated
      },

      stripePaymentIntentAlreadyCreated() {
        return stripePaymentIntentAlreadyCreated
      },

      campaignInterestAreas() {
        return campaignInterestAreas
      },

      interestAreas() {
        return interestAreas
      },

      allEventsAddedByUser() {
        return allEventsAddedByUser
      },

      numberOfEventsAddedByUser() {
        return numberOfEventsAddedByUser
      },

      numberOfSurveysAddedByUser() {
        return numberOfSurveysAddedByUser
      },

      allProductsAddedByUser() {
        return allProductsAddedByUser
      },

      allSurveysAddedByUser() {
        return allSurveysAddedByUser
      },

      rewardQuantity() {
        return rewardQuantity
      },

      rewardPrice() {
        return rewardPrice
      },

      mobileBoostPrice() {
        return mobileBoostPrice
      },

      chatChannelQuantity() {
        return chatChannelQuantity
      },

      contentQuantity() {
        return contentQuantity
      },

      chatChannelPrice() {
        return chatChannelPrice
      },

      contentPrice() {
        return contentPrice
      },

      allRewardsAddedByUser() {
        return allRewardsAddedByUser
      },

      allChatChannelsAddedByUser() {
        return allChatChannelsAddedByUser
      },

      allWebsAddedByUser() {
        return allWebsAddedByUser
      },

      allVideosAddedByUser() {
        return allVideosAddedByUser
      },

      allAnnouncementsAddedByUser() {
        return allAnnouncementsAddedByUser
      },

      allDocumentsAddedByUser() {
        return allDocumentsAddedByUser
      },

      allPostsAddedByUser() {
        return allPostsAddedByUser
      },

      numberOfRewardsAddedByUser() {
        return numberOfRewardsAddedByUser
      },

      numberOfChatChannelsAddedByUser() {
        return numberOfChatChannelsAddedByUser
      },

      numberOfWebsAddedByUser() {
        return numberOfWebsAddedByUser
      },

      numberOfPostsAddedByUser() {
        return numberOfPostsAddedByUser
      },

      numberOfVideosAddedByUser() {
        return numberOfVideosAddedByUser
      },

      numberOfAnnouncementsAddedByUser() {
        return numberOfAnnouncementsAddedByUser
      },

      numberOfDocumentsAddedByUser() {
        return numberOfDocumentsAddedByUser
      },

      mobileBoostQuantity() {
        return mobileBoostQuantity
      },

      startCampaignDate() {
        return startCampaignDate
      },

      endCampaignDate() {
        return endCampaignDate
      },

      currentCommunityForCreateCampaignProccess() {
        return currentCommunityForCreateCampaignProccess
      },

      sponsorS3Logo() {
        return sponsorS3Logo
      },

      sponsorS3LogoId() {
        return sponsorS3LogoId
      },

      sponsorS3Banner() {
        return sponsorS3Banner
      },

      sponsorS3BannerId() {
        return sponsorS3BannerId
      },
    }),
    [
      surveyQuantity,
      stripePaymentIntentAlreadyCreated,
      campaignTargetNumber,
      campaignId,
      stripeTemporaryCampaignIdForPaymentIntentCreation,
      welcomeAdQuantity,
      welcomeAdPrice,
      allWelcomeAdsAddedByUser,
      allContentAddedByUser,
      numberOfWelcomeAdsAddedByUser,
      surveyPrice,
      eventQuantity,
      eventPrice,
      campaignAgeLevel,
      campaignGender,
      isCampaignBeingUpdated,
      campaignInterestAreas,
      interestAreas,
      allEventsAddedByUser,
      numberOfEventsAddedByUser,
      numberOfSurveysAddedByUser,
      allProductsAddedByUser,
      allSurveysAddedByUser,
      rewardQuantity,
      rewardPrice,
      mobileBoostPrice,
      chatChannelQuantity,
      contentQuantity,
      chatChannelPrice,
      contentPrice,
      allRewardsAddedByUser,
      allChatChannelsAddedByUser,
      allWebsAddedByUser,
      allVideosAddedByUser,
      allAnnouncementsAddedByUser,
      allDocumentsAddedByUser,
      allPostsAddedByUser,
      numberOfRewardsAddedByUser,
      numberOfChatChannelsAddedByUser,
      numberOfWebsAddedByUser,
      numberOfPostsAddedByUser,
      numberOfVideosAddedByUser,
      numberOfAnnouncementsAddedByUser,
      numberOfDocumentsAddedByUser,
      mobileBoostQuantity,
      startCampaignDate,
      endCampaignDate,
      currentCommunityForCreateCampaignProccess,
      sponsorS3Logo,
      sponsorS3LogoId,
      sponsorS3Banner,
      sponsorS3BannerId,
    ],
  )
  return actions
}

export default useCampaignSelectors
