import React from 'react'
import * as S from './styles'

// eslint-disable-next-line react/display-name
const TextArea = React.forwardRef(({ label, ...props }, ref) => {
  return (
    <S.Container>
      <S.Label>
        {label}
        <S.TextArea {...props} ref={ref} />
      </S.Label>
    </S.Container>
  )
})

export default TextArea
