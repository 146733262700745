import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ShouldRender from '~/components/ShouldRender'
import * as S from './styled'

const Avatar = ({ userId, name, onPress, size, image }) => {
  const { users } = useSelector(state => state.firebaseUsers)
  const index = users && users.findIndex(x => x.id === userId)
  const [error, setError] = useState(false)

  const getInitials = () => {
    if (!name) {
      return 'UN'
    }

    const parts = name && name.trim().split(' ')

    const initials =
      parts.length > 1 ? `${parts[0].substr(0, 1)}${parts[parts.length - 1].substr(0, 1)}` : parts[0].substr(0, 2)

    return initials.toUpperCase()
  }

  return (
    <S.Container className={`${size ? `size-${size}` : ''}`} onClick={onPress && onPress} role="button">
      <ShouldRender if={(!image && (!users || !users[index] || !users[index].image)) || !!error}>
        <S.InitialsContainer className={`${size ? `size-${size}` : ''}`}>{getInitials()}</S.InitialsContainer>
      </ShouldRender>

      <ShouldRender if={(!error && !!users && users[index] && users[index].image) || (!error && !!image)}>
        <S.UserAvatar
          className={`${size ? `size-${size}` : ''}`}
          src={(!!users && users[index] && users[index].image) || image}
          alt="user avatar"
          onError={() => {
            setError(true)
          }}
        />
      </ShouldRender>
    </S.Container>
  )
}

export default Avatar
