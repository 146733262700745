/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React from 'react'
import { MdPersonOutline, MdHourglassEmpty } from 'react-icons/md'
import { IoMdFemale, IoMdMale } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { FilePond, registerPlugin } from 'react-filepond'
import { store } from '~/store'
import getYouTubeID from 'get-youtube-id'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidationType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import { toast } from 'react-toastify'
import atob from 'atob'
import { uuid } from 'uuidv4'
import * as CONST from '~/helper/const'
import api from '~/services/api'
import networkApi from '~/services/networkApi'
import i18n from '~/i18n'

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidationType,
  FilePondPluginFileValidateSize,
)

export const mountCouponTypeComboWithId = (couponTypeLabel, couponTypeCombo) => {
  let couponTypeObject = null
  if (couponTypeLabel === undefined || couponTypeLabel === null || couponTypeLabel === '') {
    return null
  }
  if (couponTypeLabel != null) {
    couponTypeCombo.forEach(couponType => {
      if (couponTypeLabel.label === couponType.label) {
        couponTypeObject = couponType
      }
    })
  }
  return couponTypeObject
}

export const UploadS3 = ({ setUploadId, setUploadUrl, fileType, setLoadingUpload }) => (
  <FilePond
    allowMultiple={false}
    acceptedFileTypes={
      fileType === 'image' ? ['image/jpeg', 'image/png', 'image/bmp', 'image/tiff', 'image/gif'] : null
    }
    onprocessfilerevert={() => {
      setUploadId(null)
      setUploadUrl(null)
    }}
    maxFileSize="10MB"
    server={{
      process: (fieldName, file, metadata, load, error, progress) => {
        setLoadingUpload(true)
        const formData = new FormData()
        const request = new XMLHttpRequest()
        const { token } = store.getState().auth

        request.open('POST', `${process.env.REACT_APP_NETWORK_API}/uploads`)
        request.setRequestHeader('Authorization', `Bearer ${token}`)

        request.upload.onprogress = e => {
          progress(e.lengthComputable, e.loaded, e.total)
        }

        formData.append('file', file)

        request.onload = () => {
          if (request.status >= 200 && request.status < 300) {
            const response = JSON.parse(request.response)
            load(request.responseText)
            setUploadId(response?.data[0]?.id)
            setUploadUrl(response?.data[0]?.url)
            setLoadingUpload(false)
          } else {
            toast.error(i18n.t('errorUploadFile'))
            setLoadingUpload(false)
          }
        }
        request.send(formData)
      },
    }}
  />
)

export const UploadCommunityVideo = ({ setUploadId }) => (
  <FilePond
    allowMultiple={false}
    server={{
      process: (fieldName, file, metadata, load, error, progress) => {
        const formData = new FormData()
        const request = new XMLHttpRequest()
        request.open('POST', `${process.env.REACT_APP_BASE_API}/s3`)
        request.upload.onprogress = e => {
          progress(e.lengthComputable, e.loaded, e.total)
        }
        formData.append('file', file)
        formData.append('uploadTypeName', 'file')
        formData.append('title', file.name)
        formData.append('isCoverImage', true)
        request.onload = () => {
          if (request.status >= 200 && request.status < 300) {
            const response = JSON.parse(request.response)
            load(request.responseText)
            setUploadId(response.upload_id)
          } else {
            toast.error(i18n.t('errorUploadFile'))
          }
        }
        request.send(formData)
      },
    }}
  />
)

export const UploadCommunityImage = ({ setUploadId, setUploadUrl }) => (
  <FilePond
    allowMultiple
    server={{
      process: (fieldName, file, metadata, load, error, progress) => {
        const formData = new FormData()
        const request = new XMLHttpRequest()
        request.open('POST', `${process.env.REACT_APP_BASE_API}/s3`)
        request.upload.onprogress = e => {
          progress(e.lengthComputable, e.loaded, e.total)
        }
        formData.append('file', file)
        formData.append('uploadTypeName', 'image')
        formData.append('title', file.name)
        formData.append('isCoverImage', true)
        request.onload = () => {
          if (request.status >= 200 && request.status < 300) {
            const response = JSON.parse(request.response)
            load(request.responseText)
            setUploadId(response.upload_id)
            setUploadUrl(response.URL)
          } else {
            toast.error(i18n.t('errorUploadFile'))
          }
        }
        request.send(formData)
      },
    }}
  />
)

export const uploadS3Link = async (image, link, setImageUrl, setImageId, setLoadingImage) => {
  setLoadingImage(true)

  const { token } = store.getState().auth

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  const formData = new FormData()

  formData.append('imageUrl', image)

  networkApi.defaults.headers.Authorization = `Bearer ${token}`

  const response = await networkApi.post('/uploads', formData, config)

  const { id, url } = response?.data?.data[0]

  setImageUrl(url)
  setImageId(id)
  setLoadingImage(false)
}

export const getWebscrapperInformations = async (
  url,
  sponsorManagerId,
  communityId,
  setScrapperTitle,
  setScrapperDescription,
  setScrapperTags,
  setLoadingDebounce,
  setScrapperLink,
  setImageUrl,
  setImageId,
  setLoadingImage,
  setIsUpdate,
  uploadTypeName,
  setLinkError,
) => {
  try {
    // Right now the api has a validation and if the webscrapper link doesn't have a image, we can't send it null
    // because imageUrl is required
    const defaultImage = 'https://ik.imagekit.io/6hyjvncrrqx/community-default-banner-picture_r9YOPIiAIU.jpg'

    const { token } = store.getState().auth

    networkApi.defaults.headers.Authorization = `Bearer ${token}`

    const response = await networkApi.get(`/utils/extract?url=${url}`)

    const { title, description, imageUrl, providerUrl: link } = response?.data?.data || {}

    setScrapperTitle(title)
    setScrapperDescription(description)
    setScrapperLink(link)

    if (setLinkError) {
      setLinkError(false)
    }

    uploadS3Link(imageUrl || defaultImage, url, setImageUrl, setImageId, setLoadingImage)
    setIsUpdate(true)
    setLoadingDebounce(false)
  } catch (error) {
    toast.error(i18n.t('unableGetLinkInformations'))
    if (setLinkError) {
      setLinkError(true)
    }
    setLoadingDebounce(false)
  }
}

export const blobToFile = (blob, fileName) => {
  blob.name = fileName
  return blob
}

export const base64ImageToBlob = base64 => {
  const pos = base64.indexOf(';base64,')
  const type = base64.substring(5, pos)
  const b64 = base64.substr(pos + 8)

  const imageContent = atob(b64)

  const buffer = new ArrayBuffer(imageContent.length)
  const view = new Uint8Array(buffer)

  for (let n = 0; n < imageContent.length; n += 1) {
    view[n] = imageContent.charCodeAt(n)
  }

  const blob = new Blob([buffer], { type })

  const file = blobToFile(blob, uuid())
  return file
}

export const prepareToSendCommunityObject = onSubmitFunc => data => {
  const transformData = data
  if (transformData.categories !== '' && transformData.categories !== null && transformData.categories !== undefined) {
    const arrayIds = []
    transformData.categories.forEach(category => {
      arrayIds.push(category.value)
    })
    transformData.categories = arrayIds
  }
  if (transformData.qtdMembers !== '' && transformData.qtdMembers !== null && transformData.qtdMembers !== undefined) {
    transformData.qtdMembers = transformData.qtdMembers.value
  }

  if (transformData?.language === undefined) {
    transformData.language = 'en'
  } else {
    transformData.language = transformData?.language?.value
  }
  onSubmitFunc(transformData)
}

export const prepareToSendSponsorObject = onSubmitFunc => data => {
  const transformData = data
  if (transformData.categories !== '' && transformData.categories !== null && transformData.categories !== undefined) {
    const arrayIds = []
    transformData.categories.forEach(category => {
      arrayIds.push(category.value)
    })
    transformData.categories = arrayIds
  }
  if (transformData.qtdMembers !== '' && transformData.qtdMembers !== null && transformData.qtdMembers !== undefined) {
    transformData.qtdMembers = transformData.qtdMembers.value
  }

  if (transformData.manager.language === undefined) {
    transformData.manager.language = 'en'
  } else {
    transformData.manager.language = transformData.manager.language.value
  }
  onSubmitFunc(transformData)
}

export const prepareToSendSurvey = onSubmitFunc => data => {
  const transformData = data
  if (transformData.optionThree === '') {
    delete transformData.optionThree
  }
  if (transformData.optionFour === '') {
    delete transformData.optionFour
  }
  if (transformData.optionFive === '') {
    delete transformData.optionFive
  }
  onSubmitFunc(transformData)
}

export const mountMultiselectCombo = (ids, comboOptions) => {
  const comboObject = []
  if (ids === undefined || ids === null || ids === '') {
    return null
  }
  if (ids !== undefined || ids !== null) {
    ids.forEach(optionCombo => {
      if (comboOptions !== null || comboOptions !== undefined) {
        comboOptions.forEach(option => {
          if (optionCombo.id === option.value) {
            comboObject.push(option)
          }
        })
      }
    })
  }
  return comboObject
}

export const returnCommunitySponsorsDataFiltered = (filter, dataToBeFilter) => {
  const lowercasedFilter = filter.toLowerCase()
  const filteredData = dataToBeFilter?.filter(data => {
    return data?.companyName?.toLowerCase().includes(lowercasedFilter)
  })
  return filteredData
}

export const returnDataFiltered = (filter, dataToBeFilter) => {
  const lowercasedFilter = filter.toLowerCase()

  const filteredData = dataToBeFilter?.filter(data => {
    return data?.name?.toLowerCase().includes(lowercasedFilter)
  })
  return filteredData
}

export const returnCommunitiesDataFiltered = (filter, dataToBeFilter) => {
  const lowercasedFilter = filter.toLowerCase()

  const filteredData = dataToBeFilter?.filter(data => {
    return data?.communityName?.toLowerCase().includes(lowercasedFilter)
  })
  return filteredData
}

export const returnProductsFilteredByTitle = (filter, dataToBeFilter) => {
  const lowercasedFilter = filter.toLowerCase()

  const filteredData = dataToBeFilter?.filter(data => {
    return data?.product?.name?.toLowerCase().includes(lowercasedFilter)
  })
  return filteredData
}

export const returnSponsorDataFiltered = (filter, dataToBeFilter) => {
  const lowercasedFilter = filter.toLowerCase()

  const filteredData = dataToBeFilter?.filter(data => {
    return data?.companyName?.toLowerCase().includes(lowercasedFilter)
  })
  return filteredData
}

export function maskEmail(email) {
  const split = email.split('@')
  return `${email.substr(0, 1) + new Array(split[0].length - 1).fill('*').join('')}@${split[1]}`
}

export const prepareToSendPackage = (onSubmitFunc, hasSpecialOfferWatch, advProducts) => data => {
  const transformData = data
  if (hasSpecialOfferWatch?.label === 'No' || !transformData?.specialOfferPrice) {
    delete transformData.specialOfferPrice
    delete transformData.specialOfferDiscount
    transformData.hasSpecialOffer = 0
  } else {
    transformData.hasSpecialOffer = 1
  }
  if (transformData.specialOfferDurationId) {
    transformData.specialOfferDurationId = transformData.specialOfferDurationId.value
  }

  transformData.monthlyPrice = Number(transformData.monthlyPrice)
  transformData.advProducts = advProducts
  onSubmitFunc(transformData)
}

export const prepareToSendEditCommunity = onSubmitFunc => data => {
  const transformData = data
  if (transformData.categories !== '' && transformData.categories !== null && transformData.categories !== undefined) {
    const arrayIds = []
    transformData.categories.forEach(category => {
      arrayIds.push(category.value)
    })
    transformData.categories = arrayIds
  }
  if (transformData.currency) {
    transformData.currency = transformData.currency.slug
  }
  if (transformData.qtdMembers) {
    transformData.qtdMembers = transformData.qtdMembers.value
  }
  if (transformData.youtube === '') {
    transformData.youtube = null
  }
  if (transformData.facebook === '') {
    transformData.facebook = null
  }
  if (transformData.website === '') {
    transformData.website = null
  }
  if (transformData.twitter === '') {
    transformData.twitter = null
  }
  if (transformData.categories === null) {
    transformData.categories = []
  }

  if (data.womenKpi) {
    transformData.womenKpi = data.womenKpi
  }

  if (data.menKpi) {
    transformData.menKpi = data.menKpi
  }

  transformData.membersKpi = Number(data.membersKpi.value) || 0
  transformData.averageAgeKpi = Number(data.averageAgeKpi.value) || 0

  onSubmitFunc(transformData)
}

export const mountAdvProductsObject = products => {
  const productsArray = []
  products.forEach(product => {
    const productObject = {
      id: product?.id,
      productName: product?.name,
      qtdPerMonth: product?.quantity?.qtdPerMonth,
      unitPrice: parseInt(product?.unitPrice, 10),
      slug: product?.slug,
    }
    productsArray.push(productObject)
  })
  return productsArray
}

export const getYoutubeThumbnail = videoLink => {
  const youtubeId = getYouTubeID(videoLink)
  return `http://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`
}

export const getYoutubeVideoId = videoLink => {
  return getYouTubeID(videoLink)
}

export const GetCommunityKpisArray = (membersKpi, averageAgeKpi, menKpi, womenKpi) => {
  const { t } = useTranslation()

  return [
    { id: 1, title: t('members'), value: membersKpi?.value, icon: <MdPersonOutline color="#000986" /> },
    { id: 2, title: t('averageAge'), value: averageAgeKpi?.value, icon: <MdHourglassEmpty color="#000986" /> },
    { id: 3, title: t('women'), value: womenKpi?.value, icon: <IoMdFemale color="#000986" /> },
    { id: 4, title: t('men'), value: menKpi?.value, icon: <IoMdMale color="#000986" /> },
  ]
}

export const getCampaignTotalPrice = productsArray => {
  const totalCampaignPriceFunction = (accumulator, a) => {
    return accumulator + a.unitPrice * a.qtdPerMonth
  }
  const totalCampaignPrice = productsArray.reduce(totalCampaignPriceFunction, 0)
  return totalCampaignPrice
}

export const getPackageTotalPrice = productsArray => {
  const totalPackagePriceFunction = (accumulator, a) => {
    return accumulator + a.unitPrice * a.qtdPerMonth
  }
  const totalPackagePrice = productsArray.reduce(totalPackagePriceFunction, 0)
  return totalPackagePrice
}

export const getTotaProductsSelected = productsArray => {
  const totalUnitiesSelectedFunction = (accumulator, a) => {
    return accumulator + a.qtdPerMonth
  }
  const totalProductsSelected = productsArray.reduce(totalUnitiesSelectedFunction, 0)
  return totalProductsSelected
}

export const returnSponsorPaymentsDataFiltered = (filter, dataToBeFilter) => {
  const lowercasedFilter = filter.toLowerCase()

  const filteredData = dataToBeFilter?.filter(data => {
    return data?.communityName?.toLowerCase().includes(lowercasedFilter)
  })
  return filteredData
}

export const checkIfCampaignByPackageCanBeCreated = async (
  communityId,
  stripeTemporaryCampaignIdForPaymentIntent,
  formData,
  packageId,
) => {
  const packageCampaignObject = {
    uuid: stripeTemporaryCampaignIdForPaymentIntent,
    packageId,
    dtStart: formData.dtStart,
    campaignDuration: formData.campaignDuration.value,
    name: formData.name,
    verifyIfCampaignCanBeCreated: true,
  }

  if (packageId === undefined || packageId === null) {
    return toast.error(i18n.t('chooseOnePackage'))
  }

  const response = await api.post(`/community/${communityId}/campaign?type=package`, packageCampaignObject)

  return response
}

export const checkIfPayAsYouGoCampaignCanBeCreated = async (
  communityId,
  stripeTemporaryCampaignIdForPaymentIntent,
  formData,
  productsSelectedPayAsYouGo,
) => {
  const payAsYouGoCampaignObject = {
    uuid: stripeTemporaryCampaignIdForPaymentIntent,
    products: productsSelectedPayAsYouGo,
    dtStart: formData.dtStart,
    campaignDuration: formData.campaignDuration.value,
    name: formData.name,
    verifyIfCampaignCanBeCreated: true,
  }
  if (productsSelectedPayAsYouGo.length === 0) {
    return toast.warning(i18n.t('addAtLeastOneProduct'))
  }

  const response = await api.post(`/community/${communityId}/campaign?type=pay`, payAsYouGoCampaignObject)

  return response
}

export const validateAndCreateCampaignByPackage = (
  communityId,
  stripeTemporaryCampaignIdForPaymentIntent,
  formData,
  packageId,
  createCampaignWithPackageAction,
) => {
  const packageCampaignObject = {
    uuid: stripeTemporaryCampaignIdForPaymentIntent,
    packageId,
    dtStart: formData.dtStart,
    campaignDuration: formData.campaignDuration.value,
    name: formData.name,
  }

  if (packageId === undefined || packageId === null) {
    return toast.error(i18n.t('chooseOnePackage'))
  }

  return createCampaignWithPackageAction(communityId, packageCampaignObject)
}

export const validateAndCreateCampaignPayAsYouGo = (
  communityId,
  stripeTemporaryCampaignIdForPaymentIntent,
  formData,
  productsSelectedPayAsYouGo,
  createCampaignPayAsYouGoAction,
) => {
  const payAsYouGoCampaignObject = {
    uuid: stripeTemporaryCampaignIdForPaymentIntent,
    products: productsSelectedPayAsYouGo,
    dtStart: formData.dtStart,
    campaignDuration: formData.campaignDuration.value,
    name: formData.name,
  }
  if (productsSelectedPayAsYouGo.length === 0) {
    return toast.warning(i18n.t('addAtLeastOneProduct'))
  }
  return createCampaignPayAsYouGoAction(communityId, payAsYouGoCampaignObject)
}

export const validateAndCreatePaymentIntentForPayAsYouGo = (
  communityId,
  createPaymentIntentPayAsYouGoAction,
  stripeTemporaryCampaignIdForPaymentIntent,
  products,
  campaignDuration,
  paymentOption,
) => {
  if (products.length === 0) {
    return toast.warning(i18n.t('addAtLeastOneProduct'))
  }
  return createPaymentIntentPayAsYouGoAction(
    communityId,
    stripeTemporaryCampaignIdForPaymentIntent,
    products,
    campaignDuration,
    paymentOption,
  )
}

export const validateAndCreatePaymentIntentForPackage = (
  communityId,
  packageId,
  stripeTemporaryCampaignIdForPaymentIntent,
  campaignDuration,
  createPaymentIntentPackageAction,
  paymentOption,
) => {
  if (packageId == null) {
    return toast.error(i18n.t('youNeedToSelectOnePackage'))
  }
  return createPaymentIntentPackageAction(
    communityId,
    packageId,
    stripeTemporaryCampaignIdForPaymentIntent,
    campaignDuration,
    paymentOption,
  )
}

export const formatSurveysToCreate = survey => {
  return survey[0].questions
}

export const doesTheCommunityHaveMoreThanTwoPackages = packages => {
  const communityPackages = packages.length >= 1
  if (communityPackages) return CONST.campaignModelSelectOptions
  return CONST.campaignModelSelectOptionsNoPackages
}

export const setAllProductsAvailableForCreation = (formatsArray, setFormats) => {
  const formats = []
  formatsArray.forEach(campaignProduct => {
    if (campaignProduct.qtdUsed < campaignProduct.qtdTotal) {
      if (campaignProduct.product.productType === 'custom') {
        formats.push({
          name: campaignProduct.product.name,
          productType: campaignProduct.product.productType,
          campaignProductId: campaignProduct.id,
          available: campaignProduct.qtdTotal,
          used: campaignProduct.qtdUsed,
          customFileFormatLabel: campaignProduct.product.customFileFormatLabel,
          customFileFormatType: campaignProduct.product.customFileFormatType,
          description: campaignProduct.product.description,
        })
      } else {
        formats.push({
          name: campaignProduct.product.name,
          productType: campaignProduct.product.productType,
          campaignProductId: campaignProduct.id,
          available: campaignProduct.qtdTotal,
          used: campaignProduct.qtdUsed,
        })
      }
      setFormats(formats)
    } else {
      setFormats(formats)
    }
  })
}

export const getFormatUnityTitle = (formatUnity, campaignFormat) => {
  if (formatUnity?.contentType === 'web') {
    return formatUnity?.unity?.scrapperTitle
  }
  if (formatUnity?.unity?.title) {
    return formatUnity?.unity?.title
  }
  if (campaignFormat?.product?.slug === 'survey') {
    return formatUnity?.unity?.name
  }
  if (campaignFormat?.product?.slug === 'welcomeAd') {
    return formatUnity?.unity?.name
  }
  if (campaignFormat?.product?.category?.name === 'Social') {
    return formatUnity?.unity?.message
  }
  if (campaignFormat?.product?.productType === 'custom') {
    return campaignFormat.product.description
  }
  if (campaignFormat?.product?.productType === 'chatChannel') {
    return formatUnity?.unity?.name
  }
  return i18n.t('theFormatDoesntHaveTitle')
}

export const getFormatType = formatSlug => {
  switch (formatSlug) {
    case 'welcomeAd':
      return i18n.t('welcomeAd')
    case 'chatChannel':
      return i18n.t('chatChannel')
    case 'event':
      return i18n.t('event')
    case 'reward':
      return i18n.t('reward')
    case 'survey':
      return i18n.t('survey')
    case 'content':
      return i18n.t('content')
    case 'slidingTopBanner':
      return i18n.t('slidingTopBanner')
    case 'bottomStoriesBanner':
      return i18n.t('bottomStoriesBanner')
    case 'communityStory':
      return i18n.t('communityStory')
    case 'scratchCard':
      return i18n.t('scratchCard')
    case 'instagramPost':
      return i18n.t('instagramPost')
    case 'linkedinPost':
      return i18n.t('linkedinPost')
    case 'facebookPost':
      return i18n.t('facebookPost')
    case 'twitterPost':
      return i18n.t('twitterPost')
    default:
      return i18n.t('customFormat')
  }
}

export const getFormatTypeForCommunity = formatType => {
  if (!formatType) {
    return 'Custom format'
  }
  return formatType
}

export const getFormatStatus = formatUnityStatus => {
  if (formatUnityStatus === 'waiting-approval') {
    return i18n.t('waitingCommunityApproval')
  }
  if (formatUnityStatus === 'revision-needed') {
    return i18n.t('revisionNeeded')
  }
  if (formatUnityStatus === 'running') {
    return i18n.t('running')
  }
  return i18n.t('finalized')
}

export const calculateBudgetAlreadyAlocated = (campaignProductsArray, setBudgetAlreadyAlocated) => {
  const productsUsed = []

  const totalUnitiesSelectedFunction = (accumulator, a) => {
    return accumulator + a.productsUsed
  }

  if (campaignProductsArray) {
    campaignProductsArray.forEach(campaignProduct => {
      productsUsed.push({
        productsUsed: campaignProduct.qtdUsed * campaignProduct.product.unitPrice,
      })
    })
    const totalProductsSelected = productsUsed.reduce(totalUnitiesSelectedFunction, 0)
    setBudgetAlreadyAlocated(totalProductsSelected)
  }
}

export const uploadUserProfileImageS3 = async (
  setTemporaryCroppedUserImageId,
  setTemporaryCroppedUserImageUrl,
  image,
) => {
  const { token } = store.getState().auth

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  try {
    const formData = new FormData()

    formData.append('file', image)

    networkApi.defaults.headers.Authorization = `Bearer ${token}`

    const response = await networkApi.post(`/uploads`, formData, config)
    const { id, url } = response?.data?.data[0]

    setTemporaryCroppedUserImageId(id)
    setTemporaryCroppedUserImageUrl(url)
  } catch (err) {
    toast.error(i18n.t('errorUpdatingImage'))
  }
}

export const mountSelectObjectWithOnlyId = (id, selectOptions) => {
  let selectObject = null
  if (id === undefined || id === null || id === '') {
    return null
  }
  if (id !== undefined || id !== null) {
    selectOptions.forEach(option => {
      if (id === option.value) {
        selectObject = option
      }
    })
  }
  return selectObject
}

export const UploadFileS3 = ({ setUploadId, setUploadUrl }) => (
  <FilePond
    allowMultiple={false}
    onprocessfilerevert={() => {
      setUploadId(null)
      setUploadUrl(null)
    }}
    maxFileSize="10MB"
    server={{
      process: (fieldName, file, metadata, load, error, progress) => {
        const formData = new FormData()
        const request = new XMLHttpRequest()
        request.open('POST', `${process.env.REACT_APP_BASE_API}/s3`)
        request.upload.onprogress = e => {
          progress(e.lengthComputable, e.loaded, e.total)
        }
        formData.append('file', file)
        formData.append('uploadTypeName', 'file')
        formData.append('title', file.name)
        formData.append('isCoverImage', true)
        request.onload = () => {
          if (request.status >= 200 && request.status < 300) {
            const response = JSON.parse(request.response)
            load(request.responseText)
            setUploadId(response.upload_id)
            setUploadUrl(response.URL)
          } else {
            toast.error(i18n.t('errorUploadFile'))
          }
        }
        request.send(formData)
      },
    }}
  />
)

export const UploadImageS3 = ({ setUploadId, setUploadUrl, setShowImagePreview, setLoadingUpload }) => (
  <FilePond
    allowMultiple={false}
    onprocessfilerevert={() => {
      setUploadId(null)
      setUploadUrl(null)
    }}
    maxFileSize="10MB"
    acceptedFileTypes={['image/*']}
    server={{
      process: (fieldName, file, metadata, load, error, progress) => {
        setLoadingUpload(true)
        const formData = new FormData()
        const request = new XMLHttpRequest()
        request.open('POST', `${process.env.REACT_APP_BASE_API}/s3`)
        request.upload.onprogress = e => {
          progress(e.lengthComputable, e.loaded, e.total)
        }
        formData.append('file', file)
        formData.append('uploadTypeName', 'image')
        formData.append('title', file.name)
        formData.append('isCoverImage', true)
        request.onload = () => {
          if (request.status >= 200 && request.status < 300) {
            const response = JSON.parse(request.response)
            load(request.responseText)
            setUploadId(response.upload_id)
            setUploadUrl(response.URL)
            setShowImagePreview(false)
            setLoadingUpload(false)
          } else {
            toast.error(i18n.t('errorUploadFile'))
            setLoadingUpload(false)
          }
        }
        request.send(formData)
      },
    }}
  />
)

export const prepareToSendReward = (onSubmitFunc, couponType, burnableCode) => data => {
  const transformData = data
  if (couponType.label === i18n.t('couponReusable') && !transformData.couponCode) {
    return toast.error(i18n.t('defaultValidation', { inputName: i18n.t('couponCode') }))
  }

  if (couponType.label === i18n.t('couponReusable') && !transformData.couponQuantity) {
    return toast.error(i18n.t('defaultValidation', { inputName: i18n.t('couponQuantity') }))
  }

  if (couponType.label === i18n.t('couponReusable') && !transformData.link) {
    return toast.error(i18n.t('defaultValidation', { inputName: i18n.t('link') }))
  }

  if (couponType.label === i18n.t('couponBurnable') && burnableCode.length === 0) {
    return toast.error(i18n.t('defaultValidation', { inputName: i18n.t('couponCodes') }))
  }

  if (couponType.label === i18n.t('couponBurnable') && !transformData.link) {
    return toast.error(i18n.t('defaultValidation', { inputName: i18n.t('link') }))
  }
  return onSubmitFunc(transformData)
}

export const mountCurrencySelect = (currency, currencyOptions) => {
  let currencySelectObject = null
  if (!currency) {
    return currencyOptions[0]
  }
  if (currency) {
    currencyOptions.forEach(currencyOption => {
      if (currency === currencyOption.slug) {
        currencySelectObject = currencyOption
      }
    })
  }
  return currencySelectObject
}
