import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import useGetCommunityCurrencySymbol from '~/hooks/useGetCommunityCurrencySymbol'
import { currencyFormat } from '~/helper/helperFunctions'
import * as S from './styled'

export default function CheckCompositionModal({ handleCloseModal, showModal, packageInfo }) {
  const { t } = useTranslation()
  const communityCurrency = useSelector(state => state.community.currentCommunity.currency)
  const communityCurrencySymbol = useGetCommunityCurrencySymbol(communityCurrency)
  const { products } = packageInfo || {}

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <Modal.Body>
        <S.TitleGridContainer>
          <S.Column>
            <S.GridTitle>{t('basicDigitalPackage')}</S.GridTitle>
          </S.Column>
          <S.Column>
            <S.GridTitle>{t('unitsPerMonth')}</S.GridTitle>
          </S.Column>
          <S.MonthlyBudgetColumn>
            <S.GridTitle>{t('monthlyBudget')}</S.GridTitle>
          </S.MonthlyBudgetColumn>
        </S.TitleGridContainer>
        {products.map(product => (
          <S.ProductsContainer key={product.id}>
            <S.Column>
              {product.productType === 'custom' ? (
                <S.ProductTitle>{product.name}</S.ProductTitle>
              ) : (
                <S.ProductTitle>{t(product.slug)}</S.ProductTitle>
              )}
            </S.Column>
            <S.Column>
              <S.ProductSubtitle>{product.quantity.qtdPerMonth}</S.ProductSubtitle>
            </S.Column>
            <S.Column>
              <S.MonthlyBudget>{`${communityCurrencySymbol} ${currencyFormat(product.unitPrice)}`}</S.MonthlyBudget>
            </S.Column>
          </S.ProductsContainer>
        ))}
      </Modal.Body>
      <S.CloseButtonContainer onClick={() => handleCloseModal()}>
        <S.CloseIcon />
      </S.CloseButtonContainer>
    </S.ModalCustom>
  )
}
