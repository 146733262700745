/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'
import { DefaultLogoImage } from '~/util/defaultImages'
import ShouldRender from '~/components/ShouldRender'

import * as S from './styled'

const CommunityStoryPreview = ({ inputWatch, media, videoThumbnail }) => {
  const { t } = useTranslation()
  const [playing, setPlaying] = useState(false)

  const company = useSelector(state => state?.sponsor?.company)

  return (
    <>
      <S.TopPreviewContainer>
        <S.PostHeader>
          <S.CommunityInformations>
            <S.CommunityLogoBorder>
              <S.CommunityLogo src={company?.logoAdvPlatServerPath || DefaultLogoImage} />
            </S.CommunityLogoBorder>
            <S.CommunityNameContainerTop>
              <S.CommunityName>{company?.name}</S.CommunityName>
              <S.Sponsored>{t('sponsoredLabel')}</S.Sponsored>
            </S.CommunityNameContainerTop>
          </S.CommunityInformations>
          <S.ThreeDotsIcon />
          <S.CloseIcon />
        </S.PostHeader>
      </S.TopPreviewContainer>

      <S.MiddlePreviewContainer>
        {media && videoThumbnail ? (
          <S.MediaContainer onClick={() => setPlaying(!playing)}>
            <ReactPlayer
              url={media}
              width="100%"
              height="100%"
              playing={playing}
              playsinline
              loop
              muted={false}
              volume={null}
            />
            <ShouldRender if={!playing}>
              <S.PlayIconContainer onClick={() => setPlaying(true)}>
                <S.PlayIcon />
              </S.PlayIconContainer>
            </ShouldRender>
            <ShouldRender if={playing}>
              <S.PlayIconContainer onClick={() => setPlaying(false)}>
                <S.PauseIcon />
              </S.PlayIconContainer>
            </ShouldRender>
          </S.MediaContainer>
        ) : (
          <S.MediaContainer>
            <S.NoMediaIndicator>
              <S.ImageIcon />
            </S.NoMediaIndicator>
          </S.MediaContainer>
        )}
        <S.CommunityNameContainer>
          {inputWatch?.description ? (
            <S.PostCommentAndDescription>{`${inputWatch?.title} - ${inputWatch.description}`}</S.PostCommentAndDescription>
          ) : (
            <S.PostComment>{inputWatch?.title}</S.PostComment>
          )}
          <S.TagsContainer>
            <S.Tag>#Startup</S.Tag>
            <S.Tag>#Technology</S.Tag>
          </S.TagsContainer>
        </S.CommunityNameContainer>

        <S.CheckNowContainer>
          <S.ArrowUp />
          <S.CheckNow>{t('checkNow')}</S.CheckNow>
        </S.CheckNowContainer>
      </S.MiddlePreviewContainer>
    </>
  )
}

export default CommunityStoryPreview
