import { v4 as uuidv4 } from 'uuid'

const blobToFile = (blob, fileName) => {
  blob.name = fileName
  return blob
}

export default base64 => {
  const pos = base64.indexOf(';base64,')
  const type = base64.substring(5, pos)
  const b64 = base64.substr(pos + 8)

  const imageContent = atob(b64)

  const buffer = new ArrayBuffer(imageContent.length)
  const view = new Uint8Array(buffer)

  for (let n = 0; n < imageContent.length; n += 1) {
    view[n] = imageContent.charCodeAt(n)
  }

  const blob = new Blob([buffer], { type })

  const file = blobToFile(blob, uuidv4())
  return file
}
