import React from 'react'
import { useTranslation } from 'react-i18next'
import Preloader from '~/components/Chat/Preloader'
import ShouldRender from '~/components/ShouldRender'
import Avatar from '~/components/Avatar'
// import Avatar from '../Avatar/Avatar'

import * as S from './styled'

const CompactList = ({ data, imageElement, onPressItem, showImages, loading }) => {
  const { t } = useTranslation()

  const renderItem = ({ item }) => {
    return (
      <S.ListContainer key={item.id} onClick={() => onPressItem && onPressItem(item)} role="button">
        <ShouldRender if={!!imageElement}>{imageElement}</ShouldRender>
        <ShouldRender if={showImages}>
          <Avatar size="sm" userId={item.id} name={item.title} />
        </ShouldRender>

        <S.TextContainer>
          <div className="d-flex items-align-center">
            <S.ItemTitle>{item.title}</S.ItemTitle>
            <ShouldRender if={!!item && item.titleCaption}>
              <div className="h5-regular-secondary500">{`- ${item && item.titleCaption}`}</div>
            </ShouldRender>
          </div>
          <ShouldRender if={!!item.description}>
            <div className="h5-regular-secondary500">{item.description}</div>
          </ShouldRender>
          <ShouldRender if={!!item.timeAgo}>
            <div className="h5-regular-secondary500">{item.timeAgo}</div>
          </ShouldRender>
        </S.TextContainer>
      </S.ListContainer>
    )
  }

  return (
    <>
      <ShouldRender if={loading}>
        <S.LoaderContainer>
          <Preloader />
        </S.LoaderContainer>
      </ShouldRender>

      <ShouldRender if={!loading}>
        {data?.length ? (
          <div>{data.map((item, index) => item && renderItem({ item, index }))}</div>
        ) : (
          <S.EmptyText>{t('nothingToShow')}</S.EmptyText>
        )}
      </ShouldRender>
    </>
  )
}

export default CompactList
