import produce from 'immer'
import {
  RESET_USER_RECOVERED_PASSWORD,
  RECOVER_USER_PASSWORD_REQUEST,
  RECOVER_USER_PASSWORD_SUCCESS,
  RECOVER_USER_PASSWORD_FAILURE,
} from '~/store/modules/user/actionTypes'
import { SIGN_IN_SPONSOR_SUCCESS, SIGN_IN_COMMUNITY_SUCCESS, SIGN_OUT } from '~/store/modules/auth/actionTypes'

const INITIAL_STATE = {
  recoveredPassword: false,
  loadingPassword: false,
  type: null,
}

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SIGN_IN_SPONSOR_SUCCESS: {
        draft.type = 'sponsor'
        break
      }

      case SIGN_IN_COMMUNITY_SUCCESS: {
        draft.type = 'community'
        break
      }

      case RECOVER_USER_PASSWORD_REQUEST: {
        draft.loadingPassword = true
        break
      }

      case RECOVER_USER_PASSWORD_SUCCESS: {
        draft.recoveredPassword = true
        draft.loadingPassword = false
        break
      }

      case RECOVER_USER_PASSWORD_FAILURE: {
        draft.recoveredPassword = false
        draft.loadingPassword = false
        break
      }

      case RESET_USER_RECOVERED_PASSWORD: {
        draft.recoveredPassword = false
        break
      }

      case SIGN_OUT: {
        draft.type = null
        break
      }

      default:
    }
  })
}
