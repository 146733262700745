import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useNavigationFunctions from '~/helper/navigationFunctions'
import useTutorialFunctions from '~/helper/tutorialFunctions'
import { useClickAway } from 'react-use'

import * as S from './styled'

export default function CommunitiesTabTooltip({ closeProps, tooltipProps }) {
  const { t } = useTranslation()
  const { setSponsorCurrentTabAction } = useNavigationFunctions()
  const { setTutorialAsInativeAction } = useTutorialFunctions()

  const ref = useRef(null)

  useClickAway(ref, () => {
    setTutorialAsInativeAction()
  })

  const goToCampaignsTab = () => {
    setSponsorCurrentTabAction('campaigns')
  }

  return (
    <S.ComponentContainer {...tooltipProps}>
      <S.TooltipArrow />
      <S.TooltipUserAvatar />
      <S.TooltipContainer ref={ref}>
        <S.CloseButtonContainer {...closeProps}>
          <S.CloseIcon onClick={() => setTutorialAsInativeAction()} />
        </S.CloseButtonContainer>
        <S.Title>{t('sponsorStep1TutorialTitle')}</S.Title>
        <S.Subtitle>{t('sponsorCommunitiesTabSubtitle')}</S.Subtitle>
        <S.ButtonsContainer>
          <S.NextButton onClick={() => goToCampaignsTab()}>
            <S.NextButtonText>{t('next')}</S.NextButtonText>
          </S.NextButton>
        </S.ButtonsContainer>
      </S.TooltipContainer>
    </S.ComponentContainer>
  )
}
