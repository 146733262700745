import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultBannerImage, DefaultLogoImage } from '~/util/defaultImages'
import * as S from './styled'

export default function CommmunityCard({ action, community }) {
  const { t } = useTranslation()
  const { networkBannerPath, networkLogoPath, name, description, adrCity, adrState } = community
  return (
    <>
      <S.CardContainer>
        <S.CardContent>
          <S.ContainerBannerImage src={networkBannerPath == null ? DefaultBannerImage : networkBannerPath} />
          <S.CardBody>
            <>
              <S.ContainerCommunityLogo>
                <S.CommunityLogo src={networkLogoPath == null ? DefaultLogoImage : networkLogoPath} />
              </S.ContainerCommunityLogo>
            </>
            <S.CommunityInformations>
              <S.CommunityName>{name}</S.CommunityName>
              {adrCity && adrState && <S.CommunityLocation>{`${adrCity}, ${adrState}`}</S.CommunityLocation>}
            </S.CommunityInformations>
            <S.CardFooter>
              <S.CommunityDescriptionContainer>
                <S.CommunityDescription>{description}</S.CommunityDescription>
              </S.CommunityDescriptionContainer>
            </S.CardFooter>
            <S.ButtonContainer>
              <S.SelectButton text={t('select')} uppercase bold action={action} />
            </S.ButtonContainer>
          </S.CardBody>
        </S.CardContent>
      </S.CardContainer>
    </>
  )
}
