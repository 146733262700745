import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { IoMdArrowBack, IoIosImages } from 'react-icons/io'
import Button from '~/components/Shared/Button'
import { customMedia } from '~/styles/global'

export const ModalCustom = styled(Modal).attrs({
  backdrop: 'static',
  keyboard: false,
})`
  .modal-dialog {
    max-width: 850px;
    height: auto;
    margin: auto;
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;

    ${customMedia.lessThan('small')`
      margin-bottom: 0px;
    `}
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;

    ${customMedia.lessThan('small')`
      width: 22.5rem !important;
      margin: auto;
    `}
  }

  .modal-title {
    display: flex;
  }

  p {
    margin: 0;
  }
`

export const DeletePhoto = styled.p`
  color: var(--text-primary);
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

export const CropContainerParent = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
`

export const CropContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 5px;
`

export const Title = styled.p`
  color: #404257;
  margin-left: 5px !important;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  opacity: 0.9;
`

export const NoImage = styled.div`
  background-color: #f2f2f2;
  height: 200px;
  width: 100%;
`

export const NoImageIndicatorIcon = styled(IoIosImages).attrs({
  color: 'red',
  size: 28,
})`
  position: absolute;
  top: -9999px;
  left: -9999px;
  right: -9999px;
  bottom: -9999px;
  margin: auto;
`

export const BackIcon = styled(IoMdArrowBack).attrs({
  color: '#47525E',
  size: 28,
})`
  cursor: pointer;
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between !important;
  align-items: baseline !important;
  margin-bottom: 4px !important;
  padding-top: 0px !important;
`

export const ChangePhotoButton = styled(Button).attrs({
  uppercase: true,
  style: { marginRight: '10px' },
  textColor: '#637383',
  border: '1px solid #8492A6',
  color: '#FFF',
  width: '180px',
  height: '40px',
  fontSize: '15px',
})``

export const InputChangePhoto = styled.input.attrs({
  type: 'file',
  accept: 'image/x-png,image/gif,image/jpeg',
  style: { display: 'none' },
  id: 'uploadUserProfilePhoto',
})``

export const SavePhotoButton = styled(Button).attrs({
  uppercase: true,
  type: 'submit',
  width: '110px',
  height: '40px',
  fontSize: '15px',
  clickEffect: true,
  color: '#499D28',
})``

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`
