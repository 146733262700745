import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useNavigationFunctions from '~/helper/navigationFunctions'
import useTutorialFunctions from '~/helper/tutorialFunctions'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { useClickAway } from 'react-use'
import { increaseStep as increaseStepAction } from '~/store/modules/step/actions'
import { resetCampaignProcess as resetCampaignProcessAction } from '~/store/modules/createCampaign/actions'

import * as S from './styled'

export default function CreateCampaignModalTooltipStep1({ closeProps, tooltipProps }) {
  const { t } = useTranslation()
  const sponsorFunction = useSponsorFunctions()
  const dispatch = useDispatch()
  const { setSponsorCurrentTabAction } = useNavigationFunctions()
  const { setTutorialAsInativeAction, closeCreateCampaignForTutorialAction } = useTutorialFunctions()
  const communityFromSponsor = useSelector(state => state.sponsor.allCommunitiesFromSponsor)

  const ref = useRef(null)

  useClickAway(ref, () => {
    setTutorialAsInativeAction()
  })

  const goToCommunitiesTab = () => {
    setSponsorCurrentTabAction('communities')
    closeCreateCampaignForTutorialAction()
  }

  const closeModalAndSetTutorialToInactive = () => {
    setTutorialAsInativeAction()
    closeCreateCampaignForTutorialAction()
  }

  const goToCampaignCreationStep2 = () => {
    dispatch(resetCampaignProcessAction())
    sponsorFunction.setCurrentCommunityForCreateCampaignProcessAction(communityFromSponsor[0])
    sponsorFunction.setTemporaryCampaignIdForStripePaymentIntentCreationAction()
    dispatch(increaseStepAction())
  }

  return (
    <S.ComponentContainer {...tooltipProps}>
      <S.TooltipArrow />
      <S.TooltipUserAvatar />
      <S.TooltipContainer ref={ref}>
        <S.CloseButtonContainer>
          <S.CloseIcon onClick={() => closeModalAndSetTutorialToInactive()} />
        </S.CloseButtonContainer>
        <S.Title>{t('sponsorCampaignStepTutorialTitle')}</S.Title>
        <S.Subtitle>{t('sponsorCampaignStepSubtitle')}</S.Subtitle>
        <S.ButtonsContainer>
          <S.BackButton onClick={() => goToCommunitiesTab()}>
            <S.BackButtonText>{t('back')}</S.BackButtonText>
          </S.BackButton>
          <S.NextButton {...closeProps}>
            <S.NextButton onClick={() => goToCampaignCreationStep2()}>
              <S.NextButtonText>{t('next')}</S.NextButtonText>
            </S.NextButton>
          </S.NextButton>
        </S.ButtonsContainer>
      </S.TooltipContainer>
    </S.ComponentContainer>
  )
}
