import styled from 'styled-components'
import { customMedia } from '~/styles/global'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 0 15px;
  border-radius: 100px;
  background-color: #fff;
  transition: 0.5s;
  border: 1px solid #e2e2e2;
  gap: 7px;
  cursor: pointer;
  z-index: 90;

  &:hover {
    background-position: right center;
    -webkit-transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
    -moz-transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
    -o-transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
    -ms-transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
    transform: scale(1.04) translate3d(0, 0, 0) perspective(1px);
  }

  .active {
    border: 2px solid var(--community-primary) !important;
    transform: scale(1.1);
  }

  ${customMedia.lessThan('small')`
    order: 2;
    height: 50px;
  `}
`

export const Button = styled.button`
  width: 25px;
  height: 25px;
  border: none;
  border-radius: 50%;
  transition: transform 0.2s ease 0s;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  ${customMedia.lessThan('small')`
    width: 35px;
    height: 35px;
  `}
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
`
