import React from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

export default function NumberOfFormatsTutorial({ closeProps, tooltipProps }) {
  const { t } = useTranslation()

  return (
    <S.ComponentContainer {...tooltipProps}>
      <S.TooltipUserAvatar />
      <S.MessageContainer>
        <S.TooltipContainer>
          <S.CloseButtonContainer {...closeProps}>
            <S.CloseIcon />
          </S.CloseButtonContainer>
          <S.Title>{t('maxFormatsTutorialTitle')}</S.Title>
          <S.Subtitle>{t('maxFormatsTutorialDescription')}</S.Subtitle>
        </S.TooltipContainer>
        <S.TooltipArrow />
      </S.MessageContainer>
    </S.ComponentContainer>
  )
}
