import React from 'react'
import * as S from './styled'

export default function DefaultButton({ color, text, onClick, uppercase, style }) {
  return (
    <S.Button color={color} onClick={onClick} style={style}>
      <S.ButtonText uppercase={uppercase}>{text}</S.ButtonText>
    </S.Button>
  )
}
