import React from 'react'
import { MdThumbUp, MdChat } from 'react-icons/md'
import { TiArrowForward } from 'react-icons/ti'
import * as S from './styled'

export default function IconsProductImage({ right, top }) {
  return (
    <S.IconsSection right={right} top={top}>
      <S.IconThumbsUpContainer>
        <MdThumbUp color="#FFF" size={26} />
        <S.IconText>0</S.IconText>
      </S.IconThumbsUpContainer>
      <S.IconChatContainer>
        <MdChat color="#FFF" size={26} />
        <S.IconText>0</S.IconText>
      </S.IconChatContainer>
      <S.IconFowardContainer>
        <TiArrowForward color="#FFF" size={26} />
      </S.IconFowardContainer>
    </S.IconsSection>
  )
}
