import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  openCloseChatWindow as openCloseChatWindowAction,
  setActiveReplyMessage as setActiveReplyMessageAction,
  setActiveChannel as setActiveChannelAction,
} from '~/store/modules/chat/actions'

const useChatFunctions = () => {
  const dispatch = useDispatch()
  const actions = useMemo(
    () => ({
      openCloseChatWindowAction(chatChannelId) {
        dispatch(openCloseChatWindowAction(chatChannelId))
      },
      setActiveReplyMessageAction(message) {
        dispatch(setActiveReplyMessageAction(message))
      },
      setActiveChannelAction(channel) {
        dispatch(setActiveChannelAction(channel))
      },
    }),
    [dispatch],
  )
  return actions
}

export default useChatFunctions
