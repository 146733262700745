import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from '~/i18n'
import { languages } from '~/helper/const'
import { setLanguage } from '~/store/modules/language/actions'
import * as S from './styled'

export default function LanguageSelector() {
  const availableLanguages = languages.map(language => language.name)

  const browserLanguage = useSelector(state => state.language.language)

  const isBrowserLanguageAvailable = availableLanguages.some(available => available === browserLanguage)

  const [activeLanguage, setActiveLanguage] = useState(isBrowserLanguageAvailable ? browserLanguage : 'en')

  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      i18n.changeLanguage(browserLanguage)
    }, 100)
  }, [browserLanguage])

  const setActiveAndChangeLanguage = language => {
    setActiveLanguage(language)
    i18n.changeLanguage(language)
    dispatch(setLanguage(language))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = useCallback(language => () => setActiveAndChangeLanguage(language.name), [])

  return (
    <S.Container>
      {languages.map(language => (
        <S.Button key={language.id} value={language.name} onClick={handleClick(language)}>
          <S.Image src={language.src} className={activeLanguage === language.name ? 'active' : ''} />
        </S.Button>
      ))}
    </S.Container>
  )
}
