import React, { useState, useEffect, useCallback, useMemo } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { RewardReviewCard } from '~/components/Community/ReviewCampaign/RewardReviewCard'
import { SurveyReviewCard } from '~/components/Community/ReviewCampaign/SurveyReviewCard'
import { EventReviewCard } from '~/components/Community/ReviewCampaign/EventReviewCard'
import { WelcomeAdReviewCard } from '~/components/Community/ReviewCampaign/WelcomeAdReviewCard'
import { ChatChannelReviewCard } from '~/components/Community/ReviewCampaign/ChatChannelReviewCard'
import ShouldRender from '~/components/ShouldRender'
import { ContentReviewCard } from '~/components/Community/ReviewCampaign/ContentReviewCard'
import { getFormattedDate, getFormattedDatesWithYear } from '~/helper/getFormattedDates'
import TabTitleAndSubtitle from '~/components/Community/TabTitleAndSubtitle'
import { DefaultLogoImage } from '~/util/defaultImages'
import { getCampaignAnalytics } from '~/services/requests'
import { getCampaignCurrency } from '~/helper/helperFunctions'
import * as FN from '~/helper/formFunctions'
import * as S from './styled'
import { MultipleBannerReviewCard } from '../ModalSingleFormatAnalytics/MultipleBannersReviewCard'

export default function ModalAnalytics({ handleCloseModal, showModal, campaign, communityEntity }) {
  const { t } = useTranslation()
  const [analyticsData, setAnalyticsData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [budgetAlreadyAlocated, setBudgetAlreadyAlocated] = useState(0)

  const {
    id: campaignId,
    budget: campaignBudget,
    name: campaignName,
    dtStart: campaignStartDate,
    dtEnd: campaignEndDate,
    networkId: communityId,
    community,
    company,
    campaignProducts,
    currency,
  } = campaign

  const dates = useMemo(() => {
    if (!!campaignStartDate && !!campaignEndDate) {
      return getFormattedDatesWithYear(campaignStartDate, campaignEndDate)
    }

    return null
  }, [campaignStartDate, campaignEndDate])

  const startYear = dates?.start?.year
  const endYear = dates?.end?.year

  const handleGetCampaignAnalytics = useCallback(async () => {
    const response = await getCampaignAnalytics(campaignId, setLoading)
    setAnalyticsData(response.data)
    setLoading(false)
  }, [campaignId, setLoading])

  useEffect(() => {
    if (showModal) {
      handleGetCampaignAnalytics()
    }
  }, [handleGetCampaignAnalytics, showModal])

  useEffect(() => {
    FN.calculateBudgetAlreadyAlocated(campaignProducts, setBudgetAlreadyAlocated)
  }, [campaignProducts])

  return loading ? (
    <S.SpinnerContainer>
      <S.CustomSpinner />
    </S.SpinnerContainer>
  ) : (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.Title>{t('campaignAnalytics')}</S.Title>
        </Modal.Title>
      </Modal.Header>
      <S.EntityInformationsContainer>
        <S.EntityInformations>
          {communityEntity ? (
            <>
              {company?.companyLogo == null && <S.EntityLogo src={DefaultLogoImage} />}
              {company?.companyLogo != null && <S.EntityLogo src={company?.companyLogo} />}
            </>
          ) : (
            <>
              {community?.networkLogo == null && <S.EntityLogo src={DefaultLogoImage} />}
              {community?.networkLogo != null && <S.EntityLogo src={community?.networkLogo} />}
            </>
          )}

          <S.EntityContainer>
            <S.EntityNameAndLocationSection>
              {communityEntity ? (
                <>
                  <S.CampaignName>{campaignName}</S.CampaignName>
                  <S.EntityName>{company?.companyName}</S.EntityName>
                  <S.EntityLocationContainer>
                    <S.EntityCity>{company?.adrCity ? `${company?.adrCity},` : ''}</S.EntityCity>
                    <S.EntityCountry>{company?.adrCountry ? `${company?.adrCountry}` : ''}</S.EntityCountry>
                  </S.EntityLocationContainer>
                </>
              ) : (
                <>
                  <S.CampaignName>{campaignName}</S.CampaignName>
                  <S.EntityName>{community?.communityName}</S.EntityName>
                  <S.EntityLocationContainer>
                    <S.EntityCity>{community?.adrCity ? `${community?.adrCity},` : ''}</S.EntityCity>
                    <S.EntityCountry>{community?.adrCountry ? `${community?.adrCountry}` : ''}</S.EntityCountry>
                  </S.EntityLocationContainer>
                </>
              )}
            </S.EntityNameAndLocationSection>
          </S.EntityContainer>
        </S.EntityInformations>
        <S.CampaignContainer>
          <S.CampaignInformationTitle>{t('startDate')}</S.CampaignInformationTitle>
          <S.CampaignInformationSubtitle>{getFormattedDate(campaignStartDate)}</S.CampaignInformationSubtitle>
          <ShouldRender if={!!startYear}>
            <S.CampaignInformationYear>{startYear}</S.CampaignInformationYear>
          </ShouldRender>
        </S.CampaignContainer>
        <S.CampaignContainer>
          <S.CampaignInformationTitle>{t('endDate')}</S.CampaignInformationTitle>
          <S.CampaignInformationSubtitle>{getFormattedDate(campaignEndDate)}</S.CampaignInformationSubtitle>
          <ShouldRender if={!!endYear}>
            <S.CampaignInformationYear>{endYear}</S.CampaignInformationYear>
          </ShouldRender>
        </S.CampaignContainer>
        <S.CampaignBudgetContainer>
          <S.CampaignInformationTitle>{t('budgetUsed')}</S.CampaignInformationTitle>
          <S.CampaignInformationSubtitle>
            {`${getCampaignCurrency(currency)} ${campaignBudget}`}
          </S.CampaignInformationSubtitle>
        </S.CampaignBudgetContainer>
        <S.CampaignBudgetContainer>
          <S.CampaignInformationTitle>{t('budgetAlreadyAlocated')}</S.CampaignInformationTitle>
          <S.CampaignInformationSubtitle>
            {`${getCampaignCurrency(currency)} ${budgetAlreadyAlocated.toFixed(2)}`}
          </S.CampaignInformationSubtitle>
        </S.CampaignBudgetContainer>
      </S.EntityInformationsContainer>
      <S.TitleContainer>
        <TabTitleAndSubtitle row title={t('campaignFollowUp')} subtitle={t('trackCampaignResult')} />
      </S.TitleContainer>
      <Modal.Body>
        {/* Reward */}
        {analyticsData !== null && analyticsData?.rewards?.length > 0 && (
          <RewardReviewCard rewards={analyticsData.rewards} />
        )}

        {/* Content */}
        {analyticsData !== null &&
          (analyticsData?.contents?.videos.length > 0 ||
            analyticsData?.contents?.webs.length > 0 ||
            analyticsData?.contents?.documents.length > 0 ||
            analyticsData?.contents?.posts.length > 0) && <ContentReviewCard contents={analyticsData.contents} />}

        {/* Event */}
        {analyticsData !== null && analyticsData?.events?.length > 0 && (
          <EventReviewCard events={analyticsData.events} />
        )}

        {/* Survey */}
        {analyticsData !== null && analyticsData?.surveys?.length > 0 && (
          <SurveyReviewCard surveys={analyticsData.surveys} campaign={campaign} />
        )}

        {/* Welcome AD */}
        {analyticsData !== null && analyticsData?.welcomeAds?.length > 0 && (
          <WelcomeAdReviewCard welcomeAds={analyticsData.welcomeAds} />
        )}

        {/* Chat channel */}
        {analyticsData !== null && analyticsData?.chatChannels?.length > 0 && (
          <ChatChannelReviewCard
            chatChannels={analyticsData.chatChannels}
            handleCloseModalAnalytics={handleCloseModal}
            communityId={communityId}
          />
        )}

        {/* TOP AND BOTTOM BANNER ANALYTICS CARD */}
        {analyticsData !== null && analyticsData?.slidingTopBanners && (
          <MultipleBannerReviewCard banners={analyticsData?.slidingTopBanners} type="topBanner" />
        )}

        {analyticsData !== null && analyticsData?.bottomStoriesBanners && (
          <MultipleBannerReviewCard banners={analyticsData?.bottomStoriesBanners} type="bottomBanner" />
        )}
      </Modal.Body>
      <S.ModalFooter>
        <S.CloseButton text={t('close')} action={handleCloseModal} />
      </S.ModalFooter>
    </S.ModalCustom>
  )
}
