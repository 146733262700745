import styled from 'styled-components'
import Row from 'react-bootstrap/Row'

export const CardBody = styled.div`
  margin-top: 20px;
  width: 100%;
  padding-bottom: 25px;

  .icon-section {
    display: flex;
    align-items: center;
    padding: 0px 30px 8px 30px;

    p {
      margin: 0px;
      margin-left: 30px;
      color: var(--input-title);
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
  }
`

export const CardEventBody = styled.div`
  padding: 20px 20px 35px 20px;
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 23.75rem;
  box-shadow: 1px 3px 12px 3px #e2e5e9;

  .event-title {
    margin: 0px;
    color: var(--input-title);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .product-buttons {
    display: flex;
    justify-content: start;
    align-items: baseline;
    flex-wrap: wrap;
  }
`

export const ContainerEventImage = styled.div`
  margin-right: 15px;
  width: 100%;
  height: 170px;
  border-radius: 5px;
`

export const EventImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`

export const CardContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding-bottom: 15px;
  width: 100%;
  height: 100%;
  border-radius: 5px;

  .event-card-title-section {
    display: flex;

    .event-card-about {
      margin: 0px;
      width: 160px;
      margin-left: 10px;
      min-height: 32px;
      padding-right: 5px;
      margin-top: 10px;
      color: #a6a6a6;
      font-family: Roboto;
      font-size: 12px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
`

export const AnalyticsCardRow = styled(Row)`
  margin: -10px 16px 0px 16px !important;
`
