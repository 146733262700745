import { takeLatest, all, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '~/services/api'
import i18n from '~/i18n'
import { GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST } from './actionTypes'
import {
  getCommunityInformationsByInvitationLinkFailure,
  getCommunityInformationsByInvitationLinkSuccess,
} from './actions'

export function* getCommunityInformations({ payload }) {
  try {
    const { code } = payload

    const response = yield call(api.get, `/community/invitation/${code}`, {
      code,
    })
    const communityInformations = response.data[0]

    yield put(getCommunityInformationsByInvitationLinkSuccess(communityInformations))
  } catch (err) {
    toast.error(i18n.t('messages.invitationLinkInvalid'))
    yield put(getCommunityInformationsByInvitationLinkFailure())
  }
}

export default all([takeLatest(GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST, getCommunityInformations)])
