import styled from 'styled-components'

export const Container = styled.div`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  height: 50px;
  padding: 10px;

  .active {
    border: 2px solid #000 !important;
    transform: scale(1.2) !important;
  }
`

export const Button = styled.button`
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  transition: transform 0.2s ease 0s;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    transform: scale(1.1);
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`
