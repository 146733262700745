import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { MdShare } from 'react-icons/md'
import { getFormatShareableLink } from '~/services/requests'
import openExternalUrl from '~/helper/openExternalUrl'

import * as S from './styled'

export default function FormatShareButton({ contentId, closePopover, productType, productStatus, communityId }) {
  const { t } = useTranslation()

  const [loadingShare, setLoadingShare] = useState(false)

  const hasShreableLink =
    ['communityStory', 'content', 'scratchCard'].includes(productType) && productStatus === 'running'

  const handleClickShareableLink = useCallback(async () => {
    setLoadingShare(true)

    const response = await getFormatShareableLink(contentId, communityId)

    setLoadingShare(false)

    if (response?.data?.data?.shareUrl) {
      openExternalUrl(response?.data?.data?.shareUrl)
      setTimeout(() => {
        closePopover()
      }, 400)
    } else {
      toast.error(t('shareLinkError'))
      closePopover()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closePopover, contentId])

  return (
    <S.Container hasShreableLink={hasShreableLink}>
      <S.ShareButton
        onClick={handleClickShareableLink}
        icon={<MdShare color="#9671F5" size={20} />}
        text={t('share')}
        loading={loadingShare}
      />
    </S.Container>
  )
}
