import React, { useRef, useState } from 'react'
import ShouldRender from '~/components/ShouldRender'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { uploadMediaToS3WithId } from '~/services/requests'

import * as S from './styles'

export default function Cover({ setImageUrl, setImageId, imageUrl, loading }) {
  const { t } = useTranslation()

  const uploadInput = useRef(null)

  const [loadingUpload, setLoadingUpload] = useState(false)

  const handleClickUploadInput = uploadRef => {
    return uploadRef?.current?.click()
  }

  const handleImageInputClick = event => {
    event.target.value = ''
  }

  const addImage = event => {
    const image = event?.target?.files?.[0]
    const imageSize = event.target?.files?.[0]?.size

    if (imageSize > 10000000) {
      return toast.error(t('imageTooBig', { limit: '10mb' }))
    }
    if (image) uploadMediaToS3WithId(image, setLoadingUpload, setImageUrl, setImageId)
  }

  // Merge loading with loadingUpload
  const loadingCover = loading || loadingUpload

  return (
    <>
      {/* Upload image */}
      <ShouldRender if={!loadingCover && !imageUrl}>
        <S.UploadSection>
          <S.UploadContainer spacing onClick={() => handleClickUploadInput(uploadInput)}>
            <S.VideoUploadIcon />
            <S.UploadText>{t('imageUploadFormat')}</S.UploadText>
          </S.UploadContainer>
        </S.UploadSection>
      </ShouldRender>

      {/* Uploading image spinner */}
      <ShouldRender if={loadingCover}>
        <S.SpinnerContainer>
          <S.CustomSpinner />
        </S.SpinnerContainer>
      </ShouldRender>

      {/* Preview image */}
      <ShouldRender if={!loadingCover && imageUrl}>
        <S.PreviewMediaContainer>
          <S.PreviewMedia src={imageUrl} />
          <S.CloseIcon
            onClick={() => {
              setImageUrl(null)
              setImageId(null)
            }}
          />
        </S.PreviewMediaContainer>
      </ShouldRender>

      <S.InputAddImage ref={uploadInput} onChange={addImage} onClick={handleImageInputClick} />
    </>
  )
}
