/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { truncate } from 'lodash'
import FileIcons from '~/components/Chat/FileIcons'
import * as S from './styled'

const FilePreview = ({ fileName, fileUrl, isReply, fileSize, fileExtension, hasAdditionalTextMessage }) => {
  return (
    <S.FileContainer hasAdditionalTextMessage={hasAdditionalTextMessage}>
      <a href={fileUrl} target="_blank" download className="w-100">
        <S.FileIconsContainer>
          <FileIcons fileExtension={fileExtension} />
          {!!fileName && <S.FileName>{truncate(fileName, { length: isReply ? 20 : 25 })}</S.FileName>}

          {isReply && (
            <S.FileDetails>
              {`${fileSize && fileSize.toUpperCase()}•{fileExtension && fileExtension.toUpperCase()}`}
            </S.FileDetails>
          )}

          <S.FileInformations>
            {!!fileSize && !isReply && <S.FileDetails>{`${fileSize && fileSize.toUpperCase()}`}</S.FileDetails>}
          </S.FileInformations>
        </S.FileIconsContainer>
      </a>
    </S.FileContainer>
  )
}

export default FilePreview
