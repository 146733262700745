import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { RiSave2Line } from 'react-icons/ri'
import { darken } from 'polished'
import { MdClose } from 'react-icons/md'
import { customMedia } from '~/styles/global'
import WhiteButton from '~/components/WhiteButton'
import ProfileButton from '~/components/ProfileButton'
import Facebook from '~/../public/icons/facebook.svg'
import Youtube from '~/../public/youtube.png'
import Twitter from '~/../public/twitter-transparent.png'
import Website from '~/../public/geowebsite.png'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    display: ${props => (props.displaymodal === 'false' ? 'none' : 'block')};
    width: 50%;
    height: 50%;
    margin: 0;
    padding: 0;

    @media (min-width: 576px) {
      max-width: 850px;
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    p {
      margin: 0;
    }
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
`

export const DescriptionCharactersLeft = styled.div`
  position: absolute;
  right: 38px;
  top: 215px;
  color: #949aa0;
  font-size: 14px;
  font-family: Roboto;
`

export const HelperText = styled.span`
  margin-left: 5px;
  color: var(--text-primary);
  font-family: Lato;
  font-style: italic;
  font-size: 14px;
`

export const InputTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const IsRequired = styled.span`
  color: var(--community-primary);
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-left: 4px;
`

export const ContainerBannerImage = styled.div`
  position: relative;
  text-align: center;
  background-color: #f2f2f2;
  height: 220px;
`

export const InputLabel = styled.span`
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
`

export const ContainerCommunityProfilePicture = styled.div`
  position: absolute;
  width: 141px;
  height: 141px;
  left: 30px;
  bottom: 0px;
  border-radius: 100%;
  background-color: #fff;
  border: 5px solid #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  z-index: 10;
`

export const CommunityProfilePicture = styled.img.attrs({
  alt: 'Community Logo',
})`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`

export const FormContainer = styled.div`
  .error-message {
    font-family: Roboto;
    color: #ea343e;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.9;
  }

  .MuiOutlinedInput-multiline {
    height: 80px !important;
    padding: 10px 14px !important;
  }
`

export const InputContainer = styled.div`
  display: flex;
  margin-top: 10px;

  .MuiInputBase-root,
  .MuiOutlinedInput-root,
  .MuiInputBase-fullWidth,
  .MuiInputBase-formControl {
    height: 50px !important;
  }

  .Mui-focused fieldset {
    border-color: #50a5d2 !important;
    border-width: 1px !important;
  }
`

export const HeadquarterAddressSection = styled.div`
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    height: 40px;
    padding-right: 45px;
    box-sizing: border-box;
    background: url('google-logo.png') no-repeat 98%;
    background-size: 26px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #c4c4c4 !important;

    padding-left: 10px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
  }
`

export const Title = styled.p`
  color: var(--text-bold);
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
`

export const Form = styled.form.attrs({
  id: 'my-form',
})``

export const NotNow = styled.p`
  color: var(--text-primary);
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

export const BannerImage = styled.img.attrs({
  alt: 'Community Banner',
})`
  width: 100%;
  height: 157px;
  object-fit: cover;
  position: relative;
  filter: brightness(90%);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
`

export const CopyLinkSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  p {
    margin: 0;
    color: #47525e;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    opacity: 0.9;
  }
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between !important;
  align-items: baseline;
  margin-bottom: 4px;
  padding-top: 14px !important;
`

export const CustomRow = styled(Row)`
  margin-top: 16px;
`

export const UploadContainer = styled.div`
  margin-top: 10px;
`

export const UploadVideoContainer = styled.div`
  margin-top: 2px;
`

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-left: -4px;
  margin-right: -4px;
`

export const CommunityMediaContainer = styled.div`
  display: flex;
`

export const CommunityMediaCardContainer = styled.div`
  padding: 0px 4px;
`

export const UploadCardContainer = styled.div`
  margin: 4px;
`

export const SaveButton = styled(WhiteButton).attrs({
  type: 'submit',
  form: 'my-form',
  style: { marginTop: '6px' },
})``

export const SaveIcon = styled(RiSave2Line).attrs({
  color: 'var(--success)',
  size: 23,
})``

export const CancelButton = styled(WhiteButton).attrs({
  style: { marginTop: '6px', padding: '0px 30px' },
  className: 'close-modal-button',
})``

export const FacebookIcon = styled.img.attrs({
  src: Facebook,
})`
  width: 24px;
  height: 19px;
`

export const YoutubeIcon = styled.img.attrs({
  src: Youtube,
})`
  width: 24px;
  height: 19px;
`

export const TwitterIcon = styled.img.attrs({
  src: Twitter,
})`
  width: 22px;
  height: 22px;
`

export const WebsiteIcon = styled.img.attrs({
  src: Website,
})`
  width: 22px;
  height: 22px;
  border-radius: 5px;
`

export const ProfilePreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProfilePreviewButton = styled.button.attrs({
  type: 'submit',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding-left: 15px;
  bottom: 0px;
  right: 30px;
  margin: 0px;
  border-radius: 5px;
  background-color: #9671f5;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  max-width: 290px;
  height: 40px;
  border: none;
  padding: 0px 26px;
  cursor: pointer;
  transition: 300ms;

  &:hover {
    transform: scale(1.02);
    background-position: right center;
  }

  ${customMedia.lessThan('1075px')`
     display: none !important;
  `}
`

export const PreviewIcon = styled.img.attrs({
  src: '/icons/eye.png',
  alt: 'Preview Icon',
})`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`

export const EditBannerButton = styled(ProfileButton).attrs({
  style: {
    position: 'absolute',
    top: '20px',
    right: '30px',
  },
  backgroundColor: '#9671F5',
})``

export const EditLogoButton = styled(ProfileButton).attrs({
  style: {
    position: 'absolute',
    top: '100px',
    right: '-6px',
  },
  backgroundColor: '#9671F5',
})``

export const InputChangePhoto = styled.input.attrs({
  type: 'file',
  accept: 'image/x-png,image/gif,image/jpeg',
  style: { display: 'none' },
  name: 'uploadPhoto',
})``

export const InputChangeVideo = styled.input.attrs({
  type: 'file',
  accept: 'video/mp4,video/x-m4v,video/',
  style: { display: 'none' },
  name: 'uploadVideo',
})``

// Modal Edit End
// Video Previewer start

export const VideoPreviewerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`

export const VideoTitleContainer = styled.div`
  display: flex;
`

export const CloseButtonContainer = styled.div`
  margin-left: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  top: 0px;
  right: 0px;

  &:hover {
    background-color: ${darken(0.05, '#FFF')};
    text-decoration: none;
  }
`

export const CloseIcon = styled(MdClose).attrs({
  size: 20,
  color: '#47525E',
})``

export const MediaContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Media = styled.img.attrs({
  alt: 'Community Media',
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  margin-top: 6px;
`

export const VideoFooter = styled.div`
  display: flex;
  justify-content: space-between !important;
  margin-bottom: 4px;
  padding-top: 0px;
`

export const VideoButton = styled.button.attrs(props => ({ type: 'button', disabled: props.disabled }))`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  background-color: #fff;
  padding: 0px 20px;
  max-width: 12.5rem;
  height: 38px;
  color: #343f4b;
  font-size: 14px;
  border-radius: 100px;
  border: none;
  box-shadow: 0px 2px 6px 3px rgb(209, 213, 220);
  margin-top: 6px;

  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;

  ${customMedia.lessThan('veryLarge')`
  font-size: 15px;
`}

  &:hover {
    background-color: ${props => (props.color ? darken(0.03, props.color) : darken(0.015, '#FFF'))};
    transform: scale(1.06);
  }

  &:disabled {
    background: #e6e6e6;
  }
`

export const YoutubeLogo = styled.img.attrs({
  src: '/youtube.png',
  alt: 'Youtube Logo',
  width: '21px',
  height: '21px',
})``

export const VimeoLogo = styled.img.attrs({
  src: '/vimeo-logo.png',
  alt: 'Vimeo Logo',
  width: '19px',
  height: '19px',
})`
  border-radius: 3px;
`

export const ErrorMessage = styled.div`
  p {
    color: #ea343e;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 600;
    opacity: 0.9;
    margin-top: 3px;
    margin-bottom: 0px;
    text-align-last: start;
  }

  p::before {
    display: ${props => (props.error === undefined ? 'none' : 'inline')};
    content: '⚠ ';
  }
`

export const ErrorMessageContainer = styled.div.attrs({
  className: 'error-message',
})``
