import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import { useDispatch } from 'react-redux'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'
import { updateStep as updateStepAction } from '~/store/modules/step/actions'

const useStyles = makeStyles(() => ({
  root: {
    width: '550px',
    borderRadius: '5px',
    zIndex: 10,
    '& $active': {
      color: '#F74657',
      transform: 'scale(1.4)',
      fontWeight: 'bold',
      opacity: '1.0',
    },
    '& $completed': {
      color: '#F74657',
      opacity: '1.0',
    },
  },
  active: {},
  completed: {},
  alternativeLabel: {},
  labelContainer: {
    '& $alternativeLabel': {
      textTransform: 'uppercase',
      fontSize: '14px',
      color: '#FFF',
      fontWeight: 'bold',
      opacity: '0.95',
    },
  },
}))

const stepperStyle = {
  borderRadius: '5px',
  fontFamily: 'Roboto',
  height: '100%',
  backgroundColor: 'transparent',
}

const ColorlibConnector = withStyles({
  active: {
    '& $line': {
      height: 5,
      border: 0,
      backgroundColor: '#F74657',
      borderRadius: 1,
      marginRight: '-9px',
      marginLeft: '-9px',
    },
  },
  completed: {
    '& $line': {
      height: 5,
      border: 0,
      backgroundColor: '#F74657',
      borderRadius: 1,
      marginRight: '-9px',
      marginLeft: '-9px',
    },
  },
  line: {},
})(StepConnector)

export default function HorizontalLinearStepper() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let step = 2

  function getSteps() {
    return [t('selectCommunity'), t('setCampaign'), t('payment'), t('contentValidation')]
  }

  const classes = useStyles()
  const steps = getSteps()

  if (step === 0) {
    step = 0
  } else if (step === 1) {
    step = 1
  } else if (step === 2) {
    step = 2
  } else if (step === 3) {
    step = 3
  }

  function onClickFunction(label) {
    switch (label) {
      case t('selectCommunity'):
        return dispatch(updateStepAction(0))
      case t('setCampaign'):
        return null
      case t('payment'):
        return null
      case t('contentValidation'):
        return null
      default:
        return ''
    }
  }

  function handleStepClick(e, label) {
    if (e.currentTarget.classList.contains(classes.completed)) {
      onClickFunction(label)
    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={step} connector={<ColorlibConnector />} alternativeLabel style={stepperStyle}>
        {steps.map(label => (
          <Step
            key={label}
            onClick={e => handleStepClick(e, label)}
            style={{ cursor: 'pointer' }}
            classes={{ completed: classes.completed }}
          >
            <StepLabel
              classes={{
                alternativeLabel: classes.alternativeLabel,
                labelContainer: classes.labelContainer,
              }}
              StepIconProps={{
                classes: {
                  active: classes.active,
                  completed: classes.completed,
                },
              }}
            >
              <p>{label}</p>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}
