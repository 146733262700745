import React from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

export default function ZeroCampaignsIndicator({ type }) {
  const { t } = useTranslation()

  const setCampaignType = () => {
    if (type === 'waiting') return t('waitingReview')
    return t('runningOrFinalized')
  }

  return (
    <S.Container>
      <S.Text>{`${t('thereIsNoCampaigns')} ${setCampaignType()}`}</S.Text>
    </S.Container>
  )
}
