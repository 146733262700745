import React from 'react'
import * as S from './styled'

export default function PendingAlertSection({ buttonText, title, subtitle, stripe, buttonAction }) {
  return (
    <S.Container>
      <S.TextContainer>
        <S.Title>{title}</S.Title>
        <S.Subtitle>{subtitle}</S.Subtitle>
      </S.TextContainer>
      {stripe ? (
        <S.Button
          onClick={() =>
            window.location.replace(
              `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${process.env.REACT_APP_BASE_URL}/community/`,
            )}
        >
          <S.ButtonText>{buttonText}</S.ButtonText>
        </S.Button>
      ) : (
        <S.Button onClick={buttonAction}>
          <S.ButtonText>{buttonText}</S.ButtonText>
        </S.Button>
      )}
    </S.Container>
  )
}
