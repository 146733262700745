import React from 'react'
import * as S from './styled'

export default function SliderArrowButton({ onClick, direction, className, style }) {
  const rotation = {
    left: 0,
    up: 90,
    right: 180,
    down: 270,
  }
  return (
    <S.ArrowContainer onClick={onClick} className={className} style={style}>
      <S.ArrowIcon rotate={rotation[direction]} />
    </S.ArrowContainer>
  )
}
