import produce from 'immer'
import { SET_CROPPED_IMAGE, SET_SHOW_CROP_MODAL } from './actionTypes'

const INITIAL_STATE = {
  croppedImage: null,
  showCropModal: false,
}

export default function contentCropModal(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_CROPPED_IMAGE: {
        draft.croppedImage = action.payload.image
        break
      }

      case SET_SHOW_CROP_MODAL: {
        draft.showCropModal = action.payload.value
        break
      }

      default:
    }
  })
}
