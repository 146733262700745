import styled, { css } from 'styled-components'

const DefaultStyle = css`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.625rem;

  padding: 1rem !important;

  width: 100%;

  border: none;
  border-radius: 1rem;

  transition: box-shadow 0.2s ease-in-out;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &::placeholder {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.625rem;

    opacity: 0.3;
  }

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }

  background-color: #f9f9f9;
`

export const Container = styled.section`
  position: relative;
  width: 100%;
`

export const Input = styled.input`
  ${DefaultStyle}
  height: 3rem;
  width: 100%;
`

export const TextArea = styled.textarea`
  ${DefaultStyle}
  height: 134px;
  width: 100%;
`

export const Label = styled.label`
  display: block;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.5625rem;
  color: #7d7f86;
  margin-bottom: 0 !important;
`
