import React from 'react'
import { useSelector } from 'react-redux'
import Message from '~/components/Chat/Message'
import { DefaultUserProfileImage } from '~/util/defaultImages'
import { getWhenTheMessageWasSended } from '~/helper/helperFunctions'
import ShouldRender from '~/components/ShouldRender'
import { useGetCommunityInformations } from '~/hooks/'
import * as S from './styled'

export default function Messages({ messages, scrollDivRef, loadingSendingMessage, open }) {
  const loggedUserId = useSelector(state => state.auth.loggedUserId)
  const { community } = useGetCommunityInformations(open)

  return (
    <S.ChatContainer>
      <ShouldRender if={loadingSendingMessage}>
        <S.LoadingContainer>
          <S.CustomSpinner />
        </S.LoadingContainer>
      </ShouldRender>

      <ShouldRender if={!loadingSendingMessage}>
        {messages.map(message => (
          <S.Messages key={message.createdAt}>
            {message.userId === loggedUserId ? null : (
              <S.UserProfileInformationsForNotCurrentUser>
                <S.LogoContainer>
                  <S.LogoImage
                    src={message.userData?.pictureUrl ? message.userData?.pictureUrl : DefaultUserProfileImage}
                  />
                </S.LogoContainer>
                <div>
                  <S.UserNameAndProfilePicture>{message.userData?.name}</S.UserNameAndProfilePicture>
                  <S.WhenMessageWasSent>{`- ${getWhenTheMessageWasSended(message.createdAt)}`}</S.WhenMessageWasSent>
                </div>
              </S.UserProfileInformationsForNotCurrentUser>
            )}
            <S.MessageContainer>
              <Message
                message={message}
                userId={message.userId}
                loggedUserId={loggedUserId}
                loadingSendingMessage={loadingSendingMessage}
                community={community?.data}
              />
            </S.MessageContainer>
          </S.Messages>
        ))}
      </ShouldRender>

      <div ref={scrollDivRef} />
    </S.ChatContainer>
  )
}
