import styled from 'styled-components'

export const CardContainer = styled.div`
  background-color: #fff;
  padding: 10px 10px 20px 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 2px rgba(240, 245, 254, 1);
  min-width: 96px;
  height: 100%;
  margin-top: 16px;
  margin-right: 3px;
  margin-left: 3px;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6px;

  svg {
    background-color: transparent;
    border: 1px solid #477ce6;
    border-radius: 100%;
    padding: 4px;
    width: 26px;
    height: 26px;
  }
`

export const CardNumber = styled.div`
  margin-top: 4px;
  text-align: center;
  color: #5a6978;
  font-family: Lato;
  font-size: 15px;
`

export const CardTitle = styled.div`
  color: #000986;
  font-family: Roboto;
  font-size: 13px;
  text-align: center;
`

export const NotAvailable = styled.div`
  margin-top: 4px;
  text-align: center;
  color: #5a6978;
  font-family: Lato;
  font-size: 14px;
  opacity: 0.8;
`
