/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React from 'react'
import { toast } from 'react-toastify'
import matchAll from 'string.prototype.matchall'
import { subDays, isSameDay, format, formatDistance } from 'date-fns'
import i18n from '~/i18n'
import { store } from '~/store'
import black from '~/../public/black.png'
import FacebookContentPreview from '~/components/SocialMedia/ContentPreviews/FacebookContentPreview'
import TwitterContentPreview from '~/components/SocialMedia/ContentPreviews/TwitterContentPreview'
import InstagramContentPreview from '~/components/SocialMedia/ContentPreviews/InstagramContentPreview'
import LinkedinContentPreview from '~/components/SocialMedia/ContentPreviews/LinkedinContentPreview'

export const getProductBadgeColor = contentType => {
  switch (contentType) {
    case 'Post':
      return 'var(--format-post)'
    case 'Document':
      return 'var(--format-document)'
    case 'Web':
      return 'var(--format-webpage)'
    case 'Video':
      return 'var(--format-video)'
    case 'Reward':
      return 'var(--format-reward)'
    default:
      return null
  }
}

export const randomizeProductInterestTag = contentType => {
  switch (contentType) {
    case 'Post':
      return '- artificial intelligence'
    case 'Document':
      return '- big data'
    case 'Web':
      return '- digital marketing'
    case 'Video':
      return '- technology'
    default:
      return '- artificial intelligence'
  }
}

export const checkNumberForPositiveAndNegative = number => {
  const numberToCheck = Math.sign(number)
  if (numberToCheck === 1) {
    return 'up'
  }
  return 'down'
}

export const numberFormat = value => value?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

export const getCampaignCurrency = currency => {
  switch (currency) {
    case null:
      return '€'
    case 'eur':
      return '€'
    case 'usd':
      return '$'
    case 'chf':
      return 'CHF'
    case 'brl':
      return 'R$'
    default:
      return `${i18n.t('currencySymbol')}`
  }
}

export const formatAndSendPutSocialMediaFormat = (
  payload,
  mediaFileType,
  media,
  formatId,
  updateCampaignFormatAction,
  handleCloseModal,
  videoThumbnail,
) => {
  let formatToUpdate
  switch (mediaFileType) {
    case 'image':
      formatToUpdate = {
        message: payload?.description ? `${payload?.title} - ${payload?.description}` : payload?.title,
        imageUrl: media,
        dtStart: payload?.date,
        dtEnd: payload?.endDate,
      }
      updateCampaignFormatAction(formatToUpdate, formatId, handleCloseModal)
      break
    case 'video':
      formatToUpdate = {
        message: payload?.description ? `${payload?.title} - ${payload?.description}` : payload?.title,
        videoUrl: media,
        dtStart: payload?.date,
        dtEnd: payload?.endDate,
        videoThumbnail,
      }
      updateCampaignFormatAction(formatToUpdate, formatId, handleCloseModal)
      break
    default:
      formatToUpdate = {
        message: payload?.description ? `${payload?.title} - ${payload?.description}` : payload?.title,
        dtStart: payload?.date,
        dtEnd: payload?.endDate,
      }
      updateCampaignFormatAction(formatToUpdate, formatId, handleCloseModal)
      break
  }
}

export const handleCheckCommunityInviteSponsorButton = setShowModalInviteSponsor => {
  const { finPersonEmail, finPersonName, revisedStatus } = store.getState().community.currentCommunity
  const { communityHasStripeAccount } = store.getState().community
  if (!revisedStatus) {
    return toast.warning(i18n.t('cantInviteSponsorCommunityNotRevisedYet'))
  }
  if (!communityHasStripeAccount) {
    return toast.error(i18n.t('pleaseCreateStripeAccount', { autoClose: 10000 }))
  }
  if (!finPersonName && !finPersonEmail) {
    return toast.error(i18n.t('inviteSponsorsWithoutFinancialInformationsMessage'), { autoClose: 12000 })
  }
  return setShowModalInviteSponsor(true)
}

export const isUrl = input => {
  const URL = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/
  return URL.test(input)
}

export const getDateString = timestamp => {
  const date = new Date(timestamp)
  const day = date.getDate() + 1 >= 10 ? date.getDate() : `0${date.getDate()}`
  const month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

export const timeAgo = date => {
  const dateString = getDateString(Date.now())
  if (getDateString(date) === dateString) {
    return i18n.t('today')
  }
  const yesterday = subDays(new Date(), 1)
  const isYesterday = isSameDay(new Date(date), yesterday)
  if (isYesterday) {
    return i18n.t('yesterday')
  }
  return format(new Date(date), 'LLL dd, yyyy, K:mm b')
}

export const getWhenTheMessageWasSended = date => {
  const dateString = new Date(date.seconds * 1000)
  return formatDistance(new Date(dateString), new Date())
}

export const clean = (object, options = { delete: false }) => {
  Object.entries(object).forEach(([key, value]) => {
    const valueIsValidObject = !!value && typeof value === 'object'
    if (valueIsValidObject) {
      clean(value)
    }
    if (value === undefined) {
      object[key] = null

      if (options.delete) {
        delete object[key]
      }
    }
  })
  return object
}

export const checkIfTheFormatIsASocialMediaPost = productType => {
  if (
    productType === 'facebookPost' ||
    productType === 'instagramPost' ||
    productType === 'linkedinPost' ||
    productType === 'twitterPost'
  ) {
    return true
  }
  return false
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const videoParser = (video, videoThumbnail) => {
  return [
    {
      uri: video,
      type: 'video',
    },
    {
      uri: videoThumbnail || black,
      type: 'image',
    },
  ]
}

export const getFileType = file => {
  return file?.replace(/(.*)\//g, '')
}

export const getLastIndex = (input, pattern) => {
  if (input) {
    const match = matchAll(input, pattern)
    const [last] = Array.from(match, x => x && x.index).slice(-1)
    return last
  }
  return null
}

export const clone = string => {
  return ` ${string}`.slice(1)
}

export const mentionParser = (currentMessage, cursor) => {
  if (currentMessage) {
    const messageUntilCursor = clone(currentMessage).substring(0, cursor)
    const indexLastAt = getLastIndex(messageUntilCursor, /\B\@/gim)
    return indexLastAt >= 0 && indexLastAt <= cursor - 1 ? messageUntilCursor.substring(indexLastAt, cursor) : ''
  }
  return null
}

export const replaceAt = ({ index, message, replacement, endsIndex }) => {
  if (index >= message?.length) {
    return message.valueOf()
  }
  return message?.substring(0, index) + replacement + message.substring(endsIndex || index + 1)
}

const MENTION_PATTERN = ' @'

const getPossibleMentions = (availableUsers, members) => {
  const channelUsers =
    availableUsers &&
    availableUsers.filter(user => members && members.includes(user.id)).map(user => `${user && user.name}`)
  return channelUsers
}

export const inputParser = ({ message, availableUsers, members }) => {
  if (!message) return null

  const clonnedMessage = clone(message)

  const phrases = clonnedMessage.split(MENTION_PATTERN).map((phrase, index) => {
    if (message.includes(MENTION_PATTERN) && index) {
      return `${MENTION_PATTERN}${phrase}`
    }
    return phrase
  })
  const possibleMentions = getPossibleMentions(availableUsers, members)

  const res = phrases.map(word => {
    if (!word) return null
    const exactMention = possibleMentions.find(mention => word.trim() === `@${mention}`)
    const includeMention = possibleMentions.find(mention => word.trim().includes(mention))
    const mention = exactMention || includeMention
    if (mention) {
      const words = word.split(`@${mention}`)
      return (
        <div key={word}>
          {!!words[0] && <span>{`${words[0]}`}</span>}
          <span color="primary">{`@${mention}`}</span>
          {!!words[1] && <span>{`${words[1]}`}</span>}
        </div>
      )
    }
    return word || ''
  })
  return res
}

export const checkCommunitySocialMediasConnected = (
  communitySocialMediasConnected,
  setShowModalNotifySocialMediaConnection,
  setSocialMediasNotConnected,
) => {
  const notConnected = []

  Object.keys(communitySocialMediasConnected).forEach(key => {
    if (!communitySocialMediasConnected[key]) {
      notConnected.push(key.replace('Connected', ''))
    }
  })

  if (notConnected?.length > 0) {
    // //Facebook modal must appear first
    if (notConnected[0] === 'facebook') {
      notConnected.push(notConnected.splice(0, 1)[0])
    }
    notConnected.forEach(socialMedia => {
      setSocialMediasNotConnected(socialMedia)
      setShowModalNotifySocialMediaConnection(true)
    })
  }
}

export const capitalizeFirstLetter = string => {
  return string?.charAt(0).toUpperCase() + string?.slice(1)
}

export const setTheSocialMediaContentPreview = (
  socialMediaFormatType,
  community,
  inputWatch,
  media,
  videoThumbnail,
) => {
  switch (socialMediaFormatType) {
    case 'facebookPost':
      return (
        <FacebookContentPreview
          community={community}
          inputWatch={inputWatch}
          media={media}
          videoThumbnail={videoThumbnail}
        />
      )
    case 'twitterPost':
      return (
        <TwitterContentPreview
          community={community}
          inputWatch={inputWatch}
          media={media}
          videoThumbnail={videoThumbnail}
        />
      )
    case 'instagramPost':
      return (
        <InstagramContentPreview
          community={community}
          inputWatch={inputWatch}
          media={media}
          videoThumbnail={videoThumbnail}
        />
      )
    case 'linkedinPost':
      return (
        <LinkedinContentPreview
          community={community}
          inputWatch={inputWatch}
          media={media}
          videoThumbnail={videoThumbnail}
        />
      )
    default:
      return null
  }
}

export const validateSocialMediaImageUpload = (formatType, mediaSize) => {
  let error = false
  switch (formatType) {
    case 'instagramPost':
      if (mediaSize > 8000000) {
        error = true
        return toast.error(i18n.t('imageTooBig', { limit: '8mb' }))
      }
      break
    case 'facebookPost':
      if (mediaSize > 4000000) {
        error = true
        return toast.error(i18n.t('imageTooBig', { limit: '4mb' }))
      }
      break
    case 'linkedinPost':
      if (mediaSize > 10000000) {
        error = true
        return toast.error(i18n.t('imageTooBig', { limit: '10mb' }))
      }
      break
    case 'twitterPost':
      if (mediaSize > 5000000) {
        error = true
        return toast.error(i18n.t('imageTooBig', { limit: '5mb' }))
      }
      break
    default:
      break
  }
  return error
}

export const validateSocialMediaVideoUpload = (formatType, videoDuration) => {
  let error = false
  switch (formatType) {
    case 'instagramPost':
      if (videoDuration > 60) {
        error = true
        return toast.error(i18n.t('videoIsTooLong', { limit: '60', format: i18n.t('seconds') }))
      }
      break
    case 'facebookPost':
      if (videoDuration > 120) {
        error = true
        return toast.error(i18n.t('videoIsTooLong', { limit: '120', format: i18n.t('seconds') }))
      }
      break
    case 'linkedinPost':
      if (videoDuration > 300) {
        error = true
        return toast.error(i18n.t('videoIsTooLong', { limit: '5', format: i18n.t('minutes') }))
      }
      break
    case 'twitterPost':
      if (videoDuration > 30) {
        error = true
        return toast.error(i18n.t('videoIsTooLong', { limit: '30', format: i18n.t('seconds') }))
      }
      break
    default:
      break
  }
  return error
}

export const formatAndSendPostSocialMediaFormat = (
  payload,
  mediaFileType,
  media,
  campaignProductId,
  campaignId,
  createCampaignFormatAction,
  handleCloseModal,
  videoThumbnail,
) => {
  let formatToCreate
  switch (mediaFileType) {
    case 'image':
      formatToCreate = {
        message: payload?.description ? `${payload?.title} - ${payload?.description}` : payload?.title,
        imageUrl: media,
        dtStart: payload?.date,
        dtEnd: payload?.endDate,
      }
      createCampaignFormatAction(campaignId, campaignProductId, formatToCreate, null, handleCloseModal)
      break
    case 'video':
      formatToCreate = {
        message: payload?.description ? `${payload?.title} - ${payload?.description}` : payload?.title,
        videoUrl: media,
        dtStart: payload?.date,
        dtEnd: payload?.endDate,
        videoThumbnail,
      }
      createCampaignFormatAction(campaignId, campaignProductId, formatToCreate, null, handleCloseModal)
      break
    default:
      formatToCreate = {
        message: payload?.description ? `${payload?.title} - ${payload?.description}` : payload?.title,
        dtStart: payload?.date,
        dtEnd: payload?.endDate,
      }
      createCampaignFormatAction(campaignId, campaignProductId, formatToCreate, null, handleCloseModal)
      break
  }
}

export const getVideoDuration = async (video, setVideoDuration) => {
  const reader = new FileReader()
  reader.onload = () => {
    const media = new Audio(reader.result)
    media.onloadedmetadata = () => {
      setVideoDuration(media.duration)
    }
  }
  await reader.readAsDataURL(video)
}

export const formatAndSendPostCommunityStory = (
  media,
  videoThumbnail,
  campaignId,
  campaignProductId,
  payload,
  createCampaignFormatAction,
  closeModal,
  setLoading,
) => {
  payload.videoId = media?.id
  payload.videoUrl = media?.url
  payload.thumbnailId = videoThumbnail?.id
  payload.thumbnailUrl = videoThumbnail?.url

  if (!payload?.thumbnailId || !payload.thumbnailUrl) return toast.error(i18n.t('failedToGetThumbnail'))

  payload.isCommunityStory = true

  createCampaignFormatAction(campaignId, campaignProductId, payload, null, closeModal, setLoading)
}

export const formatAndSendPutCommunityStory = (
  payload,
  media,
  videoThumbnail,
  updateCampaignFormatAction,
  closeModal,
  formatId,
  formatToUpdate,
) => {
  payload.videoId = media?.id || formatToUpdate?.videoId
  payload.videoUrl = media?.url || formatToUpdate?.videoUrl
  payload.thumbnailId = videoThumbnail?.id || formatToUpdate?.thumbnailId
  payload.thumbnailUrl = videoThumbnail?.url || formatToUpdate?.thumbnailUrl
  updateCampaignFormatAction(payload, formatId, closeModal)
}

// TODO refactor this to a more elegant and simple solution
export const filterFormatsPerSocialMediaConnected = (
  communitySocialFormats,
  socialMediasConnected,
  setSocialMediaFormatsAvailable,
) => {
  communitySocialFormats.map((product, index) => {
    if (product.slug === 'instagramPost' && !socialMediasConnected.instagramIsConnected) {
      communitySocialFormats.splice(index, 1)
    }
    if (product.slug === 'facebookPost' && !socialMediasConnected.facebookIsConnected) {
      communitySocialFormats.splice(index, 1)
    }
    if (product.slug === 'twitterPost' && !socialMediasConnected.twitterIsConnected) {
      communitySocialFormats.splice(index, 1)
    }
    if (product.slug === 'linkedinPost' && !socialMediasConnected.linkedinIsConnected) {
      communitySocialFormats.splice(index, 1)
    }
  })
  setSocialMediaFormatsAvailable(communitySocialFormats)
}

// TODO refactor this to a more elegant and simple solution
export const checkIfSocialMediaIsConnectedBeforeApprovingTheSocialFormat = (productType, socialMediasConnected) => {
  let error = false
  if (productType === 'instagramPost' && !socialMediasConnected.instagramIsConnected) {
    error = true
    toast.error(i18n.t('cantApproveInstagram'), { autoClose: 18000 })
  }
  if (productType === 'facebookPost' && !socialMediasConnected.facebookIsConnected) {
    error = true
    toast.error(i18n.t('cantApproveFacebook'), { autoClose: 18000 })
  }
  if (productType === 'twitterPost' && !socialMediasConnected.twitterIsConnected) {
    error = true
    toast.error(i18n.t('cantApproveTwitter'), { autoClose: 18000 })
  }
  if (productType === 'linkedinPost' && !socialMediasConnected.linkedinIsConnected) {
    error = true
    toast.error(i18n.t('cantApproveLinkedin'), { autoClose: 18000 })
  }
  return error
}

export const currencyFormat = number => {
  const { currency: communityCurrency } = store.getState().community?.currentCommunity || 'eur'

  if (communityCurrency === 'eur' || !communityCurrency || communityCurrency === 'chf') {
    const value = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    })
      .formatToParts(Number(number))
      .map(p => (p.type !== 'literal' && p.type !== 'currency' ? p.value : ''))
      .join('')
    return value
  }
  if (communityCurrency === 'usd') {
    const value = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
      .formatToParts(Number(number))
      .map(p => (p.type !== 'literal' && p.type !== 'currency' ? p.value : ''))
      .join('')
    return value
  }
  if (communityCurrency === 'brl') {
    const value = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
      .formatToParts(Number(number))
      .map(p => (p.type !== 'literal' && p.type !== 'currency' ? p.value : ''))
      .join('')
    return value
  }
}

export const analyticsNotAvailable = response =>
  response === null ||
  (!response?.reward &&
    !response?.event &&
    !response?.welcomeAd &&
    !response?.content &&
    !response?.questions &&
    !response?.slidingTopBanner &&
    !response?.bottomStoriesBanner)
