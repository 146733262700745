import styled from 'styled-components'

export const PlayContainer = styled.div.attrs(props => ({
  className: props.className,
}))`
  cursor: pointer;
  width: 42px;
  height: 42px;
  border-radius: 42px;
  position: absolute;
  align-self: center;
  justify-self: center;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;

  &.small {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`

export const PlayIcon = styled.img.attrs(props => ({
  src: 'play-icon.png',
  className: props.className,
}))`
  width: 20px;
  height: 20px;
  margin-left: 4px;

  &.small {
    width: 14px;
    height: 14px;
  }
`
