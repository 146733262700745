import React from 'react'
import { MdAttachMoney, MdImportantDevices, MdCardGiftcard, MdOutlineAnalytics, MdCampaign } from 'react-icons/md'
import i18n from '~/i18n'

export default function CommunityTabs(currentTabActive) {
  return [
    {
      icon:
        currentTabActive === 'products' ? (
          <MdImportantDevices color="#7345e9" size={27} />
        ) : (
          <MdImportantDevices color="#FFF" size={27} />
        ),
      title: i18n.t('products'),
      slug: 'products',
      className: 'communityFormatTab',
    },
    {
      icon:
        currentTabActive === 'packages' ? (
          <MdCardGiftcard color="#7345e9" size={27} />
        ) : (
          <MdCardGiftcard color="#FFF" size={27} />
        ),
      title: i18n.t('packages'),
      spacing: true,
      slug: 'packages',
      className: 'communityPackageTab',
    },
    {
      icon:
        currentTabActive === 'campaigns' ? (
          <MdCampaign color="#7345e9" size={27} />
        ) : (
          <MdCampaign color="#FFF" size={27} />
        ),
      title: i18n.t('campaigns'),
      slug: 'campaigns',
      spacing: true,
    },
    {
      icon:
        currentTabActive === 'sponsors' ? (
          <MdAttachMoney color="#7345e9" size={27} />
        ) : (
          <MdAttachMoney color="#FFF" size={27} />
        ),
      title: i18n.t('sponsors'),
      spacing: true,
      slug: 'sponsors',
      className: 'communitySponsorTab',
    },
    {
      icon:
        currentTabActive === 'analytics' ? (
          <MdOutlineAnalytics color="#7345e9" size={27} />
        ) : (
          <MdOutlineAnalytics color="#FFF" size={27} />
        ),
      title: i18n.t('analytics'),
      slug: 'analytics',
      spacing: true,
      className: 'communityDashboardTab',
    },
  ]
}
