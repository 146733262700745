import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { DefaultProjectLogo } from '~/util/defaultImages'

export const ErrorBoundaryContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const BeLogoImage = styled(LazyLoadImage).attrs({
  alt: 'Project Logo',
  role: 'presentation',
  width: '287',
  src: DefaultProjectLogo,
  effect: 'blur',
})`
  margin-top: 3rem;
`

export const ErrorText = styled.p`
  margin: 0px;
  font-size: 1.4rem;
  color: var(--input-title);
  font-weight: bold;
  margin-top: 0.5rem;
`

export const TryReload = styled.a`
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  margin-top: 0.75rem;

  &:hover {
    text-decoration: underline;
  }
`
