import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import WhiteButton from '~/components/WhiteButton'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  padding: 30px;
`

export const IncludeNewButton = styled(WhiteButton).attrs({
  style: { width: '150px' },
})``

export const SearchInputContainer = styled.div`
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 8px;
`

export const Border = styled.p`
  border: solid rgba(214, 214, 214, 0.3);
  border-width: 1px;
  margin-bottom: 0;
  width: 100% !important;
  opacity: 0.6;
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const CampaignsNeedingRevisionContainer = styled.div`
  margin-top: 20px;
`

export const GridTitleContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`

export const GridTitle = styled.p`
  margin: 0px;
  color: var(--sponsor-dark);
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
`

export const SearchInputButtonContainer = styled.div`
  margin: 30px 0px 30px 0px;
  display: flex;
  justify-content: space-between;
`

export const FormatsColumn = styled(Col)`
  padding-right: 30px !important;
  padding-left: 0px !important;
`

export const TotalBudgetColumn = styled(Col)`
  padding-left: 30px !important;
  padding-right: 0px !important;
`

export const CampaignModelColumn = styled(Col)`
  padding-left: 0px !important;
`

export const PurchaseColumn = styled(Col)``
export const PaymentStatusColumn = styled(Col)``
export const CommunityColumn = styled(Col)``
