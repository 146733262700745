import React from 'react'
import {
  AttentionSeeker,
  Bounce,
  Fade,
  Flip,
  Hinge,
  JackInTheBox,
  Roll,
  Rotate,
  Slide,
  Zoom,
} from 'react-awesome-reveal'

import { useIsDesktop } from '../../pages/LandingPage/useIsDesktop'

// Maps all available animation components
const animationsMap = {
  attentionSeeker: AttentionSeeker,
  bounce: Bounce,
  fade: Fade,
  flip: Flip,
  hinge: Hinge,
  jackInTheBox: JackInTheBox,
  roll: Roll,
  rotate: Rotate,
  slide: Slide,
  zoom: Zoom,
}

export const ResponsiveAnimation = ({ children, type, applyOn, effects }) => {
  const SelectedAnimation = animationsMap[type]

  const isDesktop = useIsDesktop()

  // Checks if the animation should be applied according to the device
  const shouldAnimate =
    (applyOn === 'desktop' && isDesktop) || (applyOn === 'mobile' && !isDesktop) || applyOn === 'both'

  // Renders animation conditionally for the children
  return shouldAnimate ? (
    <SelectedAnimation triggerOnce {...effects}>
      {children}
    </SelectedAnimation>
  ) : (
    <>{children}</>
  )
}
