/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import { useSelector } from 'react-redux'
import { RiSave2Line } from 'react-icons/ri'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { campaignModalStyle } from '~/helper/const'
import * as CHAT from '~/helper/chatFunctions'
import * as S from './styled'

export default function CampaignRequestChangeModal({ showModal, handleCloseModal, companyName, companyId }) {
  const { t } = useTranslation()
  const { handleSubmit, errors, control } = useForm()
  const community = useSelector(state => state.community.currentCommunity)
  const communityId = useSelector(state => state.community.currentCommunity.id)
  const [channelId, setChannelId] = useState(null)

  function onSubmit(data) {
    const { message } = data
    CHAT.createMessageBetweenCommunityAndCompany(community, message, channelId)
    handleCloseModal()
  }

  useEffect(() => {
    CHAT.getSpecificChannelIdWithCommunityId(communityId, setChannelId, companyId)
  }, [communityId, companyId])

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.ModalTitle>{`${t('messageTo')} ${companyName}:`}</S.ModalTitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={campaignModalStyle}>
        <S.FormContainer>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <S.InputTitle>{t('messageForTheSponsor')}</S.InputTitle>
            <Controller
              as={<TextField placeholder={t('typeYourMessage')} variant="outlined" fullWidth />}
              name="message"
              control={control}
              rules={{ required: t('defaultValidation', { inputName: t('message') }) }}
            />
            <S.ErrorMessageContainer>
              <ErrorMessage errors={errors} name="message" as="p" />
            </S.ErrorMessageContainer>
          </S.Form>
        </S.FormContainer>
      </Modal.Body>
      <S.ModalFooter>
        <S.SendMesssageButtonContainer>
          <S.SendMessageButton text={t('save')} large icon={<RiSave2Line color="var(--success)" size={23} />} />
        </S.SendMesssageButtonContainer>
      </S.ModalFooter>
    </S.ModalCustom>
  )
}
