import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { modalRatingStyle } from '~/helper/const'
import useNavigationFunctions from '~/helper/navigationFunctions'
import useTutorialFunctions from '~/helper/tutorialFunctions'
import * as S from './styled'

export default function ModalTutorialWelcome({ handleCloseModal, showModal, openModalTutorialAllSteps, sponsor }) {
  const { t } = useTranslation()
  const { setSponsorTutorialFirstLoginAction, setCommunityTutorialFirstLoginAction } = useTutorialFunctions()
  const { setSponsorCurrentTabAction, setCommunityCurrentTabAction } = useNavigationFunctions()

  const setTutorialAsActiveForTheCommunity = () => {
    setCommunityTutorialFirstLoginAction()
    openModalTutorialAllSteps()
    setCommunityCurrentTabAction('products')
    handleCloseModal()
  }

  const setTutorialAsActiveForTheSponsor = () => {
    setSponsorTutorialFirstLoginAction()
    openModalTutorialAllSteps()
    setSponsorCurrentTabAction('communities')
    handleCloseModal()
  }

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <S.WelcomeImage />
      <Modal.Body style={modalRatingStyle}>
        <S.WelcomeText>{t('welcomeTutorial')}</S.WelcomeText>
        {sponsor ? (
          <>
            <S.WelcomeDescription>{t('sponsorWelcomeTutorialDescription')}</S.WelcomeDescription>
            <S.StartNowButton
              data-cy="start-now-button"
              sponsor={sponsor}
              onClick={() => setTutorialAsActiveForTheSponsor()}
            >
              <S.StartNowText>{t('startNowTutorial')}</S.StartNowText>
            </S.StartNowButton>
          </>
        ) : (
          <>
            <S.WelcomeDescription>{t('welcomeTutorialDescription')}</S.WelcomeDescription>
            <S.StartNowButton
              data-cy="start-now-button"
              sponsor={sponsor}
              onClick={() => setTutorialAsActiveForTheCommunity()}
            >
              <S.StartNowText>{t('startNowTutorial')}</S.StartNowText>
            </S.StartNowButton>
          </>
        )}
      </Modal.Body>
    </S.ModalCustom>
  )
}
