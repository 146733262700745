import React, { useCallback } from 'react'
import { MdClose } from 'react-icons/md'
import * as S from './styles'

const Close = ({ onClick }) => {
  const handleClick = useCallback(
    event => {
      event.stopPropagation()

      // eslint-disable-next-line no-unused-expressions
      onClick?.()
    },
    [onClick],
  )

  return (
    <S.IconContainer onClick={handleClick}>
      <MdClose size={21} />
    </S.IconContainer>
  )
}

export default Close
