import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLanguage } from '~/store/modules/language/actions'
import i18n from '~/i18n'
import { languages } from '~/helper/const'
import * as S from './styled'

export default function LanguageSelector({ closePopover }) {
  const availableLanguages = languages.map(language => language.name)

  const browserLanguage = useSelector(state => state.language.language)

  const isBrowserLanguageAvailable = availableLanguages.some(available => available === browserLanguage)

  const [activeLanguage, setActiveLanguage] = useState(isBrowserLanguageAvailable ? browserLanguage : 'en')

  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      i18n.changeLanguage(browserLanguage)
    }, 100)
  }, [browserLanguage])

  const setActiveAndChangeLanguage = language => {
    setActiveLanguage(language)
    i18n.changeLanguage(language)
    dispatch(setLanguage(language))
    closePopover()
  }

  return (
    <S.Container>
      {languages.map(language => (
        <S.Button
          key={language.id}
          className={activeLanguage === language.name ? 'active' : ''}
          value={language.name}
          onClick={() => setActiveAndChangeLanguage(language.name)}
        >
          <S.Image src={language.src} />
        </S.Button>
      ))}
    </S.Container>
  )
}
