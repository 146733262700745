import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import TextField from '@material-ui/core/TextField'
import { FaImages } from 'react-icons/fa'
import { MdClose, MdCrop } from 'react-icons/md'
import { AiOutlinePlaySquare } from 'react-icons/ai'
import Spinner from 'react-bootstrap/Spinner'

export const CustomModal = styled(Modal).attrs({
  keyboard: false,
})`
  .modal-dialog {
    max-width: 1000px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .modal-content {
    border-radius: 0;
  }

  .modal-header {
    color: #000;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 3.5rem !important;
    background-color: rgb(242, 242, 242);
  }
`

export const InputContainer = styled.div`
  margin-top: 16px;
`

export const Title = styled.p`
  color: #404257;
  margin: 0px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  opacity: 0.9;
`

export const HelperText = styled.span`
  margin-left: 5px;
  color: var(--text-primary);
  font-family: Lato;
  font-style: italic;
  font-size: 14px;
  line-height: 15px;
`

export const FormContainer = styled.div`
  .input-date {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    height: 40px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(159, 159, 159, 0.7);
    border-radius: 5px;
    padding-left: 5px;
  }
`

export const CustomTextField = styled(TextField).attrs({
  variant: 'outlined',
  fullWidth: true,
})``

export const CustomMultilineTextField = styled(TextField).attrs({
  variant: 'outlined',
  fullWidth: true,
  multiline: true,
  rows: 4,
})``

export const UploadSection = styled.div`
  display: flex;
  margin-top: 4px;
`

export const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgba(159, 159, 159, 0.7);
  border-radius: 5px;
  padding: 5px;
  width: 50%;
  height: 143px;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    cursor: unset;
    opacity: 0.4;
  }
`

export const UploadText = styled.p`
  margin-top: 5px;
  color: var(--text-primary);
  font-family: Lato;
  font-size: 14px;
  line-height: 15px;
  white-space: break-spaces;
`

export const ImageUploadIcon = styled(FaImages).attrs({
  size: 28,
})``

export const VideoUploadIcon = styled(AiOutlinePlaySquare).attrs({
  size: 28,
})``

export const Form = styled.form.attrs({
  id: 'my-form',
})``

export const InputAddImage = styled.input.attrs({
  type: 'file',
  value: '',
  accept: 'image/x-png,image/gif,image/jpeg',
  style: { display: 'none' },
})``

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
`

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``

export const MediasContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`

export const PreviewMediaContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 8px;
`

export const PreviewMedia = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 7px 4px rgba(0, 0, 0, 0.35);
  aspect-ratio: 113/54;
`

export const CloseIcon = styled(MdClose).attrs({
  size: 18,
  color: '#47525E',
})`
  position: absolute;
  cursor: pointer;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border: 2px solid #47525e;
  border-radius: 50%;
  top: -8px;
  right: -5px;

  &:hover {
    opacity: 0.9;
  }
`

export const EditIcon = styled(MdCrop).attrs({
  color: 'var(--success)',
  size: 20,
})``
