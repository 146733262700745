import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { IoMdArrowDropdown } from 'react-icons/io'
import { customMedia } from '~/styles/global'

export const HeaderContainer = styled(Container).attrs({
  as: 'header',
})`
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  padding: 20px 70px 20px 70px;
  background-image: ${props =>
    props.entity === 'Community'
      ? 'linear-gradient(to right, #fff 20%, #9671F5 100%)'
      : 'linear-gradient(to right,#fff 10%,#00A2FF 100%)'};

  ${customMedia.lessThan('small')`
    padding: 20px 70px 20px 80px;
  `}
`

export const HeaderRow = styled(Row)``

export const CommunityContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${customMedia.lessThan('medium')`
    justify-content: center;
  `}
`

export const NetworkManagerAvatar = styled.img.attrs({
  alt: 'User Avatar',
})`
  width: 38px;
  height: 38px;
  min-height: 38px;
  cursor: pointer;
  border-radius: 100%;
  border: 2px solid #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
`

export const NetworkManagerArrowDown = styled(IoMdArrowDropdown).attrs({
  color: '#FFF',
  size: 28,
})`
  cursor: pointer;
`

export const LogoContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  img {
    width: 240px;
  }
`

export const LogoImage = styled.img.attrs({
  alt: 'Community Monetization',
  src: '/abilitya-logo.png',
})``

export const User = styled.span`
  font-family: Roboto;
  color: #fff;
  font-size: 14px;
`

export const ImageContainer = styled.div`
  width: 38px;
  height: 38px;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const MeSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px !important;
  cursor: pointer;
`

export const LogoColumn = styled(Col).attrs({
  md: 6,
})``

export const UserProfileColumn = styled(Col).attrs({
  md: 6,
})`
  ${customMedia.lessThan('small')`
    padding: 10px 0px;
  `}
`
