import React, { useState, useEffect } from 'react'
import * as S from './styled'

const CustomSpinner = () => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const interval = setTimeout(() => {
      setIsLoading(true)
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  return <S.Container>{isLoading && <S.LoadingSpinner />}</S.Container>
}

export default CustomSpinner
