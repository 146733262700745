import produce from 'immer'
import {
  SET_USER_CURRENT_COMMUNITY,
  SET_USER_CURRENT_COMMUNITY_AFTER_SIGN_UP,
} from '~/store/modules/community/actionTypes'
import {
  SIGN_IN_REQUEST,
  SIGN_IN_SPONSOR_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_FAILURE,
  SIGN_OUT,
  SIGN_IN_SPONSOR_SUCCESS,
  SIGN_IN_LOADING_SUCCESS,
  SET_WRONG_USER_TRYING_TO_LOGIN,
} from './actionTypes'

const INITIAL_STATE = {
  token: null,
  firebaseToken: null,
  signed: false,
  loading: false,
  loggedUserId: null,
  wrongUserTryingToLogin: null,
}

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SIGN_IN_REQUEST: {
        draft.loading = true
        break
      }

      case SIGN_IN_SPONSOR_REQUEST: {
        draft.loading = true
        break
      }

      case SIGN_IN_SUCCESS: {
        draft.token = action.payload.token
        draft.firebaseToken = action.payload.firebaseToken
        draft.signed = true
        draft.loading = false
        draft.wrongUserTryingToLogin = null
        break
      }

      case SIGN_IN_LOADING_SUCCESS: {
        draft.loading = false
        break
      }

      case SIGN_IN_SPONSOR_SUCCESS: {
        draft.token = action.payload.token
        draft.firebaseToken = action.payload.firebaseToken
        draft.signed = true
        draft.loading = false
        draft.wrongUserTryingToLogin = null
        draft.loggedUserId = action.payload.company.id
        break
      }

      case SET_USER_CURRENT_COMMUNITY: {
        draft.loggedUserId = action.payload.id
        break
      }

      case SET_USER_CURRENT_COMMUNITY_AFTER_SIGN_UP: {
        draft.loggedUserId = action.payload.id
        break
      }

      case SIGN_FAILURE: {
        draft.loading = false
        break
      }

      case SET_WRONG_USER_TRYING_TO_LOGIN: {
        draft.wrongUserTryingToLogin = action.payload
        break
      }

      case SIGN_OUT: {
        draft.token = null
        draft.firebaseToken = null
        draft.signed = false
        draft.loggedUserId = null
        draft.loading = false
        draft.wrongUserTryingToLogin = null
        break
      }

      default:
    }
  })
}
