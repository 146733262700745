import { takeLatest, all, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import history from '~/services/history'
import api from '~/services/api'
import i18n from '~/i18n'
import { setUserCurrentCommunityAfterSignUp } from '~/store/modules/community/actions'
import { incrementByOneTheSponsorLogin } from '~/store/modules/tutorial/actions'
import { getFireauth } from '~/services/firebase'
import {
  SIGN_IN_REQUEST,
  SIGN_IN_SPONSOR_REQUEST,
  SIGN_UP_COMMUNITY_REQUEST,
  SIGN_UP_SPONSOR_REQUEST,
  SIGN_UP_COMMUNITY_LEAD,
  SIGN_OUT,
} from './actionTypes'
import {
  signInSuccess,
  signInCommunitySuccess,
  signInSponsorSuccess,
  signFailure,
  signInRequest,
  signInSponsorRequest,
  signInLoadingSuccess,
  setWrongUserTryingToLogin,
} from './actions'

const registerFirebaseUser = async firebaseToken => {
  try {
    await getFireauth().signInWithCustomToken(firebaseToken)
  } catch (error) {
    console.log('Firebase auth error:', error)
  }
}

function* firebaseAuth(firebaseToken) {
  yield registerFirebaseUser(firebaseToken)
}

export function* signInCommunityAndSponsor({ payload }) {
  try {
    const { email, password } = payload
    const response = yield call(api.post, 'auth', {
      email,
      password,
    })

    const { company, communities, firebaseToken, user, token } = response.data || {}

    if (token) {
      api.defaults.headers.Authorization = token
    }

    // Login with community
    if (communities) {
      yield firebaseAuth(firebaseToken)
      yield put(signInSuccess(token, firebaseToken))
      yield put(signInCommunitySuccess(user, communities))
    }

    // Login with sponsor
    if (company) {
      yield put(signInSponsorSuccess(user, company, token, firebaseToken))
      yield put(incrementByOneTheSponsorLogin())
      history.push('/sponsor')
    }
  } catch (err) {
    if (err.code === 'auth/invalid-custom-token') {
      toast.error(i18n.t('unknownError'))
      yield put(signFailure())
    }
    if (err.response.data.error === 'ThisUserIsSponsor') {
      yield put(setWrongUserTryingToLogin(true))
      yield put(signFailure())
    } else if (err.response.data.error === 'UserNotExists') {
      toast.error(i18n.t('messages.userDoesntExist'))
      yield put(signFailure())
    } else {
      toast.error(i18n.t('messages.invalidUsernameOrPassword'))
      yield put(signFailure())
    }
  }
}

export function* signInSponsor({ payload }) {
  try {
    const { email, password } = payload
    const response = yield call(api.post, 'auth', {
      email,
      password,
    })
    const { token, user, company, firebaseToken } = response.data
    if (token) {
      api.defaults.headers.Authorization = token
    }
    yield firebaseAuth(firebaseToken)
    yield put(signInSponsorSuccess(user, company, token, firebaseToken))
    yield put(incrementByOneTheSponsorLogin())
    history.push('/sponsor')
  } catch (err) {
    if (err.code === 'auth/invalid-custom-token') {
      toast.error(i18n.t('unknownError'))
      yield put(signFailure())
    }
    if (err.response.data.error === 'ThisUserIsCommunityManager') {
      yield put(setWrongUserTryingToLogin(true))
      yield put(signFailure())
    } else if (err.response.data.error === 'UserNotExists') {
      toast.error(i18n.t('messages.userDoesntExist'))
      yield put(signFailure())
    } else {
      toast.error(i18n.t('messages.invalidUsernameOrPassword'))
      yield put(signFailure())
    }
  }
}

export function signOut() {
  history.push('/')
}

export function setToken({ payload }) {
  if (!payload) {
    return
  }
  const { token } = payload.auth
  if (token) {
    api.defaults.headers.Authorization = token
  }
}

export function* signUpAndLoginCommunityRequest({ payload }) {
  try {
    const response = yield call(api.post, 'community', payload)
    const communityCreated = response.data.communities[0]
    toast.success(i18n.t('communitySuccessfullyRegistered'))
    const { email, password } = payload.communityManager
    const credentialsFromCommunitySignedUp = { email, password }
    yield put(signInRequest(credentialsFromCommunitySignedUp))
    yield put(setUserCurrentCommunityAfterSignUp(communityCreated))
    yield put(signInLoadingSuccess())
    setTimeout(() => {
      history.push('/community')
    }, 1500)
  } catch (err) {
    if (err.response.data.error === 'CommunityAlreadyExists') {
      toast.error(i18n.t('communityAlreadyExists'))
      yield put(signFailure())
    } else if (err.response.data.error === 'UserAlreadyExists') {
      toast.error(i18n.t('emailAlreadyInUse'))
      yield put(signFailure())
    } else {
      toast.error(i18n.t('unknownError'))
      yield put(signFailure())
    }
  }
}

export function* signUpCommunityLead({ payload }) {
  try {
    yield call(api.post, 'community/lead', payload)
  } catch (err) {
    toast.error(i18n.t('unknownError'))
    yield put(signFailure())
  }
}

export function* signUpAndLoginSponsorRequest({ payload: { data, setLoading } }) {
  try {
    yield call(api.post, 'company', data)
    toast.success(i18n.t('companySuccessfullyRegistered'))
    const { email, password } = data.manager
    const credentialsFromSponsorSignedUp = { email, password }
    yield put(signInSponsorRequest(credentialsFromSponsorSignedUp))
  } catch (err) {
    setLoading(false)

    if (err.response.data.error === 'CompanyAlreadyExists') {
      toast.error(i18n.t('companyAlreadyExists'))
      yield put(signFailure())
    } else if (err.response.data.error === 'UserAlreadyExists') {
      toast.error(i18n.t('emailAlreadyInUse'))
      yield put(signFailure())
    } else {
      toast.error(i18n.t('unknownError'))
      yield put(signFailure())
    }
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(SIGN_IN_REQUEST, signInCommunityAndSponsor),
  takeLatest(SIGN_IN_SPONSOR_REQUEST, signInSponsor),
  takeLatest(SIGN_OUT, signOut),
  takeLatest(SIGN_UP_COMMUNITY_REQUEST, signUpAndLoginCommunityRequest),
  takeLatest(SIGN_UP_SPONSOR_REQUEST, signUpAndLoginSponsorRequest),
  takeLatest(SIGN_UP_COMMUNITY_LEAD, signUpCommunityLead),
])
