/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import api from '~/services/api'

const useGetCommunityInformations = open => {
  const { activeChannel } = useSelector(state => state.chat)
  const [community, setCommunity] = useState(null)

  const getCommunity = useCallback(async () => {
    if (activeChannel?.communityId) {
      api
        .get(`/community/${activeChannel?.communityId}`)
        .then(response => {
          setCommunity(response)
        })
        .catch(error => {
          console.log('error:', error)
        })
    }
  }, [activeChannel])

  useEffect(() => {
    if (activeChannel?.communityId) {
      getCommunity()
    }
  }, [open])

  return { community }
}

export default useGetCommunityInformations
