import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'

export const ModalCustom = styled(Modal).attrs({
  backdrop: 'static',
  keyboard: false,
})`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 650px;
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 50px;
    }
  }

  .modal-content {
    width: 100%;
    padding-bottom: 20px;

    .modal-body {
      padding: 0px 30px 30px 30px !important;
    }
  }

  .modal-header {
    padding: 10px 16px 0px 16px !important;
  }
`

export const Title = styled.p`
  margin-bottom: 0px;
  color: var(--text-bold);
  margin-left: 5px !important;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  opacity: 0.9;
`

export const Subtitle = styled.p`
  margin-left: 5px !important;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
`

export const LoginCardColumn = styled(Col)`
  display: flex;
  margin-top: 24px;
`
