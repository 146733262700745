import styled from 'styled-components'
import { IoIosAdd } from 'react-icons/io'
import { customMedia } from '~/styles/global'

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: 25vw;
  min-height: 102px;

  ${customMedia.greaterThan('2200px')`
    width: 23vw;
  `}
`

export const ProgressContainer = styled.div`
  background-color: var(--light-grey);
  margin-top: 4px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  height: 30px;
`

export const ProgressDone = styled.div`
  background: linear-gradient(to left, var(--yellow), #fcc603);
  box-shadow: 0 3px 3px -5px var(--yellow), 0 2px 5px var(--yellow);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
`

export const ProgressDoneText = styled.p`
  color: var(--text-bold);
  margin: 0;
  ${props => props.percentage < 8 && 'margin-left: 18px;'}
`

export const ProductTitle = styled.p`
  margin-bottom: 0px;
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 15px;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 4px;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`

export const ProductQuantityAvailable = styled.p`
  margin-top: 8px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 13px;
  text-align: left;
`

export const TitleAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  flex-wrap: wrap;
`

export const LoadNewContentButton = styled.div`
  cursor: pointer;
  background-color: ${props => (props.disabled ? '#bfbfbf' : '#8fc066')};
  padding: 0px 10px 0px 5px;
  border-radius: 5px;

  &:hover {
    opacity: 0.9;
    transform: scale(1.01);
  }
`

export const LoadNewContentText = styled.span`
  color: #fff;
  font-family: Roboto;
  font-size: 13px;
`

export const LoadNewContentIcon = styled(IoIosAdd).attrs({
  size: 23,
  color: '#FFF',
})``
