import styled from 'styled-components'

export const PackageWrapper = styled.div`
  display: grid;

  gap: 16px;
`

export const FormSectionContainer = styled.div`
  margin-top: 16px;

  svg {
    font-size: 30px !important;
  }
`

export const PackageContainer = styled.div`
  border-radius: 12px;
  padding: 10px 12px 10px 12px;
`

export const PackageSelectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const PackageNameAndPrice = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputTitle = styled.span`
  color: #4f5559;
  font-family: Inter;
  font-size: 15px;
  font-weight: bold;

  &.disabled {
    opacity: 0.5;
  }
`

export const ErrorMessageContainer = styled.div.attrs({
  className: 'error-message',
})``

export const SelectContainer = styled.div`
  margin-top: 5px;
  position: relative;
`

export const PackageInformationContainer = styled.div.attrs({
  className: 'animated fadeIn',
})`
  margin-top: 5px;
  transition: all 300ms;
`

export const PackageDescription = styled.p`
  margin: 0px;
  color: #adadad;
  font-family: Roboto;
  font-size: 12px;
  width: 270px;
  line-height: 15px;
`

export const DiscountLabelContainer = styled.div.attrs({
  className: 'animated fadeIn',
})``

export const DiscountLabel = styled.p`
  color: var(--sponsor-primary);
  font-family: Roboto;
  font-size: 15px;
  position: absolute;
  top: 9px;
  right: 55px;
  font-weight: bold;
`
