import styled from 'styled-components'
import WhiteButton from '~/components/WhiteButton'

export const Container = styled.div`
  display: ${props => (props.hasShreableLink ? 'flex' : 'none')};
`

export const ShareButton = styled(WhiteButton)`
  min-width: 120px;
  box-shadow: 0px 2px 4px 1px rgb(209, 213, 220);
  padding: 0px 10px 0px 10px;
`
