import styled from 'styled-components'
import { darken } from 'polished'

export const Button = styled.button`
  background-color: ${props => (props.color ? props.color : '#fb5561')};
  height: ${props => (props.height ? props.height : '40px')};
  width: ${props => (props.width ? props.width : 'auto')};
  border: ${props => (props.border ? props.border : 'none')};
  padding: ${props => (props.padding ? props.padding : '0')};
  border-radius: 5px;
  color: ${props => (props.textColor ? props.textColor : '#ffffff')};
  font-family: Lato;
  font-size: ${props => (props.fontSize ? props.fontSize : '15px')};
  font-weight: ${props => (props.bold === false ? '100' : '600')};

  &:hover {
    background-color: ${props => (props.color ? darken(0.05, props.color) : darken(0.05, '#fb5561'))};
  }

  span {
    text-transform: ${props => (props.uppercase === true ? 'uppercase' : 'initial')};
    color: ${props => (props.textColor ? props.textColor : '#ffffff')} !important;
  }
`
