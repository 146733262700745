/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useCallback } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import Row from 'react-bootstrap/Row'
import { useTranslation } from 'react-i18next'
import Slider from '@material-ui/core/Slider'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Spinner from 'react-bootstrap/Spinner'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Cropper from 'react-easy-crop'
import Col from 'react-bootstrap/Col'
import DefaultLogoImage from '~/util/community-profile-default-picture.png'
import { modalBodyStyleDefault } from '~/helper/const'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import useCampaignSelectors from '~/helper/campaignSelectors'
import { base64ImageToBlob } from '~/helper/formFunctions'
import getCroppedImg from './cropImage'
import * as S from './styled'

export default function ModalCropProfileImage({
  handleCloseModal,
  showModal,
  setModalStepToZero,
  sponsorInformations,
}) {
  const { t } = useTranslation()
  const { sponsorS3Logo } = useCampaignSelectors()
  const { uploadS3RequestAction, removeS3FromStateAction } = useSponsorFunctions()
  const { logoAdvPlatServerPath } = sponsorInformations
  const [loading, setLoading] = useState(false)
  const [logoImage, setLogoImage] = useState(logoAdvPlatServerPath)
  const [openDialog, setOpenDialog] = useState(false)
  const [openDialogDeletePhoto, setOpenDialogDeletePhoto] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const inputFile = useRef(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const getCroppedImage = useCallback(async () => {
    if (logoImage === null) {
      const imageToSend = base64ImageToBlob(DefaultLogoImage)
      uploadS3RequestAction(imageToSend)
      setModalStepToZero()
      handleCloseModal()
    } else {
      try {
        const croppedImage = await getCroppedImg(logoImage, croppedAreaPixels, rotation)
        setCroppedImage(croppedImage)

        const image = base64ImageToBlob(croppedImage)
        uploadS3RequestAction(image)
        setModalStepToZero()
        handleCloseModal()
      } catch (e) {
        toast.error(t('errorUpdatingImage'))
      }
    }
  }, [croppedAreaPixels, rotation, logoImage])

  const clickToCloseModal = () => {
    removeS3FromStateAction()
    setModalStepToZero()
    handleCloseModal()
    setLogoImage(logoAdvPlatServerPath)
  }

  const onBackButton = () => {
    removeS3FromStateAction()
    setModalStepToZero()
    setOpenDialog(false)
    setLogoImage(logoAdvPlatServerPath)
  }

  const deletePhoto = () => {
    setLogoImage(null)
    setCroppedImage(null)
    removeS3FromStateAction()
    setOpenDialogDeletePhoto(false)
    setLogoImage(null)
  }

  const changeFile = event => {
    event.stopPropagation()
    event.preventDefault()
    const reader = new FileReader()
    reader.onload = e => {
      setLogoImage(e.target.result)
    }
    reader.readAsDataURL(event.target.files[0])
  }

  const onButtonClick = () => {
    inputFile.current.click()
  }

  useEffect(() => {
    setLoading(true)
    if (logoAdvPlatServerPath === undefined || logoAdvPlatServerPath === null) {
      setLogoImage(sponsorS3Logo())
    }
    setLoading(false)
  }, [logoAdvPlatServerPath, sponsorS3Logo])

  return loading ? (
    <S.SpinnerContainer>
      <Spinner animation="border" size="sm" />
    </S.SpinnerContainer>
  ) : (
    <>
      <S.ModalCustom show={showModal} onHide={clickToCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <S.BackIcon onClick={() => setOpenDialog(true)} />
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
              <DialogTitle>
                <span>{t('attention')}</span>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>{t('discardUnsavedChanges')}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)} color="primary">
                  {t('cancel')}
                </Button>
                <Button onClick={onBackButton} color="primary">
                  {t('confirm')}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={openDialogDeletePhoto} onClose={() => setOpenDialogDeletePhoto(false)}>
              <DialogTitle>
                <span>{t('attention')}</span>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>{t('cofirmPhotoDeletion')}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialogDeletePhoto(false)} color="primary">
                  {t('cancel')}
                </Button>
                <Button onClick={deletePhoto} color="primary">
                  {t('confirm')}
                </Button>
              </DialogActions>
            </Dialog>
            <S.Title>{t('companyProfilePhoto')}</S.Title>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={modalBodyStyleDefault}>
          <Row>
            <Col md={12}>
              {logoImage === null && croppedImage === null && sponsorS3Logo() === null ? (
                <S.NoImage>
                  <S.NoImageIndicatorIcon />
                </S.NoImage>
              ) : (
                <>
                  <S.CropContainerParent>
                    <S.CropContainer>
                      <Cropper
                        image={logoImage === null ? logoAdvPlatServerPath : logoImage}
                        crop={crop}
                        zoom={zoom}
                        rotation={rotation}
                        aspect={1}
                        showGrid={false}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        cropShape="round"
                      />
                    </S.CropContainer>
                  </S.CropContainerParent>
                  <div className="controls mt-3 d-flex">
                    <Col md={6}>
                      <span>{t('zoom')}</span>
                      <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => setZoom(zoom)}
                      />
                    </Col>
                    <Col md={6}>
                      <span>{t('rotate')}</span>
                      <Slider
                        value={rotation}
                        min={0}
                        max={360}
                        step={1}
                        aria-labelledby="Rotation"
                        onChange={(e, rotation) => setRotation(rotation)}
                      />
                    </Col>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <S.ModalFooter>
          <div>
            <S.DeletePhoto onClick={() => setOpenDialogDeletePhoto(true)}>{t('deletePhoto')}</S.DeletePhoto>
          </div>
          <div>
            <S.ChangePhotoButton text={t('changePhoto')} action={onButtonClick} />
            <S.InputChangePhoto ref={inputFile} onChange={changeFile} />
            <S.SavePhotoButton action={getCroppedImage} text={t('savePhoto')} />
          </div>
        </S.ModalFooter>
      </S.ModalCustom>
    </>
  )
}
