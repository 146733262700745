import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import { darken } from 'polished'
import Button from '~/components/Shared/Button'
import { customMedia } from '~/styles/global'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 1300px;
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    p {
      margin: 0;
    }
  }
  .modal-content {
    height: auto;
    margin: auto;
    width: 1300px;
    border-radius: 0;
    padding: 0;

    ${customMedia.lessThan('1550px')`
      width: 1100px;
    `}

    ${customMedia.lessThan('1450px')`
      width: 900px;
    `}
  }

  .modal-body {
    padding: 0px !important;
    padding-bottom: 20px !important;
  }
`

export const SocialMidiaButtonsV = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 10px;

  button {
    border: none;
    background: transparent;

    &:hover {
      opacity: 0.6;
    }

    + button {
      margin-left: 5px;
    }
  }

  .social-icons {
    border-radius: 2px;
    width: 22px;
    height: 22px;
  }

  .social-icons-youtube {
    border-radius: 2px;
    width: 22px;
    height: 22px;
  }
`

export const Title = styled.p`
  color: #404257;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  opacity: 0.9;
`

export const ContainerCommunityProfilePicture = styled.div`
  width: 95px;
  height: 95px;
  border-radius: 100%;
`

export const SocialMidiaButtons = styled.div`
  display: flex;
  justify-content: space-around;
`

export const ModalHeaderInformations = styled.div`
  display: flex;
  align-items: center;
`

export const ChatButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #ff9052;
  border-radius: 100%;
  width: 27px;
  height: 27px;
  top: 0px;
  left: 2px;
`

export const ModalBody = styled.div`
  background-color: #f2f2f2;
  margin-top: 300px;
`

export const HeaderAndAnalyticsSection = styled.div`
  background-color: #1a73e7;
  padding: 30px 30px;
  position: relative;
  height: 300px;
`

export const CampaignInformationsAndSocialMidiaSection = styled.div`
  margin-left: 10px;
`

export const RatingSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;

  svg {
    margin-top: 5px;
    opacity: 0.85;
  }

  span {
    margin-right: 5px;
    margin-top: 5px;
    color: #ffff;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.86;
  }
`

export const NumberUsers = styled.p`
  margin-top: 4px;
  position: absolute;
  right: 45px;
  top: 80px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 35px;
  font-weight: 500;
  line-height: 20px;
  opacity: 0.86;
  margin-top: ${props => (props.campaignIsClosed === true ? '-12px !important' : '0px')};
`

export const EntitySocialMediaContainer = styled.div`
  display: flex;
  a {
    margin-left: 5px;
    img {
      width: 20px;
      height: 20px;

      &:hover {
        opacity: 0.6;
      }
    }
  }
`

export const ActiveUsersText = styled.p`
  position: absolute;
  right: 45px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
`

export const CampaignDateAndPriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`

export const ChatContainer = styled.div`
  position: relative;
  display: table;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  margin-top: 10px;
  background-color: #166acc;
  border-radius: 17px;
  height: 35px;
  padding: 0px 15px 0px 35px;

  &:hover {
    background-color: ${darken(0.04, '#166ACC')};
    text-decoration: none;
  }
`

export const ChatName = styled.p`
  margin: 0px;
  margin-top: 7px;
  text-align: center !important;
  color: rgba(255, 255, 255, 0.86);
  font-family: Roboto;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  opacity: 0.86;

  &:active {
    vertical-align: top;
    padding: 2px 0px 2px 0px;
  }
`

export const ChatImage = styled.img.attrs({
  src: '/icons/chat-white.png',
})`
  width: 14px;
  height: 14px;
`

export const CampaignDateContainer = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  background-color: #166acc;
  border-radius: 17px;
  width: 140px;
  height: 35px;

  p {
    margin: 0px;
    text-align: center;
    color: rgba(255, 255, 255, 0.86);
    font-family: Roboto;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    opacity: 0.86;
  }
`

export const CampaignDateText = styled.p`
  margin: 0px;
  text-align: center;
  color: rgba(255, 255, 255, 0.86);
  font-family: Roboto;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  opacity: 0.86;
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between !important;
  align-items: baseline;
  margin-bottom: 4px;
  padding-top: 0px;
`

export const EntityInformationsContainer = styled.div`
  padding: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
`

export const EntityInformations = styled.div`
  display: flex;
`

export const CampaignFormatsTitleContainer = styled.div`
  margin-top: 20px;
`

export const EntityNameAndLocationSection = styled.div`
  margin-top: 6px;
`

export const CampaignBudgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CampaignContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CampaignInformationTitle = styled.p`
  margin: 0px;
  color: #635b5b;
  font-family: Lato;
  font-size: 14px;
  padding: 0px 15px 5px 15px;
`

export const CampaignInformationSubtitle = styled.p`
  margin: 0px;
  color: #484848;
  font-family: Lato;
  font-size: 23px;
  font-weight: 700;
`
export const CampaignInformationYear = styled(CampaignInformationSubtitle)`
  font-size: min(1.4vw, 16px);
  position: absolute;
  bottom: -10px;
`

export const CampaignName = styled.div`
  max-width: 200px;
  word-break: normal;
  color: #47525e;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
`

export const EntityContainer = styled.div`
  display: flex;
  margin-left: 10px;
`

export const EntityLocationContainer = styled.div`
  display: flex;
`

export const EntityCity = styled.p`
  margin: 0px;
  color: #635b5b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  margin-top: -6px !important;
`

export const EntityCountry = styled(EntityCity)`
  padding-left: 4px;
`

export const EntityName = styled.p`
  margin: 0px;
  margin-top: 4px !important;
  color: var(--community-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  width: 12vw;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`

export const EntityLogo = styled.img`
  border-radius: 100px;
  width: 68px;
  height: 68px;
`

export const TitleContainer = styled.div`
  padding: 30px 30px 0px 30px;
`

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``

export const CloseButton = styled(Button).attrs({
  uppercase: true,
  width: '110px',
  height: '40px',
})``
