import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  setTutorialAsActive as setTutorialAsActiveAction,
  setTutorialAsInative as setTutorialAsInativeAction,
  neverShowTheTutorialAgainForTheSponsor as neverShowTheTutorialAgainForTheSponsorAction,
  neverShowTheTutorialAgainForTheCommunity as neverShowTheTutorialAgainForTheCommunityAction,
  setSponsorTutorialFirstLogin as setSponsorTutorialFirstLoginAction,
  setCommunityTutorialFirstLogin as setCommunityTutorialFirstLoginAction,
} from '~/store/modules/tutorial/actions'

import {
  openCreateCampaignForTutorial as openCreateCampaignForTutorialAction,
  closeCreateCampaignForTutorial as closeCreateCampaignForTutorialAction,
} from '~/store/modules/sponsor/actions'

const useTutorialFunctions = () => {
  const dispatch = useDispatch()
  const actions = useMemo(
    () => ({
      setTutorialAsActiveAction() {
        dispatch(setTutorialAsActiveAction())
      },
      setTutorialAsInativeAction() {
        dispatch(setTutorialAsInativeAction())
      },
      openCreateCampaignForTutorialAction() {
        dispatch(openCreateCampaignForTutorialAction())
      },
      closeCreateCampaignForTutorialAction() {
        dispatch(closeCreateCampaignForTutorialAction())
      },
      neverShowTheTutorialAgainForTheSponsorAction() {
        dispatch(neverShowTheTutorialAgainForTheSponsorAction())
      },
      neverShowTheTutorialAgainForTheCommunityAction() {
        dispatch(neverShowTheTutorialAgainForTheCommunityAction())
      },
      setSponsorTutorialFirstLoginAction() {
        dispatch(setSponsorTutorialFirstLoginAction())
      },
      setCommunityTutorialFirstLoginAction() {
        dispatch(setCommunityTutorialFirstLoginAction())
      },
    }),
    [dispatch],
  )
  return actions
}

export default useTutorialFunctions
