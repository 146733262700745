import styled, { css } from 'styled-components'

const breakpoints = {
  mobile: `(max-width: 760px)`,
  tablet: `(max-width: 1024px)`,
  laptop: `(max-width: 1280px)`,
  laptopL: `(max-width: 1440px)`,
  desktop: `(max-width: 1560px)`,
  desktopL: `(max-width: 1920px)`,
}

export const Nav = styled.nav`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgba(254, 253, 254);
  transition: all 0.3s ease-in-out;
  z-index: 25;

  ${({ isMinimumScroll }) =>
    isMinimumScroll &&
    css`
      box-shadow: var(--shadow);
    `}

  a:hover {
    text-decoration: none;
    color: #55555b;
  }
`

export const Content = styled.div`
  height: 6rem;
  padding: 0rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100rem;

  @media ${breakpoints.tablet} {
    padding: 0px 16px 16px 20px;
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
`

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  a,
  h4 {
    cursor: pointer !important;
    padding: 1rem;
    color: #55555b;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  @media ${breakpoints.mobile} {
    display: none;
  }
`

export const LogosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  @media ${breakpoints.tablet} {
    #powered-by {
      display: none;
    }
  }

  @media ${breakpoints.mobile} {
    #sos {
      display: none;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  button {
    padding: 0.688rem 1.875rem;
  }

  @media ${breakpoints.mobile} {
    display: none;
  }
`

export const MobileHeader = styled.div`
  width: 100%;
  display: none;
  justify-content: space-between;

  @media ${breakpoints.mobile} {
    display: flex;
  }
`

export const HamburgerIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  position: relative;
  z-index: 1100;
  gap: 3px;

  .line {
    border-radius: 50px;
    width: 16px;
    height: 2px;
    background-color: #7e7f86;
    transition: transform 0.2s ease-in-out;

    &:nth-child(1) {
      width: 75%;
    }
  }

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      .line:nth-child(1) {
        width: 100%;
        transform: rotate(45deg) translate(5px, 2px);
      }
      .line:nth-child(2) {
        opacity: 0;
      }
      .line:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -2px);
      }
    `}
`

export const DropdownMenu = styled.div`
  position: absolute;
  top: ${({ isMenuOpen }) => (isMenuOpen ? '100%' : '0')};
  right: 0;
  background: rgba(254, 253, 254);
  padding: ${({ isMenuOpen }) => (isMenuOpen ? '1rem' : '0')};
  display: ${({ isMenuOpen }) => (isMenuOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 1rem;
  z-index: 1000;
  width: 100%;
  transition: all 0.3s ease-in-out;

  a,
  p,
  h4 {
    font-weight: 700;
    border-radius: 1.6rem 1.6rem 0 0;
    font-size: 1.2rem;
    line-height: 2.7rem;
    background-color: transparent;
    color: #55555b;
    text-decoration: none;
    cursor: pointer;
    border-radius: 1.6rem;
    transition: transform 0.3s;
  }

  @media ${breakpoints.mobile} {
    top: ${({ isMenuOpen }) => (isMenuOpen ? '100%' : '-100%')};
  }
`
