import * as actions from './actionTypes'

export function recoverUserPasswordRequest(email) {
  return {
    type: actions.RECOVER_USER_PASSWORD_REQUEST,
    payload: { email },
  }
}

export function recoverUserPasswordSuccess(email) {
  return {
    type: actions.RECOVER_USER_PASSWORD_SUCCESS,
    payload: { email },
  }
}

export function recoverUserPasswordFailure() {
  return {
    type: actions.RECOVER_USER_PASSWORD_FAILURE,
  }
}

export function resetUserRecoveredPassword() {
  return {
    type: actions.RESET_USER_RECOVERED_PASSWORD,
  }
}
