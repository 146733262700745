import styled from 'styled-components'

export const AboutSection = styled.div`
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  margin: 10px 10px 3px 10px;
  width: 260px;
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
`

export const FormContainer = styled.div`
  .input-date {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    height: 40px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(159, 159, 159, 0.7);
    border-radius: 5px;
    padding-left: 5px;
  }
`

export const InputTitle = styled.span`
  margin-top: 3px;
  color: #404257;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
`

export const ProductTitle = styled.span`
  margin-top: 3px;
  color: #fff;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
`
