import React from 'react'
import * as S from './styles'

const CardContainer = ({ children, style, className }) => {
  return (
    <S.CardContainer className={className} style={style}>
      {children}
    </S.CardContainer>
  )
}

const ContentWrapper = ({ children, style }) => {
  return <S.ContentWrapper style={style}>{children}</S.ContentWrapper>
}

const ButtonWrapper = ({ children }) => {
  return <S.ButtonWrapper>{children}</S.ButtonWrapper>
}

const ListItem = ({ icon, text }) => {
  return (
    <S.ListItem>
      {icon}
      <p dangerouslySetInnerHTML={{ __html: text }} />
    </S.ListItem>
  )
}

export { CardContainer, ContentWrapper, ButtonWrapper, ListItem }
