import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'

export const Messages = styled.div`
  overflow: auto;
  flex: auto;
`

export const MessageContainer = styled.div`
  padding-left: 5px;
`

export const ChatContainer = styled.div`
  height: 335px;
`

export const LoadingContainer = styled.div`
  height: 335px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UserProfileInformationsForNotCurrentUser = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
`

export const LogoContainer = styled.div`
  width: 31px;
  height: 31px;
`
export const UserNameAndProfilePicture = styled.span`
  font-family: Lato;
  color: #47525e;
  font-size: 14px;
  font-weight: 700;
  margin-left: 18px;
`

export const WhenMessageWasSent = styled.span`
  font-family: Lato;
  color: #979ca1;
  font-size: 11px;
  font-weight: 700;
  margin-left: 4px !important;
`

export const LogoImage = styled.img`
  margin-left: 10px;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})`
  display: flex;
  justify-content: center;
  align-items: center;
`
