/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ChatChannel from '~/components/Chat/ChatChannel'
import { getAllChannelsFromEntity } from '~/helper/chatFunctions'
import useChatFunctions from '~/helper/chatActions'
import * as S from './styled'

export default function ChatList({ entityId, userType }) {
  const { t } = useTranslation()
  const [channels, setChannels] = useState([])
  const [sponsoredChannels, setSponsoredChannels] = useState([])
  const [loadingChannels, setLoadingChannels] = useState(true)
  const { openCloseChatWindowAction, setActiveChannelAction } = useChatFunctions()
  const openedChat = useSelector(state => state.chat.openedChat)

  useEffect(() => {
    getAllChannelsFromEntity(userType, entityId, setChannels, setSponsoredChannels, setLoadingChannels)
  }, [openedChat, loadingChannels])

  useEffect(() => {
    openCloseChatWindowAction(false)
    setActiveChannelAction(null)
  }, [])

  return loadingChannels ? (
    <S.SpinnerContainer>
      <S.CustomSpinner />
    </S.SpinnerContainer>
  ) : (
    <S.CardContainer bg="#FFFFFF">
      <S.CardHeader userType={userType}>
        <S.ChannelsIcon />
        <S.HeaderTitle>{t('channels')}</S.HeaderTitle>
      </S.CardHeader>

      {sponsoredChannels?.length > 0 && (
        <>
          <S.Title>{t('activeSponsoredChannels')}</S.Title>
          <S.SponsoredChatsDescription>{t('sponsoredChannelsDescription')}</S.SponsoredChatsDescription>
        </>
      )}

      {sponsoredChannels !== undefined &&
        sponsoredChannels
          .filter(channel => !channel.archived)
          .map(channel => (
            <ChatChannel
              key={channel.id}
              communityPicture={channel.pictureCommunity.url}
              communityName={channel.communityName}
              communityId={Number(channel.communityId)}
              channelId={channel.id}
              sponsorId={channel.sponsorId}
              sponsorName={channel.sponsorName}
              sponsorPicture={channel.pictureSponsor.url}
              userType={userType}
              lastMessage={channel.lastMessage ? channel.lastMessage.message : t('clickToChat')}
              channelIsSponsored={channel.isSponsored}
              sponsoredChannelTitle={channel.title}
              openedChat={openedChat}
              setOpenedChat={openCloseChatWindowAction}
              channel={channel}
            />
          ))}

      {sponsoredChannels?.length > 0 && (
        <S.BorderContainer>
          <S.Border />
        </S.BorderContainer>
      )}

      {channels?.length > 0 && (
        <S.Title>{userType === 'Sponsor' ? t('chatWithCommunities') : t('chatWithSponsors')}</S.Title>
      )}
      {channels !== undefined &&
        channels?.map(channel => {
          return (
            <ChatChannel
              key={channel.id}
              communityPicture={channel.pictureCommunity.url}
              communityName={channel.communityName}
              communityId={channel.communityId}
              channelId={channel.id}
              sponsorId={channel.sponsorId}
              sponsorName={channel.title}
              sponsorPicture={channel.pictureSponsor.url}
              userType={userType}
              lastMessage={channel.lastMessage ? channel.lastMessage.message : t('clickToChat')}
              openedChat={openedChat}
              setOpenedChat={openCloseChatWindowAction}
              channel={channel}
            />
          )
        })}
    </S.CardContainer>
  )
}
