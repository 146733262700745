import React, { useMemo } from 'react'
import { IbanElement, AddressElement } from '@stripe/react-stripe-js'
import useResponsiveFontSize from '~/helper/useResponsiveFontSize'
import TextField from '@material-ui/core/TextField'
import i18n from '~/i18n'
import { useTranslation } from 'react-i18next'
import { PoweredByStripe, PoweredByStripeContainer } from '../styled'
import * as S from './styled'

export default function IbanForm({ onSubmit }) {
  const { t } = useTranslation()

  const fontSize = useResponsiveFontSize()

  const IBAN_STYLE = useMemo(
    () => ({
      base: {
        color: '#424770',
        padding: '12px',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        backgroundColor: 'white',
        fontSize,
        '::placeholder': {
          color: '#aab7c4',
        },
        ':-webkit-autofill': {
          color: '#424770',
        },
      },
      invalid: {
        color: '#9e2146',
        iconColor: '#9e2146',
        ':-webkit-autofill': {
          color: '#9e2146',
        },
      },
    }),
    [fontSize],
  )

  const currentLanguage = i18n.language

  const IBAN_ELEMENT_OPTIONS = useMemo(
    () => ({
      supportedCountries: ['SEPA'],
      // Elements can use a placeholder as an example IBAN that reflects
      // the IBAN format of your customer's country. If you know your
      // customer's country, we recommend that you pass it to the Element as the
      // placeholderCountry.
      placeholderCountry: currentLanguage.toUpperCase(),
      style: IBAN_STYLE,
    }),
    [currentLanguage, IBAN_STYLE],
  )

  return (
    <form id="stripe-sepa" onSubmit={onSubmit}>
      <S.Container>
        <S.StripeElementContainer>
          <S.Label>{t('email')}</S.Label>
          <TextField
            style={{ borderRadius: 6, marginBottom: '0.75rem' }}
            name="email"
            placeholder={t('typeYourEmail')}
            required
            type="email"
            fullWidth
            inputProps={{
              maxLength: 80,
            }}
          />
        </S.StripeElementContainer>

        <S.StripeElementContainer>
          <AddressElement
            options={{
              mode: 'billing',
              fields: {
                phone: 'never',
              },
              autocomplete: { mode: 'google_maps_api', apiKey: 'AIzaSyDgO_N9c1jeeop39DwJ-cFYMTn46tHHJHs' },
            }}
          />
        </S.StripeElementContainer>
        <S.Label style={{ marginTop: 12, marginBottom: 4 }}>IBAN</S.Label>

        <S.StripeElementContainer
          className="iban-container"
          style={{
            background: 'white',
            padding: 12,
            marginBottom: 12,
            borderRadius: 8,
          }}
        >
          <IbanElement options={IBAN_ELEMENT_OPTIONS} />
        </S.StripeElementContainer>

        <p style={{ color: '#6b7c93', fontSize: 12 }}>{t('sepaMandate')}</p>

        <PoweredByStripeContainer>
          <PoweredByStripe />
        </PoweredByStripeContainer>
      </S.Container>
    </form>
  )
}
