import * as actions from './actionTypes'

export function updateStep(step) {
  return {
    type: actions.UPDATE_STEP,
    payload: step,
  }
}

export function increaseStep() {
  return {
    type: actions.INCREASE_STEP,
  }
}

export function decreaseStep() {
  return {
    type: actions.DECREASE_STEP,
  }
}

export function resetStep() {
  return {
    type: actions.RESET_STEP,
  }
}
