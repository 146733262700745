import React, { useCallback, useState } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import * as Yup from 'yup'
import { ResponsiveAnimation } from '~/components/ResposiveAnimation'
import TextArea from '../../Components/Inputs/textArea'
import Figure from '../../Components/Figure'
import Input from '../../Components/Inputs/input'
import * as S from './styles'
import { Button } from '../../Components/Button/Button'
import CheckedIllustration from './illustrations/CheckedIllustration'

export default function ContactUs() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/

  const { control, handleSubmit, errors, reset } = useForm({
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .max(80)
        .required('Name is required'),
      surname: Yup.string()
        .max(80)
        .required('Surname is required'),
      email: Yup.string()
        .max(80)
        .email('Invalid email format')
        .required('E-mail is required'),
      phone: Yup.string()
        .min(8, 'Minimum 8 numbers is required')
        .matches(phoneRegExp, 'Invalid phone number')
        .required('Mobile phone is required'),
      message: Yup.string()
        .max(500)
        .required('Message is required'),
    }),
  })

  const onSubmit = useCallback(
    async values => {
      setIsSubmitting(true)

      const { name, surname, email, phone, message } = values

      const body = {
        firstName: name,
        lastName: surname,
        email,
        phone,
        message,
      }

      fetch('https://api.network.hashtag.be/contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then(() => {
          reset()
          setIsSubmitting(false)

          setIsSuccess(true)
          setTimeout(() => {
            setIsSuccess(false)
          }, 3000)
        })
        .catch(error => {
          console.log({ error })
          setIsError(true)
          setTimeout(() => {
            setIsError(false)
          }, 3000)
        })
      return true
    },
    [reset],
  )

  return (
    <S.Container id="about-us">
      <S.FormContainer>
        <ResponsiveAnimation type="fade" applyOn="both" effects={{ direction: 'left' }}>
          <S.Header>
            <h2>Contact us, we will be happy to talk with you</h2>
            <p>Feel free to ask all your questions, we will answer you as soon as possible!</p>
          </S.Header>
        </ResponsiveAnimation>

        <S.FormContent>
          <ResponsiveAnimation type="fade" applyOn="desktop" effects={{ direction: 'left' }}>
            <S.DesktopImage>
              <Figure id="desktop-image" src="/images/contact-us.svg" alt="Contact us" />
            </S.DesktopImage>
          </ResponsiveAnimation>
          <S.CallbackSubmit show={isSuccess}>
            <CheckedIllustration />
            <div className="content">
              <p>Thank you for your message</p>
            </div>
          </S.CallbackSubmit>
          <S.CallbackSubmit show={isError}>
            <div className="content">
              <p>Error, please try again later</p>
            </div>
          </S.CallbackSubmit>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <S.InputsContainer>
              <S.InputContainer>
                <Controller
                  as={<Input label="Name" placeholder="Your name" type="text" />}
                  name="name"
                  control={control}
                  defaultValue=""
                />
                <ErrorMessage errors={errors} name="name" as="p" />
              </S.InputContainer>

              <S.InputContainer>
                <Controller
                  as={<Input label="Surname" placeholder="Your surname" type="text" />}
                  name="surname"
                  control={control}
                  defaultValue=""
                />
                <ErrorMessage errors={errors} name="surname" as="p" />
              </S.InputContainer>

              <S.InputContainer>
                <Controller
                  as={<Input label="E-mail" placeholder="Type your e-mail" type="email" />}
                  name="email"
                  control={control}
                  defaultValue=""
                />
                <ErrorMessage errors={errors} name="email" as="p" />
              </S.InputContainer>

              <S.InputContainer>
                <Controller
                  as={<Input label="Mobile phone" placeholder="(00) 987-654-321" type="tel" />}
                  name="phone"
                  control={control}
                  defaultValue=""
                />
                <ErrorMessage errors={errors} name="phone" as="p" />
              </S.InputContainer>

              <S.InputContainer className="text-area">
                <Controller
                  as={<TextArea label="Message" placeholder="Type a message..." type="text" />}
                  name="message"
                  control={control}
                  defaultValue=""
                />
                <ErrorMessage errors={errors} name="message" as="p" />
              </S.InputContainer>
            </S.InputsContainer>

            <S.SendContainer>
              <p>
                By sending the message you agree to our
                <span>
                  {' '}
                  <a href="https://be-code-of-conduct.hashtag.be/" target="_blank" rel="noopener noreferrer">
                    terms
                  </a>{' '}
                </span>
                and
                <span>
                  {' '}
                  <a href="https://be-privacy-policy.hashtag.be/" target="_blank" rel="noopener noreferrer">
                    privacy policy
                  </a>
                </span>
              </p>
              <Button label="Send" type="submit" isLoading={isSubmitting} />
            </S.SendContainer>

            {isSuccess && <p>Form submitted successfully!</p>}
            {isError && <p>There was an error submitting the form. Please try again.</p>}
          </S.Form>
          <ResponsiveAnimation type="fade" applyOn="mobile" effects={{ direction: 'left' }}>
            <S.MobileImage>
              <Figure id="mobile-image" src="/images/contact-us.svg" alt="Contact us" />
            </S.MobileImage>
          </ResponsiveAnimation>
        </S.FormContent>
      </S.FormContainer>
    </S.Container>
  )
}
