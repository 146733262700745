import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import { RiSave2Line } from 'react-icons/ri'
import Spinner from 'react-bootstrap/Spinner'
import { MdWeb, MdDirectionsWalk, MdLanguage } from 'react-icons/md'
import { customMedia } from '~/styles/global'
import WhiteButton from '~/components/WhiteButton'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    max-width: 910px;
    height: auto;
    margin: auto;
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;

    p {
      margin: 0;
    }

    ${customMedia.lessThan('960px')`
      padding: 0px 2rem;
    `}

    ${customMedia.lessThan('small')`
       padding: 0px;
    `}
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;

    ${customMedia.lessThan('small')`
      width: 22rem !important;
      margin: auto;
    `}
  }

  .modal-body {
    padding-bottom: 28px !important;
  }
`

export const InputLabel = styled.span`
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
`

export const SpecialOfferInputLabel = styled.span`
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
`

export const ModalTitle = styled.p`
  color: var(--text-bold);
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-bottom: 4px;
  padding-top: 0px !important;
`

export const PackageMonthlyPriceContainer = styled.div`
  input:disabled {
    background-color: ${props => (props.hasSpecialOffer ? '#e6e6e6 !important' : '#469c3f !important')};
    border-radius: 5px;
  }

  .MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    border-radius: 5px;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    background-color: ${props => (props.hasSpecialOffer ? '#e6e6e6 !important' : '#469c3f !important')};
  }

  .MuiOutlinedInput-input {
    color: ${props => (props.hasSpecialOffer ? null : '#FFF !important')};
  }
`

export const Container = styled.div`
  .MuiOutlinedInput-input {
    padding: 9px !important;
  }
`
export const ProductsColumn = styled(Col)``
export const FormColumn = styled(Col)``

export const WebAndMobileProductsSection = styled.div`
  display: flex;
  align-items: flex-end;
`

export const WebMobileText = styled.span`
  color: var(--community-dark);
  font-family: Roboto;
  font-size: 14px;
  margin-left: 6px;
  font-weight: 600;
  letter-spacing: 0.5px;
`

export const BorderContainer = styled.div`
  margin: 8px 8px 14px 8px;
`

export const PackageCompositionCardContainer = styled.div`
  margin-top: 6px;
`

export const SocialProductsSection = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
`

export const PhysicalProductsSection = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
`

export const ErrorMessageContainer = styled.div.attrs({
  className: 'error-message',
})``

export const InputContainer = styled.span`
  display: flex;
  margin-top: 10px;
  margin-bottom: 3px;
  align-items: flex-start;
`

export const PackageDetailsAndPrice = styled.div`
  position: relative;
  background-color: #fff;
  padding: 12px 20px 20px 20px;
  border-radius: 5px;
  margin-top: 48px;
`

export const FormTitle = styled.p`
  color: var(--community-dark);
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
`

export const BorderTitleContainer = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
`

export const SummaryTitle = styled.p`
  color: var(--input-title);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
`

export const SummarySection = styled.div`
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
`

export const ProductInformationContainer = styled.div.attrs({
  className: 'animated fadeIn',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`

export const ProductQuantityInformation = styled.p`
  width: 220px;
  color: #635b5b;
  font-family: Roboto;
  font-size: 14px;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`

export const ProductInformationText = styled.p`
  color: #635b5b;
  font-family: Roboto;
  font-size: 14px;
`

export const CustomBorderContainer = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
`

export const CustomBorder = styled.p`
  border: solid rgba(214, 214, 214, 0.3);
  border-width: 1px;
  margin-bottom: 0;
  width: 100% !important;
  opacity: 0.6;
`

export const PayAsYouGoInformationContainer = styled.div``

export const Form = styled.form.attrs({
  id: 'my-form',
})``

export const SaveButton = styled(WhiteButton).attrs({
  style: { marginTop: '20px' },
  type: 'submit',
})``

export const SaveIcon = styled(RiSave2Line).attrs({
  color: 'var(--success)',
  size: 23,
})``

export const CancelButton = styled(WhiteButton).attrs({
  style: { marginTop: '20px', padding: '0px 30px' },
})``

export const MobileWebIcon = styled(MdLanguage).attrs({
  color: 'var(--community-dark)',
  size: 24,
})``

export const SocialIcon = styled(MdWeb).attrs({
  color: 'var(--community-dark)',
  size: 24,
})``

export const PhysicalIcon = styled(MdDirectionsWalk).attrs({
  color: 'var(--community-dark)',
  size: 24,
})``

export const GreySectionSeparator = styled.div`
  background-color: #f2f2f2;
  height: 25px;
  margin-right: -20px !important;
  margin-left: -20px !important;
  margin-top: 30px;
`

export const SpecialOfferSectionContainer = styled.div`
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
  width: 100% !important;
`

export const SpecialOfferTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 10px;
  margin-bottom: 15px;
`

export const TotalDiscountedPriceContainer = styled.div`
  input:disabled {
    background-color: ${props => (props.hasSpecialOffer ? '#e6e6e6 !important' : '#469c3f !important')};
    border-radius: 5px;
  }

  .MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    background-color: #469c3f !important;
    border-radius: 5px;
  }

  .MuiOutlinedInput-input {
    color: #fff !important;
  }
`

export const EuroInputAdornment = styled.div`
  color: ${props => (props.white ? '#FFF' : '#333333')};
`

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`

export const MonthlyDiscountedPriceContainer = styled.div`
  input:disabled {
    background-color: #fff;
    color: #333333;
  }
`

export const SpecialOfferDiscountContainer = styled.div`
  .MuiTypography-colorTextSecondary {
    color: #333333;
  }
`

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``
