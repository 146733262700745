import React from 'react'
import contentNbaImage from '~/../public/nba-game.jpg'
import communityAbcMockupLogo from '~/../public/community-header-logo.png'
import communityRunningMockupLogo from '~/../public/social-run-group.jpg'
import contentFootballImage from '~/../public/football.png'
import * as S from './styled'

const SlidingTopBannerPreview = ({ media }) => {
  return (
    <>
      <S.CellphoneHeader>
        <S.Hour>18:06</S.Hour>
        <S.CellphoneInformationsContainer>
          <S.SignalIcon />
          <S.WifiIcon />
          <S.BatteryIcon />
        </S.CellphoneInformationsContainer>
      </S.CellphoneHeader>

      <S.SlidingBannerContainer>
        {!media ? (
          <S.NoMediaIndicator>
            <S.ImageIcon />
          </S.NoMediaIndicator>
        ) : (
          <S.BannerContainer>
            <S.Banner src={media} />
          </S.BannerContainer>
        )}
      </S.SlidingBannerContainer>

      <S.ContentTitleContainer>
        <S.SectionTitle>Sports</S.SectionTitle>
        <S.SeeMoreContainer>
          <S.SeeMore>See more</S.SeeMore>
          <S.ArrowIcon />
        </S.SeeMoreContainer>
      </S.ContentTitleContainer>

      <S.ContentContainer>
        <S.Content>
          <S.ContentImage src={contentFootballImage} />
          <S.CommunityMockupLogo src={communityAbcMockupLogo} />
          <S.ContentTitleAndDescription>
            <S.ContentTitle>ATZ Sport Club</S.ContentTitle>
            <S.ContentDescription>Well, sports play a crucial role in everyone’s life.</S.ContentDescription>
          </S.ContentTitleAndDescription>
        </S.Content>
        <S.Content>
          <S.ContentImage src={contentNbaImage} />
          <S.CommunityMockupLogo src={communityRunningMockupLogo} />
          <S.ContentTitleAndDescription>
            <S.ContentTitle>NBA Game</S.ContentTitle>
            <S.ContentDescription>Watching sports is also extreme entertainment.</S.ContentDescription>
          </S.ContentTitleAndDescription>
        </S.Content>
      </S.ContentContainer>
    </>
  )
}

export default SlidingTopBannerPreview
