import React from 'react'
import * as S from './styled'

export default function TabTitleAndSubtitle({ sponsor, title, subtitle, row, margin }) {
  return (
    <S.TextContainer row={row}>
      <S.Title sponsor={sponsor}>{title}</S.Title>
      <S.Subtitle row={row} margin={margin}>
        {subtitle}
      </S.Subtitle>
    </S.TextContainer>
  )
}
