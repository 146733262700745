import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { getThumbnails } from 'video-metadata-thumbnails'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import { InputTitle } from '~/components/Shared/StyledComponents'
import ErrorMessage from '~/components/Shared/ErrorMessage'
import { uploadMediaToS3 } from '~/services/requests'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import {
  setTheSocialMediaContentPreview,
  formatAndSendPostSocialMediaFormat,
  validateSocialMediaImageUpload,
  validateSocialMediaVideoUpload,
} from '~helper/helperFunctions'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import * as S from './styled'

const ModalCreateSocialMediaPostFormat = props => {
  const { t } = useTranslation()
  const { createCampaignFormatAction } = useSponsorFunctions()
  const { id: campaignId } = useSelector(state => state.createCampaign?.currentCampaignForReviewPage)
  const { handleSubmit, watch, control, errors } = useForm()

  const imageInput = useRef(null)
  const videoInput = useRef(null)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [media, setMedia] = useState(null)
  const [videoThumbnail, setVideoThumbnail] = useState(null)
  const [mediaFileType, setMediaFileType] = useState(null)
  const inputWatch = {
    title: watch('title', 'Title'),
    description: watch('description'),
  }
  const {
    showModal,
    handleCloseModal,
    campaignProductId,
    socialMediaFormat: { slug: formatType, name: formatName } = {},
    community,
  } = props || {}

  const resetForm = () => {
    setMedia(null)
    setVideoThumbnail(null)
    setMediaFileType(null)
  }

  const closeModal = () => {
    resetForm()
    handleCloseModal()
  }

  const onInputVideoClick = event => {
    event.target.value = ''
  }

  const onSubmit = payload => {
    if (formatType === 'instagramPost' && !media) {
      return toast.error(t('imageIsRequired'))
    }
    return formatAndSendPostSocialMediaFormat(
      payload,
      mediaFileType,
      media,
      campaignProductId,
      campaignId,
      createCampaignFormatAction,
      closeModal,
      videoThumbnail,
    )
  }

  const handleClickUploadInput = uploadRef => {
    return uploadRef?.current?.click()
  }

  const addImage = event => {
    setVideoThumbnail(null)
    const mediaSize = event.target?.files?.[0]?.size
    const validationError = validateSocialMediaImageUpload(formatType, mediaSize)
    if (!validationError) {
      if (event.target?.files?.[0]) {
        const image = event?.target?.files?.[0]
        uploadMediaToS3(image, setLoadingUpload, setMedia)
      }
      return setMediaFileType('image')
    }
    return null
  }

  const addVideo = async event => {
    const mediaSize = event.target?.files?.[0]?.size
    const mediaType = event.target?.files?.[0].type

    if (mediaType !== 'video/mp4') {
      return toast.error(t('onlyMp4Files'))
    }

    if (mediaSize > 50000000) {
      return toast.error(t('videoIsTooBig', { limit: '50mb' }))
    }

    if (mediaSize > 5000000 && formatType === 'twitterPost') {
      return toast.error(t('videoIsTooBig', { limit: '5mb' }))
    }

    if (mediaSize > 50000000 && !formatType === 'twitterPost') {
      return toast.error(t('videoIsTooBig', { limit: '50mb' }))
    }

    if (event.target?.files?.[0]) {
      const reader = new FileReader()
      reader.onload = () => {
        const media = new Audio(reader.result)
        media.onloadedmetadata = async () => {
          const validationError = validateSocialMediaVideoUpload(formatType, media.duration)

          if (!validationError) {
            const video = URL.createObjectURL(event.target?.files?.[0])
            const thumbnail = await getThumbnails(video, {
              quality: 0.6,
              start: 0,
              end: 0,
            })
            const videoToUpload = event.target?.files?.[0]
            await uploadMediaToS3(videoToUpload, setLoadingUpload, setMedia)
            await uploadMediaToS3(thumbnail[0].blob, setLoadingUpload, setVideoThumbnail)
            return setMediaFileType('video')
          }
        }
      }
      await reader.readAsDataURL(event.target?.files?.[0])
    }
  }

  const isInputDisabled = inputType => {
    if (inputType === 'image') {
      if (media && mediaFileType === 'video') {
        return true
      }
    }
    if (inputType === 'video') {
      if (media && mediaFileType === 'image') {
        return true
      }
    }
    return false
  }

  return (
    <ProductsModal
      action={onSubmit}
      modalTitle={t('newFormat', { format: formatName })}
      handleCloseModal={closeModal}
      showModal={showModal}
      review={false}
      loadingUpload={loadingUpload}
      leftColumn={
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <InputTitle>{t('title')}</InputTitle>
            <Controller
              as={
                <S.CustomTextField
                  placeholder={t('typeTitle')}
                  inputProps={{
                    maxLength: 80,
                  }}
                />
              }
              rules={{ required: t('defaultValidation', { inputName: t('title') }) }}
              name="title"
              control={control}
            />
            <ErrorMessage errors={errors} name="title" />

            <S.InputContainer>
              <InputTitle>{t('description')}</InputTitle>
              <Controller
                as={
                  <S.CustomMultilineTextField
                    placeholder={t('moreAboutContent')}
                    inputProps={{
                      maxLength: 180,
                    }}
                  />
                }
                name="description"
                control={control}
              />
            </S.InputContainer>
            <S.InputContainer>
              <InputTitle>{t('addMediasToYourPost')}</InputTitle>
              <S.HelperText>{t('onlyChooseOneOption')}</S.HelperText>
            </S.InputContainer>
          </S.FormContainer>

          {loadingUpload && (
            <S.SpinnerContainer>
              <S.CustomSpinner />
            </S.SpinnerContainer>
          )}

          {!loadingUpload && !media && (
            <S.UploadSection>
              <S.UploadContainer
                onClick={() => handleClickUploadInput(imageInput)}
                className={isInputDisabled('image') ? 'disabled' : ''}
              >
                <S.ImageUploadIcon />
                <S.UploadText>{t('imageUpload')}</S.UploadText>
              </S.UploadContainer>

              <S.UploadContainer
                spacing
                onClick={!isInputDisabled('video') && (() => handleClickUploadInput(videoInput))}
                disabled={media && mediaFileType === 'image'}
                className={isInputDisabled('video') ? 'disabled' : ''}
              >
                <S.VideoUploadIcon />
                <S.UploadText>{t('videoUpload')}</S.UploadText>
              </S.UploadContainer>
            </S.UploadSection>
          )}

          {/* Preview media */}
          {!loadingUpload && media && (
            <S.PreviewMediaContainer>
              {mediaFileType === 'video' && (
                <S.PlayIconContainer>
                  <S.PlayIcon />
                </S.PlayIconContainer>
              )}
              <S.PreviewMedia src={mediaFileType === 'image' ? media : videoThumbnail} />
              <S.CloseIcon
                onClick={() => {
                  setMedia(null)
                  setMediaFileType(null)
                  setVideoThumbnail(null)
                }}
              />
            </S.PreviewMediaContainer>
          )}
          <S.InputAddImage ref={imageInput} onChange={addImage} />
          <S.InputAddVideo ref={videoInput} onChange={addVideo} onClick={onInputVideoClick} />
        </S.Form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          defaultVisualization
          FormatVisualizationComponent={setTheSocialMediaContentPreview(
            formatType,
            community,
            inputWatch,
            media,
            videoThumbnail,
          )}
        />
      }
    />
  )
}

export default ModalCreateSocialMediaPostFormat
