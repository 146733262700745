import produce from 'immer'
import { SET_SPONSOR_CURRENT_TAB, SET_COMMUNITY_CURRENT_TAB } from './actionTypes'

const INITIAL_STATE = {
  communityCurrentTab: 'products',
  sponsorCurrentTab: 'communities',
}

export default function tab(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_COMMUNITY_CURRENT_TAB: {
        draft.communityCurrentTab = action.payload.tab
        break
      }

      case SET_SPONSOR_CURRENT_TAB: {
        draft.sponsorCurrentTab = action.payload.tab
        break
      }

      default:
    }
  })
}
