import React from 'react'
import ReactAudioPlayer from 'react-audio-player'
import * as S from './styled'

const AudioPlayer = ({ width, audio }) => {
  return (
    <S.AudioContainer style={{ width }}>
      <ReactAudioPlayer src={audio.url} controls />
    </S.AudioContainer>
  )
}

export default AudioPlayer
