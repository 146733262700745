import styled from 'styled-components'
import ProfileButton from '~/components/ProfileButton'
import { customMedia } from '~/styles/global'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  z-index: 2;
  margin-bottom: 24px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`

export const ImageContainer = styled.div`
  position: relative;
  height: 200px;
`

export const BannerImage = styled.img.attrs({
  alt: 'Community Banner',
})`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const LogoImage = styled.img.attrs({
  alt: 'Community Logo',
})`
  position: absolute;
  width: 79px;
  height: 79px;
  left: 10px;
  bottom: calc(-79px / 2);
  border: 4px solid #fff;
  border-radius: 50%;
  background-color: #fff;
  object-fit: cover;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export const CommunityInformationSection = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding-bottom: 14px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

export const CommunityKpisContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 6px;
  padding: 0px 8px 10px 8px;
`

export const CampaignLabelsSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const AdViews = styled.div`
  color: #343f4b;
  font-family: Roboto;
  font-size: 17px;
  font-weight: bold;
  padding-right: 15px;
  padding-left: 15px;
`

export const PreviewButtonContainer = styled.div`
  transition: all 50ms ease;

  img {
    ${customMedia.lessThan('huge')`
      width: 20px;
      height: 20px;
    `}
  }

  &:hover {
    transform: scale(1.098);
  }

  &:active {
    position: relative;
    top: 2px;
  }
`

export const EditButtonContainer = styled.div`
  transition: all 50ms ease;

  img {
    ${customMedia.lessThan('huge')`
      width: 15px;
      height: 15px;
    `}
  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    position: relative;
    top: 2px;
  }
`

export const ButtonSection = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 15px;
  bottom: calc(-32px / 2);

  button {
    margin: 5px;

    ${customMedia.lessThan('huge')`
      width: 28px;
      height: 28px;
    `}
  }
`

export const CommunityNameContainer = styled.div`
  display: flex;
  padding: 45px 15px 0px 15px;
`

export const CommunityName = styled.div`
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  width: 170px;
  opacity: 0.9;
  letter-spacing: 0.5px;

  ${customMedia.lessThan('huge')`
      font-size: 16px;
    `}
`

export const CommunityLocationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 8px 15px 10px 15px;
  margin-top: -5px;
  border-bottom: 2px solid rgba(214, 214, 214, 0.3);

  ${customMedia.lessThan('1370px')`
    margin-top: 0px;
  `}
`

export const CommunityLocation = styled.div`
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
`

export const CommunityStatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: ${props => (props.hasSocialMedia ? '2px solid rgba(214, 214, 214, 0.3)' : null)};
  margin-top: ${props => (props.hasSocialMedia ? '14px' : '0px')};

  img {
    margin-right: 10px;
  }

  p {
    margin: 0;
  }
`

export const CommunityStatusReprovedIcon = styled.img.attrs({
  src: '/community-status-logo.png',
  alt: 'Community Status',
})`
  width: 27px;
  height: 27px;
  margin-top: 8px;
`

export const CommunityStatusApprovedIcon = styled.img.attrs({
  src: '/community-status-logo-ok.png',
  alt: 'Community Status',
})`
  width: 27px;
  height: 27px;
  margin-top: 8px;
`

export const CommunityStatusSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  margin-left: 8px;
`

export const CommunityStatusTitle = styled.div`
  color: var(--input-title);
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;

  ${customMedia.lessThan('huge')`
    font-size: 14px;
  `}
`

export const CommunityStatusSubtitle = styled.div`
  margin-top: -5px !important;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;

  ${customMedia.lessThan('huge')`
    font-size: 13px;
  `}
`

export const SocialMediaContainer = styled.div`
  margin-top: 16px;
  padding-top: 14px;
  border-top: 2px solid rgba(214, 214, 214, 0.3);
`

export const PreviewCommunityProfileButton = styled(ProfileButton).attrs({
  imgWidth: '25px',
  imgHeight: '25px',
  review: true,
  backgroundColor: '#9671F5',
})``

export const EditCommunityProfileButton = styled(ProfileButton).attrs({
  backgroundColor: '#9671F5',
})``
