import React, { useRef, useCallback, useState, useEffect } from 'react'
import { MdAdd, MdClose, MdRemove, MdDone } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import AvatarEditor from 'react-avatar-editor'
import Image from '~/components/Image'
import WhiteButton from '~/components/WhiteButton'
import Modal from './Modal'
import * as S from './styles'

const ContentCropImageModal = props => {
  const { visible, onClose, imageToCrop, imagePreview, setCroppedImage, uploadType, type = 'topBanner' } = props

  const { t } = useTranslation()

  const [scale, setScale] = useState(1)
  const [previewCroppedImage, setPreviewCroppedImage] = useState(imageToCrop)
  const [base64ImagePreview, setBase64ImagePreview] = useState(null)
  const [updateCallback, setUpdateCallback] = useState(null)
  const editor = useRef(null)

  const bannerDimensions = {
    bottomBanner: 63,
    topBanner: 218,
  }

  const handleUpdate = () => {
    setCroppedImage(previewCroppedImage)
    onClose()
  }

  const handleZoom = useCallback(
    e => {
      const scale = parseFloat(e.target.value)
      setScale(scale)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scale],
  )

  const handleClickZoom = useCallback(
    type => () => {
      if (type === 'more' && scale >= 2) return
      if (type === 'less' && scale <= 1) return
      if (type === 'more') return setScale(scale + 0.05)
      setScale(scale - 0.05)
    },
    [scale],
  )

  const handleUpdatePreviewWhenUserChangeImagePosition = useCallback(() => {
    setUpdateCallback(prevState => !prevState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale])

  const getImageToCrop = () => {
    if (uploadType === 'blob') {
      const reader = new FileReader()
      reader.readAsDataURL(imageToCrop)
      reader.onloadend = () => {
        const base64String = reader.result
        setBase64ImagePreview(base64String)
      }
      return base64ImagePreview
    }
    return typeof imageToCrop === 'string' ? `${imageToCrop}?cacheblock=true` : imageToCrop
  }

  useEffect(() => {
    setTimeout(() => {
      const imageOnCanvas = editor.current?.getImageScaledToCanvas().toDataURL()
      if (imageOnCanvas) setPreviewCroppedImage(imageOnCanvas)
    }, 200)
  }, [previewCroppedImage, imagePreview, scale, editor, updateCallback, imageToCrop, visible])

  useEffect(() => {
    if (visible) {
      setScale(1)
    }
  }, [visible])

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      height="auto"
      width="537px"
      hideCloseIcon={false}
      padding="60px 18px 0 18px"
      blockOutsideClick
    >
      <S.Container>
        <S.Title>{t('customizeBannerVisualization')}</S.Title>

        <S.CropContainer>
          <AvatarEditor
            crossOrigin="anonymous"
            ref={editor}
            image={getImageToCrop()}
            width={457}
            height={bannerDimensions[type]}
            border={0}
            rotate={0}
            borderRadius={10}
            scale={scale}
            onPositionChange={handleUpdatePreviewWhenUserChangeImagePosition}
          />
          <S.ZoomContainer>
            <S.IconContainer onClick={handleClickZoom('less')}>
              <MdRemove color="#000" size={22} />
            </S.IconContainer>
            <input
              name="scale"
              value={scale}
              type="range"
              min="1"
              max="2"
              step="0.01"
              defaultValue="1"
              onChange={handleZoom}
            />
            <S.IconContainer onClick={handleClickZoom('more')}>
              <MdAdd color="#000" size={22} />
            </S.IconContainer>
          </S.ZoomContainer>
        </S.CropContainer>

        <S.MainText>{t('visualizedApp')}</S.MainText>

        <S.DisplayContainer>
          <S.ImageLeftContainer>
            <Image
              src={previewCroppedImage || imagePreview}
              width={457}
              height={bannerDimensions[type]}
              radius="10px"
            />
            <S.Subtext>{t('topBannerResolution')}</S.Subtext>
          </S.ImageLeftContainer>
        </S.DisplayContainer>

        <S.ButtonsContainer>
          <WhiteButton
            onClick={onClose}
            text={t('cancel')}
            icon={<MdClose color="#fff" />}
            color="#808b99"
            type="button"
          />

          <WhiteButton
            onClick={handleUpdate}
            text={t('update')}
            icon={<MdDone color="#fff" />}
            color="#00A2FF"
            type="button"
          />
        </S.ButtonsContainer>
      </S.Container>
    </Modal>
  )
}

export default ContentCropImageModal
