import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import AnalyticsKpiCard from '~/components/Shared/AnalyticsKpiCard'
import TabTitleAndSubtitle from '~/components/Community/TabTitleAndSubtitle'
import { reactSelectStyleDefault } from '~/helper/const'
import AnalyticsKpiProgressBar from '~/components/Shared/AnalyticsKpiProgressBar'
import { checkNumberForPositiveAndNegative } from '~/helper/helperFunctions'
import AnalyticsPieChart from '~/components/Shared/AnalyticsPieChart'
import * as KPI from '~/services/requests'
import * as S from './styled'

export default function AnalyticsTab() {
  const { t } = useTranslation()

  const analyticsKpiDaysSelect = useMemo(
    () => [
      { value: 15, label: `15 ${t('days')}` },
      { value: 30, label: `30 ${t('days')}` },
      { value: 45, label: `45 ${t('days')}` },
    ],
    [t],
  )

  const currencySelect = useMemo(
    () => [
      { value: 1, label: `EUR` },
      { value: 2, label: `USD` },
      { value: 3, label: `BRL` },
    ],
    [],
  )

  const sponsorId = useSelector(state => state.sponsor.company.id)
  const [followUpSelect, setFollowUpSelect] = useState(analyticsKpiDaysSelect[0])
  const [popularFormatsSelect, setPopularFormatsSelect] = useState(analyticsKpiDaysSelect[0])
  const [communityRankingSelect, setCommunityRankingSelect] = useState(analyticsKpiDaysSelect[0])
  const [mostViewedFormatsSelect, setMostViewedFormatsSelect] = useState(analyticsKpiDaysSelect[0])
  const [kpiFollowUpData, setKpiFollowUpData] = useState([])
  const [kpiPopularFormatsData, setKpiPopularFormatsData] = useState([])
  const [kpiCommunityRankingData, setKpiCommunityRankingData] = useState([])
  const [kpiMostViewedFormatsData, setKpiMostViewedFormatsData] = useState([])
  const [currentCurrencySelect, setCurrentCurrencySelect] = useState(currencySelect[0])

  const getFollowUpAnalytics = useCallback(async () => {
    const response = await KPI.getFollowUpAnalyticsKpi(followUpSelect, sponsorId, 'sponsor')
    const currencyArray = response?.data.find(data => data[currentCurrencySelect.label.toLocaleLowerCase()])
    if (currentCurrencySelect.label === 'EUR') {
      setKpiFollowUpData(currencyArray.eur)
    }
    if (currentCurrencySelect.label === 'USD') {
      setKpiFollowUpData(currencyArray.usd)
    }
    if (currentCurrencySelect.label === 'BRL') {
      setKpiFollowUpData(currencyArray.brl)
    }
  }, [followUpSelect, sponsorId, currentCurrencySelect, setKpiFollowUpData])

  const getCommunityRankingAnalytics = useCallback(async () => {
    const response = await KPI.getSponsorRankingAnalyticsKpi(communityRankingSelect, sponsorId, 'sponsor')
    setKpiCommunityRankingData(response?.data)
  }, [communityRankingSelect, sponsorId])

  const getPopularFormatsAnalytics = useCallback(async () => {
    const response = await KPI.getPopularFormatsAnalyticsKpi(popularFormatsSelect, sponsorId, 'sponsor')
    setKpiPopularFormatsData(response?.data)
  }, [popularFormatsSelect, sponsorId])

  const getMostViewedAnalytics = useCallback(async () => {
    const response = await KPI.getMostViewedFormatsAnalyticsKpi(mostViewedFormatsSelect, sponsorId, 'sponsor')
    setKpiMostViewedFormatsData(response?.data)
  }, [mostViewedFormatsSelect, sponsorId])

  useEffect(() => {
    getFollowUpAnalytics()
  }, [getFollowUpAnalytics, followUpSelect])

  useEffect(() => {
    getCommunityRankingAnalytics()
  }, [getCommunityRankingAnalytics, communityRankingSelect])

  useEffect(() => {
    getPopularFormatsAnalytics()
  }, [getPopularFormatsAnalytics, popularFormatsSelect])

  useEffect(() => {
    getMostViewedAnalytics()
  }, [getMostViewedAnalytics, mostViewedFormatsSelect])

  useEffect(() => {
    setCommunityRankingSelect(analyticsKpiDaysSelect[0])
    setPopularFormatsSelect(analyticsKpiDaysSelect[0])
    setFollowUpSelect(analyticsKpiDaysSelect[0])
    setMostViewedFormatsSelect(analyticsKpiDaysSelect[0])
  }, [analyticsKpiDaysSelect])

  return (
    <S.Container>
      <TabTitleAndSubtitle sponsor title={t('companyKpis')} subtitle={t('checkTheBudgetInvested')} />
      <S.CustomRow>
        <S.KpiCol>
          <S.KpiTitleContainer>
            <S.KpiTitle>{t('followUp')}</S.KpiTitle>
            <S.FollowUpSelectContainer>
              <S.SelectContainer spacing>
                <Select
                  className="currency-select"
                  styles={reactSelectStyleDefault}
                  options={currencySelect}
                  value={currentCurrencySelect}
                  onChange={val => {
                    setCurrentCurrencySelect(val)
                  }}
                />
              </S.SelectContainer>

              <S.SelectContainer>
                <Select
                  className="kpi-follow-up-select"
                  styles={reactSelectStyleDefault}
                  options={analyticsKpiDaysSelect}
                  value={followUpSelect}
                  onChange={val => {
                    setFollowUpSelect(val)
                  }}
                />
              </S.SelectContainer>
            </S.FollowUpSelectContainer>
          </S.KpiTitleContainer>

          <S.AnalyticsKpiCardsContainer>
            {kpiFollowUpData?.map(kpiData => (
              <React.Fragment key={kpiData.product_type}>
                <AnalyticsKpiCard
                  percentage={kpiData.percentage}
                  previousValue={kpiData.previous_sub_total}
                  value={kpiData.current_sub_total}
                  title={kpiData.product_type}
                  status={checkNumberForPositiveAndNegative(kpiData.percentage)}
                  days={followUpSelect.label}
                  communityCurrency={currentCurrencySelect.label.toLocaleLowerCase()}
                  sponsor
                />
              </React.Fragment>
            ))}
          </S.AnalyticsKpiCardsContainer>
        </S.KpiCol>

        <S.KpiCol>
          <S.KpiTitleContainer>
            <S.KpiTitle>{t('communityRanking')}</S.KpiTitle>
            <S.SelectContainer>
              <Select
                className="kpi-ranking-select"
                styles={reactSelectStyleDefault}
                options={analyticsKpiDaysSelect}
                value={communityRankingSelect}
                onChange={val => {
                  setCommunityRankingSelect(val)
                }}
              />
            </S.SelectContainer>
          </S.KpiTitleContainer>

          {kpiCommunityRankingData.length === 0 ? (
            <S.ZeroRegisterContainer>
              <S.ZeroRegisterText>{t('noInformationAvailable')}</S.ZeroRegisterText>
            </S.ZeroRegisterContainer>
          ) : (
            <S.KpiContainer>
              {kpiCommunityRankingData?.map(sponsorRaking => (
                <AnalyticsKpiProgressBar
                  key={sponsorRaking.id}
                  percentage={sponsorRaking.percentage}
                  total={sponsorRaking.sub_total}
                  title={sponsorRaking.full_name}
                  communityCurrency={sponsorRaking.currency}
                  budget
                  sponsor
                />
              ))}
            </S.KpiContainer>
          )}
        </S.KpiCol>
      </S.CustomRow>

      <S.CustomRow spacing>
        <S.KpiCol>
          <S.KpiTitleContainer>
            <S.KpiTitle>{t('mostPurchasedFormats')}</S.KpiTitle>
            <S.SelectContainer>
              <Select
                className="kpi-formats-select"
                styles={reactSelectStyleDefault}
                options={analyticsKpiDaysSelect}
                value={popularFormatsSelect}
                onChange={val => {
                  setPopularFormatsSelect(val)
                }}
              />
            </S.SelectContainer>
          </S.KpiTitleContainer>

          {kpiPopularFormatsData.length === 0 ? (
            <S.ZeroRegisterContainer>
              <S.ZeroRegisterText>{t('noFormatsAvailable')}</S.ZeroRegisterText>
            </S.ZeroRegisterContainer>
          ) : (
            <S.KpiContainer>
              {kpiPopularFormatsData?.map(popularFormat => (
                <AnalyticsKpiProgressBar
                  key={popularFormat.product_type}
                  percentage={popularFormat.percentage}
                  total={popularFormat.sub_total}
                  title={t(popularFormat.product_type)}
                  sponsor
                />
              ))}
            </S.KpiContainer>
          )}
        </S.KpiCol>

        <S.ChartCol>
          <S.KpiTitleContainer>
            <S.KpiTitle>{t('mostPopularFormats')}</S.KpiTitle>
            <S.SelectContainer>
              <Select
                className="kpi-most-viewed-select"
                styles={reactSelectStyleDefault}
                options={analyticsKpiDaysSelect}
                value={mostViewedFormatsSelect}
                onChange={val => {
                  setMostViewedFormatsSelect(val)
                }}
              />
            </S.SelectContainer>
          </S.KpiTitleContainer>
          <S.ChartContainer>
            <AnalyticsPieChart chartData={kpiMostViewedFormatsData} mostViewedFormatsSelect={mostViewedFormatsSelect} />
          </S.ChartContainer>
        </S.ChartCol>
      </S.CustomRow>
    </S.Container>
  )
}
