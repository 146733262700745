import styled from 'styled-components'
import { darken } from 'polished'
import { customMedia } from '~/styles/global'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  transition: 100ms;

  &:hover {
    background-color: ${darken(0.03, '#FFFFFF')};
    transform: scale(1.01);
  }

  &:active {
    position: relative;
    top: 2px;
  }

  ${customMedia.lessThan('1260px')`
    padding: 5px 10px
  `}
`

export const UserChatContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
`

export const LogoContainer = styled.div`
  min-height: 40px;
  min-width: 40px;
  width: 40px;
  height: 40px;
  position: relative;
`

export const UnreadMessages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  background-color: red;
  top: -5px;
  color: #fff;
`

export const UnreadMessageText = styled.span`
  font-size: 14px;
`

export const ChatChannelName = styled.p`
  margin: 0px;
  padding-bottom: 2px;
  color: var(--input-title);
  font-family: Roboto;
  font-size: 14px;
  line-height: 15px;
  width: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: nowrap;
  padding-right: 12px;
  font-weight: 600;

  ${customMedia.lessThan('1100px')`
    font-size: 14px;
    width: 23vw;
  `}

  ${customMedia.lessThan('740px')`
    font-size: 13px;
    width: 20vw;
  `}

  ${customMedia.lessThan('small')`
    font-size: 13px;
    width: 30vw;
  `}
`

export const LastMessage = styled.p`
  margin-bottom: 0px;
  display: block;
  color: var(--grey);
  padding-right: 10px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  width: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: nowrap;
  padding-right: 12px;
  font-weight: 600;

  ${customMedia.lessThan('1100px')`
    font-size: 14px;
    width: 23vw;
  `}

  ${customMedia.lessThan('740px')`
    font-size: 13px;
    width: 20vw;
  `}

  ${customMedia.lessThan('small')`
    font-size: 13px;
    width: 30vw;
  `}
`

export const ChannelNameAndLastMessageSection = styled.div`
  margin-top: 5px;
  margin-left: 10px;
`

export const NumberOfUnreadMessages = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: #fff;
`

export const ChatPicture = styled.img`
  border-radius: 100px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
