import styled from 'styled-components'
import { darken } from 'polished'
import { MdClose } from 'react-icons/md'
import { customMedia } from '~/styles/global'

export const ComponentContainer = styled.div`
  z-index: 10;
  display: flex;
  gap: 20px;
  position: absolute;
  bottom: 85px;
`

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TooltipContainer = styled.div`
  position: relative;
  width: 688px;
  height: 203px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 20px 25px;
  margin-top: 20px;
`

export const CloseButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  top: -8px;
  right: 0px;

  &:hover {
    background-color: ${darken(0.05, '#FFF')};
    text-decoration: none;
  }
`

export const Title = styled.p`
  margin: 0px;
  font-size: 18px;
  font-weight: 700;
  color: var(--sponsor-primary);
  letter-spacing: 0.00938em;

  ${customMedia.lessThan('1715px')`
    font-size: 15px;
  `}
`

export const Subtitle = styled.p`
  margin: 0px;
  margin-top: 3px;
  font-size: 15px;
  color: var(--text-primary);
  white-space: break-spaces;

  ${customMedia.lessThan('1715px')`
    font-size: 14px;
  `}
`

export const TooltipUserAvatar = styled.img.attrs({
  src: '/tutorial-tooltip-avatar.png',
})`
  width: 130px;
  height: 130px;
  margin-top: 20px;
`

export const TooltipArrow = styled.div`
  width: 46px;
  height: 42px;
  background: #fff;
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  margin-left: 28px;
  z-index: 11;
  margin-top: -3px;
`

export const CloseIcon = styled(MdClose).attrs({
  size: 20,
  color: '#47525E',
})``
