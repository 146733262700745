import React from 'react'
import { format } from 'date-fns'
import * as S from './styled'

export default function CalendarBadge({ action, color, type, height, width, startDate, backgroundColor, style }) {
  const startDateDay = format(new Date(startDate), 'dd')
  const startDateMonth = format(new Date(startDate), 'MMM')

  return (
    <S.CalendarBadgeContainer
      style={style}
      color={color}
      backgroundColor={backgroundColor}
      height={height}
      width={width}
      type={type}
      onClick={action}
    >
      <S.Content>
        <S.StartDateMonth>{startDateMonth}</S.StartDateMonth>
        <S.StartDateDay>{startDateDay}</S.StartDateDay>
      </S.Content>
    </S.CalendarBadgeContainer>
  )
}
