/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import Spinner from 'react-bootstrap/Spinner'
import InputAdornment from '@material-ui/core/InputAdornment'
import * as S from './styled'

export default function InputPlacesAutocomplete({
  adrFormatted,
  setAdrFormatted,
  register,
  setValue,
  errors,
  entityForm,
}) {
  const { t } = useTranslation()

  // TODO: Remove react-places-autocomplete and refactor the whole input
  function getAddressAndSaveToState(address) {
    address.forEach(adr => {
      if (adr.types.includes('street_number')) {
        setValue('adrStreetNumber', adr.long_name)
      }
      if (adr.types.includes('route')) {
        setValue('adrStreet', adr.long_name)
      }
      if (adr.types.includes('administrative_area_level_1')) {
        setValue('adrState', adr.long_name)
      }
      if (adr.types.includes('administrative_area_level_2')) {
        setValue('adrCity', adr.long_name)
      }
      if (adr.types.includes('postal_code')) {
        setValue('adrZipcode', adr.long_name)
      }
      if (adr.types.includes('country')) {
        setValue('adrCountry', adr.long_name)
      }
    })
    if (address.filter(adr => !adr.types.includes('administrative_area_level_1')).length === address.length) {
      setValue('adrState', undefined)
    }
    if (address.filter(adr => !adr.types.includes('administrative_area_level_2')).length === address.length) {
      setValue('adrCity', undefined)
    }
  }

  const handleSelectLocationInput = async value => {
    const results = await geocodeByAddress(value)
    getAddressAndSaveToState(results[0].address_components)
    setAdrFormatted(value)
    if (entityForm) setValue('adrFormatted', value)
    const coordinates = await getLatLng(results[0])
    setValue('adrLat', coordinates.lat.toString())
    setValue('adrLng', coordinates.lng.toString())
  }

  return (
    <>
      {entityForm === true ? (
        <PlacesAutocomplete value={adrFormatted} onChange={setAdrFormatted} onSelect={handleSelectLocationInput}>
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div>
              <input
                {...getInputProps({
                  className: 'location-search-input',
                  placeholder: t('countryCityNeighborhood'),
                  id: 'sponsor-address',
                  autoFocus: false,
                  autoComplete: 'off',
                })}
                style={{
                  boxSizing: 'border-box',
                }}
              />
              <S.ErrorMessage error={errors.adrFormatted}>
                <p>{errors.adrFormatted && t('defaultValidation', { inputName: t('address') })}</p>
              </S.ErrorMessage>

              <div
                className="autocomplete-dropdown-container"
                style={{
                  position: 'absolute',
                  marginTop: '6px',
                  border: '1px solid #cccccc',
                  borderRadius: '4px',
                  zIndex: 1,
                }}
              >
                {suggestions.map(suggestion => {
                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'

                  const style = suggestion.active
                    ? {
                        backgroundColor: '#f2f2f2',
                        cursor: 'pointer',
                        padding: '5px 10px',
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        padding: '5px 10px',
                      }
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={Math.random()}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      ) : (
        <PlacesAutocomplete value={adrFormatted} onChange={setAdrFormatted} onSelect={handleSelectLocationInput}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <S.Container>
              <TextField
                fullWidth
                variant="outlined"
                name="adrFormatted"
                label={t('address')}
                type="text"
                value={adrFormatted}
                inputRef={register({ required: true })}
                onChange={e => setAdrFormatted(e.target.value)}
                inputProps={{ autoComplete: 'new-password' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <S.GoogleLogo />
                    </InputAdornment>
                  ),
                }}
                {...getInputProps({
                  className: 'location-search-input',
                  placeholder: '',
                  autoFocus: false,
                })}
                style={{
                  boxSizing: 'border-box',
                  paddingRight: '45px !important',
                }}
              />
              <S.ErrorMessage error={errors.adrFormatted}>
                <p>{errors.adrFormatted && t('defaultValidation', { inputName: t('address') })}</p>
              </S.ErrorMessage>

              <div
                className="autocomplete-dropdown-container"
                style={{
                  position: 'absolute',
                  marginTop: '6px',
                  borderRadius: '4px',
                  border: '1px solid #cccccc',
                  zIndex: 1,
                }}
              >
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <Spinner animation="border" size="sm" />
                  </div>
                ) : null}

                {suggestions.map(suggestion => {
                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                  const style = suggestion.active
                    ? {
                        backgroundColor: '#f2f2f2',
                        cursor: 'pointer',
                        padding: '5px 10px',
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        padding: '5px 10px',
                      }

                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={Math.random()}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
              </div>
            </S.Container>
          )}
        </PlacesAutocomplete>
      )}
    </>
  )
}
