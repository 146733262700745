import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { MdDone } from 'react-icons/md'
import { RiSave2Line } from 'react-icons/ri'
import WhiteButton from '~/components/WhiteButton'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    max-width: 1000px;
    margin: auto;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;

    .modal-title {
      color: var(--text-bold);
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      line-height: 27px;
    }

    .modal-body {
      padding-bottom: 30px !important;
    }
  }
`

export const RequestChangeButton = styled(WhiteButton).attrs({
  type: 'submit',
})``

export const ApproveButton = styled(WhiteButton).attrs({
  style: { padding: '0px 20px' },
})``

export const DoneButton = styled(WhiteButton).attrs({})``

export const DoneIcon = styled(MdDone).attrs({
  color: 'var(--success)',
  size: 23,
})``

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const RequestChangeButtonContainer = styled.div`
  margin-right: 20px;
`

export const SaveIcon = styled(RiSave2Line).attrs({
  color: 'var(--success)',
  size: 23,
})``

export const SaveButton = styled(WhiteButton).attrs({
  type: 'submit',
  form: 'my-form',
  style: { marginTop: '10px' },
})``
