import produce from 'immer'
import { SET_USERS } from '~/store/modules/firebaseUsers/actionTypes'

const INITIAL_STATE = {
  users: null,
}

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_USERS: {
        draft.users = action.payload.users
        break
      }
      default:
    }
  })
}
