import styled from 'styled-components'

export const IconsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.7;
  margin-right: 20px;
  position: absolute;
  right: ${props => (props.right ? props.right : '0')};
  top: ${props => (props.top ? props.top : '30%')};

  svg {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
`

export const IconChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
`

export const IconFowardContainer = styled.div`
  margin-top: 8px;
`

export const IconThumbsUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IconText = styled.span`
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
`
