import styled from 'styled-components'

export const ListContainer = styled.div.attrs({
  className: 'animated fadeIn',
})`
  position: absolute;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px -4px 4px 0px rgba(119, 119, 119, 0.2);
  padding: 8px 8px 0;
  width: 385px;
  bottom: ${props => (props.messageHasAnyMedia || props.isThereAnyReply ? '70px' : '50px')};
  right: 1px;
  z-index: 100;
`

export const UsersContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 8px;
  max-height: 140px;
`
