import produce from 'immer'
import {
  SET_TUTORIAL_AS_ACTIVE,
  SET_TUTORIAL_AS_INACTIVE,
  SET_SPONSOR_TUTORIAL_FIRST_LOGIN,
  SET_COMMUNITY_TUTORIAL_FIRST_LOGIN,
  INCREMENT_BY_ONE_THE_SPONSOR_LOGIN,
  INCREMENT_BY_ONE_THE_COMMUNITY_LOGIN,
  NEVER_SHOW_THE_TUTORIAL_AGAIN_FOR_THE_SPONSOR,
  NEVER_SHOW_THE_TUTORIAL_AGAIN_FOR_THE_COMMUNITY,
} from './actionTypes'

const INITIAL_STATE = {
  tutorialIsActive: false,
  sponsorFirstLogin: true,
  communityFirstLogin: true,
  howManyTimesTheSponsorLoggedInTheApplication: 0,
  howManyTimesTheCommunityLoggedInTheApplication: 0,
}

export default function tutorial(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_TUTORIAL_AS_ACTIVE: {
        draft.tutorialIsActive = true
        break
      }
      case SET_TUTORIAL_AS_INACTIVE: {
        draft.tutorialIsActive = false
        break
      }
      case SET_SPONSOR_TUTORIAL_FIRST_LOGIN: {
        draft.sponsorFirstLogin = false
        break
      }
      case SET_COMMUNITY_TUTORIAL_FIRST_LOGIN: {
        draft.communityFirstLogin = false
        break
      }
      case INCREMENT_BY_ONE_THE_SPONSOR_LOGIN: {
        draft.howManyTimesTheSponsorLoggedInTheApplication += 1
        break
      }
      case INCREMENT_BY_ONE_THE_COMMUNITY_LOGIN: {
        draft.howManyTimesTheCommunityLoggedInTheApplication += 1
        break
      }
      case NEVER_SHOW_THE_TUTORIAL_AGAIN_FOR_THE_SPONSOR: {
        draft.sponsorFirstLogin = false
        draft.howManyTimesTheSponsorLoggedInTheApplication = 6
        break
      }
      case NEVER_SHOW_THE_TUTORIAL_AGAIN_FOR_THE_COMMUNITY: {
        draft.communityFirstLogin = false
        draft.howManyTimesTheCommunityLoggedInTheApplication = 6
        break
      }
      default:
    }
  })
}
