/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'
import NumberFormat from 'react-number-format'
import { InputAdornment } from '@material-ui/core'
import { Modal, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { reactSelectStyleDefault, modalBodyStyleDefault, currencyOptions } from '~/helper/const'
import InputPlacesAutocomplete from '~/components/InputPlacesAutocomplete'
import { prepareToSendEditCommunity, mountCurrencySelect } from '~/helper/formFunctions'
import useCommunityFunctions from '~/helper/communityFunctions'
import useCommunitySelectors from '~/helper/communitySelectors'
import { DefaultLogoImage, DefaultBannerImage } from '~/util/defaultImages'
import ErrorMessage from '~/components/Shared/ErrorMessage'
import ModalPreviewProfileVisualization from '~/components/Community/Modals/ModalPreviewProfileVisualization'
import * as S from './styled'

export default function ModalEditCommunity({
  handleCloseModal,
  showModal,
  setModalStepToOne,
  currentCommunity,
  showModalPreviewVisualization,
  setShowModalPreviewVisualization,
  displayModalEditCommunity,
}) {
  const { t } = useTranslation()
  const { communityS3Logo, communityS3LogoId } = useCommunitySelectors()
  const {
    updateCommunityProfileRequestAction,
    removeS3FromStateAction,
    deleteCommunityGalleryMediaRequestAction,
  } = useCommunityFunctions()
  const [adrStreet] = useState(currentCommunity.adrStreet)
  const [adrState] = useState(currentCommunity.adrState)
  const [adrFormatted, setAdrFormatted] = useState(currentCommunity.adrFormatted)
  const [adrCity] = useState(currentCommunity.adrCity)
  const [adrZipcode] = useState(currentCommunity.adrZipcode)
  const [adrCountry] = useState(currentCommunity.adrCountry)
  const [adrStreetNumber] = useState(currentCommunity.adrStreetNumber)
  const [adrLng] = useState(currentCommunity.adrLng)
  const [adrLat] = useState(currentCommunity.adrLat)
  const { handleSubmit, register, setValue, errors, control, watch } = useForm()
  const descriptionWatch = watch('description', '')
  const descriptionLength = 3000 - descriptionWatch.length
  const loading = useSelector(state => state.community.loading)

  const {
    id: communityId,
    networkLogoPath: communityLogo,
    name: communityName,
    description: communityDescription,
    facebook: communityFacebookLink,
    youtube: communityYoutubeLink,
    twitter: communityTwitterLink,
    website: communityWebsiteLink,
    averageAgeKpi: { value: averageAgeKpi },
    membersKpi: { value: membersKpi },
    menKpi: { value: menKpi },
    womenKpi: { value: womenKpi },
    finPersonName: financialResponsibleName,
    finPersonEmail: financialResponsibleEmail,
    fiscalCode,
    logoId,
    currency,
  } = currentCommunity

  const handleCloseModalAndRemoveLogoAndBannerFromState = () => {
    removeS3FromStateAction()
    handleCloseModal()
  }

  function onSubmit(data) {
    data.menKpi = parseInt(data.menKpi, 10)
    data.womenKpi = parseInt(data.womenKpi, 10)

    updateCommunityProfileRequestAction(communityId, data)
  }

  useEffect(() => {
    register({ name: 'adrLat' })
    register({ name: 'adrLng' })
    register({ name: 'adrStreetNumber' })
    register({ name: 'adrStreet' })
    register({ name: 'adrState' })
    register({ name: 'adrCity' })
    register({ name: 'adrZipcode' })
    register({ name: 'adrCountry' })
    register({ name: 'adrFormatted' }, { required: true })
    register({ name: 'logoId' })
    register({ name: 'bannerId' })
    register({ name: 'communityLogo' })
    setValue('communityLogo', communityS3Logo())
    setValue('adrStreet', adrStreet)
    setValue('adrFormatted', adrFormatted)
    setValue('adrStreetNumber', adrStreetNumber)
    setValue('adrState', adrState)
    setValue('adrCity', adrCity)
    setValue('adrZipcode', adrZipcode)
    setValue('adrCountry', adrCountry)
    setValue('adrLat', adrLat)
    setValue('adrLng', adrLng)
    setValue('bannerId', null)

    if (communityS3LogoId() !== null) {
      setValue('logoId', communityS3LogoId())
    } else {
      setValue('logoId', logoId)
    }
  }, [communityS3LogoId()])

  const [deleteData, setDeleteData] = useState(null)

  return (
    <>
      <Dialog open={!!deleteData} onClose={() => setDeleteData(null)}>
        <DialogTitle>
          <span>{t('attention')}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t('confirmMediaDeletion')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteData(null)} color="primary">
            {t('cancel')}
          </Button>
          <Button
            onClick={() => {
              const { index, type, communityId, communityMediaId } = deleteData
              deleteCommunityGalleryMediaRequestAction(index, type, communityId, communityMediaId)
              setDeleteData(null)
            }}
            color="primary"
          >
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <S.ModalCustom
        displaymodal={displayModalEditCommunity.toString()}
        show={showModal}
        onHide={handleCloseModalAndRemoveLogoAndBannerFromState}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <S.Title>{t('editCommunity')}</S.Title>
          </Modal.Title>
        </Modal.Header>
        <S.ContainerBannerImage>
          <S.BannerImage src={DefaultBannerImage} />
          <S.ProfilePreviewContainer>
            <S.ProfilePreviewButton onClick={() => setShowModalPreviewVisualization(true)}>
              <S.PreviewIcon />
              {t('profilePreview')}
            </S.ProfilePreviewButton>
          </S.ProfilePreviewContainer>
          <S.ContainerCommunityProfilePicture>
            {(communityLogo === null && communityS3LogoId() === null) ||
            (communityLogo === undefined && communityS3LogoId() === null) ? (
              <S.CommunityProfilePicture src={DefaultLogoImage} />
            ) : communityS3LogoId() === null ? (
              <S.CommunityProfilePicture src={communityLogo} />
            ) : (
              <S.CommunityProfilePicture src={communityS3Logo()} />
            )}
            <S.EditLogoButton onClick={setModalStepToOne} />
          </S.ContainerCommunityProfilePicture>
        </S.ContainerBannerImage>

        <Modal.Body style={modalBodyStyleDefault}>
          <S.FormContainer>
            <S.Form onSubmit={handleSubmit(prepareToSendEditCommunity(onSubmit))}>
              <Row>
                <Col md={12}>
                  <S.InputTitleContainer>
                    <S.InputLabel>{t('communityName')}</S.InputLabel>
                    <S.IsRequired>*</S.IsRequired>
                  </S.InputTitleContainer>
                  <Controller
                    as={<TextField fullWidth variant="outlined" placeholder={t('communityName')} />}
                    rules={{ required: t('defaultValidation', { inputName: t('name') }) }}
                    name="name"
                    control={control}
                    defaultValue={communityName === null ? '' : communityName}
                  />
                  <ErrorMessage errors={errors} name="name" />
                </Col>
              </Row>
              <S.CustomRow>
                <Col md={12}>
                  <S.InputLabel>{t('description')}</S.InputLabel>
                  <Controller
                    as={
                      <TextField
                        placeholder={t('description')}
                        fullWidth
                        variant="outlined"
                        multiline
                        rows="2"
                        inputProps={{
                          maxLength: 3000,
                        }}
                      />
                    }
                    name="description"
                    control={control}
                    defaultValue={communityDescription === null ? '' : communityDescription}
                  />
                </Col>
                {descriptionWatch !== '' && (
                  <S.DescriptionCharactersLeft>
                    {`${descriptionLength} ${t('charactersLeft')}`}
                  </S.DescriptionCharactersLeft>
                )}
              </S.CustomRow>
              <S.CustomRow>
                <Col md={12}>
                  <S.InputTitleContainer>
                    <S.InputLabel>{t('address')}</S.InputLabel>
                    <S.IsRequired>*</S.IsRequired>
                  </S.InputTitleContainer>
                  <S.HeadquarterAddressSection>
                    <InputPlacesAutocomplete
                      adrFormatted={adrFormatted}
                      setAdrFormatted={setAdrFormatted}
                      register={register}
                      setValue={setValue}
                      errors={errors}
                      entityForm
                    />
                  </S.HeadquarterAddressSection>
                </Col>
              </S.CustomRow>

              {showModalPreviewVisualization && (
                <ModalPreviewProfileVisualization
                  handleCloseModal={() => setShowModalPreviewVisualization(false)}
                  showModal={showModalPreviewVisualization}
                  community={currentCommunity}
                />
              )}
              <S.CustomRow>
                <Col md={12}>
                  <S.InputTitleContainer>
                    <S.InputLabel>{t('financialResponsibleName')}</S.InputLabel>
                    <S.IsRequired>*</S.IsRequired>
                  </S.InputTitleContainer>
                  <Controller
                    as={<TextField fullWidth variant="outlined" placeholder={t('financialResponsibleName')} />}
                    rules={{ required: t('defaultValidation', { inputName: t('financialResponsibleName') }) }}
                    name="finPersonName"
                    control={control}
                    defaultValue={financialResponsibleName}
                  />
                  <ErrorMessage errors={errors} name="finPersonName" />
                </Col>
              </S.CustomRow>

              <S.CustomRow>
                <Col md={6}>
                  <S.InputTitleContainer>
                    <S.InputLabel>{t('financialResponsibleEmail')}</S.InputLabel>
                    <S.HelperText>{t('forInvoicePurposes')}</S.HelperText>
                    <S.IsRequired>*</S.IsRequired>
                  </S.InputTitleContainer>
                  <Controller
                    as={<TextField fullWidth variant="outlined" placeholder={t('financialResponsibleEmail')} />}
                    rules={{ required: t('defaultValidation', { inputName: t('financialResponsibleEmail') }) }}
                    name="finPersonEmail"
                    control={control}
                    type="email"
                    defaultValue={financialResponsibleEmail}
                  />
                  <ErrorMessage errors={errors} name="finPersonEmail" />
                </Col>
                <Col md={6}>
                  <S.InputTitleContainer>
                    <S.InputLabel>{t('communityFiscalCode')}</S.InputLabel>
                    <S.HelperText>{t('forInvoicePurposes')}</S.HelperText>
                    <S.IsRequired>*</S.IsRequired>
                  </S.InputTitleContainer>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={t('communityFiscalCode')}
                        inputProps={{
                          maxLength: 30,
                        }}
                      />
                    }
                    rules={{ required: t('defaultValidation', { inputName: t('communityFiscalCode') }) }}
                    name="fiscalCode"
                    control={control}
                    defaultValue={fiscalCode}
                  />
                  <ErrorMessage errors={errors} name="fiscalCode" />
                </Col>
              </S.CustomRow>

              <S.CustomRow>
                <Col
                  md={6}
                  style={{
                    flex: '0 0 100%',
                    maxWidth: '100%',
                  }}
                >
                  <S.InputLabel>{t('currency')}</S.InputLabel>
                  <Controller
                    as={
                      <Select
                        styles={(reactSelectStyleDefault, { width: '100%' })}
                        placeholder={t('placeholderSelect')}
                        options={currencyOptions}
                      />
                    }
                    defaultValue={mountCurrencySelect(currency, currencyOptions)}
                    rules={{ required: t('defaultValidation', { inputName: t('currency') }) }}
                    name="currency"
                    control={control}
                    onChange={([selected]) => {
                      return selected
                    }}
                  />
                  <S.ErrorMessage error={errors.currency}>
                    <p>{errors.currency && t('defaultValidation', { inputName: t('currency') })}</p>
                  </S.ErrorMessage>
                </Col>
              </S.CustomRow>
              <S.CustomRow>
                <Col md={6}>
                  <S.InputLabel>{t('membersAverageAge')}</S.InputLabel>
                  <Controller
                    as={
                      <TextField
                        type="number"
                        placeholder={t('averageAgeOfMembers')}
                        onInput={e => {
                          e.target.value = e.target.value.toString().slice(0, 8)
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    }
                    name="averageAgeKpi.value"
                    control={control}
                    defaultValue={averageAgeKpi === null ? '' : averageAgeKpi}
                  />
                </Col>
                <Col md={6}>
                  <S.InputLabel>{t('members')}</S.InputLabel>
                  <Controller
                    as={
                      <TextField
                        type="number"
                        placeholder={t('howManyMembersDoYourCommunityHave')}
                        onInput={e => {
                          e.target.value = e.target.value.toString().slice(0, 8)
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    }
                    name="membersKpi.value"
                    control={control}
                    defaultValue={membersKpi === null ? '' : membersKpi}
                  />
                </Col>
              </S.CustomRow>
              <S.CustomRow>
                <Col md={6}>
                  <S.InputLabel>{t('womenPercentage')}</S.InputLabel>
                  <Controller
                    id="women-percentage"
                    as={
                      <NumberFormat
                        customInput={TextField}
                        thousandSeparator
                        suffix="%"
                        allowNegative={false}
                        max={100}
                        min={0}
                        onValueChange={v => {
                          setValue('womenKpi', v.value)
                          setValue('menKpi', `${100 - parseInt(v.value, 10)}%`)
                        }}
                      />
                    }
                    onInput={e => {
                      e.target.value = e.target.value.toString().slice(0, 3)
                    }}
                    fullWidth
                    allowEmptyFormatting
                    variant="outlined"
                    placeholder={t('womenPercentage')}
                    name="womenKpi"
                    defaultValue={womenKpi === null ? '' : womenKpi}
                    control={control}
                  />
                </Col>
                <Col md={6}>
                  <S.InputLabel>{t('menPercentage')}</S.InputLabel>
                  <Controller
                    id="men-percentage"
                    as={
                      <NumberFormat
                        customInput={TextField}
                        thousandSeparator
                        suffix="%"
                        allowNegative={false}
                        max={100}
                        min={0}
                        onValueChange={v => {
                          setValue('menKpi', Number(v.value))
                          setValue('womenKpi', `${100 - parseInt(v.value, 10)}%`)
                        }}
                      />
                    }
                    onInput={e => {
                      e.target.value = e.target.value.toString().slice(0, 3)
                    }}
                    fullWidth
                    allowEmptyFormatting
                    variant="outlined"
                    placeholder={t('menPercentage')}
                    name="menKpi"
                    defaultValue={menKpi === null ? '' : menKpi}
                    control={control}
                  />
                </Col>
              </S.CustomRow>
              <S.CustomRow>
                <Col md={6}>
                  <S.InputLabel>{t('facebookPage')}</S.InputLabel>
                  <Controller
                    as={
                      <TextField
                        placeholder={t('facebookPage')}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <S.FacebookIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                    name="facebook"
                    control={control}
                    defaultValue={communityFacebookLink === null ? '' : communityFacebookLink}
                  />
                </Col>
                <Col md={6}>
                  <S.InputLabel>{t('youtubeChannel')}</S.InputLabel>
                  <Controller
                    as={
                      <TextField
                        placeholder={t('youtubeChannel')}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <S.YoutubeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                    name="youtube"
                    control={control}
                    defaultValue={communityYoutubeLink === null ? '' : communityYoutubeLink}
                  />
                </Col>
              </S.CustomRow>
              <S.CustomRow>
                <Col md={6}>
                  <S.InputLabel>{t('twitterProfile')}</S.InputLabel>
                  <Controller
                    as={
                      <TextField
                        placeholder={t('twitterProfile')}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <S.TwitterIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                    name="twitter"
                    control={control}
                    defaultValue={communityTwitterLink === null ? '' : communityTwitterLink}
                  />
                </Col>
                <Col md={6}>
                  <S.InputLabel>{t('website')}</S.InputLabel>
                  <Controller
                    as={
                      <TextField
                        placeholder={t('website')}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <S.WebsiteIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                    name="website"
                    control={control}
                    defaultValue={communityWebsiteLink === null ? '' : communityWebsiteLink}
                  />
                </Col>
              </S.CustomRow>
            </S.Form>
          </S.FormContainer>
        </Modal.Body>
        <S.ModalFooter>
          <S.CancelButton text={t('cancel')} onClick={handleCloseModal} />
          <S.SaveButton
            data-cy="save-community-profile"
            text={t('save')}
            large
            icon={<S.SaveIcon />}
            loading={loading}
          />
        </S.ModalFooter>
      </S.ModalCustom>
    </>
  )
}
