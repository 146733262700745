import { addMonths, subYears, subDays, addYears } from 'date-fns'

export const modifiersClassNames = {
  today: 'my-today',
  isComponentDisabled: 'component-disabled',
  disabled: 'disabled-day',
  disabledByOutOfRangeDates: 'disabled-out-of-range-dates',
  disabledByBlockedDates: 'disabled-blocked-dates',
  disabledByRangeAvailability: 'disabled-range-availability',
  firstSelectedDay: 'first-selected-day',
  lastSelectedDay: 'last-selected-day',
}

export const defaultInitialMonthNavigation = new Date()
export const defaultFinalMonthNavigation = addMonths(new Date(), 12)

export const disabledByOutOfRangeDates = [
  {
    from: subYears(new Date(), 1),
    to: subDays(new Date(), 1),
  },

  {
    from: defaultFinalMonthNavigation,
    to: addYears(defaultFinalMonthNavigation, 1),
  },
]
