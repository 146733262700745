import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '~/i18n'
import * as S from './styled'

export default function CommunityKpiCard({ title, value, icon }) {
  const { t } = useTranslation()

  return (
    <S.CardContainer>
      <S.IconContainer>{icon}</S.IconContainer>
      {value !== null && (
        <S.CardNumber>{title === i18n.t('men') || title === i18n.t('women') ? `${value}%` : value}</S.CardNumber>
      )}
      {value == null && <S.NotAvailable>{t('notAvailable')}</S.NotAvailable>}
      <S.CardTitle>{title}</S.CardTitle>
    </S.CardContainer>
  )
}
