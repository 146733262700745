/* eslint-disable import/no-named-default */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { default as ReactSelect } from 'react-select'
import i18n from '~/i18n'

const MultiSelect = props => {
  const { t } = useTranslation()

  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        placeholder={t('placeholderSelect')}
        options={props.options.length > 0 ? [props.allOption, ...props.options] : [...props.options]}
        onChange={selected => {
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === props.allOption.value
          ) {
            return props.onChange(props.options)
          }
          return props.onChange(selected)
        }}
      />
    )
  }
  return <ReactSelect {...props} />
}

MultiSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
}

MultiSelect.defaultProps = {
  allOption: {
    label: i18n.t('selectAll'),
    value: '*',
  },
}

export default MultiSelect
