import produce from 'immer'
import { SIGN_IN_SPONSOR_SUCCESS, SIGN_OUT } from '~/store/modules/auth/actionTypes'
import {
  GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST,
  GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_SUCCESS,
  GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_ERROR,
  OPEN_CREATE_CAMPAIGN_FOR_TUTORIAL,
  CLOSE_CREATE_CAMPAIGN_FOR_TUTORIAL,
  UPDATE_SPONSOR_INFORMATIONS,
  DELETE_COMMUNITY_INFORMATIONS,
  UPLOAD_S3_SUCCESS,
  REMOVE_S3_FROM_STATE,
  SET_FIRST_LOGIN,
  SET_ALL_COMMUNITIES_FROM_SPONSOR,
  SET_SHOW_CAMPAIGN_REVIEW_PAGE_MOCKED_FOR_THE_TUTORIAL,
  UPDATE_SPONSOR_INFORMATIONS_LOADING,
  UPDATE_SPONSOR_INFORMATIONS_FAILURE,
  UPDATE_SPONSOR_INFORMATIONS_SUCCESS,
} from './actionTypes'

const INITIAL_STATE = {
  sponsorManager: null,
  company: null,
  communityInformations: null,
  allCommunitiesFromSponsor: null,
  loading: false,
  hideInvitationLink: false,
  firstLogin: true,
  openCreateCampaignForTutorial: false,
  showCampaignReviewPageMockedForTheTutorial: false,
  s3SponsorLogo: {
    id: null,
    url: null,
    loading: false,
  },
  s3SponsorBanner: {
    id: null,
    url: null,
    loading: false,
  },
}

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_FIRST_LOGIN: {
        draft.firstLogin = false
        break
      }

      case SIGN_IN_SPONSOR_SUCCESS: {
        draft.sponsorManager = action.payload.user
        draft.company = action.payload.company
        break
      }

      case GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST: {
        draft.loading = true
        break
      }

      case GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_SUCCESS: {
        draft.communityInformations = action.payload.communityInformations
        draft.hideInvitationLink = true
        draft.loading = false
        break
      }

      case GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_ERROR: {
        draft.loading = false
        break
      }

      case SET_ALL_COMMUNITIES_FROM_SPONSOR: {
        draft.allCommunitiesFromSponsor = action.payload.communities
        break
      }

      case UPDATE_SPONSOR_INFORMATIONS: {
        draft.company = action.payload
        break
      }

      case UPDATE_SPONSOR_INFORMATIONS_LOADING: {
        draft.loading = true
        break
      }

      case UPDATE_SPONSOR_INFORMATIONS_FAILURE: {
        draft.loading = false
        break
      }

      case UPDATE_SPONSOR_INFORMATIONS_SUCCESS: {
        draft.loading = false
        break
      }

      case DELETE_COMMUNITY_INFORMATIONS: {
        draft.communityInformations = null
        break
      }

      case OPEN_CREATE_CAMPAIGN_FOR_TUTORIAL: {
        draft.openCreateCampaignForTutorial = true
        break
      }

      case CLOSE_CREATE_CAMPAIGN_FOR_TUTORIAL: {
        draft.openCreateCampaignForTutorial = false
        break
      }

      case SET_SHOW_CAMPAIGN_REVIEW_PAGE_MOCKED_FOR_THE_TUTORIAL: {
        draft.showCampaignReviewPageMockedForTheTutorial = action.payload.status
        break
      }

      case UPLOAD_S3_SUCCESS: {
        draft.s3SponsorLogo.id = action.payload.id
        draft.s3SponsorLogo.url = action.payload.url
        break
      }

      case REMOVE_S3_FROM_STATE: {
        draft.s3SponsorLogo.id = null
        draft.s3SponsorLogo.url = null
        draft.s3SponsorLogo.loading = false

        draft.s3SponsorBanner.id = null
        draft.s3SponsorBanner.url = null
        draft.s3SponsorBanner.loading = false
        break
      }

      case SIGN_OUT: {
        draft.company = null
        draft.firstLogin = true
        break
      }

      default:
    }
  })
}
