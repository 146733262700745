import React, { useState } from 'react'
import { packagesMock } from '~/helper/const'
import SelectPackageSection from './SelectPackageSection'

export default function SelectPackageFormMocked({ packages, setSelectedPackage, currency }) {
  const [checkedPackageIndex, setCheckedPackageIndex] = useState()
  const handleCheckPackage = (packageInfo, index) => {
    setCheckedPackageIndex(index)
    setSelectedPackage(packageInfo)
  }

  return (
    <>
      {packages?.length > 0
        ? packages?.map((packageInfo, index) => (
            <SelectPackageSection
              key={packageInfo.id}
              checkedPackage={checkedPackageIndex === index}
              packageInformations={packageInfo}
              setSelectedPackage={setSelectedPackage}
              onCheckPackage={() => handleCheckPackage(packageInfo, index)}
              currency={currency}
            />
          ))
        : packagesMock?.map((packageInfo, index) => (
            <SelectPackageSection
              key={packageInfo.id}
              checkedPackage={checkedPackageIndex === index}
              packageInformations={packageInfo}
              setSelectedPackage={setSelectedPackage}
              onCheckPackage={() => handleCheckPackage(packageInfo, index)}
              currency={currency}
            />
          ))}
    </>
  )
}
