import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './modules/rootReducer'
import rootSagas from './modules/rootSagas'

const replacer = (key, value) => (Date instanceof Date ? value.toISOString() : value)
const reviver = (key, value) =>
  typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/) ? new Date(value) : value

export const encode = toDeshydrate => JSON.stringify(toDeshydrate, replacer)
export const decode = toRehydrate => JSON.parse(toRehydrate, reviver)

const persistConfig = {
  key: 'adv-plat',
  storage,
  transforms: [createTransform(encode, decode)],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const sagaMiddleware = createSagaMiddleware()
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(sagaMiddleware)))
const persistor = persistStore(store)
sagaMiddleware.run(rootSagas)

if (window.Cypress) {
  window.store = store
}

export { store, persistor }
