import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import { IoIosAdd } from 'react-icons/io'
import WhiteButton from '~/components/WhiteButton'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  padding: 30px;
`

export const SearchInputContainer = styled.div`
  margin: 30px 0px 30px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 35px;
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const ProductsTabContainer = styled.div`
  margin-top: 30px;
`

export const GridTitleContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
`

export const GridTitle = styled.p`
  margin: 0px;
  color: var(--community-dark);
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
`

export const MonthlyPriceTitle = styled.p`
  margin: 0px;
  color: var(--community-dark);
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 10px;
`

export const NewPackageButton = styled(WhiteButton).attrs({})``

export const NewPackageIcon = styled(IoIosAdd).attrs({
  size: 26,
  color: 'var(--community-primary)',
})``

export const DeleteIcon = styled.img.attrs({
  src: '/icons/delete-icon.png',
})`
  width: 22px;
  height: 22px;
`

export const DeletePackageButton = styled(WhiteButton).attrs({
  style: { marginRight: '20px' },
  className: 'animated fadeIn',
})``

export const CheckboxColumn = styled(Col).attrs({
  md: 1,
})``

export const PackageColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const CompositionColumn = styled(Col).attrs({
  md: 3,
})``

export const MonthlyPriceColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SpecialOfferColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  justify-content: center;
`

export const StatusColumn = styled(Col).attrs({
  md: 1,
})`
  display: flex;
  justify-content: center;
`

export const EditColumn = styled(Col).attrs({
  md: 1,
})`
  display: flex;
  justify-content: center;
  align-items: center;
`
