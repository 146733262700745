import React from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import * as S from './styled'

export default function CircularProgressBar({ interest }) {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.CircularProgressBarContainer>
        <CircularProgressbarWithChildren
          value={interest.membersFollowPercentage}
          strokeWidth={5}
          counterClockwise
          styles={buildStyles({
            pathColor: '#e30c53',
            textColor: '#5A6978',
            trailColor: '#d6d6d6',
          })}
        >
          <S.TextInsideCircleContainer>
            <S.TextInsideCircle>{interest.name}</S.TextInsideCircle>
          </S.TextInsideCircleContainer>
        </CircularProgressbarWithChildren>
      </S.CircularProgressBarContainer>
      <S.InterestPercentage>
        {`${Number(interest.membersFollowPercentage).toFixed(1)}%`}
        <br />
        {`${t('engaged')}`}
      </S.InterestPercentage>
    </S.Container>
  )
}
