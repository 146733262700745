import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import TextField from '@material-ui/core/TextField'
import DatePicker from 'react-datepicker'
import Button from '~/components/Shared/Button'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 1100px !important;
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    p {
      margin: 0;
    }
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }

  .modal-body {
    background-color: #fff !important;
    padding: 0px !important;
  }
`

export const Title = styled.p`
  color: #404257;
  margin-left: 5px !important;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  opacity: 0.9;
`

export const ModalTitle = styled.p`
  color: var(--text-bold);
  margin-left: 5px !important;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  opacity: 0.9;
`

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`

export const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  height: auto;
  z-index: 0;
`

export const DatePickerInput = styled(DatePicker)`
  display: flex;
  justify-content: flex-start;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding-left: 5px;
  height: 36px;
  width: 100%;
`

export const StepRow = styled(Row)``

export const CommunityInformationsRow = styled(Row)`
  padding: 0px 24px 30px 16px;

  .slick-list {
    margin-left: 10px;
  }
`

export const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 170px;
  background-image: linear-gradient(to right, #00a2ff 50%, #0090de 100%);
  position: relative;
`

export const StepContainerTitle = styled.p`
  color: #ffffff;
  font-family: Montserrat;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 10px;
`

export const CommunityHeaderContainer = styled.div`
  padding: 20px 20px 10px 20px;
`

export const CommunityHeaderInformations = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 10px;
`

export const CommunityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`

export const CommunityInformationsContainer = styled.div`
  display: flex;
`

export const CommunityLocation = styled.p`
  margin: 0px;
  color: #827f8e;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  text-decoration: underline;
`

export const CommunityLogo = styled.img`
  border-radius: 100px;
  width: 60px;
  height: 60px;
`

export const CommunityManager = styled.span`
  color: #827f8e;
  font-family: Lato;
  font-size: 15px;
`

export const CompanyName = styled.div`
  word-break: normal;
  font-size: 18px;
  font-weight: 700;
  color: #222432;
  font-family: Open Sans;
  font-size: 23px;
`

export const CircularProgressBarContainer = styled.div`
  margin-top: 20px;
`

export const InterestsContainer = styled.div`
  position: relative;
  margin-top: 5px;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding: 0 18px 46px 18px;
`

export const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  svg {
    margin-right: 4px;
  }
`

export const SectionTitle = styled.span`
  color: #484848;
  font-family: Lato;
  font-size: 14px;
  line-height: 15px;
  font-weight: bold;
`

export const Ball = styled.div`
  background-color: #635b5b;
  border-radius: 100%;
  width: 6px;
  height: 6px;
`

export const CommunityAvailableProductsContainer = styled.div`
  margin-top: 8px;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 24px;
  flex-wrap: wrap;
`

export const ProductContainer = styled.div`
  display: inline-flex;
  padding: 0px 24px;
  align-items: center;

  svg {
    margin-left: 4px;
    cursor: pointer;
  }
`

export const ProductName = styled.p`
  margin: 0px;
  color: #635b5b;
  font-family: Lato;
  font-size: 13px;
  margin-left: 4px !important;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`

export const SectionIcon = styled.img.attrs({
  className: 'animated fadeIn',
  alt: 'Product icon',
  src: '/icons/product_bck.png',
})`
  margin-right: 8px;
  width: 13px;
  height: 13px;
`

export const FormContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 16px;
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  padding: 0px 16px 20px 16px;
  margin-top: 20px;
`

export const Form = styled.form.attrs({
  id: 'my-form',
})``

export const FormTitle = styled.p`
  margin: 0px;
  color: var(--input-title);
  font-family: Lato;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
  padding-top: 10px;
`

export const ProductTotalAndFormatsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 12px;
`

export const FormatsSelected = styled.p`
  margin: 0px;
  color: var(--grey);
  font-family: Lato;
  font-size: 15px;
  text-align: left;
  margin-left: 6px !important;
`

export const PackageTotalPrice = styled.p`
  margin-right: 8px !important;
  color: var(--error);
  font-family: Lato;
  font-size: 21px;
  text-decoration: line-through;
  font-weight: bold;
  margin-bottom: 0px;
`

export const CampaignTotalPrice = styled.p`
  margin: 0px;
  color: var(--sponsor-primary);
  font-family: Lato;
  font-size: 21px;
  text-align: left;
  font-weight: bold;
`

export const PackageDiscountPrice = styled(CampaignTotalPrice)``

export const CampaignTypeContainer = styled.div`
  margin-top: 16px;
`

export const FormSectionContainer = styled.div`
  margin-top: 16px;
`

export const InputTextField = styled(TextField).attrs({
  variant: 'outlined',
  fullWidth: true,
})``

export const InputTitle = styled.span`
  color: var(--input-title);
  font-family: Roboto;
  font-size: ${props => (props.previewFromSponsor ? '20px' : '14px')};
  font-weight: bold;
`

export const FormSectionPackageContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 4px;
  padding-top: 14px;
`

export const CommunityKpiContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 32px;
`

export const CloseButton = styled(Button).attrs({
  width: '6.8rem',
  height: '2.5rem',
  type: 'submit',
  uppercase: true,
})``

export const CommunitySectionIcon = styled.img.attrs({
  className: 'animated fadeIn',
  alt: 'Gallery icon',
  src: '/icons/raster.png',
})`
  margin-right: 8px;
  width: 13px;
  height: 13px;
`
