import React from 'react'
import { useTranslation } from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'
import { currencyFormat } from '~/helper/helperFunctions'
import useGetCommunityCurrencySymbol from '~/hooks/useGetCommunityCurrencySymbol'
import * as S from './styled'

export default function AnalyticsKpiCard({
  percentage,
  value,
  previousValue,
  title,
  status,
  days,
  sponsor,
  communityCurrency,
}) {
  const { t } = useTranslation()
  const communityCurrencySymbol = useGetCommunityCurrencySymbol(communityCurrency)

  return (
    <Tooltip
      title={
        title === 'earned' ? (
          <>
            <span>{t('previousValueFrom', { days })}</span>
            <p>{`${communityCurrencySymbol} ${currencyFormat(previousValue)}`}</p>
          </>
        ) : (
          <>
            <span>{t('previousValueFrom', { days })}</span>
            <p>{`${previousValue} ${t(title)}`}</p>
          </>
        )
      }
      placement="top"
      arrow
    >
      <S.CardContainer>
        <S.CardPercentageContainer>
          <S.CardPercentageText status={status}>{`${percentage?.toFixed(0)}%`}</S.CardPercentageText>
          {status === 'up' ? <S.GreenStatusIcon /> : <S.RedStatusIcon />}
        </S.CardPercentageContainer>
        <S.CardValueAndTitleContainer>
          {title === 'earned' ? (
            <S.CardValue>{`${communityCurrencySymbol} ${currencyFormat(value)}`}</S.CardValue>
          ) : (
            <S.CardValue>{value}</S.CardValue>
          )}

          {title === 'earned' && sponsor ? (
            <S.CardTitle>{t('investedMoney')}</S.CardTitle>
          ) : (
            <S.CardTitle>{t(title)}</S.CardTitle>
          )}
        </S.CardValueAndTitleContainer>
      </S.CardContainer>
    </Tooltip>
  )
}
