import { getFireStorage } from '../firebase'

export const uploadFile = async (uri, path, onProgress, customMetadata) => {
  const correctURI = uri.replace('file://', '')

  // onProgress?.({ uri, path, progress: 0 })

  const response = await fetch(correctURI)

  const blob = await response.blob()

  const fileExtension = correctURI.substr(correctURI.lastIndexOf('.') + 1)

  const firestore = getFireStorage()
  const ref = firestore.ref().child(`${path}.${fileExtension}`)
  const uploadTask = ref.put(blob, { customMetadata })

  return new Promise((resolve, reject) =>
    uploadTask.on(
      'state_changed',
      function(snapshot) {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log(`Upload is ${progress}% done`)
        // onProgress?.({ uri, path, progress })
      },
      function(error) {
        reject(error)
      },
      async function() {
        resolve(await ref.getDownloadURL())
      },
    ),
  )
}
