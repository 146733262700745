import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { modalRatingStyle } from '~/helper/const'
import useNavigationFunctions from '~/helper/navigationFunctions'
import useTutorialFunctions from '~/helper/tutorialFunctions'
import * as S from './styled'

export default function ModalTutorialAllSteps({ handleCloseModal, showModal }) {
  const { t } = useTranslation()
  const communityFirstLoginForTheTutorial = useSelector(state => state.tutorial.communityFirstLogin)
  const { setTutorialAsActiveAction, neverShowTheTutorialAgainForTheCommunityAction } = useTutorialFunctions()
  const { setCommunityCurrentTabAction } = useNavigationFunctions()

  const setTutorialAsActiveAndCloseModal = () => {
    setCommunityCurrentTabAction('products')
    setTutorialAsActiveAction()
    handleCloseModal()
  }

  const handleNeverShowThisMessageAgainButton = () => {
    neverShowTheTutorialAgainForTheCommunityAction()
    handleCloseModal()
  }

  return (
    <S.ModalCustom show={showModal} onHide={handleCloseModal}>
      <S.FadingBackground>
        <S.AvatarImageContainer>
          <S.AvatarImage />
        </S.AvatarImageContainer>
      </S.FadingBackground>
      <Modal.Body style={modalRatingStyle}>
        {communityFirstLoginForTheTutorial ? (
          <S.TutorialDescriptionFirstAccess>{t('tutorialDescription')}</S.TutorialDescriptionFirstAccess>
        ) : (
          <>
            <S.TutorialDescription>{t('communityFirstTutorialDescription')}</S.TutorialDescription>
            <S.TutorialDescription>{t('communitySecondTutorialDescription')}</S.TutorialDescription>
          </>
        )}
        <S.AvailableStepsContainer>
          <S.StepContainer>
            <S.StepTitle>{`${t('step')} 1`}</S.StepTitle>
            <S.Circle />
            <S.StepDescription>{t('communityStep1TutorialLabel')}</S.StepDescription>
          </S.StepContainer>
          <S.StepContainer spacing>
            <S.StepTitle>{`${t('step')} 2`}</S.StepTitle>
            <S.Circle />
            <S.StepDescription>{t('communityStep2TutorialLabel')}</S.StepDescription>
          </S.StepContainer>
          <S.StepContainer spacing>
            <S.StepTitle>{`${t('step')} 3`}</S.StepTitle>
            <S.Circle />
            <S.StepDescription>{t('communityStep3TutorialLabel')}</S.StepDescription>
          </S.StepContainer>
          <S.StepContainer spacing>
            <S.StepTitle>{`${t('step')} 4`}</S.StepTitle>
            <S.Circle />
            <S.StepDescription>{t('communityStep4TutorialLabel')}</S.StepDescription>
          </S.StepContainer>
        </S.AvailableStepsContainer>
        <S.NextButton onClick={() => setTutorialAsActiveAndCloseModal()}>
          <S.NextText>{t('next')}</S.NextText>
        </S.NextButton>
        <S.SkipIt onClick={handleCloseModal}>{t('tutorialSkip')}</S.SkipIt>
        <S.DontShowThisMessageAgain onClick={() => handleNeverShowThisMessageAgainButton()}>
          {t('dontShowThisMessageAgain')}
        </S.DontShowThisMessageAgain>
      </Modal.Body>
    </S.ModalCustom>
  )
}
