import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { customMedia } from '~/styles/global'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  padding: 30px;
`

export const KpiContainer = styled.div`
  background-color: #fff;
  padding: 10px 15px 15px 15px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 2px rgba(240, 245, 254, 1);
  width: 100%;
  height: auto;
  margin-top: 24px;
`

export const KpiTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 10px;
`

export const KpiTitle = styled.p`
  text-transform: uppercase;
  color: var(--community-primary);
  font-family: Roboto Condensed;
  font-size: 21px;
  margin-bottom: 0px;
`

export const CustomRow = styled(Row)`
  margin-top: ${props => (props.spacing ? '20px' : '6px')};
`

export const KpiCol = styled(Col).attrs({
  md: 6,
})`
  margin-top: 30px;
`

export const ChartCol = styled(KpiCol).attrs({
  md: 6,
})`
  ${customMedia.lessThan('1185px')`
    display: none;
  `}
`

export const SelectContainer = styled.div`
  width: 120px;
  flex-shrink: 0;
  margin-left: 10px;
`

export const AnalyticsKpiCardsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`

export const ZeroRegisterContainer = styled.div`
  margin-top: 10px;
`

export const ZeroRegisterText = styled.p`
  margin-bottom: 0px;
  font-size: 32px;
  color: rgba(214, 214, 214, 0.5);
  font-family: Montserrat;
  text-align: center;
  line-height: 40px;

  ${customMedia.lessThan('1680px')`
    font-size: 28px;
  `}
`

export const ChartContainer = styled.div`
  margin-top: 30px;
`
