import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import { customMedia } from '~/styles/global'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  padding: 30px;
`

export const SearchInputContainer = styled.div`
  margin: 30px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
`

export const Border = styled.p`
  border: solid rgba(214, 214, 214, 0.3);
  border-width: 1px;
  margin-bottom: 0;
  width: 100% !important;
  opacity: 0.6;
`

export const CampaignTitleContainer = styled.div`
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 8px;
`

export const CampaignsContainer = styled.div`
  margin-top: 20px;
`

export const CampaignsNeedingRevisionContainer = styled.div`
  margin-top: 30px;
  padding-bottom: 8px;
`

export const GridTitleContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
  margin-right: 15px;
  align-items: center;

  ${customMedia.lessThan('1470px')`
    display: flex;
     align-items: center;
     justify-content: space-evenly;
  `}
`

export const GridTitle = styled.p`
  display: flex;
  margin: 0px;
  color: var(--community-dark);
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
`

export const CheckPerformanceGridTitle = styled(GridTitle)`
  text-align: center;

  ${customMedia.lessThan('1467px')`
   display: none
  `}
`

export const SectionTitle = styled.p`
  margin: 0px;
  color: var(--community-primary);
  font-family: Roboto Condensed;
  font-size: 24px;
  text-transform: uppercase;
  margin-top: 36px;
`

export const NameColumn = styled(Col).attrs({
  md: 3,
})``

export const CampaignTypeColumn = styled(Col).attrs({
  md: 2,
})``

export const TotalBudgetColumn = styled(Col).attrs({
  md: 2,
})``

export const CampaignTimingColumn = styled(Col).attrs({
  md: 2,
})`
  display: flex;
  justify-content: center;
  align-items: center;

  ${customMedia.lessThan('1470px')`
    display: none;
  `}
`

export const ActionColumn = styled(Col).attrs({
  md: 3,
})`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CheckPerformanceColumn = styled(Col).attrs({
  md: 1,
})`
  padding-left: 0px !important;
  padding-right: 15px !important;
`
