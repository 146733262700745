const openExternalUrl = async url => {
  if (url) {
    // eslint-disable-next-line no-useless-escape
    const processedUrl = !/http(?:s)?\:\/\//g.test(url) ? `https://${url?.trim()}`.toLowerCase() : url

    window.open(processedUrl, '_blank', 'noopener noreferrer')
  }
}

export default openExternalUrl
