/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable consistent-return */
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { clone } from 'ramda'
import { v4 as uuidv4 } from 'uuid'
import * as S from './styled'

const urlMatcher = /(?:https?:\/\/)?(?:www\.)?(?:[\da-z-]+\.)+[a-z]{2,10}(?:\/[^\s/]+)*/gi

const emailMatcher = /\S+@\S+\.\S+/gi

const MENTION_PATTERN = ' @'

const MessageText = ({
  message,
  entireMessage,
  isVideoOrImage,
  isFile,
  isReply,
  isReplyFromInput,
  sentByMe,
  isHubContentMessage,
  isLinkMessage,
}) => {
  const [replyLink, setReplyLink] = useState(null)
  const { users: firebaseUsers } = useSelector(state => state.firebaseUsers)

  const handleClickLink = () => {
    if (entireMessage?.links?.length > 0) {
      const urlWithHttp = `${entireMessage?.links?.[0]}`
      window.open(urlWithHttp, '_blank')
    }
  }

  const handleClickLinkReply = () => {
    if (replyLink) {
      const urlWithHttp = `${replyLink}`
      window.open(urlWithHttp, '_blank')
    }
  }

  const getPossibleMentions = () => {
    const channelUsers = firebaseUsers?.map(user => `${user?.name}`)
    return channelUsers
  }

  const splitMention = () => {
    const possibleMentions = getPossibleMentions()
    const phrases = []

    const splittedMessage =
      typeof message === 'string' && message?.trim()?.includes('@') && message?.length >= 2
        ? message?.split(/\B\@/gim)?.filter(x => !!x)
        : [message]?.filter(x => !!x)

    splittedMessage.forEach((phrase, index) => {
      const exactMention = possibleMentions?.find(possibleMention => phrase.trim() === possibleMention)

      const mention = exactMention || possibleMentions?.find(possibleMention => phrase.includes(possibleMention))

      if (mention && !!phrase) {
        const splitedMention = phrase.split(mention)
        if (!splitedMention || !splitedMention?.length) {
          return
        }

        if (splitedMention[0]) {
          phrases.push({ text: splitedMention[0], type: 'normal' })
        }

        phrases.push({
          text: `${MENTION_PATTERN}${mention}`,
          type: 'mention',
        })

        if (splitedMention[1]) {
          phrases.push({ text: splitedMention[1], type: 'normal' })
        }
      } else if (phrase !== undefined) {
        if (splittedMessage?.length === 1) {
          return phrases.push({ text: message, type: 'normal' })
        }

        if (index === splittedMessage.length - 1) {
          const [lastPhraseSplitted] = splittedMessage?.slice(-1)
          const [lastLetterMessage] = message.slice(-1)

          if (lastLetterMessage === '@' && lastPhraseSplitted === '') {
            return phrases.push({ text: `${phrase} @`, type: 'normal' })
          }
        }
        return phrases.push({
          text: message.includes(`@${phrase}`) && !phrase.includes('@') ? `@${phrase}` : phrase,
          type: 'normal',
        })
      }
    })
    return phrases
  }

  const addProperSpacing = input => {
    const spacedUrls = input.replace(urlMatcher, x => ` ${x}`)
    return spacedUrls.replace(/@ /g, x => x.trim())
  }

  const renderText = () => {
    let phrases = []

    const splittedMentions = splitMention()

    if (splittedMentions?.length) {
      phrases = [...splittedMentions]
    }

    const clonnedPhrases = clone(phrases)

    clonnedPhrases.forEach((phrase, phraseIndex) => {
      if (phrase?.type === 'mention') return

      const emails = message.match(emailMatcher) || []
      const urls = message.replace(emailMatcher, '').match(urlMatcher) || []

      const links = [...emails, ...urls]

      const words = links?.length ? addProperSpacing(phrase?.text).split(' ') : [phrase.text]

      const parsedWords = words
        .filter(x => !!x)
        .map(word => {
          return links.includes(word) ? { text: word, type: 'url' } : { text: ` ${word.trim()} `, type: 'normal' }
        })

      phrases.splice(phraseIndex, 1, ...parsedWords)
    })

    const messageFormat = phrase => {
      if (phrase?.type === 'mention') {
        return <S.MentionMessage key={uuidv4()}>{`${phrase?.text.trim()} `}</S.MentionMessage>
      }

      if (phrase?.type === 'url') {
        setReplyLink(phrase?.text)
        return <S.UrlText key={uuidv4()}>{phrase?.text}</S.UrlText>
      }
      return (
        <div key={uuidv4()} className="ml-1 mr-1">
          {phrase?.text}
        </div>
      )
    }

    return phrases.map((phrase, index) => {
      return messageFormat(phrase, index)
    })
  }

  return (
    <S.Container
      isVideoOrImage={isVideoOrImage}
      isReplyFromInput={isReplyFromInput}
      onClick={isReply ? handleClickLinkReply : handleClickLink}
      sentByMe={sentByMe}
      isFile={isFile}
      isTextMessage={entireMessage?.type === 'Message'}
      isReply={isReply}
      isHubContentMessage={isHubContentMessage}
      isLinkMessage={isLinkMessage}
    >
      <S.TextMessageContainer>{useMemo(() => renderText(), [message])}</S.TextMessageContainer>
    </S.Container>
  )
}

export default MessageText
