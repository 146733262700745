import * as actions from './actionTypes'

export function setTutorialAsActive() {
  return {
    type: actions.SET_TUTORIAL_AS_ACTIVE,
  }
}

export function setTutorialAsInative() {
  return {
    type: actions.SET_TUTORIAL_AS_INACTIVE,
  }
}

export function setSponsorTutorialFirstLogin() {
  return {
    type: actions.SET_SPONSOR_TUTORIAL_FIRST_LOGIN,
  }
}

export function setCommunityTutorialFirstLogin() {
  return {
    type: actions.SET_COMMUNITY_TUTORIAL_FIRST_LOGIN,
  }
}

export function incrementByOneTheSponsorLogin() {
  return {
    type: actions.INCREMENT_BY_ONE_THE_SPONSOR_LOGIN,
  }
}

export function incrementByOneTheCommunityLogin() {
  return {
    type: actions.INCREMENT_BY_ONE_THE_COMMUNITY_LOGIN,
  }
}

export function neverShowTheTutorialAgainForTheSponsor() {
  return {
    type: actions.NEVER_SHOW_THE_TUTORIAL_AGAIN_FOR_THE_SPONSOR,
  }
}

export function neverShowTheTutorialAgainForTheCommunity() {
  return {
    type: actions.NEVER_SHOW_THE_TUTORIAL_AGAIN_FOR_THE_COMMUNITY,
  }
}
