import * as actions from './actionTypes'

export function getCommunityInformationsByInvitationLinkRequest(code) {
  return {
    type: actions.GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_REQUEST,
    payload: { code },
  }
}

export function getCommunityInformationsByInvitationLinkSuccess(communityInformations) {
  return {
    type: actions.GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_SUCCESS,
    payload: { communityInformations },
  }
}

export function getCommunityInformationsByInvitationLinkFailure() {
  return {
    type: actions.GET_COMMUNITY_INFORMATIONS_BY_INVITATION_LINK_FAILURE,
  }
}

export function resetCommunityInformations() {
  return {
    type: actions.RESET_COMMUNITY_INFORMATIONS,
  }
}
