import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation } from 'react-i18next'
import DefaultUserImage from '~/util/default-user.png'
import * as S from './styled'

export default function PreviewCommunitySignupSponsor({ community }) {
  const { user } = community
  const { t } = useTranslation()

  return (
    <Row>
      <Col md={6} className="p-0 pl-3">
        <S.ContainerBannerImage>
          <S.BannerImage
            src={
              community !== null && community.bannerAdvPlat
                ? community.bannerAdvPlat
                : '/community-default-banner-picture.jpg'
            }
          />
        </S.ContainerBannerImage>
        <div>
          <S.CommunityLogo
            src={
              community !== null && community.logoAdvPlat
                ? community.logoAdvPlat
                : '/community-profile-default-picture.png'
            }
          />
        </div>
      </Col>

      <Col md={6} className="p-0 pr-3">
        <S.ContainerPreview>
          <p className="community-name">{community.name}</p>

          <div className="d-flex">
            <S.CommunityUserAvatarSection>
              <img
                src={
                  user.imageServerPath === null || user.imageServerPath === undefined
                    ? DefaultUserImage
                    : user.imageServerPath
                }
                alt="Community User Avatar"
              />
              <span>{community !== null && community.user.firstName ? community.user.firstName : t('userName')}</span>
            </S.CommunityUserAvatarSection>
            <S.CommunityDescription>
              {community !== null && community.description ? community.description : null}
            </S.CommunityDescription>
          </div>
        </S.ContainerPreview>
      </Col>
    </Row>
  )
}
