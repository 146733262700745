import styled from 'styled-components'
import { IoIosRemoveCircleOutline, IoIosAddCircleOutline } from 'react-icons/io'

export const CardContainer = styled.div`
  background-color: #fff;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ImageAndInfoContainer = styled.div`
  display: flex;
  gap: 2px;
`

export const TitleAndDescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 8px;
`

export const FormatImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 8px;
  border: 2px solid rgb(230, 230, 230, 0.5);
  border-radius: 8px;
`

export const FormatImage = styled.img`
  width: 52px;
  height: auto;
`

export const FormatName = styled.p`
  margin: 0px;
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  line-height: 1.1;
  font-weight: 600;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
`
export const FormatDescription = styled.p`
  color: #5a5a5a;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  line-height: 1.2;
  padding-top: 3px;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
`

export const AddAndRemoveSection = styled.div`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    min-width: 60px;

    &:active {
      vertical-align: top;
      padding: 5px 6px 3px;
    }
  }
`

export const Quantity = styled.span`
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 18px;
`

export const AddProductIcon = styled(IoIosAddCircleOutline).attrs({
  className: 'add-product-icon',
  color: '#a2a5a9',
  size: 34,
})``

export const DecreaseProductIcon = styled(IoIosRemoveCircleOutline).attrs({
  className: 'decrease-product-icon',
  color: '#a2a5a9',
  size: 34,
})``
