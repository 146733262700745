import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CommunityCard from '~/components/Sponsor/CommunityCard'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { increaseStep as increaseStepAction } from '~/store/modules/step/actions'
import { resetCampaignProcess as resetCampaignProcessAction } from '~/store/modules/createCampaign/actions'
import CreateCampaignModalTooltipStep1 from '~/components/ApplicationTutorial//Sponsor/CreateCampaignModalTooltipStep1'

import * as S from './styled'

export default function StartNewCampaignStep1({ communities }) {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const tutorialIsActive = useSelector(state => state.tutorial.tutorialIsActive)

  const {
    setCurrentCommunityForCreateCampaignProcessAction,
    setTemporaryCampaignIdForStripePaymentIntentCreationAction,
  } = useSponsorFunctions()

  function initiateCreateCampaignProccess(community) {
    dispatch(resetCampaignProcessAction())
    setCurrentCommunityForCreateCampaignProcessAction(community)
    setTemporaryCampaignIdForStripePaymentIntentCreationAction()
    dispatch(increaseStepAction())
  }

  return (
    <S.Container communities={communities}>
      {communities.length ? (
        communities.map(community => (
          <S.CommunityCardContainer key={community?.id}>
            <CommunityCard
              action={() => initiateCreateCampaignProccess(community)}
              key={community?.id}
              community={community}
            />
          </S.CommunityCardContainer>
        ))
      ) : (
        <S.NoCommunitiesAvailableMessage>{t('noCommunitiesAvailable')}</S.NoCommunitiesAvailableMessage>
      )}
      {tutorialIsActive && <CreateCampaignModalTooltipStep1 />}
    </S.Container>
  )
}
