import styled from 'styled-components'
import { MdTrendingUp } from 'react-icons/md'

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  margin-top: 0.5rem;
  width: 93%;
  height: 22rem;
  background: #f6f6f6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 1rem;

  .MuiTypography-body1 {
    font-size: 14px;
    font-family: Roboto;
    color: var(--input-title);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .MuiFormControlLabel-label.Mui-disabled {
    color: var(--input-title) !important;
  }
`

export const SurveyHeader = styled.div`
  display: flex;
  align-items: center;
`

export const SurveyIcon = styled(MdTrendingUp).attrs({
  color: '#3C3C3C',
  size: 30,
})``

export const SurveyLabel = styled.span`
  font-family: Roboto;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.005em;
  color: var(--input-title);
  margin-left: 0.8rem;
`

export const EntityInformations = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  border-bottom: 1px solid rgba(214, 214, 214, 0.3);
  padding-bottom: 0.5rem;
`

export const EntityLogo = styled.img.attrs({
  src: 'apple-logo.png',
})`
  width: 28px;
  height: 26px;
  border-radius: 100px;
`

export const EntityNameAndLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 0.5rem;
`

export const EntityName = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.005em;
  color: var(--input-title);
`

export const TrustedSponsorLabel = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.005em;
  color: var(--community-primary);
`

export const SurveyQuestionContainer = styled.div`
  margin-top: 0.3rem;
  svg {
    color: #c5c5c5 !important;
  }
`

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 18rem;
  height: 30px;

  span {
    word-wrap: break-word;
  }
`

export const SurveyQuestion = styled.p`
  margin-bottom: 0px;
  font-family: Roboto;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.005em;
  color: var(--input-title);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
`

export const SurveyTiming = styled.p`
  margin-bottom: 0px;
  top: 4.5rem;
  right: 1rem;
  position: absolute;
  font-family: Roboto;
  font-size: 10px;
  color: #777777;
  opacity: 0.45;
`
