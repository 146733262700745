import styled from 'styled-components'
import { MdClose } from 'react-icons/md'

export const Container = styled.div.attrs({
  className: 'animated fadeInUp',
})`
  position: fixed;
  bottom: 0;
  right: -10px;
  background-color: #fff;
  width: 390px;
  height: 480px;
  margin: 20px 50px 20px 20px;
  border: 2px solid rgba(214, 214, 214, 0.6);
  border-radius: 5px;
  z-index: 1050;
  display: ${props => (props.open === true ? 'inline' : 'none')};

  .emoji-picker-react {
    width: 100%;
    height: 430px;
  }

  .react-tenor {
    height: 430px;
  }
`

export const EmojiPickerContainer = styled.div.attrs({
  className: 'animated pulse',
})`
  position: absolute;
  z-index: 10;
  right: 0px;
  top: 0px;
  max-width: 390px;
`

export const GifContainer = styled.div.attrs({
  className: 'animated pulse',
})`
  position: absolute;
  z-index: 999;
  right: 0px;
  top: 0px;
  max-width: 390px;
`

export const ChatWindowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 0 15px;

  svg {
    cursor: pointer;
  }
`

export const ChannelLogoContainer = styled.div`
  width: 31px;
  height: 31px;
`

export const InputAndReplyContainer = styled.div`
  p {
    margin: 0;
    font-family: Lato;
    font-size: 13px;
    font-weight: 400;
  }

  img {
    width: 40px;
    height: 40px;
  }
`

export const InputContainer = styled.div`
  margin: 0 10px;
`

export const ChannelLogoAndTitleSection = styled.div`
  display: flex;
  align-items: center;
`

export const ChannelTitle = styled.p`
  padding-left: 10px;
  margin: 0;
  color: #47525e;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 280px;
`

export const ChannelImage = styled.img`
  border-radius: 100px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const HeaderHorizontalLine = styled.hr``

export const MessageHorizontalLine = styled.hr`
  margin-bottom: 0px;
  margin-top: 16px;
`

export const ReplyContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fafafa;
  position: absolute;
  bottom: 46px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 10;

  &.inputHasMedia {
    bottom: 118px;
  }

  &.optionsSectionIsOpen {
    bottom: 96px;
  }
`

export const ReplyCloseIcon = styled(MdClose).attrs({
  size: 22,
  color: '#47525E',
})`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 100%;
  border-color: #47525e;
  border-width: 2px;
  border-style: solid;
  margin-right: 10px;

  &:hover {
    opacity: 0.85;
  }
`

export const ChatChannelCloseIcon = styled(MdClose).attrs({
  size: 28,
  color: '#47525E',
})`
  &:hover {
    opacity: 0.9;
  }
`

export const ContainerChat = styled.div`
  overflow-y: auto;
  height: 335px;
`

export const InputAddImage = styled.input.attrs({
  type: 'file',
  accept: 'image/x-png,image/gif,image/jpeg',
  style: { display: 'none' },
})``

export const InputAddVideo = styled.input.attrs({
  type: 'file',
  accept: 'video/mp4,video/x-m4v,video/*',
  style: { display: 'none' },
})``

export const InputAddFile = styled.input.attrs({
  type: 'file',
  accept: '.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,video/*',
  style: { display: 'none' },
})``

export const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fafafa;
  position: absolute;
  bottom: 47px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 10;
  padding: 20px;
`

export const ImagePreview = styled.img.attrs({
  className: 'animated fadeIn',
})`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  margin-right: 8px;
  display: block;
`

export const ImagePreviewContainer = styled.div`
  width: 100%;
  display: flex;
`

export const ContainerImage = styled.div`
  position: relative;
`

export const RemoveImageIcon = styled(MdClose).attrs({
  color: '#FFF',
})`
  cursor: pointer;
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #47525e;
  border-radius: 50%;
  top: -4px;
  left: 25px;
`

export const VideoPreview = styled.video.attrs({
  className: 'animated fadeIn',
})`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  margin-right: 8px;
  display: block;
  object-fit: cover;
`

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  padding: 5px;
  text-decoration: none;

  & a {
    text-decoration: none;
    color: #47525e;
  }
`

export const FileName = styled.div`
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  max-width: 220px;
  margin-left: 5px;
  margin-top: 2px;
  font-size: 13px;
`
