import * as actions from './actionTypes'

export function setCroppedImage(image) {
  return {
    type: actions.SET_CROPPED_IMAGE,
    payload: { image },
  }
}

export function setShowCropModal(value) {
  return {
    type: actions.SET_SHOW_CROP_MODAL,
    payload: { value },
  }
}
