import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import useCampaignSelectors from '~/helper/campaignSelectors'
import {
  createSponsorCommunityChannel as createSponsorCommunityChannelAction,
  updateSponsorCommunityChannel as updateSponsorCommunityChannelAction,
} from '~/store/modules/chat/actions'
import { setWrongUserTryingToLogin as setWrongUserTryingToLoginAction } from '~/store/modules/auth/actions'
import {
  increaseProductQuantity as increaseProductQuantityAction,
  decreaseProductQuantity as decreaseProductQuantityAction,
  addProduct as addProductAction,
  editProduct as editProductAction,
  deleteProduct as deleteProductAction,
  addCampaignInformations as addCampaignInformationsAction,
  setCurrentCommunityForCreateCampaignProcess as setCurrentCommunityForCreateCampaignProcessAction,
  setProductInformations as setProductInformationsAction,
  resetCampaignProcess as resetCampaignProcessAction,
  setCampaignCurrentSelectedMonth as setCampaignCurrentSelectedMonthAction,
  setCampaignCurrentSelectedYear as setCampaignCurrentSelectedYearAction,
  sponsorDeleteCampaignRequest as sponsorDeleteCampaignRequestAction,
  deleteAllContent as deleteAllContentAction,
  deleteAllRewards as deleteAllRewardsAction,
  deleteAllSurveys as deleteAllSurveysAction,
  deleteAllEvents as deleteAllEventsAction,
  deleteAllChatChannels as deleteAllChatChannelsAction,
  deleteAllWelcomeAds as deleteAllWelcomeAdsAction,
  decreaseMobileBoostQuantity as decreaseMobileBoostQuantityAction,
  setCampaignRating as setCampaignRatingAction,
  stripeUserAuthorizationCode as stripeUserAuthorizationCodeAction,
  setTemporaryCampaignIdForStripePaymentIntentCreation as setTemporaryCampaignIdForStripePaymentIntentCreationAction,
  stripeCreatePaymentIntentRequest as stripeCreatePaymentIntentRequestAction,
  stripeUpdatePaymentIntentRequest as stripeUpdatePaymentIntentRequestAction,
  setCurrentCampaignBeingUpdated as setCurrentCampaignBeingUpdatedAction,
  setCampaignTotalPrice as setCampaignTotalPriceAction,
  createCampaignWithPackage as createCampaignWithPackageAction,
  createCampaignPayAsYouGo as createCampaignPayAsYouGoAction,
  resetStripeClientSecret as resetStripeClientSecretAction,
  createPaymentIntentPayAsYouGo as createPaymentIntentPayAsYouGoAction,
  createPaymentIntentPackage as createPaymentIntentPackageAction,
  setCurrentCampaignForCreateFormat as setCurrentCampaignForCreateFormatAction,
  createCampaignFormat as createCampaignFormatAction,
  updateCampaignFormat as updateCampaignFormatAction,
  setCampaignProductsStatus as setCampaignProductsStatusAction,
  addSurveyToArray as addSurveyToArrayAction,
  removeSurveysFromArray as removeSurveysFromArrayAction,
} from '~/store/modules/createCampaign/actions'
import {
  deleteCommunitiesInformations as deleteCommunitiesInformationsAction,
  updateSponsorInformations as updateSponsorInformationsAction,
  updateSponsorProfileRequest as updateSponsorProfileRequestAction,
  removeS3FromState as removeS3FromStateAction,
  uploadS3Request as uploadS3RequestAction,
  setFirstLogin as setFirstLoginAction,
  setAllCommunitiesFromSponsor as setAllCommunitiesFromSponsorAction,
  setShowCampaignReviewPageMockedForTheTutorial as setShowCampaignReviewPageMockedForTheTutorialAction,
} from '~/store/modules/sponsor/actions'
import {
  increaseStep as increaseStepAction,
  decreaseStep as decreaseStepAction,
  updateStep as updateStepAction,
} from '~/store/modules/step/actions'

const useSponsorFunctions = () => {
  const dispatch = useDispatch()
  const {
    numberOfChatChannelsAddedByUser,
    numberOfRewardsAddedByUser,
    numberOfWebsAddedByUser,
    numberOfWelcomeAdsAddedByUser,
    numberOfSurveysAddedByUser,
    numberOfPostsAddedByUser,
    numberOfVideosAddedByUser,
    numberOfAnnouncementsAddedByUser,
    numberOfDocumentsAddedByUser,
    numberOfEventsAddedByUser,
    contentQuantity,
    rewardQuantity,
    welcomeAdQuantity,
    surveyQuantity,
    chatChannelQuantity,
    eventQuantity,
    allSurveysAddedByUser,
    allEventsAddedByUser,
    allChatChannelsAddedByUser,
    allWelcomeAdsAddedByUser,
    allRewardsAddedByUser,
    allVideosAddedByUser,
    allWebsAddedByUser,
    allPostsAddedByUser,
    allDocumentsAddedByUser,
  } = useCampaignSelectors()

  const actions = useMemo(
    () => ({
      increaseStep() {
        dispatch(increaseStepAction())
      },

      decreaseStep() {
        dispatch(decreaseStepAction())
      },

      updateStepAction(step) {
        dispatch(updateStepAction(step))
      },

      setAllCommunitiesFromSponsorAction(communities) {
        dispatch(setAllCommunitiesFromSponsorAction(communities))
      },

      setShowCampaignReviewPageMockedForTheTutorialAction(status) {
        dispatch(setShowCampaignReviewPageMockedForTheTutorialAction(status))
      },

      setFirstLoginAction() {
        dispatch(setFirstLoginAction())
      },

      resetCampaignProcessAction() {
        dispatch(resetCampaignProcessAction())
      },

      setCampaignCurrentSelectedMonthAction(month) {
        dispatch(setCampaignCurrentSelectedMonthAction(month))
      },

      setCampaignCurrentSelectedYearAction(year) {
        dispatch(setCampaignCurrentSelectedYearAction(year))
      },

      createSponsorCommunityChannelAction(
        sponsorId,
        communityId,
        communityName,
        sponsorName,
        sponsorLogo,
        communityLogo,
      ) {
        dispatch(
          createSponsorCommunityChannelAction(
            sponsorId,
            communityId,
            communityName,
            sponsorName,
            sponsorLogo,
            communityLogo,
          ),
        )
      },

      updateSponsorCommunityChannelAction(
        sponsorId,
        communityId,
        communityName,
        sponsorName,
        sponsorLogo,
        communityLogo,
      ) {
        dispatch(
          updateSponsorCommunityChannelAction(
            sponsorId,
            communityId,
            communityName,
            sponsorName,
            sponsorLogo,
            communityLogo,
          ),
        )
      },

      removeS3FromStateAction() {
        dispatch(removeS3FromStateAction())
      },

      setWrongUserTryingToLoginAction(boolean) {
        dispatch(setWrongUserTryingToLoginAction(boolean))
      },

      uploadS3RequestAction(file) {
        dispatch(uploadS3RequestAction(file))
      },

      deleteCommunitiesInformationsAction() {
        dispatch(deleteCommunitiesInformationsAction())
      },

      updateSponsorInformationsAction(sponsor) {
        dispatch(updateSponsorInformationsAction(sponsor))
      },

      sponsorDeleteCampaignRequestAction(campaignId) {
        dispatch(sponsorDeleteCampaignRequestAction(campaignId))
      },

      deleteAllContentAction() {
        dispatch(deleteAllContentAction())
      },

      deleteAllRewardsAction() {
        dispatch(deleteAllRewardsAction())
      },

      deleteAllSurveysAction() {
        dispatch(deleteAllSurveysAction())
      },

      deleteAllEventsAction() {
        dispatch(deleteAllEventsAction())
      },

      deleteAllChatChannelsAction() {
        dispatch(deleteAllChatChannelsAction())
      },

      deleteAllWelcomeAdsAction() {
        dispatch(deleteAllWelcomeAdsAction())
      },

      decreaseMobileBoostQuantityAction(quantityToDecrease) {
        dispatch(decreaseMobileBoostQuantityAction(quantityToDecrease))
      },

      setCampaignRatingAction(entity, campaignId, rating, note) {
        dispatch(setCampaignRatingAction(entity, campaignId, rating, note))
      },

      stripeUserAuthorizationCodeAction(code, networkId) {
        dispatch(stripeUserAuthorizationCodeAction(code, networkId))
      },

      setTemporaryCampaignIdForStripePaymentIntentCreationAction() {
        dispatch(setTemporaryCampaignIdForStripePaymentIntentCreationAction())
      },

      stripeCreatePaymentIntentRequestAction(communityId, campaignId, campaignProducts) {
        dispatch(stripeCreatePaymentIntentRequestAction(communityId, campaignId, campaignProducts))
      },

      stripeUpdatePaymentIntentRequestAction(campaignId, campaignProducts) {
        dispatch(stripeUpdatePaymentIntentRequestAction(campaignId, campaignProducts))
      },

      updateSponsorProfileRequestAction(id, data) {
        dispatch(updateSponsorProfileRequestAction(id, data))
      },

      setCurrentCommunityForCreateCampaignProcessAction(community) {
        dispatch(setCurrentCommunityForCreateCampaignProcessAction(community))
      },

      setCurrentCampaignBeingUpdatedAction(campaign) {
        dispatch(setCurrentCampaignBeingUpdatedAction(campaign))
      },

      setCampaignTotalPriceAction(totalPrice) {
        dispatch(setCampaignTotalPriceAction(totalPrice))
      },

      createCampaignWithPackageAction(communityId, data) {
        dispatch(createCampaignWithPackageAction(communityId, data))
      },

      createCampaignPayAsYouGoAction(communityId, data) {
        dispatch(createCampaignPayAsYouGoAction(communityId, data))
      },

      resetStripeClientSecretAction() {
        dispatch(resetStripeClientSecretAction())
      },

      createPaymentIntentPayAsYouGoAction(communityId, uuid, campaignProducts, campaignDuration, paymentOption) {
        dispatch(
          createPaymentIntentPayAsYouGoAction(communityId, uuid, campaignProducts, campaignDuration, paymentOption),
        )
      },

      createPaymentIntentPackageAction(communityId, packageId, uuid, campaignDuration, paymentOption) {
        dispatch(createPaymentIntentPackageAction(communityId, packageId, uuid, campaignDuration, paymentOption))
      },

      createCampaignFormatAction(campaignId, campaignProductId, format, contentType, closeModal, setLoading) {
        dispatch(createCampaignFormatAction(campaignId, campaignProductId, format, contentType, closeModal, setLoading))
      },

      updateCampaignFormatAction(format, formatId, closeModal, setLoading) {
        dispatch(updateCampaignFormatAction(format, formatId, closeModal, setLoading))
      },

      setCampaignProductsStatusAction(products, communityId, campaignId, status, setLoading) {
        dispatch(setCampaignProductsStatusAction(products, communityId, campaignId, status, setLoading))
      },

      addSurveyToArrayAction(survey) {
        dispatch(addSurveyToArrayAction(survey))
      },

      removeSurveysFromArrayAction() {
        dispatch(removeSurveysFromArrayAction())
      },

      setCurrentCampaignForCreateFormatAction(campaign) {
        dispatch(setCurrentCampaignForCreateFormatAction(campaign))
      },

      addCampaignInformationsAction(data) {
        dispatch(addCampaignInformationsAction(data))
      },

      setProductInformationsAction(productType, product) {
        dispatch(setProductInformationsAction(productType, product))
      },

      increaseRewardQuantity() {
        dispatch(increaseProductQuantityAction('reward'))
      },

      increaseEventQuantity() {
        dispatch(increaseProductQuantityAction('event'))
      },

      increaseWelcomeAdQuantity() {
        dispatch(increaseProductQuantityAction('welcomeAd'))
      },

      increaseContentQuantity() {
        dispatch(increaseProductQuantityAction('content'))
      },

      increaseMobileBoostQuantity() {
        dispatch(increaseProductQuantityAction('mobileBoost'))
      },

      increaseSurveyQuantity() {
        dispatch(increaseProductQuantityAction('survey'))
      },

      increaseChatChannelQuantity() {
        dispatch(increaseProductQuantityAction('chatChannel'))
      },

      addProductAction(productType, product) {
        dispatch(addProductAction(productType, product))
      },

      deleteProductAction(productType, product) {
        dispatch(deleteProductAction(productType, product))
      },

      editProductAction(productType, product) {
        dispatch(editProductAction(productType, product))
      },

      addProductSurveyMultipleQuestionsAction(productType, product, surveyId) {
        dispatch(addProductAction(productType, product, surveyId))
      },

      editProductSurveyMultipleQuestionsAction(productType, product, surveyId) {
        dispatch(editProductAction(productType, product, surveyId))
      },

      getSurveysWithMultipleQuestions() {
        const arraySurveyWithoutMultipleQuestions = []
        const surveyArray = Object.values(allSurveysAddedByUser())
        surveyArray.forEach(survey => {
          if (survey.numberSurveyQuestions > 1) {
            arraySurveyWithoutMultipleQuestions.push(survey)
          }
        })
        return arraySurveyWithoutMultipleQuestions
      },

      getSurveysWithoutMultipleQuestions() {
        const arraySurveyWithoutMultipleQuestions = []
        const surveyArray = Object.values(allSurveysAddedByUser())
        surveyArray.forEach(survey => {
          if (survey.numberSurveyQuestions === 1) {
            arraySurveyWithoutMultipleQuestions.push(survey)
          }
        })
        return arraySurveyWithoutMultipleQuestions
      },

      decreaseChatChannelQuantity(setErrorDecreaseContent) {
        if (numberOfChatChannelsAddedByUser() > 0) {
          setErrorDecreaseContent(true)
          return toast.error('To decrease the amount, first delete all chat channels added.')
        }
        return dispatch(decreaseProductQuantityAction('chatChannel'))
      },

      decreaseSurveyQuantity(setErrorDecreaseContent) {
        if (numberOfSurveysAddedByUser() > 0) {
          setErrorDecreaseContent(true)
          return toast.error('To decrease the amount, first delete all surveys added.')
        }
        return dispatch(decreaseProductQuantityAction('survey'))
      },

      decreaseMobileBoostQuantity() {
        let isProductsBoosted = false
        if (numberOfDocumentsAddedByUser() > 0) {
          allDocumentsAddedByUser().forEach(document => {
            if (document.boost === true) {
              isProductsBoosted = true
            }
          })
        }
        if (numberOfPostsAddedByUser() > 0) {
          allPostsAddedByUser().forEach(post => {
            if (post.boost === true) {
              isProductsBoosted = true
            }
          })
        }
        if (numberOfVideosAddedByUser() > 0) {
          allVideosAddedByUser().forEach(video => {
            if (video.boost === true) {
              isProductsBoosted = true
            }
          })
        }
        if (numberOfWebsAddedByUser() > 0) {
          allWebsAddedByUser().forEach(web => {
            if (web.boost === true) {
              isProductsBoosted = true
            }
          })
        }
        if (numberOfWelcomeAdsAddedByUser() > 0) {
          allWelcomeAdsAddedByUser().forEach(welcomeAd => {
            if (welcomeAd.boost === true) {
              isProductsBoosted = true
            }
          })
        }
        if (numberOfChatChannelsAddedByUser() > 0) {
          allChatChannelsAddedByUser().forEach(chatChannel => {
            if (chatChannel.boost === true) {
              isProductsBoosted = true
            }
          })
        }
        if (numberOfEventsAddedByUser() > 0) {
          allEventsAddedByUser().forEach(event => {
            if (event.boost === true) {
              isProductsBoosted = true
            }
          })
        }
        if (numberOfSurveysAddedByUser() > 0) {
          allSurveysAddedByUser().forEach(survey => {
            if (survey.boost === true) {
              isProductsBoosted = true
            }
          })
        }
        if (numberOfRewardsAddedByUser() > 0) {
          allRewardsAddedByUser().forEach(reward => {
            if (reward.boost === true) {
              isProductsBoosted = true
            }
          })
        }
        if (isProductsBoosted === true) {
          return toast.error('To decrease the amount, first delete all products boosted.')
        }
        return dispatch(decreaseProductQuantityAction('mobileBoost'))
      },

      decreaseContentQuantity(setErrorDecreaseContent) {
        if (numberOfWebsAddedByUser() > 0) {
          setErrorDecreaseContent(true)
          return toast.error('To decrease the amount, first delete all webs added.')
        }
        if (numberOfPostsAddedByUser() > 0) {
          setErrorDecreaseContent(true)
          return toast.error('To decrease the amount, first delete all posts added.')
        }
        if (numberOfVideosAddedByUser() > 0) {
          setErrorDecreaseContent(true)
          return toast.error('To decrease the amount, first delete all videos added.')
        }
        if (numberOfAnnouncementsAddedByUser() > 0) {
          setErrorDecreaseContent(true)
          return toast.error('To decrease the amount, first delete all announcements added.')
        }
        if (numberOfDocumentsAddedByUser() > 0) {
          setErrorDecreaseContent(true)
          return toast.error('To decrease the amount, first delete all documents added.')
        }
        return dispatch(decreaseProductQuantityAction('content'))
      },

      decreaseRewardQuantity(setErrorDecreaseContent) {
        if (numberOfRewardsAddedByUser() > 0) {
          setErrorDecreaseContent(true)
          return toast.error('To decrease the amount, first delete all rewards added.')
        }
        return dispatch(decreaseProductQuantityAction('reward'))
      },

      decreaseWelcomeAdQuantity(setErrorDecreaseContent) {
        if (numberOfWelcomeAdsAddedByUser() > 0) {
          setErrorDecreaseContent(true)
          return toast.error('To decrease the amount, first delete all welcome ads added.')
        }
        return dispatch(decreaseProductQuantityAction('welcomeAd'))
      },

      decreaseEventQuantity(setErrorDecreaseContent) {
        if (numberOfEventsAddedByUser() > 0) {
          setErrorDecreaseContent(true)
          return toast.error('To decrease the amount, first delete all events added.')
        }
        return dispatch(decreaseProductQuantityAction('event'))
      },

      getSumOfAllContentsAddedByUser() {
        return (
          numberOfWebsAddedByUser() +
          numberOfPostsAddedByUser() +
          numberOfVideosAddedByUser() +
          numberOfAnnouncementsAddedByUser() +
          numberOfDocumentsAddedByUser()
        )
      },

      getHowManyContentsLeftForAdd() {
        const getSumOfAllContentsAddedByUser =
          numberOfWebsAddedByUser() +
          numberOfPostsAddedByUser() +
          numberOfVideosAddedByUser() +
          numberOfAnnouncementsAddedByUser() +
          numberOfDocumentsAddedByUser()
        const contentLeftForAdd = contentQuantity() - getSumOfAllContentsAddedByUser
        if (contentLeftForAdd < 0) {
          return 0
        }
        return contentLeftForAdd
      },

      checkIfThereAtLeastOneProductAdded() {
        const sumOfAllProducts =
          contentQuantity() +
          rewardQuantity() +
          welcomeAdQuantity() +
          surveyQuantity() +
          eventQuantity() +
          chatChannelQuantity()
        return sumOfAllProducts
      },

      getHowManySurveysLeftForAdd() {
        const surveysLeftForAdd = surveyQuantity() - numberOfSurveysAddedByUser()
        if (surveysLeftForAdd < 0) {
          return 0
        }
        return surveysLeftForAdd
      },

      getHowManyRewardsLeftForAdd() {
        const rewardsLeftForAdd = rewardQuantity() - numberOfRewardsAddedByUser()
        if (rewardsLeftForAdd < 0) {
          return 0
        }
        return rewardsLeftForAdd
      },

      getHowManyWelcomeAdsLeftForAdd() {
        const welcomeAdsLeftForAdd = welcomeAdQuantity() - numberOfWelcomeAdsAddedByUser()
        if (welcomeAdsLeftForAdd < 0) {
          return 0
        }
        return welcomeAdsLeftForAdd
      },

      getHowManyChatChannelsLeftForAdd() {
        const chatChannelsLeftForAdd = chatChannelQuantity() - numberOfChatChannelsAddedByUser()
        if (chatChannelsLeftForAdd < 0) {
          return 0
        }
        return chatChannelsLeftForAdd
      },

      getHowManyEventsLeftForAdd() {
        const eventsLeftForAdd = eventQuantity() - numberOfEventsAddedByUser()
        if (eventsLeftForAdd < 0) {
          return 0
        }
        return eventsLeftForAdd
      },

      allProductsQuantityAddedByTheUser() {
        return (
          contentQuantity() +
          rewardQuantity() +
          welcomeAdQuantity() +
          surveyQuantity() +
          chatChannelQuantity() +
          eventQuantity()
        )
      },

      allProductsAddedByTheUser() {
        return (
          numberOfWebsAddedByUser() +
          numberOfPostsAddedByUser() +
          numberOfVideosAddedByUser() +
          numberOfAnnouncementsAddedByUser() +
          numberOfDocumentsAddedByUser() +
          numberOfSurveysAddedByUser() +
          numberOfEventsAddedByUser() +
          numberOfRewardsAddedByUser() +
          numberOfWelcomeAdsAddedByUser() +
          numberOfChatChannelsAddedByUser()
        )
      },
    }),
    [
      dispatch,
      allSurveysAddedByUser,
      numberOfChatChannelsAddedByUser,
      numberOfSurveysAddedByUser,
      numberOfDocumentsAddedByUser,
      numberOfPostsAddedByUser,
      numberOfVideosAddedByUser,
      numberOfWebsAddedByUser,
      numberOfWelcomeAdsAddedByUser,
      numberOfEventsAddedByUser,
      numberOfRewardsAddedByUser,
      allDocumentsAddedByUser,
      allPostsAddedByUser,
      allVideosAddedByUser,
      allWebsAddedByUser,
      allWelcomeAdsAddedByUser,
      allChatChannelsAddedByUser,
      allEventsAddedByUser,
      allRewardsAddedByUser,
      numberOfAnnouncementsAddedByUser,
      contentQuantity,
      rewardQuantity,
      welcomeAdQuantity,
      surveyQuantity,
      eventQuantity,
      chatChannelQuantity,
    ],
  )
  return actions
}

export default useSponsorFunctions
