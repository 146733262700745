import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import WhiteButton from '~/components/WhiteButton'

export const ModalCustom = styled(Modal)`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 600px;
      height: auto;
      margin: auto;
      padding: 0;

      position: relative;
      top: 30%;
    }
    p {
      margin: 0;
    }
  }
  .modal-content {
    padding-top: 200px;
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
`

export const ModalTitle = styled.p`
  color: #404257;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  opacity: 0.9;
`

export const NotNow = styled.p`
  color: #969faa;
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

export const InputTitle = styled.span`
  margin-top: 3px;
  color: #404257;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
`

export const FormContainer = styled.div`
  margin-top: 10px;
  padding: 0px 20px;
`

export const Form = styled.form.attrs({
  id: 'my-form',
})``

export const ErrorMessageContainer = styled.div.attrs({
  className: 'error-message',
})``

export const SendMesssageButtonContainer = styled.div`
  margin-top: 20px;
`

export const SendMessageButton = styled(WhiteButton).attrs({
  form: 'my-form',
  type: 'submit',
})``

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end !important;
  align-items: baseline;
  margin-bottom: 4px;
  padding-top: 14px;
`
