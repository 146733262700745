import styled from 'styled-components'

export const CardBody = styled.div`
  display: flex;
  position: relative;
  background: linear-gradient(to right, ${props => props.color} 0%, ${props => props.color} 32%, #fff 32%, #fff 100%);
  border-radius: 5px;
  width: 132px;
  height: 50px;
  border: 1px solid #e8ebef;
`

export const CardIcon = styled.div`
  margin-left: 8px;
  margin-top: 10px;
`

export const CardText = styled.span`
  padding-left: 14px;
  padding-top: 5px;
  text-transform: uppercase;
  color: #47525e;
  font-family: Lato;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  display: block;
`

export const CardNumber = styled.span`
  position: absolute;
  color: #5a6978;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  right: 10px;
  bottom: 8px;
`
