import React from 'react'
import Preview from '~/components/Chat/Preview'

const UrlPreview = ({ content, linkWithoutContent, message }) => {
  const isVideo = ['youtube', 'vimeo'].includes(content && content.providerName && content.providerName.toLowerCase())

  return (
    <Preview
      image={(content && content.image && content.image.url) || (content && content.favicon)}
      isVideo={isVideo}
      title={(content && content.title) || (content && content.providerName)}
      description={content && content.providerDisplay}
      interests={content && content.interests}
      content={content}
      linkWithoutContent={linkWithoutContent}
      message={message}
    />
  )
}

export default UrlPreview
