import styled from 'styled-components'
import { breakpoints } from '~/helper/breakpoints'

export const Container = styled.div`
  margin-top: 8rem;
  width: 100vw;
  padding: 0rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: #18181a;
    text-align: center;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.16;
  }

  gap: 1.5rem;

  @media ${breakpoints.mobile} {
    padding: 0rem 1.25rem;

    margin-top: 4rem;

    h1 {
      font-size: 1.5rem;
      line-height: 116%;
    }
  }
`
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => (columns === 3 ? '1fr 1fr 1fr' : '1fr 1fr')};
  gap: 16px;

  @media ${breakpoints.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`
