import React, { useState } from 'react'
import { useAsync } from 'react-async'
import { useSelector } from 'react-redux'
import JoyRide from 'react-joyride'
import { useTranslation } from 'react-i18next'
import SearchInput from '~/components/SearchInput'
import CommunityTabCard from '~/components/Sponsor/CommunitiesTab/CommunityTabCard'
import TabTitleAndSubtitle from '~/components/Community/TabTitleAndSubtitle'
import ModalAddCommunity from '~/components/Sponsor/Modals/ModalAddCommunity'
import CommunitiesTabTooltip from '~/components/ApplicationTutorial//Sponsor/CommunitiesTabTooltip'
import { getAllCommunitiesFromSponsor } from '~/services/requests'
import CustomSpinner from '~/components/CustomSpinner'
import StartNewCampaignModal from '~/components/Sponsor/Modals/StartNewCampaignModal'
import { AddIcon, SponsorGridTitle } from '~/components/Shared/StyledComponents'
import { returnCommunitiesDataFiltered } from '~/helper/formFunctions'
import ZeroRegisterIndicator from '~/components/ZeroRegisterIndicator'
import * as CONST from '~/helper/const'
import StartCampaignButton from '../../StartCampaignButton/StartCampaignButton'
import * as S from './styled'

export default function CommunityTab() {
  const { t } = useTranslation()
  const tutorialIsActive = useSelector(state => state.tutorial.tutorialIsActive)
  const [showModalAddCommunity, setShowModalAddCommunity] = useState(false)
  const [showModalCreateNewCampaign, setShowModalCreateNewCampaign] = useState(false)
  const { data, error, isPending } = useAsync({ promiseFn: getAllCommunitiesFromSponsor })
  const [filter, setFilter] = useState('')

  if (error) throw new Error(error)
  if (isPending) return <CustomSpinner />

  if (data) {
    const filteredCommunities = returnCommunitiesDataFiltered(filter, data)
    return (
      <S.Container>
        <JoyRide
          steps={CONST.sponsorCommunitiesTooltipOptions}
          run={tutorialIsActive}
          tooltipComponent={CommunitiesTabTooltip}
          styles={{
            options: {
              arrowColor: 'transparent',
            },
          }}
        />
        <TabTitleAndSubtitle sponsor title={t('communities')} subtitle={t('manageSponsoring')} />
        <S.SearchInputContainer>
          <S.SearchInputButtonContainer>
            <SearchInput filter={filter} setFilter={setFilter} />
            <S.ButtonContainer>
              <S.IncludeNewButton
                text={t('addNewCommunity')}
                icon={<AddIcon />}
                onClick={() => setShowModalAddCommunity(true)}
              />
              <StartCampaignButton style={{ marginLeft: '26px' }} onClick={() => setShowModalCreateNewCampaign(true)} />
            </S.ButtonContainer>
          </S.SearchInputButtonContainer>
        </S.SearchInputContainer>
        <S.CampaignsNeedingRevisionContainer>
          <S.GridTitleContainer>
            <S.NameColumn>
              <SponsorGridTitle row>{t('community')}</SponsorGridTitle>
            </S.NameColumn>
            <S.ActiveCampaignsColumn>
              <SponsorGridTitle row>{t('activeCampaigns')}</SponsorGridTitle>
            </S.ActiveCampaignsColumn>
            <S.BudgetInvestedColumn>
              <SponsorGridTitle row>{t('budgetInvested')}</SponsorGridTitle>
            </S.BudgetInvestedColumn>
            <S.CommunityProfileColumn>
              <SponsorGridTitle row>{t('communityProfile')}</SponsorGridTitle>
            </S.CommunityProfileColumn>
            <S.StatusColumn>
              <SponsorGridTitle row>{t('status')}</SponsorGridTitle>
            </S.StatusColumn>
          </S.GridTitleContainer>
          {filteredCommunities.map(community => (
            <CommunityTabCard key={community.id} community={community} />
          ))}
          {filteredCommunities?.length === 0 && <ZeroRegisterIndicator text={t('noCommunitiesFound')} />}
        </S.CampaignsNeedingRevisionContainer>
        <ModalAddCommunity handleCloseModal={() => setShowModalAddCommunity(false)} showModal={showModalAddCommunity} />

        <StartNewCampaignModal
          handleCloseModal={() => setShowModalCreateNewCampaign(false)}
          showModal={showModalCreateNewCampaign}
        />
      </S.Container>
    )
  }
}
