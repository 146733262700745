import * as actions from './actionTypes'

export function sponsorDeleteCampaignRequest(campaignId) {
  return {
    type: actions.SPONSOR_DELETE_CAMPAIGN_REQUEST,
    payload: campaignId,
  }
}

export function setCampaignForReview(campaign) {
  return {
    type: actions.SET_CAMPAIGN_FOR_REVIEW,
    payload: campaign,
  }
}

export function setCampaignForUpdate(campaign) {
  return {
    type: actions.SET_CAMPAIGN_FOR_UPDATE,
    payload: campaign,
  }
}

export function setCampaignCurrentSelectedMonth(month) {
  return {
    type: actions.SET_CAMPAIGN_CURRENT_SELECTED_MONTH,
    payload: month,
  }
}

export function setCampaignCurrentSelectedYear(year) {
  return {
    type: actions.SET_CAMPAIGN_CURRENT_SELECTED_YEAR,
    payload: year,
  }
}

export function refuseCampaign(networkId, campaignId) {
  return {
    type: actions.REFUSE_CAMPAIGN,
    payload: { networkId, campaignId },
  }
}

export function approveCampaign(networkId, campaignId) {
  return {
    type: actions.APPROVE_CAMPAIGN,
    payload: { networkId, campaignId },
  }
}

export function stopCampaign(networkId, campaignId) {
  return {
    type: actions.STOP_CAMPAIGN,
    payload: { networkId, campaignId },
  }
}

export function getCampaignTargetFilterSuccess(campaignTarget) {
  return {
    type: actions.GET_CAMPAIGN_TARGET_FILTER_SUCCESS,
    payload: campaignTarget,
  }
}

// In step1, when the user clicks in one community card to start a campaign for that
// particular community, this function will get the community informations and save in the reducer
export function setCurrentCommunityForCreateCampaignProcess(community) {
  return {
    type: actions.SET_CURRENT_COMMUNITY_FOR_CREATE_CAMPAIGN_PROCESS,
    payload: community,
  }
}

export function setLoadingStripePaymentIntent(status) {
  return {
    type: actions.SET_LOADING_STRIPE_PAYMENT_INTENT,
    payload: status,
  }
}

export function addCampaignInformations(data) {
  return {
    type: actions.ADD_CAMPAIGN_INFORMATIONS,
    payload: data,
  }
}

export function addProduct(productType, product, surveyId) {
  switch (productType) {
    case 'survey_initial_informations':
      return {
        type: actions.ADD_SURVEY_INITIAL_INFORMATIONS,
        payload: product,
      }

    case 'survey':
      return {
        type: actions.ADD_SURVEY,
        payload: product,
      }

    case 'multiple_survey':
      return {
        type: actions.ADD_SURVEY_MULTIPLE_QUESTIONS,
        payload: { product, surveyId },
      }

    case 'welcomeAd':
      return {
        type: actions.ADD_WELCOME_AD,
        payload: product,
      }

    case 'reward':
      return {
        type: actions.ADD_REWARD,
        payload: product,
      }

    case 'chatChannel':
      return {
        type: actions.ADD_CHAT_CHANNEL,
        payload: product,
      }

    case 'event':
      return {
        type: actions.ADD_EVENT,
        payload: product,
      }

    case 'content/video':
      return {
        type: actions.ADD_VIDEO,
        payload: product,
      }

    case 'content/post':
      return {
        type: actions.ADD_POST,
        payload: product,
      }

    case 'content/web':
      return {
        type: actions.ADD_WEB,
        payload: product,
      }

    case 'content/announcement':
      return {
        type: actions.ADD_ANNOUNCEMENT,
        payload: product,
      }

    case 'content/document':
      return {
        type: actions.ADD_DOCUMENT,
        payload: product,
      }

    default:
      return null
  }
}

export function editProduct(productType, product, surveyId) {
  switch (productType) {
    case 'survey':
      return {
        type: actions.EDIT_SURVEY,
        payload: product,
      }

    case 'multiple_survey':
      return {
        type: actions.EDIT_SURVEY_MULTIPLE_QUESTIONS,
        payload: { product, surveyId },
      }

    case 'survey_initial_informations':
      return {
        type: actions.EDIT_SURVEY_INITIAL_INFORMATIONS,
        payload: product,
      }

    case 'welcomeAd':
      return {
        type: actions.EDIT_WELCOME_AD,
        payload: product,
      }

    case 'reward':
      return {
        type: actions.EDIT_REWARD,
        payload: product,
      }

    case 'chatChannel':
      return {
        type: actions.EDIT_CHAT_CHANNEL,
        payload: product,
      }

    case 'event':
      return {
        type: actions.EDIT_EVENT,
        payload: product,
      }

    case 'content/video':
      return {
        type: actions.EDIT_VIDEO,
        payload: product,
      }

    case 'content/post':
      return {
        type: actions.EDIT_POST,
        payload: product,
      }

    case 'content/web':
      return {
        type: actions.EDIT_WEB,
        payload: product,
      }

    case 'content/announcement':
      return {
        type: actions.EDIT_ANNOUNCEMENT,
        payload: product,
      }

    case 'content/document':
      return {
        type: actions.EDIT_DOCUMENT,
        payload: product,
      }

    default:
      return null
  }
}

// receives a product type (survey, reward) and the productId
// call action to delete the product
export function deleteProduct(productType, productId) {
  switch (productType) {
    case 'survey':
      return {
        type: actions.DELETE_SURVEY,
        payload: productId,
      }

    case 'multiple_survey':
      return {
        type: actions.DELETE_SURVEY_MULTIPLE_QUESTIONS,
        payload: productId,
      }

    case 'welcomeAd':
      return {
        type: actions.DELETE_WELCOME_AD,
        payload: productId,
      }

    case 'reward':
      return {
        type: actions.DELETE_REWARD,
        payload: productId,
      }

    case 'chatChannel':
      return {
        type: actions.DELETE_CHAT_CHANNEL,
        payload: productId,
      }

    case 'event':
      return {
        type: actions.DELETE_EVENT,
        payload: productId,
      }

    case 'content/video':
      return {
        type: actions.DELETE_VIDEO,
        payload: productId,
      }

    case 'content/post':
      return {
        type: actions.DELETE_POST,
        payload: productId,
      }

    case 'content/web':
      return {
        type: actions.DELETE_WEB,
        payload: productId,
      }

    case 'content/announcement':
      return {
        type: actions.DELETE_ANNOUNCEMENT,
        payload: productId,
      }

    case 'content/document':
      return {
        type: actions.DELETE_DOCUMENT,
        payload: productId,
      }

    default:
      return null
  }
}

export function increaseProductQuantity(productType) {
  switch (productType) {
    case 'survey':
      return {
        type: actions.INCREASE_SURVEY_QUANTITY_PRODUCT,
      }

    case 'welcomeAd':
      return {
        type: actions.INCREASE_WELCOME_AD_QUANTITY_PRODUCT,
      }

    case 'reward':
      return {
        type: actions.INCREASE_REWARD_QUANTITY_PRODUCT,
      }

    case 'chatChannel':
      return {
        type: actions.INCREASE_CHAT_CHANNEL_QUANTITY_PRODUCT,
      }

    case 'event':
      return {
        type: actions.INCREASE_EVENT_QUANTITY_PRODUCT,
      }

    case 'mobileBoost':
      return {
        type: actions.INCREASE_MOBILE_BOOST_QUANTITY_PRODUCT,
      }

    case 'content':
      return {
        type: actions.INCREASE_CONTENT_QUANTITY_PRODUCT,
      }

    default:
      return null
  }
}

// receives a product type (survey, reward) and
// call action to decrease quantity of the product
export function decreaseProductQuantity(productType) {
  switch (productType) {
    case 'survey':
      return {
        type: actions.DECREASE_SURVEY_QUANTITY_PRODUCT,
      }

    case 'welcomeAd':
      return {
        type: actions.DECREASE_WELCOME_AD_QUANTITY_PRODUCT,
      }

    case 'reward':
      return {
        type: actions.DECREASE_REWARD_QUANTITY_PRODUCT,
      }

    case 'chatChannel':
      return {
        type: actions.DECREASE_CHAT_CHANNEL_QUANTITY_PRODUCT,
      }

    case 'event':
      return {
        type: actions.DECREASE_EVENT_QUANTITY_PRODUCT,
      }

    case 'mobileBoost':
      return {
        type: actions.DECREASE_MOBILE_BOOST_QUANTITY_PRODUCT,
      }

    case 'content':
      return {
        type: actions.DECREASE_CONTENT_QUANTITY_PRODUCT,
      }

    default:
      return null
  }
}

export function resetCampaignProcess() {
  return {
    type: actions.RESET_CAMPAIGN_PROCESS,
  }
}

export function setProductInformations(productType, product) {
  return {
    type: actions.SET_PRODUCT_INFORMATIONS,
    payload: { productType, product },
  }
}

export function deleteAllContent() {
  return {
    type: actions.DELETE_ALL_CONTENT,
  }
}

export function deleteAllRewards() {
  return {
    type: actions.DELETE_ALL_REWARDS,
  }
}

export function deleteAllSurveys() {
  return {
    type: actions.DELETE_ALL_SURVEYS,
  }
}

export function deleteAllEvents() {
  return {
    type: actions.DELETE_ALL_EVENTS,
  }
}

export function deleteAllChatChannels() {
  return {
    type: actions.DELETE_ALL_CHAT_CHANNELS,
  }
}

export function deleteAllWelcomeAds() {
  return {
    type: actions.DELETE_ALL_WELCOME_ADS,
  }
}

export function decreaseMobileBoostQuantity(quantityToDecrease) {
  return {
    type: actions.DECREASE_MOBILE_BOOST_QUANTITY,
    payload: { quantityToDecrease },
  }
}

export function setCampaignRating(entity, campaignId, rating, note) {
  return {
    type: actions.SET_CAMPAIGN_RATING,
    payload: { entity, campaignId, rating, note },
  }
}

export function stripeUserAuthorizationCode(code, networkId) {
  return {
    type: actions.STRIPE_USER_AUTHORIZATION_CODE,
    payload: { code, networkId },
  }
}

export function stripeCreatePaymentIntentRequest(communityId, campaignId, campaignProducts) {
  return {
    type: actions.STRIPE_CREATE_PAYMENT_INTENT_REQUEST,
    payload: { communityId, campaignId, campaignProducts },
  }
}

export function stripeCreatePaymentIntentSuccess(clientSecret) {
  return {
    type: actions.STRIPE_CREATE_PAYMENT_INTENT_SUCCESS,
    payload: clientSecret,
  }
}

export function setTemporaryCampaignIdForStripePaymentIntentCreation() {
  return {
    type: actions.SET_TEMPORARY_CAMPAIGN_ID_FOR_STRIPE_PAYMENT_INTENT_CREATION,
  }
}

export function stripeUpdatePaymentIntentRequest(campaignId, campaignProducts) {
  return {
    type: actions.STRIPE_UPDATE_PAYMENT_INTENT_REQUEST,
    payload: { campaignId, campaignProducts },
  }
}

export function setCurrentCampaignBeingUpdated(campaign) {
  return {
    type: actions.SET_CURRENT_CAMPAIGN_BEING_UPDATED,
    payload: campaign,
  }
}

export function setCampaignTotalPrice(totalPrice) {
  return {
    type: actions.SET_CAMPAIGN_TOTAL_PRICE,
    payload: totalPrice,
  }
}

export function createCampaignWithPackage(communityId, packageInfo) {
  return {
    type: actions.CREATE_CAMPAIGN_WITH_PACKAGE,
    payload: { communityId, packageInfo },
  }
}

export function createCampaignPayAsYouGo(communityId, campaignInfo) {
  return {
    type: actions.CREATE_CAMPAIGN_PAY_AS_YOU_GO,
    payload: { communityId, campaignInfo },
  }
}

export function resetStripeClientSecret() {
  return {
    type: actions.RESET_STRIPE_CLIENT_SECRET,
  }
}

export function createPaymentIntentPayAsYouGo(communityId, uuid, campaignProducts, campaignDuration, paymentOption) {
  return {
    type: actions.CREATE_PAYMENT_INTENT_PAY_AS_YOU_GO,
    payload: { communityId, uuid, campaignProducts, campaignDuration, paymentOption },
  }
}

export function createPaymentIntentPackage(communityId, packageId, uuid, campaignDuration, paymentOption) {
  return {
    type: actions.CREATE_PAYMENT_INTENT_PACKAGE,
    payload: { communityId, packageId, uuid, campaignDuration, paymentOption },
  }
}

export function createCampaignFormat(campaignId, campaignProductId, format, contentType, closeModal, setLoading) {
  return {
    type: actions.CREATE_CAMPAIGN_FORMAT,
    payload: { campaignId, campaignProductId, format, contentType, closeModal, setLoading },
  }
}

export function updateCampaignFormat(format, formatId, closeModal, setLoading) {
  return {
    type: actions.UPDATE_CAMPAIGN_FORMAT,
    payload: { format, formatId, closeModal, setLoading },
  }
}

export function setCurrentCampaignForCreateFormat(campaign) {
  return {
    type: actions.SET_CURRENT_CAMPAIGN_FOR_CREATE_FORMAT,
    payload: { campaign },
  }
}

export function setCampaignProductsStatus(products, communityId, campaignId, status, setLoading) {
  return {
    type: actions.SET_CAMPAIGN_PRODUCTS_STATUS,
    payload: { products, communityId, campaignId, status, setLoading },
  }
}

export function addSurveyToArray(survey) {
  return {
    type: actions.ADD_SURVEY_TO_ARRAY,
    payload: { survey },
  }
}

export function removeSurveysFromArray() {
  return {
    type: actions.REMOVE_SURVEYS_FROM_ARRAY,
  }
}

export function setCurrentCampaignForReviewPage(campaign) {
  return {
    type: actions.SET_CURRENT_CAMPAIGN_FOR_REVIEW_PAGE,
    payload: { campaign },
  }
}

export function setShowCampaignReviewPage(status) {
  return {
    type: actions.SET_SHOW_CAMPAIGN_REVIEW_PAGE,
    payload: { status },
  }
}

export function setCampaignsWaitingForApprovalLength(length) {
  return {
    type: actions.SET_CAMPAIGNS_WAITING_FOR_APPROVAL_LENGTH,
    payload: { length },
  }
}

export function openInformationModalAfterFormatCreation() {
  return {
    type: actions.OPEN_INFORMATION_MODAL_AFTER_FORMAT_CREATION,
  }
}

export function closeInformationModalAfterFormatCreation() {
  return {
    type: actions.CLOSE_INFORMATION_MODAL_AFTER_FORMAT_CREATION,
  }
}

export function setCommunitySponsorDirectChannelId(channelId) {
  return {
    type: actions.SET_COMMUNITY_SPONSOR_DIRECT_CHANNEL_ID,
    payload: { channelId },
  }
}

export function setCampaignDatesForCurrentMonth(startDate, endDate) {
  return {
    type: actions.SET_CAMPAIGN_DATES_FOR_CURRENT_MONTH,
    payload: { startDate, endDate },
  }
}

export function setCallbackUpdateCampaign(value) {
  return {
    type: actions.SET_CALLBACK_UPDATE_CAMPAIGN,
    payload: { value },
  }
}
