import React from 'react'
import * as S from './styled'

export default function SocialMediaButtons({ youtube, facebook, twitter, website }) {
  const validateLink = str => {
    const link = str
    if (link.indexOf('https://') === 0) {
      return link
    }
    return `https://${link}`
  }

  return (
    <S.SocialMediaContainer>
      {youtube !== null && youtube !== '' && youtube !== undefined ? (
        <S.SocialMediaLink href={validateLink(youtube)} target="_blank" rel="noopener noreferrer">
          <S.SocialMediaImage className="youtube-icon" src="/youtube.png" alt="Youtube" />
        </S.SocialMediaLink>
      ) : null}

      {twitter !== null && twitter !== '' && twitter !== undefined ? (
        <S.SocialMediaLink href={validateLink(twitter)} target="_blank" rel="noopener noreferrer">
          <S.SocialMediaImage src="/twitter-logo.svg" alt="Twitter" />
        </S.SocialMediaLink>
      ) : null}

      {facebook !== null && facebook !== '' && facebook !== undefined ? (
        <S.SocialMediaLink href={validateLink(facebook)} target="_blank" rel="noopener noreferrer">
          <S.SocialMediaImage src="/facebook.png" alt="Facebook" />
        </S.SocialMediaLink>
      ) : null}

      {website !== null && website !== '' && website !== undefined ? (
        <S.SocialMediaLink href={validateLink(website)} target="_blank" rel="noopener noreferrer">
          <S.SocialMediaImage src="/geowebsite.png" alt="Website" />
        </S.SocialMediaLink>
      ) : null}
    </S.SocialMediaContainer>
  )
}
