import styled from 'styled-components'

export const Container = styled.div``

export const CircularProgressBarContainer = styled.div`
  width: 98px;
  height: 98px;
`

export const InterestPercentage = styled.p`
  margin: 0px;
  margin-top: 8px;
  color: #635b5b;
  font-family: Lato;
  font-size: 13px;
  width: 97px;
  text-align: center;
`

export const TextInsideCircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextInsideCircle = styled.p`
  margin: 0px;
  color: #5a6978;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  opacity: 0.8;
  width: 74px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`
