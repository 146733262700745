import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import UserHeaderProfile from '~/components/Shared/UserHeaderProfile'
import GenericPopover from '~/components/Shared/GenericPopover'
import { signOut as signOutAction } from '~/store/modules/auth/actions'
import { DefaultUserProfileImage } from '~/util/defaultImages'
import * as S from './styled'

export default function Header({ userInformations, entity, openModalUserProfile }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [popoverAnchor, setPopoverAnchor] = useState(null)

  const logout = () => {
    dispatch(signOutAction())
  }

  const handleClickPopover = event => {
    setPopoverAnchor(event.currentTarget)
  }

  return (
    <S.HeaderContainer entity={entity}>
      <S.HeaderRow>
        <S.LogoColumn>
          <S.LogoContainer>
            <S.LogoImage />
          </S.LogoContainer>
        </S.LogoColumn>
        <S.UserProfileColumn>
          <S.CommunityContainer>
            <S.ImageContainer data-cy="user-profile">
              <S.NetworkManagerAvatar
                onClick={handleClickPopover}
                src={userInformations?.userImageUrl || DefaultUserProfileImage}
              />
              <S.MeSection onClick={handleClickPopover}>
                <S.User>{t('me')}</S.User>
                <S.NetworkManagerArrowDown />
              </S.MeSection>
            </S.ImageContainer>
            <GenericPopover
              popoverAnchor={popoverAnchor}
              setPopoverAnchor={setPopoverAnchor}
              content={
                <UserHeaderProfile
                  logoutAction={logout}
                  signInAs={entity}
                  userInformations={userInformations}
                  closePopover={() => setPopoverAnchor(null)}
                  openModalUserProfile={openModalUserProfile}
                />
              }
            />
          </S.CommunityContainer>
        </S.UserProfileColumn>
      </S.HeaderRow>
    </S.HeaderContainer>
  )
}
