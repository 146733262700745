import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'
import { darken } from 'polished'
import TextField from '@material-ui/core/TextField'
import Button from '~/components/Shared/Button'

export const CampaignMetadataWrapper = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  gap: 16px;
`

export const CampaignDiscount = styled.div`
  color: #4f5559;
  font-family: Lato;
  font-size: 14px;
`

export const CampaignDiscountContainer = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CampaignDiscountTotalContainer = styled.div`
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CampaignTotal = styled.div`
  color: #4f5559;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
`

export const CommunityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`

export const CommunityHeaderInformations = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 8px;
`

export const CommunityInformationsContainer = styled.div`
  display: flex;
`

export const CommunityLocation = styled.p`
  margin: 0px;
  color: #827f8e;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  text-decoration: underline;
`

export const CommunityLogo = styled.img`
  border-radius: 100px;
  width: 60px;
  height: 60px;
`

export const CompanyName = styled.div`
  word-break: normal;
  font-size: 18px;
  font-weight: 700;
  color: #222432;
  font-family: Open Sans;
  font-size: 23px;
`

export const ConfirmCampaignButton = styled(Button).attrs({
  style: { width: '50%' },
  color: '#00A2FF',
  type: 'submit',
  textColor: '#fff',
  height: '40px',
  fontSize: '16px',
})``

export const ConfirmCampaignMockedButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 40px;
  background-color: var(--sponsor-primary);
  border-radius: 5px;
  margin-top: 20px;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    background-color: ${darken(0.05, '#00A2FF')};
  }
`

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
`

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``

export const PackageCampaignTotalContainer = styled.div`
  margin-top: 6px;
`

export const PayAsYouGoCampaignTotalContainer = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SpinnerContainer = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`

export const FormContainer = styled.div``

export const Form = styled.form.attrs({
  id: 'my-form',
})``

export const FormTitle = styled.p`
  margin: 0px;
  color: var(--input-title);
  font-family: Lato;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
`

export const ProductTotalAndFormatsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 12px;
`

export const CampaignTotalPrice = styled.p`
  margin: 0px;
  color: var(--sponsor-primary);
  font-family: Lato;
  font-size: 21px;
  text-align: left;
  font-weight: bold;
`

export const PackageTotalPrice = styled(CampaignTotalPrice)``

export const PackageDiscountPrice = styled.p`
  margin-right: 8px;
  color: var(--error);
  font-family: Lato;
  font-size: 21px;
  text-decoration: line-through;
  font-weight: bold;
  margin-bottom: 0px;
`

export const FormatsSelected = styled.p`
  margin: 0px;
  font-family: Inter;
  font-size: 24px;
  text-align: left;
  margin-left: 6px;
`

export const FormSectionContainer = styled.div`
  display: flex;

  align-items: flex-start;
  justify-content: center;

  flex-direction: column;

  width: 100%;
  margin-top: 16px;

  .select-campaign-type {
    width: 100%;
  }
`

export const FormSectionPackageContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
`

export const InputTitle = styled.span`
  color: var(--input-title);
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
`

export const CampaignTypeContainer = styled.div`
  margin-top: 16px;
`

export const ErrorMessageContainer = styled.div.attrs({
  className: 'error-message',
})``

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;

  margin-top: 10px;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 20px;

  width: 100%;
`

export const InputTextField = styled(TextField).attrs({
  variant: 'outlined',
  fullWidth: true,
})``

export const CommunitySectionIcon = styled.img.attrs({
  className: 'animated fadeIn',
  alt: 'Gallery icon',
  src: '/icons/raster.png',
})`
  margin-right: 8px;
  width: 13px;
  height: 13px;
`

export const TitleWrapper = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;

  margin-top: 10px;
  border-bottom-width: 1px;
  border-bottom-color: rgba(214, 214, 214, 0.3);
  border-bottom-style: solid;
  padding-bottom: 12px;
`
