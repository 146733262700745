import React from 'react'
import { MdAttachMoney, MdGroup, MdOutlineAnalytics, MdCampaign } from 'react-icons/md'
import i18n from '~/i18n'

export default function SponsorTabs(currentTabActive) {
  return [
    {
      icon:
        currentTabActive === 'communities' ? <MdGroup color="#00A2FF" size={27} /> : <MdGroup color="#FFF" size={27} />,
      title: i18n.t('communities'),
      slug: 'communities',
      className: 'communitiesTab',
    },
    {
      icon:
        currentTabActive === 'campaigns' ? (
          <MdCampaign color="#00A2FF" size={27} />
        ) : (
          <MdCampaign color="#FFF" size={27} />
        ),
      title: i18n.t('campaigns'),
      slug: 'campaigns',
      spacing: true,
      className: 'campaignsTab',
    },
    {
      icon:
        currentTabActive === 'payments' ? (
          <MdAttachMoney color="#00A2FF" size={27} />
        ) : (
          <MdAttachMoney color="#FFF" size={27} />
        ),
      title: i18n.t('payments'),
      slug: 'payments',
      spacing: true,
      className: 'paymentsTab',
    },
    {
      icon:
        currentTabActive === 'analytics' ? (
          <MdOutlineAnalytics color="#00A2FF" size={27} />
        ) : (
          <MdOutlineAnalytics color="#FFF" size={27} />
        ),
      title: i18n.t('analytics'),
      slug: 'analytics',
      spacing: true,
      className: 'analyticsTab',
    },
  ]
}
