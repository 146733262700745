import styled from 'styled-components'

export const Container = styled.div.attrs({
  className: 'animated fadeIn',
})`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const CommunityCardContainer = styled.div`
  margin: 14px 12px 0px 12px;
`

export const NoCommunitiesAvailableMessage = styled.p`
  color: #87919a;
  font-family: Montserrat;
  margin: 0;
  padding-top: 20px;
  font-size: 18px;
  font-weight: 400;
`
