const getEnv = () => {
  const apiURL = process.env.REACT_APP_BASE_API

  const isStaging = apiURL.includes('sandbox')

  const env = isStaging ? 'staging' : 'production'

  return env
}

export default getEnv
