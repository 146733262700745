import styled from 'styled-components'
import { BiVideo } from 'react-icons/bi'
import { MdAttachFile } from 'react-icons/md'
import { BsFillMicFill, BsCardImage } from 'react-icons/bs'
import { FaRegStopCircle, FaRegSmile } from 'react-icons/fa'
import { AiOutlineSend, AiOutlineGif } from 'react-icons/ai'
import { FiAtSign } from 'react-icons/fi'
import { IoMdAddCircleOutline, IoMdCloseCircleOutline } from 'react-icons/io'
import { CgHashtag } from 'react-icons/cg'

export const Form = styled.form`
  transition: all 300ms ease;
  position: relative;
  margin-top: 5px;

  input {
    box-sizing: border-box;
    border: none;
    border-radius: 0;
    padding: 7px 0px;
    margin-left: 8px;
    font-family: Roboto;
    font-size: 14px;
    width: 205px;
    padding-left: 12px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    background-color: #f2f2f2;
    border-radius: 20px;
    -webkit-transition: right width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
    position: absolute;
    right: 30px;
    top: 0px;

    &.full {
      width: 80%;
      right: 35px;
      -webkit-transition: right width 0.5s ease-in-out;
      transition: width 0.5s ease-in-out;
    }

    &.interestsIsOpen {
      width: 80%;
      right: 35px;
      -webkit-transition: right width 0.5s ease-in-out;
      transition: width 0.5s ease-in-out;
    }

    &.inputWithMedia {
      width: 65%;
      right: 35px;
      -webkit-transition: right width 0.5s ease-in-out;
      transition: width 0.5s ease-in-out;
    }
  }

  input:disabled {
    background-color: #ffff;
  }

  img {
    cursor: pointer;
  }

  .audio-react-recorder {
    background: #fff;
  }
`

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const SendMessageIcon = styled(AiOutlineSend).attrs({
  size: 26,
})`
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 5px;
`

export const AudioStartRecordingIcon = styled(BsFillMicFill).attrs({
  alt: 'Audio',
  size: 22,
})`
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 5px;
`

export const AudioStopIcon = styled(FaRegStopCircle).attrs({
  alt: 'Audio stop',
  size: 22,
})`
  cursor: pointer;
  margin-left: 6px;
`

export const CancelRecordIcon = styled(IoMdCloseCircleOutline).attrs({
  size: 26,
})`
  cursor: pointer;
  margin-left: 5px;
`

export const InterestIcon = styled(CgHashtag).attrs({
  alt: 'Hashtag',
  size: 24,
})`
  margin-left: 4px;
  margin-top: 3px;
`

export const AudioPlayerContainer = styled.div`
  margin: 5px 30px 5px 10px;
`

export const RecorderContainer = styled.div`
  display: ${props => (props.isRecording ? 'initial' : 'none')};
  margin-left: 14px;
  margin-top: 8px;
`

export const EmojiIcon = styled(FaRegSmile).attrs({
  size: 20,
})`
  cursor: pointer;
  margin-left: 4px;
  margin-top: 3px;

  &:hover {
    opacity: 0.9;
  }
`

export const GifIcon = styled(AiOutlineGif).attrs({
  size: 21,
  color: '#FFFF',
})`
  position: absolute;
  bottom: 2px;
  right: 40px;

  cursor: pointer;
  margin-left: 6px;
  background-color: #000;
  border-radius: 5px;
  padding: 0 2px;

  &:hover {
    opacity: 0.9;
  }
`

export const ImageIcon = styled(BsCardImage).attrs(props => ({
  size: 22,
  className: props.disabled ? 'disabled' : '',
}))`
  cursor: pointer;
  margin-left: 9px;
  margin-top: 3px;

  &:hover {
    opacity: 0.9;
  }

  &.disabled {
    opacity: 0.2;
    cursor: unset;
  }
`

export const FileIcon = styled(MdAttachFile).attrs(props => ({
  size: 20,
  className: props.disabled ? 'disabled' : '',
}))`
  cursor: pointer;
  margin-left: 8px;
  margin-top: 3px;

  &:hover {
    opacity: 0.9;
  }

  &.disabled {
    opacity: 0.2;
    cursor: unset;
  }
`

export const VideoIcon = styled(BiVideo).attrs(props => ({
  size: 20,
  className: props.disabled ? 'disabled' : '',
}))`
  cursor: pointer;
  margin-left: 6px;

  &:hover {
    opacity: 0.9;
  }

  &.disabled {
    opacity: 0.2;
    cursor: unset;
  }
`

export const SelectedCount = styled.div`
  display: flex;
  background-color: #303030;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: -4px;
  right: 0px;
  justify-content: center;
  align-items: center;
`

export const SelectInterestContainer = styled.div`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  opacity: ${props => (props.disabled ? '0.2' : '1')};
`

export const ConfirmInterestsButton = styled.div.attrs({
  role: 'button',
})`
  font-size: 10px;
  line-height: 10px;
  padding: 6px;
  background-color: #303030;
  border-radius: 9px;
  color: #fff;
  position: absolute;
  right: 0px;
  top: 6px;
`

export const IconsContainer = styled.div`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  align-items: center;
  height: 30px;
`

export const OpenChatOptionsIcon = styled(IoMdAddCircleOutline).attrs({
  size: 24,
})`
  cursor: pointer;
  margin-left: 4px;
  margin-top: 5px;

  &:hover {
    opacity: 0.9;
  }
`

export const CloseChatOptionsIcon = styled(IoMdCloseCircleOutline).attrs({
  size: 24,
})`
  cursor: pointer;
  margin-left: 4px;
  margin-top: 5px;

  &:hover {
    opacity: 0.9;
  }
`

export const SendMessageContainer = styled.div``

export const ChatSendingOptionsContainer = styled.div.attrs({
  className: 'animated fadeIn',
})`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px -3px 7px 0px rgba(119, 119, 119, 0.15);
  padding: 10px 8px 4px 8px;
  width: 385px;
  bottom: 40px;
  right: -10px;
`

export const IconOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 6px 0px;
  background-color: #eeeeee;
  width: 40px;
  height: 36px;
  border-radius: 5px;
  border: 2px solid #e6e6e6;

  &:hover {
    opacity: 0.8;
  }
`

export const GifIconOption = styled(AiOutlineGif).attrs({
  size: 21,
  color: '#FFFF',
})`
  cursor: pointer;
  margin-left: 6px;
  background-color: #000;
  border-radius: 5px;
  padding: 0 2px;

  &:hover {
    opacity: 0.9;
  }
`

export const AtSigIcon = styled(FiAtSign).attrs({
  size: 21,
})`
  cursor: pointer;
  margin-left: 8px;
  margin-top: 5px;

  &:hover {
    opacity: 0.9;
  }
`
