import React from 'react'
import Row from 'react-bootstrap/Row'
import { useTranslation } from 'react-i18next'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { DefaultLogoImage, DefaultBannerImage, DefaultUserProfileImage } from '~/util/defaultImages'
import * as S from './styled'

export default function PreviewCommunity({ community }) {
  const { t } = useTranslation()
  const { deleteCommunitiesInformationsAction } = useSponsorFunctions()
  const { user } = community

  return (
    <Row>
      <S.BannerAndLogoColumn md={6}>
        <S.ContainerBannerImage>
          <S.BannerImage
            src={community !== null && community.bannerAdvPlat ? community.bannerAdvPlat : DefaultBannerImage}
          />
        </S.ContainerBannerImage>
        <S.CommunityLogo src={community !== null && community.logoAdvPlat ? community.logoAdvPlat : DefaultLogoImage} />
      </S.BannerAndLogoColumn>
      <S.CommunityInformationsColumn md={6}>
        <S.ContainerPreview>
          <S.CommunityName>{community.name}</S.CommunityName>
          <S.UserInformationsContainer>
            <S.CommunityUserAvatarSection>
              <S.UserProfileImage src={user.imageServerPath == null ? DefaultUserProfileImage : user.imageServerPath} />
              <S.Username>
                {community !== null && community.user.firstName ? community.user.firstName : t('userName')}
              </S.Username>
            </S.CommunityUserAvatarSection>
            <S.CommunityDescription>
              {community !== null && community.description ? community.description : null}
            </S.CommunityDescription>
          </S.UserInformationsContainer>
        </S.ContainerPreview>
        <S.IconContainer>
          <S.CloseCommunityPreviewButton onClick={() => deleteCommunitiesInformationsAction()} />
        </S.IconContainer>
      </S.CommunityInformationsColumn>
    </Row>
  )
}
