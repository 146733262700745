import styled from 'styled-components'
import Switch from 'react-switch'
import { IoMdEye, IoMdEyeOff, IoIosAdd } from 'react-icons/io'
import { MdTimeline, MdDone } from 'react-icons/md'
import { GoCheck, GoX } from 'react-icons/go'
import DatePicker from 'react-datepicker'
import Button from '~/components/Shared/Button'

export const CustomSwitch = styled(Switch).attrs({
  color: 'default',
  onColor: '#77D353',
  offColor: '#c6cbd2',
  handleDiameter: 20,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 20,
  width: 50,
  right: 16,
})``

export const DoneIcon = styled(MdDone).attrs({
  color: 'var(--success)',
  size: 23,
})``

export const AddIcon = styled(IoIosAdd).attrs({
  color: '#00A2FF',
  size: 26,
})``

export const ShowPasswordIcon = styled(IoMdEye).attrs({
  color: '#8e98a4',
  size: 27,
})`
  cursor: pointer;
`

export const HidePasswordIcon = styled(IoMdEyeOff).attrs({
  color: '#8e98a4',
  size: 27,
})`
  cursor: pointer;
`

export const ErrorMessageContainer = styled.div.attrs({
  className: 'error-message-login',
})``

export const DeleteIcon = styled.img.attrs({
  alt: 'Delete Icon',
  src: '/icons/delete-icon.png',
})`
  width: 22px;
  height: 22px;
`

export const DatePickerInput = styled(DatePicker).attrs({
  autoComplete: 'off',
  dateFormat: 'dd/MM/yyyy',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 5px;
`

export const InputTitle = styled.span`
  margin-top: 3px;
  color: var(--text-bold);
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
`

export const InputDateTitle = styled.span`
  margin-top: 3px;
  margin-left: 5px;
  color: var(--text-primary);
  font-family: Lato;
  font-style: italic;
  font-size: 14px;
  line-height: 27px;
`

export const CommunityGridTitle = styled.p`
  margin: 0px;
  color: var(--community-dark);
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: ${props => (props.row ? 'center' : null)};
`

export const SponsorGridTitle = styled(CommunityGridTitle)`
  color: var(--sponsor-dark);
`

export const FormatUploadButton = styled(Button).attrs({
  style: { width: '10rem', padding: '5px 0px' },
  height: '2.5rem',
  type: 'button',
  color: '#5A6978',
})``

export const AnalyticsIcon = styled(MdTimeline).attrs({
  size: 22,
  color: '#976DD0',
})``

export const CommunityStatusApprovedIcon = styled(GoCheck).attrs({
  color: 'var(--success)',
  size: 30,
})`
  margin-top: 6px;
`

export const CommunityStatusReprovedIcon = styled(GoX).attrs({
  color: 'var(--error)',
  size: 30,
})`
  margin-top: 6px;
`
