import styled from 'styled-components'
import { darken } from 'polished'
import { MdClose } from 'react-icons/md'

export const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
`

export const TooltipContainer = styled.div`
  position: relative;
  width: 650px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 20px 25px;
`

export const CloseButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  top: -8px;
  right: 0px;

  &:hover {
    background-color: ${darken(0.05, '#FFF')};
    text-decoration: none;
  }
`

export const Title = styled.p`
  margin: 0px;
  font-size: 18px;
  font-weight: 700;
  color: var(--sponsor-primary);
  letter-spacing: 0.00938em;
`

export const Subtitle = styled.p`
  margin: 0px;
  margin-top: 3px;
  font-size: 15px;
  color: var(--text-primary);
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  margin-right: 10px;
`

export const NextButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  background-color: var(--sponsor-primary);
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${darken(0.05, '#00A2FF')};
    text-decoration: none;
  }

  &:active {
    position: relative;
    top: 2px;
  }
`

export const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: 10px;

  &:hover {
    background-color: ${darken(0.05, '#fff')};
    text-decoration: none;
  }

  &:active {
    position: relative;
    top: 2px;
  }
`

export const NextButtonText = styled.span`
  margin: 0px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
`

export const BackButtonText = styled.span`
  margin: 0px;
  font-size: 14px;
  color: #353535;
`

export const TooltipUserAvatar = styled.img.attrs({
  src: '/tutorial-tooltip-avatar.png',
})`
  width: 130px;
  height: 130px;
  margin-right: 20px;
`

export const TooltipArrow = styled.img.attrs({
  src: '/tutorial-tooltip-arrow-side.png',
})`
  position: absolute;
  top: -80%;
  left: 40%;
`

export const CloseIcon = styled(MdClose).attrs({
  size: 20,
  color: '#47525E',
})``
