import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { customNoiticeModalBodyStyle } from '~/helper/const'
import Button from '~/components/Shared/Button'
import history from '~/services/history'
import * as S from './styled'

export default function ModalStripeAccountCreationError({ handleCloseModal, showModal }) {
  const { t } = useTranslation()

  function closeModalAndRefreshRoute() {
    handleCloseModal()
    history.replace('/community')
  }

  function startAgain() {
    window.open(
      `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write`,
    )
    handleCloseModal()
  }

  return (
    <S.ModalCustom keyboard={false} show={showModal} onHide={closeModalAndRefreshRoute}>
      <Modal.Header closeButton>
        <Modal.Title>
          <S.Title>{t('stripeAccountConnection')}</S.Title>
        </Modal.Title>
      </Modal.Header>
      <S.Container>
        <S.BannerImage>
          <S.InvitationTextContainer>
            <S.InvitationText>{t('sorrySomethingWrongOccurred')}</S.InvitationText>
          </S.InvitationTextContainer>
        </S.BannerImage>
      </S.Container>
      <Modal.Body style={customNoiticeModalBodyStyle}>
        <Container fluid>
          <S.Title>{t('tryAgainStripeConnection')}</S.Title>
          <S.TitleBodyCenter>{t('contactStripeSupportTeam')}</S.TitleBodyCenter>
          <S.TextContainer>
            <S.StartAgain onClick={startAgain}>{t('startAgain')}</S.StartAgain>
          </S.TextContainer>
          <S.TextContainer>
            <S.ContactSupport onClick={() => window.open(`https://support.stripe.com/`)}>
              {t('contactSupport')}
            </S.ContactSupport>
          </S.TextContainer>
        </Container>
      </Modal.Body>
      <S.ModalFooter>
        <S.ModalFooterContainer>
          <Button
            margin
            uppercase
            text="close"
            type="submit"
            action={closeModalAndRefreshRoute}
            width="110px"
            height="40px"
          />
        </S.ModalFooterContainer>
      </S.ModalFooter>
    </S.ModalCustom>
  )
}
