import styled from 'styled-components'

export const ListContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  margin-bottom: 5px;

  img {
    border-radius: 20px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  justify-content: space-between;
  max-width: 80%;
  overflow: hidden;
`

export const ItemTitle = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  line-height: 24px;
`

export const LoaderContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
`

export const EmptyText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
`
