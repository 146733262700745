import * as actions from './actionTypes'

export function createSponsorCommunityChannel(
  sponsorId,
  communityId,
  communityName,
  sponsorName,
  sponsorLogo,
  communityLogo,
) {
  return {
    type: actions.CREATE_SPONSOR_COMMUNITY_CHANNEL,
    payload: { sponsorId, communityId, communityName, sponsorName, sponsorLogo, communityLogo },
  }
}

export function updateSponsorCommunityChannel(
  sponsorId,
  communityId,
  communityName,
  sponsorName,
  sponsorLogo,
  communityLogo,
) {
  return {
    type: actions.UPDATE_SPONSOR_COMMUNITY_CHANNEL,
    payload: { sponsorId, communityId, communityName, sponsorName, sponsorLogo, communityLogo },
  }
}

export function createCampaignApprovalMessage(companyId, message, channelId, companyName, companyLogo) {
  return {
    type: actions.CREATE_CAMPAIGN_APPROVAL_MESSAGE,
    payload: { companyId, message, channelId, companyName, companyLogo },
  }
}

export function openCloseChatWindow(chatChannelId) {
  return {
    type: actions.OPEN_CLOSE_CHAT_WINDOW,
    payload: { chatChannelId },
  }
}

export function setActiveReplyMessage(message) {
  return {
    type: actions.SET_ACTIVE_REPLY_MESSAGE,
    payload: { message },
  }
}

export function setActiveChannel(channel) {
  return {
    type: actions.SET_ACTIVE_CHANNEL,
    payload: { channel },
  }
}
