import styled from 'styled-components'
import { darken } from 'polished'
import { customMedia } from '~/styles/global'

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  background-color: ${props => (props.color ? props.color : '#fff')};
  padding: ${props => (props.large ? '0px 30px 0px 20px' : '0px 20px 0px 10px')};
  min-width: 7rem;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '18rem')};
  height: 38px;
  color: #343f4b;
  font-size: 15px;
  border-radius: 100px;
  white-space: normal;
  border: none;
  box-shadow: ${props => (props.noShadow ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '0px 4px 6px 3px rgb(209, 213, 220)')};

  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;

  &:hover {
    background-color: ${props => (props.color ? darken(0.03, props.color) : darken(0.015, '#FFF'))};
    transform: scale(1.06);
  }

  svg {
    margin-right: 5px;

    ${customMedia.lessThan('1350px')`
      display: none;
    `}
  }

  img {
    margin-right: 8px;
  }

  &:active {
    -webkit-box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 0px rgba(214, 214, 214, 0.8), 0px 3px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    top: 2px;
  }

  &:disabled {
    background-color: #bfbfbf;
  }

  ${customMedia.lessThan('1350px')`
      padding: 0;
      min-width: 5.5rem;
    `}

  ${customMedia.greaterThan('huge')`
    font-size: 16px;
  `}
`

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
