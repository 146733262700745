import { takeLatest, all, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '~/services/api'
import i18n from '~/i18n'
import { RECOVER_USER_PASSWORD_REQUEST } from './actionTypes'
import { recoverUserPasswordSuccess, recoverUserPasswordFailure } from './actions'

export function* recoverUserPassword({ payload }) {
  const { email } = payload
  try {
    yield call(api.patch, 'user/forgot-password', {
      email,
    })
    yield put(recoverUserPasswordSuccess(email))
  } catch (err) {
    toast.error(i18n.t('messages.userDoesntExist'))
    yield put(recoverUserPasswordFailure())
  }
}

export default all([takeLatest(RECOVER_USER_PASSWORD_REQUEST, recoverUserPassword)])
