import { format, compareAsc, isSameYear } from 'date-fns'

export const transformDateToUtcFormat = date => {
  const utcDate = new Date(date)
  return new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000)
}

export const getFormattedDate = startDate => {
  const utcDate = new Date(startDate)
  const formattedDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000)
  const startDateMonth = format(formattedDate, 'MMM')
  const startDateDay = format(formattedDate, 'dd')
  const startDateFormatted = `${startDateDay} ${startDateMonth}`
  return startDateFormatted
}

export const getFormattedDatesWithYear = (startDate, endDate) => {
  const mustShowYears = isSameYear(new Date(startDate), new Date(endDate)) === false

  const formatDateWithYear = date => {
    const utcDate = new Date(date)
    const formattedDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000)
    const dateMonth = format(formattedDate, 'MMM')
    const dateDay = format(formattedDate, 'dd')
    const dateYear = format(formattedDate, 'yyyy')
    const dateFormatted = `${dateDay} ${dateMonth}`
    return {
      dateFormatted,
      year: mustShowYears ? dateYear : null,
    }
  }

  const formattedStartDate = formatDateWithYear(startDate)
  const formattedEndDate = formatDateWithYear(endDate)

  return {
    start: formattedStartDate,
    end: formattedEndDate,
  }
}

export const formatDate = date => {
  const utcDate = new Date(date)
  const formattedDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000)
  return date && format(formattedDate, 'dd/MM/yyyy')
}

export const formatUtcDate = date => {
  const utcDate = new Date(date)
  const formattedDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000)
  return date && new Date(formattedDate)
}

export const formatProductDate = date => {
  if (date) {
    const utcDate = new Date(date)
    const formattedDate = utcDate.valueOf() + utcDate.getTimezoneOffset() * 60 * 1000

    return format(formattedDate, 'dd/MM/yyyy')
  }
}

export const getDateMonth = date => {
  return format(new Date(date), 'MMM')
}

export const compareFormatStartDateWithTodaysDate = campaignStartDate => {
  const todayDate = new Date()
  const utcDate = new Date(campaignStartDate)
  const formattedDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000)
  const compareDates = compareAsc(formattedDate, todayDate)
  // Returns 1 if the campaignStart date is higher than today's date
  if (compareDates === 1) {
    return true
  }
  return false
}

export const compareCampaignStartDateAndEndDate = (campaignStartDate, campaignEndDate) => {
  const utcStartDate = new Date(campaignStartDate)
  const utcEndDate = new Date(campaignEndDate)
  const formattedStartDate = new Date(utcStartDate.getTime() + utcStartDate.getTimezoneOffset() * 60000)
  const formattedEndDate = new Date(utcEndDate.getTime() + utcEndDate.getTimezoneOffset() * 60000)
  const compareDates = compareAsc(formattedStartDate, formattedEndDate)
  if (compareDates === 1) {
    return true
  }
  return false
}

/* 
  The backend expects dates to be sent as `yyyy-MM-dd` (2023-03-06)
*/
export const formatDateToSendToTheBackend = date => format(new Date(date), 'yyyy-MM-dd')
