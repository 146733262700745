import { CardContainer, ContentWrapper, ButtonWrapper, ListItem } from './Card'

const Card = {
  CardContainer,
  ContentWrapper,
  ButtonWrapper,
  ListItem,
}

export default Card
