/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { getThumbnails } from 'video-metadata-thumbnails'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import { InputTitle } from '~/components/Shared/StyledComponents'
import ErrorMessage from '~/components/Shared/ErrorMessage'
import { uploadMedia } from '~/services/requests'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import CommunityStoryPreview from '~/components/Sponsor/Modals/CommunityStories/CommunityStoryPreview'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { formatAndSendPostCommunityStory } from '~helper/helperFunctions'
import communityStoryValidator from '~/schemaValidation/communityStoryValidator'
import DisplayCriteriaSelect from '~/components/DisplayCriteriaSelect'

import * as S from './styled'

const ModalCreateCommunityStory = props => {
  const { t } = useTranslation()
  const { createCampaignFormatAction } = useSponsorFunctions()
  const { id: campaignId } = useSelector(state => state.createCampaign?.currentCampaignForReviewPage)
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign?.currentCampaignForReviewPage)
  const { handleSubmit, watch, control, errors, setValue } = useForm({ validationSchema: communityStoryValidator })

  const videoInput = useRef(null)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [loading, setLoading] = useState(false)
  const [media, setMedia] = useState(null)
  const [videoThumbnail, setVideoThumbnail] = useState(null)
  const [mediaFileType, setMediaFileType] = useState(null)
  const [date, setDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const inputWatch = {
    title: watch('title', 'Title'),
    description: watch('description'),
  }
  const { showModal, handleCloseModal, campaignProductId } = props || {}

  const resetForm = () => {
    setMedia(null)
    setVideoThumbnail(null)
    setMediaFileType(null)
    setDate('')
    setEndDate('')
  }

  const closeModal = () => {
    resetForm()
    handleCloseModal()
  }

  const onInputVideoClick = event => {
    event.target.value = ''
  }

  const onSubmit = payload => {
    if (!media?.id) {
      return toast.error(t('defaultValidation', { inputName: t('video') }))
    }

    return formatAndSendPostCommunityStory(
      media,
      videoThumbnail,
      campaignId,
      campaignProductId,
      payload,
      createCampaignFormatAction,
      closeModal,
      setLoading,
    )
  }

  const handleClickUploadInput = uploadRef => {
    return uploadRef?.current?.click()
  }

  const addVideo = async event => {
    const mediaSize = event.target?.files?.[0]?.size
    const mediaType = event.target?.files?.[0].type

    if (mediaType !== 'video/mp4') {
      return toast.error(t('onlyMp4Files'))
    }

    if (mediaSize > 105000000) {
      return toast.error(t('videoIsTooBig', { limit: '100mb' }))
    }
    const video = URL.createObjectURL(event.target?.files?.[0])

    const thumbnail = await getThumbnails(video, {
      quality: 0.6,
      start: 0,
      end: 0,
    })

    if (!thumbnail) return toast.error(t('failedToGetThumbnail'))

    if (event.target?.files?.[0]) {
      const reader = new FileReader()
      reader.onload = () => {
        const media = new Audio(reader.result)
        media.onloadedmetadata = async () => {
          const videoToUpload = event.target?.files?.[0]

          await uploadMedia(videoToUpload, setLoadingUpload, setMedia)

          return await uploadMedia(thumbnail[0]?.blob, setLoadingUpload, setVideoThumbnail)
        }
      }
      await reader.readAsDataURL(event.target?.files?.[0])
    }
    return setMediaFileType('video')
  }

  useEffect(() => {
    setValue('date', date)
  }, [date])

  useEffect(() => {
    setValue('endDate', endDate)
  }, [endDate])

  return (
    <ProductsModal
      action={onSubmit}
      modalTitle={t('newFormat', { format: t('communityStory') })}
      handleCloseModal={closeModal}
      showModal={showModal}
      review={false}
      loadingUpload={loadingUpload}
      loading={loading}
      leftColumn={
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <InputTitle>{t('title')}</InputTitle>
            <Controller
              as={
                <S.CustomTextField
                  placeholder={t('typeTitle')}
                  inputProps={{
                    maxLength: 80,
                  }}
                />
              }
              name="title"
              control={control}
            />
            <ErrorMessage errors={errors} name="title" />

            <S.InputContainer>
              <InputTitle>{t('description')}</InputTitle>
              <Controller
                as={
                  <S.CustomMultilineTextField
                    placeholder={t('moreAboutContent', { format: t('communityStory').toLocaleLowerCase() })}
                    inputProps={{
                      maxLength: 170,
                    }}
                  />
                }
                name="description"
                control={control}
              />
              <ErrorMessage errors={errors} name="description" />
            </S.InputContainer>

            <S.InputContainer>
              <InputTitle>{t('link')}</InputTitle>
              <S.HelperText>{t('communityStoryLinkSeeMore')}</S.HelperText>
              <Controller
                as={
                  <S.CustomTextField
                    placeholder={t('typeLink')}
                    inputProps={{
                      maxLength: 500,
                    }}
                  />
                }
                name="link"
                control={control}
              />
              <ErrorMessage errors={errors} name="link" />
            </S.InputContainer>

            <DisplayCriteriaSelect control={control} />

            <StartDateSection
              product={t('communityStory')}
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              control={control}
              errors={errors}
            />
            <EndDateSection
              product={t('communityStory')}
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              control={control}
              errors={errors}
            />

            <S.InputContainer>
              <InputTitle>{t('communityStoryVideo')}</InputTitle>
            </S.InputContainer>
          </S.FormContainer>

          {loadingUpload && (
            <S.SpinnerContainer>
              <S.CustomSpinner />
            </S.SpinnerContainer>
          )}

          {!loadingUpload && !media && (
            <S.UploadSection>
              <S.UploadContainer spacing onClick={() => handleClickUploadInput(videoInput)}>
                <S.VideoUploadIcon />
                <S.UploadText>{t('videoUpload')}</S.UploadText>
              </S.UploadContainer>
            </S.UploadSection>
          )}

          {/* Preview media */}
          {!loadingUpload && media && (
            <S.PreviewMediaContainer>
              {mediaFileType === 'video' && (
                <S.PlayIconContainer>
                  <S.PlayIcon />
                </S.PlayIconContainer>
              )}
              <S.PreviewMedia src={mediaFileType === 'image' ? media?.url : videoThumbnail?.url} />
              <S.CloseIcon
                onClick={() => {
                  setMedia(null)
                  setMediaFileType(null)
                  setVideoThumbnail(null)
                }}
              />
            </S.PreviewMediaContainer>
          )}
          <S.InputAddVideo ref={videoInput} onChange={addVideo} onClick={onInputVideoClick} />
        </S.Form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          defaultVisualization
          FormatVisualizationComponent={
            <CommunityStoryPreview media={media?.url} videoThumbnail={videoThumbnail?.url} inputWatch={inputWatch} />
          }
        />
      }
    />
  )
}

export default ModalCreateCommunityStory
