import React from 'react'
import { truncate } from 'lodash'
import { getWhenTheMessageWasSended } from '~/helper/helperFunctions'
import ShouldRender from '~/components/ShouldRender'
import ImagePreview from '~/components/Chat/ImagePreview'
import AudioPlayer from '~/components/Chat/AudioPlayer'
import FilePreview from '~/components/Chat/FilePreview'
import MessageText from '~/components/Chat/MessageText'
import { messageType } from '~/helper/const'
import * as S from './styled'

const InputReplyPreview = ({ message, entireMessage }) => {
  const { type } = entireMessage
  const isText = [messageType.Message].includes(entireMessage?.type)
  const isHubContent = [messageType.Content].includes(entireMessage?.type)
  const isLink = [messageType.Link].includes(entireMessage?.type)
  const isMedia = [messageType.Video, messageType.Image, messageType.Gif].includes(entireMessage?.type)
  const isFile = entireMessage?.type === messageType.File

  const getMediaIcon = () => {
    const icons = {
      [messageType.Image]: <S.ImageReplyIcon />,
      [messageType.Content]: <S.ContentIcon />,
      [messageType.Video]: <S.VideoReplyIcon />,
      [messageType.Gif]: <S.GifReplyIcon />,
    }
    return icons[entireMessage?.type] || ''
  }

  const getMediaLabel = () => {
    const labels = {
      [messageType.Gif]: 'GIF',
      [messageType.Image]: 'Photo',
      [messageType.Video]: 'Video',
      [messageType.Content]: 'Content',
    }
    return labels[entireMessage?.type] || ''
  }

  const transformMedia = () => {
    const medias = {
      [messageType.Gif]: [
        entireMessage?.gif?.media?.[0]?.tinygif
          ? entireMessage?.gif?.media?.[0]?.tinygif?.url
          : entireMessage?.gif?.media?.[0]?.nanogif?.url,
      ],
      [messageType.Image]: entireMessage?.images,
      [messageType.Video]: entireMessage?.videos,
      [messageType.Content]: [entireMessage?.hubContentInformation?.cover_image_url],
    }
    return medias[entireMessage?.type] || ''
  }

  return (
    <S.ReplyContainer>
      <S.QuoteIcon />
      <S.ReplySection>
        <ShouldRender if={isMedia || isHubContent}>
          <S.MediaPreviewContainer>
            <S.IconTextContainer>
              {getMediaIcon()}
              <S.ReplyTypeOfMedia>{getMediaLabel()}</S.ReplyTypeOfMedia>
            </S.IconTextContainer>
            <ShouldRender if={isMedia || isHubContent}>
              <S.ImagePreviewContainer>
                <ImagePreview
                  width={60}
                  media={transformMedia()}
                  type={type === messageType.Video ? 'video' : 'image'}
                  message={entireMessage}
                />
              </S.ImagePreviewContainer>
            </ShouldRender>
          </S.MediaPreviewContainer>
        </ShouldRender>
        <ShouldRender if={isText}>
          <S.IconContainer>
            <MessageText
              entireMessage={entireMessage}
              message={message}
              isReplyFromInput
              isVideoOrImage={false}
              isReply
            />
          </S.IconContainer>
        </ShouldRender>
        <ShouldRender if={isLink}>
          <S.IconContainer>
            <S.LinkReplyIcon />
            <MessageText
              entireMessage={entireMessage}
              message={message}
              isReplyFromInput
              isVideoOrImage={false}
              isReply
            />
          </S.IconContainer>
        </ShouldRender>
        <ShouldRender if={type === messageType.Voice}>
          <S.AudioContainer>
            <S.AudioReplyIcon />
            <AudioPlayer {...message} audio={entireMessage?.audio} width={210} />
          </S.AudioContainer>
        </ShouldRender>
        <ShouldRender if={isFile}>
          <FilePreview
            sentByMe
            fileSize={entireMessage?.fileSizes?.[0]}
            fileName={entireMessage?.fileLocalName?.[0]}
            fileUrl={entireMessage?.files?.[0]}
            fileExtension={entireMessage?.fileExtensions?.[0]}
          />
        </ShouldRender>
        <S.Author>
          {`${truncate(entireMessage?.userData?.name, { length: 20 })}, ${getWhenTheMessageWasSended(
            entireMessage?.createdAt,
          )}`}
        </S.Author>
      </S.ReplySection>
    </S.ReplyContainer>
  )
}

export default InputReplyPreview
