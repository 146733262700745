/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { InputAdornment } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import ModalChooseCommunities from '~/components/Community/Modals/ModalChooseCommunities'
import ModalResetPassword from '~/components/Community/Modals/ModalResetPassword'
import LandingPageHeader from '~/components/LandingPageHeader'
import Footer from '~/components/Footer'
import history from '~/services/history'
import useCommunitySelectors from '~/helper/communitySelectors'
import { resetUserRecoveredPassword as resetUserRecoveredPasswordAction } from '~/store/modules/user/actions'
import { signInRequest, signOut as signOutAction } from '~/store/modules/auth/actions'
import useCommunityFunctions from '~/helper/communityFunctions'
import ErrorMessage from '~/components/Shared/ErrorMessage'
import i18n from '~/i18n'
import * as S from './styled'

function Login() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [hiddenPassword, setHiddenPassword] = useState(true)
  const [showModalChooseCommunities, setShowModalChooseCommunities] = useState(false)
  const [showModalResetPassword, setShowModalResetPassword] = useState(false)
  const { handleSubmit, errors, control } = useForm()
  const loading = useSelector(state => state.auth.loading)
  const signed = useSelector(state => state.auth.signed)
  const token = useSelector(state => state.auth.token)
  const { allCommunitiesFromCommunityManager } = useCommunitySelectors()
  const { setUserCurrentCommunityAction } = useCommunityFunctions()

  const handleShowModalResetPassword = () => {
    dispatch(resetUserRecoveredPasswordAction())
    setShowModalResetPassword(true)
  }

  const onSubmit = data => {
    dispatch(signInRequest(data))
  }

  useEffect(() => {
    if (loading === true) {
      dispatch(signOutAction())
    }
  }, [])

  useEffect(() => {
    if (allCommunitiesFromCommunityManager() !== null) {
      if (signed === true && token !== null && allCommunitiesFromCommunityManager().length > 1) {
        setShowModalChooseCommunities(true)
      }
      if (signed === true && token !== null && allCommunitiesFromCommunityManager().length === 1) {
        setUserCurrentCommunityAction(allCommunitiesFromCommunityManager()[0])
      }
    }
  }, [signed, token, allCommunitiesFromCommunityManager()])

  useEffect(() => {
    i18n.changeLanguage('en')
  }, [])

  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, 100)

  return (
    <S.PageContainer>
      <LandingPageHeader homeButton />
      <S.Wave />
      <S.LoginSectionContainer>
        <S.LoginText>{t('login')}</S.LoginText>
        <S.FormContainer>
          <S.LoginFormRibbon />
          <form onSubmit={handleSubmit(onSubmit)}>
            <S.SubtitleContainer>
              <S.Subtitle>{t('loginWithYourEmailAndPassword')}</S.Subtitle>
            </S.SubtitleContainer>
            <S.InputContainer>
              <S.FormInput errors={errors}>
                <Controller
                  as={<S.EmailTextField placeholder={t('yourEmail')} />}
                  rules={{ required: t('defaultValidation', { inputName: t('email') }) }}
                  name="email"
                  id="login-email-field"
                  control={control}
                />
              </S.FormInput>
              <ErrorMessage login errors={errors} name="email" />

              <S.FormInput spacing errors={errors}>
                <Controller
                  id="login-password-field"
                  as={
                    <S.PasswordTextField
                      placeholder={t('yourPassword')}
                      type={hiddenPassword ? 'password' : 'text'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {hiddenPassword ? (
                              <S.HidePasswordIcon onClick={() => setHiddenPassword(!hiddenPassword)} />
                            ) : (
                              <S.ShowPasswordIcon onClick={() => setHiddenPassword(!hiddenPassword)} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                  rules={{ required: t('defaultValidation', { inputName: t('password') }) }}
                  name="password"
                  control={control}
                />
              </S.FormInput>
              <ErrorMessage login errors={errors} name="password" />

              <S.ForgotPasswordText onClick={handleShowModalResetPassword}>{t('forgotPassword')}</S.ForgotPasswordText>
            </S.InputContainer>
            <S.LoginButton id="login-button" disabled={loading ? 1 : 0}>
              <S.ButtonText>{loading ? <S.CustomSpinner /> : t('login')}</S.ButtonText>
            </S.LoginButton>
          </form>
        </S.FormContainer>
        <S.CreateNewAccountContainer onClick={() => history.push('/')}>
          <S.CreateNewAccountText>{t('createNewAccount')}</S.CreateNewAccountText>
        </S.CreateNewAccountContainer>
      </S.LoginSectionContainer>
      <Footer />

      <ModalChooseCommunities
        handleCloseModal={() => setShowModalChooseCommunities(false)}
        showModal={showModalChooseCommunities}
      />
      <ModalResetPassword
        handleCloseModal={() => setShowModalResetPassword(false)}
        showModal={showModalResetPassword}
      />
    </S.PageContainer>
  )
}

export default withRouter(Login)
