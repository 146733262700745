/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import Header from '~/components/Header'
import history from '~/services/history'
import SkeletonChatbox from '~/components/Chat/SkeletonChatbox'
import ChatList from '~/components/Chat/ChatList'
import ModalEditUserProfile from '~/components/Modals/ModalEditUserProfile'
import StartNewCampaignModal from '~/components/Sponsor/Modals/StartNewCampaignModal'
import SponsorProfile from '~/components/Sponsor/SponsorProfile'
import GroupTabs from '~/components/Shared/GroupTabs'
import CampaignReviewPage from '~/pages/Sponsor/containers/CampaignReviewPage'
import CampaignTab from '~/components/Sponsor/CampaignsTab/CampaignTab'
import CommunityTab from '~/components/Sponsor/CommunitiesTab/CommunityTab'
import PaymentTab from '~/components/Sponsor/PaymentsTab/PaymentTab'
import AnalyticsTab from '~/components/Sponsor/AnalyticsTab'
import ModalTutorialWelcome from '~/components/ApplicationTutorial/ModalTutorialWelcome'
import ModalTutorialAllSteps from '~/components/ApplicationTutorial/Sponsor/ModalTutorialAllSteps'
import CampaignReviewPageTutorialMocked from '~/components/ApplicationTutorial/Sponsor/CampaignReviewPageTutorialMocked'
import { resetCampaignProcess as resetCampaignProcessAction } from '~/store/modules/createCampaign/actions'
import PendingAlertSection from '~/components/PendingAlertSection'
import SponsorTabs from '~/components/Sponsor/SponsorTabs'
import useNavigationFunctions from '~/helper/navigationFunctions'
import useCampaignFunctions from '~/helper/campaignFunctions'
import useTutorialFunctions from '~/helper/tutorialFunctions'
import {
  getSponsorAndSponsorAlerts,
  getCampaignIdFromEmailRedirectionAndGoToCampaignReviewPage,
} from '~/services/requests'
import StartCampaignButton from '~/components/Sponsor/StartCampaignButton/StartCampaignButton'
import * as CF from '~/helper/chatFunctions'
import * as S from './styled'

export default function Sponsor({ location }) {
  const urlParams = new URLSearchParams(location.search)
  const campaignId = urlParams.get('campaignId')

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const sponsorFunction = useSponsorFunctions()
  const { setShowCampaignReviewPageAction, setCurrentCampaignForReviewPageAction } = useCampaignFunctions()
  const { setSponsorCurrentTabAction } = useNavigationFunctions()
  const { setTutorialAsInativeAction, closeCreateCampaignForTutorialAction } = useTutorialFunctions()
  const sponsorFirstLoginForTheTutorial = useSelector(state => state.tutorial.sponsorFirstLogin)
  const companyId = useSelector(state => state.sponsor.company.id)
  const [userInformations, setUserInformations] = useState(null)
  const [channels, setChannels] = useState([])
  const [loadingSetChannels, setLoadingSetChannels] = useState(false)
  const showCampaignReviewPage = useSelector(state => state.createCampaign.showCampaignReviewPage)
  const campaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const currentTabActive = useSelector(state => state.tab.sponsorCurrentTab)
  const [loading, setLoading] = useState(false)
  const [sponsorInformations, setSponsorInformations] = useState('')
  const [showModalEditUserProfile, setShowModalEditUserProfile] = useState(false)
  const [showModalCreateNewCampaign, setShowModalCreateNewCampaign] = useState(false)
  const [showModalTutorialWelcome, setShowModalTutorialWelcome] = useState(sponsorFirstLoginForTheTutorial)
  const [showModalTutorialAllSteps, setShowModalTutorialAllSteps] = useState(false)
  const [sponsorIncompleteProfile, setSponsorIncompleteProfile] = useState(null)
  const [showModalEditSponsor, setShowModalEditSponsor] = useState(false)
  const [loadingSponsorProfile, setLoadingSponsorProfile] = useState(true)
  const showCampaignReviewPageMockedForTheTutorial = useSelector(
    state => state.sponsor.showCampaignReviewPageMockedForTheTutorial,
  )
  const howManyTimesTheSponsorLoggedInTheApplication = useSelector(
    state => state.tutorial.howManyTimesTheSponsorLoggedInTheApplication,
  )

  const openCreateCampaignModalAndSetCampaignStepToOne = () => {
    dispatch(resetCampaignProcessAction())
    setShowModalCreateNewCampaign(true)
  }

  const handleChangeCurrentTabActive = tab => {
    setSponsorCurrentTabAction(tab)
  }

  const backToCommunityPage = () => {
    setShowCampaignReviewPageAction(false)
  }

  useEffect(() => {
    CF.getSponsorNonSponsoredChannels(companyId, setChannels, setLoadingSetChannels)
    CF.createOrUpdateUsersReadDocumentForTheSponsor(companyId, channels)
  }, [loadingSetChannels])

  useEffect(() => {
    if (companyId !== undefined) {
      setLoading(true)
      getSponsorAndSponsorAlerts(
        companyId,
        setLoading,
        setSponsorInformations,
        sponsorFunction.updateSponsorInformationsAction,
        sponsorFunction.createSponsorCommunityChannelAction,
        sponsorFunction.setAllCommunitiesFromSponsorAction,
        setSponsorIncompleteProfile,
        setUserInformations,
      )
    }
  }, [])

  useEffect(() => {
    if (history.location.pathname !== 'sponsor') {
      history.replace('/sponsor')
    }
  }, [])

  // Get the campaignId from the email redirection and go to the campaign review page
  useEffect(() => {
    if (campaignId) {
      getCampaignIdFromEmailRedirectionAndGoToCampaignReviewPage(
        campaignId,
        setCurrentCampaignForReviewPageAction,
        setShowCampaignReviewPageAction,
      )
    }
  }, [campaignId])

  useEffect(() => {
    if (!sponsorFirstLoginForTheTutorial && howManyTimesTheSponsorLoggedInTheApplication < 5) {
      setShowModalTutorialAllSteps(true)
    }
    setTutorialAsInativeAction()
    closeCreateCampaignForTutorialAction()
    sponsorFunction.setShowCampaignReviewPageMockedForTheTutorialAction(false)
    const interval = setTimeout(() => {
      setLoadingSponsorProfile(false)
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  if (loading)
    return (
      <S.SpinnerContainer>
        <S.CustomSpinner />
      </S.SpinnerContainer>
    )

  if (!loading) {
    return (
      <>
        <Header
          userInformations={userInformations}
          entity="Sponsor"
          openModalUserProfile={() => setShowModalEditUserProfile(true)}
        />
        <S.PageContainer fluid as="section">
          <S.MainRow>
            <S.SponsorProfileColumn>
              {!loadingSponsorProfile && (
                <SponsorProfile
                  sponsorInformations={sponsorInformations}
                  showModalEditSponsor={showModalEditSponsor}
                  setShowModalEditSponsor={setShowModalEditSponsor}
                />
              )}
            </S.SponsorProfileColumn>
            <StartCampaignButton variant="gradient" onClick={openCreateCampaignModalAndSetCampaignStepToOne} />
            {showCampaignReviewPage && (
              <S.CampaginReviewPageColumn>
                <CampaignReviewPage backToCommunityPage={backToCommunityPage} campaign={campaignForReviewPage} />
              </S.CampaginReviewPageColumn>
            )}
            {showCampaignReviewPageMockedForTheTutorial && (
              <S.CampaginReviewPageColumn>
                <CampaignReviewPageTutorialMocked backToCommunityPage={backToCommunityPage} />
              </S.CampaginReviewPageColumn>
            )}
            {!showCampaignReviewPage && !showCampaignReviewPageMockedForTheTutorial && (
              <S.GroupTabsColumn>
                {sponsorIncompleteProfile === true && (
                  <PendingAlertSection
                    title={t('completeYourCompanyProfile')}
                    subtitle={t('describeYourCompany')}
                    buttonText={t('completeCompanyProfile')}
                    buttonAction={() => setShowModalEditSponsor(true)}
                  />
                )}

                <GroupTabs
                  tabs={SponsorTabs(currentTabActive)}
                  currentTabActive={currentTabActive}
                  setCurrentTabActive={handleChangeCurrentTabActive}
                  tabsColor="#00A2FF"
                  sponsor
                />
                <S.MainContainer>
                  {(() => {
                    switch (currentTabActive) {
                      case 'communities':
                        return <CommunityTab />
                      case 'campaigns':
                        return <CampaignTab />
                      case 'payments':
                        return <PaymentTab />
                      case 'analytics':
                        return <AnalyticsTab />
                      default:
                        return null
                    }
                  })()}
                </S.MainContainer>
              </S.GroupTabsColumn>
            )}

            {!loading && (
              <ModalEditUserProfile
                showModal={showModalEditUserProfile}
                handleCloseModal={() => setShowModalEditUserProfile(false)}
              />
            )}

            <S.ChatChannelColumn>
              <S.ChatChannelContainer>
                {loading && <SkeletonChatbox />}
                {!loading && (
                  <S.ChatListContainer>
                    <ChatList entityId={sponsorInformations.id} userType="Sponsor" />
                  </S.ChatListContainer>
                )}
              </S.ChatChannelContainer>
            </S.ChatChannelColumn>
          </S.MainRow>

          <StartNewCampaignModal
            handleCloseModal={() => setShowModalCreateNewCampaign(false)}
            showModal={showModalCreateNewCampaign}
          />
          <ModalTutorialWelcome
            handleCloseModal={() => setShowModalTutorialWelcome(false)}
            showModal={showModalTutorialWelcome}
            openModalTutorialAllSteps={() => setShowModalTutorialAllSteps(true)}
            sponsor
          />
          <ModalTutorialAllSteps
            handleCloseModal={() => setShowModalTutorialAllSteps(false)}
            showModal={showModalTutorialAllSteps}
          />
        </S.PageContainer>
      </>
    )
  }
}
