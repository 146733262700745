import ShouldRender from '~/components/ShouldRender'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { store } from '~/store'
import * as S from './styles'

const StartCampaignButton = ({ onClick, variant = 'white', style }) => {
  const { t } = useTranslation()

  const { finPersonEmail, finPersonName } = store.getState().sponsor.company

  // Validate if user can create a campaign before executing `onClick`
  const handleClickButton = useCallback(() => {
    if (!finPersonName && !finPersonEmail) {
      return toast.error(t('createCampaignsWithoutFinancialInformationsMessage'), { autoClose: 12000 })
    }
    return onClick()
  }, [finPersonName, finPersonEmail, onClick, t])

  return (
    <>
      <ShouldRender if={variant === 'gradient'}>
        <S.NewCampaignGradientButton onClick={handleClickButton}>
          <S.NewCampaignText>{t('startNewCampaign')}</S.NewCampaignText>
        </S.NewCampaignGradientButton>
      </ShouldRender>

      <ShouldRender if={variant !== 'gradient'}>
        <S.StartCampaignButton
          style={style || {}}
          text={t('startNewCampaign')}
          icon={<S.AddIcon />}
          onClick={handleClickButton}
        />
      </ShouldRender>
    </>
  )
}

export default StartCampaignButton
