import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useTutorialFunctions from '~/helper/tutorialFunctions'
import { decreaseStep as decreaseStepAction } from '~/store/modules/step/actions'
import * as S from './styled'

export default function CreateCampaignModalTooltipStep2({ closeProps, tooltipProps, openCreditCardMockedModal }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { setTutorialAsInativeAction, closeCreateCampaignForTutorialAction } = useTutorialFunctions()

  const goToCampaignCreationStep1 = () => {
    dispatch(decreaseStepAction())
  }

  const closeModalAndSetTutorialToInactive = () => {
    closeCreateCampaignForTutorialAction()
    setTutorialAsInativeAction()
  }

  const closeThisTooltipAndOpenCreditCardModal = () => {
    openCreditCardMockedModal()
  }

  return (
    <S.ComponentContainer {...tooltipProps}>
      <S.TooltipArrow />
      <S.TooltipUserAvatar />
      <S.TooltipContainer>
        <S.CloseButtonContainer>
          <S.CloseIcon onClick={() => closeModalAndSetTutorialToInactive()} />
        </S.CloseButtonContainer>
        <S.Title>{t('sponsorStep3TutorialTitle')}</S.Title>
        <S.Subtitle>{t('sponsorCampaignsTabSubtitle')}</S.Subtitle>
        <S.CampaignTypeContainer>
          <S.CampaignTypeSection>
            <S.Dot />
            <S.Subtitle>{t('payAsYouGo')}</S.Subtitle>
          </S.CampaignTypeSection>
          <S.CampaignTypeSection>
            <S.Dot />
            <S.Subtitle>{t('packagesWithSpecialDiscount')}</S.Subtitle>
          </S.CampaignTypeSection>
        </S.CampaignTypeContainer>
        <S.ButtonsContainer>
          <S.BackButton onClick={() => goToCampaignCreationStep1()}>
            <S.BackButtonText>{t('back')}</S.BackButtonText>
          </S.BackButton>
          <S.NextButton {...closeProps}>
            <S.NextButton onClick={() => closeThisTooltipAndOpenCreditCardModal()}>
              <S.NextButtonText>{t('next')}</S.NextButtonText>
            </S.NextButton>
          </S.NextButton>
        </S.ButtonsContainer>
      </S.TooltipContainer>
    </S.ComponentContainer>
  )
}
