import styled from 'styled-components'
import { breakpoints } from '~/helper/breakpoints'

export const Container = styled.div`
  margin-top: 8rem;
  padding: 0rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${breakpoints.mobile} {
    margin-top: 1.8rem;
  }
`

export const DesktopContainer = styled.div`
  @media ${breakpoints.mobile} {
    display: none;
  }
`

export const MobileContainer = styled.div`
  display: none;
  @media ${breakpoints.mobile} {
    display: block;
  }

  gap: 1rem;
`

export const ImageContainer = styled.div`
  max-width: 100%;

  .desktop-image {
    max-width: 90vw;
    margin-bottom: 2.5rem;
  }
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  gap: 0.5rem;

  h2 {
    color: #18181a;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
  }
`
