import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import { customMedia } from '~/styles/global'

export const PageContainer = styled(Container).attrs({
  className: 'animated fadeIn',
})`
  padding: 0px 75px 50px 75px;

  ${customMedia.greaterThan('2200px')`
      padding-left: 250px;
      padding-right: 250px;
  `}

  ${customMedia.lessThan('huge')`
      padding-left: 35px;
      padding-right: 35px;
  `}

  ${customMedia.lessThan('small')`
      padding-left: 10px;
      padding-right: 10px;
  `}
`

export const ChatChannelContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`

export const StepperContainer = styled.div`
  background: #fff;
  border-radius: 5px;
  height: 110px;

  .start-new-campaign {
    display: flex;
    margin-left: 25px;
    align-items: center;

    p {
      margin: 0px;
      text-transform: uppercase;
      margin-left: 10px;
      color: #47525e;
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      line-height: 15px;

      ${customMedia.lessThan('1370px')`
        font-size: 14px;
      `}
    }

    ${customMedia.lessThan('small')`
      display: none;
    `}
  }

  .step-component {
    ${customMedia.lessThan('small')`
      display: none;
    `}
  }
`

export const SponsorInformationSection = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  z-index: 0;
  padding-bottom: 10px;
`

export const CampaignSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .campaign-number {
    color: #343f4b;
    font-family: Roboto;
    font-size: 17px;
    font-weight: bold;
    padding-right: 15px;
    padding-left: 15px;
  }

  .ad-views {
    color: #343f4b;
    font-family: Roboto;
    font-size: 17px;
    font-weight: bold;
    padding-right: 15px;
    padding-left: 15px;
  }
`

export const CampaignLabel = styled.div`
  color: var(--text-primary);
  font-family: Lato;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
`

export const CampaignLabelsSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const OfferingServicesSection = styled.div`
  text-align: center;
  color: #858588;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;

  p {
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }
`

export const SponsorNameAndRating = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 45px 15px 0px 15px;

  .sponsor-name {
    color: #343f4b;
    font-family: Roboto;
    font-size: 19px;
    font-weight: bold;
    line-height: 23px;
    width: 170px;

    ${customMedia.lessThan('huge')`
      font-size: 16px;
    `}
  }
  .sponsor-rating {
    color: #343f4b;
    font-family: Roboto;
    font-size: 30px;
    font-weight: 500;
    line-height: 39px;

    ${customMedia.lessThan('huge')`
      font-size: 17px;
      font-weight: bold;
    `}

    ${customMedia.lessThan('1370px')`
      display: none;
    `}
  }
`

export const SponsorLocationAndRating = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0px 15px 10px 15px;
  margin-top: -5px;

  ${customMedia.lessThan('1370px')`
    margin-top: 0px;
  `}

  .sponsor-location {
    color: #a8b1bd;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    width: 150px;
  }

  .sponsor-rating-label {
    color: #a8b1bd;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;

    ${customMedia.lessThan('1370px')`
      display: none;
    `}
  }
`

export const StartNewCampaignContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 8px 15px 8px 15px;

  p {
    margin: 0px;
  }

  .title {
    color: #484848;
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 700;
  }

  .subtitle {
    padding-bottom: 3px;
    color: #858588;
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
  }
`

export const StatusSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 5px;
  }

  p {
    margin: 0;
  }

  .community-status {
    color: #47525e;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 700;
  }

  .status {
    margin-top: -5px !important;
    color: #858588;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
  }
`

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`

export const PendingAlertsSection = styled.div``

export const MainContainer = styled.div`
  background-color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
`

export const ChatChannelColumn = styled.div`
  padding: 20px 10px;

  @media (min-width: 768px) {
    width: 17%;
    order: 3;
  }

  ${customMedia.lessThan('1100px')`
     width: 40%;
  `}

  ${customMedia.lessThan('small')`
    width: 100%;
    padding-top: 0px;
  `}
`

export const ChatListContainer = styled.div``

export const CampaginReviewPageColumn = styled.div.attrs({
  className: 'animated fadeIn',
})`
  padding: 20px 10px;

  ${customMedia.greaterThan('medium')`
   order: 2;
    width: 67% ;
  `}

  ${customMedia.lessThan('small')`
      display: none;
  `}

  ${customMedia.lessThan('1100px')`
      display: none;
  `}
`

// export const GroupTabsColumn = styled(Col)`
//   padding-bottom: 48px;

//   ${customMedia.lessThan('small')`
//       padding: 20px 0px;
//       display: none;
//   `}
// `

export const GroupTabsColumn = styled.div.attrs({
  className: 'animated fadeIn',
})`
  padding: 20px 10px !important;

  ${customMedia.greaterThan('medium')`
   order: 2;
    width: 67% ;
  `}

  ${customMedia.lessThan('small')`
      display: none;
  `}

  ${customMedia.lessThan('1100px')`
      display: none;
  `}
`

export const CustomSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})``

export const MainRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  align-content: space-between;
  justify-content: space-between;

  @media (min-width: 576px) {
    flex-flow: row wrap;
  }

  @media (min-width: 768px) {
    flex-flow: row nowrap;
  }

  ${customMedia.lessThan('medium')`
      flex-flow: row wrap;
  `}
`

export const NewCampaignText = styled.span`
  font-size: 15px;
  color: #fff;
  margin: 0px;
  font-family: Montserrat;
  font-weight: bold;
`

export const SponsorProfileColumn = styled.div`
  padding: 20px 10px;

  @media (min-width: 768px) {
    width: 16%;
    order: 1;
  }

  ${customMedia.lessThan('1100px')`
     width: 60%;
  `}

  ${customMedia.lessThan('small')`
    width: 100%;
    padding-bottom: 0px;
  `}
`
