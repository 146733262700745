import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import DefaultButton from '~/components/Shared/DefaultButton'

export const ModalCustom = styled(Modal).attrs({
  backdrop: 'static',
  keyboard: false,
})`
  .modal-content {
    width: 100%;
    padding-bottom: 5px;
    border-radius: 10px;
    margin: auto;
  }

  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .modal-body {
    position: relative;
    padding: 20px;
  }
`

export const Title = styled.p`
  color: var(--text-bold);
  margin-bottom: 0px;
  margin-left: 5px !important;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
`

export const Subtitle = styled.p`
  margin-top: 3px;
  margin-left: 5px !important;
  margin-bottom: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  line-height: 19px;
`

export const EmailAlert = styled.p`
  margin-top: 17px;
  margin-left: 5px !important;
  margin-bottom: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  line-height: 19px;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 20px;
`

export const FooterText = styled.p`
  margin-bottom: 0px;
  margin-top: 26px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
`

export const SuccessSectionContainer = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  background-color: #d5ebd1;
  border-radius: 5px;
  width: 100%;
  height: 100px;
`

export const SuccessText = styled.span`
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
`

export const SuccessMessageText = styled.span`
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
`

export const ReturnLoginText = styled.p`
  cursor: pointer;
  margin-bottom: 0px;
  margin-left: 20px;
  color: #006aa2;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
`

export const SuccessTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`

export const ReturnLoginContainer = styled.div.attrs({
  role: 'presentation',
})``

export const ErrorMessageContainer = styled.div.attrs({
  className: 'error-message',
})``

export const Form = styled.form`
  margin-top: 8px;
`

export const ResetPasswordButton = styled(DefaultButton).attrs(props => ({
  color: props.sponsor ? '#00A2FF' : '#9671F5',
}))``

export const ReturnToLoginButton = styled(DefaultButton).attrs(props => ({
  color: props.sponsor ? '#00A2FF' : '#9671F5',
}))``
