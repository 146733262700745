import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { darken } from 'polished'
import { MdClose } from 'react-icons/md'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export const ModalCustom = styled(Modal)`
  .modal-content {
    width: 100%;
    padding-bottom: 5px;
    border-radius: 10px;
    margin: auto;
  }

  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .modal-body {
    position: relative;
    padding: 20px;
  }
`

export const GridTitle = styled.p`
  margin: 0px;
  color: var(--community-dark);
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
`

export const TitleGridContainer = styled(Row)`
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`

export const ProductsContainer = styled(Row)`
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(214, 214, 214, 0.4);
  border-radius: 5px;
  height: 75px;
  padding: 10px 5px;
`

export const ProductTitle = styled.p`
  margin: 0px;
  color: var(--text-bold);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.9;
  letter-spacing: 0.5px;
`

export const ProductSubtitle = styled.p`
  margin: 0px;
  color: var(--text-primary);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
`

export const MonthlyBudget = styled.p`
  margin: 0px;
  color: var(--success);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
`

export const ProductSubtitleContainer = styled.div``

export const Column = styled(Col).attrs({
  col: 3,
})``

export const MonthlyBudgetColumn = styled(Col).attrs({
  col: 3,
})`
  padding-left: 0px !important;
`

export const PriceColumn = styled(Col).attrs({
  col: 3,
})`
  display: flex;
  padding-left: 35px !important;
  flex-direction: column;
`

export const ProductPriceWithDiscount = styled.p`
  margin: 0px;
  color: var(--error);
  font-family: Roboto;
  text-decoration: line-through;
  font-size: 13px;
  font-weight: 500;
`

export const CloseButtonContainer = styled.div`
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  top: -13px;
  right: -10px;

  &:hover {
    background-color: ${darken(0.05, '#FFF')};
    text-decoration: none;
  }
`

export const CloseIcon = styled(MdClose).attrs({
  size: 20,
  color: '#47525E',
})``
