import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;

  justify-content: space-between;

  padding-bottom: 16px;

  :not(:nth-last-of-type(-n + 2)) {
    border-bottom: 1px dashed #e3e3e3;
  }
`

export const FormatImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 8px;
  border: 2px solid rgb(230, 230, 230, 0.5);
  border-radius: 8px;
`

export const FormatImage = styled.img`
  width: 52px;
  height: auto;
`

export const FormatName = styled.strong`
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.3;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

export const FormatDescription = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  line-height: 1.2;
  color: #a6a6a6;
`

export const MonthUnity = styled(FormatDescription)`
  color: #a6a6a6;
`
