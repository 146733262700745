import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import TextField from '@material-ui/core/TextField'
import Button from '~/components/Shared/Button'

export const ModalCustom = styled(Modal).attrs({
  backdrop: 'static',
  keyboard: false,
})`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 610px;
      height: auto;
      margin: auto;
      padding: 0;
      margin-top: 100px;
    }
  }

  .modal-body {
    padding: 15px;
  }

  .modal-content {
    width: 100%;
    padding-bottom: 8px;
  }

  .error-message {
    font-family: Roboto;
    color: #ea343e;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.9;
  }
`

export const Title = styled.p`
  color: #404257;
  margin-bottom: 0px;
  margin-left: 5px !important;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  opacity: 0.9;
`

export const Subtitle = styled.p`
  margin-top: 3px;
  margin-left: 5px !important;
  margin-bottom: 0px;
  color: var(--input-title);
  font-family: Roboto;
  font-size: 14px;
  line-height: 19px;
`

export const Form = styled.form`
  margin-top: 8px;
`

export const PreviewCommunityContainer = styled.div`
  margin-top: 16px;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: baseline;
`

export const ErrorMessage = styled.span.attrs({
  className: 'error-message',
})``

export const IncludeCommunityButton = styled(Button).attrs({
  width: '185px',
  height: '45px',
  fontSize: '17px',
  type: 'Submit',
  style: { marginTop: '16px' },
  color: '#00A2FF',
})``

export const LoadingSpinner = styled(Spinner).attrs({
  size: 'sm',
  animation: 'border',
})``

export const InvitationLinkInput = styled(TextField).attrs({
  fullWidth: true,
  variant: 'outlined',
  type: 'text',
})``
