import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import { InputTitle } from '~/components/Shared/StyledComponents'
import { StartDateSection, EndDateSection } from '~/components/Shared/CreateFormatDateSection'
import CreateFormatMobileVisualization from '~/components/CreateFormatMobileVisualization'
import ScratchCardPreview from '~/components/Sponsor/Modals/ScratchCard/ScratchCardPreview'
import { getThumbnails } from 'video-metadata-thumbnails'
import { uploadMedia } from '~/services/requests'
import scratchCardValidator from '~/schemaValidation/communityStoryValidator'
import DisplayCriteriaSelect from '~/components/DisplayCriteriaSelect'

import { isVideo, isImage } from '../utils'
import * as S from './styled'

export default function ModalCreateScratchCard({ handleCloseModal, showModal, campaignProductId }) {
  const { t } = useTranslation()
  const { createCampaignFormatAction } = useSponsorFunctions()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const [media, setMedia] = useState(null)
  const [videoThumbnail, setVideoThumbnail] = useState(null)
  const [mediaFileType, setMediaFileType] = useState(null)
  const [loading, setLoading] = useState(false)

  const videoInput = useRef(null)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [date, setDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const { handleSubmit, register, setValue, control, errors } = useForm({
    validationSchema: scratchCardValidator,
  })

  function resetFormAfterSubmit() {
    setMedia(null)
    setVideoThumbnail(null)
    setMediaFileType(null)
    setDate('')
    setEndDate('')
  }

  const closeModal = () => {
    resetFormAfterSubmit()
    handleCloseModal()
  }

  const handleClickUploadInput = uploadRef => {
    return uploadRef?.current?.click()
  }

  const onInputVideoClick = event => {
    event.target.value = ''
  }

  const addMedia = async event => {
    const mediaSize = event.target?.files?.[0]?.size
    const mediaType = event.target?.files?.[0].type

    if (!isImage(mediaType) && !isVideo(mediaType)) return toast.error(t('invalidFormat'))

    if (mediaSize > 100000000) {
      return toast.error(t('uploadLimit'))
    }

    if (isVideo(mediaType)) {
      if (mediaType !== 'video/mp4') return toast.error(t('onlyMp4Files'))

      const video = URL.createObjectURL(event.target?.files?.[0])

      const thumbnail = await getThumbnails(video, {
        quality: 0.6,
        start: 0,
        end: 0,
      })

      if (!thumbnail) return toast.error(t('failedToGetThumbnail'))

      if (event.target?.files?.[0]) {
        const reader = new FileReader()
        reader.onload = () => {
          const media = new Audio(reader.result)
          media.onloadedmetadata = async () => {
            const videoToUpload = event.target?.files?.[0]
            await uploadMedia(videoToUpload, setLoadingUpload, setMedia)
            return await uploadMedia(thumbnail[0]?.blob, setLoadingUpload, setVideoThumbnail)
          }
        }
        await reader.readAsDataURL(event.target?.files?.[0])
      }
      return setMediaFileType('video')
    }

    if (isImage(mediaType)) {
      if (event.target?.files?.[0]) {
        const imagetoUpload = event.target?.files?.[0]
        await uploadMedia(imagetoUpload, setLoadingUpload, setMedia)
        return setMediaFileType('image')
      }
    }
  }

  const onSubmit = format => {
    if (!format?.imageUrl && !format?.videoId) return toast.error(t('defaultValidation', { inputName: t('media') }))

    if (mediaFileType === 'image' && !format.imageUrl)
      return toast.error(t('defaultValidation', { inputName: t('media') }))

    if (mediaFileType === 'video' && !format.videoId)
      return toast.error(t('defaultValidation', { inputName: t('media') }))

    createCampaignFormatAction(currentCampaignForReviewPage.id, campaignProductId, format, null, closeModal, setLoading)
  }

  useEffect(() => {
    if (mediaFileType === 'image') {
      register({ name: 'image' })
      register({ name: 'imageUrl' })
      setValue('image', media?.id)
      setValue('imageUrl', media?.url)
    }

    if (mediaFileType === 'video') {
      register({ name: 'thumbnailId' })
      register({ name: 'thumbnailUrl' })
      register({ name: 'videoId' })
      register({ name: 'videoUrl' })
      setValue('thumbnailId', videoThumbnail?.id)
      setValue('thumbnailUrl', videoThumbnail?.url)
      setValue('videoId', media?.id)
      setValue('videoUrl', media?.url)
    }
  }, [register, setValue, media, mediaFileType, videoThumbnail])

  return (
    <ProductsModal
      action={onSubmit}
      modalTitle={t('newFormat', { format: t('scratchCard') })}
      handleCloseModal={closeModal}
      showModal={showModal}
      review={false}
      loadingUpload={loadingUpload}
      loading={loading}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <div>
              <InputTitle>{t('title')}</InputTitle>
              <Controller
                as={<TextField fullWidth variant="outlined" placeholder={t('typeTitle')} />}
                name="title"
                control={control}
              />
            </div>
            <div className="error-message">
              <ErrorMessage errors={errors} name="title" as="p" />
            </div>

            <div className="mt-3">
              <InputTitle>{t('link')}</InputTitle>
              <Controller
                as={<TextField fullWidth variant="outlined" placeholder="e.g. https://www.greatecontent.com" />}
                name="link"
                control={control}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="link" as="p" />
              </div>
            </div>

            <div className="mt-3">
              <InputTitle>{t('description')}</InputTitle>
              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={t('moreAboutContent', { format: t('scratchCard').toLocaleLowerCase() })}
                    rows={4}
                    multiline
                  />
                }
                name="description"
                control={control}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="description" as="p" />
              </div>
            </div>

            <DisplayCriteriaSelect control={control} />

            <StartDateSection
              product={t('scratchCard')}
              selectedCampaignStartDate={date}
              setCampaignStartDate={setDate}
              campaignStartDate={currentCampaignForReviewPage.dtStart}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              control={control}
              errors={errors}
            />

            <EndDateSection
              product={t('scratchCard')}
              selectedCampaignStartDate={date}
              selectedCampaignEndDate={endDate}
              campaignEndDate={currentCampaignForReviewPage.dtEnd}
              setCampaignEndDate={setEndDate}
              control={control}
              errors={errors}
            />

            <S.InputContainer>
              <InputTitle>{t('showExperience')}</InputTitle>
              <S.HelperText>{t('scratchCardResolution')}</S.HelperText>
            </S.InputContainer>

            {loadingUpload && (
              <S.SpinnerContainer>
                <S.CustomSpinner />
              </S.SpinnerContainer>
            )}

            {!loadingUpload && !media && (
              <S.UploadSection>
                <S.UploadContainer spacing onClick={() => handleClickUploadInput(videoInput)}>
                  <S.VideoUploadIcon />
                  <S.UploadText>{t('addMedia')}</S.UploadText>
                </S.UploadContainer>
              </S.UploadSection>
            )}

            {/* Preview media */}
            {!loadingUpload && media && (
              <S.PreviewMediaContainer>
                {mediaFileType === 'video' && (
                  <S.PlayIconContainer>
                    <S.PlayIcon />
                  </S.PlayIconContainer>
                )}
                <S.PreviewMedia src={mediaFileType === 'image' ? media?.url : videoThumbnail?.url} />
                <S.CloseIcon
                  onClick={() => {
                    setMedia(null)
                    setMediaFileType(null)
                    setVideoThumbnail(null)
                  }}
                />
              </S.PreviewMediaContainer>
            )}
            <S.InputAddVideo ref={videoInput} onChange={addMedia} onClick={onInputVideoClick} />
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <CreateFormatMobileVisualization
          defaultVisualization
          FormatVisualizationComponent={
            <ScratchCardPreview media={mediaFileType === 'image' ? media?.url : videoThumbnail?.url} />
          }
        />
      }
    />
  )
}
