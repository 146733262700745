import React, { useCallback, useMemo, useState, useEffect } from 'react'
import ShouldRender from '~/components/ShouldRender'
import Close from './Close/Close'
import * as S from './styles'

const Modal = ({
  onClose,
  minHeight,
  height = '485px',
  width = '483px',
  children,
  padding,
  visible,
  hideCloseIcon,
  blockOutsideClick = false,
}) => {
  const [alreadyVisible, setAlreadyVisible] = useState(false)
  const [closed, setClosed] = useState(false)
  const [modalAnimationCompleted, setModalAnimationCompleted] = useState(false)
  const [contentAnimationCompleted, setContentAnimationCompleted] = useState(false)

  const handleClick = useCallback(
    event => {
      if (!blockOutsideClick && event.target === event.currentTarget) {
        // eslint-disable-next-line no-unused-expressions
        onClose?.()
      }
    },
    [onClose, blockOutsideClick],
  )

  const contentClassName = useMemo(() => {
    if (visible) {
      return ` animated fadeIn animatedFaster`
    }

    return ` animated fadeOut animatedFaster`
  }, [visible])

  const containerClassName = useMemo(() => {
    if (visible) {
      return ` animated fadeIn`
    }

    return ` animated fadeOut pointer-events__none`
  }, [visible])

  const handleAnimationEnd = useCallback(event => {
    if (event.target !== event.currentTarget) return

    if (event.animationName === 'fadeOut') {
      setContentAnimationCompleted(true)
    }

    if (event.animationName === 'fadeOut') {
      setModalAnimationCompleted(true)
    }
  }, [])

  useEffect(() => {
    const allAnimationCompleted = modalAnimationCompleted && contentAnimationCompleted

    if (allAnimationCompleted) {
      setClosed(true)
      setAlreadyVisible(false)
    }
  }, [modalAnimationCompleted, contentAnimationCompleted])

  useEffect(() => {
    if (visible) {
      setClosed(false)
      setAlreadyVisible(true)
      setModalAnimationCompleted(false)
      setContentAnimationCompleted(false)
    }
  }, [visible])

  return (
    <ShouldRender if={alreadyVisible && !closed}>
      <S.Container onClick={handleClick} className={containerClassName} onAnimationEnd={handleAnimationEnd}>
        <S.Content
          style={{ width, height, minHeight, padding }}
          className={contentClassName}
          onAnimationEnd={handleAnimationEnd}
          hideCloseIcon={hideCloseIcon}
        >
          <ShouldRender if={onClose && !hideCloseIcon}>
            {/* @ts-ignore-next-line */}
            <Close onClick={onClose} />
          </ShouldRender>
          {children}
        </S.Content>
      </S.Container>
    </ShouldRender>
  )
}

export default Modal
