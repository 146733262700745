/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { FaRegFileAlt, FaRegImage } from 'react-icons/fa'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { IoIosImages } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import PlacesAutocomplete from 'react-places-autocomplete'
import { useSelector } from 'react-redux'
import Button from '~/components/Shared/Button'
import IconsProductImage from '~/components/Sponsor/IconsProductImage'
import Border from '~/components/Shared/Border'
import ProductsModal from '~/components/Sponsor/Modals/ProductsModal'
import ProductBadge from '~/components/Shared/ProductBadge'
import CalendarBadge from '~/components/CalendarBadge'
import { UploadS3 } from '~/helper/formFunctions'
import useSponsorFunctions from '~/helper/sponsorFunctions'
import { transformDateToUtcFormat } from '~/helper/getFormattedDates'

import * as S from './styled'

export default function ModalCreateEvent({
  handleCloseModal,
  showModal,
  eventToUpdate,
  review,
  createMessage,
  refuseCampaignProduct,
  formatId,
  sponsor,
}) {
  const { t } = useTranslation()
  const currentCampaignForReviewPage = useSelector(state => state.createCampaign.currentCampaignForReviewPage)
  const { image, title, organizer, about, eventLocation } = eventToUpdate
  const [file, setFile] = useState(eventToUpdate.file)
  const [imageId, setImageId] = useState(image)
  const [imageUrl, setImageUrl] = useState(eventToUpdate.imageUrl)
  const [updatingImage, setUpdatingImage] = useState(false)
  const [updatingFile, setUpdatingFile] = useState(false)
  const [startDate, setStartDate] = useState(transformDateToUtcFormat(eventToUpdate.startDate))
  const [endDate, setEndDate] = useState(transformDateToUtcFormat(eventToUpdate.endDate))
  const [date, setDate] = useState(new Date(eventToUpdate.date))
  const { handleSubmit, setValue, register, control, watch, errors } = useForm()
  const { updateCampaignFormatAction } = useSponsorFunctions()
  const titleWatch = watch('title')
  const aboutWatch = watch('about')

  function resetFormAndCloseModalAfterSubmit() {
    setStartDate('')
    setImageUrl(null)
    setImageId(null)
    setFile(null)
    setUpdatingFile(false)
    setUpdatingImage(false)
    handleCloseModal()
  }

  function onSubmit(product) {
    const copyObject = JSON.stringify(eventToUpdate)
    const productCopy = JSON.stringify(product)

    if (copyObject === productCopy) {
      return
    }
    updateCampaignFormatAction(product, formatId, resetFormAndCloseModalAfterSubmit)
  }

  function handleUpdatingFile() {
    setUpdatingFile(!updatingFile)
  }

  function handleUpdatingImage() {
    setUpdatingImage(!updatingImage)
  }

  function modalReviewCreateMessageAndCloseModal() {
    createMessage()
    refuseCampaignProduct()
    handleCloseModal()
  }

  useEffect(() => {
    register({ name: 'id' })
    register({ name: 'file' })
    register({ name: 'image' })
    register({ name: 'imageUrl' })
    setValue('image', imageId)
    setValue('imageUrl', imageUrl)
    setValue('file', file)
    setValue('id', eventToUpdate.id)
  }, [register, eventToUpdate.id, setValue, imageId, imageUrl, file])

  return (
    <ProductsModal
      createMessage={modalReviewCreateMessageAndCloseModal}
      action={onSubmit}
      buttonText={review ? 'Done' : 'Update'}
      modalTitle={review ? 'Revise Event:' : 'Edit Event:'}
      handleCloseModal={handleCloseModal}
      showModal={showModal}
      review={review}
      sponsor={sponsor}
      leftColumn={
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <S.InputTitle>Title</S.InputTitle>
            <Controller
              as={<TextField fullWidth variant="outlined" placeholder="Type a title" disabled={review || false} />}
              rules={{ required: 'Title is required' }}
              name="title"
              control={control}
              defaultValue={title === null ? '' : title}
            />
            <div className="error-message">
              <ErrorMessage errors={errors} name="title" as="p" />
            </div>

            <div className="mt-3">
              <S.InputTitle>Date</S.InputTitle>
              <Controller
                as={
                  <S.DatePickerInput
                    selected={date}
                    placeholderText="Choose date"
                    disabled={review || false}
                    minDate={new Date(currentCampaignForReviewPage.dtStart)}
                    maxDate={new Date(currentCampaignForReviewPage.dtEnd)}
                  />
                }
                rules={{ required: 'Date is required' }}
                name="date"
                control={control}
                onChange={([selected]) => {
                  setDate(selected)
                  return selected
                }}
                defaultValue={date}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="date" as="p" />
              </div>
            </div>

            <div className="mt-3">
              <S.InputTitle>End date</S.InputTitle>
              <Controller
                as={
                  <S.DatePickerInput
                    selected={endDate}
                    placeholderText="Date when the event will be available"
                    disabled
                  />
                }
                name="endDateCampaign"
                control={control}
                onChange={([selected]) => {
                  setDate(selected)
                  return selected
                }}
                defaultValue={new Date(currentCampaignForReviewPage.dtEnd)}
              />
            </div>

            <div className="mt-3">
              <S.InputTitle>About</S.InputTitle>
              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Tell them a little more about your event"
                    multiline
                    rows="4"
                    disabled={review || false}
                  />
                }
                rules={{ required: 'About is required' }}
                name="about"
                control={control}
                defaultValue={about === null ? '' : about}
              />
              <div className="error-message">
                <ErrorMessage errors={errors} name="about" as="p" />
              </div>
            </div>

            <div className="mt-3">
              <S.InputTitle>When is the event?</S.InputTitle>
              <S.WhenSection>
                <div>
                  <Controller
                    as={
                      <S.DatePickerInput
                        selected={startDate}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        minDate={new Date(currentCampaignForReviewPage.dtStart)}
                        maxDate={new Date(currentCampaignForReviewPage.dtEnd)}
                        placeholderText="Select..."
                        disabled={review || false}
                      />
                    }
                    name="startDate"
                    control={control}
                    onChange={([selected]) => {
                      setStartDate(selected)
                      return selected
                    }}
                    rules={{
                      required: `${t('startDate')} ${t('isRequired')}`,
                    }}
                    defaultValue={startDate}
                  />
                  <div className="error-message">
                    <ErrorMessage errors={errors} name="startDate" as="p" />
                  </div>
                </div>

                <div>
                  <Controller
                    as={
                      <S.DatePickerInput
                        selectsEnd
                        selected={endDate}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        maxDate={new Date(currentCampaignForReviewPage.dtEnd)}
                        placeholderText="Select..."
                        disabled={review || false}
                      />
                    }
                    name="endDate"
                    control={control}
                    onChange={([selected]) => {
                      setEndDate(selected)
                      return selected
                    }}
                    rules={{
                      required: `${t('endDate')} ${t('isRequired')}`,
                    }}
                    defaultValue={endDate}
                  />
                  <div className="error-message">
                    <ErrorMessage errors={errors} name="endDate" as="p" />
                  </div>
                </div>
              </S.WhenSection>
            </div>

            <div className="mt-3">
              <div className="event-location-section">
                <S.InputTitle>Event location</S.InputTitle>
                <Controller
                  as={
                    <PlacesAutocomplete onChange={() => {}} value="">
                      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                        <div>
                          <input
                            {...getInputProps({
                              className: 'location-search-input',
                              placeholder: 'Country, City, Neighborhood',
                              autoFocus: false,
                              paddingLeft: '30px',
                            })}
                            disabled={review || false}
                            style={{
                              boxSizing: 'border-box',
                            }}
                          />

                          <div
                            className="autocomplete-dropdown-container"
                            style={{
                              position: 'absolute',
                              marginTop: '6px',
                              border: '1px solid #cccccc',
                              borderRadius: '4px',
                              zIndex: 1,
                            }}
                          >
                            {suggestions.map(suggestion => {
                              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'

                              const style = suggestion.active
                                ? {
                                    backgroundColor: '#f2f2f2',
                                    cursor: 'pointer',
                                    padding: '5px 10px',
                                  }
                                : {
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer',
                                    padding: '5px 10px',
                                  }
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                  key={Math.random()}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  }
                  name="eventLocation"
                  control={control}
                  defaultValue={eventLocation === null ? '' : eventLocation}
                  rules={{
                    required: `${t('eventLocation')} ${t('isRequired')}`,
                  }}
                />
                <div className="error-message">
                  <ErrorMessage errors={errors} name="eventLocation" as="p" />
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div>
                <S.InputTitle>Event organizer</S.InputTitle>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Who is the organizer?"
                      disabled={review || false}
                    />
                  }
                  rules={{ required: 'Event organizer is required' }}
                  name="organizer"
                  control={control}
                  defaultValue={organizer === null ? '' : organizer}
                />
                <div className="error-message">
                  <ErrorMessage errors={errors} name="organizer" as="p" />
                </div>
              </div>
            </div>

            <div className="mt-4 mb-3">
              <Border />
            </div>

            <div className="mt-4 mb-2">
              <Border />
            </div>

            {review === false || review === undefined ? (
              updatingFile ? (
                <div className="mt-2">
                  <div className="d-flex justify-content-center align-items-center">
                    <FaRegFileAlt className="mr-2" size={14} />
                    <S.InputTitle>Files</S.InputTitle>
                  </div>
                  <UploadS3 setUploadId={setFile} />
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <Button
                    text="Add file"
                    type="button"
                    color="#5A6978"
                    action={handleUpdatingFile}
                    width="160px"
                    height="40px"
                  />
                </div>
              )
            ) : null}

            {review === false || review === undefined ? (
              updatingImage ? (
                <div className="mt-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <FaRegImage className="mr-2" size={14} />
                    <S.InputTitle>Image</S.InputTitle>
                  </div>
                  <UploadS3 setUploadId={setImageId} setUploadUrl={setImageUrl} />
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <Button
                    text="Add image"
                    type="button"
                    color="#5A6978"
                    action={handleUpdatingImage}
                    width="160px"
                    height="40px"
                  />
                </div>
              )
            ) : null}
          </S.FormContainer>
        </form>
      }
      rightColumn={
        <>
          <S.Phone>
            <S.Screen>
              <S.Content>
                <div className="image-container">
                  {imageUrl !== null ? (
                    <>
                      <img className="animated fadeIn" alt="" src={imageUrl} />
                      <IconsProductImage right="0" top="30%" />
                      <div className="ml-2 mt-3">
                        <S.ProductTitle>{titleWatch === undefined ? title : titleWatch}</S.ProductTitle>
                      </div>
                      <div style={{ marginTop: '-10px' }} className="d-flex ml-2">
                        <ProductBadge
                          text="event"
                          color="#87919A"
                          width="60px"
                          height="30px"
                          eventText="- artificial intelligence"
                          eventTextColor="#87919A"
                        />
                      </div>
                      <div style={{ marginTop: '-10px' }} className="calendar-section d-flex align-items-baseline ml-2">
                        {startDate !== '' ? (
                          <div>
                            <CalendarBadge text="EVENT" color="#FFF" width="60px" height="60px" startDate={startDate} />
                          </div>
                        ) : null}
                        <S.AboutSection>
                          <span>{aboutWatch === undefined ? about : aboutWatch}</span>
                        </S.AboutSection>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        style={{ backgroundColor: '#CCC' }}
                        alt=""
                        className="d-flex justify-content-center align-items"
                      />
                      <IoIosImages
                        style={{
                          position: 'absolute',
                          top: '125px',
                          right: '125px',
                        }}
                        color="red"
                        size={28}
                      />
                      <div className="ml-2 mt-3">
                        <S.ProductTitle>{titleWatch === undefined ? title : titleWatch}</S.ProductTitle>
                      </div>
                      <div style={{ marginTop: '-10px' }} className="d-flex ml-2">
                        <ProductBadge
                          text="event"
                          color="#87919A"
                          width="60px"
                          height="30px"
                          eventText="- artificial intelligence"
                          eventTextColor="#87919A"
                        />
                      </div>

                      <div style={{ marginTop: '-10px' }} className="calendar-section d-flex align-items-baseline ml-2">
                        {startDate !== '' ? (
                          <div>
                            <CalendarBadge text="EVENT" color="#FFF" width="60px" height="60px" startDate={startDate} />
                          </div>
                        ) : null}
                        <S.AboutSection>
                          <span>{aboutWatch === undefined ? about : aboutWatch}</span>
                        </S.AboutSection>
                      </div>
                    </>
                  )}
                </div>
              </S.Content>
            </S.Screen>
            <S.Home />
          </S.Phone>
        </>
      }
    />
  )
}
