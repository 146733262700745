/* eslint-disable no-useless-escape */
import * as Yup from 'yup'
import i18n from '~/i18n'

const regMatch = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

const slidingTopBanner = Yup.object()
  .shape({
    title: Yup.string().required(i18n.t('defaultValidation', { inputName: i18n.t('title') })),
    link: Yup.string()
      .matches(regMatch, i18n.t('linkIsNotValid'))
      .required(i18n.t('defaultValidation', { inputName: i18n.t('link') })),
    date: Yup.string()
      .required(i18n.t('defaultValidation', { inputName: i18n.t('startDate') }))
      .typeError(i18n.t('defaultValidation', { inputName: i18n.t('startDate') })),
    endDate: Yup.string()
      .required(i18n.t('defaultValidation', { inputName: i18n.t('endDate') }))
      .typeError(i18n.t('defaultValidation', { inputName: i18n.t('endDate') })),
  })
  .required()

export default slidingTopBanner
