import styled from 'styled-components'
import DatePicker from 'react-datepicker'

export const FormContainer = styled.div`
  .input-date {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    height: 40px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(159, 159, 159, 0.7);
    border-radius: 5px;
    padding-left: 5px;
  }

  .event-location-section {
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      height: 40px;
      padding-right: 45px;
      box-sizing: border-box;
      background: url('google-logo.png') no-repeat 98%;
      background-size: 26px;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #c4c4c4 !important;

      padding-left: 10px;
      font-family: Lato;
      font-size: 16px;
      font-weight: 500;
      line-height: 14px;
    }
  }
`

export const DatePickerInput = styled(DatePicker)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 5px;
`

export const InputTitle = styled.span`
  margin-top: 3px;
  color: #404257;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
`

export const ProductTitle = styled.span`
  margin-top: 3px;
  color: #fff;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
`

export const WhenSection = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;

  input {
    ::placeholder {
      color: #858588;
      font-size: 15px;
      font-weight: 500;
      opacity: 0.5;
    }
  }
`

export const Phone = styled.div`
  width: 310px;
  height: 640px;
  border: 2px solid #ccc;
  border-radius: 30px;
  position: relative;
  left: 50%;
  top: 50px;
  margin: -20px -150px;
  background: #fff;
`

export const Screen = styled.div`
  background-color: rgba(0, 0, 0, 0.95);
  width: 290px;
  height: 520px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  left: 50%;
  top: 50%;
  margin: -260px -145px;
  box-sizing: border-box;
  overflow: hidden;
`

export const Home = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0 -10px;
  border-radius: 50%;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .image-container {
    width: 100%;
    height: 320px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .calendar-section {
    color: #fff;
    font-family: Roboto;
    font-size: 12px;

    .about {
      margin-left: 5px;
    }
  }
`

export const AboutSection = styled.div`
  margin: 10px 10px 3px 10px;
  color: #fff;
  font-family: Roboto;
  font-size: 12px;

  width: 200px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`
