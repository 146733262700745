import React from 'react'
import { IoIosSearch } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

export default function SearchInputMocked() {
  const { t } = useTranslation()

  return (
    <S.SearchContainer>
      <S.SearchInput type="text" name="search" placeholder={t('search')} />
      <IoIosSearch color="#969FAA" size={24} />
    </S.SearchContainer>
  )
}
