import React from 'react'
import { IoIosSearch } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

export default function SearchInput({ filter, setFilter }) {
  const { t } = useTranslation()

  function handleChange(event) {
    setFilter(event.target.value)
  }

  return (
    <S.SearchContainer>
      <S.SearchInput value={filter} name="search" placeholder={t('search')} onChange={e => handleChange(e)} />
      <IoIosSearch color="#969FAA" size={24} />
    </S.SearchContainer>
  )
}
